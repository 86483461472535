import { BoxProps } from 'ia-react-core';
import React, { FC, PropsWithChildren } from 'react';
import { StyledBorderBox } from './BorderBox.styles';

const BorderBox: FC<BoxProps & PropsWithChildren> = ({ children, ...props }) => (
  <StyledBorderBox {...props}>
    { children }
  </StyledBorderBox>
);
export default BorderBox;
