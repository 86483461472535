import {
  HelpIcon,
  Hidden,
  Menu,
  MenuItem,
  SettingsIcon,
  openModalByName,
  useScopedSelector,
} from 'ia-react-core';
import React, { FC, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ReactComponent as CloudIcon } from '~/assets/Icons/cloudDone.svg';
import { ReactComponent as HomeIcon } from '~/assets/Icons/homeWhite.svg';
import { ReactComponent as SaveDiskIcon } from '~/assets/Icons/saveDisk.svg';
import { ReactComponent as MedicalIcon } from '~/assets/Icons/medicalIcon.svg';
import openMedicalRequirements from '~/utilities/openMedicalRequirements';
import InformationModal from '~/layouts/components/InformationModal';
import { GetApplicationConfigurationResource } from '~/components/GetApplicationConfigurationResource';
import { setAppVersioning } from '~/App.actions';
import { StyledFileName, StyledParagraph } from '../../IllustrationLayout.styles';
import MyFilesButton from '../MyFilesButton';
import { IllustrationMainHeaderStateInterface } from './interfaces/IllustrationMainHeaderStateInterface';
import { StyledIllustrationMainHeaderNavIconButton } from './IllustrationMainHeaderNav.styles';

export interface IllustrationMainHeaderNavProps {
  saleFileName?: string;
  showSaveDiskIcon?: boolean;
  onSave?: () => void;
  onNavigateToHome?: () => void;
}

const IllustrationMainHeaderNav: FC<IllustrationMainHeaderNavProps> = ({ saleFileName, showSaveDiskIcon, onSave, onNavigateToHome }) => {
  const { t } = useTranslation('App');
  const history = useHistory();
  const dispatch = useDispatch();
  const localeGlobal = useSelector((state: {language: {locale: string}}) => state?.language?.locale);
  const applicationConfiguration = useScopedSelector((state: IllustrationMainHeaderStateInterface) => state?.data?.applicationConfiguration);
  const showHomeIcon = onNavigateToHome != null;

  const items = useMemo(() => {
    const menuItems: ReactElement[] = [];
    menuItems.push(
      <MenuItem
        url="#"
        onClick={() => dispatch(openModalByName('modals.advisors'))}
      >
        {t('advisors')}
      </MenuItem>,
    );

    menuItems.push(
      <MenuItem url="#" onClick={() => dispatch(openModalByName('modals.illustrationSettings'))}>
        {t('illustrations')}
      </MenuItem>,
    );

    if (applicationConfiguration?.isOipaEnvironment) {
      menuItems.push(
        <MenuItem
          url="#"
          onClick={() => dispatch(openModalByName('modals.oipaEnvironments'))}
          key="oipaEnvironments"
        >
          {t('OIPA_environments')}
        </MenuItem>,
      );
    }

    return menuItems;
  }, [applicationConfiguration?.isOipaEnvironment, dispatch, t]);

  const handleHomeClick = () => {
    if (onNavigateToHome != null) {
      onNavigateToHome();
    } else {
      dispatch(setAppVersioning('', ''));
      setTimeout(() => {
        history.push('/');
      }, 300);
    }
  };

  return (
    <>
      <GetApplicationConfigurationResource autoRequest />
      <InformationModal />
      <Hidden xs>
        <StyledParagraph>
          <StyledFileName>{saleFileName ?? ''}</StyledFileName>
        </StyledParagraph>

        {
          !showSaveDiskIcon &&
          <StyledIllustrationMainHeaderNavIconButton Icon={CloudIcon} />
        }

        {
          showSaveDiskIcon && <StyledIllustrationMainHeaderNavIconButton onClick={onSave} label={t('saved')} Icon={SaveDiskIcon} />
        }
      </Hidden>
      <Hidden sm xs>
        <StyledIllustrationMainHeaderNavIconButton label={t('work')} Icon={MedicalIcon} onClick={() => { openMedicalRequirements(localeGlobal); }} />
        {showHomeIcon && <StyledIllustrationMainHeaderNavIconButton
          label={t('home')}
          Icon={HomeIcon}
          onClick={handleHomeClick}
        />}
        <Menu
          items={items}
          verticalOffset={-5}
        >
          <StyledIllustrationMainHeaderNavIconButton label={t('settings')} Icon={SettingsIcon} />
        </Menu>
        <Menu
          verticalOffset={-5}
          items={[
            <MenuItem onClick={() => dispatch(openModalByName('modals.information'))}>
              {t('about')}
            </MenuItem>,
          ]}
        >
          <StyledIllustrationMainHeaderNavIconButton label={t('help')} Icon={HelpIcon} />
        </Menu>
      </Hidden>
      <Hidden lg xl xxl md>
        <MyFilesButton saleFileName={saleFileName} showSaveDiskIcon={showSaveDiskIcon} onSaveClick={onSave} />
      </Hidden>
    </>
  );
};

export default IllustrationMainHeaderNav;
