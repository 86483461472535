import { Action } from 'redux';
import { ChangeRequestState, Signataire } from '~/fragments/SharedLibrary/interfaces/State';
import { UpdateSignatureElectronicFormAction, UpdateAdditionalFormSignerFullNameAction, AddMainSignerRoleAction, DeleteMainSignerRoleAction, AddAdditionalSignerRoleAction, DeleteAdditionalSignerRoleAction } from '../ApplicationFragment.actions';

export function updateSignatureElectronicFormReducer(state: ChangeRequestState, action: Action) {
  const { payload } = action as UpdateSignatureElectronicFormAction;
  const additionalSignerForms = {};
  const mainSignerForms = {};
  const mainSignerData = payload.signers?.filter((signer) => !signer?.signataire?.isAdditionnel);
  const additionalSigners = payload.signers?.filter((signer) => signer?.signataire?.isAdditionnel);
  additionalSigners?.forEach((signer, indx) => {
    const { signataire } = signer;
    const formName = `additionalSignerForm_${indx}`;
    const rolesForm: Record<string, number> = {};

    signer?.roles?.forEach((role, indx2) => {
      rolesForm[`role_${indx2}`] = role;
    });

    const formData = {
      [formName]: {
        ...signataire,
        roles: signer.roles || [],
        roles_form: rolesForm,
      },
    };
    Object.assign(additionalSignerForms, formData);
  });
  mainSignerData?.forEach((signer, indx) => {
    const { signataire } = signer;
    const formName = `mainSignerForm_${indx}`;
    const rolesForm: Record<string, number> = {};

    signer?.roles?.forEach((role, indx2) => {
      rolesForm[`role_${indx2}`] = role;
    });

    const formData = {
      [formName]: {
        ...signataire,
        roles: signer.roles || [],
        roles_form: rolesForm,
      },
    };
    Object.assign(mainSignerForms, formData);
  });

  return {
    ...state,
    values: {
      ...state?.values,
      signatureElectronic: {
        ...state?.values?.signatureElectronic,
        ...payload,
      },
      additionalSignerForms: {
        ...state?.values?.additionalSignerForms,
        ...additionalSignerForms,
      },
      mainSigner: {
        ...state?.values?.mainSigner,
        ...mainSignerForms,
      },
    },
  };
}

export function updateAdditionalFormSignerFullName(state: ChangeRequestState, action: Action) {
  const { payload } = action as UpdateAdditionalFormSignerFullNameAction;

  const additionalSignerForm: Signataire = state?.values?.additionalSignerForms[`additionalSignerForm_${payload}`];
  const firstName = additionalSignerForm?.prenom;
  const lastName = additionalSignerForm?.nomFamille;

  return {
    ...state,
    values: {
      ...state?.values,
      additionalSignerForms: {
        ...state?.values?.additionalSignerForms,
        [`additionalSignerForm_${payload}`]: {
          ...additionalSignerForm,
          nomComplet: `${firstName} ${lastName}`,
        },
      },
    },
  };
}

export function addMainSignerRoleReducer(state: ChangeRequestState, action: Action) {
  const { payload } = action as AddMainSignerRoleAction;
  const mainSignersFormValues = state?.values?.mainSigner;
  const mainSignerValue = mainSignersFormValues[`mainSignerForm_${payload?.index}`];

  const newRoleCount = mainSignerValue?.roles_form ? Object.keys(mainSignerValue?.roles_form).length : 0;

  return {
    ...state,
    values: {
      ...state?.values,
      mainSigner: {
        ...state?.values?.mainSigner,
        [`mainSignerForm_${payload?.index}`]: {
          ...mainSignerValue,
          roles_form: {
            ...mainSignerValue?.roles_form,
            [`role_${newRoleCount}`]: payload?.value,
          },
        },
      },
    },
  };
}

export function deleteMainSignerRoleReducer(state: ChangeRequestState, action: Action) {
  const { payload } = action as DeleteMainSignerRoleAction;
  const mainSignerValues = state?.values?.mainSigner[`mainSignerForm_${payload?.index}`];

  const keyTobeRemoved = `role_${payload?.value}`;
  const updatedRolesform = { ...mainSignerValues?.roles_form };
  let roles = mainSignerValues?.roles || [];

  delete updatedRolesform[keyTobeRemoved];
  roles = roles.length > 1 ? roles.splice(payload.value, 1) : [];

  const renamedRolesForm: Record<string, string> = {};

  Object.keys(updatedRolesform).forEach((key, indx) => {
    renamedRolesForm[`role_${indx}`] = updatedRolesform[key];
  });

  return {
    ...state,
    values: {
      ...state?.values,
      mainSigner: {
        ...state?.values?.mainSigner,
        [`mainSignerForm_${payload?.index}`]: {
          ...mainSignerValues,
          roles_form: renamedRolesForm,
          roles: [...roles],
        },
      },
    },
  };
}

export function addAdditionalSignerRoleReducer(state: ChangeRequestState, action: Action) {
  const { payload } = action as AddAdditionalSignerRoleAction;
  const additionalSignersFormValues = state?.values?.additionalSignerForms;
  const additionalSignerValue = additionalSignersFormValues[`additionalSignerForm_${payload?.index}`];

  const newRoleCount = additionalSignerValue?.roles_form ? Object.keys(additionalSignerValue?.roles_form).length : 0;

  return {
    ...state,
    values: {
      ...state?.values,
      additionalSignerForms: {
        ...state?.values?.additionalSignerForms,
        [`additionalSignerForm_${payload?.index}`]: {
          ...additionalSignerValue,
          roles_form: {
            ...additionalSignerValue?.roles_form,
            [`role_${newRoleCount}`]: payload?.value,
          },
        },
      },
    },
  };
}

export function deleteAdditionalSignerRoleReducer(state: ChangeRequestState, action: Action) {
  const { payload } = action as DeleteAdditionalSignerRoleAction;
  const additionalSignerValues = state?.values?.additionalSignerForms[`additionalSignerForm_${payload?.index}`];

  const keyTobeRemoved = `role_${payload?.value}`;
  const updatedRolesform = { ...additionalSignerValues?.roles_form };
  let roles = additionalSignerValues?.roles || [];

  delete updatedRolesform[keyTobeRemoved];
  roles = roles.length > 1 ? roles.splice(payload.value, 1) : [];

  const renamedRolesForm: Record<string, string> = {};

  Object.keys(updatedRolesform).forEach((key, indx) => {
    renamedRolesForm[`role_${indx}`] = updatedRolesform[key];
  });

  return {
    ...state,
    values: {
      ...state?.values,
      additionalSignerForms: {
        ...state?.values?.additionalSignerForms,
        [`additionalSignerForm_${payload?.index}`]: {
          ...additionalSignerValues,
          roles_form: renamedRolesForm,
          roles: [...roles],
        },
      },
    },
  };
}
