import React, { FC } from 'react';
import { MoreHorizIcon, TertiaryButtonProps } from 'ia-react-core';
import { StyledResponsiveMoreButton } from './ResponsiveMoreButton.styles';

interface ResponsiveMoreButtonProps extends Omit<TertiaryButtonProps, 'children'> {}

const ResponsiveMoreButton: FC<ResponsiveMoreButtonProps> = (props) => (
  <StyledResponsiveMoreButton {...props}>
    <MoreHorizIcon />
  </StyledResponsiveMoreButton>
);

export default ResponsiveMoreButton;
