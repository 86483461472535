import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  T2, T5, TextInput, ConfirmModal, Spacing, Divider, closeModalByName, requestResourceByName, unselectEveryTableRowsByName, Resource,
} from 'ia-react-core';

import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { SaleFile } from '../interfaces/SaleFile';
import { clearFilter, setFilter } from '../SaleFileDataTable/SaleFileDataTable.actions';

export interface RenameModalProps {
  saleFile?: SaleFile;
  filterType: number;
}

const RenameModal: FC<RenameModalProps> = ({ saleFile, filterType }: RenameModalProps) => {
  const { t } = useTranslation('RenameModal');
  const dispatch = useDispatch();
  const renameFileUrl = getBffUrlForRoute(BffRoute.user_sales_saleId_name, { saleId: saleFile?.id?.valeur }, false);
  const refreshDataTable = () => {
    dispatch(requestResourceByName('resources.mySaleFiles'));
    dispatch(unselectEveryTableRowsByName('mySaleFiles'));
    dispatch(clearFilter());
    dispatch(setFilter(filterType));
  };

  const [saleFileName, setSaleFileName] = useState('FILENAME');
  const [dirty, setDirty] = useState(false);
  const [lockConfirm, setLockConfirm] = useState(false);

  if (saleFile) {
    if (!dirty && saleFile.nomVente !== saleFileName) {
      setSaleFileName(saleFile.nomVente);
    }
  }

  const handleRenameFile = () => {
    const saleFileDupe = { ...saleFile };
    saleFileDupe.nomVente = saleFileName?.replace(/\s+/g, ' ');
    dispatch(requestResourceByName('resources.renameFile', { name: saleFileName }));
  };

  return (
    <>
      <Resource
        name="renameFile"
        method="PUT"
        url={renameFileUrl}
        onSuccess={() => {
          refreshDataTable();
        }}
      />
      <ConfirmModal
        name="renameSalesFileModal"
        confirmButton={t('saveButtonText')}
        onConfirm={() => {
          dispatch(closeModalByName('modals.renameSalesFile'));
          handleRenameFile();
        }}
        onClose={() => {
          setDirty(false);
          setLockConfirm(false);
        }}
        confirmDisabled={lockConfirm}
        isClosable
      >
        <T2>{t('renameFileTitle')}</T2>
        <T5>{t('renameFileDescription')}</T5>
        <TextInput
          value={saleFileName}
          maxLength={60}
          onKeyPress={(ev) => {
            if ((ev.key === 'Enter') && (!lockConfirm)) {
              dispatch(closeModalByName('modals.renameSalesFile'));
              handleRenameFile();
            }
          }}
          onInput={(event) => {
            setDirty(true);
            const fileName = event.currentTarget.value.replace(/[^a-zA-Z0-9'():\s-]/g, '');
            setSaleFileName(fileName.toUpperCase());
            const lock = (fileName.trim() === '');
            setLockConfirm(lock);
          }}

        />
        <Spacing m={36} />
        <Divider />
      </ConfirmModal>
    </>
  );
};

export default RenameModal;
