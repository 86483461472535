import React, { FC, HTMLAttributes } from 'react';
import { FormRowProps } from 'ia-react-core';
import { StyledFlex } from './Flex.styles';

export interface FlexProps extends FormRowProps, Omit<HTMLAttributes<HTMLDivElement>, 'children'> {}

const Flex: FC<FlexProps> = (props) => (
  <StyledFlex {...props} />
);

export default Flex;
