import { AdvisorModel } from '~/interfaces/InsurabilityDeclaration/AdvisorModel';
import { AdvisorModalView } from './constants';

const prefix = 'AdvisorModal';

export const SET_LOADING_TYPE = `${prefix}.SetLoading` as const;
export const setLoadingAction = (isLoading: boolean) => ({
  type: SET_LOADING_TYPE,
  isLoading,
});
export type SetLoadingAction = ReturnType<typeof setLoadingAction>;

export const FATAL_ERROR_TYPE = `${prefix}.FatalError` as const;
export const fatalError = (logMessage: string, error?: unknown) => ({
  type: FATAL_ERROR_TYPE,
  logMessage,
  error,
});
export type FatalError = ReturnType<typeof fatalError>;

export const CLEAN_FATAL_ERROR_TYPE = `${prefix}.CleanFatalError` as const;
export const cleanFatalErrorAction = () => ({
  type: CLEAN_FATAL_ERROR_TYPE,
});
export type CleanFatalErrorAction = ReturnType<typeof cleanFatalErrorAction>;

export const SWITCH_VIEW_TYPE = `${prefix}.SwitchView` as const;
export const switchViewAction = (viewMode: AdvisorModalView) => ({
  type: SWITCH_VIEW_TYPE,
  viewMode,
});
export type SwitchViewAction = ReturnType<typeof switchViewAction>;

export const SET_ADVISOR_TO_EDIT_TYPE = `${prefix}.SetAdvisorToEdit` as const;
export const setAdvisorToEditAction = (advisor: AdvisorModel) => ({
  type: SET_ADVISOR_TO_EDIT_TYPE,
  advisor,
});
export type SetAdvisorToEditAction = ReturnType<typeof setAdvisorToEditAction>;

export const ADD_SETTINGS_ADVISOR_TYPE = `${prefix}.AddSettingsAdvisor` as const;
export const addSettingsAdvisorAction = (advisor: AdvisorModel) => ({
  type: ADD_SETTINGS_ADVISOR_TYPE,
  advisor,
});
export type AddSettingsAdvisorAction = ReturnType<typeof addSettingsAdvisorAction>;

export const ADD_SETTINGS_ADVISOR_SUCCESS_TYPE = `${prefix}.AddSettingsAdvisorSuccess` as const;
export const addSettingsAdvisorSuccessAction = (advisor: AdvisorModel) => ({
  type: ADD_SETTINGS_ADVISOR_SUCCESS_TYPE,
  advisor,
});
export type AddSettingsAdvisorSuccessAction = ReturnType<typeof addSettingsAdvisorSuccessAction>;

export const EDIT_SETTINGS_ADVISOR_TYPE = `${prefix}.EditSettingsAdvisor` as const;
export const editSettingsAdvisorAction = (advisor: AdvisorModel) => ({
  type: EDIT_SETTINGS_ADVISOR_TYPE,
  advisor,
});
export type EditSettingsAdvisorAction = ReturnType<typeof editSettingsAdvisorAction>;

export const EDIT_SETTINGS_ADVISOR_SUCCESS_TYPE = `${prefix}.EditSettingsAdvisorSuccess` as const;
export const editSettingsAdvisorSuccessAction = (advisor: AdvisorModel) => ({
  type: EDIT_SETTINGS_ADVISOR_SUCCESS_TYPE,
  advisor,
});
export type EditSettingsAdvisorSuccessAction = ReturnType<typeof editSettingsAdvisorSuccessAction>;

export const DELETE_SETTINGS_ADVISOR_TYPE = `${prefix}.DeleteSettingsAdvisor` as const;
export const deleteSettingsAdvisorAction = (advisorId: string) => ({
  type: DELETE_SETTINGS_ADVISOR_TYPE,
  advisorId,
});
export type DeleteSettingsAdvisorAction = ReturnType<typeof deleteSettingsAdvisorAction>;

export const DELETE_SETTINGS_ADVISOR_SUCCESS_TYPE = `${prefix}.DeleteSettingsAdvisorSuccess` as const;
export const deleteSettingsAdvisorSuccessAction = (advisorId: string) => ({
  type: DELETE_SETTINGS_ADVISOR_SUCCESS_TYPE,
  advisorId,
});
export type DeleteSettingsAdvisorSuccessAction = ReturnType<typeof deleteSettingsAdvisorSuccessAction>;

export const SET_DUPLICATION_CODE_ERROR_TYPE = `${prefix}.SetDuplicationCodeError` as const;
export const setDuplicationCodeErrorAction = (codeValue: boolean) => ({
  type: SET_DUPLICATION_CODE_ERROR_TYPE,
  codeValue,
});
export type SetDuplicationCodeErrorAction = ReturnType<typeof setDuplicationCodeErrorAction>;
