import React, { Fragment, useMemo } from 'react';
import { QuestionType } from '~/interfaces/InsurabilityDeclaration/enums';
import BoolField from './BoolField';
import DropdownField from './DropdownField';
import IntegerField from './IntegerField';
import MultipleChoiceField from './MultipleChoiceField';
import QuestionField from './QuestionField';
import LookupField from './LookupField';
import FloatField from './FloatField';
import StringField from './StringField';
import DateField from './DateField';
import StaticField from './StaticField';

const NullField: QuestionField = ({ question }) => React.createElement(Fragment, null, `Field not implemented for questionType ${question.questionType}`);

export default function useQuestionFieldFactory(questionType: QuestionType): { Field: QuestionField; sizes: { xs: number; sm: number } } {
  return useMemo(() => {
    let result: QuestionField;
    let sizes = { xs: 12, sm: 8 };

    switch (questionType) {
      case QuestionType.Unknown:
        result = null;
        break;
      case QuestionType.BoolQuestion:
        result = BoolField;
        break;
      // TODO implement
      case QuestionType.DateQuestion:
        result = DateField;
        break;
      case QuestionType.FloatQuestion:
        result = FloatField;
        break;
      case QuestionType.IntegerQuestion:
        result = IntegerField;
        break;
      case QuestionType.StringQuestion:
        result = StringField;
        break;
      case QuestionType.MultipleChoiceListQuestion:
        sizes = { ...sizes, sm: 12 };
        result = MultipleChoiceField;
        break;
      case QuestionType.SingleChoiceListQuestion:
        sizes = { ...sizes, sm: 6 };
        result = DropdownField;
        break;
      case QuestionType.LookupQuestion:
        result = LookupField;
        break;
      case QuestionType.StaticContent:
        result = StaticField;
        break;
      default:
        result = NullField;
    }
    return { Field: result, sizes };
  }, [questionType]);
}
