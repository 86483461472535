import { ProtectionType } from '~/interfaces/InsuranceHistoryPageState';

export const GetInsuranceDescription = (t: any) => ({
  [ProtectionType.lifeInsurance]: t('insuranceTypes.life'),
  [ProtectionType.criticalIllness]: t('insuranceTypes.critical'),
  [ProtectionType.disabilityInsurance]: t('insuranceTypes.disability'),
  [ProtectionType.lifeAndCritical]: '',
  [ProtectionType.lifeDisaility]: '',
  [ProtectionType.criticalDisability]: '',
  [ProtectionType.all]: '',
});

export const INSURANCE_HISTORY_FORM_NAME = 'insuranceHistory';
export const INFORCE_INSURANCE_MODAL_NAME = 'inforceInsurance';
export const INFORCE_INSURANCE_REPEATER_NAME = 'inforceInsurance';
export const INFORCE_INSURANCE_FORM_NAME = 'InforceInsurance';
export const INFORCE_INSURANCE_SAVE_WARNING_MODAL_NAME = 'inforceInsuranceSaveWarning';
export const INFORCE_INSURANCE_MINIMUM_LENTGH = 1;

export const PENDING_INSURANCE_MODAL_NAME = 'pendingInsurance';

export const UPDATE_INSURANCE_HISTORY_RESOURCE_NAME = 'updateInsuranceHistory';
