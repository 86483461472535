import { requestResourceByName } from 'ia-react-core';
import { RequestFor } from '~/interfaces/ApplicationFragmentState';
import { formatToRequestForDTO } from '../pages/AdvisorPage/utilities';
import { REQUEST_FOR_RESOURCE } from '../pages/AdvisorPage/constants';
import { RequestForForm } from '../pages/AdvisorPage/interfaces';
import { ApplicationIntentType } from '../constants';

const advisorApiCall = (requestForData: RequestFor, formValues: RequestForForm, dispatch: Function) => {
  const updatedRequest = formatToRequestForDTO({
    previousData: requestForData,
    formValues,
    save: false,
    intent: ApplicationIntentType.SaveAndValidate,
  });
  dispatch(requestResourceByName(`resources.${REQUEST_FOR_RESOURCE}`, updatedRequest));
};

export default advisorApiCall;
