import { Table, TableCell, TableHead, TableHeader, TableRow } from 'ia-react-core';
import React, { FC } from 'react';
import ExplanatoryTableModel from '~/interfaces/InsurabilityDeclaration/ExplanatoryTableModel';
import { StyledContainer } from './ExplanatoryTable.styles';

export interface ExplanatoryTableProps {
  table: ExplanatoryTableModel;
}

const ExplanatoryTable: FC<ExplanatoryTableProps> = ({ table }) => (
  <StyledContainer>
    <Table style={{ minWidth: 'max-content' }}>
      <TableHead>
        <TableRow>
          {table.columns.map((c) => (
            <TableHeader key={c.id}>{c.name}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      {table.rows.map((r) => (
        <TableRow key={r.id}>
          {r.cells.map((c) => (
            <TableCell key={c.id}>{c.value}</TableCell>
          ))}
        </TableRow>
      ))}
    </Table>
  </StyledContainer>
);

export default ExplanatoryTable;
