export default {
  bornOn: 'Né(e) le',
  age: 'Âge',
  gender: 'Sexe',
  tobaccoUse: 'Tabac',
  parameters: 'Paramètres',
  amount: 'Montant',
  premium: 'Prime',
  monthly: 'Mensuelle',
  annually: 'Annuelle',
  riders: 'Avenants',
  deathReturn: 'Remboursement des primes au décés (RPD)',
  flexibleReturn: 'Remboursement flexible des primes (RFP)',
  t75AndT100: 'Remboursement flexible des primes T75 et T100 ',
  years15: 'Remb. flexible des primes 15 ans (RFP15)',
  years65: 'Remb. flexible des primes 65 ans (RFP65)',
  yes: 'Oui',
  no: 'Non',
  frp15: 'RFP15',
  frp65: 'RFP65',
};
