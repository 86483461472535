import { Address, requestResourceByName } from 'ia-react-core';
import { ApplicationIntentType } from '../constants';
import AddressModel from '../interfaces/AddressModel';
import { PUT_APPLICANT_RESOURCE_NAME } from '../pages/ApplicantPage/constant';
import { ApplicantFormData } from '../pages/ApplicantPage/interfaces';
import { getDataForSubmission } from '../pages/ApplicantPage/utilities/getDataForSubmission';

const applicantApiCall = (formValues: ApplicantFormData<AddressModel | Address>, dispatch: Function) => {
  const clonedFormData = getDataForSubmission(formValues);
  // payload sending to BFF
  dispatch(requestResourceByName(`resources.${PUT_APPLICANT_RESOURCE_NAME}`, {
    intentType: ApplicationIntentType.SaveAndValidate,
    regulatoryQuestion: clonedFormData,
  }));
};

export default applicantApiCall;
