export const API_ROOT = (process.env.REACT_APP_MOCK_API === 'true') ?
  '/api' :
  process.env.REACT_APP_API_ROOT;

export const AUTH_AUTHORITY_URL = (process.env.REACT_APP_MOCK_API_AUTH === 'true') ?
  `${API_ROOT}/oauth` :
  process.env.REACT_APP_AUTH_AUTHORITY_URL;

export const ADVISOR_SCOPE = 'openid profile address phone email user_role other identity individuallife.eapps indlife.interaction.edit indlife.interaction.read indlife.decision.read indlife.report.read domain.dcp domain.dcp.groups experience.advisor';
export const EMPLOYEE_SCOPE = 'openid profile address phone email user_role other identity individuallife.eapps indlife.interaction.edit indlife.interaction.read indlife.decision.read indlife.report.read domain.dcp domain.dcp.groups experience.employee';
