export const sizes = {
  xs: '767px',
  sm: '768px',
  md: '1000px',
  lg: '1136px',
  xl: '1360px',
  xxl: '1600px',
};

export const devices = {
  xs: `(max-width: ${sizes.xs})`,
  sm: `(min-width: ${sizes.sm})`,
  md: `(min-width: ${sizes.md})`,
  lg: `(min-width: ${sizes.lg})`,
  xl: `(min-width: ${sizes.xl})`,
  xxl: `(min-width: ${sizes.xxl})`,
};

export enum breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}
