import React, { FC } from 'react';
import { ConfirmDialog, P, WarningIcon } from 'ia-react-core';
import { useTranslation } from 'react-i18next';

export const DOWNLOAD_CONTRACT_CONFIRM_DIALOG = 'downloadContractConfirmDialog';

export interface DownloadConfirmDialogProps {
  onConfirm: () => void;
}

const DownloadConfirmDialog: FC<DownloadConfirmDialogProps> = ({ onConfirm }: DownloadConfirmDialogProps) => {
  const { t } = useTranslation('makeEchange');

  return (
    <ConfirmDialog
      name={DOWNLOAD_CONTRACT_CONFIRM_DIALOG}
      cancelButton={t('confirmDownloadModal.no')}
      confirmButton={t('confirmDownloadModal.yes')}
      onConfirm={onConfirm}
      Icon={WarningIcon}
      color="#ffdc00"
      noheader
    >
      <P>{t('confirmDownloadModal.content')}</P>
    </ConfirmDialog>
  );
};

export default DownloadConfirmDialog;
