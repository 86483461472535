import { RouteDefinition, RouteDefinitions, RouteSectionDefinition } from 'ia-react-core';

export enum CHANGES_ROUTES_KEY {
  CHANGES = 'changes',
  ECHANGEHEADOFFICE = 'headoffice-echange',
  ADVISOR = 'advisor',
  ECHANGESUMMARY = 'summary-echange',
}

export const ChangesRoutes: Record<CHANGES_ROUTES_KEY, RouteSectionDefinition | RouteDefinition> = {
  [CHANGES_ROUTES_KEY.CHANGES]: {
    label: { en: 'Changes', fr: 'Changements' },
    path: { fr: '/application/:saleId/echange/changements', en: '/application/:saleId/echange/changes' },
    loader: () => import('../fragments/ChangeFragment/pages/EChangePage/pages/HomePage'),
    name: CHANGES_ROUTES_KEY.CHANGES,
  },
  [CHANGES_ROUTES_KEY.ECHANGEHEADOFFICE]: {
    label: { en: 'Head Office', fr: 'Siège Social' },
    path: { fr: '/application/:saleId/echange/ho-changement', en: '/application/:saleId/echange/ho-echange' },
    loader: () => import('../fragments/ChangeFragment/pages/EChangePage/pages/HeadOffice/HeadOffice'),
    name: CHANGES_ROUTES_KEY.ECHANGEHEADOFFICE,
  },
  [CHANGES_ROUTES_KEY.ADVISOR]: {
    label: { fr: 'Conseiller(s)', en: 'Advisor(s)' },
    path: { fr: '/application/:saleId/echange/conseiller', en: '/application/:saleId/echange/advisor' },
    loader: () => import('../fragments/ApplicationFragment/pages/AdvisorPage'),
    name: CHANGES_ROUTES_KEY.ADVISOR,
  },
  [CHANGES_ROUTES_KEY.ECHANGESUMMARY]: {
    label: { fr: 'Résumé', en: 'Summary' },
    path: { fr: '/application/:saleId/echange/sommaire', en: '/application/:saleId/echange/summary' },
    loader: () => import('../fragments/ChangeFragment/pages/EChangePage/pages/Summary'),
    name: CHANGES_ROUTES_KEY.ECHANGESUMMARY,
  },
};

const CHANGES_ROUTES: RouteDefinitions = Object.values(ChangesRoutes);

export default CHANGES_ROUTES;
