import styled from 'styled-components';

export const StyledFoldersPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;

  max-width: 1360px;
`;
