// This prevents validation from triggering automatically on initialy loading the form

import { v4 } from 'uuid';
import { InforceInsuranceFormData, InforceInsuranceType, InforceProtectionAmountsType } from '~/interfaces/InsuranceHistoryPageState';
import { getProtectionAmounts } from '.';

// Using null as the empty value triggers validation automatically for some reason
const EMPTY_RADIO_VALUE = '' as unknown as boolean;
const isEmptyRadioValue = (value: boolean) => value === EMPTY_RADIO_VALUE;

const formatInforceInsurancePayload = (formValues: InforceInsuranceFormData): InforceInsuranceType => {
  let protectionAmounts: InforceProtectionAmountsType[] | null = [];

  if (formValues?.willReplaceInsurance === false) {
    protectionAmounts = getProtectionAmounts(formValues);

    // If the length is 0, then no coverages where selected, uncheck the radio value
    if (protectionAmounts.length === 0) {
      protectionAmounts = null;
    }
  }

  if (isEmptyRadioValue(formValues?.willReplaceInsurance)) {
    protectionAmounts = null;
  }

  return ({
    id: formValues?.id ?? v4(),
    isContractIA: isEmptyRadioValue(formValues?.isContractIA) ? null : formValues?.isContractIA,
    isNeedPersonal: isEmptyRadioValue(formValues?.isNeedPersonal) ? null : formValues?.isNeedPersonal,
    willReplaceInsurance: isEmptyRadioValue(formValues?.willReplaceInsurance) ? null : formValues?.willReplaceInsurance,
    isIssuedLast12Months: isEmptyRadioValue(formValues?.isIssuedLast12Months) ? null : formValues?.isIssuedLast12Months,
    noContract: formValues?.isContractIA ? formValues?.noContract : null,
    yearEmission: formValues?.isContractIA && formValues?.yearEmission ? `${formValues?.yearEmission}` : null,
    protectionAmounts,
  });
};

export default formatInforceInsurancePayload;
