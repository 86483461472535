import { enCA, frCA } from 'date-fns/locale';
import { format } from 'date-fns';

const formatEffectiveDate = (date: string, language: string) => {
  const locale = language === 'en' ? enCA : frCA;
  const dateFormat = language === 'en' ? 'MMMM d, yyyy' : 'd MMMM yyyy';
  const dateString = (date || '').split('Z')[0];
  const dateToFormat = new Date(dateString);

  if (date != null) return format(dateToFormat, dateFormat, { locale });
  return null;
};

export default formatEffectiveDate;
