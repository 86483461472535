import PcToolFormModel from '../interfaces/PcToolFormModel';
import { PremiumComparatorPlansModel } from '../interfaces/PremiumComparatorPlansModel';
import createRefreshCoverageOptionsResourcePayload from './createRefreshCoverageOptionsResourcePayload';
import { ANGLAIS, FRANCAIS } from '../constants';
import { PcToolDataResponseModel } from '../interfaces/PcToolDataResponseModel';

const createUpdateSelectedPlanResourcePayload = (selectedPlan: PremiumComparatorPlansModel, locale: string, formValues: PcToolFormModel, data: PcToolDataResponseModel) => {
  const payload = createRefreshCoverageOptionsResourcePayload(formValues, data);
  return ({
    genericPlanCode: selectedPlan?.genericPlanCode,
    pdfPlanCode: selectedPlan?.pdfPlanCode,
    language: locale === 'en' ? ANGLAIS : FRANCAIS,
    ...payload,
  });
};

export default createUpdateSelectedPlanResourcePayload;
