import QuestionModel from '~/interfaces/InsurabilityDeclaration/QuestionModel';
import SetAnswerRequestDto from '../dtos/SetAnswerRequestDto';

export default class SetAnswerRequestDtoBuilder {
  fromValues(question: QuestionModel, values: unknown[]): SetAnswerRequestDto {
    return {
      questionId: question.id,
      answerValue: {
        questionType: question.questionType,
        value: values?.length ? values : null,
      },
    };
  }
}
