import AnswerModel from '~/interfaces/InsurabilityDeclaration/AnswerModel';
import AnswerDto from '../dtos/AnswerDto';

export default class AnswerBuilder {
  toModel(dto: AnswerDto): AnswerModel {
    return new AnswerModel(
      dto.id,
      dto.value,
    );
  }
}
