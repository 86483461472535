import { FC } from 'react';
import { compose } from 'redux';
import { injectTranslations } from 'ia-react-core';

import DrawerFiles, { DrawerFilesProps } from './DrawerFiles';

import fr from './translations/fr';
import en from './translations/en';

export default compose<FC<DrawerFilesProps>>(
  injectTranslations('DrawerFiles', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(DrawerFiles);
