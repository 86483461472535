import { ResourceProps, Resource, WORKFLOW_STATUSES } from 'ia-react-core';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { updateRouteStatus } from '~/App.actions';
import { loadApplicationWithoutReloading, setInsPageValidationMessagesAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';
import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';
import selectTargetRoute from '~/fragments/ApplicationFragment/selectors/selectTargetRoute';
import getStatusFromValidationMessages from '~/fragments/ApplicationFragment/utilities/getStatusFromValidationMessages';
import { ApiResponse, ValidationMessage } from '~/interfaces/ValidationMessage';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';
import { State } from '~/interfaces/State';
import { UPDATE_INSURANCE_HISTORY_RESOURCE_NAME, INSURANCE_HISTORY_FORM_NAME } from '../InsuranceHistoryPage.constants';

export interface PostRequestForResourceProps extends ResourceProps<null, null> {
  validationClientId?: string;
}

const getMessagesForNominee = (messages: ValidationMessage[], nomineeId: string): ValidationMessage[] => messages.filter((message) => message.noParticipant === nomineeId);

export const UpdateInsuranceHistoryResource: FC<PostRequestForResourceProps> = ({ validationClientId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { saleId } = useParams<{ saleId: string }>();
  let intentType: ApplicationIntentType;
  const targetRoute: string = useSelector(selectTargetRoute);
  const currentRoute = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.values?.routePath?.currentRoute);
  const backToHome = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);
  let initateFromLeftMenu = true;
  const updateIndividualUrl =
    getBffUrlForRoute(BffRoute.update_application_insurance_history, { saleId });
  return (
    <Resource
      name={UPDATE_INSURANCE_HISTORY_RESOURCE_NAME}
      url={updateIndividualUrl}
      method="PUT"
      transformRequest={(
        payloadData: { intentType: ApplicationIntentType; initateFromLeftMenu: boolean },
      ) => {
        intentType = payloadData.intentType;
        initateFromLeftMenu = payloadData.initateFromLeftMenu;
        return payloadData;
      }}
      onSuccess={(res) => {
        const { validationMessages } = (res?.data as ApiResponse);
        const messages = validationMessages || [];
        const nomineeValidationMessages = getMessagesForNominee(messages, validationClientId) || [];
        const status = getStatusFromValidationMessages(nomineeValidationMessages);
        if (backToHome) {
          history.block(true);
          history.push('/');
          return;
        }
        if (intentType !== ApplicationIntentType.Save) {
          dispatch(updateRouteStatus(currentRoute, status));
          // TO ADD VALIDATION MESSAGES TO THE REDUX NEW VALUE, SO AUTO SAVE CANNOT VANISH HOISTED WARNINGS
          dispatch(setInsPageValidationMessagesAction('NomineePage', INSURANCE_HISTORY_FORM_NAME, validationMessages, 'NomineePage', validationClientId));
        }
        if (intentType === ApplicationIntentType.SaveAndValidate) {
          if (status !== WORKFLOW_STATUSES.ERROR) dispatch(loadApplicationWithoutReloading(saleId));
          if (status === WORKFLOW_STATUSES.ERROR) {
            history.push(currentRoute);
            // we shouldn't push to targetRoute if the action is initiated from left menu
          } else if (!initateFromLeftMenu) {
            history.push(targetRoute);
          }
        }
      }}
    />
  );
};
