import { RouteDefinitions } from 'ia-react-core';
import React from 'react';
import { CustomPageLoader } from '~/components/CustomPageLoader';
import RouteGuard from '~/utilities/routeGuard';

const LazyCoveragePage = () => <CustomPageLoader loader={() => import('../pages/CoveragesPage')} />;
const LazyInvestorProfilePage = () => <CustomPageLoader loader={() => import('../pages/InvestorProfilePage')} />;
const LazyInvestmentsPage = () => <CustomPageLoader loader={() => import('../pages/InvestmentsPage')} />;
const LazyConceptsPage = () => <CustomPageLoader loader={() => import('../pages/ConceptsPage')} />;
const LazyReportsPage = () => <CustomPageLoader loader={() => import('../pages/ReportsPage')} />;
const LazyResultsPage = () => <CustomPageLoader loader={() => import('../pages/ResultsPage')} />;

const ILLUSTRATION_ROUTES = [
  {
    label: { fr: 'Couverture', en: 'Coverage' },
    path: {
      fr: '/illustration/:saleId/couverture',
      en: '/illustration/:saleId/coverage',
    },
    Component: () => (
      <RouteGuard requiredPermission={['Illustration.All', 'lllustration.All']}><LazyCoveragePage /></RouteGuard>
    ),
  },
  {
    label: { fr: 'InvestorProfile', en: 'InvestorProfile' },
    path: {
      fr: '/illustration/:saleId/investorProfile',
      en: '/illustration/:saleId/investorProfile',
    },
    Component: () => (
      <RouteGuard requiredPermission={['Illustration.All', 'lllustration.All']}><LazyInvestorProfilePage /></RouteGuard>
    ),
  },
  {
    label: { fr: 'Investments', en: 'Investments' },
    path: {
      fr: '/illustration/:saleId/investments',
      en: '/illustration/:saleId/investments',
    },
    Component: () => (
      <RouteGuard requiredPermission={['Illustration.All', 'lllustration.All']}><LazyInvestmentsPage /></RouteGuard>
    ),
  },
  {
    label: { fr: 'Concepts', en: 'Concepts' },
    path: {
      fr: '/illustration/:saleId/concepts',
      en: '/illustration/:saleId/concepts',
    },
    Component: () => (
      <RouteGuard requiredPermission={['Illustration.All', 'lllustration.All']}><LazyConceptsPage /></RouteGuard>
    ),
  },
  {
    label: { fr: 'Rapports', en: 'Reports' },
    path: {
      fr:
      '/illustration/:saleId/rapports',
      en:
      '/illustration/:saleId/rapports',
    },
    Component: () => (
      <RouteGuard requiredPermission={['Illustration.All', 'lllustration.All']}><LazyReportsPage /></RouteGuard>
    ),
  },
  {
    label: { fr: 'Résultats', en: 'Results' },
    path: {
      fr: '/illustration/:saleId/resultats',
      en: '/illustration/:saleId/results',
    },
    Component: () => (
      <RouteGuard requiredPermission={['Illustration.All', 'lllustration.All']}><LazyResultsPage /></RouteGuard>
    ),
  },
] satisfies RouteDefinitions;

export default ILLUSTRATION_ROUTES;
