import { createSelector } from 'reselect';
import { State } from '~/interfaces/State';
import { ValidationMessage } from '~/interfaces/ValidationMessage';

const selectPacpadValidations = createSelector(
  (state: State) => state?.App?.fragments?.ApplicationFragment?.PacPadAgreementPage,
  (PacPadAgreementPage) => PacPadAgreementPage?.validations || [],
);

export function defaultPacpadMessagesSelector(state: State): ValidationMessage[] {
  return state?.App?.fragments?.ApplicationFragment?.PacPadAgreementPage?.validations;
}

export default selectPacpadValidations;
