import { ComponentClass } from 'react';
import { compose } from 'redux';

import { injectTranslations } from 'ia-react-core';

import fr from './translations/fr';
import en from './translations/en';
import CoveragesContent from './CoveragesContent';

export default compose<ComponentClass>(
  injectTranslations('CoveragesContent', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(CoveragesContent);
