import { RequirementViewModel } from '../interfaces/RequirementViewModel';

const getUpdatedPayload = (payload: RequirementViewModel): RequirementViewModel => {
  if (
    payload.investigationReports.length > 0 &&
    payload.investigationReports[0].interventionType === null &&
    Object.values(payload.investigationReports[0].clientContactInformation).every((value) => value === null || value === undefined) &&
    payload.investigationReports[0].isObtainedFromOtherCompany === null
  ) {
    return {
      ...payload,
      investigationReports: [],
      investigationReportsDirective: null,
    };
  }
  return payload;
};
export default getUpdatedPayload;
