import React, { FC, PropsWithChildren } from 'react';
import { StyledBorderBlock } from './BorderBlock.styles';

interface BorderBlockProps extends PropsWithChildren {
  className?: string;
}

const BorderBlock: FC<BorderBlockProps> = ({ children, className }) => (
  <StyledBorderBlock className={className}>
    { children }
  </StyledBorderBlock>
);
export default BorderBlock;
