import React from 'react';

import { SaleFile } from '../interfaces/SaleFile';
import { Stage } from '../constants';

export interface FileInformationCellProps {
  saleFile: SaleFile;
  isChange?: boolean;
  isCase?: boolean;
  isContract?: boolean;
}

const FileInformationCell = ({ saleFile, isChange = false, isCase = false, isContract = false }: FileInformationCellProps) => {
  if (!isChange && (saleFile.numeroDemande !== '' || saleFile.numeroDemande !== null) && (saleFile.progression === Stage.transferee || saleFile.progression === Stage.signature || saleFile.progression === Stage.signee || saleFile.progression === Stage.ceremonieSignatureDebutee)) return saleFile.numeroDemande;
  if (!isChange && (saleFile.numeroContrat == null || saleFile.numeroContrat === '')) return null;
  if (isChange && isCase && (saleFile.numeroDemande == null || saleFile.numeroDemande === '')) return null;
  if (isChange && isContract && (saleFile.numeroContrat == null || saleFile.numeroContrat === '')) return null;

  return (
    <div>
      {!isChange && saleFile.numeroContrat}
      {(isChange && isCase) && saleFile.numeroDemande}
      {(isChange && isContract) && saleFile.numeroContrat}
    </div>
  );
};

export { FileInformationCell };
