import ChangeRequestModel from '~/interfaces/InsurabilityDeclaration/ChangeRequestModel';
import ItemsBuilder from './ItemsBuilder';
import FormBuilder from './FormBuilder';
import ChangeRequestDto from '../dtos/ChangeRequestDto';

export default class ChangeRequestBuilder {
  toModel(dto: ChangeRequestDto): ChangeRequestModel {
    const itemsBuilder = new ItemsBuilder();
    const formBuilder = new FormBuilder();

    const { sections, questions, repeatingSections } = itemsBuilder.buildChangeRequest(dto).getItems();

    return new ChangeRequestModel(
      dto.id,
      dto.forms.map((f) => formBuilder.toModel(f)),
      dto.language,
      sections,
      questions,
      repeatingSections,
      dto.isOipa,
    );
  }
}
