import {
  T2, T3, ClosableSection, T4, ErrorMessage, DateControl, FormRow, FormCol, CheckboxItemControl,
  Hidden,
} from 'ia-react-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateField } from '~/fragments/ApplicationFragment/utilities/fieldFormatters';
import { StyledContentContainer, StyledContractorContainer, StyledContractorDateContent, StyledInvalidDateContainer, StyledFormCol, StyledFormRow, StyledChangeDividentCheckboxContainer, StyledInfoTooltip } from '../Makeechange.styles';
import { isValidYear } from '../utilities/Validators';
import ContractorDetail from './ContractorDetail';

export interface ContractorDetailsProps {
  contractDetails?: {
    nomClient: string | null;
    typeAssurance: string | null;
    estAutoriseServiceApresVente: boolean;
    nomAssurePrincipalPourValidation: string | null;
  };
  clearContractorDataOnCloseContent?: () => void;
  showDobError?: string | null;
  formValue?: {
    contractNumber: string | null;
  };
  formErrors?: any;
  handleDobOnChange?: () => void;
  showInvalid?: boolean;
  isHeadoffice?: boolean;
}

const ContractorDetails = ({ contractDetails, clearContractorDataOnCloseContent, showDobError, formValue, formErrors, handleDobOnChange, showInvalid, isHeadoffice = false }: ContractorDetailsProps) => {
  const { t } = useTranslation('makeEchange');

  return (
    <StyledContentContainer>
      <T2>{t('title')}</T2>
      <T3>{t('subtitle')}</T3>
      <ClosableSection closeButtonLabel="" onClose={clearContractorDataOnCloseContent}>
        <StyledContractorContainer>
          <ContractorDetail title={t('clientname')} value={contractDetails?.nomClient || ''} />
          <ContractorDetail title={t('contractno')} value={formValue?.contractNumber || ''} />
          <ContractorDetail title={t('type')} value={t(contractDetails?.typeAssurance)} />
        </StyledContractorContainer>
        {!contractDetails?.estAutoriseServiceApresVente &&
          <StyledContractorDateContent>
            <T4>{t('datetitle')}</T4>
            {showDobError !== '' &&
              <ErrorMessage error>
                <span>{showDobError}</span>
                {formErrors?.contractorDob?.date && <StyledInvalidDateContainer>{t('inValidDate')}-test</StyledInvalidDateContainer>}
              </ErrorMessage>}
            {showInvalid &&
              <ErrorMessage error>
                <span>{t('inValidDate')}</span>
                <StyledInvalidDateContainer>{t('invalidrange')}</StyledInvalidDateContainer>
              </ErrorMessage>}
            <StyledFormRow>
              {contractDetails?.nomAssurePrincipalPourValidation &&
              <>
                <Hidden md lg xl xxl>
                  <FormCol label={t('name')} className="nomAssurePrincipalPourValidation">
                    {contractDetails?.nomAssurePrincipalPourValidation}
                  </FormCol>
                </Hidden>
                <Hidden sm xs>
                  <FormCol label={t('name')} width="30%" className="nomAssurePrincipalPourValidation">
                    {contractDetails?.nomAssurePrincipalPourValidation}
                  </FormCol>
                </Hidden>
              </>}
              <Hidden md lg xl xxl>
                <StyledFormCol label={t('dob')}>
                  <DateControl
                    name="contractorDob"
                    value=""
                    hideErrorMessages
                    onChange={handleDobOnChange}
                    validators={{
                      notValidYear: isValidYear,
                    }}
                    trim
                    valueToStateFormatters={[formatDateField]}
                  />
                </StyledFormCol>
              </Hidden>
              <Hidden sm xs>
                <StyledFormCol label={t('dob')} width="40%">
                  <DateControl
                    name="contractorDob"
                    value=""
                    hideErrorMessages
                    onChange={handleDobOnChange}
                    validators={{
                      notValidYear: isValidYear,
                    }}
                    trim
                    valueToStateFormatters={[formatDateField]}
                  />
                </StyledFormCol>
              </Hidden>
            </StyledFormRow>
          </StyledContractorDateContent>}
      </ClosableSection>
      {!isHeadoffice &&
        <>
          <FormRow>
            <FormCol col={12}>
              <CheckboxItemControl name="holderconsent" label={t('holderconsent')} />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol col={12}>
              <StyledChangeDividentCheckboxContainer>
                <CheckboxItemControl name="clientidentity" label={t('clientidentity')} />
                <StyledInfoTooltip
                  tip={
                    <>
                      {t('clientidentityTip1')}
                      <ul>
                        <li>{t('clientidentityTip2')}</li>
                        <li>{t('clientidentityTip3')}</li>
                        <li>{t('clientidentityTip4')}</li>
                      </ul>
                      {t('clientidentityTip5')}
                    </>
                  }
                />
              </StyledChangeDividentCheckboxContainer>
            </FormCol>
          </FormRow>
        </>}
    </StyledContentContainer>
  );
};

export default ContractorDetails;
