export default {
  quit: 'Quit',
  save: 'Save',
  cancel: 'Cancel',
  download: 'Print',
  delete: 'Delete',
  no: 'no',
  yes: 'yes',
  insured: 'For the insured(s):',
  seeError: 'See error',
  seeWarning: 'See warning',
  error: 'Error',
  warning: 'Warning',
  info: 'Info',
};
