import { Reducer } from 'react';
import { Action } from 'redux';
import { SET_DOWNLOAD_TYPE } from '../MakeEchangeModal.actions';
import { setDownloadReducer } from './MakeEchangeModal.reducer';
import { DownloadState } from '../DownloadState';

const mapping: Record<string, Reducer<any, any>> = {
  [SET_DOWNLOAD_TYPE]: setDownloadReducer,
};

export default function reducer(state: DownloadState, action: Action) {
  const typeReducer = mapping[action.type] ?? ((x) => x);
  return typeReducer(state, action);
}
