import { Progression, SectionDisplayType } from './enums';

export default class SectionModel {
  constructor(
    public id: string,
    public name: string,
    public title: string,
    public isActive: boolean,
    public progression: Progression,
    public sectionDisplayType: SectionDisplayType,
    public canRepeat?: boolean,
    public message?: string,
  ) {}
}
