import { LETTERS_SYMBOLS, LETTERS_NUMBERS, NUMBERS_SYMBOLS, LETTERS, NUMBERS } from '~/fragments/ApplicationFragment/constants/regex';
import { QuestionDisplayType } from '~/interfaces/InsurabilityDeclaration/enums';

export default function validateValue(displayType: QuestionDisplayType, value: any) {
  function validator() {
    if (displayType === QuestionDisplayType.LettersNumbers) {
      if (LETTERS_NUMBERS.test(value)) {
        return false;
      }
    } else if (displayType === QuestionDisplayType.LettersSymbols) {
      if (LETTERS_SYMBOLS.test(value)) {
        return false;
      }
    } else if (displayType === QuestionDisplayType.NumbersSymbols) {
      if (NUMBERS_SYMBOLS.test(value)) {
        return false;
      }
    } else if (displayType === QuestionDisplayType.Letters) {
      if (LETTERS.test(value)) {
        return false;
      }
    } else if (displayType === QuestionDisplayType.Numbers) {
      if (NUMBERS.test(value)) {
        return false;
      }
    }

    return true;
  }
  return validator();
}
