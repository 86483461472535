export default {
  back_to_products: 'Retour aux produits',
  add_insured: 'Sélectionner une protection pour créer une illustration',
  years: 'ans',
  choose: 'Choisir',
  create_illustration_text: 'Sélectionner une protection pour le nouvel assuré',
  change_base_coverage: 'Séléctionner une protection pour changer la protection de base',
  number_between_10_and_40_message: 'La valeur doit être entre 10 et 40.',
  learn_more_about: 'En savoir plus sur',
  coverage: 'couverture',
  createNewScenarioDescription: 'Sélectionner une protection pour créer un scénario',
  version: 'Version',
};
