import IndividualModel from '~/fragments/ApplicationFragment/interfaces/IndividualModel';
import { AccountHolder } from '~/interfaces/PacPadAgreementPageState';
import { v4 as uuid } from 'uuid';
import { ACCOUNT_HOLDER_OPTION_OTHER_VALUE, OriginalId } from '../constants';

const formatAccountHolder = (accountHolder: AccountHolder & OriginalId, individuals: IndividualModel[], isPersonalBanking: string | boolean) => {
  const individual = individuals?.find((x) => x.id === accountHolder.id);
  if (individual && accountHolder.id !== ACCOUNT_HOLDER_OPTION_OTHER_VALUE && !!isPersonalBanking) {
    return {
      id: individual.id,
      firstName: individual.firstName,
      lastName: individual.lastName,
      name: individual.name,
      isPerson: individual.isPerson,
    };
  }
  return {
    id: accountHolder.originalId ?? uuid(),
    firstName: accountHolder.firstName,
    lastName: accountHolder.lastName,
    name: isPersonalBanking ? `${accountHolder.firstName} ${accountHolder.lastName}` : accountHolder.name,
    isPerson: !!isPersonalBanking,
  };
};

export default formatAccountHolder;
