import styled from 'styled-components';

export const StyledErrorContainer = styled.div`
ul {
  li {
    margin-bottom: 0.25rem;
  }
  div::before {
    display: none;    
  }
}
.warning.caution {
  border-color: #E2C300;
  background: #FFFCE6;
}`;

export const StyledMessageList = styled.div`
  list-style: none;
  padding-bottom: 8px;
`;
