import styled from 'styled-components';
import { ActionBar, IconButton, InternalLink, MoreMenu, Select, TertiaryButton } from 'ia-react-core';

export const StyledMainLinksInternalLinkContainer = styled.nav`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const StyledMainLinksInternalLink = styled(InternalLink)`
  font-family: Montserrat;
  font-size: 21px;
  color: #6B6B6B;
  font-weight: 700;
  text-decoration: none !important;

  &.active {
    color: #003DA5;
  }

  &:hover {
    color: ${({ theme }) => theme?.colors?.blue5};
  }
`;

export const StyledMainLinksSelect = styled(Select)`
  width: 100%;
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const StyledMainLinksActionBar = styled(ActionBar)`
  display: inherit;
  text-align: end;
`;

export const StyledMainLinksDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-end;

  @media (max-width: 767.99px) {
    flex-direction: column;
    padding: 0 15px;
    margin-top: 10px;
    gap: 10px;
  }
`;

export const StyledMainLinksButton = styled(TertiaryButton)`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

export const StyledMainLinksIconButton = styled(IconButton)`
  background: #003DA5;
  color: #FFF;
  border-radius: 4px;
`;

export const StyledMainLinksMoreMenu = styled(MoreMenu)`
  background: #003DA5;
  color: #FFF;
  border-radius: 4px;  
`;

export const StyledMainLinksContainer = styled.div``;

export const StyledMainLinksMobileWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const StyledMainLinksMobileSelectWrapper = styled.div`
  width: 250px;
`;
