import { FC } from 'react';
import { injectTranslations } from 'ia-react-core';
import { compose } from 'redux';

import NotificationDrawer, { NotificationDrawerProps } from './NotificationDrawer';

import fr from './translations/fr';
import en from './translations/en';

export default compose<FC<NotificationDrawerProps>>(
  injectTranslations('NotificationDrawer', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(NotificationDrawer);

export * from './NotificationDrawer';
