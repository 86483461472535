import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { UpdateRenderCheckBox } from '../ChangeRequest.actions';

export function updateRenderCheckBoxReducer(state: ApplicationFragmentState, action: Action) {
  const { checkboxDetails } = action as UpdateRenderCheckBox;
  return {
    ...state,
    values: {
      ...state.values,
      [checkboxDetails.formName]: {
        [checkboxDetails.id]: checkboxDetails.checked,
      },
    },
  };
}
