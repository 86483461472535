export default {
  deleteButton: 'Supprimer',
  deleteBtnMsg: 'Supprimer {0} dossiers',
  all: 'Tous',
  notTransmitted: 'Non transmis',
  transmitted: 'Transmis',
  pendingSignature: 'Signature',
  requiredAction: 'Action requise',
  noSaleFiles: 'Aucun dossier',
  searchInputPlaceholder: 'Rechercher',
  noFilesTitle: 'Aucun dossier',
  noFilesMessage: ' Pour commencer, créez',
  showInformation: 'Information',
  showModifiedDate: 'Modifié',
  showCreatedDate: 'Créé',
  showStatus: 'Statut',
  fileDownloadModal: {
    yes: 'Oui',
    no: 'Non',
    confirmCancelContentOne: 'Ce dossier existe déjà sur votre poste. Toutes les informations saisies seront effacees et remplacees par le dossier télécharge. Êtes-vous certain de vouloir continuer?',
    title: "Téléchargement d'un dossier",
    infoContent: "À la réception d'une clé de partage, il faut la saisir dans l'encradré et cliquer sur OK . Le dossier reçu s'ouvrira automatiquement et sera sauvegardé dans votre liste de nouvelles affaires.",
    sharedKeyTextLabel: 'Entrer la clé de partage',
    ok: 'OK',
    cancel: 'Annuler',
    anchorError: 'Voir l\'erreur',
    Error: 'ERREUR',
    errors: {
      invalidKey: 'La clé de partage est invalide',
      downloadFailError: 'Le téléchargement du dossier partagé a échoué.',
    },
  },
  fileSharingModal: {
    title: 'Partage du dossier',
    infoContent: "Pour partager ce dossier, il vous suffit d'envoyer la clé de partage par courriel au destinataire de votre choix. À la réception de la clé, celui-ci pourra télécharger votre dossier sur son ordinateur en utilisant l'outil Télécharger un dossier.",
    sharedKeyTextLabel: 'Clé de partage *',
    reminder: '* Rappel',
    reminderContent1: "Les informations d'un client ne peuvent être partagées qu'avec des personnes en ayant absolument besoin dans l'exercice de leurs fonctions. Lorsque vous donnez à une personne accès aux informations d'un client, il est de votre responsabilité de vous assurer que cet accès est nécessaire aux tâches imparties à cette personne.",
    reminderContent2: "Le conseiller effectuant la vente demeure entièrement responsable de l'exactitude et de la fiabilité des informations recueillies et saisies par une autre personne. De plus, rappelons que l'analyse de l'information et le conseil en lien avec la vente ne peuvent être délégués.",
    close: 'Fermer',
    copyToClipboardButton: 'Copier dans le presse papier',
    fileShareFailMessage: 'Le partage du dossier a échoué.',
  },
  newBusiness: 'Nouvelles affaires',
  changeRequest: 'Demande de changements',
};
