import React from 'react';
import { useHistory, useParams } from 'react-router';
import DynamicFormLayout from '~/layouts/DynamicFormLayout';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '~/interfaces/AppState';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { requestResourceByName } from 'ia-react-core';
import DynamicFormContent from './components/DynamicFormContent/DynamicFormContent';
import RemoveChangeRequestForm from '../../Resources/RemoveChangeRequestForm';
import getPathName from './utilities/getPathName';

const DynamicFormLoader = () => {
  const history = useHistory();
  const { formId } = useParams<{ formId: string }>();
  const { formName } = useParams<{ formName: string }>();
  const { saleId } = useParams<{ saleId: string }>();

  const changeRequest = useSelector((s: AppState) => s.App?.fragments?.ApplicationFragment?.changeRequest);
  const dispatch = useDispatch();

  const quitClick = () => {
    const param = { saleId: changeRequest?.id, formId };
    const url = getBffUrlForRouteWithoutQP(BffRoute.change_request_form_by_id, param, false);
    dispatch(requestResourceByName('resources.remove_change_request_form', param, { method: 'PUT', url }));
  };

  const onCancelSuccess = () => {
    const path = getPathName(formName, changeRequest?.language);
    history.push(`/application/${saleId}/${path}`);
  };

  return (
    <>
      <DynamicFormLayout onQuitClick={quitClick}>
        <DynamicFormContent saleId={saleId} formId={formId} formName={formName} />
      </DynamicFormLayout>
      <RemoveChangeRequestForm saleId={saleId} formId={null} method="PUT" onSuccess={onCancelSuccess} />
    </>
  );
};

export default DynamicFormLoader;
