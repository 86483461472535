import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ILLUSTRATION_NAV_MAPPING } from '~/constants/CREATE_ILLUSTRATION';
import { InsuranceCategory } from '~/interfaces/InsuranceCategory';
import { State } from '~/interfaces/State';
import isHeadOfficeMode from '~/fragments/ApplicationFragment/utilities/isHeadOfficeMode';
import { CategoryProduct } from '~/interfaces/CategoryProduct';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { SideNavigationMenu } from '~/components/SideNavigationMenu';
import { Dropdown, Hidden, Visible } from 'ia-react-core';
import { StyledCreateIllustrationSideNavContainer, StyledCreateIllustrationSideNavSection, StyledCreateIllustrationSideNavTitle } from './CreateIllustrationSideNav.styles';
import { addActiveClass, getTranslatedValue } from '../../utilities';
import SaveLastProductCategoryTypeResource from './resources/SaveLastProductCategoryTypeResource';
import AppVersioning from '../AppVersioning';

export interface CreateIllustrationSideNavProps {
  data: InsuranceCategory[];
  onClick: Function;
  currentPage: number;
  productId?: number;
  isHome?: boolean;
}

const CreateIllustrationSideNav: FC<CreateIllustrationSideNavProps> = ({ data, onClick, currentPage, productId, isHome }) => {
  const locale = useSelector((state: State) => state?.language?.locale);
  const userRole = useSelector((state: State) => state?.App?.data?.permissions?.role);
  const isHeadOffice = useMemo(() => isHeadOfficeMode(userRole), [userRole]);
  const isParentModal = productId !== undefined;
  const getCategoryIndex = (pid: number) => pid <= 6 ? 0 : 1;
  const categoryIndex = isParentModal ? getCategoryIndex(productId) : undefined;
  const updateBaseCoverage = useSelector((state: State) => state?.App?.fragments?.IllustrationFragment?.Pages?.CoveragesPage?.modals?.createIllustration?.data?.updateBaseCoverage);
  const filteredCategory = useMemo(() => categoryIndex !== undefined ? [data[categoryIndex]] : data, [categoryIndex, data]);

  const filteredProducts = isParentModal ? filteredCategory[0]?.categoriesProduit.filter((product: CategoryProduct) => product?.description === ILLUSTRATION_NAV_MAPPING[productId]) : filteredCategory[0]?.categoriesProduit;
  const getIndex = (index: number, incrementBy: number) => index + incrementBy;

  const getProducts = useCallback((filteredProductsData: CategoryProduct[], productsData: CategoryProduct[]) => isParentModal ? filteredProductsData : productsData, [isParentModal]);

  const [saveLastProductUrl, setSaveLastProductUrl] = useState(null);

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, typeCategorie: string, pageId: number) => {
    onClick(pageId);
    setSaveLastProductUrl(getBffUrlForRouteWithoutQP(BffRoute.save_last_product_category_type, { productType: typeCategorie }));
    if (e) e.preventDefault?.();
  }, [onClick]);

  const navData = useMemo(() => filteredCategory?.map((category: InsuranceCategory) => (
    {
      category,
      items: getProducts(filteredProducts, category?.categoriesProduit)
        ?.filter((_, idx: number) => !(updateBaseCoverage && idx === 4))
        .map((product: CategoryProduct, idx: number) => {
          if (category?.ordreAffichage === 1) {
            return {
              to: productId?.toString() ?? getIndex(idx, 1).toString(),
              onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e, product.typeCategorie, productId ?? getIndex(idx, 1)),
              className: addActiveClass(currentPage, productId ?? getIndex(idx, 1)),
              'data-testid': `nav-item-${getIndex(idx, 1)}`,
              children: getTranslatedValue(product, locale),
            };
          }

          if (category?.ordreAffichage === 2) {
            return {
              to: productId?.toString() ?? getIndex(idx, 7).toString(),
              onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e, product.typeCategorie, productId ?? getIndex(idx, 7)),
              className: addActiveClass(currentPage, productId ?? getIndex(idx, 7)),
              children: getTranslatedValue(product, locale),
            };
          }

          return null;
        }),
    }
  )), [currentPage, filteredCategory, filteredProducts, getProducts, handleClick, locale, productId, updateBaseCoverage]);

  const flatItems = useMemo(() => navData?.map((x) => x?.items).flat(), [navData]);

  const dropdownOptions = useMemo(
    () =>
      flatItems?.map((item, index) => ({
        label: item.children,
        value: index,
      })),
    [flatItems],
  );

  const [dropdownValue, setDropdownValue] = useState(0);

  const handleDropdownChange = useCallback((index: number) => {
    const item = flatItems?.[index];
    if (item) {
      item?.onClick?.({} as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
    }
    setDropdownValue(index);
  }, [flatItems]);

  return (
    <StyledCreateIllustrationSideNavContainer>
      <SaveLastProductCategoryTypeResource saveLastProductUrl={saveLastProductUrl} />

      {
        !isHome &&
        <Visible xs sm>
          <Dropdown noEmptyValue width="100%" value={dropdownValue} options={dropdownOptions} onValueChange={handleDropdownChange as any} />
        </Visible>
      }

      <Hidden xs={!isHome} sm={!isHome}>
        {
          navData?.map(({ category, items }, index) => (
            <StyledCreateIllustrationSideNavSection key={`${category?.description}${index}`}>
              <StyledCreateIllustrationSideNavTitle>{getTranslatedValue(category, locale)}</StyledCreateIllustrationSideNavTitle>
              {
                category?.categorieFamille === 'Produits' && isHeadOffice && isHome &&
                  <AppVersioning />
              }
              <SideNavigationMenu items={items} />
            </StyledCreateIllustrationSideNavSection>
          ))
        }
      </Hidden>

    </StyledCreateIllustrationSideNavContainer>
  );
};

export default CreateIllustrationSideNav;
