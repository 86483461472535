import { Col } from 'ia-react-core';
import styled from 'styled-components';

export const StyledFieldBox = styled(Col)`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0px;
`;

export const StyledQuestionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: pre-line;
`;

export const StyledQuestionContainer = styled.div`
  &:last-of-type ${StyledFieldBox}:last-of-type{
    margin-bottom: 0;
  }
  margin-bottom: 16px;
  border-radius: 4px;
  
  &.notice, &.warning{
    border: solid 1px ${({ theme }) => theme.colors.orange2};
    background-color: ${({ theme }) => theme.colors.orange3};
  }

  &.error{
    border: solid 1px ${({ theme }) => theme.colors.red14};
    background-color: ${({ theme }) => theme.colors.red15};
  }
`;

export const StyledQuestionLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSize.base}px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
`;
