import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { P, PrimaryButton, Resource, SecondaryButton, Spacing, WarningModal, closeModalByName, requestResourceByName } from 'ia-react-core';
import { useParams } from 'react-router';
import useScenario from '~/fragments/IllustrationFragment/hooks/useScenario';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import constructScenarioActionName from '~/utilities/constructScenarioActionName';
import { RESET } from '~/constants/APP_CONSTANTS';
import { StyledScenarioNavResetActionBar } from './ScenarioNavResetModal.styles';
import { ScenarioNavResetModalProps } from './interfaces/ScenarioNavResetModalProps';

const ScenarioNavResetModal: FC<ScenarioNavResetModalProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('App');
  const { saleId } = useParams<{ saleId: string }>();
  const scenario = useScenario();
  const url = getBffUrlForRoute(BffRoute.reset_scenario, { saleId, scenarioId: scenario?.idDemande });
  const scenarioActionStorageName = constructScenarioActionName(saleId);
  const resetScenarioIdStorageName = `reset-scenario-id-${saleId}`;

  const resetResource = (
    <Resource
      name="resetScenario"
      url={url}
      method="PUT"
      onSuccess={() => {
        dispatch(requestResourceByName('resources.scenarios'));
        localStorage.setItem(scenarioActionStorageName, RESET);
        localStorage.setItem(resetScenarioIdStorageName, scenario?.idDemande);
      }}
    />
  );

  return (
    <>
      {resetResource}
      <WarningModal
        name="confirmResetScenario"
        modalTitle={t('resetScenarioTitle')}
        shouldCloseOnOverlayClick={false}
      >
        <Spacing m-b={16}>
          <P>{t('resetScenarioDesc1')}</P>
        </Spacing>

        <StyledScenarioNavResetActionBar>
          <PrimaryButton
            onClick={() => dispatch(requestResourceByName('resources.resetScenario'))}
          >
            {t('yes')}
          </PrimaryButton>
          <SecondaryButton
            onClick={() => dispatch(closeModalByName('modals.confirmResetScenario'))}
          >
            {t('no')}
          </SecondaryButton>
        </StyledScenarioNavResetActionBar>
      </WarningModal>
    </>
  );
};

export default ScenarioNavResetModal;
