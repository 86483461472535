const prefix = 'SummaryPage';
export const CLOSE_SAVE_SALE_FILE_MODAL = `${prefix}.CLOSE_SAVE_SALE_FILE_MODAL` as const;
export const OPEN_SAVE_SALE_FILE_MODAL = `${prefix}.OPEN_SAVE_SALE_FILE_MODAL` as const;
export const closeSaveFileModal = () => ({
  type: CLOSE_SAVE_SALE_FILE_MODAL,
});
export const openSaveFileModal = () => ({
  type: OPEN_SAVE_SALE_FILE_MODAL,
});
export type OpenSaveFileModal = ReturnType<typeof openSaveFileModal>;
export type CloseSaveFileModal= ReturnType<typeof closeSaveFileModal>;
