import { injectReducer } from 'ia-react-core';
import { FC } from 'react';
import { compose } from 'redux';

import { FoldersPageState } from '~/interfaces/FoldersPageState';
import { GlobalAction } from '~/interfaces/GlobalAction';

import FoldersPage, { FoldersPageProps } from './FoldersPage';
import FoldersPageReducer from './FoldersPage.reducer';

const initialState = {};

export default compose<FC<FoldersPageProps>>(
  injectReducer<FoldersPageProps, FoldersPageState, GlobalAction>({
    key: 'pages.FoldersPage',
    reducer: FoldersPageReducer,
    initialState,
    useScope: true,
    cleanState: true,
  }),
)(FoldersPage);
