import {
  Form,
  HiddenControl,
  P,
  PrimaryButton,
  Resource,
  RouteDefinition, SecondaryButton, WORKFLOW_STATUSES, WarningModal, WorkflowRouteDefinition, WorkflowRouteSectionDefinition, WorkflowStatus, closeModalByName, openModalByName, requestResourceByName, useScopedSelector,
} from 'ia-react-core';
import React, { FC, PropsWithChildren, useCallback, useMemo, useEffect, useState, ReactNode, useRef } from 'react';
import { applicationRoutesRecords } from '~/constants/APPLICATION_ROUTES';
import { useHistory } from 'react-router-dom';
import { matchPath, useLocation, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '~/interfaces/State';
import { setRoutes } from '~/App.actions';
import { AppState } from '~/interfaces/AppState';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import { LinkedApplicationFormData } from '~/interfaces/LinkedApplicationsPageState';
import { BillingPageState } from '~/interfaces/Billing/BillingPageState';
import { AxiosResponse } from 'axios';
import { BffRoute, ValidationApi, getBffUrlForRoute, getRelativeUrlForRoute, relativeRoute } from '~/utilities/bffHelper';
import { useTranslation } from 'react-i18next';
import selectBillingValidationMessages from '~/fragments/ApplicationFragment/selectors/selectBillingValidationMessages';
import ChangesValidationResource from '~/fragments/SharedLibrary/Components/RenderCheckBox/Components/ChangesValidationResource';
import ValidationResources from '~/fragments/ChangeFragment/pages/Validation/Resources/ValidationResources';
import { ChangeRequestState, ValidateMessage, ValidateRoot } from '~/fragments/SharedLibrary/interfaces/State';
import selectSignature from '~/fragments/SharedLibrary/selectors/selectSignatureConfigure';
import { SignatureMode } from '~/fragments/SharedLibrary/interfaces/SignatureState';
import HeadOfficeValidationResource from '~/fragments/SharedLibrary/Components/RenderCheckBox/Components/HeadOfficeValidationResource';
import SummaryValidationResource from '~/fragments/SharedLibrary/Components/RenderCheckBox/Components/SummaryValidationResource';
import { dispatchResourceByName } from '~/utilities/dispatchResourceByName';
import { ValidationMessageLevel } from '~/interfaces/ValidationMessageLevel';
import _ from 'lodash';
import { IndividualName, ValidationPageCorrectedValues, ValidationPageState } from '~/interfaces/ValidationPageState';
import { PAGE_ID_ECHANGE_CHANGEREQUEST } from '~/constants/PAGE_IDS';
import getTextBasedOnLocale from '~/utilities/getTextBasedOnLocale';
import selectNominees from '../selectors/selectINominees';
import { Locale, useCurrentLocale } from './useCurrentLocale';
import selectTargetRoute from '../selectors/selectTargetRoute';
import SaveOrValidateAdvisorResource from '../resources/SaveOrValidateAdvisorResource';
import selectRequestFor from '../selectors/selectRequestFor';
import UpdateBeneficiaryResource from '../pages/NomineePage/pages/BeneficiaryPage/resources/UpdateBeneficiaryResource';
import selectBeneficiaries from '../selectors/selectBeneficiaries';
import advisorApiCall from '../api/advisorApiCall';
import beneficiaryApiCall from '../api/beneficiaryApiCall';
import getHighestPriorityStatus from '../utilities/getHighestPriorityStatus';
import generateWorkflowRoutesForAnNominee from '../utilities/generateWorkflowRoutesForAnNominee';
import updateApplicationRoutes from '../utilities/updateApplicationRoutes';
import { generateInitialRoutes } from '../utilities/generateInitialRoutes';
import ENGLISH_ROUTES from '../constants/englishRoutes';
import FRENCH_ROUTES from '../constants/frenchRoutes';
import ExtendedWorkflowRouteDefinition from '../interfaces/ExtendedWorkflowRouteDefinition';
import { REQUIREMENT_FORM_NAME } from '../pages/RequirementsPage/constants/ConstantNames';
import selectIndividuals from '../selectors/selectIndividuals';
import requirementApiCall from '../api/requirementApiCall';
import { Suppliers } from '../pages/RequirementsPage/interfaces';
import linkedApplicationsApiCall from '../api/linkedApplicationsApiCall';
import billingApiCall from '../api/billingApiCall';
import selectLinkedApplicationsValidation from '../selectors/selectLinkedApplicationsValidation';
import headOfficeApiCall from '../api/headOfficeApiCall';
import coverageApiCall from '../api/coverageApiCall';
import { useCurrentNominee } from '../pages/NomineePage/utils/nominee.utils';
import insuranceHistoryApiCall from '../api/insuranceHistoryApiCall';
import selectBeneficiaryValidationsMessages from '../selectors/selectBeneficiaryValidationsMessages';
import selectAdvisorValidation from '../selectors/selectAdvisorValidation';
import { ApplicationIntentType, BILLING_FORM_NAME } from '../constants';
import pacPadApiCall from '../api/pacPadApiCall';
import ValidateBillingResource from '../resources/ValidateBillingResource';
import UpdateBankingResource from '../resources/UpdateBankingResource';
import UpdateBillingResource from '../resources/UpdateBillingResource';
import { UpdateLinkedApplicationsResource } from '../pages/LinkedApplicationsPage/resources/UpdateLinkedApplicationsResource';
import { UpdateInsuranceHistoryResource } from '../pages/NomineePage/pages/InsuranceHistoryPage/resources/UpdateInsuranceHistoryResource';
import { UpdateRequirementsResource } from '../pages/RequirementsPage/ResourceLoader/UpdateRequirementsResource';
import SaveOrValidateHeadOfficeResource from '../resources/SaveOrValidateHeadOfficeResource';
import selectHeadOfficeValidation from '../selectors/selectHeadOfficeValidation';
import UpdateSpecialInstructionsResource from '../pages/NomineePage/pages/SpecialInstructionsPage/resources/UpdateSpecialInstructionsResource';
import { selectSpecialInstructionsValidations } from '../selectors/selectSpecialInstructionsValidations';
import specialInstructionsApiCall from '../api/specialInstructionsApiCall';
import { selectInsuranceHistoryValidations } from '../selectors/selectApplicationValidationsMessages';
import DoiApiCall from '../api/doiApiCall';
import ValidateDOIResource from '../resources/ValidateDOIResource';
import selectDOIValidationsMessages from '../selectors/selectDOIValidationsMessages';
import selectIdentificationValidations from '../selectors/selectIdentificationValidations';
import identificationApiCall from '../api/identificationApiCall';
import selectProductType from '../selectors/selectProductType';
import UpdateIndividualResource from '../pages/NomineePage/pages/IdentificationPage/ResourceLoader/UpdateIndividualResource';
import selectRequirementsPageValidations from '../selectors/selectRequirementsPageValidation';
import { selectCurrentApplicant, selectValidationMessages } from '../pages/ApplicantPage/selectors/selectApplicantForm';
import getInitialApplicantFormValues from '../pages/ApplicantPage/utilities/getInitialApplicantFormValues';
import applicantApiCall from '../api/applicantApiCall';
import ApplicantResourceLoader from '../pages/ApplicantPage/resources/ApplicantResourceLoader';
import { ApplicationNavRoutesContext } from './useApplicationNavRoutes';
import selectPacpadValidations from '../selectors/selectPacpadValidations';
import updateNomineeRouteStatuses from '../utilities/updateNomineeRouteStatuses';
import updateRouteStatuses from '../utilities/updateRouteStatuses';
import PAGE_NAMES from '../constants/pageNames';
import changesApiCall from '../api/changesApiCall';
import validationEchangeApiCall from '../api/validationEchangeApiCall';
import signatureModeEchangeApiCall from '../api/signatureModeEchangeApiCall';
import selectSignatureDetails from '../selectors/selectSignatureDetails';
import signatureElectronicApiCall from '../api/signatureElectronicApiCall';
import signaturePaperApiCall from '../api/signaturePaperApiCall';
import SignaturePaperSaveResource from '../pages/SignaturePage/pages/SignatureFollowup/components/SignaturePaper/resources/SignaturePaperSaveResource';
import StartCeremonyResource from '../pages/SignaturePage/resources/StartCeremonyResource';
import { generateInitialRoutesEChanges } from '../utilities/generateInitialRoutesEChange';
import UpdateValidationNames from '../pages/ValidationPage/resources/UpdateValidationNames';
import { PostPreferentialPricingResource } from '../pages/CoveragesPage/resources/PostPreferentialPricingResource';
import { CoverageDetails } from '../pages/CoveragesPage/models/CoverageDetails';
import { POST_PREFERENTIAL_PRICING_RESOURCE } from '../pages/CoveragesPage/utilities/constants';
import { InsuredDetail } from '../pages/CoveragesPage/models/InsuredDetail';
import getWorkflowStatus from './utilities/getWorkflowStatus';
import Route from '../interfaces/Route';

const ApplicationNavRoutesProvider: FC<PropsWithChildren> = ({ children }) => {
  const saleIdRef = useRef('');
  const { saleId } = useParams<{ saleId: string; clientId: string }>();
  saleIdRef.current = saleId;
  const history = useHistory();
  const locale = useCurrentLocale();
  const homeClicked = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);
  const nominees = useSelector(selectNominees);
  const currentNominee = useCurrentNominee();
  const productValue = useSelector(selectProductType);
  const [activeRoute, setActiveRoute] = useState(history?.location?.pathname);
  const [lastVisitedRoute, setLastVisitedRoute] = useState('');
  const [lastStatuses, setLastStatuses] = useState<Record<string, WorkflowStatus>>({});
  const [nomineeId, setNomineeId] = useState('' as string);
  const userRole = useSelector((state: State) => state?.App?.data?.permissions?.role);
  const { t } = useTranslation('Application');
  const dispatch = useDispatch();
  const isSummaryRoute = (route: WorkflowRouteDefinition) => route.label && (route.label as Route['label']).en === 'Summary';
  const isInactiveStatus = (route: WorkflowRouteDefinition) => (activeRoute?.includes(ENGLISH_ROUTES.SUMMARY) || activeRoute?.includes(FRENCH_ROUTES.SUMMARY)) && !isSummaryRoute(route) &&
  !activeRoute?.includes('echange');
  const routesFromState = useSelector((state: AppState) => state.App?.fragments?.ApplicationFragment?.routes);
  const summaryData = useSelector((s: AppState) => s.App?.fragments?.ApplicationFragment?.data?.change_request_summary);
  const routes = useMemo(() => routesFromState?.map((route: any) => {
    if (route?.label?.en === 'Application(s)') {
      return {
        ...route,
        routes: summaryData?.transactions?.length === 0 ? [] : route?.routes,
      };
    }
    if (route?.label?.en === 'Signature') {
      return {
        ...route,
        routes: summaryData?.transactions?.length === 0 ? [] : route?.routes,
      };
    }
    return route;
  }) || [], [routesFromState, summaryData]);
  const [responseData, setResponseData] = useState(null);
  const applicantOrInsuredIndividuals = useSelector(selectIndividuals)?.filter((individual) => individual.isInsured === true || individual.isApplicant === true);
  const suppliers: Suppliers[] = useScopedSelector<Suppliers[]>('data.suppliers1');

  const advisorValidationMessages = useSelector(selectAdvisorValidation);
  const advisorRequestForData = useSelector(selectRequestFor);
  const advisorFormValues = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.AdvisorPage?.values?.employeeRequest);

  const identificationValidationMessages: Array<ValidationMessage> = useSelector(selectIdentificationValidations);

  const identificationFormValues = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.NomineePage?.pages?.IdentificationPage?.values?.identification);

  const beneficiaries = useSelector(selectBeneficiaries);
  const beneficiaryFormvalues = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.NomineePage?.pages?.BeneficiaryPage?.values?.beneficiaries);
  const beneficiaryValidationMessages = useSelector(selectBeneficiaryValidationsMessages);

  const doiValidationMessages = useSelector(selectDOIValidationsMessages);
  const coverages: CoverageDetails = useScopedSelector('coverage');
  const headOfficeFormValues = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.HeadOfficePage?.values?.headOffice);

  const headOfficeValidationMessages = useSelector(selectHeadOfficeValidation);

  const billingFormvalues = useScopedSelector<BillingPageState>(`values.${BILLING_FORM_NAME}`);
  const billingValidationMessages: Array<ValidationMessage> = useSelector(selectBillingValidationMessages);

  const pacPadFormvalues = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.PacPadAgreementPage?.values?.pacPadAgreement);
  const bankingRequestId = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.banking?.requestId);
  const pacPadValidationMessages: Array<ValidationMessage> = useSelector(selectPacpadValidations);

  const linkedApplicationsFormvalues: LinkedApplicationFormData = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.LinkedApplicationsPage?.linkedApplications);
  const linkedApplicationsValidationMessages: Array<ValidationMessage> = useSelector(selectLinkedApplicationsValidation);

  const requirementFormvalues = useScopedSelector(`values.${REQUIREMENT_FORM_NAME}`);
  const requirementValidationMessages: Array<ValidationMessage> = useSelector(selectRequirementsPageValidations);

  const insuranceHistoryFormData = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.NomineePage?.pages?.InsuranceHistoryPage?.values?.insuranceHistory);
  const insuranceHistoryValidationMessages: Array<ValidationMessage> = useSelector(selectInsuranceHistoryValidations);

  const instructions = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.NomineePage?.SpecialInstructionsPage?.specialInstructions);
  const specialInstructionsValidations: Array<ValidationMessage> = useSelector(selectSpecialInstructionsValidations);

  const currentApplicantState = useSelector(selectCurrentApplicant);
  const applicantValues = useMemo(() => getInitialApplicantFormValues(currentApplicantState), [currentApplicantState]);
  const applicantValidationMessages = useScopedSelector(selectValidationMessages);

  const validationResults = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.ValidationPage?.validationResults);

  const originalFormDataValidation = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.ValidationPage?.data?.GET_VALIDATION_NAMES_RESOURCE_NAME);
  const validationformValues: ValidationPageCorrectedValues = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.ValidationPage?.values?.validationOfNames);
  const data: ValidationPageState = _.cloneDeep(originalFormDataValidation);
  data?.individualNames?.forEach((individual: IndividualName) => {
    const { individualId } = individual;
    individual.correctedLastName = validationformValues?.[`lastNameCorrected_${individualId}`] || null;
    individual.correctedFirstName = validationformValues?.[`firstNameCorrected_${individualId}`] || null;
  });
  if (data?.demandDetails && data?.demandDetails?.length > 0) {
    data.demandDetails[0].applicantNames = validationformValues.applicantNames || null;
  }

  const validationResultsEchange = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.data?.getValidate?.validationResults);

  const hasValidationErrorsEchange = validationResultsEchange?.some((result: ValidateMessage) => result?.validationMessages?.some((message) => message.categorie === 0 || message.categorie === 1)) ?? false;

  const hasErrors = validationResults?.some((result) =>
    result.validationMessages?.some((message: ValidationMessage) => message.categorie === 0 || message.categorie === 1));
  const validationResult: ValidateRoot = useScopedSelector<ValidateRoot>('data.changes_validate_error_message');
  const validationApiErrorMessages: ValidationMessage[] = validationResult?.validationResults?.find((v) => v.pageType === PAGE_ID_ECHANGE_CHANGEREQUEST)?.validationMessages || [];

  const validatePageValidations = useScopedSelector((state: ChangeRequestState) => state?.data?.getValidate?.validationResults);
  const HeadOfficeChangesMessages = useSelector((state: AppState) => state.App?.fragments?.ApplicationFragment?.data?.headoffice_validate_error_message?.validationMessages);
  const signatureModeValidation = useSelector(selectSignature)?.validationMessages;
  const signatureConfigure = useSelector(selectSignature);
  const signatureMode = signatureConfigure?.modeSignature;
  const signaturePaperValidation = useSelector((state: State) => state.App?.fragments?.ApplicationFragment?.data?.saveSignaturePaper?.validationMessages);
  const signatureElectronicData = useSelector(selectSignatureDetails);
  const signatureElectronicValidation = useSelector(selectSignatureDetails)?.validationMessages;
  const formValuesPaper = useSelector((state: State) => state.App?.fragments?.ApplicationFragment?.values?.signaturePaper);
  const validationMessagesSummaryEchange = useSelector((state: AppState) => state.App?.fragments?.ApplicationFragment?.data?.summary_validate_error_message?.validationMessages);
  const pricingPreferences = coverages?.insuredDetails?.map((i: InsuredDetail) => ({ individualId: i.id, preferenceType: i.pricingPreferential }));

  const isPropertyValue = (property: ReactNode | { fr: ReactNode; en: ReactNode }, value: string) =>
    typeof property === 'object' && property !== null && !(property instanceof React.Component) && !React.isValidElement(property) && 'en' in property && property.en === value;

  const getApiCallForRoute = useCallback((route: string, apiCalls: any) => {
    const apiCallKeys = Object.keys(apiCalls);
    const matchingKey = apiCallKeys.find((key) => route?.includes(key));
    return matchingKey ? apiCalls[matchingKey] : null;
  }, []);

  const englishToFrench = useMemo(() => ({
    [ENGLISH_ROUTES.ADVISOR]: FRENCH_ROUTES.ADVISOR,
    [ENGLISH_ROUTES.BENEFICIARY]: FRENCH_ROUTES.BENEFICIARY,
    [ENGLISH_ROUTES.REQUIREMENTS]: FRENCH_ROUTES.REQUIREMENTS,
    [ENGLISH_ROUTES.HEADOFFICE]: FRENCH_ROUTES.HEADOFFICE,
    [ENGLISH_ROUTES.COVERAGES]: FRENCH_ROUTES.COVERAGES,
    [ENGLISH_ROUTES.LINKED_APPLICATIONS]: FRENCH_ROUTES.LINKED_APPLICATIONS,
    [ENGLISH_ROUTES.INSURANCE_HISTORY]: FRENCH_ROUTES.INSURANCE_HISTORY,
    [ENGLISH_ROUTES.BILLING]: FRENCH_ROUTES.BILLING,
    [ENGLISH_ROUTES.PAC_PAD_AGREEMENT]: FRENCH_ROUTES.PAC_PAD_AGREEMENT,
    [ENGLISH_ROUTES.SPECIAL_INSTRUCTIONS]: FRENCH_ROUTES.SPECIAL_INSTRUCTIONS,
    [ENGLISH_ROUTES.DECLARATIONS]: FRENCH_ROUTES.DECLARATIONS,
    [ENGLISH_ROUTES.IDENTIFICATION]: FRENCH_ROUTES.IDENTIFICATION,
    [ENGLISH_ROUTES.CONSENT]: FRENCH_ROUTES.CONSENT,
    [ENGLISH_ROUTES.APPLICANT]: FRENCH_ROUTES.APPLICANT,
    [ENGLISH_ROUTES.CHANGES]: FRENCH_ROUTES.CHANGES,
    [ENGLISH_ROUTES.HEADOFFICE_ECHANGE]: FRENCH_ROUTES.HEADOFFICE_ECHANGE,
    [ENGLISH_ROUTES.VALIDATION_ECHANGE]: FRENCH_ROUTES.VALIDATION_ECHANGE,
    [ENGLISH_ROUTES.SIGNATURE_MODE]: FRENCH_ROUTES.SIGNATURE_MODE,
    [ENGLISH_ROUTES.SIGNATURE_FOLLOWUP]: FRENCH_ROUTES.SIGNATURE_FOLLOWUP,
    [ENGLISH_ROUTES.RESULTS]: FRENCH_ROUTES.RESULTS,
    [ENGLISH_ROUTES.SUMMARY_ECHANGE]: FRENCH_ROUTES.SUMMARY_ECHANGE,
  }), []);

  const autoSaveExclusion = useMemo(() => [
    ENGLISH_ROUTES.SIGNATURE, FRENCH_ROUTES.SIGNATURE,
    ENGLISH_ROUTES.CONSENT, FRENCH_ROUTES.CONSENT,
    ENGLISH_ROUTES.TRANSMISSION, FRENCH_ROUTES.TRANSMISSION,
    ENGLISH_ROUTES.RESULTS, FRENCH_ROUTES.RESULTS,
    ENGLISH_ROUTES.SUMMARY, FRENCH_ROUTES.SUMMARY,
    ENGLISH_ROUTES.SUMMARY_ECHANGE, FRENCH_ROUTES.SUMMARY_ECHANGE,
    ENGLISH_ROUTES.CHANGES, FRENCH_ROUTES.CHANGES,
    ENGLISH_ROUTES.VALIDATION_ECHANGE, FRENCH_ROUTES.VALIDATION_ECHANGE,
    ENGLISH_ROUTES.HEADOFFICE_ECHANGE, FRENCH_ROUTES.HEADOFFICE_ECHANGE,
  ], []);

  const routeMap = useMemo(() => {
    const map = {
      [ENGLISH_ROUTES.ADVISOR]: {
        validation: advisorValidationMessages,
        apiCall: () => advisorApiCall(advisorRequestForData, advisorFormValues, dispatch),
      },
      [ENGLISH_ROUTES.BENEFICIARY]: {
        validation: beneficiaryValidationMessages,
        apiCall: () => beneficiaryApiCall(beneficiaryFormvalues, beneficiaries, dispatch),
      },
      [ENGLISH_ROUTES.REQUIREMENTS]: {
        validation: requirementValidationMessages,
        apiCall: () => requirementApiCall(requirementFormvalues, applicantOrInsuredIndividuals, suppliers, dispatch),
      },
      [ENGLISH_ROUTES.HEADOFFICE]: {
        validation: headOfficeValidationMessages,
        apiCall: () => headOfficeApiCall(headOfficeFormValues, dispatch),
      },
      [ENGLISH_ROUTES.COVERAGES]: {
        validation: [] as ValidationMessage[],
        apiCall: () => coverageApiCall(activeRoute, dispatch),
      },
      [ENGLISH_ROUTES.LINKED_APPLICATIONS]: {
        validation: linkedApplicationsValidationMessages,
        apiCall: () => linkedApplicationsApiCall(linkedApplicationsFormvalues, dispatch),
      },
      [ENGLISH_ROUTES.INSURANCE_HISTORY]: {
        validation: insuranceHistoryValidationMessages,
        apiCall: () => insuranceHistoryApiCall(insuranceHistoryFormData, nomineeId, dispatch),
      },
      [ENGLISH_ROUTES.BILLING]: {
        validation: billingValidationMessages,
        apiCall: () => billingApiCall(billingFormvalues, dispatch),
      },
      [ENGLISH_ROUTES.PAC_PAD_AGREEMENT]: {
        validation: pacPadValidationMessages,
        apiCall: () => pacPadApiCall(pacPadFormvalues, applicantOrInsuredIndividuals, dispatch, bankingRequestId),
      },
      [ENGLISH_ROUTES.SPECIAL_INSTRUCTIONS]: {
        validation: specialInstructionsValidations,
        apiCall: () => specialInstructionsApiCall(instructions, nomineeId, dispatch),
      },
      [ENGLISH_ROUTES.DECLARATIONS]: {
        validation: doiValidationMessages,
        apiCall: () => {
          const declarationCaseId = nominees?.find((nominee) => nominee.id === nomineeId)?.declarationCaseId;
          DoiApiCall(declarationCaseId, dispatch);
        },
      },
      [ENGLISH_ROUTES.IDENTIFICATION]: {
        validation: identificationValidationMessages,
        apiCall: () => identificationApiCall(identificationFormValues, nominees, productValue, nomineeId, dispatch),
      },
      [ENGLISH_ROUTES.CONSENT]: {},
      [ENGLISH_ROUTES.APPLICANT]: {
        validation: applicantValidationMessages,
        apiCall: () => applicantApiCall(applicantValues, dispatch),
      },
      [ENGLISH_ROUTES.CHANGES]: {
        validation: validationApiErrorMessages,
        apiCall: () => changesApiCall(dispatch),
      },
      [ENGLISH_ROUTES.HEADOFFICE_ECHANGE]: {
        validation: HeadOfficeChangesMessages,
        apiCall: dispatchResourceByName('resources.headoffice_validate_error_message', dispatch),
      },
      [ENGLISH_ROUTES.VALIDATION_ECHANGE]: {
        validation: validatePageValidations,
        apiCall: () => validationEchangeApiCall(dispatch),
      },
      [ENGLISH_ROUTES.SIGNATURE_MODE]: {
        validation: signatureModeValidation,
        apiCall: () => signatureModeEchangeApiCall(dispatch, signatureConfigure),
      },
      [ENGLISH_ROUTES.SIGNATURE_FOLLOWUP]: {
        validation: (signatureMode === SignatureMode.Electronic) ? signatureElectronicValidation : signaturePaperValidation,
        apiCall: () => (signatureMode === SignatureMode.Electronic) ? signatureElectronicApiCall(dispatch, saleId) : signaturePaperApiCall(dispatch, formValuesPaper),
      },
      [ENGLISH_ROUTES.RESULTS]: {},
      [ENGLISH_ROUTES.SUMMARY_ECHANGE]: {
        validation: validationMessagesSummaryEchange,
        apiCall: dispatchResourceByName('resources.summary_validate_error_message', dispatch),
      },
    };

    const frenchMap = Object.entries(englishToFrench).reduce((acc: Record<string, typeof map[keyof typeof map]>, [english, french]) => {
      acc[french] = map[english as keyof typeof map];
      return acc;
    }, {});

    return { ...map, ...frenchMap };
  }, [advisorValidationMessages, beneficiaryValidationMessages, requirementValidationMessages, linkedApplicationsValidationMessages, insuranceHistoryValidationMessages, englishToFrench, advisorRequestForData, advisorFormValues, dispatch, beneficiaryFormvalues, beneficiaries, billingFormvalues, billingValidationMessages, requirementFormvalues, applicantOrInsuredIndividuals, suppliers, linkedApplicationsFormvalues, insuranceHistoryFormData, currentNominee, headOfficeValidationMessages, headOfficeFormValues, specialInstructionsValidations, instructions, DoiApiCall, lastVisitedRoute, doiValidationMessages, identificationValidationMessages, identificationFormValues, applicantValidationMessages, applicantValues, pacPadFormvalues, bankingRequestId, pacPadValidationMessages, validationApiErrorMessages, HeadOfficeChangesMessages, signatureModeValidation, validatePageValidations, signatureConfigure, signatureMode, signatureElectronicValidation, signaturePaperValidation, formValuesPaper, saleId, validationMessagesSummaryEchange]);

  const validationMap = useMemo(() => Object.fromEntries(
    Object.entries(routeMap).map(([route, { validation }]) => [route, validation]),
  ), [routeMap]);

  const apiCallMap = useMemo(() => Object.fromEntries(
    Object.entries(routeMap).map(([route, { apiCall }]) => [route, apiCall]),
  ), [routeMap]);

  const initialRoutes = useCallback(
    () =>
      responseData && generateInitialRoutes({
        applicationRoutesRecords,
        saleId,
        nominees,
        userRole,
        currentRoute: activeRoute,
        updateApplicationRoutes,
        generateWorkflowRoutesForAnNominee,
        data: responseData,
      }),
    [responseData],
  );

  const initialRoutesEChanges = useCallback(
    () =>
      responseData && generateInitialRoutesEChanges({
        applicationRoutesRecords,
        saleId,
        nominees,
        userRole,
        currentRoute: activeRoute,
        updateApplicationRoutes,
        generateWorkflowRoutesForAnNominee,
        data: responseData,
      }),
    [responseData],
  );

  useEffect(() => {
    history.block((location) => {
      if (location.pathname === '/' && !autoSaveExclusion.some((route) => activeRoute.includes(route))) {
        return false;
      }
      return null;
    });
  }, [activeRoute, autoSaveExclusion, history]);

  useEffect(() => {
    if (homeClicked) {
      if (activeRoute?.includes(FRENCH_ROUTES.VALIDATION_ECHANGE) ||
          activeRoute?.includes(ENGLISH_ROUTES.VALIDATION_ECHANGE)) {
        return;
      }
      if (activeRoute?.includes(ENGLISH_ROUTES.VALIDATION)) {
        data.saveStatus = true;
        dispatch(requestResourceByName('resources.UPDATE_VALIDATION_NAMES_RESOURCE_NAME', data));
      }
      if (activeRoute?.includes(ENGLISH_ROUTES.COVERAGES)) {
        dispatch(requestResourceByName(`resources.${POST_PREFERENTIAL_PRICING_RESOURCE}`, { intentType: ApplicationIntentType.Save, pricingPreferences }));
      }
      const callApiForRoute = getApiCallForRoute(activeRoute, apiCallMap);
      if (!callApiForRoute) {
        return;
      }
      callApiForRoute();
    }
  }, [homeClicked, activeRoute]);

  useEffect(() => {
    const visibleChanges = responseData?.find((route: any) => route.isVisible === true && route.resourceKey === 'echanges');

    if (visibleChanges) {
      dispatch(setRoutes(initialRoutesEChanges()));
    } else {
      dispatch(setRoutes(initialRoutes()));
    }
    return () => {
      dispatch(setRoutes([]));
    };
  }, [dispatch, initialRoutes, responseData, initialRoutesEChanges, signatureModeValidation]);

  useEffect(() => {
    history.listen((location) => {
      setLastVisitedRoute(activeRoute);
      setActiveRoute(location?.pathname);
    });
  }, [history, activeRoute]);

  useEffect(() => {
    const declarationsRoutePath = getTextBasedOnLocale(locale, ENGLISH_ROUTES.DECLARATIONS, FRENCH_ROUTES.DECLARATIONS);
    // preventing validation call when loading a form or changing a form on declarations page
    if (activeRoute?.includes(declarationsRoutePath) && lastVisitedRoute?.includes(declarationsRoutePath)) return;
    const callApiForRoute = getApiCallForRoute(lastVisitedRoute, apiCallMap);
    if (callApiForRoute) {
      callApiForRoute();
    }
  }, [getApiCallForRoute, lastVisitedRoute]);

  const STATUS_PRIORITIES = useMemo(() => ({
    [WORKFLOW_STATUSES.ERROR]: 0,
    [WORKFLOW_STATUSES.WARNING]: 1,
    [WORKFLOW_STATUSES.CAUTION]: 2,
    [WORKFLOW_STATUSES.IN_PROGRESS]: 3,
    [WORKFLOW_STATUSES.COMPLETE]: 4,
    [WORKFLOW_STATUSES.INCOMPLETE]: 5,
    [WORKFLOW_STATUSES.INACTIVE]: 6,
  }), []);

  const updateSummaryOnClick = (route: WorkflowRouteDefinition) => {
    if (isSummaryRoute(route)) {
      route.onClick = () => {
        if (!activeRoute.includes(ENGLISH_ROUTES.SUMMARY) || !activeRoute.includes(FRENCH_ROUTES.SUMMARY)) {
          history.block();
          dispatch(openModalByName('modals.navigate'));
        }
      };
    }
    return route;
  };

  const hasValidationIssue = (validationData: ValidationMessage[], level: ValidationMessageLevel) =>
    validationData?.some((i) => i.categorie === level);
  const updateSubrouteStatus = (subroute: WorkflowRouteDefinition, index: number, status: WorkflowStatus, statusFollowup: WorkflowStatus) => index === 0 ? { ...subroute, status } : { ...subroute, status: statusFollowup };

  useEffect(() => {
    if (!routes) return;
    Object.entries(validationMap).forEach(([routePath, apiResponse]) => {
      const updatedRoutes = (routes as ExtendedWorkflowRouteDefinition[])?.map((route) => {
        let updatedRoute = { ...route };

        if (activeRoute?.includes(ENGLISH_ROUTES.SIGNATURE_FOLLOWUP) && signatureElectronicData?.isCeremonyInitialized && (hasErrors === false || hasValidationErrorsEchange === false)) {
          // This if block is only for 'When current page is Signature Follow-Up and Signature is Electronic and to handle nav only when Signature process starts'
          const currentPage = activeRoute.split('/').pop().toLowerCase();
          let routeStatus: typeof WORKFLOW_STATUSES[keyof typeof WORKFLOW_STATUSES];

          if (isPropertyValue(route.label, PAGE_NAMES.SIGNATURE)) {
            if (currentPage === PAGE_NAMES.SIGNATURE_FOLLOWUP && signatureElectronicData?.isSignatureComplete) {
              routeStatus = WORKFLOW_STATUSES.COMPLETE;
            } else if (currentPage === PAGE_NAMES.SIGNATURE_FOLLOWUP && signatureElectronicData?.isCeremonyInitialized) {
              const intentType = signatureConfigure?.intentType;
              const hasSignatureFollowupError = hasValidationIssue(signatureElectronicValidation, ValidationMessageLevel.Error);
              const hasSignatureFollowupWarning = hasValidationIssue(signatureElectronicValidation, ValidationMessageLevel.WarningCritique) ||
            hasValidationIssue(signatureElectronicValidation, ValidationMessageLevel.Warning);
              const ifSignatureFollowupError = hasSignatureFollowupError || hasSignatureFollowupWarning;

              routeStatus = ifSignatureFollowupError ? getWorkflowStatus(intentType, hasSignatureFollowupError, hasSignatureFollowupWarning, currentPage, false) : WORKFLOW_STATUSES.IN_PROGRESS;
            }
          } else {
            routeStatus = WORKFLOW_STATUSES.INACTIVE;
          }
          updatedRoute.status = routeStatus;

          if (Array.isArray(route.routes)) {
            updatedRoute.routes = route.routes.map((subroute, index) => {
              if (!isPropertyValue(subroute.label, 'Signature and Follow-Up')) {
                return updateSubrouteStatus(subroute, index, WORKFLOW_STATUSES.INACTIVE, WORKFLOW_STATUSES.INACTIVE);
              }

              if (isPropertyValue(subroute.label, 'Signature and Follow-Up') && currentPage === PAGE_NAMES.SIGNATURE_FOLLOWUP) {
                return updateSubrouteStatus(subroute, index, routeStatus, routeStatus);
              }
              return subroute;
            });
          }
        } else if (isPropertyValue(route.label, PAGE_NAMES.SIGNATURE) && (hasErrors === false || hasValidationErrorsEchange === false)) {
          const validationMessages = signatureConfigure?.validationMessages;
          const intentType = signatureConfigure?.intentType;
          const hasError = validationMessages?.some((i) => i.categorie === ValidationMessageLevel.Error);
          const hasWarning = validationMessages?.some((i) => i.categorie === ValidationMessageLevel.WarningCritique || i.categorie === ValidationMessageLevel.Warning);

          const hasFollowupError = hasValidationIssue(signaturePaperValidation, ValidationMessageLevel.Error) ||
            hasValidationIssue(signatureElectronicValidation, ValidationMessageLevel.Error);

          let hasFollowupWarning = hasValidationIssue(signaturePaperValidation, ValidationMessageLevel.WarningCritique) ||
            hasValidationIssue(signaturePaperValidation, ValidationMessageLevel.Warning) ||
            hasValidationIssue(signatureElectronicValidation, ValidationMessageLevel.WarningCritique) ||
            hasValidationIssue(signatureElectronicValidation, ValidationMessageLevel.Warning);
          if (signatureMode === SignatureMode.Paper) {
            hasFollowupWarning = formValuesPaper?.demandId === '' || formValuesPaper?.demandId === null || !formValuesPaper?.areSignaturesVerified;
          }

          const currentPage = activeRoute.split('/').pop().toLowerCase();

          let status: typeof WORKFLOW_STATUSES[keyof typeof WORKFLOW_STATUSES];
          if (currentPage === PAGE_NAMES.SIGNATURE_FOLLOWUP && signatureElectronicData?.isCeremonyInitialized) {
            status = WORKFLOW_STATUSES.INACTIVE;
          } else if (currentPage === PAGE_NAMES.SIGNATURE_FOLLOWUP) {
            status = WORKFLOW_STATUSES.COMPLETE;
          } else {
            status = getWorkflowStatus(intentType, hasError, hasWarning, currentPage, true);
          }

          let statusFollowup = (currentPage === PAGE_NAMES.SIGNATURE_MODE || signatureMode === SignatureMode.Electronic) ? WORKFLOW_STATUSES.IN_PROGRESS : getWorkflowStatus(intentType, hasFollowupError, hasFollowupWarning, currentPage, false);
          if (currentPage !== PAGE_NAMES.SIGNATURE_MODE && currentPage !== PAGE_NAMES.SIGNATURE_FOLLOWUP) {
            status = WORKFLOW_STATUSES.INACTIVE;
            statusFollowup = WORKFLOW_STATUSES.INACTIVE;
          }

          updatedRoute.status = currentPage === PAGE_NAMES.SIGNATURE_MODE ? status : statusFollowup;
          if (Array.isArray(route.routes)) {
            updatedRoute.routes = route.routes.map((subroute, index) =>
              updateSubrouteStatus(subroute, index, status, statusFollowup));
          }
        } else if (Array.isArray(route.routes)) {
          updatedRoute.routes = route.routes.map((nomineeRoute: WorkflowRouteDefinition) => updateNomineeRouteStatuses(
            {
              route: nomineeRoute,
              routePath,
              apiResponse: apiResponse as ValidationMessage[],
              activeRoute,
              lastVisitedRoute,
              lastStatuses,
              locale,
            },
            {
              isInactiveStatus,
              setNomineeId,
              setLastStatuses,
            },
          ));
          const childStatuses = updatedRoute.routes?.map((r: WorkflowRouteDefinition) => r.status);
          const highestPriorityStatus = getHighestPriorityStatus(childStatuses, STATUS_PRIORITIES, WORKFLOW_STATUSES.INACTIVE);
          updatedRoute.status = highestPriorityStatus;
        } else {
          updatedRoute = updateRouteStatuses(
            {
              route: updatedRoute,
              routePath,
              apiResponse: apiResponse as ValidationMessage[],
              activeRoute,
              lastVisitedRoute,
              lastStatuses,
            },
            {
              isSummaryRoute,
              isInactiveStatus,
              setLastStatuses,
            },
          );
          updatedRoute = updateSummaryOnClick(updatedRoute);
        }
        return updatedRoute;
      });
      if (JSON.stringify(routes) !== JSON.stringify(updatedRoutes)) {
        dispatch(setRoutes(updatedRoutes));
      }
    });
  }, [routes, lastVisitedRoute, activeRoute, STATUS_PRIORITIES, lastStatuses, dispatch, validationMap, hasErrors, hasValidationErrorsEchange, signatureConfigure, signatureModeValidation]);

  const flatRoutes = useMemo(() => {
    const newRoutes = (routes as WorkflowRouteSectionDefinition[])?.reduce((acc, route: WorkflowRouteSectionDefinition) => {
      if (route.routes) {
        const nestedRoutes = route.routes.reduce((nestedAcc, nestedRoute: WorkflowRouteSectionDefinition) => {
          if (nestedRoute.routes) {
            return [...nestedAcc, ...(nestedRoute.routes ?? [])];
          }
          return [...nestedAcc, nestedRoute];
        }, [] as RouteDefinition[]);
        return [...acc, ...nestedRoutes];
      }
      return [...acc, route];
    }, [] as RouteDefinition[]);

    return newRoutes;
  }, [routes]);

  const location = useLocation();

  const navigationCursor = useMemo(() => {
    const typedRoutes = flatRoutes as { path: { fr: string; en: string } }[];
    const currentRouteIndex = typedRoutes.findIndex((route) => matchPath(location.pathname, { path: route?.path?.[locale] }));

    const currentRoute = typedRoutes[currentRouteIndex]?.path?.[locale];
    const previousRoute = currentRouteIndex > 0 ? typedRoutes[currentRouteIndex - 1]?.path?.[locale] : undefined;
    const nextRoute = currentRouteIndex < flatRoutes.length - 1 ? typedRoutes[currentRouteIndex + 1]?.path?.[locale] : undefined;

    return { currentRoute, previousRoute, nextRoute };
  }, [flatRoutes, locale, location?.pathname]);

  const targetRoute = useSelector(selectTargetRoute);

  const navigateToTargetRoute = useCallback((overrideRoute?: string) => {
    history.push(overrideRoute ?? targetRoute);
  }, [history, targetRoute]);

  const value = useMemo(() => ({ routes, flatRoutes, targetRoute, navigateToTargetRoute, ...navigationCursor }), [flatRoutes, navigateToTargetRoute, navigationCursor, routes, targetRoute]);

  const handleOnBackButtonClick = useCallback(() => {
    history.block(true);
    dispatch(closeModalByName('modals.navigate'));
    const saleID = saleIdRef.current?.toString();
    const summaryPageURL = locale === Locale.EN ? getRelativeUrlForRoute(relativeRoute.application_saleId_summary, { saleId: saleID }) : getRelativeUrlForRoute(relativeRoute.application_saleId_resume, { saleId: saleID });
    history.push(summaryPageURL);
  }, [history, saleIdRef]);

  return (
    <>
      <Form name="routePath">
        <HiddenControl name="currentRoute" value={activeRoute} />
        <HiddenControl name="saleId" value={saleId} />
      </Form>
      <WarningModal name="navigate">
        <P>{t('warning')}</P>
        <PrimaryButton
          style={{ marginRight: '1rem' }}
          onClick={handleOnBackButtonClick}
        >
          {t('yes')}
        </PrimaryButton>
        <SecondaryButton onClick={() => dispatch(closeModalByName('modals.navigate'))}>
          {t('no')}
        </SecondaryButton>
      </WarningModal>
      <Resource
        name="getRoutes"
        url={getBffUrlForRoute(BffRoute.user_menu_saleId, { saleId }, false)}
        onSuccess={(response: AxiosResponse) => {
          setResponseData(response.data);
        }}
        autoRequest
      />
      <UpdateBeneficiaryResource validationClientId={nomineeId} />
      <ValidateBillingResource />
      <UpdateBillingResource />
      <UpdateBankingResource />
      <UpdateRequirementsResource />
      <UpdateIndividualResource saleId={saleId} currentNominee={nominees?.find((nominee) => nominee.id === nomineeId)} />
      <ValidateDOIResource />
      <SaveOrValidateAdvisorResource saleId={saleId} />
      <SaveOrValidateHeadOfficeResource />
      <UpdateInsuranceHistoryResource validationClientId={nomineeId} />
      <UpdateLinkedApplicationsResource />
      <PostPreferentialPricingResource />
      <UpdateSpecialInstructionsResource validationClientId={nomineeId} />
      <UpdateValidationNames />
      <ApplicantResourceLoader saleId={saleId} />
      {saleId && <ChangesValidationResource saleId={saleId} /> }
      <HeadOfficeValidationResource saleId={saleId} />
      <ValidationResources name="getValidate" url={getBffUrlForRoute(ValidationApi.validation_validate, { saleId }, false)} />
      <SignaturePaperSaveResource saleId={saleId} />
      <StartCeremonyResource saleId={saleId} />
      <SummaryValidationResource saleId={saleId} />
      <ApplicationNavRoutesContext.Provider value={value}>
        {children}
      </ApplicationNavRoutesContext.Provider>
    </>
  );
};

export default ApplicationNavRoutesProvider;
