import { MainLoader, ResourceLoader, ResourceLoaderProps } from 'ia-react-core';
import React, { FC, ReactNode, useCallback } from 'react';

import { AxiosResponse } from 'axios';
import { useHistory, useParams } from 'react-router';
import useQuery from '~/hooks/useQuery';
import { Scenario } from '~/interfaces/Scenario';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { ADD, COPY, DELETE, RESET } from '~/constants/APP_CONSTANTS';
import constructScenarioActionName from '~/utilities/constructScenarioActionName';
import ILLUSTRATION_NAMES from '../../constants/ILLUSTRATION_NAMES';

export interface ScenariosResourceLoaderProps extends Omit<ResourceLoaderProps<Scenario[]>, 'children'> {
  children: ReactNode;
}

const ScenariosResourceLoader: FC<ScenariosResourceLoaderProps> = ({ children, ...props }) => {
  const { saleId } = useParams<{ saleId: string }>();
  const history = useHistory();
  const query = useQuery();
  const scenarioActionStorageName = constructScenarioActionName(saleId);
  const deletedScenarioIndexStorageName = `deleted-scenario-index-${saleId}`;
  const scenarioUrl = getBffUrlForRoute(BffRoute.illustration_sales_saleId_scenarios, { saleId }, false);
  const resetScenarioIdStorageName = `reset-scenario-id-${saleId}`;
  const resetScenarioId = localStorage.getItem(resetScenarioIdStorageName);
  const position = history.location.pathname.indexOf('scenarioId');
  const pathName = history.location.pathname.substring(0, position);

  const handleSuccess = useCallback(({ data: scenarios }: AxiosResponse<Scenario[]>) => {
    const getSelectedScenario = scenarios.find((scenario) => scenario.isDemandeChoisie);
    const scenarioId = query.get('scenarioId');
    const replaceWithFirstScenario = (
      !scenarioId ||
      !scenarios.some((scenario) => scenario.idDemande === scenarioId)
    );

    if (replaceWithFirstScenario && !localStorage.getItem(scenarioActionStorageName)) {
      const firstScenario = scenarios[0];
      query.set('scenarioId', firstScenario.idDemande);

      if (resetScenarioId) {
        const getResettedScenario = scenarios.find((scenario) => scenario.idDemande === resetScenarioId);
        query.set('scenarioId', getResettedScenario?.idDemande);
        localStorage.setItem(resetScenarioIdStorageName, '');
      }

      if (getSelectedScenario) {
        query.set('scenarioId', getSelectedScenario?.idDemande);
      }

      history.replace({
        pathname: pathName,
        search: query.toString(),
      });
    } else if ([ADD, COPY].includes(localStorage.getItem(scenarioActionStorageName))) {
      query.set('scenarioId', scenarios[scenarios.length - 1]?.idDemande);
      history.replace({
        pathname: pathName,
        search: query.toString(),
      });
      localStorage.setItem(scenarioActionStorageName, '');
    } else if (localStorage.getItem(scenarioActionStorageName) === DELETE) {
      const deletedScenarioIndex = localStorage.getItem(deletedScenarioIndexStorageName);
      const scenarioIndex = Number(deletedScenarioIndex) === 0 ? Number(deletedScenarioIndex) : Number(deletedScenarioIndex) - 1;
      const scenarioToDisplay = scenarios?.length === 1 ? scenarios[0] : scenarios[scenarioIndex];
      query.set('scenarioId', scenarioToDisplay?.idDemande);
      history.replace({
        pathname: pathName,
        search: query.toString(),
      });
      localStorage.setItem(scenarioActionStorageName, '');
    } else if (localStorage.getItem(scenarioActionStorageName) === RESET) {
      localStorage.setItem(scenarioActionStorageName, '');
      history.go(0);
    }
  }, [history, query]);

  return (
    <ResourceLoader<Scenario[]>
      autoRequest
      name={ILLUSTRATION_NAMES.SCENARIOS_RESOURCE_NAME}
      url={scenarioUrl}
      onSuccess={handleSuccess}
      CustomLoader={MainLoader}
      {...props}
    >
      {children}
    </ResourceLoader>
  );
};

export default ScenariosResourceLoader;
