import { SexeEnum, TypeNoTelephoneEnum } from '~/fragments/ApplicationFragment/interfaces/enums';
import { Address } from 'ia-react-core';
import AddressModel from '~/fragments/ApplicationFragment/interfaces/AddressModel';
import { CompanyModel } from '~/fragments/ApplicationFragment/interfaces/CompanyModel';
import TelephoneModel from '~/fragments/ApplicationFragment/interfaces/TelephoneModel';
import { useCurrentNominee } from '../../../utils/nominee.utils';
import { NoneOfTheAboveEn, NoneOfTheAboveFr, NoneOfTheAboveValue } from '../Identity/IdentityConstants';

type Nominee = ReturnType<typeof useCurrentNominee>;
export type IndividualFormData = ReturnType<typeof getInitialFormValues>;

const countryData = {
  Canada: {
    countryName: 'Canada',
    countryIso2: 'CA',
    countryIso3: 'CAN',
    countryIsoNumber: '124',
  },
  USA: {
    countryName: 'United States',
    countryIso2: 'US',
    countryIso3: 'USA',
    countryIsoNumber: '840',
  },
};

const countries = [
  { label: countryData.Canada.countryName, value: countryData.Canada.countryIso2 },
  { label: countryData.USA.countryName, value: countryData.USA.countryIso2 },
];

enum DeliverType {
  PotalBox = 'postalbox',
  Rural = 'rural',
  None = 'none',
}

const getDeliveryType = (address: AddressModel) => {
  if (address.poBox) {
    return DeliverType.PotalBox;
  }

  if (address.ruralRoad) {
    return DeliverType.Rural;
  }

  return DeliverType.None;
};

const getCountryData = (country: string) => {
  if (country === countryData.Canada.countryName) {
    return countryData.Canada;
  }

  if (country === countryData.USA.countryName) {
    return countryData.USA;
  }

  return null;
};

export const nomineeAddressToForm = (address: AddressModel): Address => ({
  street: address.street,
  province: address.province,
  buildingNumber: address.civicNumber,
  city: address.city,
  postalCode: address.postalCode,
  subBuilding: address.extApt,
  subBuildingType: address.extApt ? address.subBuildingType : '',
  ruralRoute: address.ruralRoad,
  poBoxNumber: address.poBox,
  place: address.branch,
  deliveryType: getDeliveryType(address),
  ...getCountryData(address.country),
} as Address);

const isUsingRefAddress = (nominee: Nominee) =>
  nominee.address && nominee.address?.idFirstAssociatedEntity !== nominee.id;

const getNomineeAddress = (nominee: Nominee, allNominees: Nominee[]) => {
  let { address } = nominee;

  if (isUsingRefAddress(nominee)) {
    const refNominee = allNominees.find((n) => nominee?.address?.idFirstAssociatedEntity === n.id);
    if (refNominee) {
      address = refNominee.address;
    }
  }
  return address?.country ? nomineeAddressToForm(address) : undefined;
};

const getPhotoDocuments = (nominee: Nominee, language: string) => {
  if (nominee.identity?.photoDocuments === NoneOfTheAboveValue) {
    return language === 'fr' ? NoneOfTheAboveFr : NoneOfTheAboveEn;
  }
  return nominee.identity?.photoDocuments;
};

const getInitialFormValues = (nominee: Nominee, allNominees: Nominee[], language: string) => {
  const photoDocuments = getPhotoDocuments(nominee, language);

  return {
    firstName: nominee.firstName ?? '',
    lastName: nominee.lastName ?? '',
    middleName: nominee.middleName ?? '',
    name: nominee.name ?? '',
    birthName: nominee.birthName ?? '',
    dateOfBirth: nominee.dateOfBirth ?? '',
    ageIllustration: nominee.ageIllustration ?? 0,
    gender: nominee.gender ?? SexeEnum.Masculin,
    sin: nominee.sin ?? '',
    occupation: nominee.occupation ?? '',
    employer: nominee.employer ?? '',
    codeTypeRelation: nominee.codeTypeRelation ?? '',
    useSameAddress: isUsingRefAddress(nominee),
    addressRef: nominee?.address?.idFirstAssociatedEntity,
    address: getNomineeAddress(nominee, allNominees),
    telephones: nominee.telephones?.map((telephone) => ({
      number: telephone.number ?? '',
      telephoneNumberType: telephone.telephoneNumberType ?? '',
    })) ?? [],
    email: nominee.email ?? '',
    phoneData: {
      home: getPhoneNumber(nominee.telephones, TypeNoTelephoneEnum.Principale),
      cell: getPhoneNumber(nominee.telephones, TypeNoTelephoneEnum.Cellulaire),
      work: getPhoneNumber(nominee.telephones, TypeNoTelephoneEnum.Bureau),
      extension: getPhoneExtension(nominee.telephones, TypeNoTelephoneEnum.Bureau),
    },
    verificationType: nominee.identity?.verificationType ?? null,
    dateVerification: nominee.identity?.dateVerification,
    photoDocuments,
    photoDocumentName: nominee.identity?.photoDocumentName,
    photoDocumentNumber: nominee.identity?.photoDocumentNumber,
    photoIssuingOrganisationName: nominee.identity?.photoIssuingOrganisationName,
    photoPlaceOfIssue: nominee.identity?.photoPlaceOfIssue,
    photoExpiryDate: nominee.identity?.photoExpiryDate,
    residenceDocument: nominee.identity?.residenceDocument,
    residenceDocumentNumber: nominee.identity?.residenceDocumentNumber,
    residenceIssuingOrganisationName: nominee.identity?.residenceIssuingOrganisationName,
    residencePlaceOfIssue: nominee.identity?.residencePlaceOfIssue,
    residenceExpiryDate: nominee.identity?.residenceExpiryDate,
    isPieceValid: nominee.identity?.isPieceValid,
    activitySector: nominee.activitySector,
    uci: nominee?.uci,
  };
};

const addressToBffPayload = (
  nominee: Nominee | CompanyModel,
  { address, useSameAddress, addressRef }: IndividualFormData,
) => ({
  id: nominee?.address?.id,
  postalCode: address?.postalCode ?? '',
  extApt: address?.subBuilding ?? '',
  civicNumber: address?.buildingNumber ?? '',
  province: address?.province ?? '',
  country: Object.values(countryData)
    .find((country) => country.countryIso2 ===
  address?.countryIso2)?.countryName ?? '',
  street: address?.street ?? '',
  city: address?.city ?? '',
  poBox: address?.poBoxNumber ?? '',
  branch: address?.place ?? '',
  ruralRoad: address?.ruralRoute ?? '',
  subbuildingType: address?.subBuildingType ?? '',
  subbuilding: address?.subBuilding ?? '',
  idFirstAssociatedEntity: useSameAddress ? addressRef : nominee.id,
  addressLookupServiceWasUsed: true,
});

const telephonesToBffPayload = (form: IndividualFormData, nominee: Nominee | CompanyModel) => [
  addPhone(form.phoneData?.home, TypeNoTelephoneEnum.Principale, true, nominee),
  addPhone(form.phoneData?.cell, TypeNoTelephoneEnum.Cellulaire, false, nominee),
  addPhone(form.phoneData?.work, TypeNoTelephoneEnum.Bureau, false, nominee, form.phoneData?.extension),
];

const codeTypeRelationToBffPayload = (codeTypeRelation: string|number) => {
  if (codeTypeRelation === 0) {
    return 0;
  }
  return codeTypeRelation || null;
};

const personToBffPayload = (nominee: Nominee, form: IndividualFormData) => ({
  isPerson: Boolean(nominee.isPerson),
  id: nominee.id,
  address: form?.address?.street ? addressToBffPayload(nominee, form) : null,
  telephones: telephonesToBffPayload(form, nominee),
  email: form.email ?? '',
  isInsured: Boolean(nominee.isInsured),
  isApplicant: Boolean(nominee.isApplicant),
  codeTypeRelation: codeTypeRelationToBffPayload(form.codeTypeRelation),
  codeContextRelation: nominee.codeContextRelation,
  descriptionRelation: nominee.descriptionRelation,
  declarationType: nominee.declarationType,
  declarationCaseId: nominee.declarationCaseId,
  occupation: form.occupation ?? '',
  employer: form.employer ?? '',
  firstName: form.firstName ?? '',
  middleName: form.middleName ?? '',
  lastName: form.lastName ?? '',
  birthName: form.birthName ?? '',
  name: form.name ?? nominee.name ?? '',
  fullName: nominee.fullName ?? '',
  isBirthDateGeneratedWithAgeEntered: Boolean(nominee.isBirthDateGeneratedWithAgeEntered),
  dateOfBirth: form.dateOfBirth ?? '',
  dateOfDeath: nominee.dateOfDeath ?? null,
  birthDateText: nominee.birthDateText ?? '',
  ageIllustration: nominee.ageIllustration ?? 0,
  gender: nominee.gender ?? SexeEnum.Masculin,
  isSmoker: Boolean(nominee.isSmoker),
  sin: form.sin ?? nominee.sin ?? '',
  ageAmountKey: nominee.ageAmountKey ?? '',
  uci: nominee?.uci ? nominee?.uci : form?.uci,
  identity: {
    verificationType: verificationTypeToBffPayload(form?.verificationType),
    dateVerification: form.dateVerification || null,
    photoDocuments: form.photoDocuments,
    photoDocumentName: form.photoDocumentName,
    photoDocumentNumber: form.photoDocumentNumber,
    photoIssuingOrganisationName: form.photoIssuingOrganisationName,
    photoPlaceOfIssue: form.photoPlaceOfIssue,
    photoExpiryDate: form.photoExpiryDate || null,
    residenceDocument: form.residenceDocument,
    residenceDocumentNumber: form.residenceDocumentNumber,
    residenceIssuingOrganisationName: form.residenceIssuingOrganisationName,
    residencePlaceOfIssue: form.residencePlaceOfIssue,
    residenceExpiryDate: form.residenceExpiryDate || null,
    isPieceValid: form.isPieceValid,
  },
});

const organizationToBffPayload = (nominee: CompanyModel, form: IndividualFormData) => ({
  isPerson: Boolean(nominee.isPerson),
  id: nominee.id,
  address: form?.address?.street ? addressToBffPayload(nominee, form) : null,
  telephones: telephonesToBffPayload(form, nominee),
  email: form.email ?? '',
  isInsured: Boolean(nominee.isInsured),
  isApplicant: Boolean(nominee.isApplicant),
  occupation: form.occupation ?? '',
  employer: form.employer ?? '',
  firstName: form.firstName ?? '',
  middleName: form.middleName ?? '',
  lastName: form.lastName ?? '',
  birthName: form.birthName ?? '',
  name: form.name ?? nominee.name ?? '',
  gender: form.gender ?? SexeEnum.Masculin,
  constitutionPlace: nominee.constitutionPlace ?? '',
  companyName: nominee.companyName ?? '',
  constitutionNumber: nominee.constitutionNumber ?? '',
  activitySector: form.activitySector ?? nominee.activitySector ?? '',
  foreignTaxation: nominee.foreignTaxation,
  codeTypeRelation: '',
  descriptionRelation: '',
});

export const IdentificationUtils = {
  getInitialFormValues,
  personToBffPayload,
  organizationToBffPayload,
  nomineeAddressToForm,
  countries,
};

const addPhone = (
  number: string,
  telephoneNumberType: TypeNoTelephoneEnum,
  isPrimary: boolean,
  nominee: Nominee | CompanyModel,
  extension = '',
) => ({
  number: number ?? '',
  extension,
  telephoneNumberType,
  isPrimary,
});

const getPhoneNumber = (telephones: TelephoneModel[], type: number) =>
  telephones?.find((telephone) =>
    (telephone.telephoneNumberType === type || telephone.telephoneNumberType === TypeNoTelephoneEnum[type]))?.number;

const getPhoneExtension = (telephones: TelephoneModel[], type: number) =>
  telephones?.find((telephone) =>
    (telephone.telephoneNumberType === type || telephone.telephoneNumberType === TypeNoTelephoneEnum[type]))?.extension;

const verificationTypeToBffPayload = (verificationType: boolean) => {
  if (verificationType === true || verificationType === false) {
    return verificationType;
  }
  return null;
};
