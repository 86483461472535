export default {
  all: 'All',
  actions: 'Actions',
  newBusiness: 'New Business',
  changeRequest: 'Change Request',
  title: 'File',
  case: 'Case no.',
  selection: 'Selection',
  shared: 'Shared',
  shareCase: 'Share case',
  notShared: 'Not shared',
  version: 'Version',
  sharedWithMe: 'Shared with me',
  importSharedFile: 'Import shared file',
  fileName: 'File name',
  information: 'Information',
  modifiedDate: 'Modified',
  completed: 'Completed',
  notTransmitted: 'Not transmitted',
  createdDate: 'Creation date',
  transferredDate: 'Transferred date',
  status: 'Status',
  draft: 'Draft',
  illustration: 'Illustration',
  application: 'Application',
  inProgress: 'In progress',
  lastModified: ' Last modified',
  pendingSignature: 'Pending signature',
  transmitted: 'Transmitted',
  requiredAction: 'Action required',
  noSaleFiles: 'No file matches your search criteria',
  noOpenFiles: 'You have no open files',
  renameFileMenu: 'Rename',
  deleteFileMenu: 'Delete',
  moreInformationMenu: 'More information',
  searchInputPlaceholder: 'Name of the file, contractors, insured',
  searchInputPlaceholderfiles: 'Name of the file',
  illustrationLink: 'illustration',
  applicationLink: 'application',
  delete: 'Delete',
  rename: 'Change the file name',
  save: 'Save',
  an: 'an',
  or: 'or',
  dateFormat: 'en-ca',
  stageLabels: {
    proposition: 'Application',
    signature: 'Signature',
    illustration: 'Illustration',
    individu: 'Individual',
    admissibilite: 'Admissibility',
    validation: 'Validation',
    changements: 'Changes',
    remiseEnVigueur: 'Reinstatement',
    transferee: 'Transferred',
    ceremonieSignatureDebutee: 'Signature ceremony started',
    signee: 'Signed',
    client: 'Clients',
    passageALaProposition: 'Application',
    draft: 'Draft',
  },
  fileMessages: {
    Msg_5047: 'There are {0} day(s) remaining to proceed to the electronic application with this illustration. After this deadline, changes could affect your illustration, particularly in terms of premium or choice of coverage.',
    Msg_5048: 'The deadline for proceeding to the application was {0} . To do so now, please create a new illustration.',
    Msg_5049: 'There are {0} day(s) remaining to transmit this application as is. After this deadline, changes could affect certain elements of the application, particularly in terms of premium or choice of coverages.',
    Msg_5050: 'This file contains parameters that are no longer supported. It can, however, be viewed, modified and printed. To resume this sale, you must create a new illustration.',
  },
  newchangerequest: 'New change request',
  nochangerequest: '',
  contract: 'Contract no.',
  message: 'Any Unathorized access or use is strictly forbidden. By using this service, you consent to Industrielle Alliance data security policy. Any activity that goes against the security policy and any inappropriate use of the service will be considered an abuse, which can lead to administrative and judicial prosecution.',
  savemodalinfo: 'When saving documents on your desktop, you must respect the information security and privacy guidelines',
  savemodaltitle: 'Sale files',
  savebutton: 'Save',
  closebutton: 'Close',
  ok: 'Ok',
  olderVersionMessage: 'This file was created using a previous version of EVO and can no longer be submitted. However, you can still consult, modify and print it. To continue with this file or to create a new one and submit it, please use the latest version of EVO, click on "Create an illustration" and re-enter the information.',
  migrateSuccessMsg: 'The file has been successfully migrated.',
  success: 'Success',
  saveFileModal: {
    save: 'Documents ',
  },
  changeAge: 'Change of age detected',
  share: 'Share this file',
};
