import { RouteDefinition, WorkflowRouteDefinition } from 'ia-react-core';
import { createContext, useContext } from 'react';

interface ApplicationNavRoutesProps {
  routes: WorkflowRouteDefinition[];
  flatRoutes: RouteDefinition[];
  currentRoute: string | undefined;
  previousRoute: string | undefined;
  nextRoute: string | undefined;
  navigateToTargetRoute: (overrideRoute?: string) => void;
  targetRoute: string | undefined;
}

export const ApplicationNavRoutesContext = createContext({} as ApplicationNavRoutesProps);

const useApplicationNavRoutes = () => useContext(ApplicationNavRoutesContext);

export default useApplicationNavRoutes;
