export const LAST_NAME_MAX_LENGTH = 20;
export const FIRST_NAME_MAX_LENGTH = 20;
export const CITY_MAX_LENGTH = 20;
export const HEALTH_CARE_FACILITY_MAX_LENGTH = 40;
export const HEALTH_CARE_FACILITY_NAME = 70;
export const LOADER_DELAY_IN_MS = 1000;
export const DOI_TYPE_ACCESS_LIFE = 2;
export const UPDATE_ACCESS_LIFE_DOI_STEP = 'updateAccessLifeDOIStep';

export const ExcludedMedicalCondn = ['Acne', 'Adenoids',
  'Allergies',
  'Contraceptives',
  'Cosmetic',
  'Hemorrhoids',
  'Menopause',
  'Otitis',
  'Pregnancy',
  'Tonsils',
  'Vision'];
