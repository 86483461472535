import { Action } from 'redux';
import { AppState } from '~/interfaces/AppState';
import { UpdateSignatureConfigureAction } from '../ApplicationFragment.actions';

export function updateSignatureConfigureReducer(state: AppState, action: Action) {
  const { payload } = action as UpdateSignatureConfigureAction;
  return {
    ...state,
    values: {
      ...state.App?.fragments.ApplicationFragment.values,
      signatureConfigure: {
        ...state.App?.fragments.ApplicationFragment.values.signatureConfigure,
        ...payload,
      },
    },
  };
}
