import { PendingInsuranceFormData, PendingInsuranceType, TypeProtectionHistoriqueEnum } from '~/interfaces/InsuranceHistoryPageState';

const formatPendingInsuranceFormValues = (pendingInsurance: PendingInsuranceType): PendingInsuranceFormData => {
  const protectionAmounts = pendingInsurance?.protectionAmounts;
  const selectedProtections = pendingInsurance?.protectionAmounts?.map(
    (protectionAmount) => protectionAmount?.protectionType,
  ) || [];
  const life = protectionAmounts?.find((x) => x.protectionType === TypeProtectionHistoriqueEnum.Vie);
  const criticalIllness = protectionAmounts?.find((x) => x.protectionType === TypeProtectionHistoriqueEnum.MaladieGrave);
  const disability = protectionAmounts?.find((x) => x.protectionType === TypeProtectionHistoriqueEnum.Invalidite);

  return ({
    id: pendingInsurance?.id ?? null,

    hasLifeInsuranceCoverage: Boolean(life),
    lifeInsuranceCoverageAmount: life?.amount ? String(life?.amount) : null,

    hasCriticalIllnessCoverage: Boolean(criticalIllness),
    criticalIllnessCoverageAmount: criticalIllness?.amount ? String(criticalIllness?.amount) : null,

    hasDisabilityInsuranceCoverage: Boolean(disability),
    disabilityInsuranceCoverageAmount: disability?.amount ? String(disability?.amount) : null,

    selectedProtections,
  });
};

export default formatPendingInsuranceFormValues;
