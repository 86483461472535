import React, { FC, useMemo } from 'react';
import { Resource, ResourceProps } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';

export interface DeleteRepeatingSectionProps extends ResourceProps<null, null> {
  saleId: string;
  repeatingSectionId: string;
  sectionId: string;
}

const DeleteRepeatingSection: FC<DeleteRepeatingSectionProps> = ({ saleId, repeatingSectionId, sectionId }) => {
  const deleteRepeatingSecUrl = useMemo(() => getBffUrlForRouteWithoutQP(
    BffRoute.delete_repeating_section,
    { saleId, repeatingSectionId, sectionId },
    false,
  ), [saleId, repeatingSectionId, sectionId]);
  const body = {};
  return (
    <Resource
      name="resources.delete_repeating_section"
      url={deleteRepeatingSecUrl}
      data={body}
      method="DELETE"
      onSuccess={(result) => {
        if (result.status !== 200) {
          throw new Error(`Error encountered when deleting a section: ${result.status} - ${result.statusText}`);
        }
      }}
    />
  );
};

export default DeleteRepeatingSection;
