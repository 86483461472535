import { InforceInsuranceFormData, PendingInsuranceFormData, InforceProtectionAmountsType, TypeProtectionHistoriqueEnum } from '~/interfaces/InsuranceHistoryPageState';

const getProtectionAmounts = (formValues: InforceInsuranceFormData|PendingInsuranceFormData): InforceProtectionAmountsType[] => {
  const protectionAmounts: InforceProtectionAmountsType[] = [];

  if (formValues?.hasLifeInsuranceCoverage) {
    protectionAmounts?.push({
      amount: Number(formValues?.lifeInsuranceCoverageAmount || null),
      protectionType: TypeProtectionHistoriqueEnum.Vie,
    });
  }

  if (formValues?.hasCriticalIllnessCoverage) {
    protectionAmounts?.push({
      amount: Number(formValues?.criticalIllnessCoverageAmount || null),
      protectionType: TypeProtectionHistoriqueEnum.MaladieGrave,
    });
  }

  if (formValues?.hasDisabilityInsuranceCoverage) {
    protectionAmounts?.push({
      amount: Number(formValues?.disabilityInsuranceCoverageAmount || null),
      protectionType: TypeProtectionHistoriqueEnum.Invalidite,
    });
  }

  return protectionAmounts;
};

export default getProtectionAmounts;
