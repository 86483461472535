import Action from '~/interfaces/actions';
import { InsurabilityDeclarationState } from '~/interfaces/InsurabilityDeclaration/State';
import { SetLoading } from '../InsurabilityDeclaration.actions';

export default function setLoading(state: InsurabilityDeclarationState, action: Action): InsurabilityDeclarationState {
  const { loading } = action as SetLoading;

  if (state.loadingCount === 0 && loading === false) {
    throw new Error('Loader count can\'t be below 0, every SetLoading(true) should be follow by a SetLoading(false)');
  }

  const loadingCount = loading === true ?
    Number(state.loadingCount) + 1 : Number(state.loadingCount) - 1;

  return {
    ...state,
    loadingCount,
  };
}
