import { Form, Spacing } from 'ia-react-core';
import React, { ChangeEvent, FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { State } from '~/interfaces/State';
import { InsuranceProduct } from '~/interfaces/InsuranceProduct';
import { StyledOtherTermTextButton, StyledOtherTermTitle, StyledOtherTermContainer, StyledOtherTermSubContainer, StyledOtherTermNumberControl, StyledOtherTermYearsText } from './OtherTerm.styles';
import handleFormatValueToState from './utilities/handleFormatValueToState';
import { OTHER_TERM_REGEX } from '../../constants/InsurancePageConstants';
import isValidTerm from './utilities/isValidTerm';

export interface OtherTermProps {
  item: InsuranceProduct;
  onClick: Function;
}

const OtherTerm: FC<OtherTermProps> = ({ item, onClick }) => {
  const lang = useSelector((state: State) => state?.language?.locale);
  const localeProp = lang === 'en' ? 'descriptionEn' : 'descriptionFr';
  const { t } = useTranslation('CreateIllustration');
  const [term, setTerm] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    if (OTHER_TERM_REGEX.test(value)) setTerm(value);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = event.clipboardData.getData('text');
    if (!OTHER_TERM_REGEX.test(pastedValue?.toString())) event.preventDefault();
  };

  return (
    <StyledOtherTermContainer>
      <StyledOtherTermTitle>{item[localeProp]}</StyledOtherTermTitle>
      <StyledOtherTermSubContainer>
        <Spacing m-r={8}>
          <Form name="otherTermForm" hideErrorMessages cleanValues>
            <StyledOtherTermNumberControl
              name="otherTerm"
              defaultValue={term}
              onChange={(event) => handleChange(event)}
              formatValueToState={handleFormatValueToState}
              onPaste={handlePaste}
              onFocus={(event) => event.target.select()}
            />
          </Form>
        </Spacing>

        <Spacing m-r={16}>
          <StyledOtherTermYearsText>{t('years')}</StyledOtherTermYearsText>
        </Spacing>

        <StyledOtherTermTextButton
          onClick={() => isValidTerm(Number(term)) && onClick(item, Number(term))}
          disabled={!isValidTerm(Number(term))}
        >
          {t('choose')}
        </StyledOtherTermTextButton>
      </StyledOtherTermSubContainer>
    </StyledOtherTermContainer>
  );
};

export default OtherTerm;
