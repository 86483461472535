import { Action } from 'redux';
import { call, put, select } from 'redux-saga/effects';
import { fatalError, UpdatePacPadAgreement, setUpdating, loadApplicationSuccess } from '../ApplicationFragment.actions';
import ApplicationModel from '../interfaces/ApplicationModel';
import updatePacPadAgreement from '../api/updatePacPadAgreement';
import selectTargetRoute from '../selectors/selectTargetRoute';
import getApplication from '../api/getApplication';

export default function* UpdatePacPadAgreementSaga(action: Action) {
  const { payload } = UpdatePacPadAgreement.getData(action);

  const { body, saleId, onUpdate } = payload;
  yield put(setUpdating(true));
  try {
    yield call(updatePacPadAgreement, { saleId, body });

    let application: ApplicationModel = null;
    application = yield call(getApplication, { saleId });

    yield put(loadApplicationSuccess(application));

    const targetRoute: string = yield select(selectTargetRoute);
    if (onUpdate) {
      onUpdate(targetRoute);
    }
  } catch (ex) {
    yield put(fatalError('Fatal error while loading application data', ex));
  } finally {
    yield put(setUpdating(false));
  }
}
