import React, { FC, useMemo } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { useDispatch } from 'react-redux';
import ChangeRequestBuilder from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/builders/ChangeRequestBuilder';
import ChangeRequestDto from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/dtos/ChangeRequestDto';
import ChangeRequestModel from '~/interfaces/InsurabilityDeclaration/ChangeRequestModel';
import { loadChangeRequestSuccessAction } from '../ChangeRequest.actions';

export interface RemoveChangeRequestFormProps{
  saleId: string;
  formId: string;
  method: 'DELETE' | 'PUT';
  onSuccess?: (response: ChangeRequestModel) => void;
}

const RemoveChangeRequestForm: FC<RemoveChangeRequestFormProps> = ({ saleId, formId, method, onSuccess }) => {
  const dispatch = useDispatch();
  const removeChangeRequestFormUrl = useMemo(() => getBffUrlForRouteWithoutQP(
    BffRoute.change_request_form_by_id,
    { saleId, formId },
    false,
  ), [saleId, formId]);

  return (
    <Resource
      name="resources.remove_change_request_form"
      url={removeChangeRequestFormUrl}
      method={method}
      onSuccess={(result) => {
        const builder = new ChangeRequestBuilder();
        const dto = result.data as ChangeRequestDto;
        const model = builder.toModel(dto);
        dispatch(loadChangeRequestSuccessAction(model));
        if (onSuccess) onSuccess(model);
      }}
    />
  );
};

export default RemoveChangeRequestForm;
