import { injectReducer, injectTranslations } from 'ia-react-core';
import { compose } from 'redux';
import { ComponentClass } from 'react';
import fr from './translations/fr';
import en from './translations/en';
import PrintReportModal from './PrintReportModal';
import printReportModalReducer from './PrintReportModal.reducer';

export default compose<ComponentClass>(
  injectTranslations('PrintReportModal', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  injectReducer({
    key: 'components.PrintReportModal',
    reducer: printReportModalReducer,
    useScope: true,
  }),
)(PrintReportModal);
