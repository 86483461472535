import { PAGE_ID_APPLICATION_SIGNATUREMODE, PAGE_ID_APPLICATION_SIGNATURE_FOLLOW_UP, PAGE_ID_APPLICATION_TRANSMISSION, PAGE_ID_ECHANGE_SIGNATUREMODE, PAGE_ID_ECHANGE_SIGNATURE_FOLLOW_UP, PAGE_ID_ECHANGE_TRANSMISSION } from '~/constants/PAGE_IDS';
import { AdditionalDetails } from '~/interfaces/AdditionalDetails';

export enum pageType {
  illustration = 'illustration',
  application = 'application',
  changeRequest = 'changeRequest',
  unknown = 'unknown',
}

// BE Mapping (SalePageTypeEnum) for last accessed page id
export const lastAccessedPageId = {
  0: 'illustrationCoverages',
  1: 'illustrationInvestorProfile',
  2: 'illustrationInvestment',
  3: 'illustrationConcepts',
  4: 'illustrationResults',
  5: 'illustrationReports',
  6: 'illustration_summary',
  7: 'application_advisor',
  8: 'application_nominee_identification',
  9: 'application_nominee_insurance_history',
  10: 'application_nominee_beneficiaire',
  11: 'application_nominee_declarations',
  12: 'application_nominee_special_instructions',
  13: 'application_applicant',
  14: 'application_requirements',
  15: 'application_coverages',
  16: 'application_linked_applications',
  17: 'application_billing',
  18: 'application_pac_pad_agreement',
  19: 'application_validation',
  20: 'application_signature',
  [PAGE_ID_APPLICATION_TRANSMISSION]: 'application_transmission',
  22: 'changeRequest',
  23: 'changeRequestAdvisors',
  24: 'changeRequestHeadOffice',
  25: 'changeRequestSummary',
  26: 'changeRequestValidation',
  [PAGE_ID_ECHANGE_SIGNATUREMODE]: 'changeRequestSignatureMode',
  [PAGE_ID_ECHANGE_SIGNATURE_FOLLOW_UP]: 'changeRequestSignatureFollowUp',
  30: 'application_headoffice',
  31: 'application_nominee_consent',
  32: 'application_nominee_results',
  33: 'changeRequest_reinstatement',
  [PAGE_ID_ECHANGE_TRANSMISSION]: 'changeRequest_transmission',
  [PAGE_ID_APPLICATION_SIGNATUREMODE]: 'applicationSignatureMode',
  [PAGE_ID_APPLICATION_SIGNATURE_FOLLOW_UP]: 'applicationSignatureFollowUp',
};

// BE Mapping (LastAccessedDetailsTypesEnum) for last accessed details type
export const lastAccessedDetailTypes = {
  0: 'clientId',
  1: 'scenarioId',
};

export enum lastAccessedPageRoute {
  // Illustration pages
  illustrationCoverages_fr ='/illustration/{saleId}/couverture',
  illustrationCoverages_en ='/illustration/{saleId}/coverage',

  illustrationInvestorProfile_fr ='',
  illustrationInvestorProfile_en ='',

  illustrationInvestment_fr ='',
  illustrationInvestment_en ='',

  illustrationConcepts_fr ='',
  illustrationConcepts_en ='',

  illustrationResults_fr ='/illustration/{saleId}/resultats',
  illustrationResults_en ='/illustration/{saleId}/results',

  illustrationReports_fr ='/illustration/{saleId}/rapports',
  illustrationReports_en ='/illustration/{saleId}/rapports',

  illustration_summary_fr ='',
  illustration_summary_en ='',

  // Application pages
  application_summary_fr = '/application/{saleId}/resume',
  application_summary_en = '/application/{saleId}/summary',

  application_advisor_fr = '/application/{saleId}/conseiller',
  application_advisor_en = '/application/{saleId}/advisor',

  application_nominee_fr = '/application/{saleId}/candidat',
  application_nominee_en = '/application/{saleId}/nominee',

  application_applicant_fr = '/application/{saleId}/contractant',
  application_applicant_en = '/application/{saleId}/applicant',

  application_billing_fr = '/application/{saleId}/facturation',
  application_billing_en = '/application/{saleId}/billing',

  application_pac_pad_agreement_fr = '/application/{saleId}/accord-pac-pad',
  application_pac_pad_agreement_en = '/application/{saleId}/pac-pad-agreement',

  application_coverages_fr = '/application/{saleId}/protections',
  application_coverages_en = '/application/{saleId}/coverages',

  application_requirements_fr = '/application/{saleId}/exigences',
  application_requirements_en = '/application/{saleId}/requirements',

  application_transmission_fr = '/application/{saleId}/transmission',
  application_transmission_en = '/application/{saleId}/transmission',

  changeRequest_transmission_fr = '/application/{saleId}/transmission',
  changeRequest_transmission_en = '/application/{saleId}/transmission',

  application_linked_applications_fr = '/application/{saleId}/applications-liees',
  application_linked_applications_en = '/application/{saleId}/linked-applications',

  application_validation_fr = '/application/{saleId}/validation',
  application_validation_en = '/application/{saleId}/validation',

  application_signature_fr = '/application/{saleId}/signature',
  application_signature_en = '/application/{saleId}/signature',

  application_headoffice_fr = '/application/{saleId}/social',
  application_headoffice_en = '/application/{saleId}/headoffice',

  // Application Nominee pages
  application_nominee_identification_fr = '/application/{saleId}/candidat/{clientId}/identification',
  application_nominee_identification_en = '/application/{saleId}/nominee/{clientId}/identification',

  application_nominee_insurance_history_fr = '/application/{saleId}/candidat/{clientId}/histoire-assurance',
  application_nominee_insurance_history_en = '/application/{saleId}/nominee/{clientId}/insurance-history',

  application_nominee_declarations_fr = '/application/{saleId}/candidat/{clientId}/declarations',
  application_nominee_declarations_en = '/application/{saleId}/nominee/{clientId}/declarations',

  application_nominee_declarations_form_fr = '/application/{saleId}/candidat/{clientId}/declarations/{formName}',
  application_nominee_declarations_form_en = '/application/{saleId}/nominee/{clientId}/declarations/{formName}',

  application_nominee_beneficiaire_fr = '/application/{saleId}/candidat/{clientId}/beneficiaire',
  application_nominee_beneficiaire_en = '/application/{saleId}/nominee/{clientId}/beneficiary',

  application_nominee_special_instructions_fr = '/application/{saleId}/candidat/{clientId}/instructions-speciales',
  application_nominee_special_instructions_en = '/application/{saleId}/nominee/{clientId}/special-instructions',

  // Application Nominee pages
  application_nominee_consent_fr = '/application/{saleId}/candidat/{clientId}/consentement',
  application_nominee_consent_en = '/application/{saleId}/nominee/{clientId}/consent',

  application_nominee_results_fr = '/application/{saleId}/candidat/{clientId}/resultats',
  application_nominee_results_en = '/application/{saleId}/nominee/{clientId}/results',

  // Change Request pages
  changeRequest_fr = '/application/{saleId}/echange/changements',
  changeRequest_en = '/application/{saleId}/echange/changes',

  changeRequestAdvisors_fr = '/application/{saleId}/echange/conseiller',
  changeRequestAdvisors_en = '/application/{saleId}/echange/advisor',

  changeRequestHeadOffice_fr = '/application/{saleId}/echange/ho-changement',
  changeRequestHeadOffice_en = '/application/{saleId}/echange/ho-echange',

  changeRequestSummary_fr = '/application/{saleId}/echange/sommaire',
  changeRequestSummary_en = '/application/{saleId}/echange/Summary',

  changeRequestValidation_fr = '/application/{saleId}/validationchangement',
  changeRequestValidation_en = '/application/{saleId}/validationechange',

  changeRequestSignatureMode_fr = '/application/{saleId}/signaturechangement/signature-mode',
  changeRequestSignatureMode_en = '/application/{saleId}/signatureechange/signature-mode',

  changeRequestSignatureFollowUp_fr = '/application/{saleId}/signaturechangement/signature-followup',
  changeRequestSignatureFollowUp_en = '/application/{saleId}/signatureechange/signature-followup',

  changeRequestSignatureTransmission_fr = '/application/{saleId}/transmissionchangement',
  changeRequestSignatureTransmission_en = '/application/{saleId}/transmissionechange',

  changeRequest_reinstatement_fr = '/application/{saleId}/reinstatement',
  changeRequest_reinstatement_en = '/application/{saleId}/reinstatement',

  applicationSignatureMode_fr = '/application/{saleId}/signature/signature-mode',
  applicationSignatureMode_en = '/application/{saleId}/signature/signature-mode',

  applicationSignatureFollowUp_fr = '/application/{saleId}/signature/signature-followup',
  applicationSignatureFollowUp_en = '/application/{saleId}/signature/signature-followup',

}

export const isPageUrlValid = (url: string): boolean => url?.length > 0 && !url.includes('{');

export const getPageType = (pageId: number): pageType => {
  const lastPageId: string = lastAccessedPageId[pageId as keyof typeof lastAccessedPageId];

  if (!lastPageId) {
    return pageType.unknown;
  }

  if (lastPageId.includes('illustration')) {
    return pageType.illustration;
  }

  if (lastPageId.includes('application')) {
    return pageType.application;
  }

  if (lastPageId.includes('changeRequest')) {
    return pageType.changeRequest;
  }

  return pageType.unknown;
};

export const getLastAccessedLocalizedUrl = (pageId: number, locale: string) => {
  const lastPageId: string = lastAccessedPageId[pageId as keyof typeof lastAccessedPageId];
  return (!lastPageId || !locale) ? '' : lastAccessedPageRoute[`${lastPageId}_${locale}` as keyof typeof lastAccessedPageRoute];
};

export const getLastAccessedPageParams = (saleId: string, additionalDetails: AdditionalDetails[]): Record<string, string> => {
  const params: Record<string, string> = { saleId };

  if (additionalDetails && additionalDetails?.length > 0) {
    additionalDetails.forEach((detail) => {
      const detailType: string = lastAccessedDetailTypes[detail.detailsType as keyof typeof lastAccessedDetailTypes];
      if (detailType) {
        params[detailType] = detail.detailsValue;
      }
    });
  }

  return params;
};

export const getLastAccessedRelativeUrl = (route: string, params?: Record<string, string>) => {
  let routeWithParams = route;
  if (params && routeWithParams) {
    Object.keys(params).forEach((key) => {
      routeWithParams = routeWithParams?.replace(`{${key}}`, params[key]);
    });
  }
  return routeWithParams;
};
