import React, { FC, useEffect } from 'react';
import {
  Drawer, requestResourceByName, ResourceLoader, useScopedSelector,
} from 'ia-react-core';
import { useDispatch } from 'react-redux';
import { ImportantMessage, ImportantMessageState } from '~/interfaces/ImportantMessage';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { useTranslation } from 'react-i18next';
import { format, parseISO, addMinutes } from 'date-fns';
import { NoOpenFilesFoundMessage } from '~/fragments/HomeFragment/components/SaleFile/SaleFileDataTable/components/NoOpenFilesFoundMessage';
import {
  StyledNotificationList, StyledNotificationUrgent, StyledNotificationBulletPoints, StyledStrongText, StyledNotificationInformation, StyledNotificationDivider, StyledNotificationContainer, StyledNotificationTitleSection, StyledNotificationTitle, StyledNotificationDate, StyledNotificationInnerContainer,
} from './NotificationDrawer.styles';
import { getLanguageDesc, getLanguageTitle, MarkdownRender } from './utilities';
import { BorderBox } from '../BorderBox';
import { NOTIFICATION_DRAWER_ID } from './NotificationDrawer.constants';

import './NotificationDrawer.css';

export const GET_IMPORTANT_MESSAGES_RESOURCE_NAME = 'getImportantMessages';

const getNotificationUrl = getBffUrlForRoute(BffRoute.important_messages, {}, false);

export interface NotificationDrawerProps {}

const NotificationDrawer: FC<NotificationDrawerProps> = () => {
  const { i18n: { language } } = useTranslation();
  const dispatch = useDispatch();

  const notificationData: ImportantMessage[] = useScopedSelector((state: ImportantMessageState) => state?.data?.getImportantMessages ?? []);
  const { t } = useTranslation('NotificationDrawer');

  useEffect(() => {
    if (notificationData.length > 0) {
      const unreadIds = notificationData?.filter((notification: ImportantMessage) => notification?.isRead === false)?.map((unread: ImportantMessage) => unread.id);
      if (unreadIds.length > 0) {
        dispatch(requestResourceByName('resources.updateReadNotification', unreadIds));
      }
    }
  }, [dispatch, notificationData]);

  return (
    <Drawer
      id={NOTIFICATION_DRAWER_ID}
      drawerTitle={t('importantMessages')}
      name="notifications"
      position="right"
      noOverlay
      noContentPadding
      headerPadding="16px"
      maxWidth={324}
      showModalXs
    >
      <Drawer.Content>
        <StyledNotificationDivider />

        <StyledNotificationContainer>
          <ResourceLoader
            autoRequest
            name={GET_IMPORTANT_MESSAGES_RESOURCE_NAME}
            url={getNotificationUrl}
            method="GET"
            getDataFromResponse={(response: ImportantMessage[]) => response}
            CustomNoDataMessage={NoOpenFilesFoundMessage}
          >
            {({ data }: { data: ImportantMessage[] }) => (
              <>
                { data.map((notification: ImportantMessage) => (
                  <BorderBox m-b={0}>
                    <StyledNotificationInnerContainer>
                      <StyledNotificationTitleSection>
                        { notification.severity === 'Urgent' &&
                        <StyledNotificationUrgent><StyledStrongText>{t('urgent')}</StyledStrongText></StyledNotificationUrgent>}

                        { notification.severity === 'Information' &&
                        <StyledNotificationInformation>{t('information')}</StyledNotificationInformation>}

                        <StyledNotificationTitle>
                          {getLanguageTitle(notification, language)}
                        </StyledNotificationTitle>

                        <StyledNotificationDate>
                          {format(addMinutes(parseISO(notification.date), new Date().getTimezoneOffset()), 'yyyy-MM-dd')}
                        </StyledNotificationDate>

                      </StyledNotificationTitleSection>

                      <StyledNotificationList>
                        <StyledNotificationBulletPoints>{MarkdownRender(getLanguageDesc(notification, language))}</StyledNotificationBulletPoints>
                      </StyledNotificationList>
                    </StyledNotificationInnerContainer>
                  </BorderBox>
                ))}
              </>
            )}
          </ResourceLoader>
        </StyledNotificationContainer>
      </Drawer.Content>
    </Drawer>

  );
};
export default NotificationDrawer;
