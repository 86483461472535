import { SideNavLink } from 'ia-react-core';
import styled from 'styled-components';

export const StyledSideNavigationMenuLink = styled(SideNavLink)`
  border-bottom: none;
  padding: 0px 0px 0px 32px;
  height: 40px;
  align-items: center;

  // Default left border color state is transparent
  border-left-color: transparent;

  // Colors on link hover
  &&&:hover {
    color: ${({ theme }) => theme?.colors?.blue5};
    background-color: ${({ theme }) => theme?.colors?.white};
    border-left: 3px solid transparent;
  }

  // Colors on click of hold click
  &&&&&:active {
    color: ${({ theme }) => theme?.colors?.blue10};
    background-color: ${({ theme }) => theme?.colors?.white};
    border-left: 3px solid ${({ theme }) => theme?.colors?.blue10};
  }

  // Colors on the active page link on click
  &&&&&.active:active {
    color: ${({ theme }) => theme?.colors?.blue10};
    background-color: ${({ theme }) => theme?.colors?.white};
    border-left: 3px solid ${({ theme }) => theme?.colors?.blue10};
  }

  // Colors on hover on the active page link
  &&&&.active:hover {
    border-left: 3px solid ${({ theme }) => theme?.colors?.blue5};
  }

  // Remove background color for focus
  &:focus {
    background-color: ${({ theme }) => theme?.colors?.white};
  }

  // Colors for the active page link in no particular state
  &.active {
    border-left: 3px solid ${({ theme }) => theme?.colors?.blue6};
    color: ${({ theme }) => theme?.colors?.blue6};
  }
`;
