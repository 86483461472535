import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { IsApplicationFetched } from '../ApplicationFragment.actions';

export default function isApplicationDataFetched(state: ApplicationFragmentState, action: Action): ApplicationFragmentState {
  const { loading } = action as IsApplicationFetched;

  return {
    ...state,
    isApplicationFetching: loading,
  };
}
