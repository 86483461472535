import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';

export interface HeadOfficeValidationResourceProps {
  saleId: string;
}

const HOValidadateApiName = 'headoffice_validate_error_message';

const HeadOfficeValidationResource: FC<HeadOfficeValidationResourceProps> = ({ saleId }: HeadOfficeValidationResourceProps) => (
  <Resource
    name={HOValidadateApiName}
    url={getBffUrlForRouteWithoutQP(BffRoute.validate_api_error_message, { saleId, formtype: 'headoffice' }, false)}
    method="GET"
  />
);

export default HeadOfficeValidationResource;
