import { FormattedAdditionalApplication, LinkedApplication, LinkedApplicationFormData, LinkedApplicationsPageState } from '~/interfaces/LinkedApplicationsPageState';
import IndividualModel from '~/fragments/ApplicationFragment/interfaces/IndividualModel';
import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';
import { LinkedApplicationEnum } from '../models/LinkedApplicationEnum';

export function formatApplicationData(data: LinkedApplication[], individuals: IndividualModel[]) {
  const outputFormat: FormattedAdditionalApplication[] = [];
  const applicantMap = new Map();
  // Iterate through each application in the input object
  data?.forEach((application) => {
    const name = individuals?.find((x) => x.id === application?.entiteNommeeId);
    const applicantName = `${name?.firstName} ${name?.lastName}` || 'Unknown';
    // If the applicantName is not in the map, add it with an empty array
    if (!applicantMap.has(applicantName)) { applicantMap.set(applicantName, { name: applicantName, data: [] }); }
    // Add the application data to the corresponding applicant in the map
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    applicantMap.get(applicantName).data.push({ id: application.id, applicationNumber: application.applicationNumber, entiteNommeeId: application.entiteNommeeId, linkType: application.linkType, applicantName: application.applicantName });
  }); // Convert the map values to an array (the final output format)

  outputFormat.push(...Array.from(applicantMap.values())); // Print the result

  return outputFormat;
}

export const formatInitialValues = (data: LinkedApplicationsPageState) => {
  const resultedData: LinkedApplicationFormData = {
    AdditionalApplications: data ? (data?.linkedApplications?.filter((x) => x.linkType === LinkedApplicationEnum.Additionnelle) || []) : [],
    LinkedApplications: data ? (data?.linkedApplications?.filter((x) => x.linkType !== LinkedApplicationEnum.Additionnelle) || []) : [],
  };

  return resultedData;
};

export const formatRequestToPost = (formData: LinkedApplicationFormData, intentType: ApplicationIntentType) => {
  const resultedData: LinkedApplicationsPageState = {} as LinkedApplicationsPageState;
  const data: LinkedApplication[] = Array.from(formData?.AdditionalApplications).concat(formData?.LinkedApplications);
  resultedData.intentType = intentType;
  resultedData.linkedApplications = data;
  return resultedData;
};

export const checkIfStartsWithZ = (value: string) => !value?.startsWith('Z');
