import { Action } from 'redux';
import { SetDuplicationCodeErrorAction } from '../AdvisorsModal.actions';
import { AdvisorsModalState } from '../AdvisorsSettingsModalState';

export default function setDuplicationCodeError(state: AdvisorsModalState, action: Action): AdvisorsModalState {
  const { codeValue } = action as SetDuplicationCodeErrorAction;

  return {
    ...state,
    agentCodeDuplication: codeValue,
  };
}
