import React from 'react';
import { PageNotFoundErrorMessage, ResourceLoader } from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import { FEATURE_TOGGLE_RESOURCE_NAME } from '~/constants';

const NoAccess = () => {
  const { t } = useTranslation('App');
  const redirectToEmpHomePage = () => window.open(process.env.REACT_APP_EMP_LOGIN_URL, '_self');

  return (
    <ResourceLoader
      resourceSrc={`resources.${FEATURE_TOGGLE_RESOURCE_NAME}`}
    >
      <PageNotFoundErrorMessage
        errorTitle={t('noAccessErrorTitle')}
        message={t('noAccessErrorMsg')}
        onButtonClick={redirectToEmpHomePage}
      />
    </ResourceLoader>
  );
};

export default NoAccess;
