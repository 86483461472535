import ExplanatoryTableColumnModel from './ExplanatoryTableColumnModel';
import ExplanatoryTableRowModel from './ExplanatoryTableRowModel';

export default class ExplanatoryTableModel {
  constructor(
    public id: string,
    public columns: ExplanatoryTableColumnModel[],
    public rows: ExplanatoryTableRowModel[],
  ) {}
}
