import { FC } from 'react';
import { injectTranslations } from 'ia-react-core';
import { compose } from 'redux';

import CreateIllustrationHelpModal, { CreateIllustrationHelpModalProps } from './CreateIllustrationHelpModal';

import fr from './translations/fr';
import en from './translations/en';

export default compose<FC<CreateIllustrationHelpModalProps>>(
  injectTranslations('CreateIllustrationHelpModal', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(CreateIllustrationHelpModal);

export * from './CreateIllustrationHelpModal';
