import React, { ComponentType, FC, ReactNode } from 'react';
import { RouteDefinitions, StructuredRouter, Visible, WorkflowRouteDefinitions, WorkflowSideNav } from 'ia-react-core';
import { StyledWorkflowLayoutContainer, StyledWorkflowLayoutSideNavContainer, StyledWorkflowLayoutContent } from './WorkflowLayout.styles';
import { WorkflowLayoutWrapper } from './components/WorkflowLayoutWrapper';
import { CustomTitleLogic } from './components/WorkflowLayoutTitleLabelResolver';

export interface WorkflowLayoutProps {
  routes: RouteDefinitions;
  sideNavRoutes?: WorkflowRouteDefinitions;
  defaultPage?: ComponentType;
  beforeSideNavContent?: ReactNode;
  afterSideNavContent?: ReactNode;
  customTitleLogic?: CustomTitleLogic;
}

const WorkflowLayout: FC<WorkflowLayoutProps> = ({ routes, sideNavRoutes, defaultPage, beforeSideNavContent, afterSideNavContent, customTitleLogic }) => {
  const workflowSideNavRoutes = <WorkflowSideNav routes={sideNavRoutes} />;
  return (
    <StyledWorkflowLayoutContainer>

      {/* Side nav */}
      <Visible lg xl xxl>
        <StyledWorkflowLayoutSideNavContainer>
          {beforeSideNavContent}
          {workflowSideNavRoutes}
          {afterSideNavContent}
        </StyledWorkflowLayoutSideNavContainer>
      </Visible>

      {/* Content */}
      <StyledWorkflowLayoutContent>
        <WorkflowLayoutWrapper routes={routes} workflowSideNav={workflowSideNavRoutes} customTitleLogic={customTitleLogic}>
          <StructuredRouter routes={routes} DefaultPage={defaultPage} />
        </WorkflowLayoutWrapper>
      </StyledWorkflowLayoutContent>
    </StyledWorkflowLayoutContainer>
  );
};

export default WorkflowLayout;
