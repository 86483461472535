import { requestResourceByName, Resource, ResourceProps } from 'ia-react-core';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SAVE_LAST_PRODUCT_CATEGORY_TYPE } from '../constants';

export interface SaveLastProductCategoryTypeProps extends ResourceProps<null, null> {
  saveLastProductUrl: string;
}

const SaveLastProductCategoryType: FC<SaveLastProductCategoryTypeProps> = ({ saveLastProductUrl, ...props }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (saveLastProductUrl) { dispatch(requestResourceByName(`resources.${SAVE_LAST_PRODUCT_CATEGORY_TYPE}`)); }
  }, [dispatch, saveLastProductUrl]);

  return (
    <Resource
      name={SAVE_LAST_PRODUCT_CATEGORY_TYPE}
      method="POST"
      url={saveLastProductUrl}
      {...props}
    />
  );
};

SaveLastProductCategoryType.displayName = 'SaveLastProductCategoryType';

export default SaveLastProductCategoryType;
