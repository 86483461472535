import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateRouteStatus } from '~/App.actions';
import getStatusFromValidationMessages from '~/fragments/ApplicationFragment/utilities/getStatusFromValidationMessages';
import { State } from '~/interfaces/State';
import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';
import { useHistory } from 'react-router';
import selectTargetRoute from '~/fragments/ApplicationFragment/selectors/selectTargetRoute';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import { useChangeRoute } from '~/fragments/ApplicationFragment/hooks/useChangeRoute';
import { CHANGES_ROUTES_KEY } from '~/constants/CHANGES_ROUTES';
import { ValidationMessageLevel } from '~/interfaces/ValidationMessageLevel';
import { APPLICATION_ROUTES_KEY } from '~/constants/APPLICATION_ROUTES';

export interface ValidateErrorMessageResourceProps {
  saleId: string;
  formtype: string;
  onApiSuccess?: (isValidationError: boolean) => void;
}

const ValidadateApiName = 'validate_api_error_message';

const ValidateErrorMessageResource: FC<ValidateErrorMessageResourceProps> = ({ saleId, formtype, onApiSuccess }: ValidateErrorMessageResourceProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentRoute = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.values?.routePath?.currentRoute);
  const targetRoute: string = useSelector(selectTargetRoute);
  const targetRef = useRef<string | null>(null);
  const localizedURI = useChangeRoute();

  useEffect(() => {
    targetRef.current = targetRoute;
  }, [targetRoute]);

  let intentType: ApplicationIntentType;
  let initateFromLeftMenu = true;

  const onSuccessHandle = useCallback((response: AxiosResponse<{validationMessages: ValidationMessage[]}>) => {
    const hasError = response?.data?.validationMessages?.some((message) => message.categorie === ValidationMessageLevel.Error);
    const status = getStatusFromValidationMessages(response?.data?.validationMessages);
    dispatch(updateRouteStatus(currentRoute, status));
    if (!hasError && intentType === ApplicationIntentType.SaveAndValidate && !initateFromLeftMenu) {
      history.push(formtype === APPLICATION_ROUTES_KEY.HEADOFFICE ? localizedURI(CHANGES_ROUTES_KEY.ADVISOR) : targetRef?.current);
      if (onApiSuccess) {
        onApiSuccess(false);
      }
    }
  }, [dispatch, currentRoute, targetRef, saleId, history, onApiSuccess, formtype, localizedURI]);

  return (
    <Resource
      name={ValidadateApiName}
      url={getBffUrlForRouteWithoutQP(BffRoute.validate_api_error_message, { saleId, formtype }, false)}
      transformRequest={(
        payloadData: { intentType: ApplicationIntentType; initateFromLeftMenu: boolean },
      ) => {
        intentType = payloadData?.intentType ?? ApplicationIntentType.SaveAndValidate;
        initateFromLeftMenu = payloadData?.initateFromLeftMenu ?? false;
        return payloadData;
      }}
      method="GET"
      onSuccess={onSuccessHandle}
    />
  );
};

export default ValidateErrorMessageResource;
