import openInBrowser from '~/utilities/openInBrowser';

export default function openMedicalRequirements(localeGlobal: string) {
  const englishUrl = process.env.REACT_APP_ENG_MED_RQR_DOC_LINK;
  const frenchUrl = process.env.REACT_APP_FRA_MED_RQR_DOC_LINK;
  if (localeGlobal === 'en') {
    openInBrowser(englishUrl);
  } else {
    openInBrowser(frenchUrl);
  }
}
