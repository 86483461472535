import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { AutoSuggestResourceInput } from 'ia-react-core';
import { AxiosRequestConfig } from 'axios';
import { AppState } from '~/interfaces/AppState';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import QuestionField from './QuestionField';

interface LookupDto {
  $id: string;
  answers: Array<string>;
}

interface LookupModel {
  label: string;
  value: string;
}

const toModel = ({ answers }: LookupDto): LookupModel[] => answers.map<LookupModel>((answer) => ({
  label: answer,
  value: answer,
}));

const axiosConfig: AxiosRequestConfig = {
  withCredentials: true,
  responseType: 'json',
  transformResponse: toModel,
};

const LookupField: QuestionField = ({ question, onChange, hasError, hasWarning }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const selectedCase = useSelector((s: AppState) => s.App?.fragments?.ApplicationFragment?.changeRequest);
  const caseId = selectedCase?.id;
  const defaultValue = question?.answers?.[0]?.value as string;
  const [questionId, setQuestionId] = useState<string>('');

  useEffect(() => {
    if (question.id) setQuestionId(question.id);
  }, [question.id]);

  const debounceFn = debounce((newValue: string) => {
    onChange([newValue]);
    inputRef.current.focus();
  }, 100, { trailing: true });

  const suggestedAnsUrl = useMemo(() => getBffUrlForRouteWithoutQP(
    BffRoute.suggested_answer,
    { caseId, questionId },
    false,
  ), [caseId, questionId]);

  const onSearchHandle = useCallback((newValue: string) => {
    if (newValue) {
      debounceFn(newValue);
    } else {
      onChange([]);
    }
  }, [onChange, debounceFn]);

  return (
    <AutoSuggestResourceInput
      hasWarning={hasWarning}
      name={question.id}
      value={defaultValue}
      url={suggestedAnsUrl}
      searchParam="phrase"
      config={axiosConfig}
      onSearch={onSearchHandle}
      acceptFreeText
      requestOnLanguageChange
      clearButton
      isTouched
      isValid={!hasError}
      inputRef={inputRef}
    />
  );
};

export default LookupField;
