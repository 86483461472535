import useQuery from '~/hooks/useQuery';
import useScenarios from './useScenarios';

const useScenario = () => {
  const query = useQuery();

  const scenarioId = query.get('scenarioId');

  const scenarios = useScenarios();

  if (!scenarios) return null;

  if (!scenarioId) return scenarios[0];

  return scenarios.find((scenario) => scenario.idDemande === scenarioId);
};

export default useScenario;
