import React, { FC } from 'react';
import { Resource, closeModalByName } from 'ia-react-core';
import { useDispatch } from 'react-redux';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { SET_OIPA_ENVIRONMENTS_RESOURCE_NAME } from '../../constants/constants';
import { SetOipaEnvironmentsResourcePropsInterface } from './interfaces/SetOipaEnvironmentsResourcePropsInterface';

const SetOipaEnvironmentsResource: FC<SetOipaEnvironmentsResourcePropsInterface> = ({ env }) => {
  const dispatch = useDispatch();
  const url = getBffUrlForRoute(BffRoute.set_oipa_environments, { oipaEnvironment: env });

  return (
    <Resource
      name={SET_OIPA_ENVIRONMENTS_RESOURCE_NAME}
      url={url}
      method="PUT"
      onSuccess={() => dispatch(closeModalByName('modals.oipaEnvironments'))}
    />
  );
};

export default SetOipaEnvironmentsResource;
