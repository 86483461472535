import {
  AddCircleIcon,
  COLORS,
  Divider, Form, FormCol, FormRow, ManIcon, Modal, PrimarySubmitButton, RadioListControl, SecondaryButton, SecondaryTextIconButton, Spacing, SplitDateControl, T2, TextControl, ToggleButtonListOption, ToggleSwitchControl, WomanIcon, closeModalByName, useScopedSelector,
} from 'ia-react-core';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MAX_DATE, MIN_DATE } from '~/constants/APP_CONSTANTS';
import { INSUREDNAME_REFORMAT_REGEX } from '~/fragments/ApplicationFragment/constants/regex';
import { Locale, useCurrentLocale } from '~/hooks/useCurrentLocale';
import { InsuredLanguage } from '~/fragments/ApplicationFragment/interfaces/enums';

import { StyledActionBar, StyledPromo, StyledWrapperAddInsured } from './ManageInsuredModal.styles';
import { Applicant, DownloadState } from '../../DownloadState';
import { setDownloadAction } from '../../MakeEchangeModal.actions';
import { StyledButtonWrapper } from '../ContractInsuredModal/ContractInsuredModal.styles';

export interface ManageInsured {
}

export const MANAGE_INSURED = 'manageInsured';

const ManageInsuredModal = () => {
  const { t } = useTranslation('makeEchange');
  const dispatch = useDispatch();
  const downloadFormValues = useScopedSelector((state: DownloadState) => state?.values?.downloadData);
  const insuredIndex = useScopedSelector((state: DownloadState) => state?.modals?.manageInsured?.data?.index);
  const isEdit = useScopedSelector((state: DownloadState) => state?.modals?.manageInsured?.data?.editUser);

  const language = useCurrentLocale();
  const [addExtraInsured, setAddExtraInsured] = useState(false);

  const getInsuredGender = (insured: Applicant) => {
    if (downloadFormValues.language === InsuredLanguage.English) {
      return insured.sex === 'Masculin' ? 'Male' : 'Female';
    }
    return insured.sex;
  };

  const options = useMemo<ToggleButtonListOption<Applicant>[]>(() => downloadFormValues?.insureds?.map((insured: Applicant) => ({
    label: `${insured.name} - ${getInsuredGender(insured)} - ${new Date(insured.birthDate).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })}`,
    value: insured,
  })), [t, downloadFormValues]);

  const closeModal = () => {
    setAddExtraInsured(false);
    dispatch(closeModalByName(`modals.${MANAGE_INSURED}`));
  };

  const saveInsured = useCallback((insuredData: any) => {
    let downloadCopy;
    if (isEdit) {
      const selectedApplicants = [...downloadFormValues.selectedApplicants];
      selectedApplicants[insuredIndex] = insuredData;
      downloadCopy = { ...downloadFormValues, selectedApplicants };
    } else if (insuredData?.selectedInsured) {
      const insuredLatest = downloadFormValues?.insureds?.filter((insured: Applicant) => insured.id !== insuredData.selectedInsured.id);
      downloadCopy = { ...downloadFormValues, selectedApplicants: [...downloadFormValues.selectedApplicants, insuredData.selectedInsured], insureds: insuredLatest };
    } else {
      downloadCopy = { ...downloadFormValues, selectedApplicants: [...downloadFormValues.selectedApplicants, { ...insuredData, id: '00000000-0000-0000-0000-000000000000' }] };
    }
    dispatch(setDownloadAction({
      ...downloadCopy,
    }));
    closeModal();
  }, [downloadFormValues, isEdit, insuredIndex]);

  return (
    <Modal name={MANAGE_INSURED}>
      <StyledWrapperAddInsured>
        <T2>{t('insuredtitle')}</T2>
        <Form
          name="selectedApplicants"
          onSubmit={saveInsured}
          hoistErrors
          hideErrorHoistedAnchor
          errorMessageTitle={t('errorTitle')}
          isolate
          cleanValues
        >
          { !isEdit &&
          <>
            <FormRow>
              <FormCol col={12} label={t('whoIsDesignateInsureds')}>
                <RadioListControl
                  key={downloadFormValues?.id}
                  options={options}
                  defaultValue={null}
                  name="selectedInsured"
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <StyledButtonWrapper>
                  <SecondaryTextIconButton
                    className="addinsured"
                    LeftIcon={AddCircleIcon}
                    iconSize={24}
                    color={COLORS.BLUE5}
                    onClick={() => setAddExtraInsured(true)}
                  >
                    {t('addApplicant')}
                  </SecondaryTextIconButton>
                </StyledButtonWrapper>
              </FormCol>
            </FormRow>
          </>}
          {(isEdit || addExtraInsured) && (
            <>
              <FormRow>
                <FormCol col={12}>
                  <StyledPromo data-testid="insured-title">{isEdit ? t('modifyuser') : t('addUser')}</StyledPromo>
                </FormCol>
              </FormRow>
              <FormRow>
                {
                  ['lastName', 'firstName'].map((fieldName: string) => {
                    let value = '';
                    if (isEdit) {
                      value = fieldName === 'lastName' ? downloadFormValues?.selectedApplicants[insuredIndex].lastName : downloadFormValues?.selectedApplicants[insuredIndex]?.firstName;
                    }
                    return (
                      <FormCol label={t([fieldName.toLocaleLowerCase()])} key={fieldName}>
                        <TextControl
                          maxLength={fieldName === 'lastName' ? 35 : 25}
                          name={fieldName}
                          required
                          defaultValue={value}
                          validationMessages={{ required: t(`errors.${fieldName.toLocaleLowerCase()}`) }}
                          valueToStateFormatters={[
                            (v) => v.replace(INSUREDNAME_REFORMAT_REGEX, ''),
                          ]}
                        />
                      </FormCol>
                    );
                  })
                }
              </FormRow>
              <FormRow>
                <FormCol label={t('dob')}>
                  <SplitDateControl
                    name="birthDate"
                    className={language === Locale.FR && 'changeorder'}
                    noLabel
                    required
                    dayPlaceholder="DD"
                    yearPlaceholder={t('yearplaceholder')}
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                    monthEmptyLabel={t('monthplaceholder')}
                    defaultValue={isEdit ? new Date(downloadFormValues?.selectedApplicants[insuredIndex]?.birthDate) : undefined}
                    validators={{
                      dobfuture: {
                        validator: (val) => !(val && new Date(val) > new Date()),
                        type: 'error',
                      },
                    }}
                    validationMessages={{
                      required: t('errors.birthDate'),
                      dobfuture: t('errors.dobfuture'),
                      incomplete: t('errors.birthDate'),
                      minYear: t('errors.invalidDate'),
                    }}
                  />
                </FormCol>
                <FormCol label={t('gender')} col={1} className="gendercolumn">
                  <ToggleSwitchControl
                    className="toggleswitch"
                    name="sex"
                    defaultValue={downloadFormValues?.selectedApplicants[insuredIndex]?.sex || null}
                    options={[
                      { label: <ManIcon />, value: 'Masculin' },
                      { label: <WomanIcon />, value: 'Feminin' },
                    ]}
                    required
                    validationMessages={{
                      required: t('errors.sex'),
                    }}
                  />
                </FormCol>
              </FormRow>
            </>
          )}
          <Divider />
          <Spacing m-b={16} />
          <StyledActionBar>
            <PrimarySubmitButton>{t('save')}</PrimarySubmitButton>
            <SecondaryButton onClick={closeModal}>{t('cancel')}</SecondaryButton>
          </StyledActionBar>
        </Form>
      </StyledWrapperAddInsured>
    </Modal>
  );
};

export default ManageInsuredModal;
