import { injectTranslations } from 'ia-react-core';
import { FC } from 'react';
import { compose } from 'redux';
import fr from './translations/fr';
import en from './translations/en';
import DeleteModal, { DeleteModalProps } from './DeleteModal';

export default compose<FC<DeleteModalProps>>(
  injectTranslations('DeleteModal', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(DeleteModal);

export * from './DeleteModal';
