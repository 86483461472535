import styled from 'styled-components';

export const StyledBorderBlock = styled.div`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;

  border-radius: 4px;
  background: var(--bnd-white, #FFF);

  /* Table dropshadow */
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
`;
