import { useParams } from 'react-router';
import { useMemo } from 'react';
import IndividualModel from '~/fragments/ApplicationFragment/interfaces/IndividualModel';
import selectNominees from '~/fragments/ApplicationFragment/selectors/selectINominees';
import { useSelector } from 'react-redux';

export const useCurrentNominee = (isLegalChangeNomineeId = ''): IndividualModel => {
  const nominees = useSelector(selectNominees);
  const { clientId } = useParams<{ clientId: string }>();
  const id = isLegalChangeNomineeId !== '' ? isLegalChangeNomineeId : clientId;
  const currentNominee = useMemo(() => nominees?.find((nominee: IndividualModel) => nominee.id === id), [nominees, id]);
  return currentNominee;
};
