import { useMemo } from 'react';
import ValidationMessageModel from '~/interfaces/InsurabilityDeclaration/ValidationMessageModel';
import { ValidationMessageTypes } from '~/interfaces/InsurabilityDeclaration/enums';
import { useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState } from '~/interfaces/AppState';
import selectedForm from '~/fragments/SharedLibrary/selectors/selectedForm';
import { useTranslation } from 'react-i18next';

export const useValidationMessages = () => {
  const { formId } = useParams< {formId: string} >();
  const { t } = useTranslation('formloader');
  const currentForm = useSelector((state: AppState) => selectedForm(state, formId));
  const validationMessages = useMemo(() => currentForm?.validationMessages ?? [], [currentForm?.validationMessages]);
  const { pathname } = useLocation();

  const messageTypes = useMemo(() => ({
    notices: validationMessages.filter((m: ValidationMessageModel) => m.validationLevel === ValidationMessageTypes.Notice && Boolean(m.message)),
    warning: validationMessages.filter((m: ValidationMessageModel) => m.validationLevel === ValidationMessageTypes.Warning && Boolean(m.message)).map((m: ValidationMessageModel) => { const messageCopy = { ...m }; messageCopy.message += `&nbsp;<a href="${pathname}#q-${m.id}">${t('seeWarning')}</a>`; return messageCopy; }),
    error: validationMessages.filter((m: ValidationMessageModel) => m.validationLevel === ValidationMessageTypes.Error && Boolean(m.message)).map((m: ValidationMessageModel) => { const messageCopy = { ...m }; messageCopy.message += `&nbsp;<a href="${pathname}#q-${m.id}">${t('seeError')}</a>`; return messageCopy; }),
  }), [validationMessages, pathname]);

  return {
    validationMessages,
    messageTypes,
  };
};
