import React, { useCallback, useEffect, useMemo } from 'react';
import { DataErrorModal, Modal, closeModalByName, openModalByName, useScopedSelector } from 'ia-react-core';
import { useDispatch } from 'react-redux';
import { AdvisorModel } from '~/interfaces/InsurabilityDeclaration/AdvisorModel';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';
import AdvisorTableScreen from './components/AdvisorTableScreen/AdvisorTableScreen';
import DeleteAdvisorConfirmDialog from './components/DeleteAdvisorConfirmDialog/DeleteAdvisorConfirmDialog';
import { addSettingsAdvisorAction, editSettingsAdvisorAction, switchViewAction, setAdvisorToEditAction, deleteSettingsAdvisorAction, cleanFatalErrorAction, setDuplicationCodeErrorAction } from './AdvisorsModal.actions';
import { AdvisorModalView } from './constants';
import selectFatalError from './selectors/selectFatalError';
import selectViewMode from './selectors/selectViewMode';
import selectAdvisors from './selectors/selectAdvisors';
import selectCodeDuplicationError from './selectors/selectCodeDuplicationError';
import { SettingsAdvisorForm } from './components/SettingsAdvisorForm/SettingsAdvisorForm';

const AdvisorsModal = () => {
  const dispatch = useDispatch();
  const viewMode = useScopedSelector(selectViewMode);
  const fatalError = useScopedSelector(selectFatalError);
  const advisorList = useScopedSelector(selectAdvisors);
  const hasDuplicateError = useScopedSelector(selectCodeDuplicationError);

  useEffect(() => {
    if (!!fatalError && fatalError?.response?.status !== 409) {
      dispatch(closeModalByName('modals.advisorsModal'));
      dispatch(openModalByName('modals.fatalError'));
    }
  }, [dispatch, fatalError]);

  const resourceUrl = useMemo(() => getBffUrlForRoute(BffRoute.user_advisors, undefined, false), []);

  const handleOnFormSubmitAdd = useCallback((advisor: AdvisorModel) => dispatch(addSettingsAdvisorAction(advisor)), [dispatch]);
  const handleOnFormSubmitEdit = useCallback((advisor: AdvisorModel) => dispatch(editSettingsAdvisorAction(advisor)), [dispatch]);
  const handleOnBackButtonClick = useCallback(() => {
    dispatch(switchViewAction(AdvisorModalView.Table));
  }, [dispatch]);

  const handleOnCloseModal = useCallback(() => handleOnBackButtonClick(), []);

  const handleOnDeleteButtonClick = useCallback((advisor: AdvisorModel) => {
    dispatch(openModalByName('modals.deleteAdvisorConfirmDialog', {
      advisorId: advisor.id,
    }));
  }, [dispatch]);

  const handleOnAddAdvisorButtonClick = useCallback(() => {
    dispatch(switchViewAction(AdvisorModalView.Form));
  }, [dispatch]);

  const handleOnEditButtonClick = useCallback((advisor: AdvisorModel) => {
    dispatch(setAdvisorToEditAction(advisor));
    dispatch(switchViewAction(AdvisorModalView.FormEdit));
  }, [dispatch]);

  const handleOnConfirmDeletion = useCallback((advisorId: string) => {
    dispatch(deleteSettingsAdvisorAction(advisorId));
    dispatch(closeModalByName('modals.deleteAdvisorConfirmDialog'));
  }, [dispatch]);

  const handleOnClearDuplicationError = useCallback(() => {
    if (hasDuplicateError) {
      dispatch(setDuplicationCodeErrorAction(false));
    }
  }, [hasDuplicateError, dispatch]);

  return (
    <>
      <DataErrorModal
        name="fatalError"
        error={fatalError}
        onCancel={() => {
          dispatch(closeModalByName('modals.fatalError'));
          dispatch(cleanFatalErrorAction());
        }}
        onRetry={() => {
          dispatch(closeModalByName('modals.fatalError'));
          dispatch(openModalByName('modals.advisorsModal'));
        }}
      />

      <Modal name="advisorsModal" onClose={handleOnCloseModal}>
        {viewMode === AdvisorModalView.Table ?
          <AdvisorTableScreen
            onAddAdvisorButtonClick={handleOnAddAdvisorButtonClick}
            resourceUrl={resourceUrl}
            onDeleteButtonClick={handleOnDeleteButtonClick}
            onEditButtonClick={handleOnEditButtonClick}
          />
          :
          <SettingsAdvisorForm
            advisorList={advisorList}
            isOnEditingMode={viewMode === AdvisorModalView.FormEdit}
            onSubmitOnAdd={handleOnFormSubmitAdd}
            onSubmitOnEdit={handleOnFormSubmitEdit}
            onBackButtonClick={handleOnBackButtonClick}
            hasDuplicateError={hasDuplicateError}
            onClearDuplicationError={handleOnClearDuplicationError}
          />}

        <DeleteAdvisorConfirmDialog onConfirmDeletion={handleOnConfirmDeletion} />
      </Modal>
    </>
  );
};

export default AdvisorsModal;
