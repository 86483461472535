import { Locale } from '~/constants/APP_CONSTANTS';
import { NUMERIC } from '~/fragments/ApplicationFragment/constants/regex';

export default function formatNumericAndTrimLastCharacter(value: string | number, locale: string) {
  let result = value?.toString()?.slice(0, -1);
  if (NUMERIC.test(value?.toString())) {
    if (locale === Locale.FR) {
      result = value.toString().replace('.', ',');
    } else {
      result = value.toString();
    }
    localStorage.setItem('result', result);
  }

  return localStorage.getItem('result') ?? result;
}
