import { DateTime } from 'luxon';
import { Applicant, Download } from '../DownloadState';

export const convertToLocalDate = (data: any) => ({
  ...data,
  birthDate: data.birthDate === null ? null : `${DateTime.fromJSDate(data.birthDate).toFormat('yyyy-MM-dd')}T00:00:00Z`,
});

export const convertToLocalDateForInsureds = (data: Download) => ({
  ...data,
  insureds: data.insureds?.map((insured: Applicant) => ({
    ...insured,
    birthDate: insured.birthDate === null ? null : `${DateTime.fromJSDate(new Date(insured.birthDate)).toFormat('yyyy-MM-dd')}T00:00:00Z`,
  })),
});
