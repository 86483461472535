import { DataTable, IconButton, Divider, T5 } from 'ia-react-core';
import styled from 'styled-components';

export const StyledDataTable = styled(DataTable)`
  width: 100%;

  &.warning{
    border: solid 2px ${({ theme }) => theme?.colors?.orange2};
  }

  [role="columnheader"] {
    min-height: 50px;
    padding: 16px;
  }

  [role="cell"] {
    height: 47px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    word-break: normal;
  }

  & > div > div {
    & > div[role="columnheader"] {
      &:first-child {
        padding-right: 8px;
      }

      &:last-child {
        padding-left: 8px;
      }

      &:not(:first-child):not(:last-child) {
        padding: 16px 8px;
      }
    }

    
    & > div[role="cell"] {
      &:first-child {
        padding-right: 8px;
      }

      &:last-child {
        padding-left: 8px;
      }

      &:not(:first-child):not(:last-child) {
        padding: 12px 8px;
      }
    }
    
  }
`;

export const StyledButtonContainer = styled.div`
  flex: 1;
  text-align: right;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0px;
  height: 32px;
  width: 32px;
`;

export const StyledValue = styled(T5)`
  font-weight: 400;
`;

export const StyledMobileButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledDivider = styled(Divider)`
  margin: 24px 0;
`;

export const StyledAdvisorDataTableName = styled.div`
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 2; 
  overflow: hidden; 
  text-overflow: ellipsis; 
`;
