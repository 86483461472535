import { AxiosResponse, Method } from 'axios';
import { Resource } from 'ia-react-core';
import React from 'react';

export interface SharedResourcesProps {
  name: string;
  url?: string;
  method?: Method;
  isAutoRequest?: boolean;
  onSuccess?: (res: AxiosResponse) => void;
  onComplete?: () => void;
}
const SharedResources = ({ name, url = '', method = 'GET', isAutoRequest = false, onSuccess, onComplete }: SharedResourcesProps) => (
  <Resource
    name={name}
    method={method}
    url={url}
    autoRequest={isAutoRequest}
    onSuccess={onSuccess}
    onComplete={onComplete}
  />
);

export default SharedResources;
