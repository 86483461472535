import { Resource } from 'ia-react-core';
import React from 'react';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';
import { GET_PROVINCES_RESOURCE_NAME } from '../PremiumComparisonToolModal/constants';

const GetProvincesResource = () => {
  const url = getBffUrlForRoute(BffRoute.illustration_provinces);

  return (
    <Resource
      name={GET_PROVINCES_RESOURCE_NAME}
      method="GET"
      autoRequestIfEmpty
      url={url}
    />
  );
};

export default GetProvincesResource;
