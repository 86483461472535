import { CategoryProduct } from '~/interfaces/CategoryProduct';
import { InsuranceCategory } from '~/interfaces/InsuranceCategory';
import { InsuranceGroup } from '~/interfaces/InsuranceGroup';
import { InsuranceProduct } from '~/interfaces/InsuranceProduct';
import { LANGUAGE_EN, LANGUAGE_FR } from '~/constants/APP_CONSTANTS';
import { ASSURANCE, EMISSION, SIMPLIFIED_ISSUE_PRODUCT } from './constants';

export const getOrder = (index: number, name: string) => {
  if (name === 'T10 and T20') return 3;
  if (name === 'Permanent') return 2;
  return index;
};

export const getTranslatedValue = (data: InsuranceGroup | InsuranceCategory | CategoryProduct | InsuranceProduct, locale: string) =>
  (locale === LANGUAGE_EN) ? data?.descriptionEn : data?.descriptionFr;

export const getTranslatedFile = (data: InsuranceGroup | CategoryProduct, locale: string) =>
  (locale === LANGUAGE_EN) ? data?.infosProduit?.pathEn : data?.infosProduit?.pathFr;

export const displayInitialPage = (isMobile: boolean, currentPage: number) =>
  (currentPage === 1 || (!isMobile && currentPage === 0));

export const displaySideNav = (isMobile: boolean, currentPage: number) =>
  (isMobile && currentPage === 0) || !isMobile;

export const displayBackButton = (isMobile: boolean, currentPage: number) =>
  (isMobile && currentPage !== 0);

export const addActiveClass = (currentPage: number, pageId: number) => {
  if (currentPage === 0 && pageId === 1) {
    return 'active';
  }
  return currentPage === pageId ? 'active' : '';
};

export const getProductType = (data: CategoryProduct, locale: string) => {
  if (['ConceptBonSuccessoral', 'ConceptProprietePartagee'].includes(data.typeCategorie) && locale === LANGUAGE_EN) {
    return 'Concept';
  }
  if (['ConceptBonSuccessoral', 'ConceptProprietePartagee'].includes(data.typeCategorie) && locale === LANGUAGE_FR) {
    return 'le concept';
  }
  return '';
};

const getFormattedTranslatedValue = (value: string) => {
  let result = value;
  if (value.includes(ASSURANCE)) result = value.replace(ASSURANCE, "l'assurance");
  else if (value.includes(EMISSION)) result = value.replace(EMISSION, "l'émission");

  return result;
};

export const getLearnMoreLink = (text: string, data: CategoryProduct, locale: string) => {
  let ogTranslatedValue = (['ConceptBonSuccessoral', 'ConceptProprietePartagee'].includes(data.typeCategorie)) ? getTranslatedValue(data, locale) : getTranslatedValue(data, locale).toLowerCase();

  if (SIMPLIFIED_ISSUE_PRODUCT.includes(ogTranslatedValue)) {
    ogTranslatedValue = getSimplifiedIssueLabel(ogTranslatedValue, locale);
  }
  const productType = getProductType(data, locale);
  const translatedValue = !productType ?
    getFormattedTranslatedValue(ogTranslatedValue) :
    ogTranslatedValue;
  let formattedText = `${text} ${productType} ${translatedValue}`;

  if (locale === LANGUAGE_EN) formattedText = `${text} ${ogTranslatedValue} ${productType}`;

  return formattedText.trim();
};

const getSimplifiedIssueLabel = (text: string, locale: string) => {
  if (locale === LANGUAGE_EN) {
    return text.substring(0, 16);
  }

  return text.substring(10);
};
