import { put, call } from 'redux-saga/effects';
import { Action } from 'redux';
import { DeleteSettingsAdvisorAction, deleteSettingsAdvisorSuccessAction, fatalError, setLoadingAction } from '../AdvisorsModal.actions';
import { deleteSettingsAdvisor } from '../api/deleteSettingsAdvisor';

export function* deleteAdvisorSaga(action: Action) {
  const { advisorId } = action as DeleteSettingsAdvisorAction;
  yield put(setLoadingAction(true));
  try {
    yield call(deleteSettingsAdvisor, advisorId);
    yield put(deleteSettingsAdvisorSuccessAction(advisorId));
  } catch (error) {
    yield put(fatalError('Error while deleting an advisor', error));
  } finally {
    yield put(setLoadingAction(false));
  }
}
