import { enCA, frCA } from 'date-fns/locale';
import { format } from 'date-fns';

const formatDate = (date: string | Date, language: string) => {
  const locale = language === 'en' ? enCA : frCA;
  const dateFormat = language === 'en' ? 'MMMM d, yyyy' : 'd MMMM yyyy';
  const dateToFormat = date instanceof Date ? date : new Date(date);

  if (date != null) return format(dateToFormat, dateFormat, { locale });

  return null;
};

export default formatDate;
