import { RequirementOrderDetailViewModel } from '../interfaces';
import { defaultClientInformation } from './defaultClientInformation';

export const defaultRequirementOrderDetailView: RequirementOrderDetailViewModel =
 {
   individualId: '',
   isObtainedFromOtherCompany: null,
   otherCompanyName: '',
   referenceNumber: '',
   clientContactInformation: defaultClientInformation,
   interventionType: null,
   medicalRequirements: [],
 };
