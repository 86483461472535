import { GUID } from '~/interfaces/GUID';
import { Address } from 'ia-react-core';
import { Locale } from '../../hooks/useCurrentLocale';
import { Gender } from './enums';

export interface Province {
  code: string;
  codePostal: string;
  description: string;
  description_EN: string;
  description_FR: string;
  nomPays: string;
  type: 'P' | 'E';
}

export interface IssuedOrganization {
  code: string;
  descriptionFrench: string;
  descriptionEnglish: string;
  pieceID: string;
}

export interface FinancialInstitute {
  nomInstitution: string;
  numeroInstitution: string;
}

export interface ContingentOwnerModel {
  individualId?: GUID;
  lastName: string;
  firstName: string;
  name: string;
  gender: Gender | string | null;
  dateOfBirth: string;
}

export enum SeniorOfficialType {
  usResident = 1,
  taxStranger = 2,
  none = 3
}

export enum SourceOfFundsType {
  employeeIcome = 1,
  retirementIncome = 2,
  businessIncome = 3,
  investments = 4,
  savings = 5,
  loan = 6,
  inheritance = 7,
  other = 8
}

export enum OrgTypeType {
  corporation = 1,
  partnership = 2,
  trust = 3,
  noprofit = 4,
  other = 5,
}

export enum DocIdentificationType {
  driversLicense = 2,
  heathInsuranceCard = 3,
  governmentIdCard = 4,
  passport = 5,
  permanentResidentCard = 6,
  governmentPhotoCard = 7,
  enhancedIdCard = 8,
  none = 1
}

export enum ResDocIdentificationType {
  driversLicense = 9,
  investmentStatement = 16,
  noticeOfAssessment = 10,
  personalCheque = 14,
  propertyTax = 12,
  statementOfAccount = 15,
  utilityElectricity = 11,
  utilityTelecom = 13
}

export enum ClientVerificationMethodType {
  inPerson = 1,
  remotely = 2,
}

export interface ContingentSelectionForm {
  selectionContingent: {
    selectedContingent: ContingentOwnerModel;
  };
}

export interface ContingentCreateForm {
  addContingent: Partial<ContingentOwnerModel>;
}

export type ContingentFormValues = ContingentSelectionForm & ContingentCreateForm;

export interface LocalizedFormatDate {
  [Locale.EN]: string;
  [Locale.FR]: string;
}

export type ApplicantFormData<T = Address> = IForm<T>;

type IPremium = {
  isFinancedByOther: boolean;
  lender: string;
};

export type ITaxation = {
  isCorporationInUsa: boolean;
  ein: string;
  isOwnOrg: boolean;
  seniorOfficials: [SeniorOfficialType?, SeniorOfficialType?, SeniorOfficialType?];
  applicantId: string;
  isTaxResident: boolean;
  tinSsn: string;
  isTaxStranger: boolean;
  taxStrangers: {
    id: string;
    jurisdiction: string;
    tin: string;
  }[];
};

export interface IThirdPartyInd<T = Address> {
  id: string;
  indexRef: 0;
  referenceNomineeId: string;
  lastName: string;
  firstName: string;
  gender: Gender;
  dateOfBirth: Date;
  relationship: number;
  relationshipDesc: string;
  occupation: string;
  address: T;
  homePhone: string;
  cellPhone: string;
  workPhone: string;
  extension: string;
  email: string;
}

export interface IThirdPartyOrg<T = Address> {
  id: string;
  indexRef: 1;
  referenceNomineeId: string;
  name: string;
  relationship: number;
  relationshipDesc: string;
  lineOfBusiness: string;
  incorporationNb: string;
  placeOfIncorporation: string;
  address: T;
  homePhone: string;
  cellPhone: string;
  workPhone: string;
  extension: string;
  email: string;
}

export type ICrime<T = Address> = {
  isThirdParty: boolean;
  thirdPartyPersons?: IThirdPartyInd<T>[]; // submit to BFF by splitting thirdParties into thirdPartyPersons and thirdPartyOrgs
  thirdPartyOrgs?: IThirdPartyOrg<T>[]; // submit to BFF by splitting thirdParties into thirdPartyPersons and thirdPartyOrgs
  thirdParties: (IThirdPartyInd<T> | IThirdPartyOrg<T>)[];
  sourceOfFunds: SourceOfFundsType[];
  sourceOfFundsNote: string;
  isLumpSum: boolean;
  canIAReturnLumpSum: boolean;
  applicants: {
    applicantId: string;
    isApplicantPolitic: boolean;
    isApplicantForeign: boolean;
    isApplicantLeadOrg: boolean;
  }[];
};

export type SignatoryType<T = Address> = {
  id: string;
  referenceNomineeId: string;
  lastName: string;
  firstName: string;
  gender: Gender;
  dateOfBirth: Date;
  address: T;
  verificationMethod: ClientVerificationMethodType;
  verificationDate: Date;
  identificationDoc: string;
  nameOfIdentificationDoc: string;
  docNumber: string;
  issOrg: string;
  placeOfIssue: string;
  expiryDate: Date;
  isPieceValid: boolean;
  resIdentificationDoc: string;
  resDocNumber: string;
  resIssOrg: string;
  resPlaceOfIssue: string;
  resExpiryDate: Date;
};

export interface IForm<T = Address> {
  id: string;
  regulation: {
    premium: IPremium;
    taxations: ITaxation[];
    crime: ICrime<T>;
  };
  organization: {
    orgType: OrgTypeType;
    orgTypeOther: string;
  };
  identity: {
    signatories: SignatoryType<T>[];
  };
}
