import React, { FC, useCallback, useMemo } from 'react';
import { Resource, ResourceProps } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { updateSignatureElectronicFormAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';

export interface StartCeremonyResourceProps extends ResourceProps<null, null> {
  saleId: string;
  onApiFailure?(response: any): void;
}
const StartCeremonyResource: FC<StartCeremonyResourceProps> = ({ onApiFailure, saleId }) => {
  const StartCeremonyResourceUrl = useMemo(() => getBffUrlForRouteWithoutQP(
    BffRoute.signature_saleId_ceremony,
    { saleId },
    false,
  ), [saleId]);
  const dispatch = useDispatch();

  const handleSuccess = useCallback((response: AxiosResponse) => {
    dispatch(updateSignatureElectronicFormAction({
      ...response.data,
    }));
  }, [dispatch]);

  return (
    <Resource
      name="resources.signature_saleId_ceremony_application"
      url={StartCeremonyResourceUrl}
      method="POST"
      onSuccess={handleSuccess}
      onFailure={onApiFailure}
    />
  );
};

export default StartCeremonyResource;
