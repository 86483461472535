import axios from 'axios';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';
import logger from '~/utilities/logger';
interface GetApplicationOptions {
  saleId: string;
}

export default function getApplication({ saleId }: GetApplicationOptions) {
  return axios.get(getBffUrlForRoute(BffRoute.user_application, { saleId }))
    .then((result) => result.data)
    .catch((err) => logger.error(err));
}
