import { WORKFLOW_STATUSES, RouteDefinition, RouteSectionDefinition } from 'ia-react-core';
import { APPLICATION_ROUTES_KEY } from '~/constants/APPLICATION_ROUTES';
import IndividualModel from '../interfaces/IndividualModel';
import pathENFR from '../interfaces/PathENFR';
import { updateApplicationInnerRouteForRouteName } from './updateApplicationRoutes';
import PAGE_NAMES from '../constants/pageNames';
interface Item {
  Name: string;
  resourceKey: string | null;
  sequence: number;
  Level: number;
  isVisible: boolean;
  items: Item[];
  id?: string;
  DisplayName?: string;
  isEnabled?: boolean;
}

const setSubroutesInactive = (routes: RouteDefinition[]) => {
  if (!Array.isArray(routes)) return routes;
  return routes?.map((route) => ({ ...route, status: WORKFLOW_STATUSES.INACTIVE }));
};

export function generateInitialRoutesEChanges({
  applicationRoutesRecords,
  saleId,
  nominees,
  userRole,
  currentRoute,
  updateApplicationRoutes,
  generateWorkflowRoutesForAnNominee,
  data,
}: {
  applicationRoutesRecords: Record<APPLICATION_ROUTES_KEY, RouteDefinition | RouteSectionDefinition>;
  saleId: string;
  nominees: IndividualModel[];
  userRole: string;
  currentRoute: string;
  updateApplicationRoutes: Function;
  generateWorkflowRoutesForAnNominee: Function;
  data: Item[];
}) {
  const resourceKeys = new Map();
  const resourceKeyActive = new Map();
  data?.forEach((item) => {
    if (item.resourceKey !== null) {
      const key = item?.resourceKey?.toLowerCase();
      resourceKeys.set(key, item.isVisible);
      resourceKeyActive.set(key, item.isEnabled);
    }

    if (item.items && item.items.length > 0) {
      item.items.forEach((subItem) => {
        const key = subItem?.resourceKey?.toLowerCase();
        resourceKeys.set(key, subItem.isVisible);
        resourceKeyActive.set(key, subItem.isEnabled);
        if (subItem.items && subItem.items.length > 0) {
          subItem.items.forEach((subItemInner) => {
            const keyInner = `${subItemInner.resourceKey.toLowerCase()}-${subItemInner.id}`;
            resourceKeys.set(keyInner, subItemInner.isVisible);
            resourceKeyActive.set(keyInner, subItemInner.isEnabled);
          });
        }
      });
    }
  });
  const visibleKeys = new Set(data?.filter((item) => item.isVisible).map((item) => item.resourceKey));

  Object.keys(applicationRoutesRecords).forEach((key) => {
    if (!visibleKeys.has(key)) {
      delete applicationRoutesRecords[key as APPLICATION_ROUTES_KEY];
    }
  });

  (applicationRoutesRecords.echanges as RouteSectionDefinition)?.routes?.forEach((route: RouteDefinition, index: number) => {
    if (resourceKeys.get(route?.name) === false) {
      (applicationRoutesRecords.echanges as RouteSectionDefinition).routes.splice(index, 1);
    }
  });
  let applicationRoutesWithStatus;
  if (visibleKeys.has('echanges')) {
    applicationRoutesWithStatus = {
      ...applicationRoutesRecords.echanges,
      routes: (applicationRoutesRecords.echanges as RouteSectionDefinition)?.routes?.map((route: RouteDefinition) => {
        const isCurrentPath = (typeof route.path === 'string'
          ? route.path === currentRoute
          : (route?.path as pathENFR)?.fr === currentRoute || (route?.path as pathENFR)?.en === currentRoute);
        return {
          ...route,
          status: isCurrentPath ? WORKFLOW_STATUSES.IN_PROGRESS : WORKFLOW_STATUSES.INCOMPLETE,
        };
      }),
    };
    applicationRoutesRecords.echanges = applicationRoutesWithStatus;
    applicationRoutesWithStatus = {
      ...applicationRoutesRecords['signaturePage-echange'],
      routes: (applicationRoutesRecords['signaturePage-echange'] as RouteSectionDefinition)?.routes?.map((route: RouteDefinition) => {
        const isCurrentPath = (typeof route.path === 'string'
          ? route.path === currentRoute
          : (route?.path as pathENFR)?.fr === currentRoute || (route?.path as pathENFR)?.en === currentRoute);
        return {
          ...route,
          status: isCurrentPath ? WORKFLOW_STATUSES.IN_PROGRESS : WORKFLOW_STATUSES.INCOMPLETE,
        };
      }),
    };
    applicationRoutesRecords['signaturePage-echange'] = applicationRoutesWithStatus;
    applicationRoutesWithStatus = {
      ...applicationRoutesRecords.applications,
      routes: (applicationRoutesRecords.applications as RouteSectionDefinition)?.routes?.map((route: RouteDefinition) => {
        const isCurrentPath = (typeof route.path === 'string'
          ? route.path === currentRoute
          : (route?.path as pathENFR)?.fr === currentRoute || (route?.path as pathENFR)?.en === currentRoute);
        return {
          ...route,
          status: isCurrentPath ? WORKFLOW_STATUSES.IN_PROGRESS : WORKFLOW_STATUSES.INCOMPLETE,
        };
      }),
    };
    applicationRoutesRecords.applications = applicationRoutesWithStatus;
  }

  if ((applicationRoutesRecords.applications as RouteSectionDefinition)) {
    (applicationRoutesRecords.applications as RouteSectionDefinition).routes = (applicationRoutesRecords.applications as RouteSectionDefinition)?.routes?.filter((route: RouteDefinition) => resourceKeys.get(route?.name));
  }

  let updatedApplicationRoutes = updateApplicationRoutes(applicationRoutesRecords, saleId, userRole);

  updatedApplicationRoutes = updateApplicationInnerRouteForRouteName(updatedApplicationRoutes, saleId, APPLICATION_ROUTES_KEY.ECHANGES);
  updatedApplicationRoutes = updateApplicationInnerRouteForRouteName(updatedApplicationRoutes, saleId, APPLICATION_ROUTES_KEY.SIGNATURE_PAGE_ECHANGE);
  updatedApplicationRoutes = updateApplicationInnerRouteForRouteName(updatedApplicationRoutes, saleId, APPLICATION_ROUTES_KEY.APPLICATIONS);
  let ifUci = true;
  const applicationObject = updatedApplicationRoutes.find((route: RouteDefinition) => route.label && (route.label as { en: string }).en === 'Application(s)');

  if (applicationObject) {
    const nomineeIndex = applicationObject.routes?.findIndex((route: RouteDefinition) => route.label && (route.label as { en: string }).en === 'Nominee');

    const applications = data?.filter((item) => item.resourceKey?.toLowerCase() === 'applications');
    const matchingIds = applications[0]?.items?.filter((item) => item.resourceKey?.toLowerCase() === 'nominee' && item.isVisible === true)
      .map((item) => item.id);
    const matchedNominees = nominees.filter((nominee) => matchingIds?.includes(nominee.id));

    if (nomineeIndex >= 0) applicationObject.routes?.splice(nomineeIndex, 1);
    matchedNominees?.forEach((nominee) => {
      const newRoutes = generateWorkflowRoutesForAnNominee(nominee, saleId, userRole);
      newRoutes.routes = newRoutes.routes?.filter((route: RouteDefinition) => {
        let key = '';
        if (typeof route.path === 'string') {
          key = `${route?.path?.split('/').filter(Boolean).pop().toLowerCase().replace('-', '')}-${nominee.id}`;
        } else if (route.path) {
          key = `${String(((route.path as pathENFR).en || (route.path as pathENFR).fr))?.split('/').filter(Boolean).pop().toLowerCase().replace('-', '')}-${nominee.id}`;
        }
        const isVisible = resourceKeys.get(key);
        return isVisible;
      });
      const currentNominee = applications[0].items.find((item) => item.id === nominee.id);
      const isConsentVisible = currentNominee.items.find((item) => item.resourceKey === 'consent')?.isVisible;

      if (isConsentVisible) {
        if (nominee.uci) ifUci = true;
        else ifUci = false;
      }

      const getNomineeRouteStatus = (route: RouteDefinition, isCurrentPath: boolean) => {
        if (!nominee.isPerson) return WORKFLOW_STATUSES.INCOMPLETE;
        if (!ifUci && (route?.label && (route.label as { en: string }).en !== 'Consents')) return WORKFLOW_STATUSES.INACTIVE;
        if (ifUci && (route?.label && (route.label as { en: string }).en === 'Consents')) return WORKFLOW_STATUSES.COMPLETE;
        if (isCurrentPath) return WORKFLOW_STATUSES.IN_PROGRESS;
        return WORKFLOW_STATUSES.INCOMPLETE;
      };

      applicationRoutesWithStatus = {
        ...newRoutes,
        routes: newRoutes.routes?.map((route: RouteDefinition) => {
          const currentPage = currentRoute.split('/').pop().toLowerCase();
          if (currentPage === PAGE_NAMES.TRANSMISSION
            || currentPage === PAGE_NAMES.SIGNATURE_MODE
            || currentPage === PAGE_NAMES.REINSTATEMENT
            || currentPage === PAGE_NAMES.SIGNATURE_FOLLOWUP) {
            return { ...route, status: WORKFLOW_STATUSES.INACTIVE };
          }
          const isCurrentPath = (typeof route.path === 'string'
            ? route.path === currentRoute
            : (route?.path as pathENFR)?.fr === currentRoute || (route?.path as pathENFR)?.en === currentRoute);
          return {
            ...route,
            status: getNomineeRouteStatus(route, isCurrentPath),
          };
        }),
        status: newRoutes?.routes?.some((route: RouteDefinition) => (typeof route.path === 'string'
          ? route.path === currentRoute
          : (route?.path as pathENFR)?.fr === currentRoute || (route?.path as pathENFR)?.en === currentRoute)) ? WORKFLOW_STATUSES.IN_PROGRESS : WORKFLOW_STATUSES.INCOMPLETE,
      };
      applicationObject.routes = [applicationRoutesWithStatus, ...applicationObject.routes];
    });
  }
  const finalRoutes = [...updatedApplicationRoutes];
  // Create a mapping of ResourceKey to Sequence
  const sequenceMapping = new Map();
  data?.forEach((item) => {
    if (item.resourceKey !== null && item.sequence !== null && item.sequence !== undefined) {
      const key = item.resourceKey.toLowerCase();
      sequenceMapping.set(key, item.sequence);
    }
  });
  return finalRoutes
    .toSorted((a: RouteDefinition, b: RouteDefinition) => {
      let aKey;
      let bKey;
      const aPathENFR = String((a?.path as pathENFR)?.en || (a?.path as pathENFR)?.fr);
      const bPathENFR = String((b?.path as pathENFR)?.en || (b?.path as pathENFR)?.fr);
      if (typeof a.path === 'string') {
        aKey = a.path.split('/').filter(Boolean)[0]?.toLowerCase(); // get the parent route
      } else if (a.path) {
        aKey = aPathENFR.split('/').filter(Boolean)[0]?.toLowerCase(); // get the parent route
      }

      if (typeof b.path === 'string') {
        bKey = b.path.split('/').filter(Boolean)[0]?.toLowerCase().replace(/-/g, ''); // get the parent route
      } else if (b.path) {
        bKey = bPathENFR.split('/').filter(Boolean)[0]?.toLowerCase(); // get the parent route
      }
      const aSequence = sequenceMapping.get(aKey);
      const bSequence = sequenceMapping.get(bKey);
      return (aSequence || 0) - (bSequence || 0);
    })
    .map((r) => {
      let status;
      let { routes } = r;
      if (resourceKeyActive.get('applications') === false) {
        applicationObject.status = WORKFLOW_STATUSES.INACTIVE;
      }
      if (r.label && r.label.en === PAGE_NAMES.SUMMARY) {
        status = null;
      } else if (r.label && r.label.en === PAGE_NAMES.SIGNATURE) {
        status = WORKFLOW_STATUSES.INACTIVE;
        routes = setSubroutesInactive(r.routes);
      } else if ((resourceKeyActive.get(APPLICATION_ROUTES_KEY.VALIDATION_ECHANGE) === false) && (r.label?.en === 'Validation')) {
        status = WORKFLOW_STATUSES.INACTIVE;
      } else if ((resourceKeyActive.get(APPLICATION_ROUTES_KEY.TRANSMISSION_ECHANGE) === false) && (r.label?.en === 'Transmission')) {
        status = WORKFLOW_STATUSES.INACTIVE;
      } else {
        const isCurrentPath = applicationObject?.routes?.routes?.some((nr: RouteDefinition) =>
          (typeof nr.path === 'string'
            ? nr.path === currentRoute
            : (nr?.path as pathENFR)?.fr === currentRoute || (nr?.path as pathENFR)?.en === currentRoute));
        status = isCurrentPath ? WORKFLOW_STATUSES.IN_PROGRESS : r.status || WORKFLOW_STATUSES.INCOMPLETE;
        routes = r.status === WORKFLOW_STATUSES.INACTIVE ? setSubroutesInactive(r.routes) : routes;
      }
      return {
        ...r,
        status,
        routes,
      };
    });
}
