import styled from 'styled-components';
import { media } from 'ia-react-core';
import BorderBlock from '../../../../components/BorderBlock/BorderBlock';

export const StyledCreateIllustrationContainer = styled(BorderBlock)`
  display: flex;
  flex-direction: row;
  position: relative;
  
  align-items: flex-start;
  gap: 16px;

  ${media.xs`
    padding: 24px 16px;
  `}

  ${media.sm`
    padding: 32px 16px;
    min-height: 709px;
  `}
`;

export const StyledCreateIllustrationBackButtonContainer = styled.div``;

export const StyledCreateIllustrationProductsContainer = styled.div`
  flex-grow: 1;
`;
