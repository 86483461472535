import styled from 'styled-components';

export const StyledLoadingOverlayContainer = styled.div<{ $opaque?: boolean; $fullscreen: boolean }>`
  position: ${({ $fullscreen }) => ($fullscreen ? 'fixed' : 'absolute')};
  display: flex;
  inset: 0px 0px -1px;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background: rgba(255, 255, 255, ${({ $opaque }) => ($opaque ? 1 : 0.85)});
`;

export const StyledLoadingOverlayInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
