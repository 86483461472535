export type GUID = string & { isGuid: true };

export function stringToGuid(id: string): GUID {
  if (id.match(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?/)) {
    throw new Error(`GUID error: "${id}" is not a valid GUID`);
  }

  return id as GUID;
}

export const GUID_NULL = '00000000-0000-0000-0000-000000000000' as GUID;
