import { Action } from 'redux';
import { FormSubState } from '~/interfaces/FormSubState';
import { SetValidationMessagesInNameValidationAction } from '../ApplicationFragment.actions';

export default function setValidationMessagesNameValidationPage<Value>(state: FormSubState<Value>, action: Action): FormSubState<Value> {
  const { validatorMap, page } = action as SetValidationMessagesInNameValidationAction;
  const selectedPage = { ...state?.ValidationPage };

  return {
    ...state,
    [page]: {
      ...selectedPage,
      validationMessages: validatorMap,
    },
  };
}
