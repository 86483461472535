const getPageId = (userPreferenceType: string) => {
  if (userPreferenceType === 'Traditionnelle') {
    return 1;
  }
  if (userPreferenceType === 'Universelle') {
    return 2;
  }
  if (userPreferenceType === 'Specialisee') {
    return 3;
  }
  if (userPreferenceType === 'Participatif') {
    return 4;
  }
  if (userPreferenceType === 'Simplifiee') {
    return 5;
  }
  if (userPreferenceType === 'MaladieGrave') {
    return 6;
  }
  if (userPreferenceType === 'ConceptBonSuccessoral') {
    return 7;
  }
  if (userPreferenceType === 'ConceptProprietePartagee') {
    return 8;
  }
  return 1;
};

export default getPageId;
