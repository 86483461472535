export default {
  title: 'Print',
  generate: 'Print',
  chooseDocumentsLabel: 'Choose documents',
  printAvailable: 'Documents available for printing',
  scenarioLabel: 'SCENARIO {0}',
  allIllustations: 'All illustrations',
  allApplications: 'All applications',
  mergeLabel: 'Merge documents',
  cancel: 'Cancel',
  Yes: 'Yes',
  No: 'No',
  illustrations: 'Illustration',
  applications: 'Application',
  transactions: 'Transaction(s)',
  notSelectedMessage: 'You must select at least one document to print.',
  errorTitle: 'Error',
  illustration: 'Illustration',
  application: 'Application',
  seeError: 'See error',
  errorMessage: 'An unexpected error has occurred. You can reach us during business hours from Monday to Friday, 8:30 a.m. to 08:00 p.m. (Eastern time). Toll free : 1-888-610-5101',
  okText: 'OK',
  mergedDocuments: 'Merged_documents',
  workInProgress: 'Work in progress...',
};
