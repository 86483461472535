import { FC } from 'react';
import { Action, compose } from 'redux';
import { injectTranslations, injectReducer } from 'ia-react-core';

import { RolesPageState } from '~/interfaces/RolesPageState';

import rolesPageReducer from './reducers';
import RolesPage, { RolesPageProps } from './RolesPage';

import fr from './translations/fr';
import en from './translations/en';

export const initialState: Partial<RolesPageState> = {};

export default compose<FC<RolesPageProps>>(
  injectTranslations('RolesPage', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  injectReducer<RolesPageProps, RolesPageState, Action>({
    key: 'Pages.RolesPage',
    reducer: rolesPageReducer,
    initialState,
    useScope: true,
  }),
)(RolesPage);
