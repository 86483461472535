export const RELATIONSHIPS = 'relationships';
export const IDENTIFICATIONRELATIONSHIPS = 'identificationRelationships';

export const enum ProductType {
  Maxirance = 1,
  Abritation = 2,
  Momentum = 6,
  Transition = 7,
  Alternative = 8,
  Perspective = 9,
  Reee = 10,
  Ecoflex = 11,
  Diploma = 12,
  TransitionEvolution = 13,
  MultiTerme = 17,
  VieEntiere = 19,
  Axis = 20,
  TransitionSimplifiee = 21,
  AssuranceTraditionnelle = 22,
  AccesVie = 49,
  Par = 50,
  Genesis = 1015,
  GenesisAvantage = 1016,
  GenesisEpargnePlus = 1017,
  RegimeVieMD = 1021,
  RegimeVieMDEpargnePlus = 1022,
  Iris = 1024,
  Tendance = 1520,
  CapitalValeur = 1521,
  InconnuTrad = 0,
  Modulaire = 4,
  PoliceTrad = 5,
  Diplomes = 14,
  GarantieComplementaireTrad = 900,
  InconnuPU = 1000,
  PoliceUniverselle = 1003,
  Meridia = 1018,
  Elix = 1023,
  Topaz = 1025,
  Uniflex = 1028,
  GarantieComplementaireNonTrad = 1900,
  Heritage = 1524
}

export const TITLE_PORTAL_ID = 'StyledApplicationPageTitlePortal';
export const QUEBECCODE = 'QC';
export enum ApplicationIntentType {
  Validate = 1,
  Save = 2,
  SaveAndValidate = 3
}

export const AUTO_SAVE_INTERVAL = 30000;
export const enum ApplicationPageId {
  ADVISOR_PAGE = 7,
  NOMINEEENTIFICATION_PAGE = 8,
  NOMINEE_INSURANCE_HISTORY_PAGE = 9,
  NOMINEE_BENEFICIAIRE_PAGE = 10,
  NOMINEE_DECLARATIONS_PAGE = 11,
  NOMINEE_SPECIAL_INSTRUCTIONS_PAGE = 12,
  APPLICANT_PAGE = 13,
  REQUIREMENTS_PAGE = 14,
  COVERAGES_PAGE = 15,
  LINKED_APPLICATIONS_PAGE = 16,
  BILLING_PAGE = 17,
  PAC_PAD_AGREEMENT_PAGE = 18,
  VALIDATION_PAGE = 19,
  SIGNATURE_PAGE = 20,
  TRANSMISSION_PAGE = 21,
  HEADOFFICE_PAGE = 30,
  NOMINEE_CONSENT_PAGE = 31,
}

export const UPDATE_BANKING_RESOURCE = 'UpdateBanking';
export const VALIDATE_BANKING_RESOURCE = 'ValidateBanking';
export const SAVE_BANKING_RESOURCE = 'SaveBanking';
export const BANKING_FORM = 'pacPadAgreement';

export const VALIDATE_BILLING_RESOURCE_NAME = 'validateBilling';
export const UPDATE_BILLING_RESOURCE_NAME = 'updateBilling';
export const BILLING_FORM_NAME = 'billing';

export const CONSENT_FORM = 'consent';

export const VALIDATE_DOI_RESOURCE = 'ValidateDOI';
export const DOI_FORM_NAME = 'DOI';
