import FormModel from '~/interfaces/InsurabilityDeclaration/FormModel';
import FormDto from '../dtos/FormDto';
import SectionBuilder from './SectionBuilder';
import ValidationMessageBuilder from './ValidationMessageBuilder';
import { FormUtils } from '../../utils/forms.utils';

export default class FormBuilder {
  toModel(dto: FormDto): FormModel {
    const sectionBuilder = new SectionBuilder();
    const messageBuilder = new ValidationMessageBuilder();

    const name = dto.name ?? dto.title;

    return new FormModel(
      dto.id,
      // In the event there is a null name, there can be a non null title (Usually a warning)
      name,
      FormUtils.nameToUrlName(name, dto.$id),
      dto.title,
      dto.progression,
      dto.insureds,
      dto.sections.map((s) => sectionBuilder.toIdentityModel(s)),
      dto.validationMessages.map((m) => messageBuilder.toModel(m)),
      dto.category,
      dto.code,
      dto.status,
    );
  }
}
