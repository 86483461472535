import React, { FC, useMemo } from 'react';
import { Resource, ResourceProps } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { AxiosRequestConfig } from 'axios';

export interface DownloadChangeRequestFormProps extends ResourceProps<null, null> {
  saleId: string;
  formId: string;
  name: string;
}
const DownloadChangeRequestForm: FC<DownloadChangeRequestFormProps> = ({ saleId, formId, name }) => {
  const downloadUrl = useMemo(() => getBffUrlForRouteWithoutQP(
    `${BffRoute.change_request_form_by_id}/download?t=${(new Date()).valueOf()}`,
    { saleId, formId },
    false,
  ), [saleId, formId]);

  const axiosConfig: AxiosRequestConfig = {
    responseType: 'blob',
  };

  return (
    <Resource
      name={name}
      url={downloadUrl}
      method="GET"
      config={axiosConfig}
      onSuccess={(res: any) => {
        window.open(URL.createObjectURL(res.data));
      }}
    />
  );
};

export default DownloadChangeRequestForm;
