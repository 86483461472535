import { Dropdown } from 'ia-react-core';
import { v4 as uuid } from 'uuid';
import React, { useCallback, useMemo } from 'react';
import QuestionField from './QuestionField';

interface DropDownListOptionType { label: string; value: string }

const DropdownField: QuestionField = ({ question, onChange, hasError, hasWarning, ...props }) => {
  /*
    ia-react-core decided to use the value of the option as an HTML id.
    in our case the only value we have is a human readable string.
    Instead of trying to escape all the caracter that might cause problem in
    the browser we generate a random GUID to use as an internal value. This way
    we don't have to worry about random caracters suddenly breaking the app in the future.
   */

  const answerMap = useMemo<Record<string, string>>(
    () =>
      question.possibleAnswers.reduce((acc, curr) => {
        const id = uuid();
        return ({ ...acc, [id]: curr, [curr]: id });
      }, {}),
    [question.possibleAnswers],
  );

  const options = useMemo<DropDownListOptionType[]>(
    () =>
      question.possibleAnswers.map((v) => ({ label: v, value: answerMap[v] })),
    [question.possibleAnswers, answerMap],
  );

  const handleValueChange = useCallback((value: string) => {
    onChange([answerMap[value]]);
  }, [onChange, answerMap]);

  const value = question.answers[0]?.value as string;

  return (
    <Dropdown {...props} hasWarning={hasWarning} options={options} value={answerMap[value]} onValueChange={handleValueChange} isValid={!hasError} isTouched />
  );
};

export default DropdownField;
