import { Datepicker, ErrorMessage, Form, InfoMessage, T4, TextInput } from 'ia-react-core';
import styled from 'styled-components';

export const StyledT4 = styled(T4)`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 28px;
`;

export const StyledInfoMessage = styled(InfoMessage)`
  min-height: auto !important;
  padding: 16px;
  gap: 8px;

  svg {
    position: static;
    width: 24px;
    height: 24px;
    margin: 0;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const StyledFormSection = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const StyledFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
`;

export const StyledLabel = styled.label`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  width: 40%;
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

export const StyledDateControl = styled(Datepicker)`
  /* width: inherit; */
  padding: 8px !important;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  min-height: auto !important;
  padding: 16px;
  margin-bottom: 16px;
  gap: 8px;

  svg {
    position: static;
    width: 32px;
    height: 32px;
    margin: 0;
    margin-right: 8px;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  h1 {
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 16px;
  }
`;
