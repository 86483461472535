import { ComponentClass } from 'react';
import { compose } from 'redux';

import { injectReducer, injectSaga, injectTranslations } from 'ia-react-core';

import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';

import ApplicationFragment, { ApplicationFragmentProps } from './ApplicationFragment';
import fr from './translations/fr';
import en from './translations/en';
import applicationSaga from './sagas';
import reducer from './reducer';

export const initialState: Partial<ApplicationFragmentState> = {
  individuals: null,
  agents: null,
  billing: null,
  isLoading: true,
  fatalError: false,
  initialLoad: true,
  selectedFormId: null,
  selectedPlanId: null,
  isEditCover: false,
  isEditProduct: false,
  useTobacco: null,
  isValidateClicked: false,
  numberOfSelectedForms: null,
};

export default compose<ComponentClass<ApplicationFragmentProps>>(
  injectTranslations('Application', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  injectReducer({
    key: 'fragments.ApplicationFragment',
    reducer,
    initialState,
    useScope: true,
  }),
  injectSaga({
    key: 'Application',
    saga: applicationSaga,
  }),
)(ApplicationFragment);
