import { injectTranslations } from 'ia-react-core';
import { FC } from 'react';
import { compose } from 'redux';
import fr from './translations/fr';
import en from './translations/en';
import RenameModal, { RenameModalProps } from './RenameModal';

export default compose<FC<RenameModalProps>>(
  injectTranslations('RenameModal', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(RenameModal);

export * from './RenameModal';
