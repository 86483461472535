import { ManIcon, WomanIcon } from 'ia-react-core';
import styled from 'styled-components';

export const StyledInsuredPersonDataManIcon = styled(ManIcon)`
  height: 10px; 
  width: 10px;
  transform: scale(2.5);
`;

export const StyledInsuredPersonDataWomanIcon = styled(WomanIcon)`
  height: 10px; 
  width: 10px;
  transform: scale(2.5);
`;
