import { ValidationMessage } from '~/interfaces/ValidationMessage';

export const PREFIX = 'ApplicantPage' as const;

export const CLEAR_CONTINENT_SELECTION = `${PREFIX}.CLEAR_CONTINENT_SELECTION` as const;
export const clearContingentSelection = () => (
  {
    type: CLEAR_CONTINENT_SELECTION,
  }
);
export type clearContingentSelection = ReturnType<typeof clearContingentSelection>;

export const UPDATE_VALIDATION_MESSAGES = `${PREFIX}.UPDATE_VALIDATION_MESSAGES` as const;
export const updateValidationMessagesAction = (payload: ValidationMessage[]) => ({
  type: UPDATE_VALIDATION_MESSAGES,
  payload,
});
export type UpdateValidationMessagesAction = ReturnType<typeof updateValidationMessagesAction>;

export const UPDATE_NOMINEE_LIST = `${PREFIX}.UPDATE_NOMINEE_LIST` as const;
export const updateNomineeListAction = (payload: any) => ({
  type: UPDATE_NOMINEE_LIST,
  payload,
});
export type UpdateNomineeListAction = ReturnType<typeof updateNomineeListAction>;
