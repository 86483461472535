import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { AxiosResponse } from 'axios';

export interface SearchContractResourceProps {
  contractNumber: string;
  onComplete: (res: AxiosResponse) => void;
}
const SearchContractResource: FC<SearchContractResourceProps> = ({ contractNumber, onComplete }: SearchContractResourceProps) => (
  <Resource
    name="searchcontract"
    url={getBffUrlForRoute(BffRoute.search_contract, {}, false)}
    data={{ contractNumber }}
    method="POST"
    onSuccess={onComplete}
    onFailure={onComplete}
  />
);

export default SearchContractResource;
