import React, { FC, useMemo } from 'react';
import { Resource, ResourceProps } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import RESOURCE_NAMES from '~/fragments/ChangeFragment/constants/RESOURCE_NAMES';
import { AxiosError, AxiosResponse } from 'axios';
import { convertToLocalDateForInsureds } from '~/components/MakeEchangeModal/utilities/Formatter';

export interface CompleteContractInsuredResourceProps extends ResourceProps<null, null> {
  saleId: string;
  onContractSuccess?: (response: AxiosResponse) => void;
  onContractError?: (error: AxiosError) => void;
}
const CompleteContractInsuredResource: FC<CompleteContractInsuredResourceProps> = ({ saleId, onContractSuccess, onContractError }) => {
  const ContractDetailUrl = useMemo(() => getBffUrlForRouteWithoutQP(
    BffRoute.contract_details,
    { saleId },
    false,
  ), [saleId]);

  const handleContractSuccess = (response: AxiosResponse): void => {
    if (onContractSuccess) {
      onContractSuccess(response);
    }
  };

  const handleContractError = (error: AxiosError): void => {
    if (onContractError) {
      onContractError(error);
    }
  };

  return (
    <Resource
      name={RESOURCE_NAMES.COMPLETE_CONTRACT_INSURED_RESOURCE}
      url={ContractDetailUrl}
      method="POST"
      onSuccess={handleContractSuccess}
      onFailure={handleContractError}
      transformRequest={convertToLocalDateForInsureds}
    />
  );
};

export default CompleteContractInsuredResource;
