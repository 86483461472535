import { Action } from 'redux';
import { LoadChangeRequestFormsSuccessAction } from '../ChangeRequest.actions';
import { ChangeRequestState } from '../interfaces/State';

export default function loadChangeRequestFormsSuccessReducer(state: ChangeRequestState, action: Action): ChangeRequestState {
  const { formTypes } = action as LoadChangeRequestFormsSuccessAction;
  return {
    ...state,
    formTypes,
  };
}
