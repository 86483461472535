import { WORKFLOW_STATUSES, RouteDefinition, RouteSectionDefinition } from 'ia-react-core';
import { APPLICATION_ROUTES_KEY } from '~/constants/APPLICATION_ROUTES';
import IndividualModel from '../interfaces/IndividualModel';
import pathENFR from '../interfaces/PathENFR';
import { updateApplicationInnerRouteForRouteName } from './updateApplicationRoutes';
import PAGE_NAMES from '../constants/pageNames';
import Item from '../interfaces/Item';
import getSequenceMapping from './getSequenceMapping';
import getResourceKeys from './getResourceKeys';
import getNomineeRoutesUpdated from './getNomineeRoutesUpdated';

const setSubroutesInactive = (routes: any) => {
  if (!Array.isArray(routes)) return routes;
  return routes.map((route) => ({ ...route, status: WORKFLOW_STATUSES.INACTIVE }));
};

export function generateInitialRoutes({
  applicationRoutesRecords,
  saleId,
  nominees,
  userRole,
  currentRoute,
  updateApplicationRoutes,
  generateWorkflowRoutesForAnNominee,
  data,
}: {
  applicationRoutesRecords: Record<APPLICATION_ROUTES_KEY, RouteDefinition | RouteSectionDefinition>;
  saleId: string;
  nominees: IndividualModel[];
  userRole: string;
  currentRoute: string;
  updateApplicationRoutes: Function;
  generateWorkflowRoutesForAnNominee: Function;
  data: Item[];
}) {
  const values = Object?.values(applicationRoutesRecords);
  const resourceKeys = getResourceKeys(data, applicationRoutesRecords);

  const visibleKeys = new Set(data?.filter((item) => item.isVisible).map((item) => item.resourceKey));
  Object.keys(applicationRoutesRecords).forEach((key) => {
    if (!visibleKeys.has(key)) {
      delete applicationRoutesRecords[key as APPLICATION_ROUTES_KEY];
    }
  });
  let updatedApplicationRoutes = updateApplicationRoutes(applicationRoutesRecords, saleId, userRole);
  updatedApplicationRoutes = updateApplicationInnerRouteForRouteName(updatedApplicationRoutes, saleId, APPLICATION_ROUTES_KEY.SIGNATURE_PAGE);
  const indexOfNominee = values.indexOf(applicationRoutesRecords.nominee);
  let ifUciForPostRoutes = true;

  const preRoutes = updatedApplicationRoutes.slice(0, indexOfNominee);
  const postRoutes = updatedApplicationRoutes.slice(indexOfNominee + 1);
  const matchingIds = data?.filter((item) => item.resourceKey?.toLowerCase() === 'nominee')
    .map((item) => item.id);
  const matchedNominees = nominees.filter((nominee) => matchingIds?.includes(nominee.id));

  const applicantNominees = nominees?.filter(({ isApplicant, isPerson }: { isApplicant: boolean; isPerson: boolean }) => isApplicant && isPerson);
  applicantNominees.forEach((nominee) => {
    if (!nominee.uci) ifUciForPostRoutes = false;
  });
  const nomineeRoutesUpdated = getNomineeRoutesUpdated(matchedNominees, generateWorkflowRoutesForAnNominee, saleId, userRole, resourceKeys, currentRoute, ifUciForPostRoutes);
  const finalRoutes = [...preRoutes, ...nomineeRoutesUpdated, ...postRoutes];

  const sequenceMapping = getSequenceMapping(data);

  data?.forEach((item) => {
    if (item.resourceKey !== null && item.sequence !== null && item.sequence !== undefined) {
      const key = item.resourceKey.toLowerCase();
      sequenceMapping.set(key, item.sequence);
    }
  });
  return finalRoutes
    .toSorted((a: RouteDefinition, b: RouteDefinition) => {
      let aKey;
      let bKey;
      const aPathENFR = String((a?.path as pathENFR)?.en || (a?.path as pathENFR)?.fr);
      const bPathENFR = String((b?.path as pathENFR)?.en || (b?.path as pathENFR)?.fr);
      if (typeof a.path === 'string') {
        aKey = a.path.split('/').filter(Boolean)[2]?.toLowerCase();
      } else if (a.path) {
        aKey = aPathENFR.split('/').filter(Boolean)[2]?.toLowerCase();
      } else if ('identification' in a) {
        aKey = 'nominee';
      } else if ('signatureMode' in a) {
        aKey = 'signature';
      }
      if (typeof b.path === 'string') {
        bKey = b.path.split('/').filter(Boolean)[2]?.toLowerCase();
      } else if (b.path) {
        bKey = bPathENFR.split('/').filter(Boolean)[2]?.toLowerCase();
      } else if ('identification' in b) {
        bKey = 'nominee';
      } else if ('signatureMode' in b) {
        bKey = 'signature';
      }

      const aSequence = sequenceMapping.get(aKey);
      const bSequence = sequenceMapping.get(bKey);

      return aSequence - bSequence;
    })
    .map((r) => {
      let status;
      let { routes } = r;

      if (r.label && r.label.en === PAGE_NAMES.SUMMARY) {
        status = null;
      } else if (r.label && r.label.en === PAGE_NAMES.SIGNATURE) {
        status = WORKFLOW_STATUSES.INACTIVE;
        routes = setSubroutesInactive(r.routes);
      } else if (!ifUciForPostRoutes && r.label && postRoutes.includes(r)) {
        status = WORKFLOW_STATUSES.INACTIVE;
      } else if (r.label && r.label.en.toLowerCase() === PAGE_NAMES.TRANSMISSION) {
        status = WORKFLOW_STATUSES.INACTIVE;
      } else {
        const isCurrentPath = nomineeRoutesUpdated.some((nr: RouteDefinition) =>
          (typeof nr.path === 'string'
            ? nr.path === currentRoute
            : (nr?.path as pathENFR)?.fr === currentRoute || (nr?.path as pathENFR)?.en === currentRoute));
        status = isCurrentPath ? WORKFLOW_STATUSES.IN_PROGRESS : WORKFLOW_STATUSES.INCOMPLETE;
      }
      const subrouteSequenceMapping = new Map();
      data?.forEach((item) => {
        item.items?.forEach((subItem: any) => {
          if (subItem.resourceKey !== null && subItem.sequence !== null && subItem.sequence !== undefined) {
            const parts = subItem?.name?.split('-');
            const nomineeId = parts.slice(1).join('-');
            const key = `${nomineeId}-${subItem.resourceKey.toLowerCase()}`;
            subrouteSequenceMapping.set(key, subItem.sequence);
          }
        });
      });
      return {
        ...r,
        status,
        routes: routes?.toSorted((a: RouteDefinition, b: RouteDefinition) => {
          let aKey;
          let bKey;

          if (typeof a.path === 'string') {
            const parts = (a.path).split('/').filter(Boolean);
            const nomineeId = parts[parts.length - 2];
            const lastPart = parts[parts.length - 1].replace('-', '');
            aKey = `${nomineeId}-${lastPart}`;
          } else if (Array.isArray(a.path)) {
            const parts = (a.path[a.path.length - 1]).split('/').filter(Boolean);
            const nomineeId = parts[parts.length - 2];
            const lastPart = parts[parts.length - 1].replace('-', '');
            aKey = `${nomineeId}-${lastPart}`;
          } else if (typeof a.path === 'object') {
            const parts = (a.path.en as string).split('/').filter(Boolean);
            const nomineeId = parts[parts.length - 2];
            const lastPart = parts[parts.length - 1].replace('-', '');
            aKey = `${nomineeId}-${lastPart}`;
          }
          if (typeof b.path === 'string') {
            const parts = (b.path).split('/').filter(Boolean);
            const nomineeId = parts[parts.length - 2];
            const lastPart = parts[parts.length - 1].replace('-', '');
            bKey = `${nomineeId}-${lastPart}`;
          } else if (Array.isArray(b.path)) {
            const parts = (b.path[b.path.length - 1]).split('/').filter(Boolean);
            const nomineeId = parts[parts.length - 2];
            const lastPart = parts[parts.length - 1].replace('-', '');
            bKey = `${nomineeId}-${lastPart}`;
          } else if (typeof b.path === 'object') {
            const parts = (b.path.en as string).split('/').filter(Boolean);
            const nomineeId = parts[parts.length - 2];
            const lastPart = parts[parts.length - 1].replace('-', '');
            bKey = `${nomineeId}-${lastPart}`;
          }
          const aSequence = subrouteSequenceMapping.get(aKey);
          const bSequence = subrouteSequenceMapping.get(bKey);
          return aSequence - bSequence;
        }),
      };
    });
}
