import {
  ResourceLoader, ResourceLoaderProps,
} from 'ia-react-core';
import React, { FC } from 'react';
import { Role } from '~/interfaces/RolesPageState';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';

export interface RoleResourceLoaderProps extends ResourceLoaderProps<Role> {
  selectedRole: string;
}

const RoleResourceLoader: FC<RoleResourceLoaderProps> = ({ selectedRole, ...props }) => {
  const ROLES_RESOURCE = 'roles';
  const url = getBffUrlForRoute(BffRoute.user_profile, { entry: selectedRole }, true);

  return (
    <ResourceLoader<Role>
      name={ROLES_RESOURCE}
      url={url}
      {...props}
    />
  );
};

export default RoleResourceLoader;
