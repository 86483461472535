import { RouteDefinition, WORKFLOW_STATUSES } from 'ia-react-core';
import IndividualModel from '../interfaces/IndividualModel';
import pathENFR from '../interfaces/PathENFR';

function getNomineeRoutesUpdated(matchedNominees: IndividualModel[], generateWorkflowRoutesForAnNominee: Function, saleId: string, userRole: string, resourceKeys: Map<string, boolean>, currentRoute: string, ifUciForPostRoutes: boolean) {
  return matchedNominees?.map((i: IndividualModel) => {
    const nomineeRoute = generateWorkflowRoutesForAnNominee(i, saleId, userRole);
    const ifUciOrOrg = !!i.uci || !i.isPerson;
    nomineeRoute.routes = nomineeRoute.routes?.filter((route: RouteDefinition) => {
      let key = '';
      if (typeof route.path === 'string') {
        key = `${route?.path?.split('/').filter(Boolean).pop().toLowerCase().replace('-', '')}-${i.id}`;
      } else if (route.path) {
        key = `${String(((route.path as pathENFR).en || (route.path as pathENFR).fr))?.split('/').filter(Boolean).pop().toLowerCase().replace('-', '')}-${i.id}`;
      }
      const isVisible = resourceKeys.get(key);
      return isVisible;
    });
    const hasConsentRoute = nomineeRoute?.routes?.some((route: RouteDefinition) => (route?.label && (route.label as { en: string }).en === 'Consents'));

    const getNomineeRouteStatus = (route: RouteDefinition, isCurrentPath: boolean) => {
      if (hasConsentRoute) {
        if (!ifUciOrOrg && (route?.label && (route.label as { en: string }).en !== 'Consents')) return WORKFLOW_STATUSES.INACTIVE;
        if (ifUciOrOrg && (route?.label && (route.label as { en: string }).en === 'Consents')) return WORKFLOW_STATUSES.COMPLETE;
      } else if (!ifUciForPostRoutes) return WORKFLOW_STATUSES.INACTIVE;
      if (isCurrentPath) return WORKFLOW_STATUSES.IN_PROGRESS;
      return WORKFLOW_STATUSES.INCOMPLETE;
    };

    return {
      ...nomineeRoute,
      routes: nomineeRoute.routes?.map((route: RouteDefinition) => {
        const isCurrentPath = (typeof route.path === 'string'
          ? route.path === currentRoute
          : (route?.path as pathENFR)?.fr === currentRoute || (route?.path as pathENFR)?.en === currentRoute);
        return {
          ...route,
          status: getNomineeRouteStatus(route, isCurrentPath),
        };
      }),
      status: nomineeRoute?.routes?.some((route: RouteDefinition) => (typeof route.path === 'string'
        ? route.path === currentRoute
        : (route?.path as pathENFR)?.fr === currentRoute || (route?.path as pathENFR)?.en === currentRoute)) ? WORKFLOW_STATUSES.IN_PROGRESS : WORKFLOW_STATUSES.INCOMPLETE,
    };
  }) ?? [];
}
export default getNomineeRoutesUpdated;
