const getPageName = (pageDescription: string): string => {
  if (pageDescription === 'Traditional Insurance') {
    return 'Traditionnelle';
  }
  if (pageDescription === 'Universal Life Insurance') {
    return 'Universelle';
  }
  if (pageDescription === 'Specialized Life Insurance') {
    return 'Specialisee';
  }
  if (pageDescription === 'Participating Life Insurance') {
    return 'Participatif';
  }
  if (pageDescription === 'Simplified Issue product') {
    return 'Simplifiee';
  }
  if (pageDescription === 'Critical Illness Insurance') {
    return 'MaladieGrave';
  }
  if (pageDescription === 'Estate Bond') {
    return 'ConceptBonSuccessoral';
  }
  if (pageDescription === 'Shared Ownership') {
    return 'ConceptProprietePartagee';
  }
  return '';
};

export default getPageName;
