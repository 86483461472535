import { AppState } from '~/interfaces/AppState';
import { Language } from '~/interfaces/InsurabilityDeclaration/enums';

export default function selectChangeRequestLanguageCode(state: AppState): string {
  switch (state.fragments?.ApplicationFragment?.changeRequest.language) {
    case Language.French:
      return 'fr';
    case Language.English:
    default:
      return 'en';
  }
}
