import { Action } from 'redux';
import { isEmpty } from 'lodash';
import { IllustrationFragmentState } from '~/interfaces/IllustrationFragmentState';
import { FormClientErrors } from '~/interfaces/FormClientErrors';
import { FormSubState } from '~/interfaces/FormSubState';
import { ValidationMessageServerLevel } from '~/interfaces/ValidationMessageServerLevel';
import removeDuplicateValues from '~/utilities/removeDuplicateValuesFromObject';
import { TypeOfForm } from '~/interfaces/CoveragesPageState';
import { IllustrationFragmentActions, SET_SCENARIO, SetScenario, SET_VALIDATION_MESSAGES_TYPE, SetValidationMessagesAction, RESET_ERROR_FORM, ResetFormAction, SET_ADVISOR_VALIDATION_MESSAGES_TYPE } from './IllustrationFragment.actions';
import { FormsInterface, MessageErrorCodeMap, ValidatorConfigClientErrors, ValidatorMapConfigClientErrors } from './pages/InvestmentsPage/interfaces/InvestmentsPageInterface';

const pageMapping: Record<string, string> = {
  coverages: 'CoveragesPage',
  ratingsModal: 'CoveragesPage',
  investments: 'InvestmentsPage',
  reports: 'ReportsPage',
};

const messageErrorCodeToPageMapper: MessageErrorCodeMap = {
  3302: {
    category: 3,
    visibleOn: ['InvestmentsPage'],
  },
  3303: {
    category: 3,
    visibleOn: ['InvestmentsPage'],
  },
};

const isMessageVisibleOnThisPage = (messageCode: number, type: number, page: string) => {
  const message = messageErrorCodeToPageMapper[messageCode];
  const { category, visibleOn } = message || { category: 0, visibleOn: [] };

  if (!message) return true;
  if (isEmpty(visibleOn)) return true;
  if (!category) visibleOn.includes(page);

  return type === category && visibleOn.includes(page);
};

const reduceSetScenario = (state: IllustrationFragmentState, action: SetScenario) => {
  const { scenarios } = state.data;
  const index = scenarios.findIndex((scenario) => scenario.idDemande === action.scenario.idDemande);

  if (index === -1) return state;

  const newScenarios = [...scenarios];
  newScenarios[index] = action.scenario;

  return {
    ...state,
    data: {
      ...state.data,
      scenarios: newScenarios,
    },
  };
};

interface validationDetails {
  isFormValidaton: boolean;
  validationType: 'formErrors' | 'formWarnings' | 'formCautions' | 'formInfos' | 'controlErrors' | 'controlWarnings' | 'controlCautions' | 'controlInfos';
}

export const validationToFormClientProp = (controlName: string, val: ValidationMessageServerLevel): validationDetails => {
  const isFormValidaton = controlName.startsWith('custom_') || controlName.endsWith('ViewModels') || controlName.includes('|');
  const validationType = isFormValidaton ? 'form' : 'control';
  switch (val) {
    case ValidationMessageServerLevel.Warning:
      return {
        isFormValidaton,
        validationType: `${validationType}Warnings`,
      };
    case ValidationMessageServerLevel.Notice:
      return {
        isFormValidaton,
        validationType: `${validationType}Cautions`,
      };
    case ValidationMessageServerLevel.Information:
      return {
        isFormValidaton,
        validationType: `${validationType}Infos`,
      };
    case ValidationMessageServerLevel.Error:
    default:
      return {
        isFormValidaton,
        validationType: `${validationType}Errors`,
      };
  }
};

function setValidationMessagesReducer<Value>(state: FormSubState<Value>, action: Action): FormSubState<Value> {
  const { validatorMap, formName, instanceId, page } = action as SetValidationMessagesAction<Value>;
  const pageName = pageMapping[page] || 'CoveragesPage';
  const selectedPage = { ...state?.Pages?.[pageName as keyof Object] } as { forms: Object };
  const selectedPageForGenAppSummaryAndModal = { ...state?.Pages?.CoveragesPage };
  const forms = { ...selectedPage?.forms };
  let selectedForm = { ...forms?.[formName as keyof Object] } as TypeOfForm;
  const clientErrors: FormClientErrors<Value> = getClientErrors(validatorMap as ValidatorMapConfigClientErrors<Value>, pageName);
  const isApplicationSummaryForm = formName === 'applicationSummary';

  if (isApplicationSummaryForm) {
    selectedForm = { ...state?.Pages?.CoveragesPage?.components?.ApplicationSummary?.forms?.applicationSummary };
  }

  const errorForms: Record<string, string> = {
    ratingsModal: 'errors-ratings',
    coverages: 'errors',
    investments: 'errors',
  };

  const errorFormName: string = errorForms[page];

  const { [formName as keyof Object]: _, ...restForms } = forms;

  const selectedFormInstance = selectedForm[instanceId as keyof Object];

  const additionalCoverageForms = pageName === 'CoveragesPage' ? {
    'additional-coverage': {
      clientErrors: {},
    },
  } : {};

  let out: IllustrationFragmentState = {
    ...state,
    Pages: {
      ...state.Pages,
      [pageName]: {
        ...selectedPage,
        forms: {
          ...restForms,
          errors: {
            clientErrors: {},
          },
          premiumCustomizationModalGenesis: {
            clientErrors: {},
          },
          PremiumDepositCustomization: {
            clientErrors: {},
          },
          ...additionalCoverageForms,
        },
        components: {
          ...state?.Pages?.CoveragesPage?.components,
          ApplicationSummary: {
            ...state?.Pages?.CoveragesPage?.components?.ApplicationSummary,
            forms: {
              ...state?.Pages?.CoveragesPage?.components?.ApplicationSummary?.forms,
              applicationSummary: {
                clientErrors: {},
              },
            },
          },
        },
      },
    },
  };

  if (isEmpty(selectedForm) || isApplicationSummaryForm) {
    out = { ...out, ...injectErrorsIntoTopForm(errorFormName, clientErrors, state, page) };
  }

  if (formName === 'additional-coverage') {
    const controlErrors = (!isEmpty(clientErrors?.controlErrors) && {
      [instanceId]: {
        ...clientErrors.controlErrors,
      },
    }) || {};
    const controlCautions = (!isEmpty(clientErrors?.controlCautions) && {
      [instanceId]: {
        ...clientErrors.controlCautions,
      },
    }) || {};
    const controlWarnings = (!isEmpty(clientErrors?.controlWarnings) && {
      [instanceId]: {
        ...clientErrors.controlWarnings,
      },
    }) || {};
    const controlInfos = (!isEmpty(clientErrors?.controlInfos) && {
      [instanceId]: {
        ...clientErrors.controlInfos,
      },
    }) || {};
    out.Pages.CoveragesPage.forms[formName as keyof TypeOfForm] = {
      ...selectedForm,
      clientErrors: {
        ...selectedForm?.clientErrors,
        ...((!isEmpty(controlCautions) && { controlCautions })),
        ...((!isEmpty(controlErrors) && { controlErrors })),
        ...((!isEmpty(controlWarnings) && { controlWarnings })),
        ...((!isEmpty(controlInfos) && { controlInfos })),
      },
    };
  }

  if (formName === 'premiumCustomizationModalGenesis') {
    const controlErrors = (!isEmpty(clientErrors?.controlErrors) && {
      ...clientErrors.controlErrors,
    }) || {};
    const controlCautions = (!isEmpty(clientErrors?.controlCautions) && {
      ...clientErrors.controlCautions,
    }) || {};
    const controlWarnings = (!isEmpty(clientErrors?.controlWarnings) && {
      ...clientErrors.controlWarnings,
    }) || {};
    out.Pages.CoveragesPage.forms[formName as keyof TypeOfForm] = {
      ...selectedPageForGenAppSummaryAndModal.forms?.[formName as keyof TypeOfForm],
      clientErrors: {
        ...selectedPageForGenAppSummaryAndModal.forms?.[formName]?.clientErrors,
        ...((!isEmpty(controlCautions) && { controlCautions: { ...selectedPageForGenAppSummaryAndModal.forms?.[formName]?.clientErrors?.controlCautions, ...controlCautions } })),
        ...((!isEmpty(controlErrors) && { controlErrors: { ...selectedPageForGenAppSummaryAndModal.forms?.[formName]?.clientErrors?.controlErrors, ...controlErrors } })),
        ...((!isEmpty(controlWarnings) && { controlWarnings: { ...selectedPageForGenAppSummaryAndModal.forms?.[formName]?.clientErrors?.controlWarnings, ...controlWarnings } })),
      },
    };
  }

  if (formName === 'PremiumDepositCustomization') {
    out.Pages.CoveragesPage.forms[formName as keyof TypeOfForm] = {
      ...selectedPageForGenAppSummaryAndModal.forms?.[formName as keyof TypeOfForm],
      [instanceId]: {
        ...selectedFormInstance,
        clientErrors,
      },
    };
  }

  if (isApplicationSummaryForm) {
    const controlErrors = (!isEmpty(clientErrors?.controlErrors) && {
      ...clientErrors.controlErrors,
    }) || {};
    const controlCautions = (!isEmpty(clientErrors?.controlCautions) && {
      ...clientErrors.controlCautions,
    }) || {};
    const controlWarnings = (!isEmpty(clientErrors?.controlWarnings) && {
      ...clientErrors.controlWarnings,
    }) || {};

    out.Pages.CoveragesPage.components.ApplicationSummary.forms.applicationSummary = {
      ...selectedPageForGenAppSummaryAndModal.components.ApplicationSummary.forms?.applicationSummary,
      clientErrors: {
        ...selectedPageForGenAppSummaryAndModal.components.ApplicationSummary.forms?.applicationSummary?.clientErrors,
        ...((!isEmpty(controlCautions) && { controlCautions: { ...selectedPageForGenAppSummaryAndModal.components.ApplicationSummary.forms?.applicationSummary?.clientErrors?.controlCautions, ...controlCautions } })),
        ...((!isEmpty(controlErrors) && { controlErrors: { ...selectedPageForGenAppSummaryAndModal.components.ApplicationSummary.forms?.applicationSummary?.clientErrors?.controlErrors, ...controlErrors } })),
        ...((!isEmpty(controlWarnings) && { controlWarnings: { ...selectedPageForGenAppSummaryAndModal.components.ApplicationSummary.forms?.applicationSummary?.clientErrors?.controlWarnings, ...controlWarnings } })),
      },
    };
  }

  if (formName === 'insuredPersons') {
    out.Pages.CoveragesPage.forms.insuredPersons = {
      ...(selectedPage.forms as Record<string, any>)?.[formName],
      [instanceId]: {
        ...selectedFormInstance,
        clientErrors,
      },
    };
  }

  out = { ...out, ...injectErrorsIntoTopForm(errorFormName, clientErrors, out, page) };

  return out;
}

function setAdvisorValidationMessagesReducer<Value>(state: FormSubState<Value>, action: Action): FormSubState<Value> {
  const { validatorMap, formName } = action as SetValidationMessagesAction<Value>;
  const selectedPage = { ...state?.Pages?.ReportsPage };
  const selectedForm = { ...selectedPage.forms?.[formName as keyof Object] };
  const clientErrors: FormClientErrors<Value> = getClientErrors(validatorMap as ValidatorMapConfigClientErrors<Value>, 'ReportsPage');

  const forms = { ...selectedPage.forms };
  const { [formName as keyof Object]: _, ...restForms } = forms;

  const out: IllustrationFragmentState = {
    ...state,
    Pages: {
      ...state.Pages,
      ReportsPage: {
        ...selectedPage,
        forms: {
          ...restForms,
          errors: {
            clientErrors: {},
          },

        },
      },
    },
  };

  out.Pages.ReportsPage.forms[formName] = {
    ...selectedForm,
    clientErrors,
  };
  const errorForm = 'errors';
  out.Pages.ReportsPage.forms[errorForm] = {
    ...out.Pages.ReportsPage.forms[errorForm],
    clientErrors: {
      ...out.Pages.ReportsPage.forms[errorForm].clientErrors,
      ...clientErrors,
    },
  };

  return out;
}

function getClientErrors<Value>(validatorMap: ValidatorMapConfigClientErrors<Value>, pageName?: string): FormClientErrors<Value> {
  return Object.entries(validatorMap).reduce((acc, [name, validator]) => {
    const previousValue = { ...acc };
    const norValidator = validator as ValidatorConfigClientErrors<Value>;
    const [controlName, ruleName] = name.split('.');

    const clientFormSectionName = validationToFormClientProp(controlName, norValidator.type as unknown as ValidationMessageServerLevel);

    const previousSectionValue = previousValue?.[clientFormSectionName.validationType];
    const previousControlSectionValue = previousSectionValue?.[controlName as keyof typeof previousSectionValue];

    if (!isMessageVisibleOnThisPage(norValidator.messageCode, norValidator.type, pageName)) {
      return { ...previousValue };
    }

    if (clientFormSectionName.isFormValidaton) {
      return {
        ...previousValue,
        [clientFormSectionName.validationType]: {
          ...previousSectionValue,
          [`ValidationRule-${ruleName}`]: norValidator.message,
        },
      };
    }

    return {
      ...previousValue,
      [clientFormSectionName.validationType]: {
        ...previousSectionValue,
        [controlName]: { ...previousControlSectionValue, [`ValidationRule-${ruleName}`]: norValidator.message },
      },
    };
  }, {} as FormClientErrors<Value>);
}

function injectErrorsIntoTopForm<Value>(errorForm: string, clientErrors: FormClientErrors<Value>, out: FormSubState<Value>, page: string) {
  interface formNotification {
    control?: Object;
    form?: Object;
  }
  const formNotifications: Record<string, formNotification> = {
    formErrors: {
      control: clientErrors?.controlErrors,
      form: clientErrors?.formErrors,
    },
    formCautions: {
      control: clientErrors?.controlCautions,
      form: clientErrors?.formCautions,
    },
    formWarnings: {
      control: clientErrors?.controlWarnings,
      form: clientErrors?.formWarnings,
    },
    formInfos: {
      form: clientErrors?.formInfos,
    },
  };
  const pageName = pageMapping[page] || 'CoveragesPage';
  const outNew: IllustrationFragmentState = JSON.parse(JSON.stringify(out));

  Object.keys(formNotifications).forEach((itemKey: string) => {
    const item = formNotifications[itemKey];
    const controlErrorMessages = Object.values(item.control || {}).reduce((acc: Object, val: Object) => {
      const result = { ...acc, ...val };
      return result;
    }, {}) as Object;
    const formErrorMessages = item?.form || {};

    if (errorForm === 'errors') {
      const forms = (outNew?.Pages[pageName as keyof Object] as FormsInterface)?.forms || {};
      const errorFormErrors = (forms[errorForm as keyof Object]?.clientErrors[itemKey as keyof Object] || {});

      if (Object.keys(errorFormErrors).length > 0 || Object.keys(controlErrorMessages).length > 0 || Object.keys(formErrorMessages).length > 0) {
        forms[errorForm] = {
          ...forms[errorForm],
          clientErrors: {
            ...forms[errorForm]?.clientErrors,
            [itemKey]: removeDuplicateValues({
              ...forms[errorForm]?.clientErrors[itemKey as keyof Object],
              ...errorFormErrors,
              ...controlErrorMessages,
              ...formErrorMessages,
            }),
          },
        };
      }
    } else if (errorForm === 'errors-ratings') {
      const errorFormErrors = outNew.Pages.CoveragesPage.components.Ratings.forms[errorForm].clientErrors[itemKey as keyof Object] || {};

      if (Object.keys(errorFormErrors).length > 0 || Object.keys(formErrorMessages).length > 0) {
        outNew.Pages.CoveragesPage.components.Ratings.forms[errorForm] = {
          ...outNew.Pages.CoveragesPage.components.Ratings.forms[errorForm],
          clientErrors: {
            ...outNew.Pages.CoveragesPage.components.Ratings.forms[errorForm].clientErrors,
            [itemKey]: {
              ...outNew.Pages.CoveragesPage.components.Ratings.forms[errorForm].clientErrors[itemKey as keyof Object],
              ...errorFormErrors,
              ...controlErrorMessages,
              ...formErrorMessages,
            },
          },
        };
      }
    }
  });
  return outNew;
}

function resetErrorForm<Value>(state: FormSubState<Value>, action: ResetFormAction): FormSubState<Value> {
  const { formName = [] } = action;
  if (formName.length) {
    const name = formName[0];
    const instanceId = formName[1];
    if (name === 'insuredPersons') {
      return {
        ...state,
        Pages: {
          ...state?.Pages,
          CoveragesPage: {
            ...state?.Pages?.CoveragesPage,
            forms: {
              ...state?.Pages?.CoveragesPage?.forms,
              [name]: {
                ...state?.Pages?.CoveragesPage?.forms[name],
                [instanceId]: {
                  ...state?.Pages?.CoveragesPage?.forms[name][instanceId],
                  clientErrors: {},
                },
              },
            },
          },
        },
      };
    }
    if (name === 'additional-coverage') {
      return {
        ...state,
        Pages: {
          ...state?.Pages,
          CoveragesPage: {
            ...state?.Pages?.CoveragesPage,
            forms: {
              ...state?.Pages?.CoveragesPage?.forms,
              [name]: {
                ...state?.Pages?.CoveragesPage?.forms[name],
                clientErrors: {},
              },
            },
          },
        },
      };
    }
    if (name === 'premiumCustomizationModalGenesis') {
      return {
        ...state,
        Pages: {
          ...state.Pages,
          CoveragesPage: {
            ...state?.Pages?.CoveragesPage,
            forms: {
              ...state?.Pages?.CoveragesPage?.forms,
              [name]: {
                ...state?.Pages?.CoveragesPage?.forms[name],
                clientErrors: {},
              },
            },
          },
        },
      };
    }
    if (name === 'PremiumDepositCustomization') {
      return {
        ...state,
        Pages: {
          ...state.Pages,
          CoveragesPage: {
            ...state.Pages.CoveragesPage,
            forms: {
              ...state.Pages.CoveragesPage.forms,
              [name]: {
                ...state.Pages.CoveragesPage.forms[name],
                [instanceId]: {
                  ...state.Pages.CoveragesPage.forms[name][instanceId],
                  clientErrors: {},
                },
              },
            },
          },
        },
      };
    }
    if (name === 'applicationSummary') {
      return {
        ...state,
        Pages: {
          ...state?.Pages,
          CoveragesPage: {
            ...state?.Pages?.CoveragesPage,
            components: {
              ...state?.Pages?.CoveragesPage?.components,
              ApplicationSummary: {
                ...state?.Pages?.CoveragesPage?.components?.ApplicationSummary,
                forms: {
                  ...state?.Pages?.CoveragesPage?.components?.ApplicationSummary?.forms,
                  applicationSummary: {
                    clientErrors: {},
                  },
                },
              },
            },
          },
        },
      };
    }
  }
  return {
    ...state,
    Pages: {
      ...state?.Pages,
      CoveragesPage: {
        ...state?.Pages?.CoveragesPage,
        components: {
          ...state?.Pages?.CoveragesPage?.components,
          Ratings: {
            ...state?.Pages?.CoveragesPage?.components?.Ratings,
            forms: {
              ...state?.Pages?.CoveragesPage?.components?.Ratings?.forms,
              'errors-ratings': {
                clientErrors: {},
              },
            },
          },
        },
        forms: {
          ...state?.Pages?.CoveragesPage?.forms,
          errors: {
            ...state?.Pages?.CoveragesPage?.forms?.errors,
            clientErrors: {},
          },
        },
      },
      InvestmentsPage: {
        ...state?.Pages?.InvestmentsPage,
        forms: {
          ...state?.Pages?.InvestmentsPage?.forms,
          errors: {
            ...state?.Pages?.InvestmentsPage?.forms?.errors,
            clientErrors: {},
          },
        },
      },
    },
  };
}

const illustrationFragmentReducer = (state: IllustrationFragmentState, action: IllustrationFragmentActions): IllustrationFragmentState => {
  switch (action.type) {
    case SET_SCENARIO:
      return reduceSetScenario(state, action);
    case SET_VALIDATION_MESSAGES_TYPE:
      return setValidationMessagesReducer(state, action);
    case SET_ADVISOR_VALIDATION_MESSAGES_TYPE:
      return setAdvisorValidationMessagesReducer(state, action);
    case RESET_ERROR_FORM:
      return resetErrorForm(state, action);
    default:
      return state;
  }
};

export default illustrationFragmentReducer;
