export const AVAILABLE_ADVISORS_RESOURCE = 'availableAdvisors';
export const REQUEST_FOR_RESOURCE = 'requestFor';
export const CREATE_ADVISOR_RESOURCE = 'createAdvisor';
export const UPDATE_ADVISOR_RESOURCE = 'updateAdvisor';
export const DELETE_ADVISOR_RESOURCE = 'deleteAdvisor';
export const AUTO_SAVE_ADVISOR_RESOURCE = 'autoSaveAdvisor';

export const ADVISOR_SELECTION_MODAL = 'advisorSelection';
export const ADVISOR_CREATION_EDITION_MODAL = 'advisorCreationEdition';
export const ADVISOR_DELETION_CONFIRMATION_MODAL = 'advisorDeleteConfirmation';
export const ADVISOR_BACK_CONFIRMATION_MODAL = 'advisorBackConfirmation';

export const ADVISOR_MAIN_FORM = 'employeeRequest';

export const EMPLOYEE_NUMBER_MAX_LENGTH = 6;
export const EMPLOYEE_NAME_MAX_LENGTH = 50;
