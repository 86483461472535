export enum ProductEnum {
  Maxirance = 1,
  Abritation = 2,
  Momentum = 6,
  Transition = 7,
  Alternative = 8,
  Perspective = 9,
  Reee = 10,
  Ecoflex = 11,
  Diploma = 12,
  TransitionEvolution = 13,
  MultiTerme = 17,
  VieEntiere = 19,
  Axis = 20,
  TransitionSimplifiee = 21,
  AssuranceTraditionnelle = 22,
  AccesVie = 49,
  Par = 50,
  Genesis = 1015,
  GenesisAvantage = 1016,
  GenesisEpargnePlus = 1017,
  RegimeVieMD = 1021,
  RegimeVieMDEpargnePlus = 1022,
  Iris = 1024,
  Tendance = 1520,
  CapitalValeur = 1521,
  InconnuTrad = 0,
  Modulaire = 4,
  PoliceTrad = 5,
  Diplomes = 14,
  GarantieComplementaireTrad = 900,
  InconnuPU = 1000,
  PoliceUniverselle = 1003,
  Meridia = 1018,
  Elix = 1023,
  Topaz = 1025,
  Uniflex = 1028,
  GarantieComplementaireNonTrad = 1900,
  Heritage = 1524
}
