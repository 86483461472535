export enum BffRoute {
  user_sales = '/user/sales/',
  user_sales_paged = '/user/sales/paged',
  application_configuration = '/user/application-configuration',
  application_configuration_application_versions = '/configuration/application-versions/{effectiveDate}',
  application_configuration_application_versions_effective_dates = '/configuration/application-versions/effective-dates',
  application_configuration_language = '/user/application-configuration/language/{language}',
  illustration_product_navigation = '/illustration/Product/Navigation',
  illustration_insurancetypes = '/illustration/insurancetypes',
  illustration_product_catalog = '/illustration/product/catalog',
  illustration_sales = '/illustration/sales',
  illustration_sales_saleId_scenarios = '/illustration/sales/{saleId}/scenarios',
  illustration_sales_add_insured = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals/insureds',
  illustration_sales_saleId_scenarios_scenarioId_province = '/illustration/sales/{saleId}/scenarios/{scenarioId}/province',
  illustration_sales_saleId_scenarios_scenarioId_tax_rates = '/illustration/sales/{saleId}/scenarios/{scenarioId}/tax-rates',
  illustration_sales_saleId_scenarios_scenarioId_pua_purchaseAmount = '/illustration/sales/{saleId}/scenarios/{scenarioId}/pua/purchase-amount',
  illustration_sales_saleId_scenarios_scenarioId_pua_duration = '/illustration/sales/{saleId}/scenarios/{scenarioId}/pua/duration',
  illustration_sales_saleId_scenarios_scenarioId_pua_reset = '/illustration/sales/{saleId}/scenarios/{scenarioId}/pua/reset',
  illustration_sales_saleId_scenarios_scenarioId_pua_tooltip = '/illustration/sales/{saleId}/scenarios/{scenarioId}/pua/tooltip',
  illustration_sales_saleId_scenarios_scenarioId_product = '/illustration/sales/{saleId}/scenarios/{scenarioId}/product',
  illustration_sales_saleId_scenarios_scenarioId_product_dividendOption = '/illustration/sales/{saleId}/scenarios/{scenarioId}/product/dividend-option',
  illustration_sales_saleId_scenarios_template_individual_remove = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals/insureds/removal/{individualId}',
  illustration_sales_saleId_scenarios_summary_validation = '/illustration/sales/{saleId}/scenarios/{scenarioId}/summary',
  illustration_sales_saleId_scenarios_scenarioId_coverages_coverageId = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}',
  illustration_sales_saleId_scenarios_scenarioId_validate_calculate = '/illustration/sales/{saleId}/scenarios/{scenarioId}/validate-calculate',
  illustration_sales_saleId_summary = '/illustration/sales/{saleId}/summary',
  illustration_sales_saleId_scenarios_scenarioId_coverages_coverageId_calculateRatings = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/calculate-ratings',
  illustration_sales_saleId_scenarios_scenarioId_coverages_coverageId_ratingModels = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/rating-models',
  illustration_sales_saleId_scenarios_scenarioId_coverages_coverageId_ratingPresentation = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/rating-presentation',
  illustration_sales_saleId_scenarios_scenarioId_coverages_coverageId_leveling = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/leveling',
  illustration_sales_saleId_scenarios_scenarioId_coverages_ratings_validation = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/ratings/validation',
  illustration_sales_saleId_scenarios_scenarioId_coverages_ratings_validateCalculate= '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/ratings/validate-calculate',
  illustration_sales_saleId_scenarios_scenarioId_coverages_ratings = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/ratings',
  illustration_sales_saleId_scenarios_scenarioId_coverages_coverageId_addOn_faceAmount = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/add-on/face-amount',
  illustration_sales_saleId_scenarios_scenarioId_summary_specialQuoteWarnings = '/illustration/sales/{saleId}/scenarios/{scenarioId}/summary/special-quote-warnings',
  illustration_sales_salesId_children = '/illustration/sales/{saleId}/children',
  illustration_sales_salesId_child = '/illustration/sales/{saleId}/child',
  illustration_sales_salesId_scenarios_scenarioId_children = '/illustration/sales/{saleId}/scenarios/{scenarioId}/children',
  illustration_sales_salesId_scenarios_scenarioId_childmodule = '/illustration/sales/{saleId}/scenarios/{scenarioId}/childmodule',
  illustration_sales_salesId_scenarios_scenarioId_child_childId = '/illustration/sales/{saleId}/scenarios/{scenarioId}/child/{childId}',
  illustration_sales_salesId_scenarios_scenarioId_child = '/illustration/sales/{saleId}/scenarios/{scenarioId}/child',
  illustration_sales_saleId_scenarios_scenarioId_contribution = '/illustration/sales/{saleId}/scenarios/{scenarioId}/contribution',
  illustration_sales_saleId_scenarios_scenarioId_contribution_ods = '/illustration/sales/{saleId}/scenarios/{scenarioId}/contribution/ods',
  illustration_sales_saleId_scenarios_scenarioId_contribution_installment_duration = '/illustration/sales/{saleId}/scenarios/{scenarioId}/contribution/installment-duration',
  illustration_sales_saleId_scenarios_scenarioId_contribution_premium = '/illustration/sales/{saleId}/scenarios/{scenarioId}/contribution/premium',
  illustration_sales_saleId_scenarios_scenarioId_contribution_budget_amount = '/illustration/sales/{saleId}/scenarios/{scenarioId}/contribution/budget-amount',
  illustration_sales_saleId_scenarios_scenarioId_contribution_min_ods = '/illustration/sales/{saleId}/scenarios/{scenarioId}/contribution/min-ods',
  illustration_sales_salesId_scenarios_scenarioId_concepts_eclipse_prime = '/illustration/sales/{saleId}/scenarios/{scenarioId}/concepts/eclipse-prime',
  illustration_sales_salesId_scenarios_scenarioId_concepts_eclipse_prime_start_option = '/illustration/sales/{saleId}/scenarios/{scenarioId}/concepts/eclipse-prime/start-option',
  illustration_sales_salesId_scenarios_scenarioId_concepts_eclipse_prime_years = '/illustration/sales/{saleId}/scenarios/{scenarioId}/concepts/eclipse-prime/years',
  user_profile = '/user/profile',
  user_sales_saleId = '/user/sales/{saleId}',
  user_sales_saleId_name = '/user/sales/{saleId}/name',
  user_sales_saleId_metadata = '/user/sales/{saleId}/metadata',
  user_relationship_types = '/configuration/relationship',
  user_menu_saleId = '/user/menu/{saleId}',
  relationship_all = '/configuration/relationship/all',
  feature_toggles = '/user/featuretoggles',
  user_application = '/application',
  illustration_update_selected_individual = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals/{individualId}',
  illustration_replace_individual = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals/replacement/{individualId}',
  illustration_individual_list = '/illustration/sales/{saleId}/individuals',
  illustration_sales_saleId_scenarios_scenarioId_individuals = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals',
  illustration_sales_saleId_individuals_individualId = '/illustration/sales/{saleId}/individuals/{individualId}',
  illustration_sales_saleId_scenarios_scenarioId_basecoverage = '/illustration/sales/{saleId}/scenarios/{scenarioId}/base-coverage/{individualId}',
  illustration_applicant_add = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals/applicants/persons',
  illustration_replace_with_organization = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals/applicants/switch-applicant-type/{applicantType}',
  illustration_add_organisation = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals/applicants/organizations',
  illustration_delete_applicant = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals/applicants/persons/removal/{applicantId}',
  user_advisors = '/user/advisors',
  user_advisors_advisorId = '/user/advisors/{advisorId}',
  illustration_edit_organization = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals/applicants/organizations/{individualId}',
  illustration_sales_saleId_scenarios_scenarioId_typeOfInsurance = '/illustration/sales/{saleId}/scenarios/{scenarioId}/insurance-type',
  update_declaration_case = '/application/declaration',
  application_sales_saleId_individuals_individualId_person = '/application/sales/{saleId}/individuals/{individualId}/person',
  application_sales_saleId_individuals_individualId_organization = '/application/sales/{saleId}/individuals/{individualId}/organization',
  financialInstitutes = '/configuration/banks/{language}',
  bankBranches = '/configuration/bankbranches',
  Update_pac_pad_agreement = '/application/sales/{saleId}/banking',
  save_validate_banking = '/application/sales/{saleId}/banking/save-or-validate',
  Update_billing = '/application/sales/{saleId}/billing',
  save_validate_billing = '/application/sales/{saleId}/billing/save-or-validate',
  important_messages = '/user/importantmessages',
  update_sales_progression = '/user/sales/{saleId}/progression',
  application_advisor_saleId = '/application/sales/{saleId}/advisors',
  application_available_advisor_saleId = '/application/sales/{saleId}/available-advisors',
  application_advisor_saleId_advisorId = '/application/sales/{saleId}/advisors/{advisorId}',
  request_for_application_saleId = '/application/sales/{saleId}/advisors/save-or-validate',
  update_application_insurance_history = '/application/sales/{saleId}/insurancehistory/save-or-validate',
  contingent_owner_application_saleId = '/application/sales/{saleId}/contingent-owner',
  applicant_application_saleId = '/application/sales/{saleId}/regulatory-questions/save-or-validate',
  provinces = '/configuration/provinces/{language}',
  illustration_provinces = '/illustration/provinces',
  dividends_rate_for_corporate = '/configuration/tax-rate/dividends/corp/default/{province}',
  default_rates_by_province = '/configuration/tax-rate/province/{province}/default',
  latest_news = '/user/news',
  identificationdocuments = '/configuration/identification-documents',
  issuingOrganizations = '/configuration/organisations-with-residence',
  search_contract = '/change-request/contract/search',
  update_insured_information = '/change-request/contract/{saleId}/insureds/{insuredId}',
  coverage_riders = '/illustration/coverage-riders/product/{genericProductCode}/plan/{genericPlanCode}/base-plan/{baseCoveragePlanCode}',
  places = '/configuration/issue-location/{language}',
  preferentialStatus = '/illustration/sales/{saleId}/scenarios/{scenarioId}/insureds/preferential-status',
  download_contract = '/change-request/contract/download',
  update_death_benefit_option = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverage/{coverageId}/death-benefit/option',
  update_death_benefit_active_minimisation = '/illustration/sales/{saleId}/scenarios/{scenarioId}/death-benefit/is-active-minimisation',
  update_death_benefit_active_optimisation = '/illustration/sales/{saleId}/scenarios/{scenarioId}/death-benefit/is-active-optimisation',
  update_death_benefit_capital_value = '/illustration/sales/{saleId}/scenarios/{scenarioId}/death-benefit/capital-value',
  update_death_benefit_duration = '/illustration/sales/{saleId}/scenarios/{scenarioId}/death-benefit/duration',
  update_death_benefit_aofa = '/illustration/sales/{saleId}/scenarios/{scenarioId}/death-benefit/aofa',
  add_additional_coverage = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverage/{coverageId}/add-additional-coverage',
  coverage_by_pattern = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/patterns/{patternType}',
  pattern_data = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/patterns/data',
  update_latest_news = '/user/news/{newsId}',
  change_page_tab_content = '/change-request/{saleId}/form-types/{changeRequestType}',
  additional_coverage_action = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}',
  suppliers = '/configuration/suppliers/{organizationType}',
  companies = '/configuration/companies',
  update_requirements = '/application/sales/{saleId}/requirements',
  save_validate_requirements = '/application/sales/{saleId}/requirements/save-or-validate',
  update_beneficiary = '/application/sales/{saleId}/update-beneficiaries',
  change_request = '/change-request/{saleId}',
  change_request_form_by_id = '/change-request/{saleId}/forms/{formId}',
  update_answer_question = '/change-request/{saleId}/answers',
  create_repeating_section = '/change-request/{saleId}/repeating-sections/{repeatingSectionId}/sections',
  delete_repeating_section = '/change-request/{saleId}/repeating-sections/{repeatingSectionId}/sections/{sectionId}',
  suggested_answer = '/change-request/{saleId}/questions/{questionId}/suggestedanswers',
  save_validate_beneficiary = '/application/sales/{saleId}/individuals/{clientId}/beneficiaries/save-or-validate',
  update_consent = '/application/sales/{saleId}/individuals/{individualId}/consents',
  application_sales_saleId_coverages_save_or_validate = '/application/sales/{saleId}/coverages/save-or-validate',
  under_coverages = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/under-coverages',
  under_coverage = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/under-coverage',
  update_coverage = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/plan',
  delete_coverage = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}',
  additional_coverage_action_plan = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/plan',
  update_preferences_product_category = '/user/preferences/product-category/{productSelected}',
  preferenceType='/user/preferences/CategorieDernierProduitLance',
  save_last_product_category_type = '/user/preferences/product-category/{productType}',
  get_insurance_age = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals/insurance-age',
  get_insured_list = '/illustration/sales/{saleId}/scenarios/{scenarioId}/projection/insured-list',
  get_under_coverages = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/under-coverages',
  add_under_coverage = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/under-coverage',
  get_change_requests = '/change-request/paged',
  coverage_projection_results = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverage-projection-results',
  linked_applications_get='/application/sales/{saleId}/linked-applications',
  linked_applications_add='/application/sales/{saleId}/linked-applications/save-or-validate',
  save_validate_special_instructions = '/application/sales/{saleId}/insureds/{clientId}/special-instructions/save-or-validate',
  validate_api_error_message = '/change-request/{saleId}/validate/{formtype}',
  changes_validate_error_message = '/change-request/{saleId}/validate-all-changes',
  validate_names_get='/application/sales/{saleId}/validation/validate-names',
  validate_new_sale_get='/application/sales/{saleId}/validation/validate-new-sale',
  validate_names_add='/application/sales/{saleId}/validation/save-or-validate-names',
  export_coverage_result = '/illustration/sales/{saleId}/scenarios/{scenarioId}/export-coverage-projection',
  update_premium_frequency = '/illustration/sales/{saleId}/scenarios/{scenarioId}/premium-frequency',
  add_new_insured = '/change-request/{saleId}/forms/{formId}/insured',
  change_request_summary = '/change-request/{saleId}/summary',
  get_effective_date = '/illustration/sales/{saleId}/scenarios/{scenarioId}/effective-dates',
  effective_date_details = '/illustration/sales/{saleId}/scenarios/{scenarioId}/effective-date/details',
  update_effective_date = '/illustration/sales/{saleId}/scenarios/{scenarioId}/effective-date',
  get_premium_increase_values = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/premium',
  premium_increase = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/premium/increase',
  delete_premium_increase = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/premium',
  add_concept_type = '/illustration/sales/{saleId}/scenarios/{scenarioId}/concept',
  update_estate_bond = '/illustration/sales/{saleId}/scenarios/{scenarioId}/concept/bon-successoral',
  get_concept_limits = '/illustration/concepts/{typeConcept}/limits',
  delete_concept_type = '/illustration/sales/{saleId}/scenarios/{scenarioId}/concept-type/{typeConcept}',
  update_head_office = '/application/sales/{saleId}/headoffice/save-or-validate',
  doi_context = '/application/sales/{saleId}/individuals/{individualId}/doicontext',
  application_auto_save_advisor = '/application/sales/{saleId}/advisors/validate-auto-save',
  renewal_duration_limits_min_max_values = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/renewal-duration/limits',
  legacy_get_premium_details = '/illustration/sales/{saleId}/scenarios/{scenarioId}/legacy/premium/details',
  legacy_get_premium_periods = '/illustration/sales/{saleId}/scenarios/{scenarioId}/legacy/premium/periods',
  legacy_update_premium_type = '/illustration/sales/{saleId}/scenarios/{scenarioId}/legacy/premium/type',
  legacy_update_end_year = '/illustration/sales/{saleId}/scenarios/{scenarioId}/legacy/premium/year-end',
  legacy_update_amount = '/illustration/sales/{saleId}/scenarios/{scenarioId}/legacy/premium/amount',
  legacy_add_period = '/illustration/sales/{saleId}/scenarios/{scenarioId}/legacy/premium/period',
  legacy_delete_period = '/illustration/sales/{saleId}/scenarios/{scenarioId}/period/{periodId}/legacy/premium/period',
  legacy_calculate_periods = '/illustration/sales/{saleId}/scenarios/{scenarioId}/legacy/premium/calculate-period',
  legacy_reset_periods = '/illustration/sales/{saleId}/scenarios/{scenarioId}/legacy/premium/reset-period',
  legacy_cancel_periods = '/illustration/sales/{saleId}/scenarios/{scenarioId}/legacy/premium/period/cancel',
  illustration_sales_saleId_scenarios_scenarioId_investor_profile = '/illustration/sales/{saleId}/scenarios/{scenarioId}/investor-profile',
  genesis_get_premium_details = '/illustration/sales/{saleId}/scenarios/{scenarioId}/premium/details',
  genesis_update_premium_type = '/illustration/sales/{saleId}/scenarios/{scenarioId}/premium/type',
  genesis_update_end_year = '/illustration/sales/{saleId}/scenarios/{scenarioId}/premium/end-age',
  genesis_reset_default = '/illustration/sales/{saleId}/scenarios/{scenarioId}/premium/default-type',
  genesis_update_amount = '/illustration/sales/{saleId}/scenarios/{scenarioId}/premium/amount',
  genesis_update_for_year = '/illustration/sales/{saleId}/scenarios/{scenarioId}/premium/period',
  genesis_calculate_optimization = '/illustration/sales/{saleId}/scenarios/{scenarioId}/premium/calculate-optimization',
  genesis_calculate_capital_assure = '/illustration/sales/{saleId}/scenarios/{scenarioId}/calculate/optimized/capital-assure',
  genesis_update_desired_amount = '/illustration/sales/{saleId}/scenarios/{scenarioId}/target-fund/amount',
  genesis_update_target_year = '/illustration/sales/{saleId}/scenarios/{scenarioId}/target-fund/year',
  genesis_update_target_age = '/illustration/sales/{saleId}/scenarios/{scenarioId}/target-fund/age',
  genesis_post_premium_personalized_options = '/illustration/sales/{saleId}/scenarios/{scenarioId}/premium/personalized-options',
  genesis_save_premium_personalized_options_details = '/illustration/sales/{saleId}/scenarios/{scenarioId}/premium/personalized',
  save_or_validate_person = '/change-request/{saleId}/save-or-validate',

  aboutinfo_about='/aboutinfo/about',
  getProducts = '/change-request/{saleId}/products',
  saveProduct = '/change-request/{saleId}/products/{productName}',
  getCoverage = '/change-request/{saleId}/forms/{formId}/coverage',
  get_additional_coverage = '/change-request/{saleId}/forms/{formId}/coverage-details',
  save_coverage = '/change-request/{saleId}/forms/{formId}/insured/coverage',
  save_insured='/change-request/{saleId}/forms/{formId}/insured/',
  delete_product = '/change-request/{saleId}/products/{productId}',
  save_head_office_request = 'change-request/{saleId}/head-office-request',
  under_contract = '/change-request/{saleId}/head-office-request',
  reviewRating = '/change-request/{saleId}/rating-exclusion',
  updateBonusPayment = '/illustration/sales/{saleId}/scenarios/{scenarioId}/product/bonus-payment',
  bonusPaymentFundRates = '/illustration/sales/{saleId}/scenarios/{scenarioId}/product/bonus-payment/fund-rates',
  updateEquiFundRate = '/illustration/sales/{saleId}/scenarios/{scenarioId}/product/bonus-payment/fund-rate',
  illustration_configuration = '/user/illustration-configurations',
  illustration_configuration_type = '/user/illustration-configurations/type/{type}',
  reporting_sales_saleId_scenarios_scenarioId_parameters = '/illustration/sales/{saleId}/scenarios/{scenarioId}/parameters',
  reporting_sales_saleId_scenarios_scenarioId_parameters_language = '/reporting/sales/{saleId}/scenarios/{scenarioId}/parameters/language',
  reporting_sales_saleId_scenarios_scenarioId_parameters_projection_year = '/reporting/sales/{saleId}/scenarios/{scenarioId}/parameters/projection-year',
  reporting_sales_saleId_scenarios_scenarioId_parameters_clients = '/reporting/sales/{saleId}/scenarios/{scenarioId}/parameters/clients',
  reporting_sales_saleId_scenarios_scenarioId_parameters_advisors = '/reporting/sales/{saleId}/scenarios/{scenarioId}/parameters/advisors',
  reporting_sales_saleId_scenarios_scenarioId_parameters_dividendScale = '/reporting/sales/{saleId}/scenarios/{scenarioId}/parameters/dividend-scale',
  get_oipa_environments = '/user/application-configuration/oipa-environments',
  set_oipa_environments = '/user/application-configuration/oipa-environments/{oipaEnvironment}',
  signature_saleId_role = '/signature/{saleId}/role',
  signature_saleId_paper = '/signature/{saleId}/paper',
  signature_saleId = '/signature/{saleId}',
  signature_saleId_signatoryId = '/signature/{saleId}/{signatoryId}',
  signature_saleId_ceremony = '/signature/{saleId}/ceremony',
  signature_saleId_sign_as_advisor = '/signature/{saleId}/advisor/sign',
  signature_saleId_unlock_signer = '/signature/{saleId}/{signatoryId}/unlock',
  signature_saleId_ceremony_url_emailId = '/signature/{saleId}/ceremony/url/{emailId}',
  signature_configure = '/signature/{saleId}/configure',
  signature_province = '/signature/{saleId}/province',
  signature_check_code = '/signature/{saleId}/{agentCode}/validate',
  get_signature_questions = '/signature/{saleId}/question',
  signature_complete = '/signature/{saleId}/complete',
  identitypage_person_saveorvalidate = '/application/sales/{saleId}/individuals/person/save-or-validate',
  identitypage_organization_saveorvalidate = '/application/sales/{saleId}/individuals/organization/save-or-validate',
  complete_change_request = '/change-request/{saleId}/complete',
  report_advisor = '/reporting/sales/{saleId}/advisors/{advisorId}',
  add_report_advisor = '/reporting/sales/{saleId}/advisors',
  add_checked_advisors = '/reporting/sales/{saleId}/scenarios/{scenarioId}/advisors',
  report_request = '/illustration/sales/{saleId}/report-request',
  report_status = '/illustration/report-status/{reportRequestId}',
  report_download = '/document/document/{documentId}',
  illustration_sales_salesId_scenarios_scenarioId_concepts_change_payout = '/illustration/sales/{saleId}/scenarios/{scenarioId}/concepts/payable-cash',
  illustration_sales_salesId_scenarios_scenarioId_concepts_change_payout_start_option = '/illustration/sales/{saleId}/scenarios/{scenarioId}/concepts/payable-cash/start-option',
  illustration_sales_salesId_scenarios_scenarioId_concepts_change_payout_years = '/illustration/sales/{saleId}/scenarios/{scenarioId}/concepts/payable-cash/years',
  illustration_sales_comparison_tool = '/illustration/sales/comparison-tool',
  illustration_sales_comparison_tool_refresh_amounts = '/illustration/sales/comparison-tool/refresh-amounts',
  illustration_sales_salesId_scenarios_scenarioId_comparison_tool_selected_plan = '/illustration/sales/{saleId}/scenarios/{scenarioId}/comparison-tool/selected-plan',
  investment_interestrate = '/illustration/sales/{saleId}/scenarios/{scenarioId}/investment/interest-rate',
  investment_deposits = '/illustration/sales/{saleId}/scenarios/{scenarioId}/investment/deposits',
  investment_withdrawals = '/illustration/sales/{saleId}/scenarios/{scenarioId}/investment/withdrawals',
  illustration_sales_saleId_scenarios_scenarioId_investment = '/illustration/sales/{saleId}/scenarios/{scenarioId}/investment',
  illustration_sales_saleId_scenarios_scenarioId_investment_fundCapitalization = '/illustration/sales/{saleId}/scenarios/{scenarioId}/investment/fund-capitalization',
  illustration_sales_saleId_scenarios_scenarioId_investment_fundsAvailable = '/illustration/sales/{saleId}/scenarios/{scenarioId}/investment/funds-available',
  illustration_sales_saleId_scenarios_scenarioId_investment_accountWallet = '/illustration/sales/{saleId}/scenarios/{scenarioId}/investment/account-wallet',
  document_upload = '/signature/{saleId}/document',
  document_delete = '/signature/{saleId}/document/{documentId}',
  document_types = '/signature/{saleId}/document-type',
  report_request_application = '/application/sales/{saleId}/application/report-request',
  report_status_application = '/reporting/report-status/{reportRequestId}',
  add_scenario = '/illustration/sales/{saleId}/scenario',
  copy_scenario = '/illustration/sales/{saleId}/scenarios/{scenarioId}/duplicate',
  reset_scenario = '/illustration/sales/{saleId}/scenarios/{scenarioId}/reset',
  delete_scenario = '/illustration/sales/{saleId}/scenarios/{scenarioId}',
  validate_individual = '/illustration/sales/{saleId}/scenarios/{scenarioId}/individuals/{individualId}/validate',
  scenario_management = '/illustration/sales/{saleId}/scenarios/{scenarioId}/management',
  contract_details = '/change-request/contract/{saleId}',
  contract_insured = '/change-request/contract/{saleId}/insureds/{requestType}',
  contract_reinstatement = '/change-request/{saleId}/reinstatement',
  update_access_life_step = '/illustration/access-life/sales/{saleId}/scenarios/{scenarioId}/coverages/step',
  update_access_life_scenario = '/illustration/access-life/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}',
  access_life_scenario_calculate = '/illustration/access-life/sales/{saleId}/scenarios/{scenarioId}/coverages/calculate',
  access_life_scenario_specific_coverage_calcualte = '/illustration/access-life/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/calculate',
  download_file ='/user/sales/import?sharedKey={sharedKey}&intentType={intentType}',
  share_file='/user/sales/{saleId}/export',
  get_transmission = '/application/sales/{saleId}/transmission',
  process_transmission = '/application/sales/{saleId}/transmission/save-or-validate',
  application_access_life_step_update ='/application/sales/{saleId}/individuals/{individualId}/accesslifestep',
  application_access_life_results_validate ='/application/sales/{saleId}/individuals/{individualId}/validate-accesslifestep',
  sale_migrate = '/user/sales/{saleId}/migrate',
  get_File_List = '/user/sales/{saleId}/archives',
  download_single_file = '/user/sales/{saleId}/archives/{documentId}',
  validate_effective_date = '/user/sales/{saleId}/validate-effective-date',
  beneficiary_candidates ='/application/sales/{saleId}/individuals/{individualId}/beneficiary-candidates',
  configuration_tax_rate = '/configuration/tax-rate/default/{province}',
}

export enum relativeRoute {
  illustration_coverage_saleId = '/illustration/{saleId}/coverage',
  illustration_couverture_saleId = '/illustration/{saleId}/couverture/',
  user_application_individual = '/application/{saleId}/individuals/{individualId}/person',
  application_saleId = '/application/{saleId}',
  changes_form = '/changesform/{saleId}/{formId}/{formName}',
  changes_form_fr = '/changementsforme/{saleId}/{formId}/{formName}',
  application_saleId_summary = '/application/{saleId}/summary',
  application_saleId_resume = '/application/{saleId}/resume',
  change_route = '/application/{saleId}/echange/changes',
  changements_route = '/application/{saleId}/echange/changements',
  application_advisor_en = '/application/{saleId}/advisor',
  application_advisor_fr = '/application/{saleId}/conseiller',
  signature_mode = '/application/{saleId}/signatureechange/signature-mode',
  signature_mode_fr = '/application/{saleId}/signaturechangement/signature-mode',
  summary = '/application/{saleId}/echange/summary',
  summary_fr = '/application/{saleId}/echange/sommaire',
  validation = '/application/{saleId}/validationechange',
  validation_fr = '/application/{saleId}/validationchangement',
  validation_application = '/application/{saleId}/validation',
  signature_followup = '/application/{saleId}/signatureechange/signature-followup',
  signature_followup_fr = '/application/{saleId}/signaturechangement/signature-followup',
  signature_followup_application = '/application/{saleId}/signature/signature-followup',
  signature_mode_application = '/application/{saleId}/signature/signature-mode',
  complete_change_request = '/changes/{saleId}/complete',
  complete_change_request_fr = '/changements/{saleId}/complete',
  transmission_fr = '/application/{saleId}/transmission',
  transmission = '/application/{saleId}/transmission',
  requirements = '/application/{saleId}/requirements',
  requirements_fr = '/application/{saleId}/exigences',
  billing = '/application/{saleId}/billing',
  billing_fr = '/application/{saleId}/facturation',
}

export enum CommisionCutRoute {
  commission_cut_verify = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/commission-cuts/verify',
  commission_cut_delete = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/commission-cuts',
  commission_cut_update = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/commission-cuts/update',
  commission_cut_add = '/illustration/sales/{saleId}/scenarios/{scenarioId}/coverages/{coverageId}/commission-cuts/add'}

export enum ValidationApi {
    validation_init = '/change-request/{saleId}/validate/init',
    validation_person = '/change-request/{saleId}/person',
    validation_validate = '/change-request/{saleId}/validate',
    validation_demand ='/change-request/{saleId}/demand',
    validation_progression_details = '/change-request/{saleId}/progression/{progression}',
  }

export type BffRouteType = BffRoute | string;

export const getRelativeUrlForRoute = (route: string, params?: Record<string, string>) => {
  let routeWithParams = route;
  if (params) {
    Object.keys(params).forEach((key) => {
      routeWithParams = routeWithParams?.replace(`{${key}}`, params[key]);
    });
  }
  return routeWithParams;
};

export const getBffUrlForRoute = (route: BffRouteType, params?: Record<string, string>, useMock = false) => {
  let routeWithParams = getRelativeUrlForRoute(route, params);
  const queryParams = new URLSearchParams();
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      routeWithParams = routeWithParams.replace(`{${key}}`, value);
      queryParams.append(key, value);
    });
  }

  const useMockDataParam = useMock ? 'true' : 'false';
  queryParams.append('useMockData', useMockDataParam);
  const queryString = queryParams.toString();
  return `${process.env.REACT_APP_BFF_API}${routeWithParams}${queryString ? `?${queryString}` : ''}`;
};

export const getBffUrlForRouteforConsent = (route: BffRouteType, params?: Record<string, string>) => {
  let routeWithParams = getRelativeUrlForRoute(route, params);
  const queryParams = new URLSearchParams();
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      routeWithParams = routeWithParams.replace(`{${key}}`, value);
    });
  }
  const queryString = queryParams.toString();
  return `${process.env.REACT_APP_BFF_API}${routeWithParams}${queryString ? `?${queryString}` : ''}`;
};

// eslint-disable-next-line default-param-last
export const getBffUrlForRouteWithoutQP = (route: BffRouteType, params?: Record<string, string>, useMock = false, queryParmsObj?: Record<string, string>) => {
  const routeWithParams = getRelativeUrlForRoute(route, params);
  const queryParams = new URLSearchParams();
  const useMockDataParam = useMock ? 'true' : 'false';
  if (queryParmsObj) {
    Object.entries(queryParmsObj).forEach(([key, value]) => {
      queryParams.append(key, value);
    });
  }
  queryParams.append('useMockData', useMockDataParam);

  const queryString = queryParams.toString();
  return `${process.env.REACT_APP_BFF_API}${routeWithParams}${queryString ? `?${queryString}` : ''}`;
};

export const getUrlForInsuranceTypes = ({ planCode = '', productVersionDate = '' }) => {
  const url = new URL(getBffUrlForRoute(BffRoute.illustration_insurancetypes, undefined, false));
  url.searchParams.append('planCode', planCode);
  url.searchParams.append('productVersionDate', productVersionDate);
  return url.toString();
};

export const getUrlForAddInsured = (params?: Record<string, string>, useMock = false) => {
  const routeWithParams = getRelativeUrlForRoute(BffRoute.illustration_sales_add_insured, params);
  return `${process.env.REACT_APP_BFF_API}${routeWithParams}?useMockData=${useMock}`;
};
export const getUrlForFileDownload = (params?: Record<string, string>, useMock = false) => {
  const routeWithParams = getRelativeUrlForRoute(BffRoute.download_file, params);
  return `${process.env.REACT_APP_BFF_API}${routeWithParams}&useMockData=${useMock}`;
};

// this is part of the code compliancy task
export const getUrlForDeleteInsured = (params?: Record<string, string>, useMock = false) => {
  const routeWithParams = getRelativeUrlForRoute(BffRoute.illustration_sales_saleId_scenarios_template_individual_remove, params);
  return `${process.env.REACT_APP_BFF_API}${routeWithParams}?useMockData=${useMock}`;
};

export const getUrlForDeleteFundCapitalization = (params?: Record<string, string>, useMock = false) => {
  const routeWithParams = getRelativeUrlForRoute(BffRoute.illustration_sales_saleId_scenarios_scenarioId_investment_fundCapitalization, params);
  return `${process.env.REACT_APP_BFF_API}${routeWithParams}?accountVehiculeId=${params?.accountVehiculeId}&useMockData=${useMock}`;
};

export const getURLForReportStatus = (route: BffRouteType, params?: Record<string, string>, docIds?: string[]) => {
  const routeWithParams = getRelativeUrlForRoute(route, params);
  const queryParams = new URLSearchParams();
  if (docIds) {
    docIds.forEach((val) => {
      queryParams.append('documentIds', val);
    });
  }
  const queryString = queryParams.toString();
  const queryParamUrl = queryString ? `?${queryString}` : '';
  return `${process.env.REACT_APP_BFF_API}${routeWithParams}${queryParamUrl}`;
};
