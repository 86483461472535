/**
 * Add any resource call here in which its state is needed across application layouts
 */
import { Resource } from 'ia-react-core';
import React from 'react';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { CATALOG_RESOURCE } from '~/constants';
import { GetApplicationConfigurationResource } from '../GetApplicationConfigurationResource';

export interface SharedResourcesProps {
  effectiveDate?: string;
  versionNumber?: string;
}

const SharedResources = ({ effectiveDate, versionNumber }: SharedResourcesProps) => {
  let url = getBffUrlForRoute(BffRoute.illustration_product_catalog);
  if (effectiveDate !== null && effectiveDate !== undefined && versionNumber !== null && versionNumber !== undefined && effectiveDate !== '' && versionNumber !== '') url = getBffUrlForRoute(BffRoute.illustration_product_catalog, { effectiveDate, versionNumber });
  return (
    <>
      <Resource
        name={CATALOG_RESOURCE}
        url={url}
        autoRequest
      />
      <GetApplicationConfigurationResource autoRequest />
    </>
  );
};
export default SharedResources;
