import React, { FC, useRef, useState, useMemo, useCallback } from 'react';
import {
  Modal, PrimarySubmitButton, SecondaryButton, useTranslation, Form, closeModalByName, useScopedSelector, Resource, requestResourceByName, openModalByName,
} from 'ia-react-core';
import { useDispatch, useSelector } from 'react-redux';
import PrintReportState, { PrintReportResponse } from '~/interfaces/PrintReport';
import { BffRoute, getBffUrlForRouteWithoutQP, getURLForReportStatus } from '~/utilities/bffHelper';
import { useParams } from 'react-router';
import { AxiosResponse } from 'axios';
import promisePoller from 'promise-poller';
import { pollerConfig } from '~/fragments/IllustrationFragment/api/Config';
import { State } from '~/interfaces/State';
import { StyledActionBar } from '../IllustrationSettingsModal/IllustrationSettingsModal.styles';
import PrintReportContent from './PrintReportContent';
import AvailableForPrinting from './components/AvailableForPrinting';
import ErrorPrintModal from './components/ErrorPrintModal';
import LoaderOverlay from '../LoaderOverlay';
import { StyledLoaderDiv } from './PrintReportModal.styles';

export interface PrintReportModalProps {}

const PrintReportModal: FC<PrintReportModalProps> = () => {
  const { t } = useTranslation('PrintReportModal');
  const dispatch = useDispatch();
  const { saleId } = useParams<{ saleId: string }>();
  const [isDownloadInProcess, setIsDownloadInProcess] = useState(false);
  const pollStatus = useRef(false);
  const reportLanguage = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.language);
  const scenarioId = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.scenarioId);
  const formValues = useScopedSelector((state: PrintReportState) => state?.values?.printReport);
  const reportRequestURL = useMemo(() => getBffUrlForRouteWithoutQP(BffRoute.report_request_application, { saleId }, false), []);

  const onCloseHandler = () => {
    setIsDownloadInProcess(false);
    dispatch(closeModalByName('modals.printReport'));
  };

  const createPayload = useCallback(() => {
    const payload = {
      saleId,
      scenarioId,
      data: {
        language: reportLanguage,
        reportTypes: formValues?.selectedDocuments,
        shouldMerge: !!formValues?.isMerged,
      },
    };
    return payload;
  }, [formValues, reportLanguage, saleId, scenarioId]);

  const handlePrint = () => {
    const data = createPayload();
    setIsDownloadInProcess(true);
    dispatch(requestResourceByName('resources.reportRequest', data, { method: 'POST', url: reportRequestURL }));
    dispatch(openModalByName('modals.printLoader'));
  };

  const openErrorModal = () => {
    setIsDownloadInProcess(false);
    dispatch(closeModalByName('modals.printLoader'));
    dispatch(openModalByName('modals.printError'));
  };

  return (
    <>
      <Modal isClosable={false} name="printLoaderModal">
        <LoaderOverlay enabled delayMs={0}>
          <StyledLoaderDiv>
            { t('workInProgress') }
          </StyledLoaderDiv>
        </LoaderOverlay>
      </Modal>
      <Modal
        name="printReportModal"
        onClose={onCloseHandler}
        shouldCloseOnOverlayClick={false}
      >
        {/* REQUEST TO BE FOR REPORTS BASED ON SELECTION */}
        <Resource
          name="resources.reportRequest"
          method="POST"
          onSuccess={(res: AxiosResponse<PrintReportResponse>) => {
            const getPromise = async () => {
              if (pollStatus.current) {
                return true;
              }

              const statusURL = `https://test.com/${res?.data?.requestStatusUrl}`;
              const requestStatusUrl = new URL(statusURL);

              const params: string[] = [];
              requestStatusUrl.searchParams.forEach((value) => {
                params.push(value);
              });
              const reportStatusURL = getURLForReportStatus(BffRoute.report_status_application, { reportRequestId: res?.data?.requestId }, params);

              await (dispatch(requestResourceByName('resources.reportStatus', undefined, { url: reportStatusURL }))as unknown as Promise<void>);

              throw new Error('Polling');
            };

            pollStatus.current = false;
            setTimeout(() => {
              const poller = promisePoller({
                taskFn: getPromise,
                retries: pollerConfig.retries,
                strategy: pollerConfig.strategy,
                start: pollerConfig.start,
                increment: pollerConfig.increment,
              });
              poller.catch(() => {
                setIsDownloadInProcess(false);
              });
            }, pollerConfig.initialDelay);
          }}
          onFailure={() => { console.error('Report request failed'); openErrorModal(); }}
        />

        {/* POLLING FOR REPORT STATUS USING REQUESTID */}
        <Resource
          name="resources.reportStatus"
          method="GET"
          onSuccess={(res: AxiosResponse<{requestStatus: string;documentIdentifiers: Array<string>}>) => {
            const status = res.data?.requestStatus;
            if (status === 'Finished') {
              pollStatus.current = true;
              if (!!formValues?.isMerged === false && formValues?.selectedDocuments?.length === 2) {
                if (res?.data?.documentIdentifiers?.length === 2) {
                  const illustrationDocID = res?.data?.documentIdentifiers[1];
                  const applicationDocID = res?.data?.documentIdentifiers[0];
                  dispatch(closeModalByName('modals.printLoader'));

                  dispatch(openModalByName('modals.availableReports', { illustrationDocID, applicationDocID, isMerged: !!formValues?.isMerged }));
                  setIsDownloadInProcess(false);
                } else {
                  console.error('Did not receive 2 document identifiers');
                  setIsDownloadInProcess(false);
                }
              } else if (res?.data?.documentIdentifiers && res?.data?.documentIdentifiers?.length > 0) {
                const id = res?.data?.documentIdentifiers[0];
                dispatch(closeModalByName('modals.printLoader'));

                dispatch(openModalByName('modals.availableReports', { id, type: formValues?.selectedDocuments[0], isMerged: !!formValues?.isMerged }));
                setIsDownloadInProcess(false);
              } else {
                console.error('Did not receive document identifier');
                setIsDownloadInProcess(false);
              }
            }
            if (status === 'Exception') {
              console.error('Report status received as exception');
              pollStatus.current = true;
              openErrorModal();
            }
          }}
          onFailure={() => { console.error('Report status failed'); openErrorModal(); }}
        />

        {/* DOWNLOAD REPORT FROM DOCUMENT IDENTIFIER */}
        <Resource
          name="reportDownload"
          method="GET"
          onSuccess={(res: AxiosResponse<{content: string; name: string}>) => {
            const binaryString = atob(res.data?.content);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }
            const blob = new Blob([bytes], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${res?.data?.name?.toString() || 'report'}.pdf`;
            link.click();
            setIsDownloadInProcess(false);
            dispatch(closeModalByName('modals.printLoader'));
          }}
          onFailure={() => { console.error('Report download failed'); openErrorModal(); }}
        />
        <Form
          name="printReport"
          cleanValues
          onSubmit={handlePrint}
          hoistErrors
          hideHoistedAnchor
          errorMessageTitle={t('errorTitle')}
        >

          <PrintReportContent />

          <StyledActionBar flexButtonsXs>
            <PrimarySubmitButton disabled={isDownloadInProcess}> {t('generate')} </PrimarySubmitButton>
            <SecondaryButton onClick={onCloseHandler}>
              {t('cancel')}
            </SecondaryButton>
          </StyledActionBar>
        </Form>
      </Modal>
      <AvailableForPrinting />
      <ErrorPrintModal />
    </>
  );
};

export default PrintReportModal;
