import { requestResourceByName } from 'ia-react-core';
import { ApplicationIntentType } from '../constants';
import { SPECIAL_INSTRUCTIONS_POST } from '../pages/NomineePage/pages/SpecialInstructionsPage/constants/ConstantNames';
import { SpecialInstructionsData } from '../pages/NomineePage/pages/SpecialInstructionsPage/interfaces/SpecialInstructionsPageState';

const specialInstructionsApiCall = (formvalues: SpecialInstructionsData[], nomineeId: string, dispatch: Function) => {
  const message = formvalues?.find((x) => x.id === nomineeId)?.specialInstructions ?? '';
  dispatch(requestResourceByName(`resources.${SPECIAL_INSTRUCTIONS_POST}`, { intentType: ApplicationIntentType.SaveAndValidate, specialInstructions: message, initateFromLeftMenu: true }));
};

export default specialInstructionsApiCall;
