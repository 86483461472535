export default {
  content: 'Content',
  backToIllustration: 'Retour à Illustration',
  declarationOfInsurabilityTitle: "Déclaration d'assurabilité",
  headOfficeTitle: 'Saisie siège social',
  consentTitle: 'Consentements relatifs aux renseignements personnels',
  warning: "Attention, vous pourriez perdre les données saisies dans l'étape en cours. Voulez-vous retourner à l'étape précédente ?",
  yes: 'Oui',
  no: 'Non',
};
