import { Action } from 'redux';
import { InsurabilityDeclarationState } from '~/interfaces/InsurabilityDeclaration/State';
import { validateCaseSuccessAction } from '../InsurabilityDeclaration.actions';

import { validateDateYear } from '../utils/dateYear.validation';

export const validateCaseSuccess = (state: InsurabilityDeclarationState, action: Action): InsurabilityDeclarationState => {
  const { payload: { formId } } = validateCaseSuccessAction.getData(action);

  // Added custom DateField validation because DateField validation is not provide from Magnum API
  const newState = validateDateYear(state, formId);

  const hasExisting = Boolean(newState?.showValidation?.[formId]);

  const showValidation = hasExisting ? { ...newState.showValidation } : { ...newState.showValidation, [formId]: true };
  return {
    ...newState,
    showValidation,
  };
};
