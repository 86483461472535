import { Action } from 'redux';
import { SetAdvisorToEditAction } from '../AdvisorsModal.actions';
import { AdvisorsModalState } from '../AdvisorsSettingsModalState';

export default function advisorToEdit(state: AdvisorsModalState, action: Action): AdvisorsModalState {
  const { advisor } = action as SetAdvisorToEditAction;

  return {
    ...state,
    data: {
      ...state.data,
      advisorToEdit: advisor,
    },
  };
}
