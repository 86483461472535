import { AnsweredQuestionModel } from '~/interfaces/InsurabilityDeclaration/AnsweredQuestionModel';
import IndividualModel from '~/fragments/ApplicationFragment/interfaces/IndividualModel';
import CaseModel from '~/interfaces/InsurabilityDeclaration/CaseModel';
import { HealthCareFacilityModel } from '~/interfaces/InsurabilityDeclaration/HealthCareFacilityModel';
import ItemsModel from '~/interfaces/InsurabilityDeclaration/ItemsModel';
import { PhysicianModel } from '~/interfaces/InsurabilityDeclaration/PhysicianModel';
import QuestionIdentityModel from '~/interfaces/InsurabilityDeclaration/QuestionIdentityModel';
import RepeatingSectionModel from '~/interfaces/InsurabilityDeclaration/RepeatingSectionModel';
import { SearchHealthCareFacilityOption } from '~/interfaces/InsurabilityDeclaration/SearchHealthCareFacilityOption';
import { SearchPhysicianOption } from '~/interfaces/InsurabilityDeclaration/SearchPhysicianOption';
import SectionIdentityModel from '~/interfaces/InsurabilityDeclaration/SectionIdentityModel';
import SetAnswerResponseModel from '~/interfaces/InsurabilityDeclaration/SetAnswerResponseModel';
import { NOMINEE_ROUTES_KEY } from '~/constants/NOMINEE_ROUTES';
import FormModel from '~/interfaces/InsurabilityDeclaration/FormModel';
import { useHistory } from 'react-router';

const prefix = 'InsurabilityDeclaration';

export const LOAD_CASE_TYPE = `${prefix}.LoadCase` as const;
export const loadCase = (caseId: string) => ({
  type: LOAD_CASE_TYPE,
  caseId,
});
export type LoadCase = ReturnType<typeof loadCase>;

export const LOAD_CASE_SUCCESS_TYPE = `${prefix}.LoadCaseSuccess` as const;
export const loadCaseSuccess = (caseModel: CaseModel) => ({
  type: LOAD_CASE_SUCCESS_TYPE,
  caseModel,
});
export type LoadCaseSuccess = ReturnType<typeof loadCaseSuccess>;

export const ANSWER_QUESTIONS_TYPE = `${prefix}.AnswerQuestions` as const;
export const answerQuestions = (caseId: string, questionAnswers: AnsweredQuestionModel[], forceReloadData = false) => ({
  type: ANSWER_QUESTIONS_TYPE,
  caseId,
  questionAnswers,
  forceReloadData,
});
export type AnswerQuestions = ReturnType<typeof answerQuestions>;

export const ANSWER_QUESTIONS_SUCCESS_TYPE = `${prefix}.AnswerQuestionsSuccess` as const;
export const answerQuestionsSuccess = (question: QuestionIdentityModel, values: unknown[], result: SetAnswerResponseModel[]) => ({
  type: ANSWER_QUESTIONS_SUCCESS_TYPE,
  question,
  values,
  result,
});
export type AnswerQuestionsSuccess = ReturnType<typeof answerQuestionsSuccess>;

export const SET_LOADING_TYPE = `${prefix}.SetLoading` as const;
export const setLoading = (loading: boolean) => ({
  type: SET_LOADING_TYPE,
  loading,
});
export type SetLoading = ReturnType<typeof setLoading>;

export const CLEAR_LOADING_TYPE = `${prefix}.ClearLoading` as const;
export const clearLoading = () => ({
  type: CLEAR_LOADING_TYPE,
});
export type ClearLoading = ReturnType<typeof setLoading>;

export const FATAL_ERROR_TYPE = `${prefix}.FatalError` as const;
export const fatalError = (logMessage: string, error?: unknown) => ({
  type: FATAL_ERROR_TYPE,
  logMessage,
  error,
});
export type FatalError = ReturnType<typeof fatalError>;

export const GO_PREV_NEXT_FORM_ACTION_TYPE = `${prefix}.GoPrevNextForm` as const;
export const goPrevNextFormAction = (
  isNext: boolean,
  caseId: string,
  currentForm: FormModel,
  isAccessLife: boolean,
  previousRoute?: string,
  nextRoute?: string,
  history?: ReturnType<typeof useHistory>,
) => ({
  type: GO_PREV_NEXT_FORM_ACTION_TYPE,
  isNext,
  caseId,
  currentForm,
  isAccessLife,
  previousRoute,
  nextRoute,
  history,
});
export type GoPrevNextFormAction = ReturnType<typeof goPrevNextFormAction>;

export const REDIRECT_ACTION_TYPE = `${prefix}.Redirect` as const;
export const redirectAction = (routeKey: NOMINEE_ROUTES_KEY, params?: Record<string, string | number>) => ({
  type: REDIRECT_ACTION_TYPE,
  routeKey,
  params,
});
export type RedirectAction = ReturnType<typeof redirectAction>;

export const CLEAN_REDIRECT_ACTION_TYPE = `${prefix}.CleanRedirect` as const;
export const cleanRedirectAction = () => ({
  type: CLEAN_REDIRECT_ACTION_TYPE,
});
export type CleanRedirectAction = ReturnType<typeof cleanRedirectAction>;

export const SET_CURRENT_FORM_ID_TYPE = `${prefix}.SetCurrentFormId` as const;
export const setCurrentFormId = (formId: string) => ({
  type: SET_CURRENT_FORM_ID_TYPE,
  formId,
});
export type SetCurrentFormId = ReturnType<typeof setCurrentFormId>;

// New redux structure proposal could also be enhanced with @redux/toolkit
interface ActionData<T> {
  type: string;
  payload?: T;
}
interface PayloadAction<T> {
  type: string;
  getData: (action: ActionData<unknown>) => ActionData<T>;
  (payload: T): ActionData<T>;
}
export class ActionCreator {
  private createType(type: string) {
    return this.typePrefix ? `${this.typePrefix}.${type}` : type;
  }

  public createAction<PayloadType>(type: string) {
    const derivedType = this.createType(type);
    const action: PayloadAction<PayloadType> = (payload: PayloadType) => ({
      type: derivedType,
      payload,
    });
    action.getData = (actionData) => actionData as ActionData<PayloadType>;
    action.type = derivedType;
    return action;
  }

  constructor(private typePrefix?: string) {}
}

type OnCaseCreate = (caseId: string) => void;
type OnCaseValidation = (caseModel: CaseModel) => void;

const actionCreator = new ActionCreator('InsurabilityDeclaration' as const);
export const createCaseAction = actionCreator.createAction<{ language: string; individual: IndividualModel; saleId: string; onCreate?: OnCaseCreate }>('CreateCase');
export type CreateCaseAction = ReturnType<typeof createCaseAction>;

export const validateCaseAction = actionCreator.createAction<{ caseId: string; formId?: string; onValidate?: OnCaseValidation }>('ValidateCase');
export const validateCaseSuccessAction = actionCreator.createAction<{ formId: string }>('ValidateCaseSuccess');
export type ValidateCaseSuccessAction = ReturnType<typeof validateCaseSuccessAction>;

export const CREATE_REPEATING_SECTION_TYPE = `${prefix}.CreateRepeatingSection` as const;
export const createRepeatingSection = (caseId: string, repeatingSection: RepeatingSectionModel) => ({
  type: CREATE_REPEATING_SECTION_TYPE,
  caseId,
  repeatingSection,
});
export type CreateRepeatingSection = ReturnType<typeof createRepeatingSection>;

export const CREATE_REPEATING_SECTION_SUCCESS_TYPE = `${prefix}.CreateRepeatingSectionSuccess` as const;
export const createRepeatingSectionSuccessAction = (repeatingSectionId: string, newInstance: SectionIdentityModel, items: ItemsModel) => ({
  type: CREATE_REPEATING_SECTION_SUCCESS_TYPE,
  repeatingSectionId,
  newInstance,
  items,
});
export type CreateRepeatingSectionSuccessAction = ReturnType<typeof createRepeatingSectionSuccessAction>;

export const DELETE_INSTANCE_SECTION_TYPE = `${prefix}.DeleteInstanceSection` as const;
export const deleteInstanceSectionAction = (caseId: string, repeatingSectionId: string, instanceId: string) => ({
  type: DELETE_INSTANCE_SECTION_TYPE,
  caseId,
  repeatingSectionId,
  instanceId,
});
export type DeleteInstanceSectionAction = ReturnType<typeof deleteInstanceSectionAction>;

export const DELETE_INSTANCE_SECTION_SUCCESS_TYPE = `${prefix}.DeleteInstanceSectionSuccess` as const;
export const deleteInstanceSectionSuccessAction = (repeatingSectionId: string, instanceId: string) => ({
  type: DELETE_INSTANCE_SECTION_SUCCESS_TYPE,
  repeatingSectionId,
  instanceId,
});
export type DeleteInstanceSectionSuccessAction = ReturnType<typeof deleteInstanceSectionSuccessAction>;

// Physician / Health Care Facility

export const SEARCH_PHYSICIAN_TYPE = `${prefix}.SearchPhysician` as const;
export const searchPhysicianAction = (searchObject: SearchPhysicianOption) => ({
  type: SEARCH_PHYSICIAN_TYPE,
  searchObject,
});
export type SearchPhysicianAction = ReturnType<typeof searchPhysicianAction>;

export const SEARCH_PHYSICIAN_SUCCESS_TYPE = `${prefix}.SearchPhysicianSuccess` as const;
export const searchPhysicianSuccessAction = (searchResult: PhysicianModel[]) => ({
  type: SEARCH_PHYSICIAN_SUCCESS_TYPE,
  searchResult,
});
export type SearchPhysicianSuccessAction = ReturnType<typeof searchPhysicianSuccessAction>;

export const SEARCH_HEALTH_CARE_FACILITY_TYPE = `${prefix}.SearchHealthCareFacility` as const;
export const searchHealthCareFacilityAction = (searchObject: SearchHealthCareFacilityOption) => ({
  type: SEARCH_HEALTH_CARE_FACILITY_TYPE,
  searchObject,
});
export type SearchHealthCareFacilityAction = ReturnType<typeof searchHealthCareFacilityAction>;

export const SEARCH_HEALTH_CARE_FACILITY_SUCCESS_TYPE = `${prefix}.SearchHealthCareFacilitySuccess` as const;
export const searchHealthCareFacilitySuccessAction = (searchResult: HealthCareFacilityModel[]) => ({
  type: SEARCH_HEALTH_CARE_FACILITY_SUCCESS_TYPE,
  searchResult,
});
export type SearchHealthCareFacilitySuccessAction = ReturnType<typeof searchHealthCareFacilitySuccessAction>;

export const CLEAN_SEARCH_PHYSICIAN_HCF_TYPE = `${prefix}.CleanSearchPhysicianHCF` as const;
export const cleanSearchPhysicianHCFAction = () => ({
  type: CLEAN_SEARCH_PHYSICIAN_HCF_TYPE,
});
export type CleanSearchPhysicianHCFAction = ReturnType<typeof cleanSearchPhysicianHCFAction>;

export const ADD_PHYSICIAN_HCF_TYPE = `${prefix}.AddPhysicianHCF` as const;
export const addPhysicianHCFAction = (physicianHCF: PhysicianModel | HealthCareFacilityModel) => ({
  type: ADD_PHYSICIAN_HCF_TYPE,
  physicianHCF,
});
export type AddPhysicianHCFAction = ReturnType<typeof addPhysicianHCFAction>;

export const CHOSE_FAMILY_PHYSICIAN_HCF_TYPE = `${prefix}.ChoseFamilyPhysicianHCF` as const;
export const choseFamilyPhysicianHCFAction = (physicianHCF: PhysicianModel | HealthCareFacilityModel) => ({
  type: CHOSE_FAMILY_PHYSICIAN_HCF_TYPE,
  physicianHCF,
});
export type ChoseFamilyPhysicianHCFAction = ReturnType<typeof choseFamilyPhysicianHCFAction>;

export const CHOSE_MEDICAL_CONDITION_PHYSICIAN_HCF_TYPE = `${prefix}.ChoseMedicalConditionPhysicianHCF` as const;
export const choseMedicalConditionPhysicianHCFAction = (physicianHCF: PhysicianModel | HealthCareFacilityModel, medicalConditionId: string) => ({
  type: CHOSE_MEDICAL_CONDITION_PHYSICIAN_HCF_TYPE,
  physicianHCF,
  medicalConditionId,
});
export type ChoseMedicalConditionPhysicianHCFAction = ReturnType<typeof choseMedicalConditionPhysicianHCFAction>;

export const RESET_PHYSICIAN_HCF_DATA_TYPE = `${prefix}.ResetPhysicianHCFData` as const;
export const resetPhysicianPhysicianHCFAction = () => ({
  type: RESET_PHYSICIAN_HCF_DATA_TYPE,
});
export type ResetPhysicianPhysicianHCFAction = ReturnType<typeof resetPhysicianPhysicianHCFAction>;
