import { State } from '~/interfaces/State';

export const selectCatalogue = (state: State) => state?.App?.data?.catalog?.categories;

export const selectEffectiveDate = (state: State) => state?.App?.values?.appVersion?.effectiveDate;

export const selectVersion = (state: State) => state?.App?.values?.appVersion?.version;

export const selectEffectiveDateFromForm = (state: State) => state?.App?.fragments?.HomeFragment?.pages?.CreateIllustrationPage?.values?.versioning?.effectiveDate;

export const selectVersionNumberOptions = (state: State) => state?.App?.data?.versionNumberOptions;

export const selectUserGroups = (state: State) => state?.App?.data?.permissions?.groups;
export const selectUserPermissions = (state: State) => state?.App?.data?.permissions?.permissions;
