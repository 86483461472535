import { AddCircleIcon, requestResourceByName, T4, TertiaryButton } from 'ia-react-core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import RepeatingSectionIdentityModel from '~/interfaces/InsurabilityDeclaration/RepeatingSectionIdentityModel';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '~/interfaces/AppState';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import SectionDto from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/dtos/SectionDto';
import ItemsBuilder from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/builders/ItemsBuilder';
import SectionBuilder from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/builders/SectionBuilder';
import { createRepeatingSectionSuccessAction } from '~/fragments/SharedLibrary/ChangeRequest.actions';
import ChangeRequestModel from '~/interfaces/InsurabilityDeclaration/ChangeRequestModel';
import selectedCase from '~/fragments/SharedLibrary/selectors/selectedCase';
import { StyledSectionTitle } from './Section.styles';
import CompactInstanceSection from './CompactInstanceSection';
import InstanceSection from './InstanceSection';

export interface RepeatingSectionProps {
  repeatingSection: RepeatingSectionIdentityModel;
  formId: string;
}

const RepeatingSection: FC<RepeatingSectionProps> = ({ formId, repeatingSection: repeatingSectionIdentity }) => {
  const dispatch = useDispatch();
  const changeData: ChangeRequestModel = useSelector((state: AppState) => selectedCase(state));
  const postRepeatingSection = useSelector((s: AppState) => s.App?.fragments?.ApplicationFragment?.data?.create_repeating_section);
  const repeatingSection = changeData.repeatingSections[repeatingSectionIdentity.id];
  const [addRepeatingId, setAddRepeatingId] = useState<string>();
  const [repeatingSectionState, setRepatingSectionState] = useState<SectionDto>();

  useEffect(() => {
    if (postRepeatingSection && addRepeatingId && (addRepeatingId === repeatingSectionIdentity.id) && (postRepeatingSection.id !== repeatingSectionState?.id)) {
      const itemsBuilder = new ItemsBuilder();
      const sectionBuilder = new SectionBuilder();
      setAddRepeatingId(undefined);
      dispatch(createRepeatingSectionSuccessAction(
        repeatingSectionIdentity.id,
        sectionBuilder.toIdentityModel(postRepeatingSection),
        itemsBuilder.buildSection(postRepeatingSection, repeatingSection.casePath$).getItems(),
      ));
    }
  }, [postRepeatingSection, dispatch, addRepeatingId, repeatingSectionState]);

  useEffect(() => {
    if (postRepeatingSection) setRepatingSectionState(postRepeatingSection);
  }, [postRepeatingSection]);

  const handleAddButtonClick = useCallback(() => {
    setAddRepeatingId(repeatingSectionIdentity.id);
    const param = { saleId: changeData?.id, repeatingSectionId: repeatingSectionIdentity?.id };
    const url = getBffUrlForRouteWithoutQP(BffRoute.create_repeating_section, param, false);
    dispatch(requestResourceByName('resources.create_repeating_section', param, { url }));
  }, [changeData?.id, dispatch, repeatingSectionIdentity?.id]);

  const maxInstances = repeatingSection.maxInstances ? repeatingSection.maxInstances : 10;

  return (repeatingSection.isActive && (
    <>
      {!!repeatingSection.name
      &&
      <>
        <T4>{repeatingSection.name}</T4>
        <br />
      </>}

      {!!repeatingSection.title && <StyledSectionTitle>{repeatingSection.title}</StyledSectionTitle>}
      {repeatingSectionIdentity.instances.map((s, idx, arr) => (
        repeatingSection.isCompact ?
          (<CompactInstanceSection
            key={`rsi_${repeatingSection.id}`}
            section={s}
            sectionIndex={idx}
            formId={formId}
            isAloneInstance={arr.length === 1}
            parentRepeatingId={repeatingSection.id}
          />) :
          (
            <InstanceSection
              key={`rsi_${repeatingSection.id}`}
              section={s}
              formId={formId}
              isAloneInstance={arr.length === 1}
              parentRepeatingId={repeatingSection.id}
            />
          )
      ))}
      { repeatingSectionIdentity.instances.length < maxInstances &&
      <div>
        <TertiaryButton text LeftIcon={AddCircleIcon} onClick={handleAddButtonClick}>{repeatingSection.addText}</TertiaryButton>
      </div>}
      <br />
    </>
  ));
};

export default RepeatingSection;
