import React from 'react';
import {
  FormRow, FormCol, DateControl, NumberControl, T5, Visible, Spacing, ToggleSwitchControl,
} from 'ia-react-core';
import { useTranslation } from 'react-i18next';

import usePremiumComparisonToolManager from '~/components/PremiumComparisonToolModal/hooks/usePremiumComparisonToolManager';
import { FEMININ, MASCULIN } from '~/components/PremiumComparisonToolModal/constants';
import { numericValidatorWithKeys } from '~/components/MakeEchangeModal/utilities/Validators';
import { StyledInsuredPersonDataManIcon, StyledInsuredPersonDataWomanIcon } from './InsuredPersonDataSection.styles';

const InsuredPersonDataSection = () => {
  const { t } = useTranslation('InsuredPersonsContent');
  const { dateFormat, insuredName, onFormValuesChanged, onInputFieldChanged, handleOnInputFieldValuesChanged } = usePremiumComparisonToolManager();

  const onGenderToggleValueChanged = (val: string) => {
    onFormValuesChanged({ gender: val });
  };

  const onIsSmokerToggleValueChanged = (val: boolean) => {
    onFormValuesChanged({ isSmoker: val });
  };

  return (
    <>
      <T5 marginBottom={0}>{insuredName}</T5>
      <FormRow>
        <FormCol label={t('bornOn')} width="130px" labelMarginBottom={0}>
          <DateControl
            dateFormat={dateFormat}
            name="dob"
            onBlur={handleOnInputFieldValuesChanged}
            hideErrorMessages
            onChange={onInputFieldChanged}
            onKeyDown={numericValidatorWithKeys}
          />
        </FormCol>
        <FormCol label={t('age')} width="64px" labelMarginBottom={0}>
          <NumberControl
            name="age"
            maxLength={2}
            onBlur={handleOnInputFieldValuesChanged}
            onChange={onInputFieldChanged}
          />
          <Visible xs>
            <Spacing m-t={16} />
          </Visible>
        </FormCol>
        <FormCol
          label={t('gender')}
          col-xs={12}
          width-xs="100%"
          width-sm="80px"
          labelMarginBottom={0}
        >
          <ToggleSwitchControl
            name="gender"
            options={[
              { label: <StyledInsuredPersonDataManIcon />, value: MASCULIN },
              { label: <StyledInsuredPersonDataWomanIcon />, value: FEMININ },
            ]}
            fullWidth
            onValueChange={onGenderToggleValueChanged}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol
          label={t('tobaccoUse')}
          col-xs={12}
          width-xs="100%"
          width-sm="108px"
          labelMarginBottom={0}
        >
          <ToggleSwitchControl
            name="isSmoker"
            options={[
              { label: t('no'), value: false },
              { label: t('yes'), value: true },
            ]}
            fullWidth
            onValueChange={onIsSmokerToggleValueChanged}
          />
        </FormCol>
      </FormRow>
    </>
  );
};

export default InsuredPersonDataSection;
