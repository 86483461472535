import { BillingPageState } from '~/interfaces/Billing/BillingPageState';
import { ApplicationIntentType } from '../../../constants';

type BillingPagePayloadType = {
  intentType: number;
  billingViewModel: BillingPageState;
};

const formatBillingPayloadDTO = (formValues: BillingPageState, intentType: ApplicationIntentType): BillingPagePayloadType => (
  {
    intentType,
    billingViewModel: {
      ...formValues,
      paidAmount: formValues?.paidAmount || 0,
      withdrawalDay: formValues?.isWithdrawalDayChosenByClient ? formValues?.withdrawalDay : null,
    },
  });

export default formatBillingPayloadDTO;
