import { Resource } from 'ia-react-core';
import React from 'react';
import logger from '~/utilities/logger';

export const UPDATE_LATEST_PRODUCT = 'productCategory';

export interface UpdateUserPreferenceProductProps {
  updateUserPreferenceProductUrl: string;
}

const UpdateLatestUsedProduct = ({ updateUserPreferenceProductUrl }: UpdateUserPreferenceProductProps) => (
  <Resource
    name={UPDATE_LATEST_PRODUCT}
    url={updateUserPreferenceProductUrl}
    method="POST"
    onFailure={() => {
      logger.error('failed to update product category.');
    }}
  />
);

export default UpdateLatestUsedProduct;
