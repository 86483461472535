import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { LoadApplicationIndividualsAction } from '../ChangeRequest.actions';

export default function LoadApplicationApplicantsReducer(state: ApplicationFragmentState, action: Action):
ApplicationFragmentState {
  const { individuals } = action as LoadApplicationIndividualsAction;
  return {
    ...state,
    individuals: [
      ...individuals,
    ],
  };
}
