export const REQUIREMENT_FORM_NAME = 'requirements';
export const NON_REQUIREMENT_FORM_NAME = 'nonMedical';
export const REQUIREMENT_FORM_DETAILS = 'requirmentdetails';
export const UPDATE_REQUIREMENTS_RESOURCE_NAME = 'updateRequirements';
export const SAVE_REQUIREMENTS_RESOURCE_NAME = 'saveRequirements';
export const AUTO_SAVE_REQUIREMENTS_RESOURCE_NAME = 'autoSaveRequirements';
export const OTHER_TELEPHONE_VALUE = '3';
export const DEFAULT_SERVICE_PROVIDER_ID = 'MEDSYS';
export const DEFAULT_SERVICE_PROVIDER_NAME = 'Dynacare';
export const MEDICAL_REQUIREMENT_TYPE = 'Medical';
export const NONMEDICAL_REQUIREMENT_TYPE = 'NonMedical';
