import { createSelector } from 'reselect';
import selectForms from './selectForms';

export default createSelector(
  selectForms,
  (forms) => (urlName: string) => {
    if (forms.length > 0) {
      const firstElement = forms[0];
      return firstElement?.urlName === urlName;
    }

    return false;
  },
);
