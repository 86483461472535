import React from 'react';
import { RouteDefinitions } from 'ia-react-core';
import DynamicFormLoader from '~/fragments/SharedLibrary/Components/DynamicFormLoader';
import ROUTES_BY_NAME from './ROUTES_BY_NAME';

export const APPLICATION_PATH = '/application/:saleId';

const ROUTES: RouteDefinitions = [
  ROUTES_BY_NAME.ROLES_PAGE,
  ROUTES_BY_NAME.ILLUSTRATION_FRAGMENT,
  ROUTES_BY_NAME.APPLICATION_FRAGMENT,
  {
    label: { en: 'Changesform', fr: 'Changementsforme' },
    path: { en: '/changesform/:saleId/:formId/:formName', fr: '/changementsforme/:saleId/:formId/:formName' },
    Component: () => <DynamicFormLoader />,
  },
  // Declared last as matches the base path
  ROUTES_BY_NAME.HOME_FRAGMENT,
];

export default ROUTES;
