import { ValidatorMap } from 'ia-react-core';
import { Scenario } from '~/interfaces/Scenario';

const prefix = 'Illustration';
export const SET_SCENARIO = 'SET_SCENARIO' as const;
export const setScenario = (scenario: Scenario) => ({
  type: SET_SCENARIO,
  scenario,
});

export type SetScenario = ReturnType<typeof setScenario>;

export type IllustrationFragmentActions = SetScenario | SetValidationMessagesAction<any> | SetValidationMessagesInValidationPageAction<any> | ResetFormAction | SetAdvisorValidationMessagesAction<any>;

export const SET_VALIDATION_MESSAGES_TYPE = `${prefix}.SetValidations` as const;
export const setValidationMessagesAction = <Value>(page: string, formName: string, instanceId: string, validatorMap: ValidatorMap<Value>, appPage?: string) => ({
  type: SET_VALIDATION_MESSAGES_TYPE,
  page,
  formName,
  instanceId,
  validatorMap,
  appPage,
});
export type SetValidationMessagesAction<Value> = ReturnType<typeof setValidationMessagesAction<Value>>;

export const SET_ADVISOR_VALIDATION_MESSAGES_TYPE = 'Advisor.SetValidations' as const;
export const setAdvisorValidationMessagesAction = <Value>(page: string, formName: string, validatorMap: ValidatorMap<Value>, appPage?: string) => ({
  type: SET_ADVISOR_VALIDATION_MESSAGES_TYPE,
  page,
  formName,
  validatorMap,
  appPage,
});
export type SetAdvisorValidationMessagesAction<Value> = ReturnType<typeof setAdvisorValidationMessagesAction<Value>>;
export const resetErrorFormAction = (formName: Array<string> = []) => ({
  type: RESET_ERROR_FORM,
  formName,
});
export const RESET_ERROR_FORM = `${prefix}.ResetErrorForm` as const;
export type ResetFormAction = ReturnType<typeof resetErrorFormAction>;
export const SET_VALIDATION_MESSAGES_VALIDATION_TYPE = `${prefix}.SetValidationsValidationPage` as const;
export const setValidationMessagesInValidationPageAction = <Value>(page: string, formName: string, validatorMap: ValidatorMap<Value>) => ({
  type: SET_VALIDATION_MESSAGES_VALIDATION_TYPE,
  page,
  formName,
  validatorMap,
});
export type SetValidationMessagesInValidationPageAction<Value> = ReturnType<typeof setValidationMessagesInValidationPageAction<Value>>;
