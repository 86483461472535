import { Language } from './enums';
import SectionModel from './SectionModel';
import QuestionModel from './QuestionModel';
import RepeatingSectionModel from './RepeatingSectionModel';
import FormModel from './FormModel';

export default class ChangeRequestModel {
  constructor(
    public id: string,
    public forms: Array<FormModel>,
    public language: Language,
    public sections: Record<string, (SectionModel)>,
    public questions: Record<string, (QuestionModel)>,
    public repeatingSections: Record<string, (RepeatingSectionModel)>,
    public isOipa: boolean,
  ) {}
}
