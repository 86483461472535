import React from 'react';
import { P } from 'ia-react-core';
import { StyledContratorColumn } from '../Makeechange.styles';
export interface ContractorDetailProp {
  title: string;
  value: string;
}

const ContractorDetail = ({ title, value }: ContractorDetailProp) => (
  <StyledContratorColumn>
    <P>{title}</P>
    <P>{value}</P>
  </StyledContratorColumn>
);

export default ContractorDetail;
