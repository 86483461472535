import { Action } from 'redux';
import { FatalError } from '../AdvisorsModal.actions';
import { AdvisorsModalState } from '../AdvisorsSettingsModalState';

export default function fatalError(state: AdvisorsModalState, action: Action): AdvisorsModalState {
  const { error } = action as FatalError;

  return {
    ...state,
    fatalError: error,
  };
}
