import { LANGUAGE_EN, LANGUAGE_FR } from '~/constants/APP_CONSTANTS';

export const languageMap = {
  Anglais: LANGUAGE_EN,
  Francais: LANGUAGE_FR,
};

export const reverseLanguageMap = {
  [LANGUAGE_EN]: 'Anglais',
  [LANGUAGE_FR]: 'Francais',
};

export const reportLanguageMap = {
  0: 'Francais',
  1: 'Anglais',
};

export const reportLanguage118nMap = {
  [LANGUAGE_EN]: 1,
  [LANGUAGE_FR]: 0,
};
