import type { PromisePollerOptions } from 'promise-poller';
export const getApiRootUrl = () =>
  (process.env.REACT_APP_MOCK_API === 'true'
    ? '/api'
    : process.env.REACT_APP_BFF_API);

export const pollerConfig = {
  retries: 5,
  strategy: 'linear-backoff' as PromisePollerOptions<Function>['strategy'],
  start: 0,
  increment: 5000,
  initialDelay: 5000,
};
