import { FilePdfBoxIcon, FileWordIcon, FileExcelIcon, FileDocumentIcon, FilePowerpointIcon } from 'ia-react-core';
import React from 'react';

export const getFileIcon = (iconType: string) => {
  switch (iconType?.split('/')[1]?.replace('.', '')) {
    case 'pdf':
      return <FilePdfBoxIcon />;
    case 'word':
      return <FileWordIcon />;
    case 'excel':
      return <FileExcelIcon />;
    case 'text':
    case 'txt':
      return <FileDocumentIcon />;
    default:
      return <FilePowerpointIcon />;
  }
};
