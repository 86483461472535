import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { SetLoading } from '../ApplicationFragment.actions';

export default function setLoading(state: ApplicationFragmentState, action: Action): ApplicationFragmentState {
  const { loading } = action as SetLoading;

  const { loadingCount = 0 } = state;

  const newLoadingCount = loading ? loadingCount + 1 : loadingCount - 1;

  return {
    ...state,
    loadingCount: newLoadingCount < 0 ? 0 : newLoadingCount,
    isLoading: newLoadingCount > 0,
  };
}
