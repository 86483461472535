import { compose } from 'redux';
import { FC } from 'react';

import {
  withStore,
  withSaga,
  injectAppReducer,
  configTranslation,
  withRouter,
  injectTranslations,
  configSchema,
  CategorySchema,
} from 'ia-react-core';

import appReducer from './App.reducer';
import App, { AppProps } from './App';

import fr from './translations/fr';
import en from './translations/en';
import { GlobalAction } from './interfaces/GlobalAction';
import { AppState } from './interfaces/AppState';

const initialState = {};
const storeOptions = { initialState };

const initialAppState: Partial<AppState> = {
  values: {
    isPcToolFormDisabled: true,
    appVersion: { version: '', effectiveDate: '' },
  },
};

interface ConfiguredAppProps {}

const ConfiguredApp = compose<FC<ConfiguredAppProps>>(
  withStore(storeOptions),
  configSchema(new CategorySchema({ strict: false })),
  withSaga,
  withRouter,
  configTranslation({ saveCookie: true }),
  injectTranslations('App', { fr, en }),
  injectAppReducer<AppProps, AppState, GlobalAction>({
    reducer: appReducer,
    initialState: initialAppState,
  }),
)(App);

export default ConfiguredApp;
