import React, { ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { TextInput, Textarea } from 'ia-react-core';
import { StringFieldUtils } from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/utils/field.utils';
import validateValue from '~/fragments/ChangeFragment/utilities/StringFieldValidator/StringFieldValidator';
import QuestionField from './QuestionField';

const StringField: QuestionField = ({ question, onChange, hasError, hasWarning, disabled, ...props }) => {
  const [value, setValue] = useState(((question.answers[0]?.value?.toString()) || ''));

  const debounced = useMemo(() => debounce(onChange, 500, { trailing: true }), [onChange]);

  const { isMultiline, isHidden, maskFormatter } = StringFieldUtils.useStringFormatter(question.displayType);

  const maxLength = question.maxLength > 0 ? question.maxLength : 2000;

  useEffect(() => {
    if (disabled) { setValue(''); }
  }, [disabled]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!validateValue(question.displayType, event.target.value)) {
      event.preventDefault();
    } else {
      const newValue = maskFormatter(event.target.value);
      if (newValue !== value) {
        setValue(newValue);
        debounced([newValue]);
      }
    }
  }, [debounced, maskFormatter, value]);

  const additionalProps = {
    ...props,
    name: question.id,
    value: value?.toString(),
    onChange: handleChange,
    isTouched: true,
    isValid: !hasError,
    hasWarning,
  };

  if (isHidden) {
    return null;
  }

  return (
    isMultiline ? <Textarea autoComplete="off" {...additionalProps} maxLength={maxLength} disabled={disabled} /> : <TextInput autoComplete="off" maxLength={maxLength} {...additionalProps} disabled={disabled} leftSign={question?.leftSign} />
  );
};

export default StringField;
