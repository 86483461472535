export const UPDATE_SCENARIO_CONCEPT = 'update_scenario_concept';
export const GET_CONCEPT_LIMIT = 'get_concept_limit';
export const UPDATE_ESTATEBOND = 'update_estatebond';
export const ESTATE_BOND = 'BonSuccessoral';
export const STANDARD_ILUSTRATION = 'IllustrationReguliere';
export const TOTAL_CONCEPTS_AMOUNT = 100;
export const PERCENT_SIGN = '%';
export const WARNING_BORDER = 'warning-border';
export const ESTATE_BOND_FORM_VALUES = 'estateBondFormValues';
export const ESTATE_BOND_ALLOWED_KEYBOARD_KEYS = ['Backspace', 'ArrowRight', 'ArrowLeft', 'Tab', 'Delete'];
export const ESTATE_BOND_NUMBER_REGEX_EN = /^[\d.]+$/;
export const ESTATE_BOND_NUMBER_REGEX_FR = /^[\d.,]+$/;
export const DEFAULT_EN_VALUE = '0.00';
export const DEFAULT_FR_VALUE = '0,00';
