import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { GET_OIPA_ENVIRONMENTS_RESOURCE_NAME } from '../../constants/constants';

const GetOipaEnvironmentsResource: FC = () => {
  const url = getBffUrlForRoute(BffRoute.get_oipa_environments);

  return (
    <Resource
      name={GET_OIPA_ENVIRONMENTS_RESOURCE_NAME}
      url={url}
      method="GET"
      autoRequest
    />
  );
};

export default GetOipaEnvironmentsResource;
