import {
  ArrowLeftIcon,
  Col,
  Spacing,
  useScopedSelector,
} from 'ia-react-core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { State } from '~/interfaces/State';
import { selectCatalogue } from '~/utilities/selectors';
import getWindowWidth from '~/utilities/getWindowWidth';
import { LoadingOverlay } from '~/components/LoadingOverlay';
import { ButtonLink } from '~/components/ButtonLink';
import { StyledCreateIllustrationContainer, StyledCreateIllustrationProductsContainer } from './CreateIllustration.styles';
import CreateIllustrationSideNav from './components/CreateIllustrationSideNav';
import InsurancePage from './components/InsurancePage';
import getPageId from './components/InsurancePage/utilities/pageIds';
import { displayBackButton, displayInitialPage, displaySideNav } from './utilities';
import { CREATE_NEW_SALES_RESOURCE_NAME, UPDATE_BASE_COVERAGE_RESOURCE_NAME } from './CreateIllustration.constants';

export interface CreateIllustrationProps { }

const CreateIllustration = () => {
  const catalogData = useSelector((state: State) => selectCatalogue(state)) ?? [];
  const { t } = useTranslation('CreateIllustration');
  let initialState = 1;
  let isMobile = false;

  const [windowWidth, setWindowWidth] = useState(getWindowWidth);

  if (windowWidth < 768) {
    initialState = 0;
    isMobile = true;
  }

  const [currentPage, setCurrentPage] = useState(initialState);
  const userPreferenceType = useScopedSelector<string>('data.preferenceType');

  const handleClick = (pageId: number) => {
    setCurrentPage(pageId);
  };

  const handleBackButton = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setCurrentPage(0);
    if (e) e.preventDefault();
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(getWindowWidth());
    };

    window.addEventListener('resize', updateWindowWidth);

    return (() => {
      window.removeEventListener('resize', updateWindowWidth);
    });
  }, [windowWidth]);

  useEffect(() => {
    if (userPreferenceType) {
      setCurrentPage(getPageId(userPreferenceType));
    }
  }, [userPreferenceType]);

  const hasCatalogData = catalogData.length !== 0;

  const isSaleCreateLoading = useScopedSelector<boolean>(`resources.${CREATE_NEW_SALES_RESOURCE_NAME}.pending`);
  const isBaseCoverageLoading = useScopedSelector<boolean>(`resources.${UPDATE_BASE_COVERAGE_RESOURCE_NAME}.pending`);

  const isLoading = isSaleCreateLoading || isBaseCoverageLoading || !hasCatalogData;

  return (
    <StyledCreateIllustrationContainer>
      <LoadingOverlay isLoading={isLoading} />

      {
        hasCatalogData &&
        <>
          {
            displaySideNav(isMobile, currentPage) &&
            <CreateIllustrationSideNav data={catalogData} onClick={handleClick} currentPage={currentPage} isHome />
          }

          <StyledCreateIllustrationProductsContainer>
            {
              displayBackButton(isMobile, currentPage) &&
              <Col xs={12} p-l={0} data-testid="back-button-container">
                <Spacing m-b={16}>
                  <ButtonLink
                    onClick={(e) => handleBackButton(e)}
                    LeftIcon={ArrowLeftIcon}
                    data-testid="tertiary-button"
                  >
                    {t('back_to_products')}
                  </ButtonLink>
                </Spacing>
              </Col>
            }

            { displayInitialPage(isMobile, currentPage) && <InsurancePage data={catalogData[0]?.categoriesProduit[0]} /> }
            { currentPage === 2 && <InsurancePage data={catalogData[0]?.categoriesProduit[1]} /> }
            { currentPage === 3 && <InsurancePage data={catalogData[0]?.categoriesProduit[2]} /> }
            { currentPage === 4 && <InsurancePage data={catalogData[0]?.categoriesProduit[3]} /> }
            { currentPage === 5 && <InsurancePage data={catalogData[0]?.categoriesProduit[4]} /> }
            { currentPage === 6 && <InsurancePage data={catalogData[0]?.categoriesProduit[5]} /> }
            { currentPage === 7 && <InsurancePage data={catalogData[1]?.categoriesProduit[0]} /> }
            { currentPage === 8 && <InsurancePage data={catalogData[1]?.categoriesProduit[1]} /> }
          </StyledCreateIllustrationProductsContainer>
        </>
      }
    </StyledCreateIllustrationContainer>
  );
};

export default CreateIllustration;
