import { AxiosResponse } from 'axios';
import { ValidationMessageLevel } from '~/interfaces/ValidationMessageLevel';
import { ValidationResponseDTO } from '~/interfaces/ValidationResponseDTO';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getStatusFromValidationMessages from '~/fragments/ApplicationFragment/utilities/getStatusFromValidationMessages';
import { WORKFLOW_STATUSES } from 'ia-react-core';
import { useHistory } from 'react-router-dom';
import { State } from '~/interfaces/State';
import { updateRouteStatus } from '~/App.actions';
import { setPageValidationMessagesAction } from '../../../ApplicationFragment.actions';

type ViewModelToFormMap = {
  [key: string]: string;
};

const viewModelToFormMap: ViewModelToFormMap = {
  InfoEmployeeViewModel: 'employeeRequest',
  AdvisorPageRequestViewModel: 'employeeRequest',
  AgentViewModel: 'addAdvisor',
};

export function useSetPageValidationFormMessage<Data>(
  onApiValidationSuccess: () => void,
  page?: string,
  formName?: string,
) {
  const dispatch = useDispatch();
  const history = useHistory();
  const backToHome = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);
  const currentRoute = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.values?.routePath?.currentRoute);

  return useCallback((res: AxiosResponse<Data>) => {
    const data = res.data as ValidationResponseDTO;
    const validationMessages = data?.validationMessages;
    if (validationMessages) {
      if (backToHome) {
        history.block(true);
        history.push('/');
        return;
      }
      const status = getStatusFromValidationMessages(validationMessages);
      if (status) {
        if (status === WORKFLOW_STATUSES.ERROR) {
          dispatch(setPageValidationMessagesAction(page, viewModelToFormMap[validationMessages[0]?.nomModel] ?? formName, validationMessages, 'AdvisorPage'));
          history?.push(currentRoute);
        } else {
          dispatch(updateRouteStatus(currentRoute, status));
          dispatch(setPageValidationMessagesAction(page, viewModelToFormMap[validationMessages[0]?.nomModel] ?? formName, validationMessages, 'AdvisorPage'));
        }
      }
    }

    if (data.saveStatus === true && !validationMessages?.some((v) => v.categorie === ValidationMessageLevel.Error)) {
      onApiValidationSuccess();
    }
  }, [dispatch, page, formName, onApiValidationSuccess, history, currentRoute, backToHome]);
}
