import { ProductNavigationPageMappingInterface } from '../interfaces/ProductNavigationPageMappingInterface';

const productNavigationPageNamesMapping: ProductNavigationPageMappingInterface = {
  Scenario: 'coverage',
  ProfilInvestisseur: 'investorProfile',
  Fonds: 'investments',
  Concepts: 'concept',
  TableauResultats: 'result',
  Rapports: 'report',
};

const getPageName = (page: string) => {
  if (!productNavigationPageNamesMapping[page]) return null;
  return productNavigationPageNamesMapping[page];
};

export default getPageName;
