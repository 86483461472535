import { WORKFLOW_STATUSES, WorkflowRouteDefinition } from 'ia-react-core';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import updateNomineeRouteStatus from './updateNomineeRouteStatus';
import PAGE_NAMES from '../constants/pageNames';
import Route from '../interfaces/Route';
import ROUTENAMES from '../constants/routeNames';

interface RouteStatusParams {
  route: WorkflowRouteDefinition;
  routePath: string;
  apiResponse: ValidationMessage[];
  activeRoute: string;
  lastVisitedRoute: string;
  lastStatuses: any;
  locale: string;
}

interface RouteStatusHandlers {
  isInactiveStatus: (route: WorkflowRouteDefinition) => boolean;
  setNomineeId: (id: string) => void;
  setLastStatuses: (statuses: any) => void;
}

const updateNomineeRouteStatuses = (
  { route, routePath, apiResponse, activeRoute, lastVisitedRoute, lastStatuses, locale }: RouteStatusParams,
  { isInactiveStatus, setNomineeId, setLastStatuses }: RouteStatusHandlers,
) => {
  let status;
  const currentPage = activeRoute.split('/').pop().toLowerCase();
  const isEChangeSignature = activeRoute.includes('signatureechange')
  || activeRoute.includes('signaturechangement');
  if (currentPage === PAGE_NAMES.TRANSMISSION
    || currentPage === PAGE_NAMES.REINSTATEMENT
    || currentPage === PAGE_NAMES.EChangeValidation
    || currentPage === PAGE_NAMES.EChangeValidationEN
  ) {
    status = WORKFLOW_STATUSES.INACTIVE;
    return { ...route, status };
  }

  if ([PAGE_NAMES.SIGNATURE_MODE, PAGE_NAMES.SIGNATURE_FOLLOWUP].includes(currentPage) && isEChangeSignature) {
    const routeLabelLower = (route.label as Route['label'])?.en?.toLowerCase();
    if (routeLabelLower !== PAGE_NAMES.SIGNATURE_MODE.toLowerCase() && routeLabelLower !== PAGE_NAMES.SIGNATURE_FOLLOWUP.toLowerCase() &&
    routeLabelLower !== ROUTENAMES.SIGNATUREMODE && routeLabelLower !== ROUTENAMES.SIGNATUREFOLLOWUP) {
      status = WORKFLOW_STATUSES.INACTIVE;
      return { ...route, status };
    }
  }

  if (isInactiveStatus(route)) {
    status = WORKFLOW_STATUSES.INACTIVE;
  } else {
    status = updateNomineeRouteStatus(route, activeRoute, lastVisitedRoute, routePath, lastStatuses, apiResponse, setNomineeId, setLastStatuses, locale);
  }
  return { ...route, status };
};

export default updateNomineeRouteStatuses;
