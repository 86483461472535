import { ApplicationValidationWarning, SwitchToApplicationCondition, SwitchToApplicationConditionsMap } from '~/interfaces/CoveragesPageState';
import { EntiteNommee, InfosPAR, Scenario, UpdateAddOnFaceAmountPayload, Concept } from '~/interfaces/Scenario';
import { PeriodItemValuesType } from './interfaces/FieldValueForLegacyModal';

const actionCreator = <T>(type: string) => {
  const newType = `CoveragesPage.${type}`;
  const action = (payload: T) => ({
    type: newType,
    payload,
  });
  action.type = newType;
  return action;
};

const updateValidations = actionCreator<{ validations: SwitchToApplicationConditionsMap }>('UpdateValidations');

const updateValidation = actionCreator<{ validation: SwitchToApplicationCondition }>('UpdateValidation');

const updateSwitchToApplicationButtonEnabled = actionCreator<{ enabled: boolean }>('UpdateSwitchToApplicationButtonEnabled');
const updateSwitchToApplicationButtonVisible = actionCreator<{ visible: boolean }>('UpdateSwitchToApplicationButtonVisible');
const updateSwitchToApplicationExpanded = actionCreator<{ expanded: boolean }>('UpdateSwitchToApplicationExpanded');
const setAddtionalCoverageFormValues = actionCreator<{ formValues: Object }>('setAddtionalCoverageFormValues');

const updateDisplayApplicationTransferDeadlineValidation = actionCreator<{ type: ApplicationValidationWarning; shouldDisplay: boolean }>('UpdateDisplayApplicationTransferDeadlineValidation');

const updateScenario = actionCreator<{ scenario: Scenario }>('UpdateScenario');
const updateInsuredPersons = actionCreator<{ formValues: EntiteNommee }>('UpdateInsuredPersons');
const updateInsuredPersonAge = actionCreator<{ formValues: {id: string; age: number} }>('UpdateInsuredPersonAge');
const clearInsuredPersonDateOfBirth = actionCreator<{ formValues: {id: string} }>('clearInsuredPersonDateOfBirth');
const updateOnePropertyOfInsuredPerson = actionCreator<{ formValues: {id: string; name: string; value: any} }>('updateOnePropertyOfInsuredPerson');
const updateProvinceSelection = actionCreator<{ formValues: {province: string} }>('updateProvinceSelection');
const updateMarginalRate = actionCreator<{ formValues: {value: number} }>('updateMarginalRate');
const updateInterestRate = actionCreator<{ formValues: {value: number} }>('updateInterestRate');
const updateDividendRate = actionCreator<{ formValues: {value: number} }>('updateDividendRate');
const updateDeathBenefitOption = actionCreator<{ formValues: {value: string} }>('updateDeathBenefitOption');
const updateCostTypeOption = actionCreator<{ formValues: {value: string} }>('updateCostTypeOption');
const updateVMaxActiveOptimisation = actionCreator<{ formValues: {value: boolean} }>('updateVMaxActiveOptimisation');
const updateVMaxDureeMinimisation = actionCreator<{ formValues: {value: number} }>('updateVMaxDureeMinimisation');
const updateCapitalValMax = actionCreator<{ value: number; coverageId: string }>('updateCapitalValMax');
const coverageDrawerUpdateBottom = actionCreator<{ isBottom: boolean }>('coverageRiderUpdateBottom');
const clearDropDown = actionCreator<{value: string; coverageId: string}>('clearDropDown');
const updateParentSelectedInsured = actionCreator<{insuredId: string}>('updateParentSelectedInsured');
const updateReduceFaceAmountFormValues = actionCreator<UpdateAddOnFaceAmountPayload & { coverageId: string }>('updateReduceFaceAmountFormValues');

const updateAdditionalInsuredPrimeSans = actionCreator<{ formValues: { id: string }; montantSansFrais: string | number; capitalAssure?: string | number; montant?: string | number }>('updateAdditionalInsuredPrimeSans');

const removeInsuredPerson = actionCreator<{ entiteNomeeId: string }>('removeInsuredPerson');

const updatePuaAllocation = actionCreator<{toggle?: boolean; years?: number; amount?: number; coverageId?: string; maxAmount?: number; minAmount?: number}>('updatePuaAllocation');

const updateAdditionalDepositOption = actionCreator<{coverageId: string; formValues: InfosPAR}>('updateAdditionalDepositOption');
const updateContributionMinOdsAmount = actionCreator<{coverageId: string; minOdsAmount: number}>('updateContributionMinOdsAmount');
const updateContributionOds = actionCreator<{coverageId: string; contributionOds: number}>('updateContributionOds');

const updatePremiumOffsetOption = actionCreator<{coverageId: string; formValues: Concept}>('updatePremiumOffsetOption');

const updateChangeDividentOption = actionCreator<{coverageId: string; formValues: Concept}>('updateChangeDividentOption');

const updateAmountFieldInGenesisModal = actionCreator<{ value: number | string }>('updateAmountFieldInGenesisModalAction');
const updateForYearFieldInGenesisModal = actionCreator<{ value: number | string }>('updateForYearFieldInGenesisModal');
const updateToAgeYearFieldInGenesisModal = actionCreator<{ value: number | string }>('updateToAgeYearFieldInGenesisModal');
const updateDesiredAmountFieldInGenesisModal = actionCreator<{ value: number | string }>('updateDesiredAmountFieldInGenesisModal');
const updateTargetYearFieldInGenesisModal = actionCreator<{ value: number }>('updateTargetYearFieldInGenesisModal');
const updateTargetAgeYearFieldInGenesisModal = actionCreator<{ value: number }>('updateTargetAgeYearFieldInGenesisModal');
const updateAmountFieldForPersonalizedInGenesisModal = actionCreator<{ value: number; fieldName: string }>('updateAmountFieldForPersonalizedInGenesisModal');
const updateAmountToAssignInGenesisModal = actionCreator<{ value: number }>('updateAmountToAssignInGenesisModal');
const updateRating = actionCreator<{isRating: boolean}>('updateRating');

const updatePcToolModalApplyBtnDisabled = actionCreator<{isDisabled: boolean}>('updatePcToolModalApplyBtnDisabled');

const updatePeriodFieldsInLegacyModal = actionCreator<PeriodItemValuesType>('updatePeriodFieldsInLegacyModal');

const updateManageCommissionCutToggle = actionCreator<{hasCorpoureCommission: boolean; coverageId: string}>('updateManageCommissionCutToggle');

export const CoveragesPageActions = {
  updateValidations,
  updateValidation,
  updateSwitchToApplicationButtonEnabled,
  updateSwitchToApplicationButtonVisible,
  updateSwitchToApplicationExpanded,
  updateDisplayApplicationTransferDeadlineValidation,
  updateScenario,
  updateInsuredPersons,
  updateInsuredPersonAge,
  clearInsuredPersonDateOfBirth,
  updateOnePropertyOfInsuredPerson,
  updateProvinceSelection,
  updateMarginalRate,
  updateInterestRate,
  setAddtionalCoverageFormValues,
  updateDividendRate,
  updateDeathBenefitOption,
  updateCostTypeOption,
  updateVMaxActiveOptimisation,
  updateVMaxDureeMinimisation,
  updateCapitalValMax,
  coverageDrawerUpdateBottom,
  updateReduceFaceAmountFormValues,
  clearDropDown,
  updateAdditionalInsuredPrimeSans,
  removeInsuredPerson,
  updateParentSelectedInsured,
  updatePuaAllocation,
  updateAdditionalDepositOption,
  updateContributionMinOdsAmount,
  updateContributionOds,
  updatePremiumOffsetOption,
  updateChangeDividentOption,
  updateAmountFieldInGenesisModal,
  updateForYearFieldInGenesisModal,
  updateToAgeYearFieldInGenesisModal,
  updateDesiredAmountFieldInGenesisModal,
  updateTargetYearFieldInGenesisModal,
  updateTargetAgeYearFieldInGenesisModal,
  updateAmountFieldForPersonalizedInGenesisModal,
  updateAmountToAssignInGenesisModal,
  updateRating,
  updatePcToolModalApplyBtnDisabled,
  updatePeriodFieldsInLegacyModal,
  updateManageCommissionCutToggle,
};
