import { AxiosResponse } from 'axios';
import {
  Col,
  Drawer,
  Form,
  HelpIcon,
  HiddenControl,
  Menu,
  MenuItem,
  Resource,
  Row,
  SecondaryTextIconButton,
  SettingsIcon,
  Spacing,
  SpacingProps,
  openDrawerByName,
  openModalByName,
  useScopedSelector,
} from 'ia-react-core';
import React, { FC, MouseEvent, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ThemeContext, ThemeProvider } from 'styled-components';
import { ReactComponent as DrawerMegaPhoneIcon } from '~/assets/Icons/drawerMegaPhone.svg';
import IllustrationSettingsModal from '~/components/IllustrationSettingsModal';
import NotificationDrawer from '~/components/NotificationModal';
import { GetNotificationResource } from '~/components/Resources/GetNotificationResource';
import { APPLICATION_PATH } from '~/constants/ROUTES';
import { getApiRootUrl } from '~/fragments/HomeFragment/api/Config';
import { Stage } from '~/fragments/HomeFragment/components/SaleFile/constants';
import { SaleFile } from '~/fragments/HomeFragment/components/SaleFile/interfaces/SaleFile';
import { CoveragesPageState } from '~/interfaces/CoveragesPageState';
import { State } from '~/interfaces/State';
import { AppTheme } from '~/interfaces/Theme';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import OipaEnvironmentsModal from '~/components/OipaEnvironmentsModal';
import { setAppVersioning } from '~/App.actions';
import { openSaveFileModal } from '~/fragments/ApplicationFragment/pages/SummaryPage/SummaryPage.actions';
import PrintReportModal from '~/components/PrintReportModal';
import { HOME_REGEX, MENU_REGEX } from '~/constants';
import { ReactComponent as HomeIconBlue } from '~/assets/Icons/homeIcon.svg';
import { ReactComponent as SaveDiskIconBlue } from '~/assets/Icons/saveDiskBlue.svg';
import { ReactComponent as CloudIconBlue } from '~/assets/Icons/cloudDoneBlue.svg';
import MainLayout from '../MainLayout/MainLayout';
import { AppLayoutHeaderRightContent } from './components/AppLayoutHeaderRightContent';
import { StyledPrintIcon, StyledSecondaryTextIconButton } from './AppLayout.styles';

export interface AppLayoutProps {
  children?: ReactNode;
  isApp?: boolean;
  saleFileName?: string;
  isDraft?: boolean;
  isChangePage?: boolean;
  spacingProps?: SpacingProps;
}

const AppLayout: FC<AppLayoutProps> = ({ children, isApp, spacingProps, isChangePage = false }) => {
  const { t } = useTranslation('App');
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const lang = useScopedSelector((state: State) => state?.language?.locale ?? 'fr');
  const { saleId } = useParams<{ saleId: string }>();
  const fileName = useScopedSelector((state: CoveragesPageState) => state?.data?.saleMetadata?.nomVente);

  const history = useHistory();
  const saleMetadataUrl = getBffUrlForRoute(BffRoute.user_sales_saleId_metadata, { saleId }, false);
  const getOnCancelSaveSaleFileUrl = getBffUrlForRoute(BffRoute.user_sales_saleId, { saleId }, false);
  const [isDraft, setIsDraft] = useState(false);
  const GET_SALEMETADATA_RESOURCE_NAME = 'saleMetadata';
  const ON_CANCEL_SAVE_RESOURCE_NAME = 'onCancelSave';
  const hidePrintIcon = useMemo(() => MENU_REGEX.test(history?.location?.pathname) || history?.location?.pathname === '/', [history?.location?.pathname]);
  const hideHomeIcon = useMemo(() => (HOME_REGEX.test(history?.location?.pathname) || history?.location?.pathname === '/'), [history?.location?.pathname]);
  const [homeClicked, setHomeClicked] = useState(false);

  const saveFile = () => {
    dispatch(openSaveFileModal());
    dispatch(openModalByName('modals.saveSaleFile', { fromNav: true }));
  };

  const printFile = () => {
    dispatch(openModalByName('modals.printReport'));
  };

  const [isNewMessage, setIsNewMessage] = useState(false);

  const saleMetadataResource = (
    <Resource
      name={GET_SALEMETADATA_RESOURCE_NAME}
      method="GET"
      url={saleMetadataUrl}
      onSuccess={(sale: AxiosResponse<SaleFile>) => {
        setIsDraft(sale.data.progression === Stage.draft);
      }}
      autoRequestIfEmpty={isApp}
    />
  );

  const onCancelSaveResource = (
    <Resource
      name={ON_CANCEL_SAVE_RESOURCE_NAME}
      url={getOnCancelSaveSaleFileUrl}
      method="DELETE"
      onSuccess={() => {
        history.push('/');
      }}
    />
  );

  const navigateToHome = () => {
    if (isChangePage) {
      history.push('/');
    } else if (isDraft) {
      dispatch(openModalByName('modals.saveSaleFile', { isLeaving: true }));
    } else {
      setHomeClicked(true);
      dispatch(setAppVersioning('', ''));
      setTimeout(() => { history.push('/'); }, 200);
    }
  };

  const pageTheme: AppTheme = {
    ...theme,
    layout: {
      ...theme?.layout,
      maxContentWidth: isChangePage ? 1536 : 1296,
      maxHeaderContentWidth: isChangePage ? 1536 : 1360,
    },
  };

  useEffect(() => (
    MainLayout.setMainLayoutPropsTunnel({
      noFooterLanguageButton: path === APPLICATION_PATH || isChangePage,
      contentVerticalPadding: 48,
      flexFooterLinks: true,
      onMainLogoClick: (e: MouseEvent<HTMLAnchorElement>) => {
        e?.preventDefault();
        navigateToHome();
      },
    })
  ), [isChangePage, navigateToHome, path]);

  return (
    <>
      {saleMetadataResource}
      {onCancelSaveResource}
      <Form name="homeStatus">
        <HiddenControl name="homeClicked" value={homeClicked} />
      </Form>
      <ThemeProvider theme={pageTheme}>
        <Spacing m-v-xs={24} m-v-sm={48} m-v-lg={60} p-h-xs={0} p-h-sm={20} p-h-md={20} {...spacingProps}>
          <MainLayout.MainHeaderRightContentTunnelEntry>
            <AppLayoutHeaderRightContent
              isApp={isApp}
              isDraft={isDraft}
              isNewMessage={isNewMessage}
              isChangePage={isChangePage}
              saleFileName={fileName}
              onSaveClick={saveFile}
              onHomeClick={navigateToHome}
              onPrintClick={printFile}
              hidePrintIcon={hidePrintIcon}
              onNotificationsClick={() => {
                dispatch(openDrawerByName('drawers.notifications'));
                setIsNewMessage(false);
              }}
              onAdvisorsClick={() => {
                dispatch(openModalByName('modals.advisors'));
              }}
              onIllustrationClick={() => {
                dispatch(openModalByName('modals.illustrationSettings'));
              }}
              onMobileMenuClick={() => { dispatch(openDrawerByName('drawers.myFiles')); }}
              onOipaOptionClick={() => dispatch(openModalByName('modals.oipaEnvironments'))}
            />
          </MainLayout.MainHeaderRightContentTunnelEntry>
          <Drawer
            drawerTitle="My files"
            name="myFiles"
            headerPadding="40px 16px"
            headerBottomBorder
          >
            <div>
              {isDraft &&
              <Row p-t={24} data-test-id="saveDiskIconBlue">
                <Col onClick={saveFile}>
                  <StyledSecondaryTextIconButton LeftIcon={SaveDiskIconBlue}>
                    {t('save')}
                  </StyledSecondaryTextIconButton>
                </Col>
              </Row>}

              {
                !isDraft && isApp &&
                  <Row p-t={24}>
                    <Col>
                      <StyledSecondaryTextIconButton LeftIcon={CloudIconBlue} data-test-id="cloudIconBlue">
                        {fileName}
                      </StyledSecondaryTextIconButton>
                    </Col>
                  </Row>
              }

              { !hidePrintIcon &&
                <Row p-t={24}>
                  <Col
                    onClick={printFile}
                  >
                    <StyledSecondaryTextIconButton LeftIcon={StyledPrintIcon}>
                      {t('print')}
                    </StyledSecondaryTextIconButton>
                  </Col>
                </Row>}

              {!hideHomeIcon &&
                <Row p-t={16}>
                  <Col
                    onClick={navigateToHome}
                  >
                    <StyledSecondaryTextIconButton LeftIcon={HomeIconBlue}>
                      {t('home')}
                    </StyledSecondaryTextIconButton>
                  </Col>
                </Row>}
              {!isChangePage && !isApp &&
              <Row p-t={hidePrintIcon ? 24 : 16} data-test-id="drawerMegaPhoneIconRow">
                <Col
                  onClick={() => {
                    dispatch(openDrawerByName('drawers.notifications'));
                  }}
                >
                  <SecondaryTextIconButton LeftIcon={DrawerMegaPhoneIcon}>
                    {t('Notifications')}
                  </SecondaryTextIconButton>
                </Col>
              </Row>}
              <Row p-t={16}>
                <Col>
                  <Menu
                    items={[
                      <MenuItem
                        url="#"
                        onClick={() => {
                          dispatch(openModalByName('modals.advisors'));
                        }}
                      >{t('advisors')}
                      </MenuItem>,
                      <MenuItem
                        url="#"
                        onClick={() => {
                          dispatch(openModalByName('modals.illustrationSettings'));
                        }}
                      >
                        {t('illustrations')}
                      </MenuItem>,
                      <MenuItem url="#">{t('signature')}</MenuItem>,
                    ]}
                  >
                    <SecondaryTextIconButton LeftIcon={SettingsIcon}>
                      {t('settings')}
                    </SecondaryTextIconButton>
                  </Menu>
                </Col>
              </Row>
              <Row p-t={16}>
                <Col>
                  <SecondaryTextIconButton LeftIcon={HelpIcon}>
                    {t('help')}
                  </SecondaryTextIconButton>
                </Col>
              </Row>
            </div>
          </Drawer>

          <Resource
            name="resources.provincesResource"
            url={`${getApiRootUrl()}/provinces?lang=${lang === 'en' ? 1 : lang}`}
          />
          <Resource
            name="resources.paymentFrequencyResource"
            url={`${getApiRootUrl()}/payment-frequency?lang=${lang === 'en' ? 1 : lang}`}
          />
          <Resource
            name="resources.illustrationConfigurationResource"
            url={`${getApiRootUrl()}/illustration-configuration?adviserId=${lang === 'fr' ? 1 : lang}`}
          />
          <Resource
            name="resources.updateIllustrationConfigurationResource"
            url={`${getApiRootUrl()}/update-illustration-configuration?adviserId=${lang === 'fr' ? 1 : lang}`}
            method="POST"
          />
          <GetNotificationResource />
          <NotificationDrawer />
          <IllustrationSettingsModal />
          <OipaEnvironmentsModal />
          <PrintReportModal />
          {children}
        </Spacing>
      </ThemeProvider>
    </>
  );
};

export default AppLayout;
