import styled from 'styled-components';

export const StyledDisclaimerContainer = styled.div`
  margin-bottom: 24px;
`;

export const StyledListContainer = styled.ul`
  columns: 2;
  column-fill: balance;
`;
