import { FC } from 'react';
import { compose } from 'redux';
import { injectTranslations, injectReducer } from 'ia-react-core';

import { IllustrationFragmentState } from '~/interfaces/IllustrationFragmentState';
import illustrationFragmentReducer from './IllustrationFragment.reducer';
import IllustrationFragment, { IllustrationFragmentProps } from './IllustrationFragment';
import { IllustrationFragmentActions } from './IllustrationFragment.actions';

const initialState = {};

export default compose<FC<IllustrationFragmentProps>>(
  injectTranslations('IllustrationFragment', []),
  injectReducer<IllustrationFragmentProps, IllustrationFragmentState, IllustrationFragmentActions>({
    key: 'fragments.IllustrationFragment',
    reducer: illustrationFragmentReducer,
    initialState,
    useScope: true,
    cleanState: true,
  }),
)(IllustrationFragment);
