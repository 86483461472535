import axios from 'axios';
import { BillingPageState } from '~/interfaces/Billing/BillingPageState';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';

interface UpdateBillingOptions {
  saleId: string;
  body: BillingPageState;
}

export default async function updateBilling({ saleId, body }: UpdateBillingOptions) {
  const result = await axios.put(getBffUrlForRoute(BffRoute.Update_billing, { saleId }), body);

  if (result.status !== 200) {
    throw new Error(`Error while updating billing details: ${result.status} - ${result.statusText}`);
  }
  return result;
}
