import { ValidationMessage } from '~/interfaces/ValidationMessage';
import IndividualModel from '../../interfaces/IndividualModel';

export enum ActionType {
  UPDATE_VALIDATION_STATUS = 'UPDATE_VALIDATION_STATUS',
  DO_DOI_VALIDATION = 'DO_DOI_VALIDATION',
}

export const updateValidationStatusAction = (isNameValid: boolean, isDemandValid: boolean, messages: ValidationMessage[] = []) => ({
  type: ActionType.UPDATE_VALIDATION_STATUS,
  isNameValid,
  isDemandValid,
  messages,
});
export type UpdateValidationStatusAction = ReturnType<typeof updateValidationStatusAction>;

export const doDOIValidationAction = (insuredNominee: IndividualModel, locale: string, hasAssignedAdvisor: boolean) => ({
  type: ActionType.DO_DOI_VALIDATION,
  insuredNominee,
  locale,
  hasAssignedAdvisor,
});
export type DoDOIValidationAction = ReturnType<typeof doDOIValidationAction>;
