import { Action } from 'redux';
import ChangeRequestModel from '~/interfaces/InsurabilityDeclaration/ChangeRequestModel';
import { ChangeRequestTypes } from '~/interfaces/InsurabilityDeclaration/enums';
import SectionIdentityModel from '~/interfaces/InsurabilityDeclaration/SectionIdentityModel';
import ItemsModel from '~/interfaces/InsurabilityDeclaration/ItemsModel';
import SetAnswerResponseModel from '~/interfaces/InsurabilityDeclaration/SetAnswerResponseModel';
import QuestionModel from '~/interfaces/InsurabilityDeclaration/QuestionModel';
import { SignaturePaperState } from '~/interfaces/SignaturePaperState';
import { AddDeleteAdditionalSigner, AddDeleteMainSigner, ChangeRequestFormState } from './interfaces/State';
import { ChangeRequestSummaryModel } from './interfaces/ChangeRequestFormTypeModel';
import {
  AnswerQuestionsSuccessType,
  CreateRepeatingSectionSuccessType,
  DeleteChangeRequestFormByIdType, DeleteInstanceSectionSuccessType, LoadChangeRequestFormByIdType, LoadChangeRequestFormsSuccessType, LoadApplicationIndividuals,
  LoadChangeRequestFormsType, LoadChangeRequestSuccessType, LoadChangeRequestSummarySuccessType,
  LoadChangeRequestType, SaveChangeRequestFormType, SetCurrentTab, UpdateCaseDataType,
  SetFormId, SetUseTobacco, UpdateSignatureConfigureType, UpdateSignaturePaperFormType, UpdateSignatureElectronicFormType, StartCeremonyFormType, UpdateAdditionalFormSignerFullNameType, AddMainSignerRoleType, DeleteMainSignerRoleType, AddAdditionalSignerRoleType, DeleteAdditionalSignerRoleType,
  UpdateRenderCheckBoxStatus,
  FormSelectionErrorType
  ,
} from './ChangeRequest.actionTypes';
import { SignatureConfigure } from './interfaces/SignatureState';
import { GetSignatureElectronicDetailsState } from './interfaces/GetSignatureElectronicDetailsState';
import IndividualModel from '../ApplicationFragment/interfaces/IndividualModel';

export class LoadChangeRequest implements Action {
  public readonly type: string = LoadChangeRequestType;
  constructor(public apiBaseUrl: string, public caseId: string, public bearerToken: string, public validate?: boolean) { }
}

export const loadChangeRequestSuccessAction = (changeRequest: ChangeRequestModel) => ({
  type: LoadChangeRequestSuccessType,
  changeRequest,
});
export type LoadChangeRequestSuccessAction = ReturnType<typeof loadChangeRequestSuccessAction>;

export class LoadChangeRequestForms implements Action {
  public readonly type: string = LoadChangeRequestFormsType;
  constructor(public apiBaseUrl: string, public caseId: string, public formType: ChangeRequestTypes, public bearerToken: string, public validate?: boolean) { }
}

export const loadChangeRequestFormsSuccessAction = (formTypes: ChangeRequestFormState) => ({
  type: LoadChangeRequestFormsSuccessType,
  formTypes,
});
export type LoadChangeRequestFormsSuccessAction = ReturnType<typeof loadChangeRequestFormsSuccessAction>;

export const LoadChangeRequestSummarySuccess = (summary: ChangeRequestSummaryModel[]) => ({
  type: LoadChangeRequestSummarySuccessType,
  summary,
});

export class LoadChangeRequestFormById implements Action {
  public readonly type: string = LoadChangeRequestFormByIdType;
  constructor(public apiBaseUrl: string, public caseId: string, public formId: string, public bearerToken: string, public validate?: boolean) { }
}

export class DeleteChangeRequestFormById implements Action {
  public readonly type: string = DeleteChangeRequestFormByIdType;
  constructor(public apiBaseUrl: string, public caseId: string, public formId: string, public bearerToken: string, public validate?: boolean) { }
}

export class UpdateCaseData implements Action {
  public readonly type: string = UpdateCaseDataType;
  constructor(public changeRequest: ChangeRequestModel) { }
}

export class SaveChangeRequestForm implements Action {
  public readonly type: string = SaveChangeRequestFormType;
  constructor(public apiBaseUrl: string, public caseId: string, public formId: string, public bearerToken: string, public validate?: boolean) { }
}

export const deleteInstanceSectionSuccessAction = (repeatingSectionId: string, instanceId: string) => ({
  type: DeleteInstanceSectionSuccessType,
  repeatingSectionId,
  instanceId,
});
export type DeleteInstanceSectionSuccessAction = ReturnType<typeof deleteInstanceSectionSuccessAction>;

export const createRepeatingSectionSuccessAction = (repeatingSectionId: string, newInstance: SectionIdentityModel, items: ItemsModel) => ({
  type: CreateRepeatingSectionSuccessType,
  repeatingSectionId,
  newInstance,
  items,
});
export type CreateRepeatingSectionSuccessAction = ReturnType<typeof createRepeatingSectionSuccessAction>;

export const answerQuestionsSuccessAction = (question: QuestionModel, values: unknown[], result: SetAnswerResponseModel[]) => ({
  type: AnswerQuestionsSuccessType,
  question,
  values,
  result,
});
export type AnswerQuestionsSuccessAction = ReturnType<typeof answerQuestionsSuccessAction>;

export const setCurrentTab = (currentTabIndex: number) => ({
  type: SetCurrentTab,
  currentTabIndex,
});
export type SetCurrentTab = ReturnType<typeof setCurrentTab>;

export const setFormId = (selectedFormId: string) => ({
  type: SetFormId,
  selectedFormId,
});
export type SetFormId = ReturnType<typeof setFormId>;

export const setUseTobacco = (useTobacco: boolean) => ({
  type: SetUseTobacco,
  useTobacco,
});

export type SetUseTobacco = ReturnType<typeof setUseTobacco>;

export const updateSignatureConfigureAction = (payload: SignatureConfigure) => ({
  type: UpdateSignatureConfigureType,
  payload,
});
export type UpdateSignatureConfigureAction = ReturnType<typeof updateSignatureConfigureAction>;

export const updateSignaturePaperFormAction = (payload: SignaturePaperState) => ({
  type: UpdateSignaturePaperFormType,
  payload,
});
export type UpdateSignaturePaperFormAction = ReturnType<typeof updateSignaturePaperFormAction>;

export const updateSignatureElectronicFormAction = (payload: GetSignatureElectronicDetailsState) => ({
  type: UpdateSignatureElectronicFormType,
  payload,
});
export type UpdateSignatureElectronicFormAction = ReturnType<typeof updateSignatureElectronicFormAction>;

export const UpdateAdditionalFormSignerFullNameAction = (payload: number) => ({
  type: UpdateAdditionalFormSignerFullNameType,
  payload,
});
export type UpdateAdditionalFormSignerFullNameAction = ReturnType<typeof UpdateAdditionalFormSignerFullNameAction>;

export const addMainSignerRoleAction = (payload: AddDeleteMainSigner) => ({
  type: AddMainSignerRoleType,
  payload,
});
export type AddMainSignerRoleAction = ReturnType<typeof addMainSignerRoleAction>;

export const deleteMainSignerRoleAction = (payload: AddDeleteMainSigner) => ({
  type: DeleteMainSignerRoleType,
  payload,
});
export type DeleteMainSignerRoleAction = ReturnType<typeof deleteMainSignerRoleAction>;

export const addAdditionalSignerRoleAction = (payload: AddDeleteAdditionalSigner) => ({
  type: AddAdditionalSignerRoleType,
  payload,
});
export type AddAdditionalSignerRoleAction = ReturnType<typeof addAdditionalSignerRoleAction>;

export const deleteAdditionalSignerRoleAction = (payload: AddDeleteAdditionalSigner) => ({
  type: DeleteAdditionalSignerRoleType,
  payload,
});
export type DeleteAdditionalSignerRoleAction = ReturnType<typeof deleteAdditionalSignerRoleAction>;

export const startCeremonyFormAction = (payload: GetSignatureElectronicDetailsState) => ({
  type: StartCeremonyFormType,
  payload,
});
export type startCeremonyFormAction = ReturnType<typeof startCeremonyFormAction>;

export const LoadApplicationIndividual = (individuals: IndividualModel[]) => ({
  type: LoadApplicationIndividuals,
  individuals,
});

export type LoadApplicationIndividualsAction = ReturnType<typeof LoadApplicationIndividual>;

export const UpdateRenderCheckBox = (checkboxDetails: {formName: string; id: string; checked: boolean}) => ({
  type: UpdateRenderCheckBoxStatus,
  checkboxDetails,
});

export type UpdateRenderCheckBox = ReturnType<typeof UpdateRenderCheckBox>;

export const formSelectionErrorAction = (payload: string[]) => ({
  type: FormSelectionErrorType,
  payload,
});
export type FormSelectionError = ReturnType<typeof formSelectionErrorAction>;
