import { Address } from 'ia-react-core';
import AddressModel from '../../interfaces/AddressModel';

enum DeliverType {
  PotalBox = 'postalbox',
  Rural = 'rural',
  None = 'none',
}

const countryData = {
  Canada: {
    countryName: 'Canada',
    countryIso2: 'CA',
    countryIso3: 'CAN',
    countryIsoNumber: '124',
  },
  USA: {
    countryName: 'United States',
    countryIso2: 'US',
    countryIso3: 'USA',
    countryIsoNumber: '840',
  },
};

const getDeliveryType = (address: AddressModel) => {
  if (address.poBox) {
    return DeliverType.PotalBox;
  }

  if (address.ruralRoad) {
    return DeliverType.Rural;
  }

  return DeliverType.None;
};

const getCountryData = (country: string) => {
  if (country === countryData.Canada.countryName) {
    return countryData.Canada;
  }

  if (country === countryData.USA.countryName) {
    return countryData.USA;
  }

  return null;
};

// Convert AddressModel to Address object used by the address component
export const fromAddressDto = (address: AddressModel): Address => ({
  id: address?.id,
  street: address.street,
  province: address.province,
  buildingNumber: address.civicNumber,
  city: address.city,
  postalCode: address.postalCode,
  subBuilding: address.extApt,
  subBuildingType: address.extApt ? address.subBuildingType : '',
  ruralRoute: address.ruralRoad,
  poBoxNumber: address.poBox,
  place: address.branch,
  deliveryType: getDeliveryType(address),
  ...getCountryData(address.country),
} as Address);
