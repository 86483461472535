import { Action } from 'redux';
import { LoadChangeRequestSuccessAction } from '../ChangeRequest.actions';
import { ChangeRequestState } from '../interfaces/State';

export default function loadChangeRequestSuccessReducer(state: ChangeRequestState, action: Action): ChangeRequestState {
  const { changeRequest } = action as LoadChangeRequestSuccessAction;
  return {
    ...state,
    changeRequest,
    summary: null,
  };
}
