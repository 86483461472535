import { Action } from 'redux';
import { all, takeEvery } from 'redux-saga/effects';
import { ADD_SETTINGS_ADVISOR_TYPE, DELETE_SETTINGS_ADVISOR_TYPE, EDIT_SETTINGS_ADVISOR_TYPE } from '../AdvisorsModal.actions';
import { createAdvisorSaga } from './createAdvisorSaga';
import { updateAdvisorSaga } from './updateAdvisorSaga';
import { deleteAdvisorSaga } from './deleteAdvisorSaga';

const mapping: Record<string, (action: Action) => unknown> = {
  [ADD_SETTINGS_ADVISOR_TYPE]: createAdvisorSaga,
  [EDIT_SETTINGS_ADVISOR_TYPE]: updateAdvisorSaga,
  [DELETE_SETTINGS_ADVISOR_TYPE]: deleteAdvisorSaga,
};

export default function* globalSaga() {
  yield all(Object.entries(mapping).map(([key, saga]) => takeEvery(key, saga)));
}
