import { BlockProps } from 'ia-react-core';
import React, { FC } from 'react';
import { StyledBorderBlock } from './BorderBlock.styles';

export interface BorderBlockProps extends BlockProps {}

const BorderBlock: FC<BorderBlockProps> = (props) => (
  <StyledBorderBlock {...props} />
);

export default BorderBlock;
