import ExplanatoryTableRowModel from '~/interfaces/InsurabilityDeclaration/ExplanatoryTableRowModel';
import ExplanatoryTableRowDto from '../dtos/ExplanatoryTableRowDto';
import ExplanatoryTableCellBuilder from './ExplanatoryTableCellBuilder';

export default class ExplanatoryTableRowBuilder {
  toModel(dto: ExplanatoryTableRowDto): ExplanatoryTableRowModel {
    const cellBuilder = new ExplanatoryTableCellBuilder();
    return new ExplanatoryTableRowModel(
      dto.id,
      dto.cells.map((c) => cellBuilder.toModel(c)),
    );
  }
}
