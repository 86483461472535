export default function getClientIdFromRoute(currentRoute: string): string {
  const parts = currentRoute.split('/');
  const nomineeIndex = parts.indexOf('nominee');
  const candidatIndex = parts.indexOf('candidat');
  if (nomineeIndex !== -1) {
    return parts[nomineeIndex + 1];
  }
  if (candidatIndex !== -1) {
    return parts[candidatIndex + 1];
  }
  return '';
}
