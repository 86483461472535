import { requestResourceByName } from 'ia-react-core';
import buildUrl from '~/utilities/buildUrl';
import { VALIDATE_DOI_RESOURCE } from '../constants';
import { validateCaseAction } from '../pages/NomineePage/pages/InsurabilityDeclaration/InsurabilityDeclaration.actions';

const DoiApiCall = (declarationCaseId: string, dispatch: Function) => {
  if (declarationCaseId) {
    dispatch(requestResourceByName(`resources.${VALIDATE_DOI_RESOURCE}`, {}, {
      url: buildUrl([process.env.REACT_APP_UNDERWRITING_API_BASE_PATH, `cases/${encodeURIComponent(declarationCaseId)}`]),
    }));
    dispatch(validateCaseAction({ caseId: declarationCaseId }));
  }
};

export default DoiApiCall;
