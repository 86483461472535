import React from 'react';
import {
  Form, P, Spacing, T4, Container, Row, Col,
  Visible,
} from 'ia-react-core';
import { useTranslation } from 'react-i18next';

import { PCT_FORM_NAME } from '../../constants';
import CoveragesContent from '../CoveragesContent';
import InsuredPersonsContent from '../InsuredPersonsContent';
import usePremiumComparisonToolManager from '../../hooks/usePremiumComparisonToolManager';
import { StyledPcToolFormCoveragesContentContainer } from './PcToolForm.styles';

const PcToolForm = () => {
  const { t } = useTranslation('PremiumComparisonToolModal');
  const { isPcToolFormDisabled } = usePremiumComparisonToolManager();

  return (
    <Form
      name={PCT_FORM_NAME}
      disabled={isPcToolFormDisabled}
      cleanValues
    >
      <Container p={0}>
        <P strong>{t('policyFees')}</P>
        <Spacing m-b={16} />
        <Row>
          <Col md={3}>
            <T4 marginBottom={0}>{t('insuredPersons')}</T4>
            <Spacing m-b={16} />
            <InsuredPersonsContent />
          </Col>
          <Col md={9}>
            <Visible xs sm>
              <Spacing m-b={16} />
            </Visible>

            <T4 marginBottom={0}>{t('coverages')}</T4>
            <Spacing m-b={16} />
            <StyledPcToolFormCoveragesContentContainer>
              <CoveragesContent />
            </StyledPcToolFormCoveragesContentContainer>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
export default PcToolForm;
