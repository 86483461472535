import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import ChangeRequestBuilder from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/builders/ChangeRequestBuilder';
import ChangeRequestDto from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/dtos/ChangeRequestDto';
import { useDispatch } from 'react-redux';
import { loadChangeRequestSuccessAction } from '../ChangeRequest.actions';

export interface GetChangeRequestProps {
  saleId: string;
}
const GetChangeRequest: FC<GetChangeRequestProps> = ({ saleId }) => {
  const dispatch = useDispatch();

  return (
    <Resource
      name="resources.change_request"
      url={getBffUrlForRouteWithoutQP(BffRoute.change_request, { saleId }, false)}
      onSuccess={(result) => {
        const builder = new ChangeRequestBuilder();
        const dto = result.data as ChangeRequestDto;
        const model = builder.toModel(dto);
        dispatch(loadChangeRequestSuccessAction(model));
      }}
    />
  );
};

export default GetChangeRequest;
