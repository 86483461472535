import React, { useCallback } from 'react';
import {
  ArrowLeftIcon,
  ControlAnchor,
  Divider, EmailControl, Form, FormCol, FormRow, InfoTooltip, PercentControl, PhoneControl, PrimarySubmitButton, RadioListControl, SecondaryButton, T2, T4, TertiaryButton, TextControl,
  emailValidator,
} from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import { AdvisorModel } from '~/interfaces/InsurabilityDeclaration/AdvisorModel';
import { TITLE_WITH_SYMBOLS_REGEX, PHONE_REFORMAT_REGEX, NUMERIC_WITH_SYMBOLS } from '~/fragments/ApplicationFragment/constants/regex';
import { formatEmailField, formatNameField, formatRegexBased } from '~/fragments/ApplicationFragment/utilities/fieldFormatters';
import OptionalLabel from '~/fragments/ApplicationFragment/components/OptionalLabel';
import { StyledActionBar } from '../../../IllustrationSettingsModal/IllustrationSettingsModal.styles';
import { StyledFormTitleContainer } from './SettingsAdvisorForm.styles';
import { AdvisorFormFieldMapping } from '../../../../interfaces/AdvisorFormFieldMapping';
import { ADD_SETTINGS_ADVISOR_FORM, CODE_AGENT_MAX_LENGTH, CODE_AGENT_REGEX, DEFAULT_DATA_MAPPING, NAME_MAX_LENGTH, PHONE_MAX_LENGTH } from '../../constants';

export interface SettingsAdvisorFormProps {
  advisorList: AdvisorModel[];
  onSubmitOnAdd: (advisor: AdvisorModel) => void;
  onSubmitOnEdit: (advisor: AdvisorModel) => void;
  onBackButtonClick: (editMode: boolean) => void;
  // Deprecated
  isOnEditingMode: boolean;
  // Deprecated
  onClearDuplicationError?: () => void;
  hasDuplicateError?: boolean;
  // Map fields name with API names
  dataNameMapping?: AdvisorFormFieldMapping;
}

export const SettingsAdvisorForm = ({
  onSubmitOnAdd,
  onSubmitOnEdit,
  onBackButtonClick,
  onClearDuplicationError,
  advisorList,
  isOnEditingMode,
  dataNameMapping = DEFAULT_DATA_MAPPING,
  hasDuplicateError,
}: SettingsAdvisorFormProps) => {
  const { t } = useTranslation('AdvisorsModal');

  const isFirstAdvisor = !Array.isArray(advisorList) || !advisorList.length;
  const advisorsCommissionPercentageSum = advisorList?.map((a) => a.commissionPercentage).reduce((a, b) => a + b, 0);
  const remainingCommissionPercentage = Math.max(100 - advisorsCommissionPercentageSum, 0);

  const handleSubmit = useCallback((advisor: AdvisorModel) => {
    onClearDuplicationError();

    if (!isOnEditingMode) {
      onSubmitOnAdd(advisor);
    } else {
      onSubmitOnEdit(advisor);
    }
  }, [onSubmitOnAdd, onSubmitOnEdit, isOnEditingMode, onClearDuplicationError]);

  const handleBack = useCallback(() => {
    onBackButtonClick(isOnEditingMode);
  }, [onBackButtonClick]);

  const anchorError = (message: string, fieldName: string) => (
    <>
      {t(message)} <ControlAnchor name={fieldName}>{t('anchorError')}</ControlAnchor>
    </>
  );
  const anchorWarning = (message: string, fieldName: string) => (
    <>
      {t(message)} <ControlAnchor name={fieldName}>{t('anchorWarning')}</ControlAnchor>
    </>
  );

  return (
    <>
      <TertiaryButton
        onClick={handleBack}
        LeftIcon={ArrowLeftIcon}
        text
        style={{ paddingBottom: '32px' }}
      >
        {t('form.back')}
      </TertiaryButton>

      <StyledFormTitleContainer>
        <T2> {isOnEditingMode ? t('form.modifyAnAdvisor') : t('common.addAnAdvisor')}  </T2>
        {isOnEditingMode && <InfoTooltip tip={t('form.modifyTooltip')} />}
      </StyledFormTitleContainer>
      <Form
        name={ADD_SETTINGS_ADVISOR_FORM}
        hoistErrors
        valuesSrc="data.advisorToEdit"
        onSubmit={handleSubmit}
        onKeyDown={onClearDuplicationError}
        scrollToFirstError
        errorMessageTitle={t('form.error')}
        warningMessageTitle={t('form.warning')}
        hideErrorMessages
        hideHoistedAnchor
        cleanValues
      >
        <T4>{t('form.identificationAndContactInfo')}</T4>
        <FormRow>
          <FormCol label={t('common.agentCode')}>
            <TextControl
              name="code"
              forceInvalid={hasDuplicateError}
              maxLength={CODE_AGENT_MAX_LENGTH}
              required
              validators={{
                lastNameRequired: () => !hasDuplicateError,
                validator: (v) => v?.length > 0 ? CODE_AGENT_REGEX.test(v) : true,
              }}
              valueToStateFormatters={[
                (v: string) => formatRegexBased(v, TITLE_WITH_SYMBOLS_REGEX),
              ]}
              validationMessages={{
                required: anchorError('form.agentCodeRequired', 'code'),
                validator: anchorError('form.agentCodeSixDigit', 'code'),
                lastNameRequired: anchorError('form.agentCodeExists', 'code'),
              }}
            />
          </FormCol>
          <FormCol label={<>{t('form.professionalTitle')} <OptionalLabel /></>}>
            <TextControl
              colLabel={`${t('form.professionalTitle')}`}
              name="agentTitle"
              maxLength={NAME_MAX_LENGTH}
              valueToStateFormatters={[
                (v: string) => formatRegexBased(v?.toString(), TITLE_WITH_SYMBOLS_REGEX),
              ]}
              trim
              trimMultipleSpaces
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol label={t('form.lastName')}>
            <TextControl
              name="lastName"
              maxLength={NAME_MAX_LENGTH}
              valueToStateFormatters={[
                (v: string) => formatNameField(v),
              ]}
              validationMessages={{ required: anchorError('form.agentLastNameRequired', 'lastName') }}
              required
              trim
              trimMultipleSpaces
            />
          </FormCol>
          <FormCol label={<>{t('form.firstName')} <OptionalLabel /></>}>
            <TextControl
              colLabel={`${t('form.firstName')}`}
              name="firstName"
              maxLength={NAME_MAX_LENGTH}
              valueToStateFormatters={[
                (v) => formatNameField(v),
              ]}
              trim
              trimMultipleSpaces
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol label={<>{t('form.agency')} <OptionalLabel /></>}>
            <TextControl
              colLabel={`${t('form.agency')}`}
              name="agencyName"
              maxLength={NAME_MAX_LENGTH}
              valueToStateFormatters={[
                (v: string) => formatRegexBased(v, TITLE_WITH_SYMBOLS_REGEX),
              ]}
              trim
              trimMultipleSpaces
            />
          </FormCol>
          <FormCol
            label={<>{t('form.cellPhone')} <OptionalLabel /></>}
            tip={<InfoTooltip tip={t('form.cellPhoneTooltip')} />}
          >
            <PhoneControl
              colLabel={`${t('form.cellPhone')}`}
              name="cellularNumber"
              maxLength={10}
              valueToStateFormatters={[
                (v: string) => formatRegexBased(v, PHONE_REFORMAT_REGEX),
              ]}
              validationMessages={{
                validator: anchorError('form.cellPhoneFormat', 'cellularNumber'),
                phone: anchorError('form.cellPhoneFormat', 'cellularNumber'),
              }}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol label={<>{t('form.workPhone')} <OptionalLabel /></>}>
            <PhoneControl
              colLabel={`${t('form.workPhone')}`}
              name="officeTelephone"
              maxLength={10}
              valueToStateFormatters={[
                (v: string) => formatRegexBased(v, PHONE_REFORMAT_REGEX),
              ]}
              validationMessages={{
                validator: anchorError('form.workPhoneFormat', 'officeTelephone'),
                phone: anchorError('form.workPhoneFormat', 'officeTelephone'),
              }}
            />
          </FormCol>
          <FormCol label={<>{t('form.extension')} <OptionalLabel /></>}>
            <TextControl
              colLabel={`${t('form.extension')}`}
              name="officePhoneExtension"
              valueToStateFormatters={[
                (v: string) => formatRegexBased(v, NUMERIC_WITH_SYMBOLS),
              ]}
              maxLength={PHONE_MAX_LENGTH}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol label={<>{t('form.fax')} <OptionalLabel /></>}>
            <PhoneControl
              colLabel={`${t('form.fax')}`}
              name="faxNumber"
              maxLength={10}
              valueToStateFormatters={[
                (v: string) => formatRegexBased(v, PHONE_REFORMAT_REGEX),
              ]}
              validationMessages={{
                validator: anchorError('form.faxNumberFormat', 'faxNumber'),
                phone: anchorError('form.faxNumberFormat', 'faxNumber'),
              }}
            />
          </FormCol>
          <FormCol label={<>{t('form.emailAddress')} <OptionalLabel /></>}>
            <EmailControl
              colLabel={`${t('form.emailAddress')}`}
              name="emailAddress"
              maxLength={NAME_MAX_LENGTH}
              validationMessages={{
                email: anchorWarning('form.emailMessage', 'emailAddress'),
              }}
              valueToStateFormatters={[
                (v: string) => formatEmailField(v),
              ]}
              validators={{
                email: {
                  validator: emailValidator,
                  type: 'warning',
                },
              }}
            />
          </FormCol>
        </FormRow>

        <T4>{t('form.compensationAndAfterSalesService')}</T4>
        <FormRow>
          <FormCol label={<>{t('common.commissionDistribution')} <OptionalLabel /></>} width={100}>
            <PercentControl
              name="commissionPercentage"
              inputWidth={67}
              defaultValue={remainingCommissionPercentage}
              maxLength={3}
              valueToStateFormatters={[
                (v) => formatRegexBased(v?.toString(), PHONE_REFORMAT_REGEX),
              ]}
              validationMessages={{
                max: anchorError('form.commissionDistributionMax', 'commissionPercentage'),
              }}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol label={<>{t('form.afterSalesService')} <OptionalLabel /></>} width={100}>
            <RadioListControl
              name="provideAfterSaleService"
              options={[
                { label: t('common.yes'), value: true },
                { label: t('common.no'), value: false },
              ]}
              defaultValue={isFirstAdvisor}
              noBox
              horizontal
            />
          </FormCol>
        </FormRow>

        <T4>{t('common.defaultAdvisor')}</T4>
        <FormRow>
          <FormCol label={t('form.addAsDefault')} width={100}>
            <RadioListControl
              name={dataNameMapping.isDefault}
              options={[
                { label: t('common.yes'), value: true },
                { label: t('common.no'), value: false },
              ]}
              defaultValue={isFirstAdvisor}
              noBox
              horizontal
            />
          </FormCol>
        </FormRow>

        <Divider />
        <StyledActionBar>
          <PrimarySubmitButton>{isOnEditingMode ? t('form.save') : t('form.add')}</PrimarySubmitButton>
          <SecondaryButton onClick={handleBack}>
            {t('common.cancel')}
          </SecondaryButton>
        </StyledActionBar>
      </Form>
    </>
  );
};
