import { ValidationMessage } from '~/interfaces/ValidationMessage';

export default function updateValidationMessages(data: ValidationMessage[], info: ValidationMessage[], id: string) {
  if (info?.length === 0) return data?.filter((item) => item.noParticipant !== id);
  // Create a map to store the latest info by noParticipant
  const infoMap: Map<string, ValidationMessage[]> = new Map();
  info?.forEach((item) => { infoMap.set(item.noParticipant, []); });
  info?.forEach((item) => { infoMap.get(item.noParticipant)?.push(item); });
  // Filter out old data entries with ids present in infoMap
  const updatedData = data?.filter((item) => !infoMap.has(item.noParticipant));
  // Append new info entries to the filtered data
  infoMap?.forEach((entries) => { updatedData.push(...entries); });
  return updatedData;
}
