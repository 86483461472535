import { ColumnCode } from '../constants';

export const isColVisible = (column: string, screenClass: string): boolean => {
  if ((screenClass === 'xs' || screenClass === 'sm') && column === ColumnCode.created) return true;
  if (screenClass === 'sm' && column === ColumnCode.status) return true;

  if (['xs', 'sm'].includes(screenClass) === false) return true;

  return false;
};
