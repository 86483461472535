import ExplanatoryTableCellModel from '~/interfaces/InsurabilityDeclaration/ExplanatoryTableCellModel';
import ExplanatoryTableCellDto from '../dtos/ExplanatoryTableCellDto';

export default class ExplanatoryTableCellBuilder {
  toModel(dto: ExplanatoryTableCellDto): ExplanatoryTableCellModel {
    return new ExplanatoryTableCellModel(
      dto.id,
      dto.value,
    );
  }
}
