import {
  useScopedSelector,
} from 'ia-react-core';
import React, { FC } from 'react';
import { RolesPageState } from '~/interfaces/RolesPageState';
import RoleResourceLoader from '../../resources/RoleResourceLoader';

export interface RoleListProps { }

const RoleList: FC<RoleListProps> = () => {
  const selectedRole = useScopedSelector((state: RolesPageState) => (
    state?.values?.roles?.role
  ));

  return (
    <RoleResourceLoader selectedRole={selectedRole}>
      {({ data: { permissions } }) => (
        <ul>
          {permissions?.map((item) => (<li key={item}>{item}</li>))}
        </ul>
      )}
    </RoleResourceLoader>
  );
};

export default RoleList;
