import { Action } from 'redux';
import { all, takeEvery } from 'redux-saga/effects';
import loadApplicationSaga from './loadApplication';
import { FATAL_ERROR_TYPE, LOAD_APPLICATION_TYPE, UpdatePacPadAgreement, UpdateBilling, LOAD_APPLICATION_TYPE_WITHOUT_RELOADING } from '../ApplicationFragment.actions';
import fatalErrorSaga from './fatalError';
import updatePacPadAgreement from './updatePacPadAgreement';
import updateBilling from './updateBilling';
import loadApplicationWithoutreloadingSaga from './loadApplicationWithoutreloading';

const mapping: Record<string, (action: Action) => unknown> = {
  [LOAD_APPLICATION_TYPE]: loadApplicationSaga,
  [FATAL_ERROR_TYPE]: fatalErrorSaga,
  [UpdatePacPadAgreement.type]: updatePacPadAgreement,
  [UpdateBilling.type]: updateBilling,
  [LOAD_APPLICATION_TYPE_WITHOUT_RELOADING]: loadApplicationWithoutreloadingSaga,
};
export default function* applicationSaga() {
  yield all(Object.entries(mapping).map(([key, saga]) => takeEvery(key, saga)));
}
