import {
  ResourceLoader,
  createChildrenTunnel,
  createPropsTunnel,
  DropListOption,
  MainLoader,
} from 'ia-react-core';
import React, { ComponentProps, FC, MouseEvent, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ProductNavigationState } from '~/interfaces/ProductNavigationState';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import useScenario from '~/fragments/IllustrationFragment/hooks/useScenario';
import OipaEnvironmentsModal from '~/components/OipaEnvironmentsModal';
import IllustrationSettingsModal from '~/components/IllustrationSettingsModal';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/interfaces/State';
import { AppState } from '~/interfaces/AppState';
import GetProvincesResource from '~/components/Resources/GetProvincesResource';
import { Locale } from '~/constants/APP_CONSTANTS';
import { setAppVersioning } from '~/App.actions';
import MainLayout from '../MainLayout/MainLayout';
import './IllustrationLayout.css';
import DrawerFiles from './components/DrawerFiles';
import FluidContainer from './components/FluidContainer';
import IllustrationMainHeaderNav, { IllustrationMainHeaderNavProps } from './components/IllustrationMainHeaderNav';
import { IllustrationNavigationLinkProps } from './components/IllustrationSideNavigation/IllustrationSideNavigation';
import { ScenarioDrawer } from './components/ScenarioDrawer/ScenarioDrawer';
import ScenariosNav from './components/ScenariosNav';
import getPageName from './utilities/getPageName';

export interface IllustrationLayoutProps extends IllustrationMainHeaderNavProps {
  children?: ReactNode;
  onSetIsLeaving?: (value: boolean) => void;
  openSaveSaleFileModal?: () => void;
  onMarginalRateLinkClicked?: () => void;
  onProvinceSelectionLinkClicked?: () => void;
  isCoverageRiderDrawerOpen?: boolean;
  isInvestmentSideDrawerOpen?: boolean;
  shouldShowApplicationSummaryForRider?: boolean;
  setShouldShowApplicationSummaryForRider?: (value: boolean) => void;
  localizedProvinceName?: string;
  mainContentHeader?: string;
}
export interface IllustrationRouteParamProp {
  saleId: string;
}

const {
  setTunnel: setIllustrationLayoutPropsTunnel,
  useTunnel: useIllustrationLayoutPropsTunnel,
} = createPropsTunnel<IllustrationLayoutProps>();

const {
  Entry: ApplicationContentTunnelEntry,
  Exit: ApplicationContentTunnelExit,
} = createChildrenTunnel();

const IllustrationLayout: FC<IllustrationLayoutProps> & {
  setIllustrationLayoutPropsTunnel: typeof setIllustrationLayoutPropsTunnel;
  ApplicationContentTunnelEntry: typeof ApplicationContentTunnelEntry;
} = (props) => {
  const tunneledProps = useIllustrationLayoutPropsTunnel();

  const {
    children, onSave, showSaveDiskIcon, openSaveSaleFileModal, onSetIsLeaving, saleFileName, onMarginalRateLinkClicked, onProvinceSelectionLinkClicked, localizedProvinceName, mainContentHeader,
  } = { ...props, ...tunneledProps };
  const provinceList = useSelector((state: AppState) => state?.App?.fragments?.IllustrationFragment?.data?.provinces);

  const { t } = useTranslation('App');
  const history = useHistory();
  const scenario = useScenario();
  const dispatch = useDispatch();
  const lang = useSelector((state: State) => state.language?.locale);
  const province = provinceList?.find((p) => p.code === scenario?.provinceCode);
  const provinceDescription = lang === Locale.EN ? province?.description_EN : province?.description_FR;
  const productType = scenario?.produit?.typeProduit;

  const { saleId } = useParams<IllustrationRouteParamProp>();

  const getNavigationLinks = (data: ProductNavigationState[], locale: string) => {
    const productsNavigation = [...data];
    const navigationLinks: IllustrationNavigationLinkProps[] = [];
    productsNavigation.sort((curr, next) => curr.ordreAffichage - next.ordreAffichage);

    // get active link
    const pathName = document.location.pathname;
    const pageRoute = pathName.split('/')[2];
    const getActiveLink = (to: string) => {
      const currPageRoute = t(pageRoute);
      const toPageRoute = to.split('/')[2];
      const linkPageRoute = t(toPageRoute, { lng: 'en' });
      return to.replace(toPageRoute, linkPageRoute) === pathName.replace(pageRoute, currPageRoute);
    };

    productsNavigation.forEach((productNavigation) => {
      const page = getPageName(productNavigation.page);
      const to = `/illustration/${saleId}/${t(page)}`;
      navigationLinks.push({
        to,
        title: locale === 'en' ? productNavigation.titreAnglais : productNavigation.titreFrancais,
        id: `${productNavigation.page}${productNavigation.idProduit}`,
        active: getActiveLink(to),
      });
    });
    return navigationLinks;
  };

  const getNavigationSelectLinks = (data: ProductNavigationState[], locale: string) => {
    const productsNavigation = [...data];
    const navigationLinks: DropListOption<string>[] = [];
    productsNavigation.forEach((productNavigation) => {
      const page = getPageName(productNavigation.page);
      navigationLinks.push({
        label: locale === 'en' ? productNavigation.titreAnglais : productNavigation.titreFrancais,
        value: `/illustration/${t(page)}/${saleId}`,
      });
    });
    return navigationLinks;
  };

  const onNavigateToHome = () => {
    if (showSaveDiskIcon) {
      onSetIsLeaving(true);
      openSaveSaleFileModal();
    } else {
      dispatch(setAppVersioning('', ''));
      setTimeout(() => { history.push('/'); }, 300);
    }
  };

  useEffect(() => (
    MainLayout.setMainLayoutPropsTunnel({
      noFooterLanguageButton: true,
      // Only way for now to get 100% width Scenario tabs
      maxContentWidth: '100%' as unknown as number,
      onMainLogoClick: (e: MouseEvent<HTMLAnchorElement>) => {
        e?.preventDefault();
        return onNavigateToHome != null ? onNavigateToHome() : history.push('/');
      },
    } as ComponentProps<typeof MainLayout>)
  ), [history, onNavigateToHome]);

  return (
    <ResourceLoader
      name="productNavigationResource"
      url={`${getBffUrlForRoute(BffRoute.illustration_product_navigation, undefined, false)}&produitEnum=${productType}`}
      CustomLoader={MainLoader}
    >
      {({ data }: { data: ProductNavigationState[] }) => (
        <>
          <MainLayout.MainHeaderRightContentTunnelEntry>
            <IllustrationMainHeaderNav
              saleFileName={saleFileName}
              showSaveDiskIcon={showSaveDiskIcon}
              onNavigateToHome={onNavigateToHome}
              onSave={onSave}
            />
          </MainLayout.MainHeaderRightContentTunnelEntry>
          <DrawerFiles />
          <ScenarioDrawer name="scenarioDrawer" title={t('scenarioDrawerTitle')} />

          <ScenariosNav />
          <GetProvincesResource />
          <IllustrationSettingsModal />
          <OipaEnvironmentsModal />

          <FluidContainer
            data={data}
            getNavigationSelectLinks={getNavigationSelectLinks}
            onMarginalRateLinkClicked={onMarginalRateLinkClicked}
            onProvinceSelectionLinkClicked={onProvinceSelectionLinkClicked}
            localizedProvinceName={localizedProvinceName ?? provinceDescription}
            renderApplicationContent={() => (
              <ApplicationContentTunnelExit />
            )}
            getNavigationLinks={getNavigationLinks}
            renderMainContent={() => children}
            mainContentHeader={mainContentHeader}
          />
        </>
      )}
    </ResourceLoader>
  );
};

IllustrationLayout.setIllustrationLayoutPropsTunnel = setIllustrationLayoutPropsTunnel;
IllustrationLayout.ApplicationContentTunnelEntry = ApplicationContentTunnelEntry;

export default IllustrationLayout;
