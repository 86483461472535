import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConfirmModal,
} from 'ia-react-core';
import './DeleteModal.css';
import { StyledDeleteSalesFileModalDescription, StyledIcon, StyledIconContainer } from './DeleteModal.styles';
import { SaleFile } from '../interfaces/SaleFile';

export interface DeleteModalProps {
  saleFile: SaleFile;
  deleteFile: () => void;
}

const DeleteModal: FC<DeleteModalProps> = ({ deleteFile, saleFile }) => {
  const { t } = useTranslation('DeleteModal');

  return (
    <ConfirmModal
      id="deletefile-confirm"
      name="deleteSalesFileModal"
      confirmButton={t('confirmDelete')}
      confirmDisabled={saleFile?.statut === 3}
      cancelButton={t('cancelDelete')}
      isClosable={false}
      onConfirm={deleteFile}
    >
      <StyledIconContainer>
        <StyledIcon />
      </StyledIconContainer>

      <StyledDeleteSalesFileModalDescription>
        { saleFile?.statut === 3 ? t('textCannotDelete') : t('textDeleteFile')}
      </StyledDeleteSalesFileModalDescription>
    </ConfirmModal>
  );
};

export default DeleteModal;
