import { FC } from 'react';
import { compose } from 'redux';
import { injectTranslations } from 'ia-react-core';
import ContractCancelConfirmDialog, { ContractCancelConfirmDialoggProps } from './ContractCancelConfirmDialog';
import fr from '../../translations/fr';
import en from '../../translations/en';

export default compose<FC<ContractCancelConfirmDialoggProps>>(
  injectTranslations('makeEchange', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(ContractCancelConfirmDialog);

export * from './ContractCancelConfirmDialog';
