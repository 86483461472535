import React, { FC, useMemo } from 'react';
import { Resource, ResourceProps } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { setDownloadAction } from '../MakeEchangeModal.actions';

export interface ContractDetaileProps extends ResourceProps<null, null> {
  saleId: string;
  method: 'GET' | 'POST';
  onContractSuccess?: (response: AxiosResponse) => void;
  onContractError?: (error?: AxiosError) => void;
}
const ContractDetailResource: FC<ContractDetaileProps> = ({ saleId, method, onContractSuccess, onContractError }) => {
  const dispatch = useDispatch();
  const ContractDetailUrl = useMemo(() => getBffUrlForRouteWithoutQP(
    BffRoute.contract_details,
    { saleId },
    false,
  ), [saleId]);

  const handleContractSuccess = (response: AxiosResponse): void => {
    if (onContractSuccess) {
      dispatch(setDownloadAction({
        ...response.data,
      }));
      onContractSuccess(response);
    }
  };

  return (
    <Resource
      name="contractDetails"
      url={ContractDetailUrl}
      method={method}
      onSuccess={handleContractSuccess}
      onFailure={onContractError}
    />
  );
};

export default ContractDetailResource;
