import { ActionBar, DataTable, InfoMessage, P } from 'ia-react-core';
import styled from 'styled-components';
import Description from '~/assets/Icons/description.svg';

export const StyledActionBar = styled(ActionBar)`
  margin: 10px 0 0 0;
  justify-content: end;
  padding: 0;

  @media (max-width: 480px) {
    flex-wrap: nowrap;
    flex-direction: column;
    button {
      width: 100%;
      margin: 0;
      &:first-child,
      &:last-child {
        margin-bottom: 10px !important;
      }
    }
  }
`;

export const StyledP = styled(P)`
  line-height: 1.5;
  color:grey;
`;

export const StyledInfoMessage = styled(InfoMessage)`
h1 {
  font-size: 14px;
  font-weight: 500;
}
`;

export const StyledDataTable = styled(DataTable)`
  margin-top: 10px;
  [role="rowgroup"]:first-child {
    background-color: rgb(0, 61, 165);
    color: white;
    [role="row"] {
      [role="columnheader"]:nth-child(1) {
        padding: 0;
      }
      [role="columnheader"]:nth-child(2) {
        background: url('${Description}') no-repeat center;
      }
    }
  }

  [role="row"] {
    align-items: center;
    [role="cell"]:first-child {
      padding: 0;
    }
  }
`;
