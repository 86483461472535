import { LinkedApplication, LinkedApplicationFormData } from '~/interfaces/LinkedApplicationsPageState';
import { ValidationMessage } from '~/interfaces/ValidationMessage';

export enum ActionType {
  ADD_ALL_LINKED_APPLICATIONS = 'ADD_ALL_LINKED_APPLICATIONS',
  UPDATE_LINKED_APPLICATION = 'UPDATE_LINKED_APPLICATION',
  ADD_LINKED_APPLICATION = 'ADD_LINKED_APPLICATION',
  SELECT_LINKED_APPLICATION = 'SELECT_LINKED_APPLICATION',
  DELETE_LINKED_APPLICATION = 'DELETE_LINKED_APPLICATION',
  SELECT_SAVE_LINKED_APPLICATION = 'SELECT_SAVE_LINKED_APPLICATION',
  UPDATE_ADDITIONAL_APPLICATION = 'UPDATE_ADDITIONAL_APPLICATION',
  ADD_ADDITIONAL_APPLICATION = 'ADD_ADDITIONAL_APPLICATION',
  CLEAR_SELECTED_LINKED_APPLICATION='CLEAR_SELECTED_LINKED_APPLICATION',
  ADD_VALIDATION_MESSAGES = 'ADD_VALIDATION_MESSAGES',
}

export const addAllLinkedApplicationsAction = (payload: LinkedApplicationFormData) => ({
  type: ActionType.ADD_ALL_LINKED_APPLICATIONS,
  payload,
});
export type AddAllLinkedApplicationsAction = ReturnType<typeof addAllLinkedApplicationsAction>;

export const updateLinkedApplicationAction = (linkedApplication: LinkedApplication) => ({
  type: ActionType.UPDATE_LINKED_APPLICATION,
  payload: linkedApplication,
});
export type UpdateLinkedApplicationAction = ReturnType<typeof updateLinkedApplicationAction>;

export const addLinkedApplicationAction = (linkedApplication: LinkedApplication) => ({
  type: ActionType.ADD_LINKED_APPLICATION,
  payload: linkedApplication,
});
export type AddLinkedApplicationAction = ReturnType<typeof addLinkedApplicationAction>;

export const selectLinkedApplicationAction = (linkedApplication: LinkedApplication) => ({
  type: ActionType.SELECT_LINKED_APPLICATION,
  payload: linkedApplication,
});
export type SelectLinkedApplicationAction = ReturnType<typeof selectLinkedApplicationAction>;

export const clearSelectedLinkedApplicationAction = () => ({
  type: ActionType.CLEAR_SELECTED_LINKED_APPLICATION,
});

export const selectSaveLinkedApplicationAction = (linkedApplication: LinkedApplication) => ({
  type: ActionType.SELECT_SAVE_LINKED_APPLICATION,
  payload: linkedApplication,
});
export type SelectSaveLinkedApplicationAction = ReturnType<typeof selectSaveLinkedApplicationAction>;

export const deleteLinkedApplicationAction = (linkedApplication: LinkedApplication) => ({
  type: ActionType.DELETE_LINKED_APPLICATION,
  payload: linkedApplication,
});
export type DeleteLinkedApplicationAction = ReturnType<typeof deleteLinkedApplicationAction>;

export const updateAdditionalApplicationAction = (linkedApplication: LinkedApplication) => ({
  type: ActionType.UPDATE_ADDITIONAL_APPLICATION,
  payload: linkedApplication,
});
export type UpdateAdditionalApplicationAction = ReturnType<typeof updateAdditionalApplicationAction>;

export const addAdditionalApplicationAction = (linkedApplication: LinkedApplication) => ({
  type: ActionType.ADD_ADDITIONAL_APPLICATION,
  payload: linkedApplication,
});
export type AddAdditionalApplicationAction = ReturnType<typeof addAdditionalApplicationAction>;

export const addValidationMessagesAction = (messages: ValidationMessage[]) => ({
  type: ActionType.ADD_VALIDATION_MESSAGES,
  payload: messages,
});
export type AddValidationMessagesAction = ReturnType<typeof addValidationMessagesAction>;
