import React from 'react';
import { DropListOption } from 'ia-react-core';
import { StyledIllustrationNavigationDropdown } from './IllustrationNavigationSelect.styles';

export interface IllustrationNavigationSelectProps {
  options: DropListOption<unknown>[];
  onChange?: React.FormEventHandler<HTMLButtonElement>;
  fullWidth?: boolean;
}

const IllustrationNavigationSelect = ({ options, onChange, fullWidth }: IllustrationNavigationSelectProps) => (
  <StyledIllustrationNavigationDropdown
    name="navSelect"
    onChange={onChange}
    options={options}
    noEmptyValue
    value={options?.[0]?.value}
    $fullWidth={fullWidth}
  />
);

export { IllustrationNavigationSelect };
