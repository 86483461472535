import React from 'react';
import { CheckboxItemControl, CheckboxListControl, DropdownControl, FormCol, FormRow, T4 } from 'ia-react-core';
import { useTranslation } from 'react-i18next';

import usePremiumComparisonToolManager from '~/components/PremiumComparisonToolModal/hooks/usePremiumComparisonToolManager';
import getTextBasedOnLocale from '~/utilities/getTextBasedOnLocale';
import { RiderPlanCodes } from '~/components/PremiumComparisonToolModal/constants';

const RidersSection = () => {
  const { t } = useTranslation('InsuredPersonsContent');
  const {
    flexibleReturnChecked, pcToolFormValues, riderPresentationViewModels, locale,
    onFormValuesChanged, getSimpleLinkedRidersCheckBoxOptions,
  } = usePremiumComparisonToolManager();

  const handleOnFieldValuesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    const data = {
      ...(name === 'simpleLinkedRiders' && { planCode: value }),
      ...(name === 'flexibleReturn' && { planCode: checked }),
    };
    onFormValuesChanged(data);
  };

  const handleOnValueChange = (value: string) => {
    if (pcToolFormValues.flexibleReturnPeriod !== value) onFormValuesChanged({ planCode: value });
  };

  const genericPlanCodeTxt = (genericPlanCode: string) => {
    if (genericPlanCode === RiderPlanCodes.flexReturnFifteen) return t('frp15');
    return t('frp65');
  };

  const getFlexibleReturnDropDownOptions = () => riderPresentationViewModels?.mutuallyExclusiveRiderGroups?.[0]?.multipleRiders?.toSorted((a, b) => a.genericPlanCode.localeCompare(b.genericPlanCode)).map((rider) => ({
    label: `${getTextBasedOnLocale(locale, rider.descriptionEn, rider.descriptionFr)} (${genericPlanCodeTxt(rider.genericPlanCode)})`,
    value: rider.genericPlanCode,
  }));

  return (
    <>
      <T4 marginBottom={0}>{t('riders')}</T4>

      <FormRow>
        <FormCol col-xs={12} labelMarginBottom={0}>
          <CheckboxListControl
            name="simpleLinkedRiders"
            options={getSimpleLinkedRidersCheckBoxOptions()}
            onChange={handleOnFieldValuesChanged}
          />
        </FormCol>
      </FormRow>

      {riderPresentationViewModels &&
      <FormRow>
        <FormCol col-xs={12} labelMarginBottom={0}>
          <CheckboxItemControl
            name="flexibleReturn"
            label={t('flexibleReturn')}
            onChange={handleOnFieldValuesChanged}
          />
        </FormCol>
      </FormRow>}

      {flexibleReturnChecked &&
      <FormRow>
        <FormCol col-xs={12} label={t('t75AndT100')} labelMarginBottom={0}>
          <DropdownControl
            name="flexibleReturnPeriod"
            options={getFlexibleReturnDropDownOptions()}
            onValueChange={handleOnValueChange}
            noEmptyValue
          />
        </FormCol>
      </FormRow>}
    </>
  );
};

export default RidersSection;
