import { Resource, WORKFLOW_STATUSES } from 'ia-react-core';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router';
import { updateRouteStatus } from '~/App.actions';
import { loadApplicationWithoutReloading, updateApplicantValidationMessagesAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';
import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';
import selectCurrentRoute from '~/fragments/ApplicationFragment/selectors/selectCurrentRoute';
import getStatusFromValidationMessages from '~/fragments/ApplicationFragment/utilities/getStatusFromValidationMessages';
import { Locale, APIRequestLanguage } from '~/interfaces/InsurabilityDeclaration/enums';
import { ApiResponse } from '~/interfaces/ValidationMessage';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';
import scrollIntoView from '~/utilities/scrollIntoView';
import { State } from '~/interfaces/State';
import { updateValidationMessagesAction } from '../ApplicantPage.actions';
import { NEXT_BTN_PUT_APPLICANT, PREVIOUS_BTN_PUT_APPLICANT, PUT_APPLICANT_RESOURCE_NAME, UPDATE_APPLICANT_RESOURCE_NAME } from '../constant';

export interface ApplicantResourceLoaderProps {
  useMock?: boolean;
  language?: Locale;
  saleId: string;
  onSuccess?: (resourceName: string) => void;
  onError?: (resourceName: string) => void;
}
const ApplicantResourceLoader: React.FC<ApplicantResourceLoaderProps> = ({ language, saleId, useMock = false, onSuccess, onError }) => {
  const provinceUrl = useMemo(() => getBffUrlForRoute(BffRoute.places, {
    language: language === Locale.FR ? APIRequestLanguage.French :
      APIRequestLanguage.English,
  }, useMock), [useMock, language]);

  const issuingorganizationsUrl = useMemo(() => getBffUrlForRoute(BffRoute.issuingOrganizations, {
    language: language === Locale.FR ? APIRequestLanguage.French :
      APIRequestLanguage.English,
  }, useMock), [useMock, language]);

  const financialInstitutesUrl = useMemo(() => getBffUrlForRoute(BffRoute.financialInstitutes, {
    language: language === Locale.FR ? APIRequestLanguage.French :
      APIRequestLanguage.English,
  }, useMock), [useMock, language]);

  let intentType: ApplicationIntentType;
  const dispatch = useDispatch();

  const identificationDocumentsURL = useMemo(() => getBffUrlForRoute(BffRoute.identificationdocuments, {
    language: language === Locale.FR ? APIRequestLanguage.French :
      APIRequestLanguage.English,
  }, useMock), [useMock, language]);

  const applicantDataURL = useMemo(() => getBffUrlForRoute(BffRoute.applicant_application_saleId, { saleId }, useMock), [useMock, saleId]);
  const currentRoute = useSelector(selectCurrentRoute);
  const backToHome = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);
  const history = useHistory();

  return (
    <>
      <Resource
        name={PUT_APPLICANT_RESOURCE_NAME}
        url={applicantDataURL}
        autoRequest={false}
        method="PUT"
        transformRequest={(payloadData: {intentType: ApplicationIntentType}) => { intentType = payloadData.intentType; return payloadData; }}
        onSuccess={(res) => {
          const { validationMessages } = (res?.data as ApiResponse);

          const status = getStatusFromValidationMessages(validationMessages);
          if (backToHome) {
            history.block(true);
            history.push('/');
            return;
          }
          if (intentType === ApplicationIntentType.Validate) {
            dispatch(updateApplicantValidationMessagesAction(validationMessages || []));
            dispatch(updateRouteStatus(currentRoute, status));
          } else if (intentType === ApplicationIntentType.SaveAndValidate) {
            dispatch(updateApplicantValidationMessagesAction(validationMessages || []));
            dispatch(updateRouteStatus(currentRoute, status));
            dispatch(loadApplicationWithoutReloading(saleId));
            if (status === WORKFLOW_STATUSES.ERROR) {
              history.push(currentRoute);
              scrollIntoView();
            }
          } else if (intentType === ApplicationIntentType.Save) {
            onSuccess?.(UPDATE_APPLICANT_RESOURCE_NAME);
            dispatch(loadApplicationWithoutReloading(saleId));
          }
        }}
        onFailure={() => { onError?.(PUT_APPLICANT_RESOURCE_NAME); }}
      >
        {null}
      </Resource>
      <Resource
        autoRequest
        name="provinces"
        url={provinceUrl}
        onSuccess={() => { onSuccess?.('provinces'); }}
        onFailure={() => { onError?.('provinces'); }}
      >
        {null}
      </Resource>
      <Resource
        autoRequest
        name="issuingorganizations"
        url={issuingorganizationsUrl}
        onSuccess={() => { onSuccess?.('issuingorganizations'); }}
        onFailure={() => { onError?.('issuingorganizations'); }}
      >
        {null}
      </Resource>
      <Resource
        autoRequest
        name="financialInstitutes"
        url={financialInstitutesUrl}
        onSuccess={() => { onSuccess?.('financialInstitutes'); }}
        onFailure={() => { onError?.('financialInstitutes'); }}
      >
        {null}
      </Resource>
      <Resource
        autoRequest
        name="identificationdocuments"
        url={identificationDocumentsURL}
        onSuccess={() => { onSuccess?.('identificationdocuments'); }}
        onFailure={() => { onError?.('identificationdocuments'); }}
      >
        {null}
      </Resource>
      <Resource
        name={PREVIOUS_BTN_PUT_APPLICANT}
        url={applicantDataURL}
        autoRequest={false}
        method="PUT"
        transformRequest={(payloadData: {intentType: ApplicationIntentType}) => { intentType = payloadData.intentType; return payloadData; }}
        onSuccess={(res) => {
          const { validationMessages } = (res?.data as ApiResponse);
          const status = getStatusFromValidationMessages(validationMessages);
          dispatch(updateValidationMessagesAction(validationMessages || []));

          if (status === WORKFLOW_STATUSES.ERROR) {
            dispatch(loadApplicationWithoutReloading(saleId));
          } else {
            onSuccess?.(PREVIOUS_BTN_PUT_APPLICANT);
            dispatch(updateRouteStatus(currentRoute, status));
          }
        }}
        onFailure={() => { onError?.(PREVIOUS_BTN_PUT_APPLICANT); }}
      >
        {null}
      </Resource>
      <Resource
        name={NEXT_BTN_PUT_APPLICANT}
        url={applicantDataURL}
        autoRequest={false}
        method="PUT"
        transformRequest={(payloadData: {intentType: ApplicationIntentType}) => { intentType = payloadData.intentType; return payloadData; }}
        onSuccess={(res) => {
          const { validationMessages } = (res?.data as ApiResponse);
          const status = getStatusFromValidationMessages(validationMessages);
          dispatch(updateValidationMessagesAction(validationMessages || []));

          if (status === WORKFLOW_STATUSES.ERROR) {
            dispatch(loadApplicationWithoutReloading(saleId));
          } else {
            onSuccess?.(NEXT_BTN_PUT_APPLICANT);
            dispatch(updateRouteStatus(currentRoute, status));
          }
        }}
        onFailure={() => { onError?.(NEXT_BTN_PUT_APPLICANT); }}
      >
        {null}
      </Resource>
    </>

  );
};

export default ApplicantResourceLoader;
