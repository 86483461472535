import { media } from 'ia-react-core';
import styled, { css } from 'styled-components';
import { devices } from '~/interfaces/devices';

export const StyledContainerFluid = styled.section<{ $isDrawerOpen?: boolean; $minWidthRequiringOffset: number; $maxWidthRequiringOffset: number }>`
  position: relative;
  height: 100%;
  left: 0px;
  transition: left 0.2s;

  ${({ $isDrawerOpen }) => $isDrawerOpen ? css`
    ${media.lg`
      left: 300px;
    `}

    ${media.xl`
      left: 0px;
    `}
  ` : ''}

  ${media.xs`
    margin: 0;
  `}

  ${media.sm`
    margin: auto 16px;
  `}

  ${media.md`
    margin: auto 32px;
  `}

  ${media.lg`
    margin: auto 32px;
  `}
  
  ${({ $minWidthRequiringOffset, $maxWidthRequiringOffset }) => css`
    @media screen and (min-width: ${($minWidthRequiringOffset)}px) and (max-width: ${($maxWidthRequiringOffset)}px)  {
      margin: 0 32px 0 0 ;
    }

    @media screen and (min-width: ${($maxWidthRequiringOffset)}px) {
      margin-left: 0;
      margin-right: 0;
    }
  `}

`;

export const StyledFileName = styled.span`
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  text-align: right;
  font-weight: 400;
  margin-right: 5px;
  white-space: nowrap;
  width: calc(100%);
`;

export const StyledParagraph = styled.p`
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 400px;
  @media only screen and (${devices.sm}) {
    width: 250px;
  }
  @media only screen and (${devices.md}) {
    width: 150px;
  }
  @media only screen and (${devices.lg}) {
    width: 200px;
  }
  @media only screen and (${devices.xl}) {
    width: 300px;
  }
  @media only screen and (${devices.xxl}) {
    width: 400px;
  }
`;
