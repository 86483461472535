const FRENCH_ROUTES = {
  SUMMARY: '/resume',
  ADVISOR: '/conseiller',
  CONSENT: '/consentement',
  IDENTIFICATION: '/identification',
  INSURANCE_HISTORY: '/histoire-assurance',
  BENEFICIARY: '/beneficiaire',
  APPLICANT: '/contractant',
  REQUIREMENTS: '/exigences',
  COVERAGES: '/protections',
  HEADOFFICE: '/social',
  LINKED_APPLICATIONS: '/applications-liees',
  BILLING: '/facturation',
  PAC_PAD_AGREEMENT: '/accord-pac-pad',
  VALIDATION: '/validation',
  SIGNATURE: '/signature',
  DECLARATIONS: '/declarations',
  SPECIAL_INSTRUCTIONS: '/instructions-speciales',
  TRANSMISSION: '/transmission',
  REINSTATEMENT: '/reinstatement',
  CHANGES: '/changements',
  HEADOFFICE_ECHANGE: '/ho-changement',
  SUMMARY_ECHANGE: '/sommaire',
  VALIDATION_ECHANGE: '/validationchangement',
  SIGNATURE_MODE: '/signature-mode',
  SIGNATURE_FOLLOWUP: '/signature-followup',
  TRANSMISSION_ECHANGE: '/transmissionchangement',
  RESULTS: '/resultats',
};

export default FRENCH_ROUTES;
