import React from 'react';
import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import { QuestionDisplayType } from '~/interfaces/InsurabilityDeclaration/enums';
import { SafeHtml } from 'ia-react-core';
import QuestionField from './QuestionField';

const StaticField: QuestionField = ({ onChange, question, hasError, hasWarning, ...props }) => {
  const locale = useCurrentLocale();
  let content = <img src={`${locale}/${question.name}`} alt={question.name} />;
  if (question.displayType === QuestionDisplayType.Multiline) {
    content = <SafeHtml>{question.answers[0].value.toString()}</SafeHtml>;
  }
  return (
    <div {...props}>{content}</div>
  );
};

export default StaticField;
