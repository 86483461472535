import React, { FC, useCallback, useMemo } from 'react';
import {
  Modal, SecondaryButton, useTranslation, closeModalByName, ExternalLink, Spacing, T2, Divider, useScopedSelector, requestResourceByName,
  openModalByName,
} from 'ia-react-core';
import { useDispatch, useSelector } from 'react-redux';
import { getBffUrlForRouteWithoutQP, BffRoute } from '~/utilities/bffHelper';
import { selectApplicationContextMode } from '~/fragments/ApplicationFragment/selectors/selectApplicationContextMode';
import { ApplicationMode } from '~/interfaces/ApplicationMode';
import { StyledActionBar } from '../../../IllustrationSettingsModal/IllustrationSettingsModal.styles';
import { StyledDiv } from '../../PrintReportModal.styles';

export interface AvailableForPrintingProps {}
interface AvailableReportsModalState {
  modals: {
    availableReports: {
      data: {
        illustrationDocID: string;
        applicationDocID: string;
        id: string;
        type: string;
        isMerged: boolean;
      };
    };
  };
}

const AvailableForPrinting: FC<AvailableForPrintingProps> = () => {
  const isStringNotEmpty = useCallback((str: string) => str !== undefined && str !== null && str?.trim() !== '', []);
  const modalData = useScopedSelector(
    (state: AvailableReportsModalState) => state?.modals?.availableReports?.data,
  );
  const applicationMode = useSelector(selectApplicationContextMode);

  const { t } = useTranslation('PrintReportModal');
  const dispatch = useDispatch();
  const isId = useMemo(() => isStringNotEmpty(modalData?.id), [modalData?.id]);
  const ids = useMemo(() => modalData?.id, [modalData?.id]);
  const documentName = useMemo(() => modalData?.isMerged ? t('mergedDocuments') : t(`${modalData?.type}`), [modalData?.type, modalData?.isMerged]);
  const label = useMemo(() => applicationMode === ApplicationMode.ChangementElectronique ? t('transactions') : t('illustrations'), [applicationMode]);

  const onCloseHandler = () => {
    dispatch(closeModalByName('modals.availableReports'));
  };

  const openReport = (id: string) => {
    dispatch(requestResourceByName('resources.reportDownload', undefined, {
      url: getBffUrlForRouteWithoutQP(BffRoute.report_download, { documentId: id }, false),
    }));
    dispatch(openModalByName('modals.printLoader'));
  };

  return (
    <Modal
      name="availableReportsModal"
      onClose={onCloseHandler}
      shouldCloseOnOverlayClick={false}
    >
      <T2>{t('printAvailable')}</T2>
      <StyledDiv>
        {!isId && <ExternalLink href={null} onClick={() => openReport(modalData?.illustrationDocID)}>{label}.pdf</ExternalLink>}
        {!isId && <ExternalLink href={null} onClick={() => openReport(modalData?.applicationDocID)}>{t('application')}.pdf</ExternalLink>}
        {isId && <ExternalLink href={null} onClick={() => openReport(ids)}>{documentName}.pdf</ExternalLink>}

      </StyledDiv>
      <Spacing m={24} />
      <Divider />
      <StyledActionBar flexButtonsXs>
        <SecondaryButton onClick={onCloseHandler}>
          {t('cancel')}
        </SecondaryButton>
      </StyledActionBar>
    </Modal>
  );
};

export default AvailableForPrinting;
