import React, { FC, useMemo } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { useDispatch } from 'react-redux';
import ChangeRequestBuilder from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/builders/ChangeRequestBuilder';
import ChangeRequestDto from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/dtos/ChangeRequestDto';
import { AxiosError } from 'axios';
import ChangeRequestModel from '~/interfaces/InsurabilityDeclaration/ChangeRequestModel';
import { loadChangeRequestSuccessAction } from '../ChangeRequest.actions';

export interface AddChangeRequestFormProps {
  saleId: string;
  formId: string;
  method: 'GET' | 'POST';
  onSuccess?: (response: ChangeRequestModel) => void;
  onFailure?: (error: AxiosError) => void;
}
const AddChangeRequestForm: FC<AddChangeRequestFormProps> = ({ saleId, formId, method, onFailure, onSuccess }) => {
  const dispatch = useDispatch();
  const addChangeRequestFormUrl = useMemo(() => getBffUrlForRouteWithoutQP(
    BffRoute.change_request_form_by_id,
    { saleId, formId },
    false,
  ), [saleId, formId]);

  return (
    <Resource
      name="resources.add_change_request_form"
      url={addChangeRequestFormUrl}
      method={method}
      onSuccess={(result) => {
        const builder = new ChangeRequestBuilder();
        const dto = result?.data as ChangeRequestDto;
        const model = builder.toModel(dto);
        dispatch(loadChangeRequestSuccessAction(model));
        if (onSuccess) onSuccess(model);
      }}
      onFailure={onFailure}
    />
  );
};

export default AddChangeRequestForm;
