import { injectTranslations, injectReducer } from 'ia-react-core';
import { FC } from 'react';
import { compose } from 'redux';
import CreateIllustrationPage, { CreateIllustrationPageProps } from './CreateIllustrationPage';
import en from './translations/en';
import fr from './translations/fr';
import createIllustrationReducer from '../../components/CreateIllustration/CreateIllustration.reducer';

export default compose<FC<CreateIllustrationPageProps>>(
  injectTranslations('CreateIllustrationPage', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  injectReducer({
    key: 'pages.CreateIllustrationPage',
    reducer: createIllustrationReducer,
    initialState: {},
    useScope: true,
    cleanState: true,
  }),
)(CreateIllustrationPage);

export * from './CreateIllustrationPage';
