import { FC } from 'react';
import { injectTranslations } from 'ia-react-core';
import { compose } from 'redux';
import fr from '../../translations/fr';
import en from '../../translations/en';
import AppVersioning, { AppVersioningProps } from './AppVersioning';

export default compose<FC<AppVersioningProps>>(
  injectTranslations('CreateIllustration', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(AppVersioning);

export * from './AppVersioning';
