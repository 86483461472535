import { Language } from '~/interfaces/InsurabilityDeclaration/enums';

function getPathName(formName: string, language: number) {
  if (language === Language.English) {
    return formName === 'applicant' ? 'applicant' : 'echange/changes';
  }
  return formName === 'applicant' ? 'contractant' : 'echange/changements';
}

export default getPathName;
