import { Drawer, DrawerProps } from 'ia-react-core';
import React, { useId } from 'react';
import './ResponsiveDrawer.css';

interface ResponsiveDrawerProps extends DrawerProps {}

const DRAWER_BASE_ID = 'responsive-drawer';

const ResponsiveDrawer: React.FC<ResponsiveDrawerProps> = (props) => {
  const id = useId();
  return (
    <Drawer
      {...props}
      id={`${DRAWER_BASE_ID}-${id}`}
      noOverlay
      headerBottomBorder
      headerPadding="24px 16px"
    />
  );
};

export default ResponsiveDrawer;
