import displayDefaultDate from '~/fragments/IllustrationFragment/utilities/displayDefaultDate';
import { PcToolDataResponseModel } from '../interfaces/PcToolDataResponseModel';
import { AMOUNT, PREMIUM, RiderPlanCodes } from '../constants';

const getPCToolFormInitialValues = (data: PcToolDataResponseModel) =>
  ({
    dob: displayDefaultDate(data.insureds[0]),
    age: data.insureds[0].entiteNommee.ageIllustration,
    gender: data.insureds[0].entiteNommee.gender,
    isSmoker: data.insureds[0].entiteNommee.isSmoker,
    amountPremiumToggle: data.isPremiumMode ? PREMIUM : AMOUNT,
    enteredAmount: data.faceAmount,
    enteredPremium: data.premium,
    paymentFrequency: data.paymentFrequency,
    province: data.province,
    selectedPlanCode: data.selectedPlanCode,
    flexibleReturnPeriod: RiderPlanCodes.flexReturnFifteen,
  });

export default getPCToolFormInitialValues;
