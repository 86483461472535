import React from 'react';
import {
  Drawer, SideNav, RestartAltIcon, ContentCopyIcon, AddCircleIcon, useScopedSelector, openModalByName,
} from 'ia-react-core';
import { ReactComponent as DeleteOutlineIcon } from '~/assets/Icons/deleteOutline.svg';
import { useTranslation } from 'react-i18next';
import { IllustrationFragmentState } from '~/interfaces/IllustrationFragmentState';
import useScenario from '~/fragments/IllustrationFragment/hooks/useScenario';
import { useDispatch } from 'react-redux';
import { dispatchResourceByName } from '~/utilities/dispatchResourceByName';
import constructScenarioActionName from '~/utilities/constructScenarioActionName';
import { useParams } from 'react-router';
import { ADD } from '~/constants/APP_CONSTANTS';
import { StyledScenarioDrawerButtonSection, StyledScenarioDrawerSecondaryTextIconButton, StyledScenarioDrawerSection, StyledScenarioDrawerSideNavLink } from './ScenarioDrawer.styles';
import { isDrawerLinkActive } from './utilities/isDrawerLinkActive';

export interface ScenarioDrawerProps {
  name: string;
  title: string;
}

const ScenarioDrawer = ({ name, title }: ScenarioDrawerProps) => {
  const { saleId } = useParams<{ saleId: string }>();
  const { t } = useTranslation('App');
  const dispatch = useDispatch();
  const currentScenario = useScenario();
  const scenarios = useScopedSelector((state: IllustrationFragmentState) => state?.data?.scenarios);
  const scenarioManagement = useScopedSelector((state: IllustrationFragmentState) => state?.data?.manageScenario);
  const scenarioActionStorageName = constructScenarioActionName(saleId);

  return (
    <Drawer
      drawerTitle={title}
      name={name}
      noContentPadding
    >
      <StyledScenarioDrawerSection>
        <SideNav className="sideNav_drawer_styling">
          {
            scenarios?.map((scenario, index) => (
              <StyledScenarioDrawerSideNavLink
                key={`Scenario${scenario?.idDemande}}`}
                to={`coverage?scenarioId=${scenario?.idDemande}`}
                isActive={isDrawerLinkActive(scenario?.idDemande, currentScenario?.idDemande)}
              >
                {t('scenario')} {index + 1}
              </StyledScenarioDrawerSideNavLink>
            ))
          }
        </SideNav>

        <StyledScenarioDrawerButtonSection>
          {scenarioManagement?.isDeleteScenarioVisible && (
            <StyledScenarioDrawerSecondaryTextIconButton
              LeftIcon={DeleteOutlineIcon}
              onClick={() => dispatch(openModalByName('modals.confirmDeleteScenario'))}
            >
              {t('delete')}
            </StyledScenarioDrawerSecondaryTextIconButton>
          )}

          {scenarioManagement?.isCopyScenarioVisible && (
            <StyledScenarioDrawerSecondaryTextIconButton
              LeftIcon={ContentCopyIcon}
              onClick={dispatchResourceByName('resources.copyScenario', dispatch)}
            >
              {t('copy')}
            </StyledScenarioDrawerSecondaryTextIconButton>
          )}

          {scenarioManagement?.isResetScenarioVisible && (
            <StyledScenarioDrawerSecondaryTextIconButton
              LeftIcon={RestartAltIcon}
              onClick={() => dispatch(openModalByName('modals.confirmResetScenario'))}
            >
              {t('reset')}
            </StyledScenarioDrawerSecondaryTextIconButton>
          )}

          {scenarioManagement?.isAddScenarioVisible && (
            <div>
              <StyledScenarioDrawerSecondaryTextIconButton
                LeftIcon={AddCircleIcon}
                onClick={() => {
                  localStorage.setItem(scenarioActionStorageName, ADD);
                  dispatch(openModalByName('modals.createIllustration', { updateBaseCoverage: false }));
                }}
              >
                {t('add')}
              </StyledScenarioDrawerSecondaryTextIconButton>
            </div>
          )}
        </StyledScenarioDrawerButtonSection>
      </StyledScenarioDrawerSection>
    </Drawer>
  );
};

export { ScenarioDrawer };
