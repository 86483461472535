export default {
  fatalError: 'A technical error has occurred. We are sorry for any inconvenience this may cause you.',
  advisorTable: {
    advisors: 'Advisor(s)',
    ok: 'Ok',
  },
  common: {
    addAnAdvisor: 'Add an advisor',
    agentCode: 'Agent code',
    commissionDistribution: 'Commission distribution',
    defaultAdvisor: 'Default advisor',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
  },
  dataTable: {
    name: 'Name',
    role: 'Role',
    afterSaleService: 'After sales service',
    default: 'Default',
    edit: 'Edit',
    delete: 'Delete',
  },
  delete: {
    confirm: 'Please click on Yes to delete this advisor. You will not able to cancel the change.\nOk to delete?',
  },
  form: {
    modifyAnAdvisor: 'Modify an advisor',
    modifyTooltip: 'These changes will not apply for the current file, and will apply for future files. If you wish to apply these changes to the current file, you must make the modification in the “Report” screen or the application.',
    identificationAndContactInfo: 'Identification and contact information',
    professionalTitle: 'Professional designation',
    lastName: 'Last name',
    firstName: 'First name',
    agency: 'Agency',
    cellPhone: 'Cell phone',
    cellPhoneTooltip: 'Please supply us with your cell phone number in order to receive notifications.',
    workPhone: 'Work phone',
    extension: 'Extension',
    fax: 'Fax',
    emailAddress: 'Email address',
    compensationAndAfterSalesService: 'Compensation and after sales service',
    afterSalesService: 'Is this advisor going to give after sales service?',
    addAsDefault: 'Do you want to add this default advisor to your reports and applications?',
    save: 'Save',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    back: 'Back',
    optional: 'optional',
    agentCodeRequired: 'Please enter the agent code.',
    agentLastNameRequired: 'Please enter the advisor last name.',
    agentCodeSixDigit: 'The agent code must contain six digits.',
    agentCodeExists: 'An advisor with this agent code already exists.',
    workPhoneFormat: 'The work phone number must be in a valid format.',
    cellPhoneFormat: 'The cell phone number must be in a valid format.',
    faxNumberFormat: 'The fax number must be in a valid format.',
    phoneExtension: 'The phone extension cannot be saved if no office number has been supplied.',
    emailMessage: 'The email address does not respect the validation criteria.',
    error: 'Error',
    warning: 'Warning',
    commissionDistributionMax: 'Commission distribution must be equal to or less than 100 %.',
    commissionDistribution: 'Please indicate commission distribution.',
  },
  anchorWarning: 'See warning',
  anchorError: 'See error',
};
