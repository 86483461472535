import React, { useEffect, useState } from 'react'; import {
  Form, FormCol, FormRow, Modal, InfoTooltip,
  NumberControl,
  Resource,
  requestResourceByName,
  ResourceLoader,
  RadioListControl,
  closeModalByName,
  ErrorMessage,
  PrimarySubmitButton,
  SecondaryActionButton,
  useScopedSelector,
  DropdownControl,
  FixedKeyboardEvent,
} from 'ia-react-core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { State } from '~/interfaces/State';
import { IllustrationConfigurationModalState, IllustrationConfig, IllustrationValues, IllustrationConfigResponse } from '~/interfaces/IllustrationConfigurationModalState';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { AxiosResponse } from 'axios';
import COVERAGE_NAMES from '~/fragments/IllustrationFragment/pages/CoveragesPage/constants/COVERAGE_NAMES';
import { Locale, useCurrentLocale } from '~/hooks/useCurrentLocale';
import formatNumericAndTrimLastCharacter from '~/utilities/formatNumericAndTrimLastCharacter';
import { DEFAULT_EN_VALUE, DEFAULT_FR_VALUE, ESTATE_BOND_ALLOWED_KEYBOARD_KEYS, ESTATE_BOND_NUMBER_REGEX_EN, ESTATE_BOND_NUMBER_REGEX_FR } from '~/fragments/IllustrationFragment/pages/ConceptsPage/constants/constants';
import { StyledFormCol, StyledT2, StyledIllustrationSettingsModalActionBar, StyledIllustrationSettingsModalDividerContainer, StyledIllustrationSettingsModalDivider, StyledIllustrationSettingsModalFooterContainer, StyledIllustrationSettingsModalDescription } from './IllustrationSettingsModal.styles';

export interface IllustrationConfigurationModalProps {
  data: IllustrationConfigurationModalState;
}

const IllustrationSettingsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('IllustrationSettingsModal');
  const provinces = useScopedSelector((state: { data: IllustrationConfigurationModalState}) => state?.data?.provinces);
  const [error, setError] = useState({ isError: false, errorMessage: '' });
  const [param, setParam] = useState({ type: null, value: '' });
  const lang = useSelector((state: State) => state?.language?.locale ?? 'fr');
  const provinceOptions = provinces ? provinces.map((item) => ({ label: lang === 'fr' ? item?.description_FR : item.description_EN, value: item?.value }))?.sort((a, b) => a.label.localeCompare(b.label)) : [];
  const locale = useCurrentLocale();

  const handleChange = (label: number, value: string) => {
    setParam({ type: label, value });
  };

  const handleValueStateFormatter = () => [{ deferred: true, formatter: (val: string | number) => handleFormatter(val.toString()) }];

  const handleFormatter = (val: string) => {
    if (locale === Locale.EN && val === '.') {
      return val.replace('.', DEFAULT_EN_VALUE);
    }

    if (locale === Locale.FR && val === ',') {
      return val.replace(',', DEFAULT_FR_VALUE);
    }
    return val;
  };

  const handleKeyValidation = (event: FixedKeyboardEvent | React.KeyboardEvent<HTMLDivElement>): void => {
    const { key } = event;
    const numericRegex = locale === Locale.EN ? ESTATE_BOND_NUMBER_REGEX_EN : ESTATE_BOND_NUMBER_REGEX_FR;
    if (ESTATE_BOND_ALLOWED_KEYBOARD_KEYS.includes(key)) return;
    if ((event.ctrlKey || event.metaKey) && key === 'a') return;
    if (event.ctrlKey && key === 'v') return;
    if (!numericRegex.test(key)) event.preventDefault();
  };

  useEffect(() => {
    if (param.type !== null) {
      dispatch(requestResourceByName('resources.illustrationConfigType', { ...param }, { method: 'POST' }));
    }
  }, [dispatch, param]);

  const handleSubmit = (data: IllustrationValues) => {
    const payload = {
      province: data?.province,
      premiumPaymentFrequency: data.paymentFrequency,
      bonusPercentage: Number(`${data?.compensationBonus}`?.replace(',', '.')),
    };
    dispatch(requestResourceByName('resources.illustrationConfigPost', { ...payload }, { method: 'POST' }));
  };

  return (
    <Modal name="illustrationSettings" isClosable>
      <Resource
        autoRequest
        name="resources.illustrationConfig"
        method="GET"
        url={getBffUrlForRouteWithoutQP(BffRoute.illustration_configuration, undefined, false)}
      />
      <Resource
        name="resources.illustrationConfigPost"
        method="POST"
        url={getBffUrlForRouteWithoutQP(BffRoute.illustration_configuration, undefined, false)}
        onSuccess={(res: AxiosResponse<IllustrationConfigResponse>) => {
          const { data } = res;
          if (data?.saveStatus) {
            setError({ errorMessage: '', isError: false });
            dispatch(requestResourceByName(`resources.${COVERAGE_NAMES.VALIDATE_CALCULATE_NAME}`));
            dispatch(closeModalByName('modals.illustrationSettings'));
          } else {
            const message = lang === 'fr' ? data?.validationMessages[0]?.messageFR : data?.validationMessages[0]?.messageEN;
            setError({ errorMessage: message, isError: true });
          }
        }}
      />
      <Resource
        name="resources.illustrationConfigType"
        method="POST"
        url={getBffUrlForRouteWithoutQP(BffRoute.illustration_configuration_type, { type: param.type }, false)}
      />
      <StyledT2>{t('illustration_configuration')}</StyledT2>
      <ResourceLoader resourceSrc="resources.illustrationConfig">
        {({ data }: { data: IllustrationConfig }) => (
          <Form
            name="illustrationConfigurationForm"
            initialValues={{ paymentFrequency: data?.premiumPaymentFrequency, compensationBonus: data?.bonusPercentage, province: data?.province }}
            onSubmit={handleSubmit}
          >
            {error?.isError &&
            <FormRow>
              <FormCol col={12}>
                <ErrorMessage errorTitle={t('error')} error={error.errorMessage} data-testid="error-message" />
              </FormCol>
            </FormRow>}
            <FormRow>
              <FormCol label={t('province')} width-sm="300px">
                <DropdownControl
                  data-testid="province"
                  name="province"
                  noEmptyValue
                  options={provinceOptions ?? []}
                  onValueChange={(val) => handleChange(1, val.toString())}
                />
              </FormCol>
            </FormRow>

            <FormRow>
              <FormCol col={12} label={t('premiums_payment_frequency')}>
                <RadioListControl
                  name="paymentFrequency"
                  data-testid="paymentFrequency"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(2, e.target.value)}
                  options={[
                    { label: t('monthly'), value: 1 },
                    { label: t('annually'), value: 3 },
                  ]}
                  horizontal
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <StyledFormCol label={t('compensation_bonus')} tip={<InfoTooltip tip={t('bonus_info')} />}>
                <NumberControl
                  data-testid="compensationBonus"
                  name="compensationBonus"
                  defaultValue={0}
                  forceDecimals
                  precision={2}
                  onKeyDown={handleKeyValidation}
                  onFocus={(event) => event.target.select()}
                  rightSign="%"
                  inputWidth={133.33}
                  maxLength={6}
                  valueToStateFormatters={handleValueStateFormatter()}
                  forceInvalid={error.isError}
                  formatValueToState={(value) => formatNumericAndTrimLastCharacter(value, locale)}
                />
              </StyledFormCol>
            </FormRow>

            <StyledIllustrationSettingsModalFooterContainer>
              <StyledIllustrationSettingsModalDescription>{t('configuration_note')}</StyledIllustrationSettingsModalDescription>

              <StyledIllustrationSettingsModalDividerContainer>
                <StyledIllustrationSettingsModalDivider />
              </StyledIllustrationSettingsModalDividerContainer>

              <StyledIllustrationSettingsModalActionBar flexButtonsXs>
                <PrimarySubmitButton data-testid="save-setting-btn">{t('save')}</PrimarySubmitButton>
                <SecondaryActionButton onClickAction="modals.illustrationSettings.closeModal">{t('cancel')}</SecondaryActionButton>
              </StyledIllustrationSettingsModalActionBar>
            </StyledIllustrationSettingsModalFooterContainer>
          </Form>
        )}
      </ResourceLoader>
    </Modal>
  );
};

export default IllustrationSettingsModal;
