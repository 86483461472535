import { RouteDefinition, RouteDefinitions, RouteSectionDefinition } from 'ia-react-core';

export enum SIGNATURE_ROUTES_KEY {
  SIGNATURE_MODE = 'signatureMode',
  SIGNATURE_FOLLOWUP = 'signatureFollowup',
}

export const signatureRoutes: Record<SIGNATURE_ROUTES_KEY, RouteSectionDefinition | RouteDefinition> = {
  [SIGNATURE_ROUTES_KEY.SIGNATURE_MODE]: {
    label: { en: 'Signature Mode', fr: 'Mode de signature' },
    path: { en: '/application/:saleId/signature/signature-mode', fr: '/application/:saleId/signature/signature-mode' },
    loader: () => import('~/fragments/ApplicationFragment/pages/SignaturePage/pages/SignatureMode'),
  },
  [SIGNATURE_ROUTES_KEY.SIGNATURE_FOLLOWUP]: {
    label: { en: 'Signature and Follow-Up', fr: 'Signature et suivi' },
    path: { en: '/application/:saleId/signature/signature-followup', fr: '/application/:saleId/signature/signature-followup' },
    loader: () => import('~/fragments/ApplicationFragment/pages/SignaturePage/pages/SignatureFollowup'),
  },
};

const SIGNATURE_ROUTES: RouteDefinitions = Object.values(signatureRoutes);

export default SIGNATURE_ROUTES;
