import { Action } from 'redux';
import { FormClientErrors } from '~/interfaces/FormClientErrors';
import { ValidatorConfig } from 'ia-react-core';
import logger from '~/utilities/logger';
import { ValidationMessageServerLevel } from '~/interfaces/ValidationMessageServerLevel';
import { FormSubStateNominee } from '~/interfaces/FormSubStateNominee';
import { SetValidationMessagesAction } from '../ApplicationFragment.actions';

interface validationDetails {
  isFormValidaton: boolean;
  validationType: 'formErrors' | 'formWarnings' | 'formCautions' | 'controlErrors' | 'controlWarnings' | 'controlCautions';
}

export const validationToFormClientProp = (controlName: string, val: ValidationMessageServerLevel): validationDetails => {
  const isFormValidaton = controlName.startsWith('custom_') || controlName.endsWith('ViewModels') || controlName.includes('|');
  const validationType = isFormValidaton ? 'form' : 'control';

  switch (val) {
    case ValidationMessageServerLevel.Warning:
      return {
        isFormValidaton,
        validationType: `${validationType}Warnings`,
      };
    case ValidationMessageServerLevel.Notice:
      return {
        isFormValidaton,
        validationType: `${validationType}Cautions`,
      };
    case ValidationMessageServerLevel.Error:
    default:
      return {
        isFormValidaton,
        validationType: `${validationType}Errors`,
      };
  }
};

export default function setValidationMessagesNominee<Value>(state: FormSubStateNominee<Value>, action: Action): FormSubStateNominee<Value> {
  const { validatorMap, page, formName, stateName } = action as SetValidationMessagesAction<any>;
  const selectedPage = { ...state?.NomineePage };
  const currentForm = { ...selectedPage.pages?.[stateName as keyof typeof selectedPage.pages] };
  const selectedForm = { ...currentForm.forms?.[formName] };
  if (!selectedForm) {
    logger.error(`Unable to access form name '${page}=>${selectedForm}'`);
  }

  const forms = { ...currentForm.forms };
  const pages = { ...selectedPage.pages };
  const { [formName]: _form, ...restForms } = forms;
  const { [stateName as keyof typeof selectedPage.pages]: _page, ...restPages } = pages;

  const clientErrors: FormClientErrors<Value> = Object.entries(validatorMap).reduce((acc, [name, validator]) => {
    const previousValue = { ...acc };
    const norValidator = validator as ValidatorConfig<Value>;
    const [controlName, ruleName] = name.split('.');

    const clientFormSectionName = validationToFormClientProp(controlName, norValidator.type as unknown as ValidationMessageServerLevel);
    const previousSectionValue = previousValue?.[clientFormSectionName.validationType];
    const previousControlSectionValue = previousSectionValue?.[controlName as keyof typeof previousSectionValue];

    if (clientFormSectionName.isFormValidaton) {
      return {
        ...previousValue,
        [clientFormSectionName.validationType]: {
          ...previousSectionValue,
          [`ValidationRule-${ruleName}`]: norValidator.message,
        },
      };
    }

    return {
      ...previousValue,
      [clientFormSectionName.validationType]: {
        ...previousSectionValue,
        [controlName]: { ...previousControlSectionValue, [`ValidationRule-${ruleName}`]: norValidator.message },
      },
    };
  }, {} as FormClientErrors<Value>);

  selectedForm.clientErrors = clientErrors;
  forms[formName] = selectedForm;

  return {
    ...state,
    NomineePage: {
      ...selectedPage,
      pages: {
        ...restPages,
        [stateName as keyof typeof selectedPage.pages]: {
          ...selectedPage.pages?.[stateName as keyof typeof selectedPage.pages],
          forms: {
            ...restForms,
            [formName]: {
              ...selectedForm,
            },
          },
        },
      },
    },
  };
}
