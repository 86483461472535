import { Action, Reducer } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import {
  FATAL_ERROR_TYPE, LOAD_APPLICATION_SUCCESS_TYPE, LOAD_APPLICATION_TYPE, SET_LOADING_TYPE, SET_TARGET_ROUTE_TYPE, SET_UPDATING_TYPE, UpdateAccountHolder, UpdateAccountHolders, UPDATE_NOMINEE_TYPE, SET_VALIDATION_MESSAGES_TYPE, SET_VALIDATION_MESSAGES_NOMINEE_TYPE, SET_VALIDATION_MESSAGES_LINKED_APPLICATION_TYPE, SET_VALIDATION_MESSAGES_VALIDATION_TYPE, SET_VALIDATION_MESSAGES_NAME_VALIDATION_TYPE, SET_PAGE_VALIDATION_MESSAGES_TYPE, UPDATE_SIGNATURE_CONFIGURE_TYPE, UPDATE_SIGNATURE_PAPER_FORM_TYPE, UPDATE_SIGNATURE_ELECTRONIC_FORM_TYPE, UPDATE_APPLICANT_VALIDATION_MESSAGES, UPDATE_ADDITIONAL_FORM_SIGNER_FULLNAME, ADD_MAIN_SIGNER_ROLE_TYPE, DELETE_MAIN_SIGNER_ROLE_TYPE, ADD_ADDITIONAL_SIGNER_ROLE_TYPE, DELETE_ADDITIONAL_SIGNER_ROLE_TYPE,
  SET_ACCESS_LIFE_ELIGIBILITY_TYPE,
  SET_INS_VALIDATION_MESSAGES_TYPE,
  SET_VALIDATE_CLICKED_TYPE,
  UPDATE_PACPAD_VALIDATION_MESSAGES,
  IS_APPLICATION_FETCHED,
  SET_NUMBER_OF_FORMS_SELECTED,
} from '../ApplicationFragment.actions';
import loadApplicationReducer from './loadApplicationReducer';
import setLoading from './setLoading';
import fatalError from './fatalError';
import loadApplicationSuccessReducer from './loadApplicationSuccessReducer';
import updateNomineeReducer from './updateNomineeReducer';
import setUpdating from './setUpdating';
import setTargetRoute from './setTargetRoute';
import { updateAccountHoldersReducer } from './updateAccountHoldersReducer';
import { updateAccountHolderReducer } from './updateAccountHolderReducer';
import setValidationMessages from './setValidationMessages';
import setValidationMessagesNominee from './setValidationMessagesNominee';
import setValidationMessagesLinkedApplications from './setValidationMessagesLinkedApplications';
import setValidationMessagesValidationPage from './setValidationMessagesValidationPage';
import setValidationMessagesNameValidationPage from './setValidationMessagesNameValidationPage';
import setPageValidationMessages from './setPageValidationMessages';
import { updateSignatureConfigureReducer } from './signatureReducer';
import { updateSignaturePaperFormReducer } from './SignaturePaper.reducer';
import { addAdditionalSignerRoleReducer, addMainSignerRoleReducer, deleteAdditionalSignerRoleReducer, deleteMainSignerRoleReducer, updateAdditionalFormSignerFullName, updateSignatureElectronicFormReducer } from './SignatureElectronic.reducer';
import setApplicantValidationMessagesReducer from './setApplicantValidationMessagesReducer';
import setAccessLifeEligibilityReducer from './setAccessLifeEligibilityReducer';
import setInsValidationMessages from './setInsValidationMessages';
import setValidate from './setValidate';
import setPacpadValidationMessagesReducer from './setPacpadValidationMessagesReducer';
import isApplicationDataFetched from './isApplicationDataFetched';
import SetNumberOfForms from './SetNumberOfForms';

const mapping: Record<string, Reducer<any, any>> = {
  [LOAD_APPLICATION_TYPE]: loadApplicationReducer,
  [LOAD_APPLICATION_SUCCESS_TYPE]: loadApplicationSuccessReducer,
  [UPDATE_NOMINEE_TYPE]: updateNomineeReducer,
  [SET_TARGET_ROUTE_TYPE]: setTargetRoute,
  [SET_LOADING_TYPE]: setLoading,
  [IS_APPLICATION_FETCHED]: isApplicationDataFetched,
  [SET_UPDATING_TYPE]: setUpdating,
  [FATAL_ERROR_TYPE]: fatalError,
  [UpdateAccountHolders.type]: updateAccountHoldersReducer,
  [UpdateAccountHolder.type]: updateAccountHolderReducer,
  // Reducer from parent
  [SET_VALIDATION_MESSAGES_TYPE]: setValidationMessages,
  [SET_PAGE_VALIDATION_MESSAGES_TYPE]: setPageValidationMessages,
  [SET_VALIDATION_MESSAGES_NOMINEE_TYPE]: setValidationMessagesNominee,
  [SET_VALIDATION_MESSAGES_LINKED_APPLICATION_TYPE]: setValidationMessagesLinkedApplications,
  [SET_VALIDATION_MESSAGES_VALIDATION_TYPE]: setValidationMessagesValidationPage,
  [SET_VALIDATION_MESSAGES_NAME_VALIDATION_TYPE]: setValidationMessagesNameValidationPage,
  [UPDATE_SIGNATURE_CONFIGURE_TYPE]: updateSignatureConfigureReducer,
  [UPDATE_SIGNATURE_PAPER_FORM_TYPE]: updateSignaturePaperFormReducer,
  [UPDATE_SIGNATURE_ELECTRONIC_FORM_TYPE]: updateSignatureElectronicFormReducer,
  [UPDATE_APPLICANT_VALIDATION_MESSAGES]: setApplicantValidationMessagesReducer,
  [UPDATE_ADDITIONAL_FORM_SIGNER_FULLNAME]: updateAdditionalFormSignerFullName,
  [ADD_MAIN_SIGNER_ROLE_TYPE]: addMainSignerRoleReducer,
  [DELETE_MAIN_SIGNER_ROLE_TYPE]: deleteMainSignerRoleReducer,
  [ADD_ADDITIONAL_SIGNER_ROLE_TYPE]: addAdditionalSignerRoleReducer,
  [DELETE_ADDITIONAL_SIGNER_ROLE_TYPE]: deleteAdditionalSignerRoleReducer,
  [SET_ACCESS_LIFE_ELIGIBILITY_TYPE]: setAccessLifeEligibilityReducer,
  [SET_INS_VALIDATION_MESSAGES_TYPE]: setInsValidationMessages,
  [SET_VALIDATE_CLICKED_TYPE]: setValidate,
  [UPDATE_PACPAD_VALIDATION_MESSAGES]: setPacpadValidationMessagesReducer,
  [SET_NUMBER_OF_FORMS_SELECTED]: SetNumberOfForms,

};

export default function reducer(state: ApplicationFragmentState, action: Action) {
  const typeReducer = mapping[action.type] ?? ((x) => x);
  return typeReducer(state, action);
}
