import { RouteDefinition, RouteDefinitions, RouteSectionDefinition } from 'ia-react-core';

export enum NOMINEE_ROUTES_KEY {
  CONSENT = 'consent',
  IDENTIFICATION = 'identification',
  INSURANCE_HISTORY = 'insuranceHistory',
  DECLARATIONS_BASE = 'declarationsBase',
  DECLARATIONS = 'declarations',
  BENEFICIARY = 'beneficiary',
  SPECIAL_INSTRUCTIONS = 'specialinstructions',
  RESULTS = 'results',
}

export const nomineeRoutes: Record<NOMINEE_ROUTES_KEY, RouteSectionDefinition | RouteDefinition> = {
  [NOMINEE_ROUTES_KEY.CONSENT]: {
    label: { fr: 'Consentements', en: 'Consents' },
    path: { fr: '/application/:saleId/candidat/:clientId/consentement', en: '/application/:saleId/nominee/:clientId/consent' },
    loader: () => import('../fragments/ApplicationFragment/pages/NomineePage/pages/ConsentPage'),
  },
  [NOMINEE_ROUTES_KEY.IDENTIFICATION]: {
    label: { fr: 'Identification', en: 'Identification' },
    path: { fr: '/application/:saleId/candidat/:clientId/identification', en: '/application/:saleId/nominee/:clientId/identification' },
    loader: () => import('../fragments/ApplicationFragment/pages/NomineePage/pages/IdentificationPage'),
  },
  [NOMINEE_ROUTES_KEY.INSURANCE_HISTORY]: {
    label: { fr: "Historique d'assurance", en: 'Insurance History' },
    path: { fr: '/application/:saleId/candidat/:clientId/histoire-assurance', en: '/application/:saleId/nominee/:clientId/insurance-history' },
    loader: () => import('../fragments/ApplicationFragment/pages/NomineePage/pages/InsuranceHistoryPage'),
  },
  [NOMINEE_ROUTES_KEY.DECLARATIONS_BASE]: {
    label: { fr: 'Déclarations', en: 'Declarations' },
    path: { fr: '/application/:saleId/candidat/:clientId/declarations', en: '/application/:saleId/nominee/:clientId/declarations' },
    exact: true,
    loader: () => import('../fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration'),
  },
  [NOMINEE_ROUTES_KEY.DECLARATIONS]: {
    label: { fr: 'Déclarations', en: 'Declarations' },
    path: { fr: '/application/:saleId/candidat/:clientId/declarations/:formName', en: '/application/:saleId/nominee/:clientId/declarations/:formName' },
    loader: () => import('../fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration'),
  },
  [NOMINEE_ROUTES_KEY.BENEFICIARY]: {
    label: { fr: 'Bénéficiaire(s)', en: 'Beneficiary(ies)' },
    path: { fr: '/application/:saleId/candidat/:clientId/beneficiaire', en: '/application/:saleId/nominee/:clientId/beneficiary' },
    loader: () => import('../fragments/ApplicationFragment/pages/NomineePage/pages/BeneficiaryPage'),
  },
  [NOMINEE_ROUTES_KEY.SPECIAL_INSTRUCTIONS]: {
    label: { fr: 'Instructions spéciales', en: 'Special instructions' },
    path: { fr: '/application/:saleId/candidat/:clientId/instructions-speciales', en: '/application/:saleId/nominee/:clientId/special-instructions' },
    loader: () => import('../fragments/ApplicationFragment/pages/NomineePage/pages/SpecialInstructionsPage'),
  },
  [NOMINEE_ROUTES_KEY.RESULTS]: {
    label: { fr: 'Resultats', en: 'Results' },
    path: { fr: '/application/:saleId/candidat/:clientId/resultats', en: '/application/:saleId/nominee/:clientId/results' },
    loader: () => import('../fragments/ApplicationFragment/pages/NomineePage/pages/ResultsPage'),
  },
};

const NOMINEE_ROUTES: RouteDefinitions = Object.values(nomineeRoutes);

export default NOMINEE_ROUTES;
