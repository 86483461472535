import { Action } from 'redux';
import { AppState } from '~/interfaces/AppState';
import { SetFormId, SetUseTobacco } from '../ChangeRequest.actions';

export function clearInsured(state: AppState) {
  return {
    ...state,
    values: {
      ...state.App?.fragments.ApplicationFragment.values,
      insured: {
      },
    },
  };
}

export function setFormId(state: AppState, action: Action) {
  const { selectedFormId } = action as SetFormId;
  return {
    ...state,
    selectedFormId,
  };
}

export function setUseTobacco(state: AppState, action: Action) {
  const { useTobacco } = action as SetUseTobacco;
  return {
    ...state,
    useTobacco,
    data: {
      ...state.data,
      insured: {
        ...state.data?.insured,
        useTobacco,
      },
    },
  };
}
