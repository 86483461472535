import { FC } from 'react';
import { compose } from 'redux';
import { injectTranslations } from 'ia-react-core';
import fr from './translations/fr';
import en from './translations/en';
import HoistedServerErrors, { HoistedServerErrorsProps } from './HoistedServerErrors';

export default compose<FC<HoistedServerErrorsProps>>(
  injectTranslations('HoistedServerErrors', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(HoistedServerErrors);

export * from './HoistedServerErrors';
