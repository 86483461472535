const ROUTENAMES = {
  SIGNATUREMODE: 'signature mode',
  SIGNATUREFOLLOWUP: 'signature followup',
  CHANGES: 'changes',
  ADVIS0R: 'advisor(s)',
  SUMMARY: 'summary',

};

export default ROUTENAMES;
