import { Action } from 'redux';
import { ChangeRequestState } from '~/fragments/SharedLibrary/interfaces/State';
import { SetCurrentTab } from '../ChangeRequest.actions';

export default function setCurrentTab(state: ChangeRequestState, action: Action) {
  const { currentTabIndex } = action as SetCurrentTab;
  return {
    ...state,
    currentPage: currentTabIndex,
  };
}
