import { requestResourceByName } from 'ia-react-core';
import { BillingPageState } from '~/interfaces/Billing/BillingPageState';
import { ApplicationIntentType, UPDATE_BILLING_RESOURCE_NAME } from '../constants';
import formatBillingPayloadDTO from '../pages/BillingPage/utilities/FormatBillingPayloadDTO';

const billingApiCall = (formvalues: BillingPageState, dispatch: Function) => {
  const validateRequest = formatBillingPayloadDTO(formvalues, ApplicationIntentType.SaveAndValidate);

  dispatch(requestResourceByName(`resources.${UPDATE_BILLING_RESOURCE_NAME}`, validateRequest));
};

export default billingApiCall;
