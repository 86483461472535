import { MIN_DATE_YEAR } from '~/constants/MIN_DATE_YEAR';
import { Language, QuestionType, ValidationMessageTypes } from '~/interfaces/InsurabilityDeclaration/enums';
import QuestionIdentityModel from '~/interfaces/InsurabilityDeclaration/QuestionIdentityModel';
import QuestionModel from '~/interfaces/InsurabilityDeclaration/QuestionModel';
import SectionIdentityModel from '~/interfaces/InsurabilityDeclaration/SectionIdentityModel';
import { InsurabilityDeclarationState } from '~/interfaces/InsurabilityDeclaration/State';

function getAllQuestions(sections: SectionIdentityModel[]) {
  let allQuestions: QuestionIdentityModel[] = [];
  if (sections) {
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      allQuestions = allQuestions.concat(section.questions);
      allQuestions = allQuestions.concat(getAllQuestions(section.sections));
    }
  }
  return allQuestions;
}
// Added custom DateField validation because DateField validation is not provide from Magnum API
export const validateDateYear = (state: InsurabilityDeclarationState, formId: string): InsurabilityDeclarationState => {
  const newState = { ...state };

  const currentForm = newState.case.parties?.[0].forms.filter((f) => f.id === formId)[0];

  if (!currentForm?.sections) {
    return state;
  }

  const allQuestions = getAllQuestions(currentForm.sections);

  // This will get all the questions of the current form that is Date Questions
  let answeredQuestions: QuestionModel[] = [];
  if (allQuestions.length > 0) {
    answeredQuestions = Object.values(newState.case.questions).filter(
      (que) => que.questionType === QuestionType.DateQuestion && que.answers.length > 0 && allQuestions.some((q) => q.id === que.id),
    );
  } else {
    answeredQuestions = Object.values(newState.case.questions).filter(
      (que) => que.questionType === QuestionType.DateQuestion && que.answers.length > 0,
    );
  }

  if (answeredQuestions.length > 0) {
    if (answeredQuestions) {
      const dateVal = answeredQuestions.flatMap((q) => q.answers.filter((a) => {
        const valueAsString = a.value.toString();
        return valueAsString !== '' && parseInt(valueAsString, 10) < MIN_DATE_YEAR;
      }));
      if (dateVal.length > 0) {
        const dateValidationMessage = {
          id: answeredQuestions[0].id,
          message: newState.case.language === Language.English ? 'Date entered is not valid.' : 'La date saisie n\'est pas valide.',
          validationLevel: ValidationMessageTypes.Error,
          targetItem: dateVal[0].id,
        };
        currentForm.validationMessages.push(dateValidationMessage);
        newState.showValidation = { ...newState.showValidation, [formId]: true };
      }
    }
  }
  return { ...newState };
};
