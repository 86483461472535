import { injectTranslations } from 'ia-react-core';
import { FC } from 'react';
import { compose } from 'redux';
import fr from './translations/fr';
import en from './translations/en';
import CreateIllustration, { CreateIllustrationProps } from './CreateIllustration';

export default compose<FC<CreateIllustrationProps>>(
  injectTranslations('CreateIllustration', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(CreateIllustration);

export * from './CreateIllustration';
