import { Resource, ResourceProps, WORKFLOW_STATUSES } from 'ia-react-core';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import selectTargetRoute from '~/fragments/ApplicationFragment/selectors/selectTargetRoute';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { setPageValidationMessagesAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';
import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';
import { updateRouteStatus } from '~/App.actions';
import { ApiResponse } from '~/interfaces/ValidationMessage';
import { State } from '~/interfaces/State';
import getStatusFromValidationMessages from '../utilities/getStatusFromValidationMessages';
import selectCurrentRoute from '../selectors/selectCurrentRoute';
import { HEADOFFICE_FORM_NAME, UPDATE_HEADOFFICE_RESOURCE_NAME } from '../pages/HeadOfficePage/constants/ConstantNames';

export interface SaveOrValidateHeadOfficeResourceProps extends ResourceProps<any, null> {
  onSuccess?: (res: any) => void;
}

const SaveOrValidateHeadOfficeResource: FC<SaveOrValidateHeadOfficeResourceProps> = ({ onSuccess, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { saleId } = useParams<{ saleId: string }>();
  let intentType: ApplicationIntentType = ApplicationIntentType.SaveAndValidate;
  let initateFromLeftMenu = true;

  const targetRoute: string = useSelector(selectTargetRoute);
  const currentRoute: string = useSelector(selectCurrentRoute);
  const updateHeadOfficeUrl = getBffUrlForRoute(BffRoute.update_head_office, { saleId });
  const backToHome = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);

  const defaultOnSuccess = (res: any) => {
    const { validationMessages } = (res?.data as ApiResponse);
    const messages = validationMessages || [];
    const status = getStatusFromValidationMessages(messages);

    if (backToHome) {
      history.block(true);
      history.push('/');
      return;
    }
    if (intentType === ApplicationIntentType.Validate || intentType === ApplicationIntentType.SaveAndValidate) {
      dispatch(setPageValidationMessagesAction('HeadOfficePage', HEADOFFICE_FORM_NAME, validationMessages, 'HeadOfficePage'));
      dispatch(updateRouteStatus(currentRoute, status));
    }

    if (intentType === ApplicationIntentType.SaveAndValidate) {
      if (status === WORKFLOW_STATUSES.ERROR) {
        history.push(currentRoute);
      } else if (!initateFromLeftMenu) { // if initiated from left menu, do not redirect/reload the target route
        history.push(targetRoute);
      }
    }
  };

  return (
    <Resource
      name={UPDATE_HEADOFFICE_RESOURCE_NAME}
      url={updateHeadOfficeUrl}
      method="PUT"
      transformRequest={(
        payloadData: { intentType: ApplicationIntentType; initateFromLeftMenu: boolean },
      ) => {
        intentType = payloadData.intentType;
        initateFromLeftMenu = payloadData.initateFromLeftMenu;

        return payloadData;
      }}
      onSuccess={onSuccess || defaultOnSuccess}
      {...props}
    />
  );
};

export default SaveOrValidateHeadOfficeResource;
