import { cloneDeep } from 'lodash';
import Action from '~/interfaces/actions';
import AnswerModel from '~/interfaces/InsurabilityDeclaration/AnswerModel';
import { AnswerQuestionsSuccess } from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/InsurabilityDeclaration.actions';
import { ChangeRequestState } from '../interfaces/State';

export default function answerQuestionSuccessReducer(state: ChangeRequestState, action: Action): ChangeRequestState {
  const { result, question, values } = action as AnswerQuestionsSuccess;
  const sections = result.reduce((acc, { id, isActive }) => {
    const item = state.changeRequest.sections[id];
    if (item && item.isActive !== isActive) {
      const modified = cloneDeep(item);
      modified.isActive = isActive;
      return { ...acc, [id]: modified };
    }
    return acc;
  }, state.changeRequest.sections);

  const questions = result.reduce((acc, { id, isActive }) => {
    const item = state.changeRequest.questions[id];
    if (item && item.isActive !== isActive) {
      const modified = cloneDeep(item);
      modified.isActive = isActive;
      return { ...acc, [id]: modified };
    }
    return acc;
  }, state.changeRequest.questions);

  const answeredQuestion = structuredClone(questions[question.id]);
  answeredQuestion.answers = values.map((v) => (new AnswerModel('NEW', v)));

  return {
    ...state,
    changeRequest: {
      ...state.changeRequest,
      sections,
      questions: { ...questions, [question.id]: answeredQuestion },
    },
  };
}
