import { requestResourceByName } from 'ia-react-core';
import { ApplicationIntentType } from '../constants';
import FormDataToPayload from '../pages/RequirementsPage/utilities/FormDataToPayload';
import { REQUIREMENT_FORM_DETAILS, SAVE_REQUIREMENTS_RESOURCE_NAME, UPDATE_REQUIREMENTS_RESOURCE_NAME } from '../pages/RequirementsPage/constants/ConstantNames';
import IndividualModel from '../interfaces/IndividualModel';
import { MedicalRequirementDirectiveViewModel, RequirementOrderDetailViewModel, Suppliers } from '../pages/RequirementsPage/interfaces';
import getUpdatedPayload from '../pages/RequirementsPage/utilities/getUpdatedPayload';

const requirementApiCall = (formvalues: any, applicantOrInsuredIndividuals: IndividualModel[], suppliers: Suppliers[], dispatch: Function) => {
  const emptyPayload = {
    requirementViewModel: {
      medicalRequirements: [] as RequirementOrderDetailViewModel[],
      medicalRequirementsDirective: null as MedicalRequirementDirectiveViewModel,
      investigationReports: [] as RequirementOrderDetailViewModel[],
      investigationReportsDirective: null as MedicalRequirementDirectiveViewModel,
    },
    intentType: ApplicationIntentType.Validate,
  };
  const hasRequirementDetails = formvalues && Object.keys(formvalues).some((key) => key.startsWith(REQUIREMENT_FORM_DETAILS));
  if (!hasRequirementDetails) {
    dispatch(requestResourceByName(`resources.${SAVE_REQUIREMENTS_RESOURCE_NAME}`, emptyPayload));
    return;
  }
  const payload = FormDataToPayload(formvalues, applicantOrInsuredIndividuals, suppliers);
  const validateRequest = {
    requirementViewModel: getUpdatedPayload(payload),
    intentType: ApplicationIntentType.SaveAndValidate,
  };
  dispatch(requestResourceByName(`resources.${UPDATE_REQUIREMENTS_RESOURCE_NAME}`, { ...validateRequest, initateFromLeftMenu: true }));
};

export default requirementApiCall;
