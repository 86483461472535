import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { CheckboxList, CheckboxListOption } from 'ia-react-core';
import { v4 as uuid } from 'uuid';
import QuestionField from './QuestionField';
import { StyledCheckboxListWrapper } from './QuestionField.styles';

const MultipleChoiceField: QuestionField = ({ question, onChange, hasError, hasWarning }) => {
  /*
  ia-react-core decided to escape the values we pass to it. Which means they don't match what we receive in the props
  so we generate a temporary value to use instead of the full human readable string
 */
  const answerMap = useMemo<Record<string, string>>(
    () =>
      question.possibleAnswers.reduce((acc: Record<string, string>, curr: string) => {
        const id = uuid();
        return ({ ...acc, [id]: curr, [curr]: id });
      }, {}),
    [question.possibleAnswers],
  );

  const options = useMemo<CheckboxListOption<string>[]>(() => question.possibleAnswers.map((possibleAnswer: string) => ({
    label: possibleAnswer,
    value: answerMap[possibleAnswer],
  })), [question.possibleAnswers, answerMap]);

  const values = useMemo(() => question.answers.map((answer) => answerMap[answer.value as string]), [question.answers, answerMap]);

  const handleChange = useCallback(({ target: { checked, value } }: ChangeEvent<HTMLInputElement>) => {
    const newValues = (checked ? [...values, value] : values.filter((x: string) => x !== value)).map((v: string) => answerMap[v]);
    onChange(newValues);
  }, [onChange, values, answerMap]);

  return (
    <StyledCheckboxListWrapper>
      <CheckboxList size={11} hasWarning={hasWarning} onChange={handleChange} value={values} options={options} noBox={!hasError && !hasWarning} isValid={!hasError} isTouched />
    </StyledCheckboxListWrapper>
  );
};

export default MultipleChoiceField;
