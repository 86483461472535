import { Action } from 'redux';
import PrintReportState from '~/interfaces/PrintReport';

const printReportModalReducer = (state: PrintReportState, action: Action): PrintReportState => {
  switch (action.type) {
    case 'TYPE_1':
    case 'TYPE_2':
    case 'TYPE_3':
    default:
      return state;
  }
};

export default printReportModalReducer;
