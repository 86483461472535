import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import {
  Col, DataErrorModal, ExternalLink, Hidden, InfoOutlineIcon, LaunchIcon, Resource, Row, Spacing, Visible, closeModalByName, openModalByName, requestResourceByName,
} from 'ia-react-core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CategoryProduct } from '~/interfaces/CategoryProduct';
import CreateIllustrationHelpModal from '~/components/CreateIllustrationHelpModal';
import { State } from '~/interfaces/State';
import { InsuranceGroup } from '~/interfaces/InsuranceGroup';
import { InsuranceProduct } from '~/interfaces/InsuranceProduct';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { ACCESS_LIFE_TEMPLATE_ID, LANGUAGE_ENGLISH, LANGUAGE_FRENCH, LOCALE_EN, MALADIE_GRAVE, OTHER_TERM_TEMPLATE_ID, getLearnMoreUrl } from '~/constants/APP_CONSTANTS';

import logger from '~/utilities/logger';
import { Scenario } from '~/interfaces/Scenario';
import getBaseProtections from '~/fragments/IllustrationFragment/pages/CoveragesPage/utilities/getBaseProtections';
import { CoveragesPageActions } from '~/fragments/IllustrationFragment/pages/CoveragesPage/CoveragesPage.actions';
import { clearCoverages } from '~/App.actions';
import { selectEffectiveDate, selectVersion } from '~/utilities/selectors';
import { getStringToFormattedDate } from '~/utilities/getFormattedDateVals';
import isHeadOfficeMode from '~/fragments/ApplicationFragment/utilities/isHeadOfficeMode';
import AddScenarioPayload from '~/fragments/IllustrationFragment/pages/CoveragesPage/interfaces/AddScenarioPayload';
import {
  StyledInsurancePageIconButton, StyledInsurancePageTitle, StyledInsurancePageContainer, StyledInsurancePageP, StyledInsurancePageSection, StyledInsurancePageTitleContainer, StyledInsurancePageMainSection, StyledInsurancePageTextButton, StyledInsurancePageToggleSwitch, StyledInsurancePageToggleSwitchContainer, StyledInsurancePageSectionsContainer,
} from './InsurancePage.styles';
import OtherTerm from './components/OtherTerm';
import { getLearnMoreLink, getTranslatedValue } from '../../utilities';
import UpdateLatestUsedProduct, { UPDATE_LATEST_PRODUCT } from './resources/UpdateLatestUsedProduct';
import getPageName from './utilities/pageDescriptionToName';
import { FUNCTION_TYPE, SHARED_OWNERSHIP } from './constants/InsurancePageConstants';
import orderInsuranceGroups from './utilities/orderInsuranceGroups';
import { CREATE_NEW_SALES_RESOURCE_NAME, UPDATE_BASE_COVERAGE_RESOURCE_NAME } from '../../CreateIllustration.constants';

export interface InsurancePageProps {
  data: CategoryProduct;
  transKey?: string;
  onCreateFile?: Function;
  isInModal?: boolean;
  recalculateCoverage?: () => void;
  getProductData?: () => void;
  page?: number;
  isAddScenario?: boolean;
  isIllustration?: boolean;
}

export interface RequestBodyProps {
  id: string;
  language: string;
  pdfPlanCode: string | { code: string; firstTerm: number; productVersionDate: string };
  IsCustomTerm?: boolean;
  calculationEffectiveDate?: Date;
  selectedVersionNumber?: string;
}

const homePageMobileProps = {
  xs: true,
  sm: true,
};

const illustrationMobileProps = {
  xs: true,
  sm: true,
  md: true,
  lg: true,
};

const InsurancePage: FC<InsurancePageProps> = ({
  data,
  transKey,
  onCreateFile,
  isInModal,
  recalculateCoverage,
  getProductData,
  isAddScenario,
  isIllustration,
}) => {
  const locale = useSelector((state: State) => state?.language?.locale);
  const { t } = useTranslation('CreateIllustration');
  const [renewableOptions, setRenewableOptions] = useState('level');
  const [modalData, setModalData] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const updateBaseCoverage = useSelector((state: State) => state?.App?.fragments?.IllustrationFragment?.Pages?.CoveragesPage?.modals?.createIllustration?.data?.updateBaseCoverage);
  const saleId = useSelector((state: State) => state?.App?.fragments?.IllustrationFragment?.Pages?.CoveragesPage?.modals?.createIllustration?.data?.saleId);
  const scenarioId = useSelector((state: State) => state?.App?.fragments?.IllustrationFragment?.Pages?.CoveragesPage?.modals?.createIllustration?.data?.scenarioId);
  const individualId = useSelector((state: State) => state?.App?.fragments?.IllustrationFragment?.Pages?.CoveragesPage?.modals?.createIllustration?.data?.selectedInsured);
  const userRole = useSelector((state: State) => state?.App?.data?.permissions?.role);
  const isHeadOffice = useMemo(() => isHeadOfficeMode(userRole), [userRole]);
  const effectiveDate = useSelector((state: State) => selectEffectiveDate(state));
  const version = useSelector((state: State) => selectVersion(state));
  const productSelected = useMemo(() => getPageName(data.description), [data.description]);

  const updateUserPreferenceProduct = getBffUrlForRoute(BffRoute.update_preferences_product_category, { productSelected });

  const updateBaseCoverageUrl = getBffUrlForRoute(BffRoute.illustration_sales_saleId_scenarios_scenarioId_basecoverage, { saleId, scenarioId, individualId }, false);
  const parentSelectedInsured = useSelector((state: State) => state?.App?.fragments?.IllustrationFragment?.Pages?.CoveragesPage?.parentInsuredId);
  const scenario = useSelector((state: State) => state?.App?.fragments?.IllustrationFragment?.data?.scenarios)?.[0];
  const baseProtections = getBaseProtections(scenario?.protections) || [];
  const selectedTab = baseProtections?.findIndex((item) => item.id === parentSelectedInsured);
  const createSalesFileUrl = getBffUrlForRoute(
    BffRoute.illustration_sales,
    null,
    false,
  );
  const isSharedOwnershipSelected = data.description === SHARED_OWNERSHIP;

  const updateBaseCoverageResource = (
    <Resource
      name={UPDATE_BASE_COVERAGE_RESOURCE_NAME}
      url={updateBaseCoverageUrl}
      method="PUT"
      onSuccess={({ data: result }: {data: Scenario}) => {
        dispatch(closeModalByName('modals.createIllustration'));
        recalculateCoverage();
        if (selectedTab > -1) {
          const protection = getBaseProtections(result?.protections)?.[selectedTab];
          if (protection) {
            dispatch(CoveragesPageActions.updateParentSelectedInsured({ insuredId: protection.id }));
          }
        }

        if (getProductData && typeof getProductData() === 'function') { getProductData(); }
      }}
      onFailure={() => {
        logger.error('insured person property updates failed...');
      }}
    />
  );

  const createSalesFile = (
    <Resource
      name={CREATE_NEW_SALES_RESOURCE_NAME}
      url={createSalesFileUrl}
      dataDst="salesCreatedResponse"
      method="POST"
      onSuccess={(res: any) => {
        history.push(`/illustration/${res.data}/${t('coverage')}`);
      }}
      onFailure={() => {
        dispatch(openModalByName('modals.createSaleFileError'));
      }}
    />
  );

  const handleClick = useCallback((productData: InsuranceGroup) => {
    setModalData(productData);
    dispatch(openModalByName('modals.createIllustrationHelp'));
  }, [dispatch]);

  const getRequestBody = (product: InsuranceProduct, term: number) => {
    const language = locale === LOCALE_EN ? LANGUAGE_ENGLISH : LANGUAGE_FRENCH;
    let requestBody: RequestBodyProps = {
      id: product.idTemplate,
      language,
      pdfPlanCode: product?.pdfPlanCodes?.[0] || null,
    };

    if (isHeadOffice) {
      requestBody = {
        ...requestBody,
        calculationEffectiveDate: getStringToFormattedDate(effectiveDate) || null,
        selectedVersionNumber: version || null,
      };
    }

    if (product.idTemplate === OTHER_TERM_TEMPLATE_ID) {
      const element = product.pdfPlanCodes.find((item) => item.firstTerm === term);
      requestBody = {
        ...requestBody,
        id: product.idTemplate,
        language,
        IsCustomTerm: true,
        pdfPlanCode: element || null,
      };
    }

    return requestBody;
  };

  const addNewScenario = (product: InsuranceProduct, term: number) => {
    const language = locale === LOCALE_EN ? LANGUAGE_ENGLISH : LANGUAGE_FRENCH;
    let payload: AddScenarioPayload = {
      id: product?.idTemplate,
      language,
      pdfPlanCode: product?.idTemplate === ACCESS_LIFE_TEMPLATE_ID ? null : product?.pdfPlanCodes[0],
      isCustomTerm: false,
      calculationEffectiveDate: null,
    };
    if (product?.idTemplate === OTHER_TERM_TEMPLATE_ID) {
      const element = product.pdfPlanCodes.find((item) => item.firstTerm === term);
      payload = {
        ...payload,
        id: product?.idTemplate,
        isCustomTerm: true,
        pdfPlanCode: element,
      };
    }
    if (product?.idTemplate === ACCESS_LIFE_TEMPLATE_ID) {
      payload = {
        id: product?.idTemplate,
      };
    }
    dispatch(requestResourceByName('resources.addScenario', payload));
  };

  const handleCreateSaleFile = useCallback((product: InsuranceProduct, term: number = null) => {
    const requestBody = getRequestBody(product, term);
    dispatch(clearCoverages(undefined));
    if (updateBaseCoverage) {
      dispatch(requestResourceByName(
        'resources.baseCoverage',
        requestBody,
      ));
    } else {
      if (isAddScenario) {
        addNewScenario(product, term);
        return;
      }

      if (typeof onCreateFile === FUNCTION_TYPE) {
        onCreateFile(product);
        return;
      }

      dispatch(requestResourceByName(`resources.${UPDATE_LATEST_PRODUCT}`, getPageName(data.description)));
      dispatch(requestResourceByName(`resources.${CREATE_NEW_SALES_RESOURCE_NAME}`, requestBody));
    }
  }, [data.description, dispatch, locale, onCreateFile, updateBaseCoverage, isAddScenario, effectiveDate]);

  const groups = useMemo(
    () =>
      data?.groupes?.filter((item, i) => !((isInModal && data.typeCategorie === MALADIE_GRAVE) && i === 2)),
    [data?.groupes, data.typeCategorie, isInModal],
  );

  const sectionItems = useMemo(() => (
    orderInsuranceGroups(data?.description, groups)
      .map((group: InsuranceGroup) => (
        <StyledInsurancePageMainSection key={group.description}>
          <StyledInsurancePageTitleContainer>
            <StyledInsurancePageTitle>
              {getTranslatedValue(group, locale)}
            </StyledInsurancePageTitle>
            { isSharedOwnershipSelected ? null : <StyledInsurancePageIconButton
              onClick={() => handleClick(group)}
              Icon={InfoOutlineIcon}
              buttonSize={20}
              iconSize={15}
            /> }
          </StyledInsurancePageTitleContainer>

          {group.groupes.map((subGroup: InsuranceGroup) => (
            <Fragment key={subGroup.description}>

              {/* Section Description */}
              <StyledInsurancePageP>{getTranslatedValue(subGroup, locale)}</StyledInsurancePageP>

              {subGroup.description === 'Renewable at YRT premiums' &&
              <>
                <StyledInsurancePageToggleSwitchContainer>
                  <StyledInsurancePageToggleSwitch
                    name="renewableOptions"
                    value={renewableOptions}
                    onChange={(e) => {
                      setRenewableOptions(e.target.value);
                    }}
                    options={[
                      { label: getTranslatedValue(subGroup?.groupes[0], locale), value: 'level' },
                      { label: getTranslatedValue(subGroup?.groupes[1], locale), value: 'decreasing' },
                    ]}
                  />
                </StyledInsurancePageToggleSwitchContainer>

                <StyledInsurancePageSection>
                  {renewableOptions === 'level' && subGroup?.groupes[0].produits.map((product: InsuranceProduct) => (
                    <div key={product.idTemplate}>
                      {
                        product.idTemplate !== 'TRADMTU' &&
                        <StyledInsurancePageTextButton
                          onClick={() => handleCreateSaleFile(product)}
                        >
                          {getTranslatedValue(product, locale)}
                        </StyledInsurancePageTextButton>
                      }

                      {product.idTemplate === 'TRADMTU' &&
                      <OtherTerm item={product} onClick={handleCreateSaleFile} />}
                    </div>
                  ))}
                </StyledInsurancePageSection>

                <StyledInsurancePageSection>
                  {renewableOptions === 'decreasing' && subGroup?.groupes[1].produits.map((product: InsuranceProduct) => (
                    <div key={product.idTemplate}>
                      {product.idTemplate !== 'TRADMTD' &&
                      <StyledInsurancePageTextButton
                        onClick={() => handleCreateSaleFile(product)}
                      >
                        {getTranslatedValue(product, locale)}
                      </StyledInsurancePageTextButton>}

                      {product.idTemplate === 'TRADMTD' &&
                      <OtherTerm item={product} onClick={handleCreateSaleFile} />}
                    </div>
                  ))}
                </StyledInsurancePageSection>
              </>}

              <StyledInsurancePageSection>
                {subGroup?.produits.map((product: InsuranceProduct) => (
                  <StyledInsurancePageTextButton
                    onClick={() => handleCreateSaleFile(product)}
                    key={product.idTemplate}
                  >
                    {getTranslatedValue(product, locale)}
                  </StyledInsurancePageTextButton>
                ))}
              </StyledInsurancePageSection>
            </Fragment>
          ))}

          <StyledInsurancePageSection>
            {group.produits.map((product: InsuranceProduct) => (
              <StyledInsurancePageTextButton
                onClick={() => handleCreateSaleFile(product)}
                key={product.idTemplate}
              >
                {getTranslatedValue(product, locale)}
              </StyledInsurancePageTextButton>
            ))}
          </StyledInsurancePageSection>
        </StyledInsurancePageMainSection>
      ))
  ), [data?.description, groups, handleClick, handleCreateSaleFile, locale, renewableOptions]);

  const [evenSections, oddSections] = useMemo(
    () => [
      sectionItems.filter((item, index) => index % 2 === 0),
      sectionItems.filter((item, index) => index % 2 !== 0),
    ],
    [sectionItems],
  );

  const visibilityProps = isIllustration ? illustrationMobileProps : homePageMobileProps;

  return (
    <>
      {createSalesFile}
      <UpdateLatestUsedProduct updateUserPreferenceProductUrl={updateUserPreferenceProduct}></UpdateLatestUsedProduct>
      {updateBaseCoverageResource}

      <StyledInsurancePageContainer>
        <DataErrorModal
          name="createSaleFileError"
          onRetry={() => null}
          onCancel={() => {
            dispatch(closeModalByName('modals.createSaleFileError'));
          }}
        />
        <CreateIllustrationHelpModal data={modalData} />

        <StyledInsurancePageP $fontSize="12px">
          {t(transKey ?? 'add_insured')}
        </StyledInsurancePageP>

        <Row gutterWidth={24}>
          <Hidden {...visibilityProps}>
            <Col xs={6}>
              <StyledInsurancePageSectionsContainer>
                {evenSections}
              </StyledInsurancePageSectionsContainer>
            </Col>
            <Col xs={6}>
              <StyledInsurancePageSectionsContainer>
                {oddSections}
              </StyledInsurancePageSectionsContainer>
            </Col>
          </Hidden>

          <Visible {...visibilityProps}>
            <Col xs={12}>
              <StyledInsurancePageSectionsContainer>
                {sectionItems}
              </StyledInsurancePageSectionsContainer>
            </Col>
          </Visible>
        </Row>
      </StyledInsurancePageContainer>

      <Spacing m-t={16}>
        <StyledInsurancePageP $textAlign="right">
          <ExternalLink href={getLearnMoreUrl()} RightIcon={LaunchIcon}>
            {getLearnMoreLink(t('learn_more_about'), data, locale)}
          </ExternalLink>
        </StyledInsurancePageP>
      </Spacing>
    </>
  );
};

export default InsurancePage;
