import { MainLoader, ScrollableDataTable, DataTable } from 'ia-react-core';
import styled from 'styled-components';

export const StyledCoveragesContentScrollableDataTable = styled(ScrollableDataTable)`
  [role="rowgroup"] {
    position: sticky;
  }
`;

export const StyledCoveragesContentDataTable = styled(DataTable)`
  [role="rowgroup"]: first-child {
    position: sticky;
  }
  
  [role="rowgroup"]: last-child {
    [role="row"] {
      align-items: center;
    }
  }
`;

export const StyledCoveragesContentMainLoader = styled(MainLoader)`
  margin-top: 130px;
`;
