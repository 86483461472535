import { T3 } from 'ia-react-core';
import styled from 'styled-components';

export const StyledNoSaleFilesFoundMessageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const StyledNoSaleFilesFoundMessage = styled(T3)`

`;
StyledNoSaleFilesFoundMessage.defaultProps = {
  marginBottom: 0,
};
