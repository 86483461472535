import { StructuredRouter } from 'ia-react-core';
import React, { FC } from 'react';
import AppLayout from '~/layouts/AppLayout';
import HOME_ROUTES from './constants/HOME_ROUTES';

export interface HomeFragmentProps { }
const HomeFragment: FC<HomeFragmentProps> = () => (
  <AppLayout>
    <StructuredRouter routes={HOME_ROUTES} />
  </AppLayout>
);

export default HomeFragment;
