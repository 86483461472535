import axios from 'axios';
import { PacPadAgreementPageState } from '~/interfaces/PacPadAgreementPageState';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';

interface UpdatePacPadAgreementOptions {
  saleId: string;
  body: PacPadAgreementPageState;
}

export default async function updatePacPadAgreement({ saleId, body }: UpdatePacPadAgreementOptions) {
  const result = await axios.put(getBffUrlForRoute(BffRoute.Update_pac_pad_agreement, { saleId }), body);

  if (result.status !== 200) {
    throw new Error(`Error while updating pac pad agreement details: ${result.status} - ${result.statusText}`);
  }
  return result;
}
