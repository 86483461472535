import { FixedKeyboardEvent } from 'ia-react-core';
import { NUMBERS_WITH_SLASH } from '../constants/regex';

const removeAccents = (field: string): string => field
  ?.replace(/[éèêëÉÈÊË]/g, 'E')
  ?.replace(/[àâÀÂ]/g, 'A')
  ?.replace(/[îïÎÏ]/g, 'I')
  ?.replace(/[ôÔ]/g, 'O')
  ?.replace(/[œŒ]/g, 'OE')
  ?.replace(/[æÆ]/g, 'AE')
  ?.replace(/[ûüÛÜ]/g, 'U')
  ?.replace(/[çÇ]/g, 'C');

export const formatNameField = (field: string): string => removeAccents(field)
  ?.toUpperCase()
  ?.replace(/[^A-Z\-.'\s]*/g, '');

export const formatTextField = (field: string): string => removeAccents(field)
  ?.toUpperCase()
  ?.replace(/[^A-Z0-9\-.'\s]*/g, '');

export const formatDateNumberField = (field: string): string => removeAccents(field)
  ?.toUpperCase()
  ?.replace(/[^0-9]*/g, '');

export const formatEmailField = (field: string): string => removeAccents(field)
  ?.toUpperCase()
  ?.replace(/[^A-Z0-9\-_.@]*/g, '');

export const formatRegexBased = (field: string, regexp: RegExp): string => removeAccents(field)
  ?.toUpperCase()
  ?.replace(regexp, '');

export const formatDateField = (field: string|Date): string|Date => {
  if (typeof field === 'string') return field?.replace(/[^0-9]*/g, '');
  return field;
};

export const formatBeneficiaryAmountField = (event: FixedKeyboardEvent): void => {
  const { key, target } = event;

  if (['Backspace', 'ArrowRight', 'ArrowLeft', 'Tab', 'Delete'].includes(key)) return;
  if ((event.ctrlKey || event.metaKey) && key === 'a') return;
  if (event.ctrlKey && key === 'v') return;
  if (NUMBERS_WITH_SLASH.test(key)) event.preventDefault();
  if (key === '/' && (target.selectionStart === 0 || target.selectionStart === 2)) event.preventDefault();
};
