import { ActionBar, FormCol, FormRow, InfoTooltip, T2, T4 } from 'ia-react-core';
import styled from 'styled-components';

export const StyledSearchContainer = styled.div`
  margin-bottom: 20px;
  width: 50%;
  button {
    background: #003da5;
    color: #fff;
    height: 38px;
    top: -10px;
    right: -9px;

    &:focus,
    &:hover {
      color: #fff;
    }
  }
  @media (max-width: 752px) {
    width: 100%;
  }
`;

export const StyledInformationContainer = styled.div`
  margin-bottom: 10px;
  aside {
    min-height: 60px;
    span {
      font-weight: bold;
    }
    svg {
      height: 40px;
      width: 40px;
      top: initial;
    }
  }
`;
export const StyledInfoTooltip = styled(InfoTooltip)``;

export const StyledChangeDividentCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  & > label {
    margin-bottom: 0px;
  }
`;

export const StyledContentContainer = styled.div`
  *:not(h3,h4) {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
  }
  label {
    align-items: center;
  }
  h5 {
    font-weight: normal;
  }
  h5,h6,section {
    margin-bottom: 15px;
  }
  aside {
    min-height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-left : 45px;
    svg {
      top: unset;
      width: 24px;
      height: 24px;
    }
  }
`;

export const StyledButtonContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  @media (max-width: 752px) {
    width: 100%;
    flex-direction: column;
    button {
      font-size: 14px;
      flex: 1 0 100%;
      width: 100%;
    }
  }
`;

export const StyledContractorContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding-bottom: 20px;
  
  @media (max-width: 752px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
`;

export const StyledFormCol = styled(FormCol)`
  position: unset;
`;

export const StyledContratorColumn = styled.div`
  flex: 1 0 30%;
  p {
    font-size: 14px;
  }
  p:first-child {
    font-weight: bold;
    padding-bottom: 5px;

    @media (max-width: 752px) {
      flex: 1 0 100%;
    }
  }
  p:last-child {
    margin-bottom: 0px;
  }
  input {
    width: 20%;    
  }
`;

export const StyledContractorDateContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const StyledInvalidDateContainer = styled.span`
  display: block;
  margin-top: 5px;
`;

export const StyledColoumn = styled(FormCol)`
  margin-botton: 5px;

  [data-testid="FormColLabel"] {
    margin-bottom: 0px;
  }

  button {
    color: #fff;
    background-color: rgb(0, 61, 165);
    height: 40px;
    top: -11px;

    &:hover { 
      color: #fff;
    }
  }
`;

export const StyledFormRow = styled(FormRow)`
@media (max-width: 767.99px) {
  gap: 10px;
  div {
    width: 100%;
  }
}`;

export const StyledMakeEchangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 767.99px) {
    gap: 12px;
  }
`;

export const StyledMakeEchangeTitle = styled(T2)`
  line-height: 28px;
`;
StyledMakeEchangeTitle.defaultProps = {
  marginBottom: 0,
};

export const StyledMakeEchangeSubtitle = styled(T4)`
  line-height: 22px;
`;
StyledMakeEchangeSubtitle.defaultProps = {
  marginBottom: 0,
};

export const StyledMakeEchangeDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.gray3};
`;

export const StyledActionBar = styled(ActionBar)`
gap: 20px;

@media (max-width: 767.99px) {
  flex-direction: column;
  gap: 10px;
  margin: 0;  

  button {
    width: 100%;
    padding: 0;
    white-space: pre-wrap;
    line-height: 25px;
    min-height: 50px;
    margin: 0 0 10px 0;
  }  
}
`;
