export default {
  title: 'e-Change request',
  subtitle: 'Contract Identification',
  cancel: 'Cancel',
  proceed: 'Proceed with the change request',
  placeholder: 'Contract number',
  holderconsent: 'I confirm that I have obtained the policy holder’s consent to access certain information in the contract.',
  clientidentity: 'I confirm that I have validated the policyholder\'s identity by means of a valid identification document.',
  clientidentityTip1: 'Here are the accepted valid identification documents for identification purposes. Use original documents only:',
  clientidentityTip2: 'Driver\'s licence',
  clientidentityTip3: 'Passport',
  clientidentityTip4: 'Health insurance card *',
  clientidentityTip5: '* Health insurance card cannot be used in the following provinces: Ontario, Manitoba, Prince Edward Island and Nova Scotia. In Quebec, health insurance card cannot be required for identification purposes but if a policyholder chooses to present one, it can be accepted.',
  validmessage: 'The downloaded data are valid for a duration of 72 hours. These will be automatically removed after this time limit or when the request is completed.',
  emptyerror: 'Please enter a contract number',
  invaliderror: 'The contract is invalid',
  clientname: 'Client\'s Name',
  name: 'Name',
  contractno: 'Contract No',
  type: 'Type',
  dob: 'Date of birth',
  invalidrange: 'Please indicate date of birth',
  dateerror: 'Please complete the date of birth.',
  dateinvalid: 'The date of birth of the principal insured is not valid',
  datetitle: 'Please enter the date of birth of the principal insured:',
  apierror: 'The download service is not available at the moment, please try again later. If the problem persists, please contact the Help Desk.',
  VN: 'Universal Life',
  VT: 'Traditional Life',
  LI: 'Traditional Life',
  UW: 'Traditional Life',
  inValidDate: 'Date entered is not valid',
  confirmDownloadModal: {
    content: 'There is already a change request in progress on this contract. Would you like to open it?',
    no: 'No',
    yes: 'Yes',
  },
  onapifail: 'The download service is not available at the moment, please try again later.  If the problem persists, please contact the Help Desk.',
  notfound: 'Search for: ',
  noresultfound: 'No results found.',
  onsearchapifail: 'The service is not available at the moment, please try again later.  If the problem persists, please contact the Help Desk.',
  contractInsuredTitle: 'Identification of the applicants',
  contractInsuredInfo: 'In order to allow the proper processing of this request, please indicate if the policyowner is a legal person (corporation)',
  isCorporation: "I confirmed that I have validated the policyholder's identityby means of a valid identification document.",
  identificationLabel: 'Is "####" a corporation?',
  yes: 'Yes',
  no: 'No',
  identifyapplicant: 'Identify an applicant',
  applicantTitle: 'Applicant(s)',
  dateFormat: 'en-ca',
  insuredtitle: 'Applicant',
  modifyuser: 'Update',
  addUser: 'Add',
  whoIsDesignateInsureds: 'Please identify an applicant among the insureds listed below',
  addApplicant: 'Identify an applicant other than those identified above',
  confirmDeleteContentOne: 'Please click on Yes to delete this applicant. You will not be able to cancel the change.',
  confirmDeleteContentTwo: 'Ok to delete',
  confirmCancelContentOne: 'By canceling, the information entered during the applicants identification will not be saved.',
  confirmCancelContentTwo: 'Do you want to continue?',
  errors: {
    firstname: 'Please enter this person\'s first name.',
    lastname: 'Please enter this person\'s last name.',
    birthDate: 'Please complete the date of birth.',
    sex: 'Please enter this person\'s gender.',
    dobfuture: 'The birth date for this ensured must not be in the future.',
    invalidDate: 'Date entered is not valid',
  },
  firstname: 'First name(s)',
  lastname: 'Last name',
  gender: 'Gender',
  save: 'Save',
  monthplaceholder: 'Month',
  yearplaceholder: 'YYYY',
  errorTitle: 'Error',
  unknownDOBSexHeader: 'Information to complete',
  unknownDOBSexMessage: 'We have identified missing information(s) in the contract. Please specify.',
  lastName: 'Last name',
  firstName: 'First name(s)',
  male: 'Male',
  female: 'Female',
  confirmModal: {
    content1: 'By cancelling, the information entered during the applicants identification will not be saved.',
    content2: 'Do you want to continue?',
    no: 'No',
    yes: 'Yes',
  },
};
