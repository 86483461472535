import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  AddCircleIcon,
  COLORS,
  DeleteIcon,
  Divider,
  EditIcon,
  Form, FormCol, FormRow, IconButton, Modal, PrimaryButton, RadioListControl, SecondaryButton, SecondaryTextIconButton, T2, ToggleButtonListOption, changeLanguage, closeModalByName, openModalByName, requestResourceByName, useScopedSelector,
} from 'ia-react-core';
import { ReactComponent as PersonIcon } from '~/assets/Icons/personFill.svg';

import { StyledActionBar, StyledModalWrapper } from '~/fragments/ChangeFragment/ChangeFragment.styles';
import formatDate from '~/utilities/formatDate.utils';
import { Locale, useCurrentLocale } from '~/hooks/useCurrentLocale';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { useHistory } from 'react-router';
import { AxiosResponse } from 'axios';
import getGender from '~/fragments/ChangeFragment/pages/EChangePage/pages/HeadOffice/utilities/GetGender';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import { StyledButtonWrapper, StyledErrorMessage, StyledFormCol, StyledInfoMessage, StyledInsuredBlock, StyledInsuredDiv, StyledInsuredRow, StyledT4 } from './ContractInsuredModal.styles';
import { DownloadState } from '../../DownloadState';
import ManageInsuredModal, { MANAGE_INSURED } from '../ManageInsuredModal/ManageInsuredModal';
import ContractDetailResource from '../../resources/ContractDetailResource';
import ContractCancelConfirmDialog, { CONTRACT_CONFIRM_CANCEL_DIALOG } from '../ContractCancelConfirmDialog';
import ContractConfirmDialog, { CONTRACT_CONFIRM_DELETE_DIALOG } from '../ContractConfirmDialog';
import { setDownloadAction } from '../../MakeEchangeModal.actions';
import selectContractSaveError from '../../selectors/selectContractSaveError';
export const CONTRACT_INSURED = 'contractInsured';

interface ContractInsuredModalProps{
  onCloseHandler?: () => void;
}

const ContractInsuredModal = ({ onCloseHandler }: ContractInsuredModalProps) => {
  const history = useHistory();
  const { t, i18n } = useTranslation('makeEchange');
  const locale = i18n.language;
  const dispatch = useDispatch();
  const lang = useCurrentLocale();
  const downloadFormValues = useScopedSelector((state: DownloadState) => state?.values?.downloadData);
  const hasErrors = useScopedSelector(selectContractSaveError);

  const [isEdit, setIsEdit] = React.useState(false);
  const [redirectUrl, setRedirectUrl] = React.useState('');

  const options = useMemo<ToggleButtonListOption<boolean>[]>(
    () => [
      { label: t('yes'), value: true },
      { label: t('no'), value: false }],
    [t],
  );

  useEffect(() => {
    if (downloadFormValues) dispatch(changeLanguage(downloadFormValues.language ? Locale.EN : Locale.FR));
  }, [downloadFormValues]);

  useEffect(() => {
    if (redirectUrl !== '') {
      history.push(redirectUrl);
    }
    return () => {
      setRedirectUrl('');
    };
  }, [redirectUrl, dispatch, history]);

  const gender = useCallback((sex: string) => getGender(sex, lang), [lang]);

  const closeModal = useCallback(() => {
    if (downloadFormValues?.isApplicantIdentificationComplete === false) {
      dispatch(openModalByName(`modals.${CONTRACT_CONFIRM_CANCEL_DIALOG}`));
    } else {
      dispatch(closeModalByName(`modals.${CONTRACT_INSURED}`));
    }
    onCloseHandler();
  }, [dispatch, downloadFormValues?.isApplicantIdentificationComplete]);

  const handleSaveInsured = useCallback(() => {
    const param = { saleId: downloadFormValues?.id };
    const url = getBffUrlForRouteWithoutQP(BffRoute.contract_details, param, false);
    dispatch(requestResourceByName('resources.contractDetails', { ...downloadFormValues }, { url }));
  }, [dispatch, downloadFormValues]);

  const handleAddApplicant = useCallback(() => {
    setIsEdit(false);
    dispatch(openModalByName(`modals.${MANAGE_INSURED}`, { editUser: false, index: downloadFormValues?.selectedApplicants?.length > 0 ? downloadFormValues?.selectedApplicants?.length : 0 }));
  }, [isEdit, dispatch, downloadFormValues?.selectedApplicants]);

  const handleDeleteInsured = useCallback((indx: number) => {
    dispatch(openModalByName(`modals.${CONTRACT_CONFIRM_DELETE_DIALOG}`, { index: indx }));
  }, [dispatch]);

  const handleEditInsured = useCallback((indx: number) => {
    setIsEdit(true);
    dispatch(openModalByName(`modals.${MANAGE_INSURED}`, { editUser: true, index: indx }));
  }, [isEdit, dispatch]);

  const onContractSuccess = useCallback((response: AxiosResponse) => {
    const language = response?.data?.language;
    if (response?.data?.requireUpdateInsuredInformation) {
      dispatch(openModalByName('modals.unknownDOBSex'));
      return;
    }
    dispatch(changeLanguage(language ? Locale.EN : Locale.FR));
    setRedirectUrl(`/application/${response?.data?.id}/echange/${language ? 'changes' : 'changements'}`);
  }, [dispatch, changeLanguage]);

  const handleCancelContractSave = useCallback(() => {
    dispatch(closeModalByName(`modals.${CONTRACT_INSURED}`));
  }, [dispatch]);

  const handleDeleteInsuredConfirm = useCallback((indx: number) => {
    if (downloadFormValues?.selectedApplicants[indx]?.id !== '00000000-0000-0000-0000-000000000000') {
      const insuredLatest = [...downloadFormValues.insureds, { ...downloadFormValues.selectedApplicants[indx] }];
      const downloadCopy = { ...downloadFormValues, selectedApplicants: (downloadFormValues?.selectedApplicants?.length === 1) ? [] : [...downloadFormValues.selectedApplicants.slice(0, indx), ...downloadFormValues.selectedApplicants.slice(indx + 1)], insureds: insuredLatest };
      dispatch(setDownloadAction({
        ...downloadCopy,
      }));
      return;
    }
    const downloadCopy = { ...downloadFormValues, selectedApplicants: (downloadFormValues?.selectedApplicants?.length === 1) ? [] : [...downloadFormValues.selectedApplicants.slice(0, indx), ...downloadFormValues.selectedApplicants.slice(indx + 1)] };
    dispatch(setDownloadAction({
      ...downloadCopy,
    }));
  }, [dispatch, downloadFormValues]);
  return (
    <Modal name={CONTRACT_INSURED}>
      <StyledModalWrapper>
        <Form className="productlistform" name="downloadData" onSubmit={(evt: React.ChangeEvent) => evt?.preventDefault()}>
          <FormRow>
            <FormCol col={12}>
              <T2>{t('contractInsuredTitle')}</T2>
            </FormCol>
          </FormRow>
          {
            hasErrors?.data?.length > 0 &&
              <StyledErrorMessage title={t('error')}>
                <strong>{t('errorTitle')}</strong>
                <ul>
                  {hasErrors?.data?.map((errorItem: ValidationMessage) => (
                    <li key={errorItem?.numero}>
                      {locale === Locale.FR ? errorItem?.messageFR : errorItem?.messageEN}
                    </li>
                  ))}
                </ul>
              </StyledErrorMessage>
          }
          <FormRow>

            <StyledInfoMessage>
              {t('contractInsuredInfo')}
            </StyledInfoMessage>
          </FormRow>

          <FormRow>
            <FormCol col={12} label={t('identificationLabel')?.replace('####', downloadFormValues?.applicant?.name ?? '')}>
              <RadioListControl
                key={downloadFormValues?.id}
                options={options}
                defaultValue={downloadFormValues?.isApplicantIdentificationComplete}
                name="isApplicantIdentificationComplete"
                horizontal
              />
            </FormCol>
          </FormRow>
          { downloadFormValues?.isApplicantIdentificationComplete === false &&
            <StyledInsuredBlock>
              <StyledInsuredDiv>
                <PersonIcon color={COLORS.BLACK} />
                <StyledT4 as="h6">{t('applicantTitle')}</StyledT4>
              </StyledInsuredDiv>
              { downloadFormValues?.selectedApplicants?.map((applicant, index) => (
                <StyledInsuredRow key={`${applicant.firstName}-${index}`}>
                  <StyledFormCol width="40%">
                    <>{applicant.lastName} {applicant.firstName}</>
                  </StyledFormCol>
                  <StyledFormCol width="25%">
                    {formatDate(new Date(applicant?.birthDate), i18n.language)}
                  </StyledFormCol>
                  <StyledFormCol width="20%">
                    {gender(applicant.sex)}
                  </StyledFormCol>
                  <StyledFormCol width="15%">
                    <IconButton
                      onClick={() => handleEditInsured(index)}
                      Icon={EditIcon}
                    />
                    <IconButton
                      onClick={() => handleDeleteInsured(index)}
                      Icon={DeleteIcon}
                    />
                  </StyledFormCol>
                </StyledInsuredRow>
              )) }
              { downloadFormValues?.selectedApplicants?.length < 2 &&
              <FormRow>
                <FormCol>
                  <StyledButtonWrapper>
                    <SecondaryTextIconButton
                      className="addinsured"
                      LeftIcon={AddCircleIcon}
                      iconSize={24}
                      color={COLORS.BLUE5}
                      onClick={handleAddApplicant}
                    >
                      {t('identifyapplicant')}
                    </SecondaryTextIconButton>
                  </StyledButtonWrapper>
                </FormCol>
              </FormRow>}
            </StyledInsuredBlock>}

          <Divider />
          <StyledActionBar>
            <PrimaryButton onClick={handleSaveInsured}>{t('save')}</PrimaryButton>
            <SecondaryButton onClick={closeModal}>{t('cancel')}</SecondaryButton>
          </StyledActionBar>
        </Form>
      </StyledModalWrapper>
      {downloadFormValues && <ManageInsuredModal />}
      <ContractDetailResource saleId={downloadFormValues?.id} method="POST" onContractSuccess={onContractSuccess} />
      <ContractCancelConfirmDialog onConfirm={handleCancelContractSave} />
      <ContractConfirmDialog onConfirm={handleDeleteInsuredConfirm} />
    </Modal>
  );
};

export default ContractInsuredModal;
