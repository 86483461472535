import { injectTranslations } from 'ia-react-core';
import { FC } from 'react';
import { compose } from 'redux';
import fr from './translations/fr';
import en from './translations/en';
import SaleFileSection, { SaleFileSectionProps } from './SaleFileSection';

export default compose<FC<SaleFileSectionProps>>(
  injectTranslations('SaleFileSection', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(SaleFileSection);

export * from './SaleFileSection';
