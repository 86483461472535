export default function buildUrl(urlParts: string[], query?: Record<string, string | number | boolean>): string {
  const url = urlParts
    .join('/')
    .replaceAll(/(?<!:)\/{2,}/g, '/');

  const queryString = Object.entries(query || {})
    .filter(([, value]) => value !== null && value !== undefined)
    .map(([key, value]) => (`${encodeURIComponent(key)}=${encodeURIComponent(value)}`))
    .join('&');

  if (queryString) {
    return `${url}?${queryString}`;
  }

  return url;
}
