import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ErrorMessage,
  FadingLoader,
  Form,
  FormCol,
  FormRow,
  Modal,
  PrimaryButton,
  SearchControl,
  SecondaryButton,
  T5,
  clearResourceDataByName,
  closeModalByName,
  requestResourceByName,
  resetFormByName,
  useScopedSelector,
  openModalByName,
  Divider,
  changeLanguage,
  Resource,
} from 'ia-react-core';
import { useHistory } from 'react-router';
import { Locale, useCurrentLocale } from '~/hooks/useCurrentLocale';
import { AxiosError, AxiosResponse } from 'axios';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';
import logger from '~/utilities/logger';
import { MakeEchangeState, ContractApiData, FormError } from '~/interfaces/MakeEchange';

import { AppState } from '~/interfaces/AppState';
import getTextBasedOnLocale from '~/utilities/getTextBasedOnLocale';
import isHeadOfficeMode from '~/fragments/ApplicationFragment/utilities/isHeadOfficeMode';
import { State } from '~/interfaces/State';
import {
  StyledActionBar,
  StyledInformationContainer,
  StyledColoumn,
  StyledFormRow,
  StyledMakeEchangeTitle,
  StyledMakeEchangeContainer,
  StyledMakeEchangeSubtitle,
  StyledMakeEchangeDivider,
} from './Makeechange.styles';
import SearchContractResource from './components/SearchContractResource';
import ValidateDobResource from './components/ValidateDobResource';
import { numericValidatorWithKeys } from './utilities/Validators';
import DownloadConfirmDialog, { DOWNLOAD_CONTRACT_CONFIRM_DIALOG } from './components/DownloadConfirmDialog';
import ContractorDetails from './components/ContractorDetails';
import ContractInsuredModal, { CONTRACT_INSURED } from './components/ContractInsuredModal/ContractInsuredModal';
import UnknownDOBSexModal from './components/UnknownDOBSexModal/UnknownDOBSexModal';

const MakeEchange = () => {
  const history = useHistory();
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [downloadData, setDownloadData] = useState({ saleId: '', language: 1 });
  const [redirectUrl, setRedirectUrl] = useState('');
  const [showDobError, setDobError] = useState('');
  const [showLoader, setLoader] = useState(false);
  const [showInvalid, setInvalid] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation('makeEchange');
  const formValue = useScopedSelector((state: MakeEchangeState) => state?.values?.makeechange);
  const userRole = useSelector((state: State) => state?.App?.data?.permissions?.role);
  const isHeadOfficeLogin = useMemo(() => isHeadOfficeMode(userRole), [userRole]);
  const contractApiData = useScopedSelector((state: ContractApiData) => state?.data?.searchcontract);
  const isProceedDisabled = (contractApiData && isHeadOfficeLogin) ? false : !(formValue?.clientidentity && formValue?.holderconsent);
  const contractSearchApiError = useSelector((state: AppState) => state?.App?.fragments?.HomeFragment?.pages?.CreateIllustrationPage?.components?.MakeEchangeModal?.resources?.searchcontract?.error);
  const searchApiFolderPageError = useSelector((state: AppState) => state?.App?.fragments?.HomeFragment?.pages?.FoldersPage?.components?.MakeEchangeModal?.resources?.searchcontract?.error);
  const searchApiFeaturePageError = useSelector((state: AppState) => state?.App?.fragments?.HomeFragment?.pages?.FeaturesPage?.components?.MakeEchangeModal?.resources?.searchcontract?.error);
  const searchApiError = useMemo(() => contractSearchApiError || searchApiFolderPageError || searchApiFeaturePageError, [contractSearchApiError, searchApiFolderPageError, searchApiFeaturePageError]);
  const locale = useCurrentLocale();
  const formErrors = useScopedSelector((state: FormError) => state?.forms?.makeechange?.errors);
  const isEmployeeRoute = process.env.REACT_APP_EMP_ROUTES.split(',').includes(window.location.origin);
  const redirectUrlonApiFail = isEmployeeRoute ? process.env.REACT_APP_EMP_LOGIN_URL : process.env.REACT_APP_LOGIN_URL;

  const handleEchangeClose = () => {
    setEmptyError(false);
    clearContractorDataOnCloseContent();
    dispatch(closeModalByName('modals.makeEchange'));
  };

  useEffect(() => {
    if (redirectUrl !== '') {
      history.push(redirectUrl);
    }
    return () => {
      setRedirectUrl('');
    };
  }, [redirectUrl, dispatch, history]);

  useEffect(() => {
    if (!formErrors?.contractorDob?.notValidYear) {
      setInvalid(false);
    }
  }, [formErrors?.contractorDob?.notValidYear]);

  const handleChange = () => setEmptyError(false);

  const handleDobOnChange = () => setDobError('');

  const handleSearch = () => {
    setShowEmptyError(!formValue.contractNumber);
    if (formValue?.contractNumber) {
      setLoader(true);
      dispatch(requestResourceByName('resources.searchcontract'));
    }
  };
  const setEmptyError = (isEmpty: boolean) => setShowEmptyError(isEmpty);

  const clearContractorDataOnCloseContent = () => {
    setDobError('');
    dispatch(clearResourceDataByName('resources.searchcontract'));
    dispatch(clearResourceDataByName('resources.downloadcontract'));
    dispatch(resetFormByName('forms.makeechange'));
  };

  const handleProceed = () => {
    setDobError('');
    setInvalid(false);
    if (!contractApiData?.estAutoriseServiceApresVente && !formValue?.contractorDob && !formErrors?.contractorDob?.notValidYear) {
      setDobError(t('dateerror'));
    } else if (formErrors?.contractorDob?.notValidYear) {
      setInvalid(formErrors?.contractorDob?.notValidYear);
    } else {
      setLoader(true);
      dispatch(requestResourceByName('resources.downloadcontract'));
    }
  };

  const clearErrors = () => {
    setDobError(t('dateinvalid'));
    setLoader(false);
  };

  const onAfter = (language: string, id: string, requireReinstatement: boolean) => {
    dispatch(changeLanguage(language ? Locale.EN : Locale.FR));
    const path = 'echange';
    const changesPath = language ? '/changes' : '/changements';
    const reinstatement = requireReinstatement ? 'reinstatement' : path + changesPath;
    history.push(`/application/${id}/${reinstatement}`);
  };
  const onSuccessValidation = useCallback((response: AxiosResponse) => {
    setLoader(false);
    const { status, data } = response;
    if (status !== 200 && status !== 202) {
      clearErrors();
      if (status === 401) {
        history.push(redirectUrlonApiFail);
      }
    } else if (contractApiData.changeRequestId) {
      dispatch(openModalByName(`modals.${DOWNLOAD_CONTRACT_CONFIRM_DIALOG}`));
    } else {
      const { language, id, requireApplicantIdentification, requireUpdateInsuredInformation } = data as { language: string; id: string; requireApplicantIdentification: boolean; requireUpdateInsuredInformation: boolean; requireReinstatement: boolean };
      clearContractorDataOnCloseContent();
      if (requireApplicantIdentification) {
        dispatch(openModalByName(`modals.${CONTRACT_INSURED}`));
      } else if (requireUpdateInsuredInformation) {
        dispatch(openModalByName('modals.unknownDOBSex'));
      } else {
        onAfter(language, id, (data as { requireReinstatement: boolean }).requireReinstatement);
      }
    }
  }, [contractApiData, dispatch]);

  const onFailureValidation = (error: AxiosError) => {
    setLoader(false);
    const { response } = error;
    if (response?.status === 504) {
      setDobError(t('onapifail'));
    } else if (response?.status === 401) {
      history.push(redirectUrlonApiFail);
    } else if (response?.status !== 200 && Array.isArray(response?.data) && response?.data?.length > 0) {
      const errorObject: { messageEN?: string; messageFR?: string }[] = response?.data;
      setDobError(locale === Locale.EN ? errorObject[0]?.messageEN : errorObject[0]?.messageFR);
    } else {
      setDobError('');
    }
  };

  const onConfirmDownload = useCallback(() => {
    const { language, saleId } = downloadData;
    dispatch(changeLanguage(language ? Locale.EN : Locale.FR));
    setRedirectUrl(`/application/${saleId}/echange/${language ? 'changes' : 'changements'}`);
  }, [downloadData, dispatch]);

  const onSearchComplete = () => setLoader(false);

  return (
    <>
      {formValue?.contractNumber?.trim() &&
        <SearchContractResource
          contractNumber={formValue?.contractNumber}
          onComplete={onSearchComplete}
        />}

      <ValidateDobResource
        contractNumber={formValue?.contractNumber}
        birthDate={formValue?.contractorDob ?? null}
        onSuccess={onSuccessValidation}
        onFailure={onFailureValidation}
      />
      {contractApiData?.changeRequestId &&
        <Resource
          name="getLangauge"
          method="GET"
          url={getBffUrlForRoute(BffRoute.change_request, { saleId: contractApiData?.changeRequestId }, false)}
          onFailure={() => {
            logger.error('get langauge metadata failed to retrieve...');
          }}
          onSuccess={(responses: AxiosResponse) => {
            const { langauge } = responses.data;
            setDownloadData({
              saleId: contractApiData?.changeRequestId,
              language: langauge ? 1 : 0,
            });
          }}
          autoRequest
        />}

      <Modal name="makeEchange" onAfterClose={handleEchangeClose} isClosable={!showLoader} shouldCloseOnOverlayClick={false}>
        {showLoader && <FadingLoader />}
        {!showLoader &&
          <Form name="makeechange" onSubmit={(evt: React.ChangeEvent) => evt?.preventDefault()}>
            <StyledMakeEchangeContainer>
              {!contractApiData && (
                <>
                  <StyledMakeEchangeTitle>{t('title')}</StyledMakeEchangeTitle>
                  {(searchApiError && searchApiError?.status !== 404 && searchApiError?.status !== 504) && (
                    <FormRow>
                      <FormCol col={12}>
                        <StyledInformationContainer>
                          {searchApiError?.data ?
                            <ErrorMessage error={getTextBasedOnLocale(locale, searchApiError?.data[0]?.messageEN, searchApiError?.data[0]?.messageFR)} /> :
                            <ErrorMessage error={t('onsearchapifail')} />}
                        </StyledInformationContainer>
                      </FormCol>
                    </FormRow>
                  )}
                  {showEmptyError && (
                    <FormRow>
                      <FormCol col={12}>
                        <StyledInformationContainer>
                          <ErrorMessage error={t('emptyerror')} />
                        </StyledInformationContainer>
                      </FormCol>
                    </FormRow>
                  )}

                  <StyledMakeEchangeSubtitle>{t('subtitle')}</StyledMakeEchangeSubtitle>

                  <FormRow>
                    <StyledColoumn col-md={6} col-sm={6} col-lg={6}>
                      <SearchControl
                        maxLength={10}
                        name="contractNumber"
                        placeholder={t('placeholder')}
                        onKeyDown={numericValidatorWithKeys}
                        onSearch={handleSearch}
                        onChange={handleChange}
                      />
                    </StyledColoumn>
                  </FormRow>
                  {contractSearchApiError?.status === 404 && (
                    <>
                      <StyledFormRow>
                        <FormCol col={12}>
                          <p>
                            <span style={{ fontWeight: 400, fontSize: 14 }}>{t('notfound')}</span>
                            <span style={{ fontWeight: 600, fontSize: 14, marginLeft: 10 }}>{formValue?.contractNumber}</span>
                          </p>
                        </FormCol>
                      </StyledFormRow>
                      <StyledFormRow>
                        <StyledColoumn col={12}>
                          <T5 style={{ textAlign: 'center', marginBottom: 5 }}>{t('noresultfound')}</T5>
                        </StyledColoumn>
                        <StyledColoumn col={12}>
                          <Divider />
                        </StyledColoumn>
                      </StyledFormRow>
                    </>
                  )}
                </>
              )}

              {contractApiData &&
              <ContractorDetails
                contractDetails={contractApiData}
                showDobError={showDobError}
                formValue={formValue}
                formErrors={formErrors}
                clearContractorDataOnCloseContent={clearContractorDataOnCloseContent}
                showInvalid={showInvalid}
                handleDobOnChange={handleDobOnChange}
                isHeadoffice={isHeadOfficeLogin}
              /> }
              <StyledMakeEchangeDivider />

              <StyledActionBar nopadding>
                <SecondaryButton onClick={handleEchangeClose}>{t('cancel')}</SecondaryButton>
                <PrimaryButton disabled={isProceedDisabled} onClick={handleProceed}>{t('proceed')}</PrimaryButton>
              </StyledActionBar>

            </StyledMakeEchangeContainer>

          </Form>}
        <DownloadConfirmDialog onConfirm={onConfirmDownload} />
        { downloadData && <ContractInsuredModal onCloseHandler={handleSearch} />}
        { downloadData && <UnknownDOBSexModal />}
      </Modal>
    </>
  );
};

export default MakeEchange;
