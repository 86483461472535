import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { useDispatch, useSelector } from 'react-redux';
import scrollIntoView from '~/utilities/scrollIntoView';
import getStatusFromValidationMessages from '~/fragments/ApplicationFragment/utilities/getStatusFromValidationMessages';
import { updateRouteStatus } from '~/App.actions';
import CaseModel from '~/interfaces/InsurabilityDeclaration/CaseModel';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import { ValidationMessageTypes } from '~/interfaces/InsurabilityDeclaration/enums';
import { ValidationMessageLevel } from '~/interfaces/ValidationMessageLevel';
import { DOI_FORM_NAME, VALIDATE_DOI_RESOURCE } from '../constants';
import { setPageValidationMessagesAction } from '../ApplicationFragment.actions';
import selectCurrentRoute from '../selectors/selectCurrentRoute';

export interface ValidateDOIResourceProps {
}

const ValidateDOIResource: FC<ValidateDOIResourceProps> = () => {
  const dispatch = useDispatch();
  const currentRoute = useSelector(selectCurrentRoute);
  return (
    <Resource
      name={VALIDATE_DOI_RESOURCE}
      method="GET"
      onSuccess={(res) => {
        const doiCase = (res?.data as CaseModel);
        const validationMessages = doiCase?.parties
          ?.flatMap((p) => p.forms.flatMap((f) => f.validationMessages))
          .map((message) => {
            let categorie;
            if (message.validationLevel === ValidationMessageTypes.Notice) {
              categorie = ValidationMessageLevel.Warning;
            } else if (message.validationLevel === ValidationMessageTypes.Error) {
              categorie = ValidationMessageLevel.Error;
            } else {
              categorie = ValidationMessageLevel.WarningCritique;
            }
            return { categorie };
          }) as ValidationMessage[];
        const status = getStatusFromValidationMessages(validationMessages);
        dispatch(setPageValidationMessagesAction(
          'NomineePage',
          DOI_FORM_NAME,
          validationMessages,
          'NomineePage',
        ));
        dispatch(updateRouteStatus(currentRoute, status));
        scrollIntoView();
      }}
    />
  );
};
export default ValidateDOIResource;
