import { Resource, ResourceProps } from 'ia-react-core';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { ApiResponse } from '~/interfaces/ValidationMessage';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { setValidationMessagesInNameValidationAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';
import { State } from '~/interfaces/State';
import { UPDATE_VALIDATION_NAMES_RESOURCE_NAME, VALIDATION_OF_NAMES_FORM_NAME } from '../ValidationPage.constants';
import { updateValidationStatusAction } from '../ValidationPage.actions';

export interface UpdateValidationNamesResourceProps extends ResourceProps<any, null> {
  onSuccess?: (res: any) => void;
}

const UpdateValidationNames: FC<UpdateValidationNamesResourceProps> = ({ onSuccess, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { saleId } = useParams<{ saleId: string }>();
  const backToHome = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);

  const defaultOnSuccess = (res: any) => {
    const { validationMessages } = (res?.data as ApiResponse);
    const showNameBorder = validationMessages?.some((message) => message?.nomPropriete === 'individualNames') || false;
    const showDemandBorder = validationMessages?.some((message) => message?.nomPropriete === 'demandDetails') || false;
    dispatch(updateValidationStatusAction(showNameBorder, showDemandBorder, validationMessages));
    if (backToHome) {
      history.block(true);
      history.push('/');
      return;
    }
    if (validationMessages) {
      dispatch(setValidationMessagesInNameValidationAction('ValidationPage', VALIDATION_OF_NAMES_FORM_NAME, validationMessages));
    }
  };

  return (
    <Resource
      name={UPDATE_VALIDATION_NAMES_RESOURCE_NAME}
      url={getBffUrlForRoute(BffRoute.validate_names_add, { saleId })}
      method="PUT"
      onSuccess={onSuccess || defaultOnSuccess}
      {...props}
    />
  );
};

export default UpdateValidationNames;
