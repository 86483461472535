import { ArrowForwardIcon, IconButton } from 'ia-react-core';
import styled from 'styled-components';

export const StyledFileActionsCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 1 1 0;
  justify-content: flex-end;
`;

export const StyledFileActionsCellMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
`;

export const StyledFileActionsCellIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
`;

export const StyledFileActionsCellMenuItemContainer = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
`;

export const StyledFileActionsCellMenuItemDividerContainer = styled.div`
  height: 16px;
  align-self: stretch;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledFileActionsCellMenuItemDescription = styled.div<{ $link?: boolean }>`
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ $link }) => ($link ? 400 : 700)};
  line-height: 18px;
  color: ${({ $link, theme }) => ($link ? theme?.colors?.blue5 : theme?.colors?.black)};
`;

export const StyledFileActionsCellMenuItemValue = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

export const StyledFileActionsCellMenuInnerContainer = styled.div`
  display: flex;
  width: 240px;
  padding: 8px 16px;
  flex-direction: column;
`;

export const StyledFileActionsCellsMenuArrow = styled(ArrowForwardIcon)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme?.colors?.blue5};
`;
