export const PAGE_ID_APPLICATION_TRANSMISSION = 21;
export const PAGE_ID_ECHANGE_TRANSMISSION = 997;

export const PAGE_ID_APPLICATION_SIGNATUREMODE = 998;
export const PAGE_ID_ECHANGE_SIGNATUREMODE = 27;

export const PAGE_ID_APPLICATION_SIGNATURE_FOLLOW_UP = 999;
export const PAGE_ID_ECHANGE_SIGNATURE_FOLLOW_UP = 28;

export const PAGE_ID_APPLICATION_ADVISOR = 7;
export const PAGE_ID_ECHANGE_ADVISOR = 23;
export const PAGE_ID_ECHANGE_CHANGEREQUEST = 22;
