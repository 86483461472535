import { ProductEnum } from '../pages/CoveragesPage/models/ProductEnum';
import { ProductTypePar } from '../pages/CoveragesPage/models/ProductTypePar';

export function getProductName(product: string, typeProduitPar: string): string {
  const productTypeValue: number = parseInt(product, 10);
  const productTypeParValue: number = parseInt(typeProduitPar, 10);
  switch (productTypeValue) {
    case ProductEnum.AccesVie:
      return 'LUU1330_PRODUIT_ACCES_VIE';
    case ProductEnum.Axis:
    case ProductEnum.Maxirance:
      return 'LUU1330_PRODUIT_AXIS';
    case ProductEnum.Abritation:
      return 'LUU1330_PRODUIT_ABRITATION';
    case ProductEnum.RegimeVieMD:
    case ProductEnum.RegimeVieMDEpargnePlus:
      return 'LUU1330_PRODUIT_REGIME_VIE';
    case ProductEnum.Genesis:
    case ProductEnum.Iris:
    case ProductEnum.GenesisEpargnePlus:
      return 'LUU1330_PRODUIT_GENESIS';
    case ProductEnum.GenesisAvantage:
      return 'LUU1330_PRODUIT_GENESIS_AVANTAGE';
    case ProductEnum.Transition:
      return 'LUU1330_PRODUIT_TRANSITION';
    case ProductEnum.TransitionEvolution:
      return 'LUU1330_PRODUIT_TRANSITION_EVOLUTION';
    case ProductEnum.TransitionSimplifiee:
      return 'LUU1330_PRODUIT_TRANSTION_SIMPLIFIE';
    case ProductEnum.MultiTerme:
      return 'LUU1330_PRODUIT_MULTITERME';
    case ProductEnum.Alternative:
      return 'LUU1330_PRODUIT_ALTERNATIVE';
    case ProductEnum.Momentum:
      return 'LUU1330_PRODUIT_MOMENTUM';
    case ProductEnum.Ecoflex:
      return 'LUU1330_PRODUIT_ECOFLEX';
    case ProductEnum.VieEntiere:
      return 'LUU1330_PRODUIT_VIE_ENTIERE';
    case ProductEnum.Perspective:
      return 'LUU1330_PRODUIT_PERSPECTIVE';
    case ProductEnum.Diploma:
      return 'LUU1330_PRODUIT_DIPLOMA';
    case ProductEnum.AssuranceTraditionnelle:
      return 'LUU1330_PRODUIT_ASS_TRAD';
    case ProductEnum.Tendance:
      return 'LUU1330_PRODUIT_TENDANCE';
    case ProductEnum.CapitalValeur:
      return 'LUU1330_PRODUIT_CAPITAL_VALEUR';
    case ProductEnum.Heritage:
      return 'LUU1330_PRODUIT_HERITAGE';
    case ProductEnum.Par:
      switch (productTypeParValue) {
        case ProductTypePar.Base:
          return 'LUU1330_PRODUIT_PARTICIPATIF';
        case ProductTypePar.Patrimoine:
          return 'LUU1330_PRODUIT_PARTICIPATIF_PATRIMOINE';
        case ProductTypePar.Valeur:
          return 'LUU1330_PRODUIT_PARTICIPATIF_VALEUR';
        default:
          return 'LUU1330_PRODUIT_PARTICIPATIF';
      }
    default:
      return '';
  }
}

export function isHeritage(product: string): boolean {
  const productTypeValue: number = parseInt(product, 10);

  if (ProductEnum.Heritage === productTypeValue) { return true; }
  return false;
}

export function getProductNameByKey(type: any) {
  let product = ProductEnum[type];
  let typeProduitPar = '';

  if (type === 'Base' || type === 'Patrimoine' || type === 'Valeur') {
    typeProduitPar = ProductEnum[type];
    product = '50';
  }

  const typeName = getProductName(product, typeProduitPar);
  return typeName;
}
