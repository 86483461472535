import { Action } from 'redux';
import { DownloadState } from '../DownloadState';
import { SetDownloadAction } from '../MakeEchangeModal.actions';

export function setDownloadReducer(state: DownloadState, action: Action) {
  const { downloadData } = action as SetDownloadAction;
  return {
    ...state,
    values: {
      ...state?.values,
      downloadData: {
        ...state.values?.downloadData,
        ...downloadData,
      },
    },
  };
}
