import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;
  min-height: ${54 + 32}px;
`;

export const StyledOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: -1px;
  background: rgba(255, 255, 255, 0.85);
  z-index: 10;
`;

export const StyledCentered = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
