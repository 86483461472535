import React from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';

const UPDATE_READ_NOTIFICATION = 'updateReadNotification';

export interface UpdateNotificationResourceLoaderProps {
  messages: Array<string>;
}

const UpdateNotificationResourceLoader = ({ messages }: UpdateNotificationResourceLoaderProps) => (
  <Resource
    name={UPDATE_READ_NOTIFICATION}
    method="PUT"
    url={getBffUrlForRouteWithoutQP(BffRoute.important_messages, undefined, false)}
    data={messages}
  />
);

export default UpdateNotificationResourceLoader;
