import { InforceInsuranceType, InsuranceHistoryFormData } from '~/interfaces/InsuranceHistoryPageState';
import { mapProtectionType, formatPendingInsuranceFormValues } from '.';

const formatInforceInitialValues = (inforceIns: InforceInsuranceType[]) => {
  if (inforceIns) {
    return inforceIns.map((inforceInsurance) => ({
      ...inforceInsurance,
      yearEmission: inforceInsurance?.yearEmission ? String(new Date(inforceInsurance?.yearEmission).getUTCFullYear()) : null,
    }));
  }
  return [];
};

const formatInitialValues = (insuranceHistory: InsuranceHistoryFormData) => ({
  ...insuranceHistory,
  pendingInsurance: formatPendingInsuranceFormValues(insuranceHistory?.pendingInsurance),
  insuranceRefused: insuranceHistory?.insuranceRefused?.map((insurance) => ({
    ...insurance,
    yearSubmission: new Date(insurance?.yearSubmission).getUTCFullYear(),
    formProtectionType: mapProtectionType(insurance?.protectionType),
  })),
  inforceInsurance: formatInforceInitialValues(insuranceHistory?.inforceInsurance),
});

export default formatInitialValues;
