import { FC } from 'react';
import { compose } from 'redux';
import { injectTranslations } from 'ia-react-core';
import ContractConfirmDeleteDialog, { ContractConfirmDeleteDialogProps } from './ContractConfirmDeleteDialog';
import fr from '../../translations/fr';
import en from '../../translations/en';

export default compose<FC<ContractConfirmDeleteDialogProps>>(
  injectTranslations('makeEchange', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(ContractConfirmDeleteDialog);

export * from './ContractConfirmDeleteDialog';
