import axios, { AxiosError, AxiosResponse } from 'axios';
import { getApiRootUrl as bffApiRootUrl } from '~/pages/Roles/api/config';

interface BffError {
  Status: number;
  status: number;
  message: string;
}

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const isFromBFF = (error.request as XMLHttpRequest)?.responseURL?.startsWith(bffApiRootUrl());
    const isBFF404Error = (error.response?.data as BffError)?.Status === 404;
    const isBFF400Error = (error.response?.data as BffError)?.status === 400;
    const isBFF504Error = error?.message === 'Network Error';

    if (isFromBFF && isBFF404Error && isBFF400Error) {
      const alteredResponse: AxiosResponse = { ...error.response, data: null, status: 200 };
      return Promise.resolve(alteredResponse);
    }

    if (isBFF504Error) {
      const alteredResponse: AxiosResponse = { ...error.request, data: null, status: 504 };
      return Promise.reject(alteredResponse);
    }

    return Promise.reject(error);
  },
);
