import styled from 'styled-components';
import { Container, Row } from 'ia-react-core';
import { BorderBlock } from '~/components/BorderBlock';

export const StyledRow = styled(Row)`
  margin-bottom: 20px;
  display: inline-block;
`;

export const StyledContainer = styled(Container)`
  text-align: center;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

export const StyledHTMLContainer = styled(Container)`
  h1 {
    font-size: 15.96px;

    a {
      color: #954F72;
    }
  } 
  
  a[href^="https://"] {
    color: #0563C1;
  }

  p.MsoNormal {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
`;

export const StyledLatestNewsContainer = styled(BorderBlock)`
  margin-top: 24px;
`;
