/* eslint-disable @typescript-eslint/no-unsafe-member-access */ // TODO: FormValues names are dynamic : check if we can make type safe
import IndividualModel from '~/fragments/ApplicationFragment/interfaces/IndividualModel';
import { DEFAULT_SERVICE_PROVIDER_ID, DEFAULT_SERVICE_PROVIDER_NAME, NON_REQUIREMENT_FORM_NAME, OTHER_TELEPHONE_VALUE, REQUIREMENT_FORM_DETAILS } from '../constants/ConstantNames';
import { ContactInformationViewModel } from '../interfaces/ContactInformationViewModel';
import { FormRequirementOrderDetailViewModel } from '../interfaces/FormRequirementOrderDetailViewModel';
import { RequirementOrderDetailViewModel } from '../interfaces/RequirementOrderDetailViewModel';
import { RequirementViewModel } from '../interfaces/RequirementViewModel';
import { defaultRequirementOrderDetailView } from '../constants/defaultRequirementOrderDetailView';
import { Suppliers } from '../interfaces';

const FormDataToPayload = (
  formValues: any,
  individuals: IndividualModel[],
  suppliers: Suppliers[],
): RequirementViewModel => {
  let investigationReportsDirective = null;
  if (formValues?.[`${NON_REQUIREMENT_FORM_NAME}`] && Object.keys(formValues?.[`${NON_REQUIREMENT_FORM_NAME}`]).length > 1) {
    investigationReportsDirective = {
      orderChargeEntity: formValues?.[`${NON_REQUIREMENT_FORM_NAME}`]?.orderChargeEntity2 || null,
      supplier: formValues?.[`${NON_REQUIREMENT_FORM_NAME}`]?.supplier2 ??
        formValues?.[`${NON_REQUIREMENT_FORM_NAME}`]?.orderChargeEntity2 !== undefined ? DEFAULT_SERVICE_PROVIDER_ID : '',
      supplierName: formValues?.[`${NON_REQUIREMENT_FORM_NAME}`]?.supplier2 ??
        formValues?.[`${NON_REQUIREMENT_FORM_NAME}`]?.orderChargeEntity2 !== undefined ? DEFAULT_SERVICE_PROVIDER_NAME : '',
    };
  }

  return {
    medicalRequirements: medicalRequirementsFromForm(formValues, individuals) ?? [],
    medicalRequirementsDirective: {
      orderChargeEntity: formValues?.orderChargeEntity1 || null,
      supplier: formValues?.supplier1,
      supplierName: suppliers?.find((s) => s.idOrganisme === formValues?.supplier1)?.nomOrganisme,
    },
    investigationReports: medicalRequirementsFromForm(formValues?.[`${NON_REQUIREMENT_FORM_NAME}`], individuals) ?? [],
    investigationReportsDirective,
  };
};

const medicalRequirementsFromForm = (formValues: any, individuals: IndividualModel[]):
RequirementOrderDetailViewModel[] => {
  if (!formValues || Object.keys(formValues).length <= 1) {
    return [];
  }
  const updatedMedicalRequirements = individuals?.map((individual: IndividualModel) => {
    const { id } = individual;
    const requirement: RequirementOrderDetailViewModel = defaultRequirementOrderDetailView;
    if (formValues?.[`${REQUIREMENT_FORM_DETAILS}${id}`] === undefined) {
      return { ...requirement, individualId: id };
    }
    const requirementDetails = formValues?.[`${REQUIREMENT_FORM_DETAILS}${id}`][0] as FormRequirementOrderDetailViewModel;

    const optionsBestTime = requirementDetails?.optionsBestTime;
    const telephoneNumberOption = requirementDetails?.telephoneNumberOption;

    let telephoneNumber = requirementDetails?.telephoneNumber;
    if (telephoneNumberOption !== OTHER_TELEPHONE_VALUE) {
      telephoneNumber = individual.telephones?.filter((t) => t.telephoneNumberType?.toString() ===
      telephoneNumberOption?.toString())[0]?.number;
    }

    const periodValues = optionsBestTime?.reduce((acc: number, currentValue: number) => acc + currentValue, 0);
    const updatedClientContactInfo: ContactInformationViewModel = {
      ...requirement?.clientContactInformation,
      periodeContact: periodValues,
      telephoneNumber,
      isSameLanguageSelected: requirementDetails?.isSameLanguageSelected === '' ? null :
        requirementDetails?.isSameLanguageSelected,
      otherLanguage: requirementDetails?.otherLanguage,

    };
    const updatedRequirement: RequirementOrderDetailViewModel = {
      ...requirement,
      individualId: id,
      referenceNumber: requirementDetails?.referenceNumber,
      interventionType: requirementDetails?.interventionType === '' ? null : requirementDetails?.interventionType ?? null,
      medicalRequirements: formValues?.magnumResponse ?? [],
      isObtainedFromOtherCompany: requirementDetails?.isObtainedFromOtherCompany === '' ? null : requirementDetails?.isObtainedFromOtherCompany ?? null,
      otherCompanyName: requirementDetails?.otherCompanyName,
      clientContactInformation: updatedClientContactInfo,
    };
    return updatedRequirement;
  });

  return updatedMedicalRequirements;
};

export default FormDataToPayload;
