import {
  closeModalByName, requestResourceByName, resetFormByName, setFormValuesByName, useScopedSelector,
} from 'ia-react-core';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useScreenClass } from 'react-grid-system';

import useScenario from '~/fragments/IllustrationFragment/hooks/useScenario';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { Locale, useCurrentLocale } from '~/hooks/useCurrentLocale';
import { disablePcToolForm } from '~/App.actions';
import { CoveragesPageActions } from '~/fragments/IllustrationFragment/pages/CoveragesPage/CoveragesPage.actions';
import getTextBasedOnLocale from '~/utilities/getTextBasedOnLocale';
import { useState } from 'react';
import { IS_PC_TOOL_MODAL_APPLY_BTN_DISABLED, VALIDATE_CALCULATE_NAME, P_C_TOOL_DATA_RESOURCE_NAME, PCT_FORM_NAME, PREMIUM_COMPARISON_TOOL_MODAL_NAME, RiderPlanCodes, UPDATE_SELECTED_PLAN_RESOURCE_NAME } from '../constants';
import PcToolFormModel from '../interfaces/PcToolFormModel';
import createRefreshCoverageOptionsResourcePayload from '../utilities/createRefreshCoverageOptionsResourcePayload';
import createUpdateSelectedPlanResourcePayload from '../utilities/createUpdateSelectedPlanResourcePayload';
import { PcToolDataResponseModel } from '../interfaces/PcToolDataResponseModel';
import getPCToolFormInitialValues from '../utilities/getPCToolFormInitialValues';
import { PcToolProvince } from '../interfaces/PcToolProvince';
import selectIsPcToolFormDisabled from '../selectors/selectIsPcToolFormDisabled';
import { RidersPremiumModel } from '../interfaces/RidersPremiumModel';
import formatPCToolPrice from '../utilities/formatPCToolPrice';
import updatePCToolFormValues from '../utilities/updatePCToolFormValues';

const usePremiumComparisonToolManager = () => {
  const { saleId } = useParams<{ saleId: string }>();
  const scenario = useScenario();
  const dispatch = useDispatch();
  const locale = useCurrentLocale();
  const screenClass = useScreenClass();

  const pctToolData = useScopedSelector<PcToolDataResponseModel>(`data.${P_C_TOOL_DATA_RESOURCE_NAME}`) || {} as PcToolDataResponseModel;
  const pcToolFormValues = useScopedSelector<PcToolFormModel>(`values.${PCT_FORM_NAME}`) || {} as PcToolFormModel;
  const provinces = useScopedSelector<PcToolProvince[]>('data.provinces');
  const isPcToolFormDisabled = useSelector(selectIsPcToolFormDisabled);
  const isApplyButtonDisabled = useScopedSelector<boolean>(IS_PC_TOOL_MODAL_APPLY_BTN_DISABLED);

  const scenarioId = scenario?.idDemande;
  const { premiumComparatorPlans, riderPresentationViewModels, insureds } = pctToolData;
  const { flexibleReturn: flexibleReturnChecked, simpleLinkedRiders, amountPremiumToggle: amountPremiumToggleValue, flexibleReturnPeriod, selectedPlanCode } = pcToolFormValues;
  const showFrp15Column = flexibleReturnPeriod === RiderPlanCodes.flexReturnFifteen;
  const showFrp65Column = flexibleReturnPeriod === RiderPlanCodes.flexReturnSixtyFive;
  const isProductSelected = !!saleId;
  const deathReturnChecked = simpleLinkedRiders?.some((v) => v === RiderPlanCodes.deathReturn);
  const dateFormat = locale === Locale.EN ? 'MM-dd-yyyy' : 'dd-MM-yyyy';
  const insuredName = insureds?.length ? insureds[0].entiteNommee.fullName : '';

  const [inputFieldsValueChanged, setInputFieldsValueChanged] = useState<boolean>(false);

  const onInputFieldChanged = () => {
    setInputFieldsValueChanged(true);
  };
  const handleOnInputFieldValuesChanged = () => {
    if (!inputFieldsValueChanged) return;
    setInputFieldsValueChanged(false);
    onFormValuesChanged();
  };

  const provinceDropDownOptions = () => provinces?.map((p) => ({
    value: p.name,
    label: locale === Locale.EN ? p.description_EN : p.description_FR,
  }))?.sort((a, b) => a.label.localeCompare(b.label));

  const disableForm = () => dispatch(disablePcToolForm(true));
  const enableForm = () => dispatch(disablePcToolForm(false));
  const resetForm = () => dispatch(resetFormByName(`forms.${PCT_FORM_NAME}`));

  const enableApplyBtn = () => dispatch(CoveragesPageActions.updatePcToolModalApplyBtnDisabled({ isDisabled: false }));
  const disableApplyBtn = () => dispatch(CoveragesPageActions.updatePcToolModalApplyBtnDisabled({ isDisabled: true }));

  const onApply = () => {
    const selectedPlan = premiumComparatorPlans.find((p) => p.pdfPlanCode.code === selectedPlanCode);
    disableForm();
    disableApplyBtn();
    dispatch(requestResourceByName(
      `resources.${UPDATE_SELECTED_PLAN_RESOURCE_NAME}`,
      createUpdateSelectedPlanResourcePayload(selectedPlan, locale, pcToolFormValues, pctToolData),
    ));
  };

  const onCancel = () => {
    resetForm();
    dispatch(closeModalByName(`modals.${PREMIUM_COMPARISON_TOOL_MODAL_NAME}`));
  };

  const onFormValuesChanged = (params?: Record<string, boolean | string>) => {
    disableForm();
    if (isProductSelected) disableApplyBtn();
    dispatch(requestResourceByName(
      `resources.${P_C_TOOL_DATA_RESOURCE_NAME}`,
      createRefreshCoverageOptionsResourcePayload(pcToolFormValues, pctToolData, params),
      { method: 'POST', url: getBffUrlForRoute(BffRoute.illustration_sales_comparison_tool_refresh_amounts, {}, false) },
    ));
  };

  const getSimpleLinkedRidersCheckBoxOptions = () =>
    riderPresentationViewModels?.simpleLinkedRiders?.filter((rider) => rider.genericPlanCode !== RiderPlanCodes.increasedBenefits)?.map((rider) => ({
      label: `${getTextBasedOnLocale(locale, rider.descriptionEn, rider.descriptionFr)} (${rider.genericPlanCode})`,
      value: rider?.genericPlanCode,
    }));

  const getRiderPlanCodePrice = (ridersPremiums: RidersPremiumModel[], riderPlanCode: string) => {
    const price = ridersPremiums?.find((r) => r.riderPlanCode === riderPlanCode)?.premium;
    return formatPCToolPrice(price, locale);
  };

  const onPcToolDataResourceSuccess = (data: PcToolDataResponseModel, method: string, planCode: string) => {
    if (isProductSelected) {
      if (!data?.premiumComparatorPlans.length) {
        disableApplyBtn();
      } else {
        enableApplyBtn();
      }
    }

    if (method === 'get') {
      dispatch(setFormValuesByName(`forms.${PCT_FORM_NAME}`, getPCToolFormInitialValues(data), { override: true }));
    } else {
      dispatch(setFormValuesByName(`forms.${PCT_FORM_NAME}`, updatePCToolFormValues(data, planCode)));
    }
  };

  const onPcToolDataResourceFailure = () => {
    if (isProductSelected) {
      disableApplyBtn();
    }
  };

  const onUpdateSelectedPlanResourceSuccess = () => {
    onCancel();
    resetForm();
    dispatch(requestResourceByName(`resources.${VALIDATE_CALCULATE_NAME}`));
  };

  const onModalClose = () => {
    disableForm();
    if (isProductSelected) {
      disableApplyBtn();
    }
  };

  return {
    pcToolFormValues,
    isPcToolFormDisabled,
    flexibleReturnChecked,
    deathReturnChecked,
    isProductSelected,
    amountPremiumToggleValue,
    showFrp15Column,
    showFrp65Column,
    saleId,
    scenarioId,
    premiumComparatorPlans,
    riderPresentationViewModels,
    locale,
    Locale,
    screenClass,
    selectedPlanCode,
    pctToolData,
    dateFormat,
    insuredName,
    isApplyButtonDisabled,
    provinceDropDownOptions,
    onFormValuesChanged,
    enableForm,
    onApply,
    onCancel,
    onModalClose,
    onPcToolDataResourceSuccess,
    onUpdateSelectedPlanResourceSuccess,
    getSimpleLinkedRidersCheckBoxOptions,
    getRiderPlanCodePrice,
    disableApplyBtn,
    onPcToolDataResourceFailure,
    onInputFieldChanged,
    handleOnInputFieldValuesChanged,
  };
};

export default usePremiumComparisonToolManager;
