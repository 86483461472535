import React, { FC, useMemo } from 'react';
import { OpenIdConnector, Resource, StructuredRouter, useScopedSelector } from 'ia-react-core';
import { useSelector } from 'react-redux';
import isHeadOfficeMode from '~/fragments/ApplicationFragment/utilities/isHeadOfficeMode';
import ROUTES from './constants/ROUTES';
import GetVersionNumberOptionsResource from './components/Resources/GetVersionNumberOptionsResource';
import SharedResources from './components/SharedResources';
import { EMPLOYEE_SCOPE, ADVISOR_SCOPE } from './constants/API';
import { BffRoute, getBffUrlForRoute } from './utilities/bffHelper';
import { getFormattedDateTostring, getStringToFormattedDatestring } from './utilities/getFormattedDateVals';
import MainLayout from './layouts/MainLayout/MainLayout';
import { State } from './interfaces/State';
import RequestInterceptor from './interceptors/requestInterceptor';
import useIsFeatureEffective from './hooks/useIsFeatureEffective';
import { FEATURE_TOGGLE_RESOURCE_NAME } from './constants';
import NoAccess from './components/NoAccess/NoAccess';
import { AppVersionNumber } from './interfaces/AppVersionNumber';
import { selectEffectiveDate, selectEffectiveDateFromForm, selectVersion, selectVersionNumberOptions } from './utilities/selectors';
import { StyledAptyDataField } from './App.styles';
import { ThemeProvider } from './components/ThemeProvider';
import { ZoomProvider } from './components/ZoomProvider';

export interface AppProps { }

const App: FC<AppProps> = () => {
  const isEmployeeRoute = process.env.REACT_APP_EMP_ROUTES.split(',').includes(window.location.origin);
  const OpenIdConfig = useMemo(() => {
    const commonConfig = {
      authority: process.env.REACT_APP_AUTH_AUTHORITY_URL,
      scope: isEmployeeRoute ? EMPLOYEE_SCOPE : ADVISOR_SCOPE,
      redirectUri: `${window.location.origin}/`,
    };
    if (isEmployeeRoute) {
      return {
        ...commonConfig,
        clientId: process.env.REACT_APP_EMP_AUTH_CLIENT_ID,
      };
    }
    return {
      ...commonConfig,
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    };
  }, [isEmployeeRoute]);

  const url = getBffUrlForRoute(BffRoute.user_profile, undefined, false);
  const userPermissions = useSelector((state: State) => state?.App?.data?.permissions);
  const rolePermissions = useSelector((state: State) => state?.App?.Pages?.RolesPage?.data?.roles?.permissions);
  const userRole = useSelector((state: State) => state?.App?.data?.permissions?.role);
  const isHeadOffice = useMemo(() => isHeadOfficeMode(userRole), [userRole]);
  const agencyNumber = useMemo(() => {
    if (userPermissions?.agentInformation?.infosContractuellesAgent?.length > 0) {
      return userPermissions?.agentInformation?.infosContractuellesAgent[0].agence;
    }
    return '';
  }, [userPermissions]);

  const aboutUrl = getBffUrlForRoute(BffRoute.aboutinfo_about, undefined, false);

  const featureTogglesUrl = getBffUrlForRoute(BffRoute.feature_toggles);
  const getRoles = (
    <Resource
      name="permissions"
      url={url}
      autoRequest={rolePermissions !== null || rolePermissions !== undefined}
    />
  );
  const dateFromSelector = useSelector((state: State) => selectEffectiveDateFromForm(state) || selectEffectiveDate(state));
  const effectiveDate = (dateFromSelector !== null && dateFromSelector !== undefined && dateFromSelector !== '') ? getStringToFormattedDatestring(dateFromSelector) : getFormattedDateTostring(new Date());
  const getVersionNumberOptions = isHeadOffice ? (<GetVersionNumberOptionsResource effectiveDate={effectiveDate} />) : null;
  const versionNumberArr: AppVersionNumber[] = useSelector((state: State) => selectVersionNumberOptions(state));
  const versionNumFromSelector = useSelector((state: State) => selectVersion(state));
  const versionNumber = versionNumFromSelector ?? versionNumberArr[versionNumberArr.length - 1].actualVersionNumber;

  const featureNames = [process.env.REACT_APP_FT_PERMISSION_ACCESS];
  // fallback to true so the devs wont be blocked
  // will be removed in the near future
  const isAccessFeatureEffective = useIsFeatureEffective(featureNames) ?? true;
  const isFeatureToggleResourcePending = useScopedSelector(`resources.${FEATURE_TOGGLE_RESOURCE_NAME}.pending`);

  return (
    <ZoomProvider>
      <ThemeProvider>
        {
          process.env.REACT_APP_MOCK_API_AUTH === 'true' ?
            <>
              {getRoles}
              {getVersionNumberOptions}
              {userPermissions !== undefined && userPermissions !== null && (
                <>
                  <Resource name="resources.featureToggles" url={featureTogglesUrl} autoRequest />

                  {!isAccessFeatureEffective && !isFeatureToggleResourcePending && <NoAccess />}

                  {isAccessFeatureEffective &&
                  <>
                    <Resource autoRequest url={aboutUrl} name="aboutResource" />
                    <MainLayout>
                      {
                        isHeadOffice ? (versionNumber !== null && versionNumber !== '' && <SharedResources effectiveDate={effectiveDate} versionNumber={versionNumber} />) : <SharedResources />
                      }
                    </MainLayout>
                    <StyledAptyDataField id="apty_DistributionNetwork">{userPermissions?.extranetCode}</StyledAptyDataField>
                    <StyledAptyDataField id="apty_AgencyNumber">{agencyNumber}</StyledAptyDataField>
                    <StyledAptyDataField id="apty_UserRole">{userPermissions?.role}</StyledAptyDataField>
                  </>}
                </>
              )}
            </>
            :
            <OpenIdConnector
              name="apiGateway"
              loginUrl={isEmployeeRoute ? process.env.REACT_APP_EMP_LOGIN_URL : process.env.REACT_APP_LOGIN_URL}
              signInMethod="redirect"
              config={OpenIdConfig}
              onSuccess={RequestInterceptor}
            >
              <Resource name="resources.featureToggles" url={featureTogglesUrl} autoRequest />

              {!isAccessFeatureEffective && !isFeatureToggleResourcePending && <NoAccess />}
              {isAccessFeatureEffective &&
              <>
                <Resource url={aboutUrl} name="aboutResource" autoRequest />
                <MainLayout>
                  {getRoles}
                  {getVersionNumberOptions}
                  {userPermissions !== undefined && userPermissions !== null && (
                    <>
                      {
                        isHeadOffice ? (versionNumber !== null && versionNumber !== '' && <SharedResources effectiveDate={effectiveDate} versionNumber={versionNumber} />) : <SharedResources />
                      }
                      <StructuredRouter routes={ROUTES} />
                    </>
                  )}
                </MainLayout>
                <StyledAptyDataField id="apty_DistributionNetwork">{userPermissions?.extranetCode}</StyledAptyDataField>
                <StyledAptyDataField id="apty_AgencyNumber">{agencyNumber}</StyledAptyDataField>
                <StyledAptyDataField id="apty_UserRole">{userPermissions?.role}</StyledAptyDataField>
              </>}
            </OpenIdConnector>
        }
      </ThemeProvider>
    </ZoomProvider>
  );
};

export default App;
