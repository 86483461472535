export default {
  about: 'À propos',
  appName: 'Assurance Vie',
  error: "Désolé, quelque chose s'est mal passé",
  settings: 'Paramètres',
  help: 'Aide',
  home: 'Accueil',
  work: 'Travail',
  saved: 'Enregistrer',
  delete: 'Supprimer',
  reset: 'Réinitialiser',
  copy: 'Dupliquer',
  add: 'Ajouter',
  illustration_settings: "Paramètres d'illustration",
  contact_us: 'Contactez-nous',
  privacy: 'Confidentialité',
  tos: "Conditions d'utilisation",
  accessibility: 'Accessibilité',
  advisors: 'Conseillers',
  illustrations: 'Illustrations',
  signature: 'Signature',
  scenario: 'Scénario',
  coverages: 'Protections',
  concept: 'concepts',
  coverage: 'couverture',
  result: 'resultats',
  report: 'rapports',
  concepts: 'Concepts',
  results: 'Resultats',
  reports: 'Rapports',
  marginal_rate: 'Taux marginal',
  province: 'Choisir...',
  yes: 'Oui',
  no: 'Non',
  scenarioDrawerTitle: 'Scénarios',
  alerts: 'Alerts',
  autosaveText: 'Enregistrement automatique',
  loading: 'Chargement',
  OIPA_environments: 'Environnements OIPA',
  deleteScenarioTitle: 'Supprimer ce scénario',
  deleteScenarioDesc1: 'Veuillez cliquer sur Oui pour supprimer ce scénario. Vous ne pourrez plus annuler ce changement.',
  deleteScenarioDesc2: 'Voulez-vous continuer ?',
  resetScenarioTitle: 'Réinitialiser ce scénario',
  resetScenarioDesc1: 'Cette action aura pour effet de supprimer toutes les protections et de réinitialiser le scénario, voulez-vous continuer ?',
  noAccessErrorTitle: 'No Access',
  noAccessErrorMsg: 'You do not have access to this page.',
  print: 'Liste des documents disponibles pour l\'impresion',
  notifications: 'Notifications',
  accessibilityUrl: 'https://ia.ca/accessibilite',
};
