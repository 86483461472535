import { Action } from 'redux';
import SectionIdentityModel from '~/interfaces/InsurabilityDeclaration/SectionIdentityModel';
import { set, get } from 'lodash';
import { DeleteInstanceSectionSuccessAction } from '../ChangeRequest.actions';

import { ChangeRequestState } from '../interfaces/State';

export const deleteInstanceSectionSuccess = (state: ChangeRequestState, action: Action) => {
  const { repeatingSectionId, instanceId } = action as DeleteInstanceSectionSuccessAction;

  const clonedCase = structuredClone(state.changeRequest);

  const instances: SectionIdentityModel[] =
    get(
      clonedCase,
      `${clonedCase.repeatingSections[repeatingSectionId].casePath$}.instances`,
    );

  if (!instances || instances.length === 0) {
    throw new Error('Unable to access repeatingSection instances');
  }

  // Remove instance with the given id
  const newInstanceArray = instances.filter((i) => i.id !== instanceId);

  set(
    clonedCase,
    `${clonedCase.repeatingSections[repeatingSectionId].casePath$}.instances`,
    newInstanceArray,
  );

  return {
    ...state,
    changeRequest: clonedCase,
  };
};
