import { Resource, ResourceProps } from 'ia-react-core';
import React, { FC } from 'react';
import { useSetPageValidationFormMessage } from '../hooks/useSetPageValidationFormMessage';

export interface ResourceFormValidationProps extends ResourceProps<null, null> {
  onValidationAPISuccess?: () => any;
  formName?: string;
  page?: string;
}

const ResourceFormValidation: FC<ResourceFormValidationProps> = ({ onValidationAPISuccess, formName, page, ...props }) => {
  const setPageValidationFormMessage = useSetPageValidationFormMessage(onValidationAPISuccess, page, formName);
  return (
    <Resource
      {...props}
      onSuccess={setPageValidationFormMessage}
    />
  );
};

export default ResourceFormValidation;
