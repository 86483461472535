import React, { FC, useEffect } from 'react';
import SectionIdentityModel from '~/interfaces/InsurabilityDeclaration/SectionIdentityModel';
import {
  Col, DeleteIcon, Hidden, requestResourceByName, Row, TertiaryButton, Visible,
} from 'ia-react-core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '~/interfaces/AppState';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { deleteInstanceSectionSuccessAction } from '~/fragments/SharedLibrary/ChangeRequest.actions';
import CompactSection from './CompactSection';
import { StyledQuestionContainer, StyledQuestionLabel, StyledQuestionLabelContainer } from './Question/Question.styles';

export interface CompactInstanceSectionProps {
  section: SectionIdentityModel;
  isAloneInstance: boolean;
  parentRepeatingId: string;
  formId: string;
  sectionIndex: number;
}

const CompactInstanceSection: FC<CompactInstanceSectionProps> = ({ formId, section: sectionIdentity, isAloneInstance, parentRepeatingId, sectionIndex }) => {
  const dispatch = useDispatch();

  const selectedCase = useSelector((s: AppState) => s.App?.fragments?.ApplicationFragment?.changeRequest);
  const deleteRepeatingSection = useSelector((s: AppState) => s.App?.fragments?.ApplicationFragment?.data?.delete_repeating_section);

  useEffect(() => {
    if (deleteRepeatingSection && (deleteRepeatingSection.id === sectionIdentity.id)) {
      dispatch(deleteInstanceSectionSuccessAction(parentRepeatingId, sectionIdentity.id));
    }
  }, [deleteRepeatingSection]);

  const handleDeleteClick = () => {
    const param = { saleId: selectedCase.id, repeatingSectionId: parentRepeatingId, sectionId: sectionIdentity.id };
    const url = getBffUrlForRouteWithoutQP(BffRoute.delete_repeating_section, param, false);
    dispatch(requestResourceByName('resources.delete_repeating_section', param, { url }));
  };

  return (
    <StyledQuestionContainer>
      <Row>
        <Col xl={11} lg={11} md={11} sm={11}>
          <Row>
            <Hidden xs sm>
              {
                sectionIndex === 0 && sectionIdentity.questions.map((q) => (
                  <Col key={q.id} xl={selectedCase.questions[q.id].size?.xl} lg={selectedCase.questions[q.id].size?.lg} md={selectedCase.questions[q.id].size?.md} sm={selectedCase.questions[q.id].size?.sm}>
                    <StyledQuestionLabelContainer>
                      <StyledQuestionLabel>
                        {selectedCase.questions[q.id].title}
                      </StyledQuestionLabel>
                    </StyledQuestionLabelContainer>
                  </Col>
                ))
              }
            </Hidden>
          </Row>
        </Col>
        <Col xl={1} lg={1} md={1} sm={1}></Col>
      </Row>
      <Row>
        <Col style={{ padding: '0px' }}>
          <CompactSection formId={formId} section={sectionIdentity} key={sectionIdentity.id} />
        </Col>
        <Col style={{ padding: '0px', margin: 'auto' }} xl={1} lg={1} md={1} sm={1}>
          { !isAloneInstance &&
            <Hidden sm xs>
              <TertiaryButton text LeftIcon={DeleteIcon} onClick={handleDeleteClick}>&nbsp;</TertiaryButton>
            </Hidden>}
        </Col>
      </Row>
      { !isAloneInstance &&
        <Visible sm xs>
          <Row>
            <Col>
              <TertiaryButton text LeftIcon={DeleteIcon} onClick={handleDeleteClick}>{selectedCase?.repeatingSections[parentRepeatingId]?.deleteText}</TertiaryButton>
            </Col>
          </Row>
        </Visible>}
    </StyledQuestionContainer>
  );
};

export default CompactInstanceSection;
