export default {
  about: 'About',
  buildVersion: 'Build version',
  mode: 'Mode',
  evoVersion: 'Evo version',
  userCode: 'User code',
  microServices: 'Microservices',
  featureToggles: 'Active toggles',
  logoDesc: 'iA Financial Group is a trade mark and another name under which Industrial Alliance Insurance and Financial Services Inc. operates.',
  close: 'Close',
  environment: 'Environment',
  environmentOipa: 'OIPA Environment',
  ADVISOR: 'Advisor',
  HO_DATAENTRY: 'iA Head Office Data Entry',
  HO_DATAENTRY_VIP: 'iA Head Office Data Entry VIP',
  HO_SP_QOUTE: 'iA Head Office Special Quotation',
  HO_SP_QOUTE_VIP: 'iA Head Office Special Quotation VIP',
  STUDENT: 'Student',
  GUEST: 'Guest',
  copyright: 'Copyright',
  ia: 'iA Financial Group',
  development: 'Development',
  production: 'Production',
  test: 'Test',
  qa: 'QA',
};
