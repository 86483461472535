import { TFunction } from 'i18next';
import { RadioList, RadioListProps, ToggleButtonListOption } from 'ia-react-core';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface YesNoToggleButtonsListProps extends Omit<RadioListProps<boolean>, 'options'> {
  value: any;
  lng?: string;
  hasError?: boolean;
  hasWarning?: boolean;
  optionItems?: ToggleButtonListOption<any>[];
}

export const YesNoListOptions = (t: TFunction) => ([
  { label: t('yes'), value: true },
  { label: t('no'), value: false }]);

const YesNoToggleButtonsList: FC<YesNoToggleButtonsListProps> = ({ onChange, lng, value, hasError, hasWarning, optionItems, horizontal = true, ...props }) => {
  const { t } = useTranslation();

  // TODO Fix translation
  let options = useMemo<ToggleButtonListOption<boolean>[]>(
    () => [
      { label: t('yes'), value: true },
      { label: t('no'), value: false }],
    [t],
  );
  if (optionItems != null) {
    options = optionItems;
  }

  // Disregard non-boolean values
  // const normalizedValue = typeof value === 'boolean' ? value : undefined;

  return (
    <div className={`${hasWarning ? 'warning' : ''}`}>
      <RadioList
        {...props}
        value={value}
        onChange={onChange}
        options={options}
        horizontal={horizontal}
        hasWarning={hasWarning}
        isTouched
        isValid={!hasError}
        noBox={!hasError && !hasWarning}
      />
    </div>
  );
};

export default YesNoToggleButtonsList;
