import { CautionMessage, ControlAnchor, ErrorMessage, InfoMessage, Link, Spacing, WarningMessage } from 'ia-react-core';
import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import { ValidationMessageLevel } from '~/interfaces/ValidationMessageLevel';
import { StyledErrorContainer, StyledMessageList } from './HoistedServerErrors.styles';
export interface HoistedServerErrorsProps {
  selector?: <State, >(scopedState: State | any) => ValidationMessage[];
  controlAnchorMap: Record<string, string>;
  extraMessages?: ValidationMessage[];
  marginBottom?: number;
  nomineeId?: string;
  page?: string;
}

const HoistedServerErrors = ({ controlAnchorMap, extraMessages, marginBottom = 24, selector, nomineeId, page }: HoistedServerErrorsProps) => {
  const { t } = useTranslation('HoistedServerErrors');
  const theme = useTheme();

  const defaultSelector = (): null => null;
  const validationMessages = useSelector(
    typeof selector === 'string' ? defaultSelector : selector,
  );

  const filteredMessages = nomineeId
    ? validationMessages?.filter((message) => message.noParticipant === nomineeId)
    : validationMessages;

  const locale = useCurrentLocale();
  const withAnchor = (text: string, control: string, anchorText: string) => {
    if (page === 'applicant') {
      const elementListByName = document.getElementsByName(control);
      const elementListByClassName = document.getElementsByClassName(control);
      const elementList = elementListByName?.length > 0 ? elementListByName : elementListByClassName;
      if (['gender', 'dateOfBirth'].includes(control)) {
        return text;
      }
      if (elementList && elementList?.length > 0) {
        return <>{ text } {control && <Link onClick={() => elementList[0].scrollIntoView()}>{anchorText}</Link>}</>;
      }
      return text;
    }
    return <>{ text } {control && <ControlAnchor name={control}>{anchorText}</ControlAnchor>}</>;
  };

  const allMessages = useMemo(() => {
    const errors: ReactNode[] = [];
    const warnings: ReactNode[] = [];
    const cautions: ReactNode[] = [];
    const infos: ReactNode[] = [];
    const messages = (filteredMessages ?? [])?.concat(extraMessages) || [];
    messages?.forEach((data) => {
      const messageData = {
        en: data?.messageEN,
        fr: data?.messageFR,
      };

      const text = messageData[locale];
      const control = controlAnchorMap?.[data?.nomPropriete];
      switch (data?.categorie) {
        case ValidationMessageLevel.Error:
          errors.push(withAnchor(text, control, t('seeError')));
          break;
        case ValidationMessageLevel.WarningCritique:
          warnings.push(withAnchor(text, control, t('seeWarning')));
          break;
        case ValidationMessageLevel.Warning:
          cautions.push(withAnchor(text, control, t('seeWarning')));
          break;
        case ValidationMessageLevel.Notice:
          infos.push(withAnchor(text, control, t('seeInfo')));
          break;
        default:
          break;
      }
    });

    return { errors, warnings, cautions, infos };
  }, [validationMessages, filteredMessages, t, extraMessages, controlAnchorMap]);

  const hasErrors = allMessages?.errors?.length > 0;
  const hasWarnings = allMessages?.warnings?.length > 0;
  const hasCautions = allMessages?.cautions?.length > 0;
  const hasInfos = allMessages?.infos?.length > 0;
  return (
    <StyledErrorContainer>
      {
        hasErrors &&
        <Spacing m-b={marginBottom}>
          <ErrorMessage useBullets errorTitle={t('error')} error={allMessages?.errors} />
        </Spacing>
      }

      {
        hasWarnings &&
        <Spacing m-b={marginBottom}>
          <ErrorMessage className="warning" useBullets errorTitle={t('warning')} as={WarningMessage} iconColor={theme?.colors?.warning} error={allMessages?.warnings} />
        </Spacing>
      }

      {
        hasCautions &&
        <Spacing m-b={marginBottom}>
          <ErrorMessage className="warning caution" useBullets errorTitle={t('warning')} as={CautionMessage} iconColor={theme?.colors?.caution} error={allMessages?.cautions} />
        </Spacing>
      }
      {
        hasInfos &&
        <Spacing m-b={marginBottom}>
          <Spacing m-b={marginBottom}>
            <InfoMessage>
              {allMessages?.infos?.map((info, index) => <StyledMessageList key={index}>{info}</StyledMessageList>)}
            </InfoMessage>
          </Spacing>
        </Spacing>
      }
    </StyledErrorContainer>
  );
};

export default React.memo(HoistedServerErrors);
