import moment from 'moment';

export const getFormattedDateTostring = (date: Date): string => (`${date.getFullYear()}-${(date.getMonth() + 1)}-${date.getDate()} 00:00:00`);

export const getStringToFormattedDate = (date: string): Date => {
  const dateString = Date.parse(date);
  return new Date(dateString);
};

export const getStringToFormattedDatestring = (date: string): string => {
  const dateStrToDate = new Date(Date.parse(date));
  return moment(dateStrToDate).format('YYYY-MM-DD HH:mm:ss');
};

export const getStringToIsoFormattedDateString = (dateStr: string): string => {
  if (!dateStr) return null;
  return new Date(Date.parse(dateStr)).toISOString().substring(0, 19).concat('Z');
};
