import { Roles } from '~/pages/Roles/constants';
import { RouteSectionDefinition } from 'ia-react-core';
import { NOMINEE_ROUTES_KEY, nomineeRoutes } from '~/constants/NOMINEE_ROUTES';
import IndividualModel from '../interfaces/IndividualModel';

function generateWorkflowRoutesForAnNominee(nominee: IndividualModel, saleId: string, userRole: Roles): RouteSectionDefinition {
  // Path element to replace with current nominee values
  const mapObj: Record<string, string> = {
    ':saleId': saleId,
    ':clientId': nominee.id,
    ':formName': '',
  };

  // Remove declaration base route
  const { [NOMINEE_ROUTES_KEY.DECLARATIONS_BASE]: _, ...filteredRoutes } = nomineeRoutes;
  if (userRole !== Roles.EVO_Employee
  ) {
    delete filteredRoutes[NOMINEE_ROUTES_KEY.SPECIAL_INSTRUCTIONS];
  }

  const updatedPathRoutes = Object.values(filteredRoutes)?.map((route) => {
    const path = route.path as { fr: string; en: string };

    const en = path.en.replace(/:saleId|:clientId|:formName/gi, (m) => mapObj[m]);
    const fr = path.fr.replace(/:saleId|:clientId|:formName/gi, (m) => mapObj[m]);

    return { ...route, path: { en, fr } as { fr: string; en: string } };
  });
  return { ...nomineeRoutes, routes: updatedPathRoutes, label: { fr: nominee.name, en: nominee.name } };
}

export default generateWorkflowRoutesForAnNominee;
