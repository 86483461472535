import 'core-js/stable';
import 'regenerator-runtime/runtime';
import FontFaceObserver from 'fontfaceobserver';

import { render } from 'ia-react-core';

import logger from './utilities/logger';
import './interceptors';
import ConfiguredApp from './ConfiguredApp';

const montserrat = new FontFaceObserver('Montserrat');
const openSans = new FontFaceObserver('Open Sans');

Promise.all([
  montserrat.load(),
  openSans.load(),
]).then(() => {
  render(ConfiguredApp);
}, () => {
  logger.warn('Fonts could not be loaded');
});
