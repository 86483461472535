import React, { FC } from 'react';
import { ConfirmDialog, P, WarningIcon } from 'ia-react-core';
import { useTranslation } from 'react-i18next';

export const CONTRACT_CONFIRM_CANCEL_DIALOG = 'ContractConfirmCancelDialog';

export interface ContractCancelConfirmDialoggProps {
  onConfirm: () => void;
}

const ContractCancelConfirmDialog: FC<ContractCancelConfirmDialoggProps> = ({ onConfirm }: ContractCancelConfirmDialoggProps) => {
  const { t } = useTranslation('makeEchange');

  return (
    <ConfirmDialog
      name={CONTRACT_CONFIRM_CANCEL_DIALOG}
      cancelButton={t('confirmDownloadModal.no')}
      confirmButton={t('confirmDownloadModal.yes')}
      onConfirm={onConfirm}
      Icon={WarningIcon}
      color="#ffdc00"
      noheader
    >
      <P>{t('confirmCancelContentOne')}</P>
      <P>{t('confirmCancelContentTwo')}</P>
    </ConfirmDialog>
  );
};

export default ContractCancelConfirmDialog;
