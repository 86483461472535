import Action from '~/interfaces/actions';
import { InsurabilityDeclarationState } from '~/interfaces/InsurabilityDeclaration/State';
import { LoadCaseSuccess } from '../InsurabilityDeclaration.actions';

export default function loadCaseSuccess(state: InsurabilityDeclarationState, action: Action): InsurabilityDeclarationState {
  const { caseModel } = action as LoadCaseSuccess;

  const showValidation = { ...state.showValidation };

  const forms = caseModel?.parties?.[0]?.forms ?? [];

  // Add any form that isnt already there
  forms.forEach((form) => {
    const formId = form.id;
    const hasForm = Boolean(formId in showValidation);
    if (!hasForm) {
      showValidation[formId] = false;
    }
  });

  return {
    ...state,
    case: caseModel,
    initialLoad: false,
    showValidation,
  };
}
