import ExplanatoryTableModel from '~/interfaces/InsurabilityDeclaration/ExplanatoryTableModel';
import ExplanatoryTableDto from '../dtos/ExplanatoryTableDto';
import ExplanatoryTableColumnBuilder from './ExplanatoryTableColumnBuilder';
import ExplanatoryTableRowBuilder from './ExplanatoryTableRowBuilder';

export default class ExplanatoryTableBuilder {
  toModel(dto: ExplanatoryTableDto): ExplanatoryTableModel {
    const columnBuilder = new ExplanatoryTableColumnBuilder();
    const rowBuilder = new ExplanatoryTableRowBuilder();

    return new ExplanatoryTableModel(
      dto.id,
      dto.columns.map((c) => columnBuilder.toModel(c)),
      dto.rows.map((r) => rowBuilder.toModel(r)),
    );
  }
}
