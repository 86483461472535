import {
  ActionBar,
  Loader,
  Modal,
  PrimaryButton,
  SafeHtml,
  T2,
  closeModalByName,
} from 'ia-react-core';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslatedFile, getTranslatedValue } from '~/fragments/HomeFragment/components/CreateIllustration/utilities';
import { InsuranceGroup } from '~/interfaces/InsuranceGroup';
import { State } from '~/interfaces/State';
import { SectionDivider } from '../SectionDivider';
import { IllustrationHelpFilePath } from './constants';
import { StyledCreateIllustrationHelpModalContainer } from './CreateIllustrationHelpModal.styles';
export interface CreateIllustrationHelpModalProps {
  data?: InsuranceGroup;
}

const CreateIllustrationHelpModal: FC<CreateIllustrationHelpModalProps> = ({ data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('CreateIllustrationHelpModal');
  const [coveragesDesc, setCoveragesDesc] = React.useState();
  const locale = useSelector((state: State) => state?.language?.locale);

  const handleClose = () => {
    dispatch(closeModalByName('modals.createIllustrationHelp'));
  };

  useEffect(() => {
    if (data) {
      const fileName = (getTranslatedFile(data, locale)?.split('.')[0])?.toUpperCase();
      const filePath = IllustrationHelpFilePath[fileName as keyof typeof IllustrationHelpFilePath];
      const url = `${process.env.REACT_APP_CDN_BASE_PATH}/2.0.0/${filePath}`;

      fetch(url)
        .then((response) => response.text())
        .then((res: any) => { setCoveragesDesc(res); })
        .catch((error: any) => { setCoveragesDesc(error); });
    }
  }, [data, locale]);

  return (
    <Modal name="createIllustrationHelpModal" isClosable>
      <StyledCreateIllustrationHelpModalContainer>
        <T2 marginBottom={0}>{getTranslatedValue(data, locale)}</T2>

        {
          !coveragesDesc
            ? <Loader />
            : <SafeHtml>{coveragesDesc}</SafeHtml>
        }

        <SectionDivider />

        <ActionBar flexButtonsXs nopadding>
          <PrimaryButton onClick={() => handleClose()}>
            {t('close')}
          </PrimaryButton>
        </ActionBar>
      </StyledCreateIllustrationHelpModalContainer>
    </Modal>
  );
};
export default CreateIllustrationHelpModal;
