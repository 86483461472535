/* eslint-disable react/no-danger */
/* eslint-disable no-param-reassign */
import React from 'react';
import { ImportantMessage } from '~/interfaces/ImportantMessage';
import DOMPurify from 'dompurify';

export function getLanguageDesc(notification: ImportantMessage, language: string) {
  if (language === 'fr') {
    return notification.description.fr;
  }

  return notification.description.en;
}
export function getLanguageTitle(notification: ImportantMessage, language: string) {
  if (language === 'fr') {
    return notification.title.fr;
  }

  return notification.title.en;
}

export function processMarkDown(theText: string) {
  let thatText = '';

  thatText = theText.replace(/\\r\\n/gi, '\n');

  return thatText;
}

export function parseRegex(mark: string) {
  const rules = [
    { regex: /(?:^|\n)###### (.+)/g, replacement: '<h6>$1</h6>' },
    { regex: /\*\*(.+?)\*\*/g, replacement: '<strong>$1</strong>' },
    { regex: /\* (.+)/g, replacement: '<li>$1</li>' },
    { regex: /\+ (.+)/g, replacement: '<li>$1</li>' },
    { regex: /- (.+)/g, replacement: '<li>$1</li>' },
    { regex: /\?\?/g, replacement: '' },
    { regex: /\?/g, replacement: '' },
    { regex: /\*(.+?)\*/g, replacement: '<i>$1</i>' },
    { regex: /\*(.+?)\*/g, replacement: '<i>$1</i>' },
  ];

  rules.forEach((rule) => {
    mark = mark.replace(rule.regex, rule.replacement);
  });

  mark = mark.replace(/(<li>.*<\/li>)/g, '<ul style="list-style-type: disc;margin-bottom: 10px;">$1</ul>');

  mark = mark.replace(/\[([^\]]+)\]\(([^)]+)\)/g, (match: any, linkText: any, url: string) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = `http://${url}`;
    }
    return `<a href="${url}">${linkText}</a>`;
  });

  return mark;
}

export const MarkdownRender = (markdown: string) => {
  const rawHtml = convertMarkdownToHtml(markdown)?.replace(/href/g, "target='_blank' href");
  const cleanHtml = DOMPurify.sanitize(rawHtml, { ADD_ATTR: ['target'] });

  return <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
};

export const convertMarkdownToHtml = (markdown: string): string => {
  const rules = [
    { regex: /(?:^|\n)###### (.+)/g, replacement: '<h6>$1</h6>' },
    { regex: /\*\*(.+?)\*\*/g, replacement: '<strong>$1</strong>' },
    { regex: /\* (.+)/g, replacement: '<li>$1</li>' },
    { regex: /\+ (.+)/g, replacement: '<li>$1</li>' },
    { regex: /- (.+)/g, replacement: '<li>$1</li>' },
    { regex: /\?\?/g, replacement: '' },
    { regex: /\?/g, replacement: '' },
    { regex: /\*(.+?)\*/g, replacement: '<i>$1</i>' },
    { regex: /\*(.+?)\*/g, replacement: '<i>$1</i>' },
  ];

  let html = markdown;

  rules.forEach((rule) => {
    html = html.replace(rule.regex, rule.replacement);
  });

  html = html.replace(/(<li>.*<\/li>)/g, '<ul style="list-style-type: disc;margin-bottom: 10px;">$1</ul>');

  html = html.replace(/\[([^\]]+)\]\(([^)]+)\)/g, (match: any, linkText: any, url: string) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = `http://${url}`;
    }
    return `<a href="${url}">${linkText}</a>`;
  });

  return html;
};
