import { set, cloneDeep } from 'lodash';
import { BeneficiaryFormState, BeneficiaryPageState } from '~/interfaces/BeneficiaryPageState';

export default (formValues: BeneficiaryFormState, beneficiaryPageState: BeneficiaryPageState): BeneficiaryPageState => {
  const beneficiaries = cloneDeep(beneficiaryPageState);
  const index = beneficiaries?.insuredBeneficiaries?.findIndex((item) => item.individualId === formValues?.beneficiary?.individualId);
  if (index < 0) {
    beneficiaries?.insuredBeneficiaries.push(formValues?.beneficiary);
  } else {
    set(beneficiaries, `insuredBeneficiaries[${index}]`, formValues?.beneficiary);
  }
  if (formValues?.hasAccumulationBeneficiary) {
    beneficiaries.accumulationBeneficiary = formValues.accumulationBeneficiary;
  }
  if (formValues?.relations) {
    formValues?.relations.forEach((relation) => {
      beneficiaries?.relations
        .filter((item) => item.comparedIndividualId === relation.comparedIndividualId)
        .forEach((item) => {
          item.descriptionRelation = relation.descriptionRelation;
          item.codeTypeRelation = relation.codeTypeRelation;
          item.detailRelation = relation.detailRelation;
        });
    });
  }
  return beneficiaries;
};
