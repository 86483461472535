import React, { FC } from 'react';
import { ConfirmDialog, P, WarningIcon, useScopedSelector } from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import { DownloadState } from '../../DownloadState';

export const CONTRACT_CONFIRM_DELETE_DIALOG = 'contractConfirmDeleteDialog';

export interface ContractConfirmDeleteDialogProps {
  onConfirm: (indx: number) => void;
}

const ContractConfirmDeleteDialog: FC<ContractConfirmDeleteDialogProps> = ({ onConfirm }: ContractConfirmDeleteDialogProps) => {
  const { t } = useTranslation('makeEchange');
  const insuredIndex = useScopedSelector((state: DownloadState) => state?.modals?.contractConfirmDeleteDialog?.data?.index);

  return (
    <ConfirmDialog
      name={CONTRACT_CONFIRM_DELETE_DIALOG}
      cancelButton={t('confirmDownloadModal.no')}
      confirmButton={t('confirmDownloadModal.yes')}
      onConfirm={() => onConfirm(insuredIndex)}
      Icon={WarningIcon}
      color="#ffdc00"
      noheader
    >
      <P>{t('confirmDeleteContentOne')}</P>
      <P>{t('confirmDeleteContentTwo')}</P>
    </ConfirmDialog>
  );
};

export default ContractConfirmDeleteDialog;
