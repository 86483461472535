import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionBar,
  ConfirmDialog,
  Divider,
  Form,
  FormCol,
  FormRow,
  InfoMessage, Modal, P, PrimarySubmitButton, Resource, SecondaryButton, Spacing, T2, TextControl,
  WarningIcon,
  closeModalByName,
  openModalByName,
  requestResourceByName,
  requiredValidator,
} from 'ia-react-core';
import ControlAnchorLink from '~/components/ControlAnchorLink/ControlAnchorLink';
import { AUTO_SUGGEST_RESOURCE_INPUT_REGEX } from '~/fragments/ApplicationFragment/constants/regex';
import { useDispatch, useSelector } from 'react-redux';
import { BffRoute, getBffUrlForRoute, getBffUrlForRouteWithoutQP, getRelativeUrlForRoute, getUrlForFileDownload, relativeRoute } from '~/utilities/bffHelper';
import { useHistory } from 'react-router';
import { State } from '~/interfaces/State';
import HoistedServerErrors from '~/fragments/ApplicationFragment/components/HoistedServerErrors';
import { hasValidationError } from '~/fragments/ApplicationFragment/utilities';
import { ValidationMessageLevel } from '~/interfaces/ValidationMessageLevel';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import { SaleFile } from '../interfaces/SaleFile';
import { Stage, stagesBeforeApplication } from '../constants';

export interface FileDownloadModalProps {
  handleSaleMigrate: (saleFile: SaleFile) => void;
  handleRelativePath: (relativePath: string) => void;
  isChangeRequest?: boolean;
}
const FileDownloadModal: FC<FileDownloadModalProps> = ({ isChangeRequest }: FileDownloadModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const downloadFileUrl: string = getBffUrlForRoute(BffRoute.download_file);
  const { t } = useTranslation('SaleFileSection');
  const [sharedKey, setSharedKey] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);

  const handleDownloadFile = (key: string, intentType: string) => {
    const requestResource = intentType === 'Validate' ? 'resources.validateFile' : 'resources.downloadFile';
    const endpointUrl = getUrlForFileDownload({ sharedKey: key, intentType });
    dispatch(requestResourceByName(requestResource, { sharedKey: key, intentType }, { url: endpointUrl }));
  };
  const lang = useSelector((state: State) => state?.language?.locale) as 'en' | 'fr' ?? 'fr';

  const relativePath = (saleId: string, progression: number) => {
    const params = { saleId };
    const changeParams = { saleId };

    const illustrationRouteUrlEn = getRelativeUrlForRoute(relativeRoute.illustration_coverage_saleId, params);
    const illustrationouteUrlFr = getRelativeUrlForRoute(relativeRoute.illustration_couverture_saleId, params);
    const applicationRouteUrlEn = getRelativeUrlForRoute(relativeRoute.application_advisor_en, params);
    const applicationRouteUrlFr = getRelativeUrlForRoute(relativeRoute.application_advisor_fr, params);
    const changeRouteUrlEn = getRelativeUrlForRoute(relativeRoute.change_route, changeParams);
    const changeRouteUrlFr = getRelativeUrlForRoute(relativeRoute.changements_route, changeParams);

    const routeMap = {
      illustration: {
        en: illustrationRouteUrlEn,
        fr: illustrationouteUrlFr,
      },
      application: {
        en: applicationRouteUrlEn,
        fr: applicationRouteUrlFr,
      },
      change: {
        en: changeRouteUrlEn,
        fr: changeRouteUrlFr,
      },
    };

    const isBeforeApplication = stagesBeforeApplication.includes(progression as Stage);
    if (isChangeRequest) {
      return routeMap.change[lang];
    }

    return isBeforeApplication ? routeMap.illustration[lang] : routeMap.application[lang];
  };

  const handleSubmit = (formValues: { inputKey: string }) => {
    const key = formValues?.inputKey?.toLowerCase() || '';
    setSharedKey(key);
    handleDownloadFile(key, 'Validate');
  };

  const closeModal = () => {
    setErrorMessages([]);
    dispatch(closeModalByName('modals.filedownload'));
  };
  useEffect(() => {
    setErrorMessages([]);
  }, []);
  const progressionPath = useRef(null);
  const onValidateSuccess = (res: {config: {url?: string}; data: ValidationMessage[]}) => {
    const url = new URL(res?.config?.url);
    const key = url.searchParams.get('sharedKey');

    setErrorMessages(res?.data || []);

    if (res?.data && Array.isArray(res?.data) && res?.data?.length > 0) {
      // should display confirm dialog when error messages only when it does not contain any error message
      if (!hasValidationError(res?.data, 'sharedKey')) {
        dispatch(openModalByName('modals.confirmDownload'));
      }
    } else {
      handleDownloadFile(key, 'Save');
    }
  };
  const onDownloadSuccess = (res: {data: {venteId: string; progression: number; shouldMigrate: boolean}}) => {
    if (res?.data) {
      const id = res?.data?.venteId;
      const shouldMigrate = res?.data?.shouldMigrate;
      progressionPath.current = relativePath(id, res?.data?.progression);
      if (shouldMigrate) {
        dispatch(requestResourceByName('resources.saleMigration', undefined, { url: getBffUrlForRouteWithoutQP(BffRoute.sale_migrate, { saleId: id }) }));
      } else {
        history.push(progressionPath.current);
      }
    }
  };

  const onConfirm = () => {
    handleDownloadFile(sharedKey, 'Save');
    dispatch(closeModalByName('modals.confirmDownloadModal'));
    dispatch(closeModalByName('modals.downloadFile'));
  };
  const onChange = () => {
    setErrorMessages([]);
  };
  const controlAnchorMap = {
    sharedKey: 'inputKey',
  };
  const openModal = () => {
    setErrorMessages([]);
  };
  // not supposed the info message on UI
  const hideInfoMessage = errorMessages.length > 0 ? errorMessages.some((message: ValidationMessage) => message?.categorie === ValidationMessageLevel.Notice) : false;
  const hasValidationErrorMessage = errorMessages.length > 0 ? errorMessages.some((message: ValidationMessage) => message?.categorie === ValidationMessageLevel.Error) : false;

  return (
    <>
      <Resource
        name="validateFile"
        method="GET"
        url={downloadFileUrl}
        onSuccess={onValidateSuccess}
      />
      <Resource
        name="downloadFile"
        method="GET"
        url={downloadFileUrl}
        onSuccess={onDownloadSuccess}
      />
      <Resource
        name="saleMigration"
        method="POST"
        onSuccess={() => {
          if (progressionPath.current) history.push(progressionPath.current);
        }}
      />
      <ConfirmDialog<{content?: ReactNode; formValue?: { sharedKey: string }}>
        name="confirmDownloadModal"
        cancelButton={t('fileDownloadModal.no')}
        confirmButton={t('fileDownloadModal.yes')}
        onConfirm={onConfirm}
        Icon={WarningIcon}
        color="#ffdc00"
        noheader
      >
        <P>{t('fileDownloadModal.confirmCancelContentOne')}</P>

      </ConfirmDialog>

      <Modal name="filedownloadModal" onOpen={openModal}>
        <T2>{t('fileDownloadModal.title')}</T2>
        <InfoMessage>{t('fileDownloadModal.infoContent')}</InfoMessage>
        <Spacing p={20} />
        <Form
          name="filedownloadform"
          onSubmit={handleSubmit}
          onChange={onChange}
          hoistErrors
          hideErrorHoistedAnchor
          isolate
          cleanValues
          hideErrorMessages
          hideWarningHoistedAnchor
          hideCautionHoistedAnchor
          errorMessageTitle={t('fileDownloadModal.Error')}
        >
          {!hideInfoMessage && <HoistedServerErrors
            selector={(): null => null}
            extraMessages={errorMessages}
            controlAnchorMap={controlAnchorMap}
          />}
          <FormRow>
            <FormCol label={t('fileDownloadModal.sharedKeyTextLabel')}>
              <TextControl
                name="inputKey"
                forceInvalid={hasValidationErrorMessage}
                validators={{
                  required: {
                    validator: requiredValidator,
                    message: <ControlAnchorLink
                      message={t('fileDownloadModal.errors.invalidKey')}
                      anchorText={t('fileDownloadModal.anchorError')}
                      fieldName="inputKey"
                    />,
                    type: 'error',
                  },
                }}
                valueToStateFormatters={[
                  (v) => v.replace(AUTO_SUGGEST_RESOURCE_INPUT_REGEX, '').toUpperCase(),
                ]}
              />
            </FormCol>
          </FormRow>
          <Divider />
          <ActionBar>
            <PrimarySubmitButton>{t('fileDownloadModal.ok')}</PrimarySubmitButton>
            <SecondaryButton onClick={closeModal}>{t('fileDownloadModal.cancel')}</SecondaryButton>
          </ActionBar>
        </Form>

      </Modal>
    </>
  );
};
export default FileDownloadModal;
