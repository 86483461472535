import format from 'date-fns/format';
import { frCA, enCA } from 'date-fns/locale';

export interface DateCellProps {
  language: string;
  date: Date;
}

const FileDateCell = (language: string, date: Date) => {
  const locale = language === 'en' || language === 'en-US' ? enCA : frCA;
  const dateFormat = language === 'en' || language === 'en-US' ? 'MMMM d, yyyy' : 'd MMMM yyyy';

  if (date != null) return format(new Date(date), dateFormat, { locale });

  return null;
};

export { FileDateCell };
