import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormModel from '~/interfaces/InsurabilityDeclaration/FormModel';
import ValidationMessageModel from '~/interfaces/InsurabilityDeclaration/ValidationMessageModel';
import QuestionIdentityModel from '~/interfaces/InsurabilityDeclaration/QuestionIdentityModel';
import { ValidationMessageTypes } from '~/interfaces/InsurabilityDeclaration/enums';
import { AppState } from '~/interfaces/AppState';
import isEqual from 'lodash/isEqual';
import { answerQuestionsSuccess } from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/InsurabilityDeclaration.actions';
import SetAnswerRequestDtoBuilder from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/builders/SetAnswerRequestDtoBuilder';
import { InfoTooltip, Visible, requestResourceByName } from 'ia-react-core';
import ChangeRequestModel from '~/interfaces/InsurabilityDeclaration/ChangeRequestModel';
import selectedCase from '~/fragments/SharedLibrary/selectors/selectedCase';
import selectQuestion from '~/fragments/SharedLibrary/selectors/selectQuestion';
import useQuestionFieldFactory from './QuestionFields/useQuestionFieldFactory';
import { StyledFieldBox, StyledQuestionContainer, StyledQuestionLabel, StyledQuestionLabelContainer } from './Question.styles';

export interface CompactQuestionProps {
  question: QuestionIdentityModel;
  formId: string;
}

const CompactQuestion: FC<CompactQuestionProps> = ({ formId, question: questionIdentity }) => {
  const [answerElement, setAnswerElement] = useState<unknown[]>();
  const changeData: ChangeRequestModel = useSelector((state: AppState) => selectedCase(state));

  const changedQuestion = useSelector((state: AppState) => selectQuestion(state, questionIdentity), (prevData, newData) => isEqual(prevData, newData));

  const selectedForm = useMemo(() => changeData?.forms?.find((form: FormModel) => form?.id === formId), [formId, changeData?.forms]);
  const answerData = useSelector((s: AppState) => s.App?.fragments?.ApplicationFragment?.data?.update_answer_question);

  const question = changeData.questions[questionIdentity.id];
  const dispatch = useDispatch();
  const { Field } = useQuestionFieldFactory(question.questionType);
  const caseId = changeData.id;

  useEffect(() => {
    if (answerData && answerElement) {
      dispatch(answerQuestionsSuccess(changedQuestion, answerElement, answerData));
    }
  }, [answerData, answerElement, dispatch, changedQuestion]);

  useEffect(() => {
    if (answerData?.length && answerData[0].id === questionIdentity.id && question.canTriggerActive) {
      dispatch(requestResourceByName('resources.change_request', { caseId }));
    }
  }, [answerData, caseId, dispatch, question.canTriggerActive, questionIdentity.id]);

  const handleChange = useCallback(
    (v: unknown[]) => {
      setAnswerElement(v);
      const requestBuilder = new SetAnswerRequestDtoBuilder();
      const body = [{ questions: question, answers: v }]?.map(({ questions, answers }) => requestBuilder.fromValues(questions, answers));
      dispatch(requestResourceByName('resources.update_answer_question', body));
    },
    [question, dispatch],
  );

  const validationMessages = useMemo(() => selectedForm?.validationMessages ?? [], [selectedForm?.validationMessages]);

  const validationMessage = useMemo(() => validationMessages.find((v: ValidationMessageModel) => v.targetItem === questionIdentity.id), [questionIdentity.id, validationMessages]);
  const validationLevel = validationMessage?.validationLevel ?? null;

  const hasError = Boolean((validationLevel === ValidationMessageTypes.Error));

  const hasWarning = Boolean((validationLevel === ValidationMessageTypes.Warning));
  const formattedToolTip = question.toolTip ? question.toolTip.replace(/\\n/g, '\n') : null;
  const props = { disabled: !question.isActive };

  return (
    question.isActive &&
    <StyledQuestionContainer id={`q-${question.id}`}>
      <Visible xs sm>
        {Boolean(question.title) &&
          <StyledQuestionLabelContainer>
            <StyledQuestionLabel>{question.title}</StyledQuestionLabel>
            {Boolean(formattedToolTip) && <InfoTooltip tip={formattedToolTip} />}
          </StyledQuestionLabelContainer>}
      </Visible>
      <StyledFieldBox>
        <Field question={question} onChange={handleChange} hasError={hasError} hasWarning={hasWarning} {...props} />
      </StyledFieldBox>
    </StyledQuestionContainer>
  );
};

export default CompactQuestion;
