import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';

export interface ChangesValidationResourceProps {
  saleId: string;
  autoRequest?: boolean;
}

const ValidadateApiName = 'changes_validate_error_message';

const ChangesValidationResource: FC<ChangesValidationResourceProps> = ({ saleId, autoRequest = false }: ChangesValidationResourceProps) => (
  <Resource
    name={ValidadateApiName}
    url={getBffUrlForRouteWithoutQP(BffRoute.changes_validate_error_message, { saleId }, false)}
    method="GET"
    autoRequest={autoRequest}
  />
);

export default ChangesValidationResource;
