import { Action } from 'redux';
import { AddSettingsAdvisorSuccessAction } from '../AdvisorsModal.actions';
import { AdvisorsModalState } from '../AdvisorsSettingsModalState';

export default function addSettingsAdvisorSuccess(state: AdvisorsModalState, action: Action): AdvisorsModalState {
  const { advisor } = action as AddSettingsAdvisorSuccessAction;

  const previousList = state?.data?.advisorList ?? [];

  return {
    ...state,
    data: {
      ...state?.data,
      advisorList: [...previousList, advisor],
    },
  };
}
