import { Badge, IconButton } from 'ia-react-core';
import styled from 'styled-components';

const StyledSaleFileNameContainer = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  max-width: 100%;
`;

const StyledSaleFileName = styled.span`
  margin-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
`;

const StyledBadge = styled(Badge)`
  color: #e71313;
  position: absolute;
  right: 161px;
  top: 19px;
  z-index: 1;
  font-size: 0px;
  min-width: 10px;
  height: 10px;
`;

const StyledIconButton = styled(IconButton)`
  &&& {
    width: 40px;
  }
`;

const AppLayoutHeaderRightContentStyles = {
  StyledSaleFileNameContainer,
  StyledSaleFileName,
  StyledBadge,
  StyledIconButton,
};

export default AppLayoutHeaderRightContentStyles;
