import { RouteDefinition, RouteDefinitions, RouteSectionDefinition } from 'ia-react-core';

export enum SIGNATURE_ROUTES_ECHANGE_KEY {
  SIGNATURE_MODE = 'signatureMode',
  SIGNATURE_FOLLOWUP = 'signatureFollowup',
}

export const signatureRoutesEchange: Record<SIGNATURE_ROUTES_ECHANGE_KEY, RouteSectionDefinition | RouteDefinition> = {
  [SIGNATURE_ROUTES_ECHANGE_KEY.SIGNATURE_MODE]: {
    label: { en: 'Signature Mode', fr: 'Mode de signature' },
    path: { en: '/application/:saleId/signatureechange/signature-mode', fr: '/application/:saleId/signaturechangement/signature-mode' },
    loader: () => import('~/fragments/ChangeFragment/pages/SignaturePage/pages/SignatureMode'),
  },
  [SIGNATURE_ROUTES_ECHANGE_KEY.SIGNATURE_FOLLOWUP]: {
    label: { en: 'Signature and Follow-Up', fr: 'Signature et suivi' },
    path: { en: '/application/:saleId/signatureechange/signature-followup', fr: '/application/:saleId/signaturechangement/signature-followup' },
    loader: () => import('~/fragments/ChangeFragment/pages/SignaturePage/pages/SignatureFollowup'),
  },
};

const SIGNATURE_ROUTES_ECHANGE: RouteDefinitions = Object.values(signatureRoutesEchange);

export default SIGNATURE_ROUTES_ECHANGE;
