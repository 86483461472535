import {
  Drawer, IconButton, MoreHorizIcon, PrimaryPage, RouteDefinitions, Spacing, StructuredRouter, Visible, closeDrawerByName, openDrawerByName,
} from 'ia-react-core';
import React, {
  FC, Fragment, PropsWithChildren, ReactNode, createContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StyledWorkflowLayoutWrapperTitle, StyledWorkflowLayoutWrapperContentContainer, StyledWorkflowLayoutWrapperPrimaryPageContainer, StyledWorkflowLayoutWrapperTitleContainer } from './WorkflowLayoutWrapper.styles';
import { WORKFLOW_LAYOUT__WRAPPER_TITLE_PORTAL_ID } from './WorkflowLayoutWrapper.constants';
import { CustomTitleLogic, generateWorkflowLayoutRouteOverrides } from '../WorkflowLayoutTitleLabelResolver';

interface WorkflowLayoutTitleContextProps {
  titleLabel: ReactNode;
  setTitleLabel: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

export const WorkflowLayoutTitleContext = createContext({} as WorkflowLayoutTitleContextProps);

export interface WorkflowLayoutWrapperProps extends PropsWithChildren {
  routes: RouteDefinitions;
  workflowSideNav: ReactNode;
  customTitleLogic?: CustomTitleLogic;
}

const WorkflowLayoutWrapper: FC<WorkflowLayoutWrapperProps> = ({ children, routes, workflowSideNav, customTitleLogic }) => {
  const dispatch = useDispatch();

  const drawerName = 'drawers.workflowNavMenu';

  const [isInitialRender, setIsInitialRender] = useState(true);

  const location = useLocation();

  // Close the drawer when a route is selected
  useEffect(() => {
    if (!isInitialRender) {
      dispatch(closeDrawerByName(drawerName));
    } else {
      setIsInitialRender(false);
    }
  }, [dispatch, drawerName, location, isInitialRender]);

  const handleDrawerOpen = () => {
    dispatch(openDrawerByName(drawerName));
  };

  const [titleLabel, setTitleLabel] = useState<ReactNode>('');
  const contextValue = useMemo(() => ({ titleLabel, setTitleLabel }), [titleLabel]);

  // Generates routes for retrieving page names
  const routesOverrides = useMemo(() => generateWorkflowLayoutRouteOverrides(routes, customTitleLogic), [routes]);

  return (
    <WorkflowLayoutTitleContext.Provider value={contextValue}>

      {/* Synchronize current titleLabel value */}
      <StructuredRouter
        routes={routesOverrides}
        routeDeeply
        DefaultPage={null}
      />

      {/* Responsive drawer for mobile */}
      <Drawer
        name={drawerName}
        drawerTitle={titleLabel}
        maxWidth={420}
      >
        {workflowSideNav}
      </Drawer>

      {/* Page Content */}
      <StyledWorkflowLayoutWrapperPrimaryPageContainer>
        <PrimaryPage
          Title={Fragment}
          pageTitle={
            <StyledWorkflowLayoutWrapperContentContainer>
              <StyledWorkflowLayoutWrapperTitleContainer>

                {/* Page title */}
                <StyledWorkflowLayoutWrapperTitle>
                  {titleLabel}
                </StyledWorkflowLayoutWrapperTitle>

                <div id={WORKFLOW_LAYOUT__WRAPPER_TITLE_PORTAL_ID}></div>

                {/* Button to open navigation drawer, only visible on mobile */}
                <Visible xs sm md>
                  <Spacing m-l={16}>
                    <IconButton
                      Icon={MoreHorizIcon}
                      onClick={handleDrawerOpen}
                      primaryBackground
                    />
                  </Spacing>
                </Visible>
              </StyledWorkflowLayoutWrapperTitleContainer>
            </StyledWorkflowLayoutWrapperContentContainer>
          }
        >
          <StyledWorkflowLayoutWrapperContentContainer>
            {children}
          </StyledWorkflowLayoutWrapperContentContainer>
        </PrimaryPage>
      </StyledWorkflowLayoutWrapperPrimaryPageContainer>
    </WorkflowLayoutTitleContext.Provider>
  );
};

export default WorkflowLayoutWrapper;
