import styled from 'styled-components';
import { ActionBar, FormCol, SelectControl, T2, TextControl } from 'ia-react-core';

export const StyledT2 = styled(T2)`
  margin-bottom: 24px;
`;

export const StyledSelectControl = styled(SelectControl)`
  width: 100%;
  padding: 9px 9px 10px;
  border: 1px solid rgb(127, 127, 127);
  border-radius: 4px;
  appearance: none;
`;

export const StyledTextControl = styled(TextControl)`
  width: 130px;
`;

export const StyledFormCol = styled(FormCol)`
  margin-bottom: 8px;
`;

export const StyledActionBar = styled(ActionBar)`
  padding-top: 32px;
`;
