import { ConfirmDialog, WarningIcon } from 'ia-react-core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledConfirmationText } from './BackToIllustrationModal.styles';
import { BACK_TO_ILLUSTRATION_MODAL } from './constants/constants';

export interface BackToIllustrationModalProps {
  onConfirm?: () => void;
}

const BackToIllustrationModal: FC<BackToIllustrationModalProps> = ({ onConfirm }) => {
  const { t } = useTranslation('BackToIllustrationModal');
  return (
    <ConfirmDialog
      name={BACK_TO_ILLUSTRATION_MODAL}
      cancelButton={t('no')}
      confirmButton={t('yes')}
      onConfirm={onConfirm}
      Icon={WarningIcon}
      color="#ffdc00"
      noheader
    >
      <StyledConfirmationText>{t('backConfirmation')}</StyledConfirmationText>
    </ConfirmDialog>
  );
};

export default BackToIllustrationModal;
