import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '~/interfaces/AppState';
import ChangeRequestModel from '~/interfaces/ChangeRequestModel';
import UpdateAnswerQuestion from '~/fragments/SharedLibrary/Resources/UpdateAnswerQuestion';
import DeleteRepeatingSection from '~/fragments/SharedLibrary/Resources/DeleteRepeatingSection';
import CreateRepeatingSection from '~/fragments/SharedLibrary/Resources/CreateRepeatingSection';
import selectedForm from '~/fragments/SharedLibrary/selectors/selectedForm';
import selectedCase from '~/fragments/SharedLibrary/selectors/selectedCase';
import Form from './Form/Form';
import { StyledFormContainer } from './FormContainer.styles';

export interface FormContainerProps {
  formId?: string;
}

const FormContainer: FC<FormContainerProps> = ({ formId }) => {
  const currentForm = useSelector((state: AppState) => selectedForm(state, formId));
  const changeRequest: ChangeRequestModel = useSelector(selectedCase);

  return (
    <>
      {currentForm &&
      <StyledFormContainer>
        <Form key={currentForm?.id} form={currentForm} />
      </StyledFormContainer>}
      <UpdateAnswerQuestion saleId={changeRequest?.id} />
      <DeleteRepeatingSection saleId={changeRequest?.id} repeatingSectionId={null} sectionId={null} />
      <CreateRepeatingSection repeatingSectionId={null} saleId={changeRequest?.id} />
    </>
  );
};

export default FormContainer;
