/**
 * This MainLayout component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import {
  AppErrorBoundary,
  ApplicationLayout,
  ApplicationLayoutProps,
  Resource,
  createChildrenTunnel,
  createPropsTunnel,
  requestResourceByName,
} from 'ia-react-core';
import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import AdvisorsModal from '~/components/AdvisorsModal';
import MidHeaderLogo from '~/components/MidHeaderLogo';
import { useZoom } from '~/hooks/useZoom';
import { useDispatch } from 'react-redux';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { reverseLanguageMap } from '~/fragments/IllustrationFragment/pages/CoveragesPage/utilities/languageMap';
import InformationModal from '~/layouts/components/InformationModal';
import { StyledApplicationLayout } from './MainLayout.styles';
export interface MainLayoutProps {
  children?: ReactNode;
}

const {
  setTunnel: setMainLayoutPropsTunnel,
  useTunnel: useMainLayoutPropsTunnel,
} = createPropsTunnel<ApplicationLayoutProps & {
  flexFooterLinks?: boolean;
}>();

const {
  Entry: MainHeaderRightContentTunnelEntry,
  Exit: MainHeaderRightContentTunnelExit,
} = createChildrenTunnel();

export const APPLICATION_LAYOUT_ID = 'application-layout';

const MainLayout: FC<MainLayoutProps> & {
  setMainLayoutPropsTunnel: typeof setMainLayoutPropsTunnel;
  MainHeaderRightContentTunnelEntry: typeof MainHeaderRightContentTunnelEntry;
} = ({
  children,
}) => {
  const { t } = useTranslation('App');
  const appName = t('appName');

  const { zoom } = useZoom();

  const tunneledProps = useMainLayoutPropsTunnel();
  const contactUsUrl = 'https://iaa.secureweb.inalco.com/cw/individual-insurance-savings-retirement/contact-us';
  const privacyUrl = 'https://iaa.secureweb.inalco.com/cw/individual-insurance-savings-retirement/protection-of-personal-information';
  const tosUrl = 'https://iaa.secureweb.inalco.com/cw/terms-of-use';

  const dispatch = useDispatch();
  return (
    <AppErrorBoundary>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} {appName}
        </title>
        <script src={process.env.REACT_APP_CONSENT_FORM_JAVASCRIPT} type="text/javascript" />
        <link rel="stylesheet" href={process.env.REACT_APP_CONSENT_FORM_CSS} />
      </Helmet>
      <StyledApplicationLayout
        data-id={APPLICATION_LAYOUT_ID}
        headerPrimary
        horizontalLogo
        contentBackground="linear-gradient(231deg, #edf7df, #e3f2fd)"
        mainFooterLinks={[
          { url: contactUsUrl, text: t('contact_us'), target: '_blank' },
          { url: privacyUrl, text: t('privacy'), target: '_blank' },
          { url: tosUrl, text: t('tos'), target: '_blank' },
          { url: t('accessibilityUrl'), text: t('accessibility'), target: '_blank' },
        ]}
        headerHeight={60}
        minHeight={`${zoom * 100}vh`}
        nopadding
        maxContentWidth={100000}
        onLanguageChange={(language) => {
          const inputLanguage = reverseLanguageMap[language];
          dispatch(requestResourceByName('resources.updateApplicationConfiguration', undefined, { url: getBffUrlForRoute(BffRoute.application_configuration_language, { language: inputLanguage }, false) }));
        }}
        showXsLogos
        {...tunneledProps}
      >
        <Resource
          name="updateApplicationConfiguration"
          method="put"
        >
          {null}
        </Resource>
        <ApplicationLayout.MainHeaderMiddleContent>
          <MidHeaderLogo />
        </ApplicationLayout.MainHeaderMiddleContent>
        <ApplicationLayout.MainHeaderRightContent>
          <MainHeaderRightContentTunnelExit />
        </ApplicationLayout.MainHeaderRightContent>
        {children}
      </StyledApplicationLayout>
      <AdvisorsModal />
      <InformationModal />
    </AppErrorBoundary>
  );
};

MainLayout.setMainLayoutPropsTunnel = setMainLayoutPropsTunnel;
MainLayout.MainHeaderRightContentTunnelEntry = MainHeaderRightContentTunnelEntry;

export default MainLayout;
