import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { SetUpdating } from '../ApplicationFragment.actions';

export default function setUpdating(state: ApplicationFragmentState, action: Action): ApplicationFragmentState {
  const { updating } = action as SetUpdating;

  return {
    ...state,
    updating,
  };
}
