import ValidationMessageModel from '~/interfaces/InsurabilityDeclaration/ValidationMessageModel';
import ValidationMessageDto from '../dtos/ValidationMessageDto';

export default class ValidationMessageBuilder {
  toModel(dto: ValidationMessageDto): ValidationMessageModel {
    return new ValidationMessageModel(
      dto.messageId,
      dto.messageText,
      dto.validationLevel,
      dto.instance?.id,
    );
  }
}
