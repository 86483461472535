import { RouteDefinition, RouteDefinitions, RouteSectionDefinition } from 'ia-react-core';
import NOMINEE_ROUTES from './NOMINEE_ROUTES';

export enum APPLICATION_ECHANGE_ROUTES_KEY {
  NOMINEE = 'nominee',
  APPLICANT = 'applicant',
  REQUIREMENTS = 'requirements',
  APPLICATIONHEADOFFICE = 'headoffice',
  COVERAGES = 'coverages',
}

export const applicationEchangeRoutes: Record<APPLICATION_ECHANGE_ROUTES_KEY, RouteSectionDefinition | RouteDefinition> = {
  [APPLICATION_ECHANGE_ROUTES_KEY.NOMINEE]: {
    label: { en: 'Nominee', fr: 'Candidat' },
    path: { fr: '/application/:saleId/candidat', en: '/application/:saleId/nominee' },
    loader: () => import('../fragments/ApplicationFragment/pages/NomineePage'),
    routes: NOMINEE_ROUTES,
    name: APPLICATION_ECHANGE_ROUTES_KEY.NOMINEE,
  },
  [APPLICATION_ECHANGE_ROUTES_KEY.APPLICANT]: {
    label: { fr: 'Contractant', en: 'Applicant' },
    path: { fr: '/application/:saleId/contractant', en: '/application/:saleId/applicant' },
    loader: () => import('../fragments/ApplicationFragment/pages/ApplicantPage'),
    name: APPLICATION_ECHANGE_ROUTES_KEY.APPLICANT,
  },
  [APPLICATION_ECHANGE_ROUTES_KEY.REQUIREMENTS]: {
    label: { fr: 'Exigences', en: 'Requirements' },
    path: { fr: '/application/:saleId/exigences', en: '/application/:saleId/requirements' },
    loader: () => import('../fragments/ApplicationFragment/pages/RequirementsPage'),
    name: APPLICATION_ECHANGE_ROUTES_KEY.REQUIREMENTS,
  },
  [APPLICATION_ECHANGE_ROUTES_KEY.APPLICATIONHEADOFFICE]: {
    label: { fr: 'Siège social', en: 'Head office' },
    path: { fr: '/application/:saleId/social', en: '/application/:saleId/headoffice' },
    loader: () => import('../fragments/ApplicationFragment/pages/HeadOfficePage'),
    name: APPLICATION_ECHANGE_ROUTES_KEY.APPLICATIONHEADOFFICE,
  },
  [APPLICATION_ECHANGE_ROUTES_KEY.COVERAGES]: {
    label: { fr: 'Protection(s)', en: 'Coverage(s)' },
    path: { fr: '/application/:saleId/protections', en: '/application/:saleId/coverages' },
    loader: () => import('../fragments/ApplicationFragment/pages/CoveragesPage'),
    name: APPLICATION_ECHANGE_ROUTES_KEY.COVERAGES,
  },

};

const APPLICATION_ECHANGE_ROUTES: RouteDefinitions = Object.values(applicationEchangeRoutes);

export default APPLICATION_ECHANGE_ROUTES;
