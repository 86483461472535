import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum Locale {
  EN = 'en',
  US_EN = 'en-US',
  FR = 'fr'
}

export function useCurrentLocale(): Locale.FR | Locale.EN {
  const { i18n } = useTranslation();

  return useMemo(() => {
    const baseLanguage = i18n.language.split('-')[0];
    const locale = baseLanguage === Locale.FR ? Locale.FR : Locale.EN;

    return locale;
  }, [i18n.language]);
}
