import AddressModel from '~/fragments/ApplicationFragment/interfaces/AddressModel';
import { fromAddressDto } from '~/fragments/ApplicationFragment/utilities/address/fromAddressDto';
import { getI18n } from 'react-i18next';
import { Locale } from '~/hooks/useCurrentLocale';
import { DocIdentificationType, IForm, SeniorOfficialType } from '../interfaces';
import { NoneOfTheAboveEn, NoneOfTheAboveFr } from '../constant';

const getDefaultValue = (value: any) => {
  if (![true, false].includes(value)) return null;
  return value;
};
const getDefaultModalValue = (value: any) => {
  if (value) return value;
  return null;
};

const getInitialApplicantFormValues: (formValues?: IForm<AddressModel>) => IForm = (formValues) => ({
  id: formValues?.id || null,
  regulation: {
    premium: {
      isFinancedByOther: getDefaultValue(formValues?.regulation?.premium?.isFinancedByOther),
      lender: formValues?.regulation?.premium?.lender || '',
    },
    taxations: formValues?.regulation?.taxations?.map((t) => ({
      isCorporationInUsa: getDefaultValue(t?.isCorporationInUsa),
      ein: t?.ein || '',
      isOwnOrg: getDefaultValue(t?.isOwnOrg),
      applicantId: t?.applicantId || '',
      seniorOfficials: t?.seniorOfficials || [SeniorOfficialType.none],
      isTaxResident: getDefaultValue(t?.isTaxResident),
      tinSsn: t?.tinSsn || '',
      isTaxStranger: getDefaultValue(t?.isTaxStranger),
      taxStrangers: t?.taxStrangers?.map((ts) => ({
        id: ts.id,
        jurisdiction: ts.jurisdiction,
        tin: ts.tin,
      })) || [],
    })) || [],
    crime: {
      isThirdParty: getDefaultValue(formValues?.regulation?.crime?.isThirdParty),
      thirdParties: formValues?.regulation?.crime?.thirdParties?.map((tp) => {
        // Individual
        if ('gender' in tp) {
          return {
            id: tp.id,
            indexRef: 0,
            referenceNomineeId: getDefaultModalValue(tp.referenceNomineeId),
            lastName: getDefaultModalValue(tp.lastName),
            firstName: getDefaultModalValue(tp.firstName),
            gender: getDefaultModalValue(tp.gender),
            dateOfBirth: tp.dateOfBirth ? new Date(tp.dateOfBirth) : null,
            relationship: getDefaultModalValue(tp.relationship),
            relationshipDesc: getDefaultModalValue(tp.relationshipDesc),
            occupation: getDefaultModalValue(tp.occupation),
            address: tp.address ? fromAddressDto(tp.address) : null,
            homePhone: getDefaultModalValue(tp.homePhone),
            cellPhone: getDefaultModalValue(tp.cellPhone),
            workPhone: getDefaultModalValue(tp.workPhone),
            extension: getDefaultModalValue(tp.extension),
            email: getDefaultModalValue(tp.email),
          };
        }
        // Organization
        return {
          id: tp.id,
          indexRef: 1,
          referenceNomineeId: getDefaultModalValue(tp.referenceNomineeId),
          name: getDefaultModalValue(tp.name),
          relationship: getDefaultModalValue(tp.relationship),
          relationshipDesc: getDefaultModalValue(tp.relationshipDesc),
          lineOfBusiness: getDefaultModalValue(tp.lineOfBusiness),
          incorporationNb: getDefaultModalValue(tp.incorporationNb),
          placeOfIncorporation: getDefaultModalValue(tp.placeOfIncorporation),
          address: tp.address ? fromAddressDto(tp.address) : null,
          homePhone: getDefaultModalValue(tp.homePhone),
          cellPhone: getDefaultModalValue(tp.cellPhone),
          workPhone: getDefaultModalValue(tp.workPhone),
          extension: getDefaultModalValue(tp.extension),
          email: getDefaultModalValue(tp.email),
        };
      }) || [],
      sourceOfFunds: formValues?.regulation?.crime?.sourceOfFunds || [],
      sourceOfFundsNote: formValues?.regulation?.crime?.sourceOfFundsNote || '',
      isLumpSum: getDefaultValue(formValues?.regulation?.crime?.isLumpSum),
      canIAReturnLumpSum: getDefaultValue(formValues?.regulation?.crime?.canIAReturnLumpSum),
      applicants: formValues?.regulation?.crime?.applicants?.map((a) => ({
        applicantId: a.applicantId || null,
        isApplicantPolitic: getDefaultValue(a.isApplicantPolitic),
        isApplicantForeign: getDefaultValue(a.isApplicantForeign),
        isApplicantLeadOrg: getDefaultValue(a.isApplicantLeadOrg),
      })) || [],
    },
  },
  organization: {
    orgType: getDefaultModalValue(formValues?.organization?.orgType),
    orgTypeOther: formValues?.organization?.orgTypeOther || '',
  },
  identity: {
    signatories: formValues?.identity?.signatories?.map((a) => {
      let identificationDoc = a?.identificationDoc || null;

      if (identificationDoc === `${DocIdentificationType.none}`) {
        const { language } = getI18n();
        identificationDoc = (language as Locale) === Locale.FR ? NoneOfTheAboveFr : NoneOfTheAboveEn;
      }
      return ({
        id: a.id || null,
        referenceNomineeId: getDefaultModalValue(a.referenceNomineeId),
        lastName: getDefaultModalValue(a.lastName),
        firstName: getDefaultModalValue(a.firstName),
        gender: getDefaultModalValue(a.gender),
        dateOfBirth: a.dateOfBirth ? new Date(a.dateOfBirth) : null,
        address: a.address ? fromAddressDto(a.address) : null,
        verificationMethod: getDefaultModalValue(a.verificationMethod),
        verificationDate: a.verificationDate ? new Date(a.verificationDate) : null,
        identificationDoc,
        nameOfIdentificationDoc: getDefaultModalValue(a.nameOfIdentificationDoc),
        docNumber: getDefaultModalValue(a.docNumber),
        issOrg: getDefaultModalValue(a.issOrg),
        placeOfIssue: getDefaultModalValue(a.placeOfIssue),
        expiryDate: a.expiryDate ? new Date(a.expiryDate) : null,
        isPieceValid: getDefaultModalValue(a.isPieceValid),
        resIdentificationDoc: getDefaultModalValue(a.resIdentificationDoc),
        resDocNumber: getDefaultModalValue(a.resDocNumber),
        resIssOrg: getDefaultModalValue(a.resIssOrg),
        resPlaceOfIssue: getDefaultModalValue(a.resPlaceOfIssue),
        resExpiryDate: a.resExpiryDate ? new Date(a.resExpiryDate) : null,
      });
    }) || [],
  },
});

export default getInitialApplicantFormValues;
