export const ILLUSTRATION_NAV_MAPPING: string[] = [
  null,
  'Traditional Insurance',
  'Universal Life Insurance',
  'Specialized Life Insurance',
  'Participating Life Insurance',
  'Simplified Issue product',
  'Critical Illness Insurance',
  'Estate Bond',
  'Shared Ownership',
];
