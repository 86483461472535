import React, { FC } from 'react';
import { Link, LinkProps } from 'ia-react-core';
import { StyledButtonLinkContainer } from './ButtonLink.styles';

export interface ButtonLinkProps extends LinkProps {
  disabled?: boolean;
}

const ButtonLink: FC<ButtonLinkProps> = ({ className, disabled, ...props }) => (
  <StyledButtonLinkContainer className={className} disabled={disabled}>
    <Link as="button" type="button" {...props} />
  </StyledButtonLinkContainer>
);

export default ButtonLink;
