import {
  ArrowForwardIcon,
  Col,
  Container,
  Hidden,
  MenuItem,
  Row,
  Spacing,
  Visible,
  openModalByName,
  requestResourceByName,
} from 'ia-react-core';
import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { State } from '~/interfaces/State';
import MakeEchange from '../MakeEchangeModal';
import {
  StyledMainLinksButton,
  StyledMainLinksDiv,
  StyledMainLinksInternalLink,
  StyledMainLinksContainer,
  StyledMainLinksMoreMenu,
  StyledMainLinksSelect,
  StyledMainLinksInternalLinkContainer,
  StyledMainLinksMobileWrapper,
  StyledMainLinksMobileSelectWrapper,
} from './MainLinks.styles';
import { isActive } from './utilities';
import { P_C_TOOL_DATA_RESOURCE_NAME, PREMIUM_COMPARISON_TOOL_MODAL_NAME } from '../PremiumComparisonToolModal/constants';
import PremiumComparisonToolModal from '../PremiumComparisonToolModal';

export interface MainLinksProps { }

const MainLinks: FC<MainLinksProps> = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation('MainLinks');
  const history = useHistory();
  const dispatch = useDispatch();
  const userPermissions = useSelector((state: State) => state?.App?.data?.permissions?.permissions);
  const rolePermissions = useSelector((state: State) => state?.App?.Pages?.RolesPage?.data?.roles?.permissions);

  useEffect(() => {
    history.block(true);
  }, [history]);

  const finalPermission = rolePermissions !== undefined ? rolePermissions : userPermissions;
  const hasPermission = finalPermission?.includes('EChange.All');

  const handleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    history.push(e.target.value);
  }, []);

  const handleCriticalBtn = () => {
    dispatch(requestResourceByName(`resources.${P_C_TOOL_DATA_RESOURCE_NAME}`));
    dispatch(openModalByName(`modals.${PREMIUM_COMPARISON_TOOL_MODAL_NAME}`));
  };

  return (
    <StyledMainLinksContainer>
      <MakeEchange />
      <PremiumComparisonToolModal />
      <Visible xs>
        <StyledMainLinksMobileWrapper>
          <StyledMainLinksMobileSelectWrapper>
            <Spacing p-h-xs={16}>
              <StyledMainLinksSelect
                value={pathname}
                options={[
                  { label: t('createIllustrationTitle'), value: '/' },
                  { label: t('myFilesTitle'), value: `/${t('folders')}` },
                  {
                    label: t('newFeaturesTitle'),
                    value: `/${t('features')}`,
                  },
                ]}
                onChange={(e) => handleChange(e)}
              />
            </Spacing>
          </StyledMainLinksMobileSelectWrapper>
          <StyledMainLinksMoreMenu
            label="Menu"
            items={[
              <MenuItem
                key="critical_illness_comparison"
                onClick={handleCriticalBtn}
              >
                <span key="critical_illness_span">{t('critical_illness_comparison')}</span>
                <ArrowForwardIcon key="critical_illness_arrow" />
              </MenuItem>,
              <MenuItem
                key="make_an_echange"
                onClick={() => dispatch(openModalByName('modals.makeEchange'))}
              >
                <span key="echange_span">{t('make_an_echange')}</span>
                <ArrowForwardIcon key="echange_arrow" />
              </MenuItem>,
            ]}
          />
        </StyledMainLinksMobileWrapper>
      </Visible>
      <Container>
        <Row align="center">
          <Col p={0} xl={6} lg={7} md={7} sm={11}>

            <Hidden xs>
              <StyledMainLinksInternalLinkContainer>
                <StyledMainLinksInternalLink
                  className={isActive(pathname, ['/'])}
                  id="createillustration-link"
                  href="/"
                >
                  {t('createIllustrationTitle')}
                </StyledMainLinksInternalLink>

                <StyledMainLinksInternalLink
                  className={isActive(pathname, ['/folders', '/dossiers'])}
                  id="myfiles-link"
                  href="/folders"
                >
                  {t('myFilesTitle')}
                </StyledMainLinksInternalLink>

                <StyledMainLinksInternalLink
                  className={isActive(pathname, ['/features', '/nouveautes'])}
                  id="newfeatures-link"
                  href="/features"
                >
                  {t('newFeaturesTitle')}
                </StyledMainLinksInternalLink>
              </StyledMainLinksInternalLinkContainer>
            </Hidden>
          </Col>

          <Col p={0} xl={6} lg={5} md={5} sm={1}>
            <Hidden xs sm md>
              <StyledMainLinksDiv>
                <StyledMainLinksButton
                  data-testid="criticalBtn"
                  type="button"
                  onClick={handleCriticalBtn}
                >
                  {t('critical_illness_comparison')}
                </StyledMainLinksButton>
                <StyledMainLinksButton disabled={!hasPermission} type="button" onClick={() => dispatch(openModalByName('modals.makeEchange'))}>
                  {t('make_an_echange')}
                </StyledMainLinksButton>
              </StyledMainLinksDiv>
            </Hidden>

            <Hidden xs lg xl xxl>
              <StyledMainLinksDiv>
                <StyledMainLinksMoreMenu
                  label="Menu"
                  items={[
                    <MenuItem
                      key="critical_illness_comparison"
                      onClick={handleCriticalBtn}
                    >
                      <span key="critical_illness_span">{t('critical_illness_comparison')}</span>
                      <ArrowForwardIcon key="critical_illness_arrow" />
                    </MenuItem>,
                    <MenuItem
                      key="make_an_echange"
                      onClick={() => dispatch(openModalByName('modals.makeEchange'))}
                    >
                      <span key="echange_span">{t('make_an_echange')}</span>
                      <ArrowForwardIcon key="echange_arrow" />
                    </MenuItem>,
                  ]}
                />
              </StyledMainLinksDiv>
            </Hidden>
          </Col>
        </Row>
      </Container>
    </StyledMainLinksContainer>
  );
};
export default MainLinks;
