export default {
  all: 'Tous',
  actions: 'Actions',
  newBusiness: 'Nouvelles affaires',
  changeRequest: 'Demandes de changement',
  case: 'No. demande',
  title: 'Dossier',
  selection: 'Sélection',
  notTransmitted: 'Non transmis',
  fileName: 'Nom du dossier',
  version: 'Version',
  sharedWithMe: 'Partagé avec moi',
  shared: 'Partagé',
  shareCase: 'Partagé demande',
  notShared: 'Non partagé',
  importSharedFile: 'Importer un fichier partagé',
  information: 'Information',
  modifiedDate: 'Modifié',
  createdDate: 'Date de début',
  transferredDate: 'Date de transfert',
  status: 'Statut',
  draft: 'Brouillon',
  illustration: 'Illustration',
  application: 'application',
  completed: 'Complétées',
  inProgress: 'En cours',
  lastModified: 'Modifiées récemment',
  pendingSignature: 'en attente de signature',
  transmitted: 'Transmis',
  requiredAction: 'action requise',
  noSaleFiles: 'Aucun dossier ne correspond à vos critères de recherche',
  noOpenFiles: "Vous n'avez aucun dossier ouvert",
  searchInputPlaceholder: 'Nom du dossier, contractants, assurés',
  searchInputPlaceholderfiles: 'Nom du dossier',
  renameFileMenu: 'Renommer',
  deleteFileMenu: 'Supprimer',
  moreInformationMenu: 'Plus d\'informations',
  illustrationLink: 'illustration',
  applicationLink: 'proposition',
  delete: 'Supprimer',
  rename: 'Modifier le nom du dossier',
  save: 'Sauvegarder',
  an: 'une',
  or: 'ou',
  dateFormat: 'fr-CA',
  stageLabels: {
    proposition: 'Proposition',
    signature: 'Signature',
    illustration: 'Illustration',
    individu: 'Individu',
    admissibilite: 'Admissibilité',
    validation: 'Validation',
    changements: 'Changements',
    remiseEnVigueur: 'Remise en vigueur',
    transferee: 'Transférée',
    ceremonieSignatureDebutee: 'Cérémonie de signature débutée',
    signee: 'Signée',
    client: 'Clients',
    passageALaProposition: 'Proposition',
    draft: 'Brouillon',
  },
  fileMessages: {
    Msg_5047: 'Il reste {0} jour(s) pour passer à la proposition électronique avec cette illustration. Après ce délai, des changements pourraient impacter votre illustration, notamment en termes de prime ou de choix de protection.',
    Msg_5048: 'La date limite pour pouvoir passer à la proposition est échue depuis le {0}. Pour ce faire, veuillez créer une nouvelle illustration.',
    Msg_5049: 'Il reste {0} jour(s) pour transmettre cette proposition telle quelle. Après ce délai, des changements pourraient impacter certains éléments de la proposition, notamment en termes de prime ou de choix de protections. ',
    Msg_5050: 'Ce dossier contient des paramètres qui ne sont plus pris en charge. Toutefois, il peut être consulté, modifié et imprimé. Pour reprendre cette vente, vous devez créer une nouvelle illustration.',
  },
  newchangerequest: 'Nouvelle demande de changement',
  nochangerequest: '',
  contract: 'No. contrat',
  message: 'Tout accès ou usage non autorisé est strictement interdit. En utilisant ce service, vous acceptez l\'adhésion à la politique de sécurité de l\'information de l\'Industrielle Alliance. Toute activité allant à l\'encontre de la politique de sécurité et des règles de bonne utilisation du service sera considérée comme un abus, pouvant entrainer des poursuites administratives et judiciaires.',
  savemodalinfo: 'Lorsque vous sauvegardez des documents sur votre poste de travail, il est de votre responsabilité de respecter les lignes directrices en matière de sécurité de l\'information et de protection des renseignements personnels.',
  savemodaltitle: 'Fichiers de vente',
  savebutton: 'Enregistrer',
  closebutton: 'Fermer',
  ok: 'Ok',
  olderVersionMessage: 'Ce dossier a été créé avec une version précédente d’EVO et ne peut plus être transmis. Toutefois, il peut être consulté, modifié et imprimé. Pour poursuivre ce dossier ou en créer un nouveau et le transmettre, veuillez utiliser la version la plus récente d’EVO, cliquer sur « Créer une illustration » et ressaisir l’information.',
  migrateSuccessMsg: 'Le dossier a été migré avec succès.',
  success: 'Succès',
  saveFileModal: {
    save: 'Documents',
  },
  changeAge: 'Changement d’âge détecté',
  share: 'Partager ce dossier',
};
