import { GlobalAction } from '~/interfaces/GlobalAction';
import { FoldersPageState } from '~/interfaces/FoldersPageState';

const foldersPageReducer = (state: FoldersPageState, action: GlobalAction): FoldersPageState => {
  switch (action.type) {
    default:
      return state;
  }
};

export default foldersPageReducer;
