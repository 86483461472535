import { T5 } from 'ia-react-core';
import styled from 'styled-components';

export const StyledT5 = styled(T5)`
  margin-top: 15px;
`;
export const StyledAppVersioningSection = styled.section`
  gap: 10px;
  margin-bottom: 15px;
`;
