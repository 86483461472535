import { InforceInsuranceType } from '~/interfaces/InsuranceHistoryPageState';

const sortInforceInsurance = (a: InforceInsuranceType, b: InforceInsuranceType) => {
  if (!a.isContractIA && b.isContractIA) {
    return 1;
  }
  if (a.isContractIA && !b.isContractIA) {
    return -1;
  }

  if (!a.protectionAmounts && b.protectionAmounts) {
    return 1;
  }
  if (a.protectionAmounts && !b.protectionAmounts) {
    return -1;
  }

  const yearA = a.yearEmission ? new Date(a.yearEmission).getUTCFullYear() : 0;
  const yearB = b.yearEmission ? new Date(b.yearEmission).getUTCFullYear() : 0;

  if (yearA > yearB) {
    return -1;
  }
  if (yearA < yearB) {
    return 1;
  }

  return 0;
};

export default sortInforceInsurance;
