import {
  Block, T4, T5, P, ToggleSwitchControl, ActionBar, FormCol, Spacing, Form,
  Promo2,
  FormRow,
  ErrorMessage,
  InfoMessage,
  Table,
  AddIcon,
} from 'ia-react-core';
import styled from 'styled-components';

export const StyledBlock = styled(Block)`
  margin-top: 10px;
  background-color: #f5f5f5;
`;

export const StyledTitle = styled(T4)`
font-size: 17px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0px;
text-align: left;
margin-bottom: 10px;

[data-testid="InfoTooltip"] {
  width: auto;
  height: auto;
}
`;

export const StyledSubTitle = styled(T5)`
font-size: 14px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
margin-bottom: 10px;
`;

export const StyledTitleWrapper = styled.div`
display: flex;
align-items: center;

[data-testid="InfoTooltip"] {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0 0 5px 5px;
}
`;

export const StyledModalWrapper = styled.div`
h3 {
  margin-bottom: 15px;
}
p {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 0;
}
button:last-child {
  margin-left: 10px;
}
`;

export const StyledText = styled(P)`
margin-bottom: 20px;
font-size: 17px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0px;
text-align: center;
`;

export const StyledToggleButton = styled(ToggleSwitchControl)`
label {
  margin-bottom: 0;
  span {
    padding: 7px 13px;
  }
}
`;

export const StyledEditInsuredModal = styled.div`
  h3 {   
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
  }
  .columnheader label {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }
  [data-testid="FormColLabel"] {    
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;

    label {
      align-items: center;
    }
  }
  .coveragetable {
    margin-bottom: 10px;
    [role="row"] {
      align-items: start;
    }
    [role="cell"] {
      svg {
        width: 16px;
        height: 16px;
      }
      &:last-child button.moremenu {
        all: unset;
      }
    }
  }
`;

export const StyledActionBar = styled(ActionBar)<{align?: string }>`
  justify-content: ${(props) => props.align ? props.align : 'center'};

  button {
    flex: 0 0 22%;    
  }
  @media only screen and (max-width: 767.0px) {
    flex-direction: column;
  }
`;

export const StyledHeadOfficeWrapper = styled.div`
  all: unset; 
`;

export const StyledUnit = styled(FormCol)`
  padding-left: 0;
  margin-top: 10px;
  margin-bottom: 0;
`;

export const StyledSpacing = styled(Spacing)`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  
  h6 {    
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 0;
    margin-left: 5px;
  }
`;

export const StyledIconWrapperUser = styled.div`
  padding-right: 0px;
  svg {
    color: blue;
  }
`;

export const StyledIconWrapper = styled.div`
  svg {
    &:first-child {
      margin-right: 10px;
    }
  }
`;

export const StyledProduct = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin-bottom: 0;    
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }
  .iconproduct svg {
    color: blue;
    padding-top: 10px;
    magrin: 0;

    &:first-child {
      margin-right: 10px;
    }
  }
`;

export const StyledProductHeader = styled(P)`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
`;

export const StyledProductBlock = styled(Block)`
  padding: 10px 5px;
`;

export const StyledProductContainer = styled.div`
  margin-bottom: 10px !important;
  padding-bottom: 10px;
`;

export const StyledPageContainer = styled(FormCol)`
margin-top: 20px`;

export const StyledDeleteActionBar = styled(ActionBar)`
justify-content: center;
button:last-child {
  margin-left: 20px;
}
`;

export const StyledCurrencyFormCol = styled(FormCol)`
[role="presentation"] {
  flex: 1 0 100%;
}`;

export const StyledContractForm = styled(Form)``;

export const StyledReviewModal = styled.div`
  padding: 16px;
  h3 {
    margin-bottom: 15px;
  }
`;

export const StyledPromo = styled(Promo2)`
  font-size: 1.5rem;
  font-weight: 600;
`;

export const StyledHr = styled.hr`
border-top: 1px solid lightgray;`;

export const StyledAdditionalWrapper = styled.div`
display: flex;
align-items: start;
flex-direction: column`;

export const StyledProductTitle = styled(Promo2)`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0px;
  padding-left: 8px;
  padding-top: 8px;
`;

export const StyledFormRow = styled(FormRow)`
    .gendercolumn {
        flex: 0 0 165px;
    }
`;

export const StyledErrorMessage = styled(ErrorMessage)`
    margin-bottom: 10px;
    ul {
        list-style: none;
        margin-left:0;
        padding-left:0;
    }
`;

export const StyledWrapperAddInsured = styled.div`
.changeorder {
  [role="presentation"] {
    display: none;
  }
  div {
    &:first-child {
      order: 3;
    }
    &:nth-child(2) {
      order: 2;
    }
    &:last-child {
      order: 1;
    }
  }
`;

export const StyledAdditionalCoverageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCoverageDetailsBlock = styled(Block)`
padding: 0.75rem 0.25rem;
background-color: transparent;
box-shadow: none;

.tableheader {
  font-weight: 700;
  border-bottom: 1px solid;

  span {
    display: inline-block;
    padding-bottom: 15px;
  }
}

.coverageblock {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 10px;

  svg {
    color: #2062D4;
    width: 20px;
    height: 20px;
  }

  .moremenu {
    all: unset;
    svg {
      &:first-child {
        margin-right: 0;
      }
    }
  }
}

`;

export const StyledCoverageBlock = styled.div``;

export const StyledBasePlanDetail = styled.div`
  p {
    line-height: 1.5;  
  }
  display: flex;

  .planitem {
    flex: 1 0 200px;
    
    @media (max-width: 752px) {
      flex: 0;
    };
  }
    @media (max-width: 767.9px) {
      justify-content: space-between;
    };
`;

export const StyledCoverageFormContainer = styled(Form)`
display: ${(props: {hide: boolean}) => props?.hide ? 'none' : 'block'};
`;

export const StyledInfoMessage = styled(InfoMessage)`
ul {
  list-style: none;
  font-weight: 600;
}`;

export const StyledTable = styled(Table)`
[role='columnheader'],
[role='cell'] {
  text-align: left;
  background-color: #f5f5f5;
}
.planinfo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .planitem {
    margin: 0;
  }
  div {
    top: -15px;
  }
}`;

export const StyledDeleteIcon = styled(AddIcon)`
  color: red;
  cursor: pointer;
  transform: rotate(45deg);
  `;
