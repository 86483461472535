import { injectTranslations } from 'ia-react-core';
import { FC } from 'react';
import { compose } from 'redux';
import BackToIllustrationModal, { BackToIllustrationModalProps } from './BackToIllustrationModal';
import en from './translations/en';
import fr from './translations/fr';

export default compose<FC<BackToIllustrationModalProps>>(
  injectTranslations('BackToIllustrationModal', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(BackToIllustrationModal);

export * from './BackToIllustrationModal';
