import { Resource } from 'ia-react-core';
import React, { FC, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import selectTargetRoute from '~/fragments/ApplicationFragment/selectors/selectTargetRoute';
import { useSelector } from 'react-redux';
import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';
import { State } from '~/interfaces/State';
import { POST_PREFERENTIAL_PRICING_RESOURCE } from '../utilities/constants';

export const PostPreferentialPricingResource: FC = () => {
  const { saleId } = useParams<{ saleId: string }>();
  const history = useHistory();
  const url = useMemo(() => getBffUrlForRoute(BffRoute.application_sales_saleId_coverages_save_or_validate, { saleId }), [saleId]);
  const targetRoute: string = useSelector(selectTargetRoute);
  const backToHome = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);
  let intentType: ApplicationIntentType;
  return (
    <Resource
      name={POST_PREFERENTIAL_PRICING_RESOURCE}
      method="POST"
      url={url}
      transformRequest={(payloadData: {intentType: ApplicationIntentType }) => { intentType = payloadData.intentType; return payloadData; }}
      onSuccess={() => {
        if (backToHome) {
          history.block(true);
          history.push('/');
          return;
        }
        if (intentType === ApplicationIntentType.SaveAndValidate) history.push(targetRoute);
      }}
    />
  );
};
