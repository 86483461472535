import styled from 'styled-components';
interface StyledButtonLinkContainerProps {
  disabled?: boolean;
}

export const StyledButtonLinkContainer = styled.div<StyledButtonLinkContainerProps>`
  ${({ theme, disabled }) => `
    & > button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 32px;
      height: 24px;

      color: ${theme.colors.blue5};

      ${disabled ? `
        pointer-events: none;
        color:${theme.colors.gray4};
      ` : `
        &:hover {
          color: ${theme.colors.blue5};
          & > svg {
            fill: ${theme.colors.blue5};
          }
        }

        &:active  {
          color: ${theme.colors.blue10};
          & > svg {
            fill: ${theme.colors.blue10};
          }
        }
      `}

      &:not(:has([class*="StyledLeftIcon"])) {
        padding-left: 0px;
      }

      & > svg {
        width: 24px;
        height: 24px;
        top: 0px;
        margin-top: 0px;
      }

      & > [class*="StyledLeftIcon"] {
        margin-right: 8px;
      }

      & > [class*="StyledRightIcon"] {
        margin-left: 4px;
      }
    }
  `}
`;
