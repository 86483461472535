const PAGE_NAMES = {
  SIGNATURE: 'Signature',
  SUMMARY: 'Summary',
  SIGNATURE_ECHANGE: 'Signature',
  TRANSMISSION: 'transmission',
  SIGNATURE_MODE: 'signature-mode',
  SIGNATURE_FOLLOWUP: 'signature-followup',
  VALIDATION: 'validation',
  REINSTATEMENT: 'reinstatement',
  EChangeValidation: 'validationchangement',
  EChangeValidationEN: 'validationechange',
  EChangeSUMMARY: 'sommaire',
};

export default PAGE_NAMES;
