import React, { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

export const PREFENTIAL_STATUS_PORTAL_ID = 'PreferentialStatusPortal';

const FluidContainerPreferentialStatusPortal: FC<PropsWithChildren> = ({ children }) => {
  const preferentialStatusPortal = document.getElementById(PREFENTIAL_STATUS_PORTAL_ID);
  return preferentialStatusPortal ? <>{createPortal(children, preferentialStatusPortal)}</> : null;
};

export default FluidContainerPreferentialStatusPortal;
