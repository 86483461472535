import { Action } from 'redux';
import { FormSubState } from '~/interfaces/FormSubState';
import { SetValidationMessagesInValidationPageAction } from '../ApplicationFragment.actions';

export default function setValidationMessagesValidationPage<Value>(state: FormSubState<Value>, action: Action): FormSubState<Value> {
  const { validatorMap, page } = action as SetValidationMessagesInValidationPageAction;
  const selectedPage = { ...state?.ValidationPage };

  return {
    ...state,
    [page]: {
      ...selectedPage,
      validationResults: validatorMap,
    },
  };
}
