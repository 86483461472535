export const ADDITIONAL_COVERAGE = 'additionalCoverage';

export const getLearnMoreUrl = () => process.env.LEARN_MORE_URL;

export enum ProductGuideNames {
  GuideTemporaire = 'GuideProduitTemporaire',
  GuideVieEntiere = 'GuideProduitVieEntiere',
  GuideGenesis = 'GuideProduitGenesis',
  GuideProduitParticipatif = 'GuideProduitPar',
  GuideTransition = 'GuideProduitTransition',
  GuideHeritage = 'GuideProduitHeritage',
  GuideAccesVie = 'GuideProduitAccesVie',
}

export interface productGuideUrlsType {
  [key: string]: {
    [key: string]: string;
  };
}

export const productGuideUrls: productGuideUrlsType = {
  GuideProduitTemporaire: {
    fr: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2022/03/f13-1176-guide-produits-assurance-temporaire.pdf',
    en: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2022/03/f13-1176a-products-guide-term-life-insurance.pdf',
  },
  GuideProduitVieEntiere: {
    fr: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2019/06/dev004803.pdf',
    en: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2019/06/product-guide-whole-life-insurance.pdf',
  },
  GuideProduitGenesis: {
    fr: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2019/06/dev006071.pdf',
    en: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2019/06/dev006072.pdf',
  },
  GuideProduitPar: {
    fr: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2020/04/f13-1070-guide-du-produit-ia-par.pdf',
    en: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2020/04/f13-1070a--product-guide-ia-par.pdf',
  },
  GuideProduitTransition: {
    fr: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2022/10/guide-de-lassurance-maladie-grave.pdf',
    en: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2022/10/critical-illness-insurance-guide.pdf',
  },
  GuideProduitHeritage: {
    fr: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2023/03/guide-produit-heritage.pdf',
    en: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2023/03/product-guide-legacy.pdf',
  },
  GuideProduitAccesVie: {
    fr: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2019/06/av000001.pdf',
    en: 'https://iaa.secureweb.inalco.com/cw/-/media/documents-repository/individual-insurance-savings-and-retirement/individual-insurance/2019/06/al000001.pdf',
  },
};

export const GENESIS = 'GENESIS';
export const PAR = 'PAR';
export const MALADIE_GRAVE = 'MaladieGrave';
export const TRADITIONAL_INSURANCE = 'AssuranceTraditionnelle';
export const UPDATE_INSURED_PROPERTY_RESOURCE_NAME = 'insuredPersonProperty';
export const GET_CALCULATED_AGE_RESOURCE_NAME = 'getCalculatedAge';
export const HIDE_TOOLTIP_PRODUCTS = ['AssuranceTraditionnelle', 'Transition', 'Heritage'];
export const DUPLICATE_WARNING_MODAL_NAME = 'duplicate_warning_modal';
export const LOCALE_EN = 'en';
export const LOCALE_FR = 'fr';
export const LANGUAGE_ENGLISH = 'Anglais';
export const LANGUAGE_FRENCH = 'Francais';

export enum PreferentialStatus {
  STANDARD = 'Standard',
  PRIVILEGE = 'Privilege',
  ELITE = 'Elite',
}

export const AGE_14 = 14;
export const AGE_15 = 15;
export const LANGUAGE_EN = 'en';
export const LANGUAGE_FR = 'fr';
export const VALID_YEAR = /^19\d{2}$|^20\d{2}$/;
export const CATALOG_PRODUCTS_FAMILY = 'Produits';
export const SPECIALIZED_INSURANCE_CATEGORY_TYPE = 'Specialisee';
export const LEGACY_PRODUCT_ID_TEMPLATE = 'HERITAGENIV';
export const CHILD_INSURED_AGE_LIMIT = 16;
export const CHILD_MODULE_AGE_LIMIT = 20;

export const MIN_DATE = new Date('1901-01-01');
const MAX_DATE = new Date();
MAX_DATE.setFullYear(MAX_DATE.getFullYear() + 100);
export { MAX_DATE };

export const SERVER_MIN_DATE = new Date('1000-01-01');
const SERVER_MAX_DATE = new Date();
MAX_DATE.setFullYear(MAX_DATE.getFullYear() + 1000);
export { SERVER_MAX_DATE };

export enum AppEnvironment {
  development = 'development',
  test = 'test',
  production = 'production',
}

export enum Locale {
  EN = 'en',
  FR = 'fr'
}

export const ADD = 'ADD';
export const COPY = 'COPY';
export const DELETE = 'DELETE';
export const RESET = 'RESET';
export const OTHER_TERM_TEMPLATE_ID = 'TRADMTU';
export const ACCESS_LIFE_TEMPLATE_ID = 'FAUX_TEMPLATE_POUR_ACCES_VIE';

export enum NotAvailableLabel {
  ND = 'ND',
  NA = 'N/A'
}
