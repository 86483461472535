import displayDefaultDate from '~/fragments/IllustrationFragment/utilities/displayDefaultDate';
import { PcToolDataResponseModel } from '../interfaces/PcToolDataResponseModel';

const updatePCToolFormValues = (data: PcToolDataResponseModel, planCode: string) =>
  ({
    dob: displayDefaultDate(data.insureds[0]),
    age: data.insureds[0].entiteNommee.ageIllustration,
    selectedPlanCode: planCode,
  });

export default updatePCToolFormValues;
