import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { TextInput } from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import { Locale, QuestionDisplayType } from '~/interfaces/InsurabilityDeclaration/enums';
import { NumberFieldUtil } from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/utils/field.utils';
import QuestionField from './QuestionField';

const NOT_NUMBERS = /\D/;
const MAX_CURRENCY_LENGTH = 14;
const MAX_PERCENTAGE_LENGTH = 3;
const DEFAULT_NUMBER_LENGTH = 2000;

const IntegerField: QuestionField = ({ question, onChange, hasError, hasWarning, ...props }) => {
  const [value, setValue] = useState(((question.answers[0]?.value?.toString()) || ''));
  const [focused, setFocused] = useState(false);

  const debounced = useMemo(() => debounce(onChange, 500, { trailing: true }), [onChange]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    // Ensure only numbers in value
    const newValue = event.target.value.replace(NOT_NUMBERS, '');
    if (newValue !== value) {
      setValue(newValue);
      const parsedValue = Number.parseInt(newValue, 10);
      debounced([parsedValue]);
    }
  }, [debounced, value]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const { symbol, numberFormatter } = NumberFieldUtil.useNumberFormatter(question.displayType, true);

  let maxLength: number;
  switch (question.displayType) {
    case QuestionDisplayType.Currency:
      maxLength = MAX_CURRENCY_LENGTH;
      break;
    case QuestionDisplayType.Percentage:
      maxLength = MAX_PERCENTAGE_LENGTH;
      break;
    default:
      maxLength = DEFAULT_NUMBER_LENGTH;
      break;
  }

  // Display formatted value when focused and plain number when unfocused
  const displayValue = useMemo(() => {
    if (focused) {
      return value;
    }
    const parsedValue = Number.parseInt(value, 10);
    return Number.isNaN(parsedValue) ? '' : numberFormatter(parsedValue);
  }, [focused, numberFormatter, value]);

  const { i18n } = useTranslation();
  const isSignOnLeft = symbol === '$' && i18n.language !== Locale.FR;
  const signProps = isSignOnLeft ? { leftSign: symbol } : { rightSign: symbol };

  return (
    <TextInput
      hasWarning={hasWarning}
      name={question.id}
      maxLength={maxLength}
      onChange={handleChange}
      value={displayValue}
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      isTouched
      isValid={!hasError}
      {...signProps}
    />
  );
};

export default IntegerField;
