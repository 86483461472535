import { SaleFile } from '../interfaces/SaleFile';

export enum ActionType {
  CLEAR_FILTER = 'CLEAR_FILTER',
  RESET_PAGINATION = 'RESET_PAGINATION',
  SET_ALL_FILES = 'SET_ALL_FILES',
  SET_FILTER = 'SET_FILTER',
}

export interface SortingPayload {
  columnName: string;
  sortDirection: string;
}
 interface ClearFilter {
  type: ActionType.CLEAR_FILTER;
 }
 interface ResetPagination {
  type: ActionType.RESET_PAGINATION;
 }

 interface SetFilter {
  type: ActionType.SET_FILTER;
  payload: number;
 }

export const clearFilter = (): ClearFilter => ({
  type: ActionType.CLEAR_FILTER,
});
export const resetPagination = (): ResetPagination => ({
  type: ActionType.RESET_PAGINATION,
});

export const setAllFiles = (data: SaleFile[]) => ({
  type: ActionType.SET_ALL_FILES,
  payload: data,
});

export const setFilter = (filterType: number): SetFilter => ({
  type: ActionType.SET_FILTER,
  payload: filterType,
});
