import RepeatingSectionIdentityModel from '~/interfaces/InsurabilityDeclaration/RepeatingSectionIdentityModel';
import RepeatingSectionModel from '~/interfaces/InsurabilityDeclaration/RepeatingSectionModel';
import RepeatingSectionDto from '../dtos/RepeatingSectionDto';
import SectionBuilder from './SectionBuilder';
import RepeatingSectionInCaseDto from '../dtos/RepeatingSectionInCaseDto';

export default class RepeatingSectionBuilder {
  toModel(dto: RepeatingSectionDto | RepeatingSectionInCaseDto, casePath$: string): RepeatingSectionModel {
    return {
      id: dto.id,
      isActive: dto.isActive,
      title: dto.title,
      name: dto.name,
      progression: dto.progression,
      sectionDisplayType: dto.sectionDisplayType,
      addText: dto.addText,
      deleteText: dto.deleteText,
      casePath$,
      isCompact: dto.isCompact,
      maxInstances: dto.maxInstances,
    };
  }

  toIdentityModel(dto: RepeatingSectionInCaseDto): RepeatingSectionIdentityModel {
    const sectionBuilder = new SectionBuilder();

    return {
      id: dto.id,
      instances: dto.instances.map((i) => sectionBuilder.toIdentityModel(i)),
    };
  }
}
