import { Locale } from '~/fragments/ApplicationFragment/hooks/useCurrentLocale';

const getGender = (gender: string, lang: string) => {
  const genderList = [{ fr: 'Feminin', en: 'Female' }, { fr: 'Masculin', en: 'Male' }];
  if (lang === Locale.EN) {
    const selectedGender = genderList.filter((genderOption: { fr: string; en: string }) => genderOption.fr?.toString() === gender?.toString());
    return selectedGender?.length > 0 ? selectedGender[0].en : gender;
  }
  return gender === 'Feminin' ? 'Féminin' : gender;
};

export default getGender;
