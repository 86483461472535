import { createSelector } from 'reselect';
import { State } from '~/interfaces/State';
import { ValidationMessage } from '~/interfaces/ValidationMessage';

export const selectInsuranceHistoryValidations = createSelector(
  (state: State) => state?.App?.fragments?.ApplicationFragment?.NomineePage?.validations?.insuranceHistory?.validationMessages,
  (validationMessages) => validationMessages || [],
);

export function defaultInsuranceHistoryMessagesSelector(state: State): ValidationMessage[] {
  return state?.App?.fragments?.ApplicationFragment?.NomineePage?.validations?.insuranceHistory?.validationMessages;
}

export const validationChangesMessagesCreateSelector = createSelector(
  (state: State) => state.App?.fragments?.ApplicationFragment?.data?.validate_api_error_message?.validationMessages,
  (validationMessages) => validationMessages || [],
);
