import { ComponentClass } from 'react';
import { compose } from 'redux';
import { injectReducer, injectTranslations } from 'ia-react-core';
import changeRequestReducer from '~/fragments/SharedLibrary/reducers';
import DynamicFormLoader from './DynamicFormLoader';
import fr from './translations/fr';
import en from './translations/en';

export default compose<ComponentClass>(
  injectTranslations('formloader', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  injectReducer({
    key: 'fragments.ApplicationFragment',
    reducer: changeRequestReducer,
    useScope: true,
  }),
)(DynamicFormLoader);
