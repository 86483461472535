import { v4 } from 'uuid';
import IndividualModel from '~/fragments/ApplicationFragment/interfaces/IndividualModel';
import { InsuranceHistoryFormData } from '~/interfaces/InsuranceHistoryPageState';

const checkValue = (value: unknown) => value === '' || value == null ? null : value;

const formatSubmitValues = (
  values: InsuranceHistoryFormData,
  individual: IndividualModel,
) => {
  const formattedInforce = values?.inforceInsurance?.map((inforce) => ({
    ...inforce,
    yearEmission: inforce?.yearEmission ? new Date(inforce?.yearEmission).toISOString() : null,
    protectionAmounts: inforce?.protectionAmounts ?? [],
  }));

  return {
    id: values?.id ?? v4(),
    individualId: values?.individualId || individual?.id,
    hasInforceInsurance: checkValue(values?.hasInforceInsurance),
    hasInsuranceRefused: checkValue(values?.hasInsuranceRefused),
    hasPendingInsurance: checkValue(values?.hasPendingInsurance),
    pendingInsurance: {
      id: values?.individualId || individual?.id,
      ...values?.pendingInsurance?.protectionsAmounts,
    },
    insuranceRefused: values?.insuranceRefused?.map((insurance) => ({
      ...insurance,
      yearSubmission: insurance?.yearSubmission ? new Date(insurance?.yearSubmission.toString()).toISOString() : null,
      protectionType: insurance?.formProtectionType?.reduce((acc, curr) => Number(acc) + Number(curr), 0),
    })),
    inforceInsurance: formattedInforce ?? [],
  };
};

export default formatSubmitValues;
