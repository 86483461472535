import { OTHER_TERM_FIELD_STORAGE_NAME, OTHER_TERM_REGEX } from '../../../constants/InsurancePageConstants';

const handleFormatValueToState = (value: string | number) => {
  let result = value?.toString()?.slice(0, -1);

  if (OTHER_TERM_REGEX.test(value?.toString())) {
    result = value.toString();
    localStorage.setItem(OTHER_TERM_FIELD_STORAGE_NAME, result);
  }

  return localStorage.getItem(OTHER_TERM_FIELD_STORAGE_NAME) ?? result;
};

export default handleFormatValueToState;
