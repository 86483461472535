import React from 'react';
import { ConfirmDialog, useScopedSelector, useTranslation, WarningIcon } from 'ia-react-core';
import { useTheme } from 'styled-components';

export interface DeleteAdvisorConfirmDialogProps {
  onConfirmDeletion: (advisorId: string) => void;
}

const DeleteAdvisorConfirmDialog = (
  {
    onConfirmDeletion,
  }: DeleteAdvisorConfirmDialogProps,
) => {
  const { t } = useTranslation('AdvisorsModal');

  const deleteAdvisorConfirmDialog = useScopedSelector((s: any) => s.modals.deleteAdvisorConfirmDialog);

  const advisorId: string = deleteAdvisorConfirmDialog?.data?.advisorId;

  const handleConfirm = () => {
    onConfirmDeletion(advisorId);
  };

  const theme = useTheme();

  return (
    <ConfirmDialog
      name="deleteAdvisorConfirmDialog"
      cancelButton={t('common.no')}
      confirmButton={t('common.yes')}
      onConfirm={handleConfirm}
      Icon={WarningIcon}
      color={theme?.colors?.warning}
      noheader
    >
      <div style={{ whiteSpace: 'pre-line' }}>{t('delete.confirm')}</div>
    </ConfirmDialog>
  );
};

export default DeleteAdvisorConfirmDialog;
