export const LOAD_ILLUSTRATION_DATA = 'LOAD_ILLUSTRATION_DATA' as const;

export const loadIllustrationData = (data: {categories: []}) => ({
  type: LOAD_ILLUSTRATION_DATA,
  data,
});

export type LoadIllustrationData = ReturnType<typeof loadIllustrationData>;

export type CreateIllustrationActions = LoadIllustrationData;
