import { DesignSystemTheme, T2 } from 'ia-react-core';
import styled from 'styled-components';

export const StyledFormContainer = styled.div``;

export const StyledPartyName = styled(T2)`
  color: ${({ theme }: {theme: DesignSystemTheme}) => theme.colors.primary};
  display: inline-block;
  margin-bottom: 28px;
`;

export const StyledPartyKind = styled.span`
  color: ${({ theme }) => theme.colors.gray13};
  margin-left: 1em;
`;
