import React, { FC } from 'react';
import {
  Divider, Link, MainLogo, Modal, P, PrimaryButton, Spacing, T2, T5, closeModalByName,
} from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { ApplicationConfiguration } from '~/fragments/HomeFragment/pages/HomePage/interfaces/interfaces';
import { StyledInfoModalFlex, StyledInfoModalFlexBr, StyledInfoModalLogoContainer, StyledInfoModalLogoDesc } from './informationModal.styles';
import selectAboutData from './selectors/selectAboutData';
import selectAppConfig from './selectors/selectAppConfig';

export interface InformationModalProps {
}

const InformationModal: FC<InformationModalProps> = () => {
  const { t } = useTranslation('InformationModal');
  const dispatch = useDispatch();

  const aboutData = useSelector(selectAboutData);
  const appConfig: ApplicationConfiguration = useSelector(selectAppConfig);
  const handleClose = () => dispatch(closeModalByName('modals.information'));

  return (
    <Modal
      name="informationModal"
      isClosable
    >
      <T2> {t('about')} </T2>
      <StyledInfoModalFlex>
        <T5>{t('evoVersion')}:</T5>
        <Spacing m-l={4} />
        <P>{aboutData?.evoVersion}</P>
        <StyledInfoModalFlexBr />
        <T5>{t('userCode')}:</T5>
        <Spacing m-l={4} />
        <P>{aboutData?.userCode}</P>
        <StyledInfoModalFlexBr />
        <T5>
          {t('featureToggles')}:
        </T5>
        <Spacing m-l={4} />
        <P>
          {aboutData?.activeFeatureToggles?.map((feature, index) => (
            <React.Fragment key={index}>
              {feature}<br />
            </React.Fragment>
          ))}
        </P>
        <StyledInfoModalFlexBr />
        <T5>{t('buildVersion')}:</T5>
        <Spacing m-l={4} />
        <P>{process.env.REACT_APP_BUILD_NUMBER}</P>
        <StyledInfoModalFlexBr />
        <T5>
          {t('microServices')}:
        </T5>
        <Spacing m-l={4} />
        <P>
          {aboutData?.microserviceInfos?.map((info, index) => (
            <React.Fragment key={index}>
              {`${info.buildVersion} - ${info.microserviceName}`}<br />
            </React.Fragment>
          ))}
        </P>
        <StyledInfoModalFlexBr />
        <T5>{t('mode')}:</T5>
        <Spacing m-l={4} />
        <P>{t(aboutData?.evoMode)}</P>
        <StyledInfoModalFlexBr />
        <T5>
          {t('environment')}:
        </T5>
        <Spacing m-l={4} />
        <P>{t(process.env.NODE_ENV)}</P>
        <StyledInfoModalFlexBr />
        {appConfig?.isOipaEnvironment &&
        <>
          <T5>
            {t('environmentOipa')}:
          </T5>
          <Spacing m-l={4} />
          <P>{appConfig.environmentOipa}</P>
          <StyledInfoModalFlexBr />
        </>}
        <T5>
          {t('copyright')}:
        </T5>
        <Spacing m-l={4} />
        <P>2005 - {t('ia')}</P>
        <StyledInfoModalFlexBr />
        <Link href="https://ia.ca/" m-b={24}>iA.ca</Link>
        <StyledInfoModalFlexBr />
        <StyledInfoModalLogoContainer>
          <MainLogo />
          <Spacing m-l={8} />
          <StyledInfoModalLogoDesc>
            {t('logoDesc')}
          </StyledInfoModalLogoDesc>
        </StyledInfoModalLogoContainer>
      </StyledInfoModalFlex>
      <Spacing m-t={32} />
      <Divider />
      <Spacing m-b={32} />
      <PrimaryButton onClick={handleClose}>{t('close')}</PrimaryButton>
    </Modal>
  );
};

export default InformationModal;
