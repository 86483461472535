export default {
  back_to_products: 'Back to products',
  add_insured: 'Select a coverage to create an illustration',
  years: 'years',
  choose: 'Choose',
  create_illustration_text: 'Select a protection for a new insured',
  change_base_coverage: 'Select a protection to change the basic coverage',
  number_between_10_and_40_message: 'The value must be between 10 and 40.',
  learn_more_about: 'Learn more about',
  coverage: 'coverage',
  createNewScenarioDescription: 'Select a protection to create a scenario',
  version: 'Version',
};
