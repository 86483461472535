import { Download } from './DownloadState';

const prefix = 'MakeEchangeModal';

export const SET_DOWNLOAD_TYPE = `${prefix}.SetDownloading` as const;
export const setDownloadAction = (downloadData: Download) => ({
  type: SET_DOWNLOAD_TYPE,
  downloadData,
});
export type SetDownloadAction = ReturnType<typeof setDownloadAction>;
