import { createSelector } from 'reselect';
import { State } from '~/interfaces/State';
import { ValidationMessage } from '~/interfaces/ValidationMessage';

export default createSelector(
  (state: State) => state?.App?.fragments?.ApplicationFragment?.LinkedApplicationsPage,
  (linkedApplicationsPage) => linkedApplicationsPage?.validations || [],
);
export function defaultLinkedMessagesSelector(state: State): ValidationMessage[] {
  return state?.App?.fragments?.ApplicationFragment?.LinkedApplicationsPage?.validations;
}
