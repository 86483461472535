import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  requestResourceByName, unselectEveryTableRowsByName, openModalByName, closeModalByName, MainLoader,
  Tab,
} from 'ia-react-core';

import { getRelativeUrlForRoute, relativeRoute } from '~/utilities/bffHelper';
import { useHistory } from 'react-router';
import { State } from '~/interfaces/State';
import SaleFileDataTable from '../SaleFileDataTable';

import { deleteFile, isNoFiles } from '../../../api/saleFileApi';

import { SaleFile } from '../interfaces/SaleFile';
import RenameModal from '../RenameModal';
import DeleteModal from '../DeleteModal';
import { Stage } from '../constants';
import FileSharingModal from '../FileSharingModal/FileSharingModal';
import { StyledSaleFileDataTableTabs } from '../SaleFileDataTable/SaleFileDataTable.styles';
import { NoOpenFilesFoundMessage } from '../SaleFileDataTable/components/NoOpenFilesFoundMessage';

export interface SaleFileSectionProps {
  apiUrl: string;
  changeRequestUrl: string;
}

const illustrationRouteUrlEn = (params: Record<string, string>) => getRelativeUrlForRoute(relativeRoute.illustration_coverage_saleId, params);
const illustrationouteUrlFr = (params: Record<string, string>) => getRelativeUrlForRoute(relativeRoute.illustration_couverture_saleId, params);

export const routeMap = {
  illustration: {
    en: illustrationRouteUrlEn,
    fr: illustrationouteUrlFr,
  },
};

const SaleFileSection: FC<SaleFileSectionProps> = ({ apiUrl, changeRequestUrl }) => {
  const { t } = useTranslation('SaleFileSection');
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterType, setFilterType] = useState(0);
  const lang = useSelector((state: State) => state?.language?.locale) as 'en' | 'fr' ?? 'fr';
  const [isNoFilesMessage, setIsNoFilesMessage] = useState(undefined);

  const refreshDataTable = () => {
    dispatch(requestResourceByName('resources.mySaleFiles'));
    dispatch(unselectEveryTableRowsByName('mySaleFiles'));
  };

  const [editingSaleFile, setEditingSaleFile] = useState<SaleFile>();
  const [hasCalledAPI, setHasCalledAPI] = useState(false);

  const openRenameModal = (saleFile: SaleFile) => {
    const params = { saleId: saleFile.id.valeur };
    if (saleFile.progression === Stage.transferee && !saleFile.numeroContrat) {
      history.push(routeMap.illustration[lang](params));
      return;
    }
    setEditingSaleFile(saleFile);
    dispatch(openModalByName('modals.renameSalesFile'));
  };
  const openFileSharingModal = (saleFile: SaleFile) => {
    setEditingSaleFile(saleFile);

    dispatch(openModalByName('modals.filesharing'));
  };

  const handleDeleteFile = (saleFile: SaleFile) => {
    setEditingSaleFile(saleFile);
    openDeleteModal();
  };

  const handleMoreInfos = (/* saleFile: SaleFile */) => {
    /* TODO: handleMoreInfos file */
    refreshDataTable();
  };

  const handleDelete = () => {
    deleteFile(editingSaleFile)
      .then(() => {
        isNoFiles()
          .then((isNoData) => {
            if (isNoData) setIsNoFilesMessage(true);
            else refreshDataTable();
            dispatch(closeModalByName('modals.deleteSalesFile'));
          })
          .catch(() => Promise.reject(new Error('get error')));
      })
      .catch(() => Promise.reject(new Error('delete error')));
  };

  const openDeleteModal = () => {
    dispatch(openModalByName('modals.deleteSalesFile'));
  };

  useEffect(() => {
    if (!hasCalledAPI) {
      isNoFiles()
        .then((isNoData) => {
          if (isNoData !== isNoFilesMessage) {
            setIsNoFilesMessage(isNoData);
          }
          setHasCalledAPI(true);
        })
        .catch(() => Promise.reject(new Error('get error')));
    }
  }, [isNoFilesMessage]);

  useEffect(() => {
    setHasCalledAPI(false);
  }, []);

  const userPermissions = useSelector((state: State) => state?.App?.data?.permissions?.permissions);
  const rolePermissions = useSelector((state: State) => state?.App?.Pages?.RolesPage?.data?.roles?.permissions);
  const finalPermission = rolePermissions !== undefined ? rolePermissions : userPermissions;
  const hasPermission = finalPermission?.includes('EChange.All');

  if (isNoFilesMessage === undefined) {
    return <MainLoader />;
  }

  if (isNoFilesMessage) {
    return (
      <StyledSaleFileDataTableTabs
        small
        paddingXs="24px 16px 24px 16px"
        paddingSm="32px 16px 32px 16px"
        name="typeofBusiness"
      >
        <Tab data-id-tab-new-business="true" title={t('newBusiness')}>
          <NoOpenFilesFoundMessage />
        </Tab>
        <Tab disabled={!hasPermission} data-id-tab-change-request="true" title={t('changeRequest')}>
          <NoOpenFilesFoundMessage />
        </Tab>
      </StyledSaleFileDataTableTabs>
    );
  }

  return (
    <div id="myfiles-component">
      <RenameModal {...{ apiUrl, saleFile: editingSaleFile, filterType }} />
      <DeleteModal {...{ deleteFile: handleDelete, saleFile: editingSaleFile }}></DeleteModal>
      <FileSharingModal saleFile={editingSaleFile} />
      <SaleFileDataTable
        changeRequestUrl={changeRequestUrl}
        onDelete={handleDeleteFile}
        onRename={openRenameModal}
        onMoreInfos={handleMoreInfos}
        onFileShare={openFileSharingModal}
        handleFilterType={setFilterType}
      />
    </div>
  );
};

export default SaleFileSection;
