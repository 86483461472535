import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stage, StageLabels } from '../constants';
import { SaleFile } from '../interfaces/SaleFile';
import { StyledDraftSpan } from './FileStatusCell.styles';

export interface FileStatusCellProps {
  saleFile: SaleFile;
}

export const getStageLabelValueByKey = (keyToFind: string) => Object.entries(StageLabels).find(([key]) => key === keyToFind)?.[1];

const FileStatusCell = ({ saleFile }: FileStatusCellProps) => {
  const { t } = useTranslation('SaleFileDataTable');
  const label = getStageLabelValueByKey(Stage[saleFile.progression as number]);
  if (label) {
    return saleFile.progression === Stage.draft ? <StyledDraftSpan>{t(label) }</StyledDraftSpan> : <span>{t(label) }</span>;
  }
  return null;
};

export { FileStatusCell };
