import React, { FC } from 'react';
import { ReactComponent as EvoLogo } from '~/assets/Icons/evoLogo.svg';
import { useTranslation } from 'react-i18next';
import { Hidden } from 'ia-react-core';
import { StyledSpan } from './MidHeaderLogo.styles';

export interface MidHeaderLogoProps {}

const MidHeaderLogo: FC<MidHeaderLogoProps> = () => {
  const { t } = useTranslation('App');
  return (
    <>
      <EvoLogo />
      <StyledSpan id="logo-text">
        <Hidden xs sm>
          {t('appName')}
        </Hidden>
      </StyledSpan>
    </>
  );
};
export default MidHeaderLogo;
