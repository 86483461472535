export default {
  title: 'Demande de changement électronique',
  subtitle: 'Identification du contrat',
  cancel: 'Annuler',
  proceed: 'Procéder à la demande de changement',
  placeholder: 'Numéro de contrat',
  holderconsent: 'Je confirme avoir obtenu le consentement du contractant à accéder à certains renseignements du contrat.',
  clientidentity: 'Je confirme avoir validé l\'identité du contractant à l\'aide d\'une pièce d\'identité valide.',
  clientidentityTip1: 'Voici les pièces d\'identités reconnues comme valide pour fins d\'identification.Veuillez utiliser l\'original seulement :',
  clientidentityTip2: 'Permis de conduire',
  clientidentityTip3: 'Passeport',
  clientidentityTip4: 'Carte d\'assurance maladie *',
  clientidentityTip5: '* La carte d\'assurance maladie ne peut pas être utilisée aux fins d\'identification dans les provinces suivantes : Ontario, Manitoba, Île-du-Prince-Édouard et Nouvelle-Écosse. Au Québec, on ne peut pas exiger la carte d’assurance maladie aux fins d’identification, mais si le client choisit de la présenter, elle peut être acceptée',
  validmessage: 'Les données téléchargées sont valables pour une durée de 72 heures. Ceux-ci seront automatiquement supprimés après ce délai ou lorsque la demande sera complétée.',
  emptyerror: 'Veuillez saisir un numéro de contrat',
  invaliderror: 'Le contrat n\'est pas valide',
  clientname: 'Nom du client',
  name: 'Nom',
  contractno: 'Numéro de contrat',
  type: 'Type',
  dob: 'Date de naissance',
  invalidrange: 'Veuillez indiquer la date de naissance',
  dateerror: 'Veuillez compléter les données de naissance.',
  dateinvalid: 'La date de naissance de l\'assuré principal n\'est pas valide',
  datetitle: 'Veuillez inscrire la date de naissance du principal assuré:',
  apierror: "Le service de téléchargement n'est actuellement pas disponible. Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le centre d'aide.",
  VN: 'Vie Universelle',
  VT: 'Vie Traditionnelle',
  LI: 'Vie Traditionnelle',
  UW: 'Vie Traditionnelle',
  inValidDate: 'La date saisie n’est pas valide',
  confirmDownloadModal: {
    content: 'Il y a déjà une demande de changement en cours pour ce contrat. Souhaitez-vous l\'ouvrir?',
    no: 'Non',
    yes: 'Oui',
  },
  onapifail: 'Le service de téléchargement n\'est actuellement pas disponible. Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le centre d\'aide.',
  notfound: 'Rechercher pour: ',
  noresultfound: 'Aucun résultat trouvé',
  onsearchapifail: 'Le service n\'est actuellement pas disponible. Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le centre d\'aide.',
  contractInsuredTitle: 'Identification des contractants',
  contractInsuredInfo: 'Dans le but de permettre le traitement adéquat de cette demande, veuillez indiquer si le propriétaire du contrat est une personne morale (compagnie)',
  isCorporation: "I confirmed that I have validated the policyholder's identityby means of a valid identification document.",
  identificationLabel: 'Est-ce que "####" est une personne morale (compagnie) ?',
  no: 'Non',
  yes: 'Oui',
  identifyapplicant: 'Identifier un contractant',
  applicantTitle: 'Contractant(s)',
  dateFormat: 'fr-CA',
  insuredtitle: 'Contractant',
  modifyuser: 'Modification',
  addUser: 'Ajout',
  whoIsDesignateInsureds: 'Veuillez identifier un contractant parmi les assurés listés',
  addApplicant: 'Identifier un contractant autre que ceux identifiés ci-dessus',
  confirmDeleteContentOne: 'Veuillez cliquer sur Oui pour supprimer ce contractant. Vous ne pourrez plus annuler ce changement.',
  confirmDeleteContentTwo: 'D’accord pour supprimer?',
  confirmCancelContentOne: "En annulant, l'information saisie lors de l'identification des contractants ne sera pas sauvegardée.",
  confirmCancelContentTwo: 'Voulez-vous confirmer ?',
  errors: {
    firstname: 'Veuillez indiquer le prénom de cette personne.',
    lastname: 'Veuillez indiquer le nom de cette personne.',
    birthDate: 'Veuillez indiquer le date de naissance.',
    sex: 'Veuillez indiquer le sexe de cette personne.',
    dobfuture: 'La date saisie n\'est pas valide.',
    invalidDate: 'La date saisie n’est pas valide',
  },
  firstname: 'Prénom(s)',
  lastname: 'Nom',
  gender: 'Sexe',
  save: 'Sauvegarder',
  monthplaceholder: 'Mois',
  yearplaceholder: 'AAAA',
  errorTitle: 'Erreur',
  unknownDOBSexHeader: 'Informations à compléter',
  unknownDOBSexMessage: 'Nous avons identifié un ou des informations manquantes dans le contrat. Veuillez les speicifier.',
  lastName: 'Nom',
  firstName: 'Prénom(s)',
  male: 'Masculin',
  female: 'Féminin',
  confirmModal: {
    content1: "En annulant, les informations saisies lors de l'identification du candidat ne seront pas enregistrées",
    content2: 'voulez-vous continuer?',
    no: 'Non',
    yes: 'Oui',
  },
};
