export default {
  quit: 'Quitter',
  save: 'Sauvegarder',
  cancel: 'Annuler',
  download: 'Imprimer',
  delete: 'Supprimer',
  no: 'non',
  yes: 'oui',
  insured: "Pour l'assuré(s):",
  seeError: 'Voir erreur',
  seeWarning: 'Voir avertissement',
  error: 'Erreur',
  warning: 'Avertissement',
  info: 'Info',
};
