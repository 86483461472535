import { IconButton } from 'ia-react-core';
import styled from 'styled-components';

export const StyledDraftSpan = styled.span`
  color: #EF6D19;
`;

export const StyledIconButton = styled(IconButton)`
  color: #2062D4;
  padding-right: 8px;
`;

export const StyledFileActionsChangeCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 1 1 0;
  justify-content: flex-end;
`;

export const StyledFileActionsChangeCellMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
`;

export const StyledFileActionsChangeCellIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
`;
