import { AxiosResponse } from 'axios';
import React, { FC, useCallback } from 'react';
import { Redirect, useHistory } from 'react-router';
import MainLinks from '~/components/MainLinks';
import { News } from '~/interfaces/LatestNews';
import { Resource, changeLanguage } from 'ia-react-core';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';

import { LANGUAGE_EN, LANGUAGE_FR } from '~/constants/APP_CONSTANTS';
import { useDispatch } from 'react-redux';
import CreateIllustration from '../../components/CreateIllustration';
import FetchNewsResourceLoader from './resources/FetchNewsResourceLoader';
import { ApplicationConfiguration } from '../HomePage/interfaces/interfaces';
import { StyledCreateIllustrationPageContainer } from './CreateIllustrationPage.styles';

export interface CreateIllustrationPageProps { }
const languageMap = {
  Anglais: LANGUAGE_EN,
  Francais: LANGUAGE_FR,
};
export interface HomePageProps { }

const CreateIllustrationPage: FC<CreateIllustrationPageProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleNewsSuccess = useCallback(({ data: latestNews }: AxiosResponse<News>) => {
    if (latestNews && !latestNews?.isRead && Object.keys(latestNews)?.length !== 0) {
      history.push('/features');
    }
  }, [history]);

  const checkHasLatestNews = useCallback((latestNews: News) => (
    Boolean(latestNews && (latestNews?.isRead || Object.keys(latestNews)?.length === 0))
  ), []);

  return (
    <StyledCreateIllustrationPageContainer>
      <MainLinks />

      <Resource
        autoRequest
        name="preferenceType"
        url={getBffUrlForRoute(BffRoute.preferenceType, undefined, false)}
      />

      <Resource
        autoRequest
        name="applicationConfiguration"
        url={getBffUrlForRoute(BffRoute.application_configuration, undefined, false)}
        onSuccess={({ data }: {data: ApplicationConfiguration}) => {
          dispatch(changeLanguage(languageMap[data.langue]));
        }}
      />

      <CreateIllustration />

      <FetchNewsResourceLoader
        onSuccess={handleNewsSuccess}
        CustomLoader={() => null}
      >
        {({ data: latestNews }) => (
          !checkHasLatestNews(latestNews) && <Redirect to="/features" />
        )}
      </FetchNewsResourceLoader>

    </StyledCreateIllustrationPageContainer>
  );
};

export default CreateIllustrationPage;
