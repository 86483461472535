import React, { FC } from 'react';
import { Resource, WORKFLOW_STATUSES } from 'ia-react-core';
import { setPageValidationMessagesAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';
import { ApiResponse } from '~/interfaces/ValidationMessage';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import scrollIntoView from '~/utilities/scrollIntoView';
import getStatusFromValidationMessages from '~/fragments/ApplicationFragment/utilities/getStatusFromValidationMessages';
import { updateRouteStatus } from '~/App.actions';
import selectCurrentRoute from '~/fragments/ApplicationFragment/selectors/selectCurrentRoute';
import { VALIDATE_BILLING_RESOURCE_NAME, BILLING_FORM_NAME } from '../constants';

export interface ValidateBillingResourceProps {}

const ValidateBillingResource: FC<ValidateBillingResourceProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { saleId, clientId } = useParams<{ saleId: string; clientId: string }>();
  const currentRoute = useSelector(selectCurrentRoute);

  return (
    <Resource
      name={VALIDATE_BILLING_RESOURCE_NAME}
      url={getBffUrlForRoute(BffRoute.save_validate_billing, { saleId, clientId })}
      method="PUT"
      onSuccess={(res) => {
        const { validationMessages } = (res?.data as ApiResponse);
        const status = getStatusFromValidationMessages(validationMessages);
        if (status === WORKFLOW_STATUSES.ERROR) {
          history.push(currentRoute);
        }
        dispatch(setPageValidationMessagesAction('BillingPage', BILLING_FORM_NAME, validationMessages, 'BillingPage'));
        dispatch(updateRouteStatus(currentRoute, status));
        scrollIntoView();
      }}
      onFailure={() => { scrollIntoView(); }}
    />
  );
};

export default ValidateBillingResource;
