import AnswerModel from './AnswerModel';
import { QuestionDisplayType, QuestionType } from './enums';
import ExplanatoryTableModel from './ExplanatoryTableModel';
import QuestionSize from './QuestionSize';

export default class QuestionModel {
  constructor(
    public id: string,
    public title: string,
    public toolTip: string,
    public questionType: QuestionType,
    public displayType: QuestionDisplayType,
    public answers: Array<AnswerModel>,
    public possibleAnswers: Array<string>,
    public isActive: boolean,
    public explanatoryTables: ExplanatoryTableModel[],
    public canTriggerActive: boolean,
    public canBeTriggeredActive: boolean,
    public size: QuestionSize,
    public name: string | null,
    public importanceLevel: number,
    public status: number,
    public listFormat: number,
    public maxLength?: number,
    // No in Magnum API implementation
    public forceRefetchData?: boolean,
    public leftSign?: string,
  ) {}
}
