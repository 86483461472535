import { SecondaryTextIconButton, SideNavLink } from 'ia-react-core';
import styled from 'styled-components';

export const StyledScenarioDrawerSection = styled.section`
  border-top: 1px solid #D3D8DE;
`;

export const StyledScenarioDrawerSideNavLink = styled(SideNavLink)<{active?: boolean}>`
  color: ${(props) => props.active ? '#003DA5' : props.color ?? '#6B6B6B'}; 
`;

export const StyledScenarioDrawerButtonSection = styled.section`
  margin-top: 24px;
  padding-left: '16px';
`;

export const StyledScenarioDrawerSecondaryTextIconButton = styled(SecondaryTextIconButton)`
  display: block;
  margin-bottom: 24px;
`;
