import { Action } from 'redux';
import SectionIdentityModel from '~/interfaces/InsurabilityDeclaration/SectionIdentityModel';
import { get, set } from 'lodash';

import ChangeRequestModel from '~/interfaces/InsurabilityDeclaration/ChangeRequestModel';
import { ChangeRequestState } from '../interfaces/State';
import { CreateRepeatingSectionSuccessAction } from '../ChangeRequest.actions';

export const createRepeatingSectionSuccess = (state: ChangeRequestState, action: Action) => {
  const { repeatingSectionId, newInstance, items } = action as CreateRepeatingSectionSuccessAction;
  const clonedCase = structuredClone(state.changeRequest);
  const instances: SectionIdentityModel[] =
    get(
      clonedCase,
      `${clonedCase.repeatingSections[repeatingSectionId].casePath$}.instances`,
    );

  if (!instances || instances.length === 0) {
    throw new Error('Unable to access repeatingSection instances');
  }

  // Add new instances to the repeating section
  const newInstanceArray = [...instances, newInstance];

  set(
    clonedCase,
    `${clonedCase.repeatingSections[repeatingSectionId].casePath$}.instances`,
    newInstanceArray,
  );

  return {
    ...state,
    changeRequest: {
      ...clonedCase,

      // Add new needed elements
      questions: { ...state.changeRequest.questions, ...items.questions },
      sections: { ...state.changeRequest.sections, ...items.sections },
      repeatingSections: { ...state.changeRequest.repeatingSections, ...items.repeatingSections },
    } as ChangeRequestModel,
  };
};
