const RESOURCE_NAMES = {
  GET_SIGNATURE_ROLES_RESOURCE: 'getSignatureRoles',
  GET_REINSTATEMENT_RESOURCE: 'getReinstatement',
  UPDATE_REINSTATEMENT_RESOURCE: 'updateReinstatement',
  VALIDATE_REINSTATEMENT_RESOURCE: 'validateReinstatement',
  UPDATE_INSURED_INFORMATION_RESOURCE: 'updateInsuredInformation',
  COMPLETE_CONTRACT_INSURED_RESOURCE: 'completeContractInsured',
  SAVE_REINSTATEMENT_RESOURCE: 'saveReinstatement',
} as const;

export default RESOURCE_NAMES;
