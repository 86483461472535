export default {
  content: 'Content',
  backToIllustration: 'Back to Illustration',
  declarationOfInsurabilityTitle: 'Declaration of insurability',
  headOfficeTitle: 'Head office',
  consentTitle: 'Personal Information Consents',
  warning: 'Caution, you may lose the data entered in the current step. Do you want to go back to the previous step?',
  yes: 'Yes',
  no: 'No',
};
