export const NAME_REFORMAT_REGEX = /[^A-Za-z\-.\s]*/g;
export const INSTITUTIONAL_REFORMAT_REGEX = /[^0-9A-z\-'.\s]*/g;
export const INSTITUTIONAL_REFORMAT_REGEX_CHAR = /[0-9A-Za-z\-'.\s]/;
export const PHONE_REFORMAT_REGEX = /[^0-9]*/g;
export const PHONE_NUMBER_SHOULD_NOT_START_WITH_0_OR_1_REGEX = /^[2-9]\d*$/;
export const PHONE_REFORMAT_REGEX_ALLOWSPACE = /[^0-9\s-]*/g;
export const EMAIL_REFORMAT_REGEX = /[^0-9a-zA-Z'@_.\s-]*/g;
export const INSUREDNAME_REFORMAT_REGEX = /[^A-Za-z\-'.\s]*/g;
export const CODE_EMPLOYEE_REFORMAT_REGEX = /[^0-9]*/g;
export const TEXT_WITH_ALLOWED_SYMBOLS = /[^a-zA-Z\s,.'']+$/;
export const ALPHANUMERIC_WITH_ALLOWED_SYMBOLS = /[^a-zA-Z0-9\s,.'']+$/;
export const LENDER_NAME_REGEX = /[^A-Za-z0-9\-.'\s]*/g;
export const ALPHANUMERIC_SYMBOLS = /[^a-zA-Z0-9]+$/;
export const ALPHABETIC_ONLY = /[^a-zA-Z]*/g;
export const ALPHANUMERIC_WITH_SPACE = /[^a-zA-Z0-9\s]*/g;
export const DISTRIBUTION_REGEX = /[^\d/]*/g;
export const ORGANIZATION_NAME_REGEX = /[^A-Za-z0-9\-'. ]/g;
export const NUMERIC_DECIMAL = /^(?:\d{0,10}(?:\.\d{0,2})?|\.\d{0,2})$/;
export const NUMERIC_DECIMAL_COMMA = /^(?:\d{0,10}(?:[.,]\d{0,2})?|[.,]\d{0,2})$/;
export const NUMERIC = /^(?:\d{0,3}(?:[.,]\d{0,2})?|\d{1,3}(?:[.,]\d{1,2})?)$/;
export const NUMERIC_NO_COMMA = /^(?:\d{1,3}(?:[.]\d{0,2})?|\d{0,3}(?:[.]\d{1,2})?)$/;
export const CURRENCY_REGEX_FOR_REMOVING_ZEROS = /^0/;
export const APPLICANTNAME_REFORMAT_REGEX = /[^A-Za-z0-9&\s]*/g;
export const TITLE_WITH_SYMBOLS_REGEX = /[^A-Za-z0-9-',.\s]*/g;
export const NUMERIC_WITH_SYMBOLS = /[^0-9,.\s]*/g;
export const TEXT_AREA_REGEX = /[^A-Za-z0-9,./'-@$%*():\s]+/;
export const TEXT_INPUT_REGEX = /[^A-Za-z0-9-',.\s]+/;
export const AUTO_SUGGEST_RESOURCE_INPUT_REGEX = /[^A-Za-z0-9-',.\s]+/;

// eslint-disable-next-line
export const LETTERS_SYMBOLS = /[^a-zA-ZÀ-ÿ`'",.\s;:!@#$%^&+=*()_{}\\|?\/\[\]<>-]+/;
// eslint-disable-next-line
export const NUMBERS_SYMBOLS = /[^0-9`'",.\s;:!@#$%^&+=*()_{}\\|?\/\[\]<>-]+/;
export const LETTERS_NUMBERS = /[^a-zA-ZÀ-ÿ`',.\s0-9]+/;
export const LETTERS_NUMBERS_SPECIAL_CHARACTERS = /[^a-zA-ZÀ-ÿ`',-.\s0-9]+/;
export const NUMBERS = /[^0-9]+/;
export const LETTERS = /[^a-zA-ZÀ-ÿ]+/;
export const LETTERS_SIGNATORY = /[^a-zA-ZÀ-ÿ-]+/;
export const NUMBER_DECIMAL = /^\d{1,2}(\.\d{1,2})?$/;
export const NUMBERS_WITH_SLASH = /[^0-9/]+/;
