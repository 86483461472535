import React, { FC } from 'react';
import { StructuredRouter } from 'ia-react-core';
import IllustrationLayout from '~/layouts/IllustrationLayout';
import ILLUSTRATION_ROUTES from './constants/ILLUSTRATION_ROUTES';
import ScenariosResourceLoader from './resources/ScenariosResourceLoader/ScenariosResourceLoader';

export interface IllustrationFragmentProps {}

const IllustrationFragment: FC<IllustrationFragmentProps> = () => (
  <ScenariosResourceLoader>
    <IllustrationLayout>
      <StructuredRouter
        routes={ILLUSTRATION_ROUTES}
      />
    </IllustrationLayout>
  </ScenariosResourceLoader>
);

export default IllustrationFragment;
