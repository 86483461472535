export default {
  title: 'Imprimer',
  generate: 'Imprimer',
  chooseDocumentsLabel: 'Choix des documents',
  printAvailable: 'Liste des documents disponibles pour l\'impression',
  scenarioLabel: 'SCENARIO {0}',
  allIllustations: 'Toutes les illustrations',
  allApplications: 'Toutes les propositions',
  mergeLabel: 'Fusionner les documents',
  cancel: 'Annuler',
  Yes: 'Oui',
  No: 'Non',
  illustrations: 'Illustration',
  applications: 'Proposition',
  transactions: 'Transaction(s)',
  notSelectedMessage: 'Vous devez sélectionner au moins un document à imprimer.',
  errorTitle: 'Erreur',
  illustration: 'Illustration',
  application: 'Proposition',
  seeError: 'Voir erreur',
  errorMessage: 'Une erreur inattendue s\'est produite. Vous pouvez nous joindre au numéro ci-dessous durant les heures d\'ouverture de nos bureaux, soit du lundi au vendredi, de 8 h 30 à 20 h (heure de l\'Est). Sans-frais : 1-888-610-5101',
  okText: 'OK',
  mergedDocuments: 'Documents_fusionnés',
  workInProgress: 'Traitement en cours...',
};
