import { IconButton, P, Col, media, T5, TertiaryButton, ToggleSwitch } from 'ia-react-core';
import styled, { css } from 'styled-components';
import { BorderBox } from '~/components/BorderBox';

interface StyledColProps {
  isInModal?: boolean;
}

interface StyledBoxProps {
  hideTab?: boolean;
}

export const StyledInsurancePageContainer = styled(BorderBox)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 608px;
`;
StyledInsurancePageContainer.defaultProps = {
  p: 32,
};

export const StyledInsurancePageBaseStyledCol = styled(Col)<StyledColProps>`
${(props) =>
    props.isInModal
      ? css`
          flex: none;
      `
      : media.md`
        &.col-1-3 {
          top: -150px;
        }
        &.col-6-2 {
          top: -110px;
        }
      `}
`;

export const StyledCol = styled(Col)`
  ${media.md`
    &.col-1-3 {
      top: -150px;
    }
    &.col-6-2 {
      top: -110px;
    }
  `}
`;

export const EndColStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  marginRight: '10px',
};
export const EndColFlexItem = {
  flexBasis: '50px',
};

export const StyledPersonName = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme?.colors?.blue5};
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;

  /* Body/regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;


  text-decoration: none;
  position: relative;
  display: inline-block;
  background: transparent;
  cursor: pointer;
`;

export const StyledInsurancePagePersonName = styled.div`
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(32, 98, 212);
  position: relative;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  line-height: 1.29;
  background: transparent;
  cursor: pointer;
  border: 0px;
  padding: 1px 2px;
  margin: -1px -2px;
  outline: 0px;
  border-radius: 3px;
  white-space: nowrap;
  max-width: calc(100%);

  ${media.sm`
    max-width: calc(80%);    
  `}

  ${media.md`
    max-width: calc(200%); 
  `}

  ${media.lg`
    max-width: calc(95%); 
  `}

  ${media.xl`
    max-width: calc(85%); 
  `}

  ${media.xl`
    max-width: calc(125%); 
  `}
`;

export const StyledInsurancePageBox = styled.div<StyledBoxProps>`
.react-tabs__tab {
  ${media.sm`
    min-width: 75px;
  `}
}
  .react-tabs__tab-panel {
    padding-left: 0;
    padding-right: 0;
    border: none;
  }
  .react-tabs__tab-list {
    ${(props) => props.hideTab && 'display: none;'}
    border-bottom: none;
  }
  .react-tabs__tab--selected {
    ${media.sm`
      min-width: 150px;
    `}
    border-left: none;
    border-right: none;
  }
`;

export const StyledBox = styled.div<StyledBoxProps>`
.react-tabs__tab {
  ${media.sm`
    min-width: 75px;
  `}
}
  .react-tabs__tab-panel {
    padding-left: 0;
    padding-right: 0;
    border: none;
  }
  .react-tabs__tab-list {
    ${(props) => props.hideTab && 'display: none;'}
    border-bottom: none;
  }
  .react-tabs__tab--selected {
    ${media.sm`
      min-width: 150px;
    `}
    border-left: none;
    border-right: none;
  }
`;

export const StyledInsurancePageP = styled(P)<{$fontSize?: string; $textAlign?: string}>`
  font-size: ${({ $fontSize }) => $fontSize};
  text-align: ${({ $textAlign }) => $textAlign};
  margin-bottom: 0px;
`;

export const StyledInsurancePageTitle = styled(T5)`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
`;

export const StyledInsurancePageTitleContainer = styled.div`
  display: flex;
  padding: 4px 0px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.gray13};;
`;

export const StyledInsurancePageIconButton = styled(IconButton)`
  float: right;
`;

export const StyledInsurancePageSection = styled.section`
  display: ${
  ({ children }) => !children || (Array.isArray(children) && children.length === 0) ?
    'none' :
    'flex'};
  flex-direction: column;
  gap: 8px;
`;

export const StyledInsurancePageSectionsContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledInsurancePageMainSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledInsurancePageTextButton = styled(TertiaryButton)`
  color: ${({ theme }) => theme?.colors?.blue5};
  & span span {
    flex: none;
  }
`;
StyledInsurancePageTextButton.defaultProps = {
  text: true,
};

export const StyledInsurancePageToggleSwitchContainer = styled.div`
  & div {
    margin-bottom: 0px;
  }
  
  & div label {
    margin-bottom: 0px;
  }
`;

export const StyledInsurancePageToggleSwitch = styled(ToggleSwitch)``;
