import React, { FC, useEffect, useMemo } from 'react';
import {
  T2, T5, Spacing, Divider, useTranslation, ToggleSwitchControl, useScopedSelector, CheckboxListControl, useFormContext,
  ControlAnchor,
} from 'ia-react-core';
import PrintReportState from '~/interfaces/PrintReport';
import { useSelector } from 'react-redux';
import { selectApplicationContextMode } from '~/fragments/ApplicationFragment/selectors/selectApplicationContextMode';
import { ApplicationMode } from '~/interfaces/ApplicationMode';
import { selectDemandHasProposition } from '~/fragments/ApplicationFragment/selectors/selectDemandHasProposition';

export interface PrintReportContentProps {}

const PrintReportContent: FC<PrintReportContentProps> = () => {
  const { t } = useTranslation('PrintReportModal');
  const { setControlValue } = useFormContext();
  const formValues = useScopedSelector((state: PrintReportState) => state?.values?.printReport);
  const isMergedDisabled = useMemo(() => formValues?.selectedDocuments && formValues?.selectedDocuments?.length === 2, [formValues?.selectedDocuments]);
  const applicationMode = useSelector(selectApplicationContextMode);
  const demandHasProposition = useSelector(selectDemandHasProposition);

  const label = useMemo(() => applicationMode === ApplicationMode.ChangementElectronique ? t('transactions') : t('illustrations'), [applicationMode]);
  const labelValue = useMemo(() => applicationMode === ApplicationMode.ChangementElectronique ? 'Transaction' : 'Illustration', [applicationMode]);

  const options = useMemo(() => {
    const tempOptions = [{ label: `${label}`, value: labelValue }];
    if ((applicationMode === ApplicationMode.ChangementElectronique && demandHasProposition) || applicationMode !== ApplicationMode.ChangementElectronique) {
      tempOptions.push({ label: t('applications'), value: 'Application' });
    }
    return tempOptions;
  }, [label, labelValue, demandHasProposition, applicationMode]);

  useEffect(() => {
    if (formValues?.selectedDocuments?.length < 2) {
      setControlValue('isMerged', '');
    }
  }, [formValues?.selectedDocuments]);

  return (
    <>
      <T2>{t('title')}</T2>
      <Spacing m={24} />
      <T5>{t('printAvailable')}</T5>
      <Spacing m={24} />

      <CheckboxListControl
        required
        horizontal
        name="selectedDocuments"
        options={options}
        validators={
          {
            required: {
              validator: (value) => !!value?.length,
              message: <>{t('notSelectedMessage')} <ControlAnchor name="selectedDocuments">{t('seeError')}</ControlAnchor></>,
              type: 'error',
            },
          }
        }
      />

      <Spacing m={24} />

      <T5>{t('mergeLabel')}</T5>
      <ToggleSwitchControl
        name="isMerged"
        disabled={!isMergedDisabled}
        options={[
          { label: t('No'), value: false },
          { label: t('Yes'), value: true },
        ]}
      />
      <Spacing m={24} />
      <Divider />

    </>
  );
};

export default PrintReportContent;
