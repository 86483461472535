import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { useHistory, useParams } from 'react-router';
import { ProgressionEnum } from '~/interfaces/ProgressionEnum';
import logger from '~/utilities/logger';
import { BffRoute, getBffUrlForRoute, getRelativeUrlForRoute, relativeRoute } from '~/utilities/bffHelper';
import { Locale, useCurrentLocale } from '~/hooks/useCurrentLocale';
export const UPDATE_SALES_PROGRESSION_BACK_TO_ILLUSTRATION = 'updateSalesProgressionbacktoillustration';

export const UpdateSalesProgression: FC = () => {
  const { saleId } = useParams<{ saleId: string }>();

  const history = useHistory();
  const updateSalesProgressionUrl = getBffUrlForRoute(BffRoute.update_sales_progression, { saleId, progression: `${ProgressionEnum.Illustration}` });
  const locale = useCurrentLocale();
  const coveragesUrl = locale === Locale.EN ? getRelativeUrlForRoute(relativeRoute.illustration_coverage_saleId, { saleId }) : getRelativeUrlForRoute(relativeRoute.illustration_couverture_saleId, { saleId });

  return (
    <Resource
      name={UPDATE_SALES_PROGRESSION_BACK_TO_ILLUSTRATION}
      url={updateSalesProgressionUrl}
      method="PUT"
      onSuccess={() => {
        history.push(coveragesUrl);
      }}
      onFailure={() => {
        // TODO: Handle error to notify user
        logger.error('failed updating sales progression');
      }}
    />
  );
};
