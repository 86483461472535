// VE_Evo\application\Interactivite.Ventes.DomainObjects\src\Enums\ProgressionEnum.cs
export enum ProgressionEnum {
    Proposition = 0,
    Signature = 2,
    Illustration = 4,
    Individu = 5,
    Admissibilite = 6,
    Validation = 7,
    Changements = 8,
    RemiseEnVigueur = 9,
    Transferee = 10,
    CeremonieSignatureDebutee = 11,
    Signee = 12,
    Client = 13,
    PassageALaProposition = 14,
    Conseillers = 15,
    Brouillon = 99,
}
