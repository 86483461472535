import {
  ArrowLeftIcon, Form, HiddenControl, InternalLink, Loader, PageNotFoundErrorMessage, ResourceLoader, Spacing, useScopedSelector, changeLanguage, requestResourceByName,
  openModalByName,
} from 'ia-react-core';
import React, { FC, MouseEvent, ReactNode, useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import APPLICATION_ROUTES, { APPLICATION_ROUTES_KEY, applicationRoutesRecords } from '~/constants/APPLICATION_ROUTES';
import { useHistory, useParams } from 'react-router';
import { BffRoute, getBffUrlForRoute, getRelativeUrlForRoute, relativeRoute } from '~/utilities/bffHelper';
import { WorkflowLayout } from '~/layouts/WorkflowLayout';
import { UPDATE_SALES_PROGRESSION_BACK_TO_ILLUSTRATION, UpdateSalesProgression } from '~/components/Resources/UpdateSalesProgression';
import { Locale, useCurrentLocale } from '~/hooks/useCurrentLocale';
import { State } from '~/interfaces/State';
import { ProgressionEnum } from '~/interfaces/ProgressionEnum';
import { NOMINEE_ROUTES_KEY, nomineeRoutes } from '~/constants/NOMINEE_ROUTES';
import { CustomTitleLogic } from '~/layouts/WorkflowLayout/components/WorkflowLayoutTitleLabelResolver';
import { useTranslation } from 'react-i18next';
import { loadApplication } from './ApplicationFragment.actions';
import selectIsEntityLoaded from './selectors/selectIsEntityLoaded';
import selectNominees from './selectors/selectINominees';
import { IDENTIFICATIONRELATIONSHIPS, RELATIONSHIPS } from './constants';
import ApplicationNavRoutesProvider from './hooks/ApplicationNavRoutesProvider';
import { StyledBackButtonContainer } from './components/NomineeNavRoutes/NomineeNavRoutes.styles';
import { ApplicationNavRoutesContext } from './hooks/useApplicationNavRoutes';
import { languageMap } from '../IllustrationFragment/pages/CoveragesPage/utilities/languageMap';
import BackToIllustrationModal from './components/BackToIllustrationModal';
import { BACK_TO_ILLUSTRATION_MODAL } from './components/BackToIllustrationModal/constants/constants';
import selectSignatureDetails from './selectors/selectSignatureDetails';
interface ApplicationDetails {
  language: string;
}

export interface ApplicationFragmentProps {
  showBackLink?: boolean;
}
const ApplicationFragment: FC<ApplicationFragmentProps> = ({ showBackLink }) => {
  const dispatch = useDispatch();
  const { saleId } = useParams<{ saleId: string }>();
  const nominees = useSelector(selectNominees);
  const applicationDetails = useScopedSelector((state: ApplicationDetails) => state);
  const applicationLanguage = applicationDetails?.language as keyof typeof languageMap;
  const signatureElectronicData = useSelector(selectSignatureDetails);
  const disableBackToIllustrationLink = useMemo(() => signatureElectronicData?.isCeremonyInitialized || signatureElectronicData?.isSignatureComplete, [signatureElectronicData]);
  useEffect(() => {
    if (applicationLanguage) dispatch(changeLanguage(languageMap[applicationLanguage]));
  }, [applicationLanguage]);

  const hasNominees: boolean = useMemo(() => nominees?.length > 0, [nominees]);

  useEffect(() => {
    dispatch(loadApplication(saleId));
  }, [saleId, dispatch]);

  const initialLoad = useScopedSelector(selectIsEntityLoaded);

  const { t } = useTranslation('Application');
  const locale = useCurrentLocale();
  const history = useHistory();
  const progression = useSelector((state: State) => state?.App?.data?.saleMetadata?.progression);
  const [showCoverage, setShowCoverage] = React.useState(undefined);

  const coveragesUrl = locale === Locale.EN ? getRelativeUrlForRoute(relativeRoute.illustration_coverage_saleId, { saleId }) :
    getRelativeUrlForRoute(relativeRoute.illustration_couverture_saleId, { saleId });

  const handleOnBackLinkClick = useCallback((evt: MouseEvent) => {
    evt.preventDefault();
    dispatch(openModalByName(`modals.${BACK_TO_ILLUSTRATION_MODAL}`));
  }, [dispatch]);

  const handleBackToIllustration = () => {
    if (progression === ProgressionEnum.Brouillon) {
      history.push(coveragesUrl);
      setShowCoverage(true);
    } else {
      setShowCoverage(false);
      dispatch(requestResourceByName(`resources.${UPDATE_SALES_PROGRESSION_BACK_TO_ILLUSTRATION}`));
    }
  };

  const customTitleLogic: CustomTitleLogic = (setTitleLabel, newTitleLabel) => {
    const consentRouteLabel = nomineeRoutes[NOMINEE_ROUTES_KEY.CONSENT]?.label as Record<string, ReactNode>;
    if (consentRouteLabel?.[locale] === newTitleLabel) {
      setTitleLabel(t('consentTitle'));
      return;
    }
    // HACK: force the declaration of insurability title to be different form the page title
    const declarationLabels = nomineeRoutes[NOMINEE_ROUTES_KEY.DECLARATIONS]?.label as Record<string, ReactNode>;

    if (declarationLabels?.[locale] === newTitleLabel) {
      setTitleLabel(t('declarationOfInsurabilityTitle'));
      return;
    }
    const headOfficeRoute = applicationRoutesRecords[APPLICATION_ROUTES_KEY.HEADOFFICE];
    const headOfficeLabels = headOfficeRoute?.label;
    if ((headOfficeLabels as { fr: ReactNode; en: ReactNode })?.[locale] === newTitleLabel) {
      setTitleLabel(t('headOfficeTitle'));
      return;
    }

    setTitleLabel(newTitleLabel);
  };

  return (
    <>
      <ResourceLoader name={RELATIONSHIPS} url={getBffUrlForRoute(BffRoute.user_relationship_types, { context: 'BeneficiaireAssureVie' })}>
        {null}
      </ResourceLoader>
      <ResourceLoader name={IDENTIFICATIONRELATIONSHIPS} url={getBffUrlForRoute(BffRoute.user_relationship_types, { context: 'AssureContractant' })}>
        {null}
      </ResourceLoader>
      <BackToIllustrationModal onConfirm={handleBackToIllustration} />
      {initialLoad && <Loader />}

      {!initialLoad && !hasNominees && <PageNotFoundErrorMessage />}

      {
        !initialLoad && hasNominees &&
        <ApplicationNavRoutesProvider>
          <ApplicationNavRoutesContext.Consumer>
            {
              ({ routes: workflowSideNavRoutes }) => (
                <WorkflowLayout
                  routes={APPLICATION_ROUTES}
                  sideNavRoutes={workflowSideNavRoutes}
                  beforeSideNavContent={
                    <>
                      <UpdateSalesProgression />
                      {showBackLink &&
                      <Spacing m-b={20}>
                        <InternalLink
                          href="#"
                          onClick={handleOnBackLinkClick}
                          disabled={disableBackToIllustrationLink}
                        >
                          <StyledBackButtonContainer>
                            <ArrowLeftIcon />
                          </StyledBackButtonContainer>
                          {t('backToIllustration')}
                        </InternalLink>
                      </Spacing>}
                    </>
                  }
                  afterSideNavContent={
                    <Form name="showCoverage">
                      <HiddenControl
                        name="coverage"
                        value={showCoverage}
                      />
                    </Form>
                  }
                  customTitleLogic={customTitleLogic}
                />
              )
            }
          </ApplicationNavRoutesContext.Consumer>
        </ApplicationNavRoutesProvider>
      }

    </>
  );
};

export default ApplicationFragment;
