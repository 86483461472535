import React, { ReactNode } from 'react';
import { MainLoader } from 'ia-react-core';
import { StyledLoadingOverlayContainer, StyledLoadingOverlayInnerContainer } from './LoadingOverlay.styles';

export interface LoadingOverlayProps {
  isLoading: boolean;
  opaque?: boolean;
  loader?: ReactNode;
  fullscreen?: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isLoading, opaque, loader, fullscreen }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <StyledLoadingOverlayContainer $opaque={opaque} $fullscreen={fullscreen}>
      <StyledLoadingOverlayInnerContainer>
        {loader ?? <MainLoader />}
      </StyledLoadingOverlayInnerContainer>
    </StyledLoadingOverlayContainer>
  );
};

export default LoadingOverlay;
