import {
  Col,
  Divider,
  Drawer,
  HelpIcon,
  IconButton,
  Menu,
  MenuIcon,
  MenuItem,
  Row,
  SecondaryTextIconButton,
  SettingsIcon,
  openDrawerByName,
  openModalByName,
} from 'ia-react-core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as HomeIconBlue } from '~/assets/Icons/homeIcon.svg';
import { ReactComponent as WorkIconBlue } from '~/assets/Icons/workIcon.svg';
import { ReactComponent as SaveDiskBlueIcon } from '~/assets/Icons/saveDiskBlue.svg';
import { ReactComponent as CloudIconBlue } from '~/assets/Icons/cloudDoneBlue.svg';

export interface MyFilesButtonProps {
  saleFileName: string;
  showSaveDiskIcon: boolean;
  onSaveClick: () => void;
}

const MyFilesButton: FC<MyFilesButtonProps> = ({ saleFileName, showSaveDiskIcon, onSaveClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('App');

  return (
    <>
      <IconButton onClick={() => { dispatch(openDrawerByName('drawers.myFiles')); }} label={t('menuIcon')} Icon={MenuIcon} />
      <Drawer
        drawerTitle="My files"
        name="myFiles"
      >
        <div>
          <Divider />
          <Row p-t={24}>
            {showSaveDiskIcon &&
            <Col onClick={onSaveClick} data-test-id="saveDiskBlueIcon">
              <SecondaryTextIconButton LeftIcon={SaveDiskBlueIcon}>
                {t('save')}
              </SecondaryTextIconButton>
            </Col>}
            {!showSaveDiskIcon &&
            <Col>
              <SecondaryTextIconButton LeftIcon={CloudIconBlue} data-test-id="cloudIconBlue">
                {saleFileName}
              </SecondaryTextIconButton>
            </Col>}
          </Row>
          <Row p-t={16}>
            <Col>
              <SecondaryTextIconButton LeftIcon={WorkIconBlue}>
                {t('work')}
              </SecondaryTextIconButton>
            </Col>
          </Row>
          <Row p-t={16}>
            <Col>
              <SecondaryTextIconButton LeftIcon={HomeIconBlue}>
                {t('home')}
              </SecondaryTextIconButton>
            </Col>
          </Row>
          <Row p-t={16}>
            <Col>
              <Menu
                data-testid="menu"
                items={[
                  <MenuItem
                    url="#"
                    onClick={() => dispatch(openModalByName('modals.advisors'))}
                  >
                    {t('advisors')}
                  </MenuItem>,
                  <MenuItem url="#" key="illustration" data-testid="menu-illustration" onClick={() => dispatch(openModalByName('modals.illustrationSettings'))}>
                    {t('illustrations')}
                  </MenuItem>,
                  <MenuItem url="#">
                    {t('signature')}
                  </MenuItem>,
                ]}
              >
                <SecondaryTextIconButton LeftIcon={SettingsIcon}>
                  {t('settings')}
                </SecondaryTextIconButton>
              </Menu>
            </Col>
          </Row>
          <Row p-t={16}>
            <Col>
              <SecondaryTextIconButton LeftIcon={HelpIcon}>
                {t('help')}
              </SecondaryTextIconButton>
            </Col>
          </Row>
        </div>
      </Drawer>
    </>
  );
};

export default MyFilesButton;
