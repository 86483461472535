import React, { FC, useEffect, useState } from 'react';
import AppLayout from '~/layouts/AppLayout';
import { SpacingProps, useScopedSelector } from 'ia-react-core';
import { APPLICATION_ROUTES_KEY } from '~/constants/APPLICATION_ROUTES';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import ApplicationFragment from '..';

export interface ApplicationFragmentLayoutProps { }

const spacingProps: SpacingProps = {
  'm-v': 0,
  'p-h-xs': 0,
  'p-h-sm': 0,
  'p-h-md': 0,
};

const ApplicationFragmentLayout: FC<ApplicationFragmentLayoutProps> = () => {
  const toShowBackLink = useScopedSelector((s: ApplicationFragmentState) => s?.data?.getRoutes)?.find((route) => route.resourceKey === APPLICATION_ROUTES_KEY.ECHANGES)?.isVisible;
  const [isEchangeSection, setIsEchangeSection] = useState(false);

  useEffect(() => {
    setIsEchangeSection((window.location.href.includes('echange') || window.location.href.includes(APPLICATION_ROUTES_KEY.REINSTATEMENT)) ? toShowBackLink : true);
  }, [toShowBackLink, window.location.href]);

  return (
    <AppLayout isApp spacingProps={spacingProps}><ApplicationFragment showBackLink={isEchangeSection} /></AppLayout>
  );
};
export default ApplicationFragmentLayout;
