import { AdvisorDataTableMapping, AdvisorFormFieldMapping } from '../../interfaces/AdvisorFormFieldMapping';

export const ADD_ADVISOR_FORM = 'addAdvisor';
export const ADD_SETTINGS_ADVISOR_FORM = 'addSettingsAdvisor';

export const CODE_AGENT_REGEX = /^[A-z0-9]{6}$/;

export const CODE_AGENT_MAX_LENGTH = 6;
export const FIRSTNAME_MAX_LENGTH = 30;
export const LASTNAME_MAX_LENGTH = 34;
export const NAME_MAX_LENGTH = 80;
export const ORGANIZATION_NAME_MAX_LENGTH = 34;
export const PHONE_MAX_LENGTH = 10;
export const ORGANIZATION_DETAIL_MAX_LENGTH = 25;

export const DEFAULT_DATA_MAPPING: AdvisorFormFieldMapping = {
  code: 'code',
  agentTitle: 'agentTitle',
  lastName: 'lastName',
  firstName: 'firstName',
  agencyName: 'agencyName',
  cellularNumber: 'cellularNumber',
  officeTelephone: 'officeTelephone',
  officePhoneExtension: 'officePhoneExtension',
  faxNumber: 'faxNumber',
  emailAddress: 'emailAddress',
  commissionPercentage: 'commissionPercentage',
  provideAfterSaleService: 'provideAfterSaleService',
  isDefault: 'isDefault',
};

export const DEFAULT_ADVISOR_DATA_TABLE_MAPPING: AdvisorDataTableMapping = {
  name: (a) => `${a.firstName} ${a.lastName}`,
  code: (a) => a.code,
  provideAfterSaleService: (a) => a.provideAfterSaleService,
  commissionPercentage: (a) => `${a.commissionPercentage}%`,
  isDefault: (a) => a.isDefault,
};

export enum AdvisorModalView {
  Table = 'Table',
  Form = 'Form',
  FormEdit = 'FormEdit'
}
