import { Action } from 'redux';
import { UpdateSignaturePaperFormAction } from '../ChangeRequest.actions';
import { ChangeRequestState } from '../interfaces/State';

export function updateSignaturePaperFormReducer(state: ChangeRequestState, action: Action) {
  const { payload } = action as UpdateSignaturePaperFormAction;
  return {
    ...state,
    values: {
      ...state.values,
      signaturePaper: {
        ...state.values.signaturePaper,
        ...payload,
      },
    },
  };
}
