import { Locale } from '~/constants/APP_CONSTANTS';

const acceptOnlyNumericDecimal = (value: number|string, locale: string) => {
  if (locale === Locale.EN) return String(value).replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{2}).+/g, '$1');
  // French version returns value with , instead of .
  // you will need to convert to a string replace the , with . and use Number() to convert it to a number
  return String(value).replace(/[^0-9.,]/g, '').replace('.', ',').replace(/(,.*?),.*/g, '$1').replace(/(,\d{2}).+/g, '$1');
};

export default acceptOnlyNumericDecimal;
