import { Action } from 'redux';
import { InsurabilityDeclarationState } from '~/interfaces/InsurabilityDeclaration/State';
import { SetCurrentFormId } from '../InsurabilityDeclaration.actions';

export default function setCurrentFormId(state: InsurabilityDeclarationState, action: Action): InsurabilityDeclarationState {
  const { formId } = action as SetCurrentFormId;

  return {
    ...state,
    currentFormId: formId,
  };
}
