import { requestResourceByName } from 'ia-react-core';
import { ApplicationIntentType } from '../constants';
import { UPDATE_INDIVIDUAL_RESOURCE_NAME } from '../pages/NomineePage/pages/IdentificationPage/constants';
import IndividualModel from '../interfaces/IndividualModel';
import { IdentificationUtils } from '../pages/NomineePage/pages/IdentificationPage/utilities/Identification.utils';
import { CompanyModel } from '../interfaces/CompanyModel';
import { ProductEnum } from '../pages/CoveragesPage/models/ProductEnum';

const identificationApiCall = (formData: any, nominees: IndividualModel[], productValue: number, nomineeId: string, dispatch: Function) => {
  const isProductUniversalOrSpecializedOrPar = [ProductEnum.Genesis, ProductEnum.CapitalValeur, ProductEnum.Heritage, ProductEnum.Par].includes(productValue);

  const currentNominee = nominees.find((nominee) => nominee.id === nomineeId);
  if (currentNominee) {
    let payload = currentNominee.isPerson ?
      IdentificationUtils.personToBffPayload(currentNominee, formData) :
      IdentificationUtils.organizationToBffPayload(currentNominee as unknown as CompanyModel, formData);
    if (!(isProductUniversalOrSpecializedOrPar && currentNominee.isPerson && currentNominee.isApplicant)) {
      payload = { ...payload, identity: null };
    }

    const saveRequest = currentNominee.isPerson ? {
      personViewModel: payload,
      intentType: ApplicationIntentType.SaveAndValidate,
    } : {
      companyViewModel: payload,
      intentType: ApplicationIntentType.SaveAndValidate,
    };
    dispatch(requestResourceByName(`resources.${UPDATE_INDIVIDUAL_RESOURCE_NAME}`, { ...saveRequest, initateFromLeftMenu: true }));
  }
};

export default identificationApiCall;
