import React, { FC, useEffect } from 'react';
import SectionIdentityModel from '~/interfaces/InsurabilityDeclaration/SectionIdentityModel';
import { DeleteIcon, requestResourceByName, TertiaryButton } from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import BorderBlock from '~/fragments/ApplicationFragment/pages/NomineePage/components/BorderBlock/BorderBlock';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '~/interfaces/AppState';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { deleteInstanceSectionSuccessAction } from '~/fragments/SharedLibrary/ChangeRequest.actions';
import Section from './Section';

export interface InstanceSection {
  section: SectionIdentityModel;
  isAloneInstance: boolean;
  parentRepeatingId: string;
  formId: string;
}

const InstanceSection: FC<InstanceSection> = ({ formId, section: sectionIdentity, isAloneInstance, parentRepeatingId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('formloader');

  const selectedCase = useSelector((s: AppState) => s.App?.fragments?.ApplicationFragment?.changeRequest);
  const deleteRepeatingSection = useSelector((s: AppState) => s.App?.fragments?.ApplicationFragment?.data?.delete_repeating_section);

  useEffect(() => {
    if (deleteRepeatingSection && (deleteRepeatingSection.id === sectionIdentity.id)) {
      dispatch(deleteInstanceSectionSuccessAction(parentRepeatingId, sectionIdentity.id));
    }
  }, [deleteRepeatingSection]);

  const handleDeleteClick = () => {
    const param = { saleId: selectedCase.id, repeatingSectionId: parentRepeatingId, sectionId: sectionIdentity.id };
    const url = getBffUrlForRouteWithoutQP(BffRoute.delete_repeating_section, param, false);
    dispatch(requestResourceByName('resources.delete_repeating_section', param, { url }));
  };

  return (
    <BorderBlock white>
      <Section formId={formId} section={sectionIdentity} key={sectionIdentity.id} />
      {!isAloneInstance &&
      <>
        <br />
        <TertiaryButton text LeftIcon={DeleteIcon} onClick={handleDeleteClick}>{t('delete')}</TertiaryButton>
      </>}
    </BorderBlock>
  );
};

export default InstanceSection;
