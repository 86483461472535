import _ from 'lodash';
import PcToolFormModel from '../interfaces/PcToolFormModel';
import { AMOUNT, FLEX_RETURN_CHECKED, FLEX_RETURN_UNCHECKED, PREMIUM, P_C_TOOL_INSURANCE_TYPE, P_C_TOOL_PRODUCT_CODE, RiderPlanCodes } from '../constants';
import { PcToolDataResponseModel } from '../interfaces/PcToolDataResponseModel';

const createRefreshCoverageOptionsResourcePayload = (formValues: PcToolFormModel, data: PcToolDataResponseModel, params?: Record<string, boolean | string | number >) => {
  const { insureds, isPremiumMode } = data;
  const { simpleLinkedRiders } = formValues;
  const simpleRidersPlanCodes: string [] = [...formValues.simpleLinkedRiders];
  const flexibleReturnPlanCodes = formValues.flexibleReturn ? [formValues.flexibleReturnPeriod, RiderPlanCodes.flexReturnTwenty] : [];
  const riderPlanCodes = simpleRidersPlanCodes.concat(flexibleReturnPlanCodes);
  const flexReturnPlanCodes: string[] = [RiderPlanCodes.flexReturnFifteen, RiderPlanCodes.flexReturnSixtyFive];
  let isPremium;

  switch (params?.planCode) {
    case RiderPlanCodes.deathReturn:
      if (simpleLinkedRiders.includes(params?.planCode)) {
        _.pull(riderPlanCodes, params?.planCode);
      } else {
        riderPlanCodes.push(params?.planCode);
      }
      break;

    case RiderPlanCodes.flexReturnFifteen:
    case RiderPlanCodes.flexReturnSixtyFive:
      if (flexReturnPlanCodes.includes(formValues.flexibleReturnPeriod)) {
        _.pull(riderPlanCodes, formValues.flexibleReturnPeriod);
      }
      riderPlanCodes.push(params?.planCode);
      break;

    case FLEX_RETURN_CHECKED:
      riderPlanCodes.push(RiderPlanCodes.flexReturnFifteen, RiderPlanCodes.flexReturnTwenty);
      break;

    case FLEX_RETURN_UNCHECKED:
      _.pull(riderPlanCodes, RiderPlanCodes.flexReturnFifteen, RiderPlanCodes.flexReturnTwenty, RiderPlanCodes.flexReturnSixtyFive);
      break;

    default:
      break;
  }

  switch (params?.amountPremiumToggle) {
    case AMOUNT:
      isPremium = false;
      break;

    case PREMIUM:
      isPremium = true;
      break;

    default:
      break;
  }

  return {
    faceAmount: formValues.enteredAmount,
    premium: Number(`${formValues.enteredPremium}`?.replace(',', '.')),
    insureds: [{
      insuredId: insureds[0].id,
      firstName: insureds[0].entiteNommee.firstName,
      lastName: insureds[0].entiteNommee.lastName,
      middleName: insureds[0].entiteNommee.middleName,
      dateOfBirth: params?.age || !formValues.dob ? null : formValues.dob,
      age: params?.age ?? formValues.age,
      sex: params?.gender ?? formValues.gender,
      isSmoker: params?.isSmoker !== undefined ? params.isSmoker : formValues.isSmoker,
    }],
    province: params?.province ?? formValues.province,
    paymentFrequency: params?.paymentFrequency ?? formValues.paymentFrequency,
    insuranceType: P_C_TOOL_INSURANCE_TYPE,
    productCode: P_C_TOOL_PRODUCT_CODE,
    riderPlanCodes: _.uniq(riderPlanCodes),
    isPremiumMode: isPremium ?? isPremiumMode,
  };
};

export default createRefreshCoverageOptionsResourcePayload;
