export default {
  coverages: 'Protections',
  amount: 'Montant',
  basePremium: 'Prime base',
  totalPremium: 'Prime totale',
  rpd: 'RPD',
  frp20: 'RFP20',
  frp15: 'RFP15',
  frp65: 'RFP65',
  noCoverage: 'Aucune protection trouvée pour les critères sélectionnés.',
  calculation: 'Calcul en cours...',
};
