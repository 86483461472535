import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorTooltip, IconButton, Tooltip, WarningTooltip } from 'ia-react-core';
import { State } from '~/interfaces/State';
import { useSelector } from 'react-redux';
import { SaleFile } from '../interfaces/SaleFile';
import { StyledDoNotDisturbIcon } from '../SaleFileDataTable/SaleFileDataTable.styles';

export interface FileMessageCellProps {
  saleFile: SaleFile;
}

enum MessageNumber {
  Msg_5047 = '5047',
  Msg_5048 = '5048',
  Msg_5049 = '5049',
  Msg_5050 = '5050',
}

const FileMessageCell = ({ saleFile }: FileMessageCellProps) => {
  const { t } = useTranslation('SaleFileDataTable');
  const currentEvoVersion = useSelector((state: State) => state?.App?.data?.about?.evoVersion);
  if (saleFile.details == null) return null;

  const formatMessage = (str: string, values: string[]) => str.replace(/{(\d+)}/g, (match, number) => typeof values[number] !== 'undefined'
    ? values[number]
    : match);

  const formatDateToLocal = (date: Date) => {
    const options: any = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString(t('dateFormat') ?? 'en-CA', options);
  };

  const retrieveParametersFromDate = () => {
    const params: string[] = [];
    if (!saleFile || !saleFile.details || !saleFile.details.messageProperties) {
      return params;
    }
    saleFile.details.messageProperties.forEach((date) => {
      switch (saleFile.details.messageNumber) {
        case MessageNumber.Msg_5047:
          params.push(saleFile.details.messageProperties[0]);
          break;
        case MessageNumber.Msg_5048:
          params.push(formatDateToLocal(new Date(date)));
          break;
        case MessageNumber.Msg_5049:
          params.push(saleFile.details.messageProperties[0]);
          break;
        default:
          params.push('');
      }
    });
    return params;
  };

  const messageNumber = t(`fileMessages.Msg_${saleFile.details.messageNumber}`);
  const messageText = formatMessage(messageNumber, retrieveParametersFromDate());

  const renderContent = () => {
    if (saleFile.details.shouldMigrate || saleFile.details.isAvertissementPeriodeTransition) {
      return <WarningTooltip tip={messageText} />;
    }
    if (saleFile.details.isErrorPeriodeTransition) {
      return <ErrorTooltip tip={messageText} />;
    }
    if (saleFile?.details?.version !== currentEvoVersion) {
      return <Tooltip data-testid="versionIcon" tip={t('olderVersionMessage')}><IconButton Icon={StyledDoNotDisturbIcon} buttonSize={16} /></Tooltip>;
    }
    return null;
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export { FileMessageCell };
