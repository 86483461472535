import { ComponentClass } from 'react';
import { compose } from 'redux';

import { injectReducer, injectTranslations } from 'ia-react-core';

import fr from './translations/fr';
import en from './translations/en';
import Makeechange from './MakeEchange';
import reducer from './reducers';

export default compose<ComponentClass>(
  injectTranslations('makeEchange', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  injectReducer({
    key: 'components.MakeEchangeModal',
    reducer,
    useScope: true,
  }),
)(Makeechange);
