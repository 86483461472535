import { InsuranceGroup } from '~/interfaces/InsuranceGroup';

const TRADITIONAL_INSURANCE = 'Traditional Insurance';

const orderInsuranceGroups = (productName: string, groups: InsuranceGroup[]) => {
  if (productName === TRADITIONAL_INSURANCE) {
    return [
      groups[0],
      groups[2],
      groups[1],
      groups[3],
    ] as InsuranceGroup[];
  }

  return groups;
};

export default orderInsuranceGroups;
