import React from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { GET_VERSION_NUMBER_OPTIONS } from '~/constants';
import { setAppVersioning } from '~/App.actions';
import { getDisplayVersionNumber } from '~/utilities/getFormattedVersionNumber';
import logger from '~/utilities/logger';
import { AppVersionNumber } from '~/interfaces/AppVersionNumber';
import { useDispatch } from 'react-redux';

export interface GetVersionNumberOptionsResourceProps {
  effectiveDate: string;
}

const GetVersionNumberOptionsResource = ({ effectiveDate }: GetVersionNumberOptionsResourceProps) => {
  const dispatch = useDispatch();
  return (
    <Resource
      name={GET_VERSION_NUMBER_OPTIONS}
      method="GET"
      url={getBffUrlForRoute(BffRoute.application_configuration_application_versions, { effectiveDate }, false)}
      onSuccess={(res) => {
        const versionNumberArr = res?.data as AppVersionNumber[];
        dispatch(setAppVersioning(effectiveDate, getDisplayVersionNumber(versionNumberArr, versionNumberArr[versionNumberArr.length - 1].actualVersionNumber)));
      }}
      onFailure={() => {
        logger.error('failed getting version number options');
      }}
      autoRequest
    />
  );
};
export default GetVersionNumberOptionsResource;
