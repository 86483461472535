import { Resource, ResourceProps, WORKFLOW_STATUSES } from 'ia-react-core';
import React, { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import selectTargetRoute from '~/fragments/ApplicationFragment/selectors/selectTargetRoute';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { loadApplicationWithoutReloading, setPageValidationMessagesAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';
import { updateRouteStatus } from '~/App.actions';
import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';
import getStatusFromValidationMessages from '~/fragments/ApplicationFragment/utilities/getStatusFromValidationMessages';
import { ApiResponse } from '~/interfaces/ValidationMessage';
import { AxiosResponse } from 'axios';
import selectCurrentRoute from '~/fragments/ApplicationFragment/selectors/selectCurrentRoute';
import { SPECIAL_INSTRUCTIONS_POST } from '../constants/ConstantNames';

export interface UpdateSpecialInstructionsResourceProps extends ResourceProps<any, null> {
  validationClientId?: string;
}

const UpdateSpecialInstructionsResource: FC<UpdateSpecialInstructionsResourceProps> = ({ validationClientId, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const targetRoute: string = useSelector(selectTargetRoute);
  const currentRoute: string = useSelector(selectCurrentRoute);
  const { saleId, clientId } = useParams<{ saleId: string; clientId: string }>();

  const intentType = useRef(null);
  let initateFromLeftMenu = true;

  const defaultOnSuccess = (res: AxiosResponse) => {
    const { validationMessages } = (res?.data as ApiResponse);
    const messages = validationMessages || [];
    const status = getStatusFromValidationMessages(messages);
    dispatch(updateRouteStatus(currentRoute, status));
    // TO ADD VALIDATION MESSAGES TO THE REDUX NEW VALUE, SO AUTO SAVE CANNOT VANISH HOISTED WARNINGS
    dispatch(setPageValidationMessagesAction('NomineePage', 'specialInstructions', messages, 'NomineePage'));
    if (intentType.current === ApplicationIntentType.SaveAndValidate) {
      dispatch(loadApplicationWithoutReloading(saleId));
      // we shouldn't push to targetRoute if the action is initiated from left menu
      if (!initateFromLeftMenu) {
        const navigateToRoute = (status === WORKFLOW_STATUSES.ERROR) ? currentRoute : targetRoute;
        history.push(navigateToRoute);
      }
    }
  };

  return (
    <Resource
      name={SPECIAL_INSTRUCTIONS_POST}
      url={getBffUrlForRoute(BffRoute.save_validate_special_instructions, { saleId, clientId: validationClientId || clientId })}
      method="PUT"
      transformRequest={(payloadData: {intentType: ApplicationIntentType; initateFromLeftMenu: boolean}) => { intentType.current = payloadData.intentType; initateFromLeftMenu = payloadData.initateFromLeftMenu; return payloadData; }}
      onSuccess={defaultOnSuccess}
      {...props}
    />
  );
};

export default UpdateSpecialInstructionsResource;
