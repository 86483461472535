import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';
import IndividualModel from '~/fragments/ApplicationFragment/interfaces/IndividualModel';
import { AccountHolder, PacPadAgreementPageState } from '~/interfaces/PacPadAgreementPageState';
import formatAccountHolder from './formatAccountHolder';

const fieldValue = (value: string | boolean): boolean => {
  if (value === '' || value === null || value === undefined) return null;
  return Boolean(value);
};

const branchNumberValue = (value: string | number) => {
  if (value === '' || value === null || value === undefined) return null;
  return value?.toString();
};

const institutionNumberValue = (value: string | number) => {
  if (value === 0 || value === '0' || value === '' || value === null || value === undefined) return null;
  return value?.toString();
};

const accountHolderValue = (value: AccountHolder[], individuals: IndividualModel[], isPersonalBanking: string | boolean) => {
  const accountHoldersData = value?.filter((accountHolder) => accountHolder?.id?.toString() !== 'false');
  if (value === undefined) return [];
  return accountHoldersData?.map((accountHolder) => formatAccountHolder(accountHolder, individuals, isPersonalBanking));
};

const formatRequestToDTOPacPad = (values: PacPadAgreementPageState, individuals: IndividualModel[], intentType: ApplicationIntentType, bankingRequestId = '') => ({
  intentType,
  bankingViewModel: {
    requestId: values?.requestId || bankingRequestId,
    isExistingPreAuthorized: fieldValue(values?.isExistingPreAuthorized),
    existingPolicyForWithdraw: fieldValue(values?.isExistingPreAuthorized) ? (values?.existingPolicyForWithdraw || null) : null,
    isPersonalBanking: fieldValue(values?.isPersonalBanking),
    isApplicantOrganization: values?.isApplicantOrganization || null,
    institutionName: values?.institutionNameHidden || null,
    isWithdrawalDayChosenByClient: fieldValue(values?.isWithdrawalDayChosenByClient),
    withdrawalDay: !values?.withdrawalDay ? null : Number(values.withdrawalDay),
    accountHolders: accountHolderValue(values?.accountHolders, individuals, values?.isPersonalBanking),
    accountNumber: values?.accountNumber?.toString() || null,
    branchNumber: branchNumberValue(values?.branchNumber),
    institutionNumber: institutionNumberValue(values?.institutionNumber),
  },
});

export default formatRequestToDTOPacPad;
