export const CREATE_CONTINGENT_MODAL_NAME = 'createContingentModalName';
export const DELETE_CONTINGENT_MODAL_NAME = 'deleteContingentModalName';
export const SAVE_CONFIRMATION_MODAL_NAME = 'saveConfirmationModalName';
export const CONFIRM_SAME_INDIVIDUAL = 'confirmSameIndividual';

export const CREATE_CONTINGENT_RESOURCE_NAME = 'createContingent';
export const DELETE_CONTINGENT_RESOURCE_NAME = 'deleteContingent';

export const PUT_APPLICANT_RESOURCE_NAME = 'putApplicant';
export const UPDATE_APPLICANT_RESOURCE_NAME = 'updateApplicant';
export const PREVENT_NEXT = 'preventNext';
export const PREVIOUS_BTN_PUT_APPLICANT = 'previousBtnSaveApplicant';
export const NEXT_BTN_PUT_APPLICANT = 'nextBtnSaveApplicant';
export const SAVE_APPLICANT_RESOURCE_NAME = 'saveApplicant';

export const F51_4_DOWNLOAD_RESOURCE_NAME = 'f51Form208A4Download';
export const F51_1_DOWNLOAD_RESOURCE_NAME = 'f51Form1Download';
export const F51_208A_3_DOWNLOAD_RESOURCE_NAME = 'f51Form208A3Download';
export const F51_208A_1_DOWNLOAD_RESOURCE_NAME = 'f51Form208A1Download';

export const ADD_CONTINGENT_FORM_NAME = 'addContingent';
export const SELECT_CONTINGENT_FORM_NAME = 'selectionContingent';

export const MAX_AVAILABLE_CONTINGENT_NB = 7;

export const NAME_REGEX = /[^A-z -]*/;
export const LAST_NAME_CHAR_LIMIT = 35;
export const FIRST_NAME_CHAR_LIMIT = 25;
export const BIRTH_DATE_MIN_YEAR = new Date(1900, 0, 0, 0, 0, 0, 0);
export const IDENTIFICATION_DOCUMENT_CHAR_LIMIT = 25;

export const NoneOfTheAboveFr = 'Aucun des choix ci-haut';
export const NoneOfTheAboveEn = 'None of the above';

export const invalidNames = [
  'madame',
  'mademoiselle',
  'monsieur',
  'mme',
  'mlle',
  'mr',
  'mrs',
  'sir',
  'miss',
  'mister',
  'madam',
  'heritier legaux',
  'estate',
  'heritiers legaux',
  'heritiers',
  'heritier',
  'legaux',
  'signataire',
  'signatory',
  'enfant',
  'un',
  'one',
  'jr',
  'sr',
  'md',
  'dr',
];
