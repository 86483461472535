import { useSelector } from 'react-redux';
import { State } from '~/interfaces/State';
import { createSelector } from 'reselect';

export const selectFeatureToggles = createSelector(
  (state: State) => state?.App?.data?.featureToggles,
  (featureToggles) => featureToggles,
);

const useFeatureToggles = () => useSelector(selectFeatureToggles);

export default useFeatureToggles;
