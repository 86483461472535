export default {
  newSaleTitle: 'New sale',
  myFilesTitle: 'My files',
  accessLifeTitle: 'Access Life',
  otherProductsTitle: 'Other life insurance products',
  lookingOtherProductsTitle: 'Looking for another EVO or Assure & Go product?',
  lookingOtherProductsDescription:
    'All of our products are still available via the usual applications, while this new version of EVO focus on Simplified issue.',
  launchEvoApp: 'Launch EVO desktop app',
  seeAllProducts: 'See all products',
  dontHaveEvoApp: "You don't have the app?",
  downloadIt: 'Download it',
  createIllustration: 'Access Life <br/><strong>Illustration</strong>',
  startApplication: 'Access Life <br/><strong>Application</strong>',
};
