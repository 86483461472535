export default {
  deleteButton: 'Delete',
  deleteBtnMsg: 'Delete {0} files',
  all: 'All',
  notTransmitted: 'Not transmitted',
  transmitted: 'Transmitted',
  inProgress: 'In Progress',
  completed: 'Completed',
  pendingSignature: 'Signature',
  requiredAction: 'Action required',
  noSaleFiles: 'No file',
  searchInputPlaceholder: 'Search',
  noFilesTitle: 'No file',
  noFilesMessage: 'To get started, create',
  showInformation: 'Information',
  showModifiedDate: 'Modified',
  showCreatedDate: 'Created',
  showStatus: 'Status',
  fileDownloadModal: {
    yes: 'Yes',
    no: 'No',
    confirmCancelContentOne: 'This file already exists on your workstation. All the information will be erased and replaced. Are you sure you want to continue?',
    title: 'Import shared file',
    infoContent: 'When you receive a shared key, enter the key in the box and click OK. The received file will open automatically and will be saved in your new business list.',
    sharedKeyTextLabel: 'Enter the shared key',
    ok: 'OK',
    cancel: 'Cancel',
    anchorError: 'See Error',
    Error: 'Error',
    errors: {
      invalidKey: 'The sharing key is invalid.',
      downloadFailError: 'Downloading the shared file failed.',
    },
  },
  fileSharingModal: {
    title: 'File sharing',
    infoContent: 'To share this file, simply send the shared key by email to the recipient of your choice. When he receives the key, he can download your file on his computer using the Download a file tool',
    sharedKeyTextLabel: 'Sharing key*',
    reminder: '* Reminder',
    reminderContent1: "Information on a client can only be shared with people who absolutely need it in the performance of their duties. When you give a person access to client's information, it is your responsibility to ensure that such access is necessary to the tasks assigned to that person.",
    reminderContent2: 'The advisor performing the sale remains fully responsible for the accuracy and reliability of the information collected and entered by another person. In addition, remember that the analysis of information and advice related to the sale cannot be delegated.',
    close: 'Close',
    copyToClipboardButton: 'Copy to clipboard',
    infoMessage: 'To share this file, simply send the shared key by email to the recipient of your choice. When he receives the key, he can download your file on his computer using the Download a file',
    fileShareFailMessage: 'The file sharing failed.',
  },
  newBusiness: 'New Business',
  changeRequest: 'Change Request',
};
