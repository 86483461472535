// export default OtherTerm;

import { injectTranslations } from 'ia-react-core';
import { FC } from 'react';
import { compose } from 'redux';
import fr from '../../../../translations/fr';
import en from '../../../../translations/en';
import OtherTerm, { OtherTermProps } from './OtherTerm';

export default compose<FC<OtherTermProps>>(
  injectTranslations('CreateIllustration', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(OtherTerm);

export * from './OtherTerm';
