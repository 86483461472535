import { ApplicationLayout } from 'ia-react-core';
import styled, { css } from 'styled-components';

export const StyledApplicationLayout = styled(ApplicationLayout) <{ $flexFooterLinks?: boolean }>`
  [data-testid="FooterLinks"] {
    @media (min-width: 768px) {
      ul {
        display: flex;
      }
    }
  }
  ${({ $flexFooterLinks }) => $flexFooterLinks && css`
      [data-testid="FooterLinks"] {
        @media (min-width: 768px) {
        ul {
          display: flex;
        }
      }
    }
  `}
`;
