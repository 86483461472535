import React, { FC, useMemo } from 'react';
import { Resource, ResourceProps } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';

import SetAnswerResponseBuilder from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/builders/SetAnswerResponseBuilder';
import SetAnswerResponseDto from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/dtos/SetAnswerResponseDto';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';

export interface UpdateAnswerQuestionProps extends ResourceProps<null, null> {
  saleId: string;
}
const UpdateAnswerQuestion: FC<UpdateAnswerQuestionProps> = ({ saleId }) => {
  const history = useHistory();
  const isEmployeeRoute = process.env.REACT_APP_EMP_ROUTES.split(',').includes(window.location.origin);
  const redirectUrlonApiFail = isEmployeeRoute ? process.env.REACT_APP_EMP_LOGIN_URL : process.env.REACT_APP_LOGIN_URL;
  const updateChangeRequestUrl = useMemo(() => getBffUrlForRouteWithoutQP(
    BffRoute.update_answer_question,
    { saleId },
    false,
  ), [saleId]);

  const onFailureValidation = (error: AxiosError) => {
    const { response } = error;
    if (response?.status === 401) {
      history.push(redirectUrlonApiFail);
    }
  };

  return (
    <Resource
      name="resources.update_answer_question"
      url={updateChangeRequestUrl}
      data={{}}
      method="PUT"
      onSuccess={(result) => {
        if (result.status !== 200) {
          throw new Error(`Error while saving answer: ${result.status} - ${result.statusText}`);
        }
        const builder = new SetAnswerResponseBuilder();
        const dto = result.data as SetAnswerResponseDto[];
        return dto.map((x) => builder.toModel(x));
      }}
      onFailure={onFailureValidation}
    />
  );
};

export default UpdateAnswerQuestion;
