import { requestResourceByName } from 'ia-react-core';
import { PacPadAgreementPageState } from '~/interfaces/PacPadAgreementPageState';
import { ApplicationIntentType, UPDATE_BANKING_RESOURCE } from '../constants';
import IndividualModel from '../interfaces/IndividualModel';
import formatRequestToDTOPacPad from '../pages/PacPadAgreementPage/utilities/formatRequestToDTOPacPad';

const pacPadApiCall = (formvalues: PacPadAgreementPageState, individuals: IndividualModel[], dispatch: Function, bankingRequestId: string) => {
  const validateRequest = formatRequestToDTOPacPad(formvalues, individuals, ApplicationIntentType.SaveAndValidate, bankingRequestId);

  dispatch(requestResourceByName(`resources.${UPDATE_BANKING_RESOURCE}`, { ...validateRequest, initateFromLeftMenu: true }));
};

export default pacPadApiCall;
