import { designSystemTheme } from 'ia-react-core';
import { AppTheme } from '../interfaces/Theme';

const appTheme: AppTheme = {
  ...designSystemTheme,
  CheckboxList: {
    ...designSystemTheme.CheckboxList,
    forceBoxOnStatus: true,
    noBox: true,
  },
  RadioList: {
    ...designSystemTheme.RadioList,
    forceBoxOnStatus: true,
    noBox: true,
  },
  Message: {
    ...designSystemTheme.Message,
    titleTextTransform: 'none',
  },
  Tooltip: {
    ...designSystemTheme.Tooltip,
    position: 'relative',
  },
};

export default appTheme;
