import { WarningMessage } from 'ia-react-core';
import styled from 'styled-components';

export const StyledMessageBarContainer = styled.div`
  & > * {
    margin-bottom: 24px;
  }
  .caution {
    border-color: #E2C300;
    background: #FFFCE6;
  }
`;

export const StyledMessageList = styled.ul`
  margin: 0;
  padding-left: 30px;

  & li:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const StyledMessageListItem = styled.li`
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  padding: 0;
  list-style: none;
`;

export const StyledWarningMessage = styled(WarningMessage)`
  border-color: #E2C300;
  background-color: #FFFCE6;
`;
