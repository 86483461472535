import { SideNavLink, T3, media } from 'ia-react-core';
import styled from 'styled-components';

export const StyledCreateIllustrationSideNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  flex-shrink: 0;

  ${media.xs`
    width: 100%;
  `}

  ${media.sm`
    padding: 0px 8px;
    width: 300px;
    box-sizing: border-box;
  `}
`;

export const StyledCreateIllustrationSideNavLink = styled(SideNavLink)`
  border-bottom: none;
  padding: 0px 0px 0px 32px;
  height: 40px;
  align-items: center;


  // Colors on link hover
  &&&:hover {
    color: ${({ theme }) => theme?.colors?.blue5};
    background-color: ${({ theme }) => theme?.colors?.white};
    border-left: 3px solid ${({ theme }) => theme?.colors?.white};
  }

  // Colors on click of hold click
  &&&&&:active {
    color: ${({ theme }) => theme?.colors?.blue10};
    background-color: ${({ theme }) => theme?.colors?.white};
    border-left: 3px solid ${({ theme }) => theme?.colors?.blue10};
  }

  // Colors on the active page link on click
  &&&&&.active:active {
    color: ${({ theme }) => theme?.colors?.blue10};
    background-color: ${({ theme }) => theme?.colors?.white};
    border-left: 3px solid ${({ theme }) => theme?.colors?.blue10};
  }

  // Colors on hover on the active page link
  &&&&.active:hover {
    border-left: 3px solid ${({ theme }) => theme?.colors?.blue5};
  }

  // Remove background color for focus
  &:focus {
    background-color: ${({ theme }) => theme?.colors?.white};
  }

  // Colors for the active page link in no particular state
  &.active {
    border-left: 3px solid ${({ theme }) => theme?.colors?.blue6};
    color: ${({ theme }) => theme?.colors?.blue6};
  }
`;

export const StyledCreateIllustrationSideNavSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const StyledCreateIllustrationSideNavTitle = styled(T3)`
  margin-bottom: 0px;
  line-height: 26px; /* 123.81% */
`;
