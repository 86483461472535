import { RouteDefinition } from 'ia-react-core';
import { Action } from 'redux';

export const CLEAR_COVERAGES = 'CLEAR_COVERAGES' as const;
export const SET_ROUTES = 'SET_ROUTES' as const;
export const UPDATE_ROUTE_STATUS = 'UPDATE_ROUTE_STATUS' as const;
export const DISABLE_PC_TOOL_FORM = 'DISABLE_PC_TOOL_FORM' as const;
export const SET_APP_VERSIONING = 'SET_APP_VERSIONING' as const;

export interface ClearCoveragesAction extends Action<typeof CLEAR_COVERAGES> {
  payload: {
    showCoverage: boolean | undefined;
  };
}

export interface SetRoutesAction extends Action<typeof SET_ROUTES> {
  payload: {
    routes: RouteDefinition[];
  };
}
export interface UpdateRouteStatusAction extends Action<typeof UPDATE_ROUTE_STATUS> {
  payload: {
    path: string;
    status: string;
  };
}
export interface DisablePcToolFormAction extends Action<typeof DISABLE_PC_TOOL_FORM> {
  payload: {
    isPcToolFormDisabled: boolean;
  };
}

export interface SetAppVersioningAction extends Action<typeof SET_APP_VERSIONING> {
  payload: {
    effectiveDate: string;
    version: string;
  };
}

export const clearCoverages = (showCoverage: boolean | undefined): ClearCoveragesAction => ({
  type: CLEAR_COVERAGES,
  payload: {
    showCoverage,
  },
});

export const setRoutes = (routes: RouteDefinition[]): SetRoutesAction => ({
  type: SET_ROUTES,
  payload: {
    routes,
  },
});

export const updateRouteStatus = (path: string, status: string): UpdateRouteStatusAction => ({
  type: UPDATE_ROUTE_STATUS,
  payload: {
    path,
    status,
  },
});

export const disablePcToolForm = (isPcToolFormDisabled: boolean | undefined): DisablePcToolFormAction => ({
  type: DISABLE_PC_TOOL_FORM,
  payload: {
    isPcToolFormDisabled,
  },
});

export const setAppVersioning = (effectiveDate: string, version: string): SetAppVersioningAction => ({
  type: SET_APP_VERSIONING,
  payload: {
    effectiveDate,
    version,
  },
});
