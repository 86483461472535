import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { GetApplicationConfigurationResourcePropsInterface } from './interfaces/GetApplicationConfigurationResourcePropsInterface';
import { GET_APPLICATION_CONFIGURATION_RESOURCE_NAME } from './constants/constants';

const GetApplicationConfigurationResource: FC<GetApplicationConfigurationResourcePropsInterface> = ({ autoRequest }) => {
  const url = getBffUrlForRoute(BffRoute.application_configuration);

  return (
    <Resource
      name={GET_APPLICATION_CONFIGURATION_RESOURCE_NAME}
      url={url}
      autoRequest={autoRequest}
    />
  );
};

export default GetApplicationConfigurationResource;
