import QuestionIdentityModel from '~/interfaces/InsurabilityDeclaration/QuestionIdentityModel';
import QuestionModel from '~/interfaces/InsurabilityDeclaration/QuestionModel';
import QuestionDto from '../dtos/QuestionDto';
import AnswerBuilder from './AnswerBuilder';
import ExplanatoryTableBuilder from './ExplanatoryTableBuilder';

export default class QuestionBuilder {
  toModel(dto: QuestionDto): QuestionModel {
    const answerBuilder = new AnswerBuilder();
    const explanatoryTableBuilder = new ExplanatoryTableBuilder();
    return new QuestionModel(
      dto.id,
      dto.title,
      dto.toolTip,
      dto.questionType,
      dto.displayType,
      dto.answers.map((a) => answerBuilder.toModel(a)),
      dto.possibleAnswers ? [...dto.possibleAnswers] : [],
      dto.isActive,
      dto.explanatoryTables.map((t) => explanatoryTableBuilder.toModel(t)),
      dto.canTriggerActive,
      dto.canBeTriggeredActive,
      dto.size,
      dto.name,
      dto.importanceLevel,
      dto.status,
      dto.listFormat,
      dto.maxLength,
      dto.forceRefetchData,
      dto.leftSign,
    );
  }

  toIdentityModel(dto: QuestionDto): QuestionIdentityModel {
    return {
      id: dto.id,
      displayType: dto.displayType,
    };
  }
}
