import React from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';

const UPDATE_READ_LATEST_NEWS = 'updateReadLatestNews';

export interface UpdateNewsResourceLoaderProps {
  newsId: string;
}

const UpdateNewsResourceLoader = ({ newsId }: UpdateNewsResourceLoaderProps) => (
  <Resource
    name={UPDATE_READ_LATEST_NEWS}
    method="PUT"
    url={getBffUrlForRouteWithoutQP(BffRoute.update_latest_news, { newsId }, false)}
    autoRequest
  />
);

export default UpdateNewsResourceLoader;
