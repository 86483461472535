import { MainLoader, PageLoader, PageLoaderProps } from 'ia-react-core';
import React, { useMemo } from 'react';

interface CustomPageLoaderProps extends Omit<PageLoaderProps, 'customLoader'> {}

const CustomPageLoader: React.FC<CustomPageLoaderProps> = (props) => {
  const customLoader = useMemo(() => <MainLoader />, []);

  return (
    <PageLoader {...props} customLoader={customLoader} />
  );
};

export default CustomPageLoader;
