import { TFunction } from 'i18next';
import { formatCurrency } from 'ia-react-core';
import { InforceInsuranceType, TypeProtectionHistoriqueEnum } from '~/interfaces/InsuranceHistoryPageState';

const getCoveragesLabel = (data: InforceInsuranceType, t: TFunction, language: string) => {
  const coverages: string[] = [];

  const translationKeyMap = {
    [TypeProtectionHistoriqueEnum.Vie]: 'insuranceTypes.life',
    [TypeProtectionHistoriqueEnum.MaladieGrave]: 'insuranceTypes.critical',
    [TypeProtectionHistoriqueEnum.Invalidite]: 'insuranceTypes.disability',
  };

  data?.protectionAmounts?.forEach((protectionAmount) => {
    const type = t(translationKeyMap[protectionAmount?.protectionType]);
    const amount = formatCurrency(protectionAmount?.amount, { locale: language });

    coverages.push(
      `${type} - ${amount}`,
    );
  });

  return coverages.join('\n');
};

export default getCoveragesLabel;
