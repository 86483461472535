import { T4 } from 'ia-react-core';
import styled from 'styled-components';

export const StyledSectionContainer = styled.div`
  // Layout
  padding: 0px;
  margin-bottom: 16px;

  // Style
  border-radius: 4px;
  border: 1px solid var(--line-grey-979797, #D3D8DE);
`;

export const StyledT4 = styled(T4)`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: Montserrat;
`;

export const StyledInsuredContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

export const StyledInsured = styled.div`
  display: column;
  margin-top: 2px;
`;
