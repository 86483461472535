import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { AxiosError, AxiosResponse } from 'axios';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { useDispatch } from 'react-redux';
import { convertToLocalDate } from '../utilities/Formatter';
import { setDownloadAction } from '../MakeEchangeModal.actions';

export interface ValidateDobResourceProps {
  contractNumber: string;
  birthDate: string;
  onSuccess: (response: AxiosResponse) => void;
  onFailure: (error: AxiosError) => void;
}

const ValidateDobResource: FC<ValidateDobResourceProps> = ({ contractNumber, birthDate, onSuccess, onFailure }: ValidateDobResourceProps) => {
  const dispatch = useDispatch();
  const onDownloadSuccess = (response: AxiosResponse) => {
    dispatch(setDownloadAction({
      ...response.data,
    }));
    onSuccess(response);
  };
  return (
    <Resource
      name="downloadcontract"
      url={getBffUrlForRoute(BffRoute.download_contract, {}, false)}
      data={{ contractNumber, birthDate }}
      onSuccess={onDownloadSuccess}
      method="POST"
      onFailure={onFailure}
      transformRequest={convertToLocalDate}
    />
  );
};

export default ValidateDobResource;
