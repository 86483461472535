import { Resource, WORKFLOW_STATUSES } from 'ia-react-core';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import selectTargetRoute from '~/fragments/ApplicationFragment/selectors/selectTargetRoute';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { loadApplicationWithoutReloading, setPageValidationMessagesAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';
import { ApiResponse } from '~/interfaces/ValidationMessage';
import getStatusFromValidationMessages from '~/fragments/ApplicationFragment/utilities/getStatusFromValidationMessages';
import { updateRouteStatus } from '~/App.actions';
import selectCurrentRoute from '~/fragments/ApplicationFragment/selectors/selectCurrentRoute';
import { State } from '~/interfaces/State';
import { REQUIREMENT_FORM_NAME, UPDATE_REQUIREMENTS_RESOURCE_NAME } from '../constants/ConstantNames';

export const UpdateRequirementsResource: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const targetRoute: string = useSelector(selectTargetRoute);
  const currentRoute = useSelector(selectCurrentRoute);
  const { saleId } = useParams<{ saleId: string }>();
  const updateIndividualUrl = getBffUrlForRoute(BffRoute.save_validate_requirements, { saleId });
  const backToHome = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);
  let initateFromLeftMenu = true;
  return (
    <Resource
      name={UPDATE_REQUIREMENTS_RESOURCE_NAME}
      url={updateIndividualUrl}
      transformRequest={(payloadData: {initateFromLeftMenu: boolean}) => { initateFromLeftMenu = payloadData.initateFromLeftMenu; return payloadData; }}
      method="PUT"
      onSuccess={(res) => {
        const { validationMessages } = (res?.data as ApiResponse);
        dispatch(setPageValidationMessagesAction('RequirementsPage', REQUIREMENT_FORM_NAME, validationMessages, 'RequirementsPage'));

        const status = getStatusFromValidationMessages(validationMessages);
        dispatch(updateRouteStatus(currentRoute, status));
        if (backToHome) {
          history.block(true);
          history.push('/');
          return;
        }
        if (status === WORKFLOW_STATUSES.ERROR) {
          history?.push(currentRoute);
        } else {
          dispatch(loadApplicationWithoutReloading(saleId));
          if (!initateFromLeftMenu) {
            history?.push(targetRoute);
          }
        }
      }}
    />
  );
};
