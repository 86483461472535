import { Action } from 'redux';
import { SwitchViewAction } from '../AdvisorsModal.actions';
import { AdvisorsModalState } from '../AdvisorsSettingsModalState';

export default function switchViewMode(state: AdvisorsModalState, action: Action): AdvisorsModalState {
  const { viewMode } = action as SwitchViewAction;

  return {
    ...state,
    viewMode,
  };
}
