import { ComponentClass } from 'react';
import { compose } from 'redux';

import { injectTranslations } from 'ia-react-core';

import IllustrationSettingsModal from './IllustrationSettingsModal';

import fr from './translations/fr';
import en from './translations/en';

export default compose<ComponentClass>(
  injectTranslations('IllustrationSettingsModal', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(IllustrationSettingsModal);
