import { ErrorMessage, InfoMessage, SafeHtml, WarningMessage } from 'ia-react-core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ValidationMessageModel from '~/interfaces/InsurabilityDeclaration/ValidationMessageModel';
import { useTheme } from 'styled-components';
import { StyledMessageBarContainer, StyledMessageListItem, StyledMessageList } from './MessageBar.styles';
import { useValidationMessages } from '../../../hooks/useValidationMessages';

interface MessageListProps {
  messages: ValidationMessageModel[];
}

const MessageList: FC<MessageListProps> = ({ messages }) => (
  <StyledMessageList>
    {messages.map((message, index) => (
      <StyledMessageListItem key={`${message.id}-${index}`}>
        <SafeHtml>
          {message.message}
        </SafeHtml>
      </StyledMessageListItem>
    ))}
  </StyledMessageList>
);

export interface MessageBarProps {
}

const MessageBar: FC<MessageBarProps> = () => {
  const { t } = useTranslation('formloader');
  const { messageTypes } = useValidationMessages();
  const theme = useTheme();
  return (
    <StyledMessageBarContainer>
      {messageTypes.error.length > 0 && (
        <ErrorMessage messageTitle={t('error')}>
          <MessageList messages={messageTypes.error} />
        </ErrorMessage>
      )}

      {messageTypes.warning.length > 0 && (
        <WarningMessage className="caution" messageTitle={t('warning')} iconColor={theme?.colors?.yellow1}>
          <MessageList messages={messageTypes.warning} />
        </WarningMessage>
      )}

      {messageTypes.notices.length > 0 && (
        <InfoMessage>
          <MessageList messages={messageTypes.notices} />
        </InfoMessage>
      )}
    </StyledMessageBarContainer>
  );
};

export default MessageBar;
