import { FC } from 'react';
import { injectTranslations } from 'ia-react-core';
import { compose } from 'redux';

import MidHeaderLogo, { MidHeaderLogoProps } from './MidHeaderLogo';

import fr from './translations/fr';
import en from './translations/en';

export default compose<FC<MidHeaderLogoProps>>(
  injectTranslations('EvoLogo', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(MidHeaderLogo);

export * from './MidHeaderLogo';
