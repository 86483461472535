import { RouteDefinition } from 'ia-react-core';
import pathENFR from '../interfaces/PathENFR';

const isPreviousRoute = (route: RouteDefinition, prevRoute: string, routePath: string) => {
  if (typeof route.path === 'string') {
    return route.path === prevRoute && route.path.includes(routePath);
  }
  const pathFr = (route?.path as pathENFR)?.fr;
  const pathEn = (route?.path as pathENFR)?.en;
  return (prevRoute.includes(pathFr as string) && pathFr.includes(routePath)) || (prevRoute.includes(pathEn as string) && pathEn.includes(routePath));
};

export default isPreviousRoute;
