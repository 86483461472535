import {
  DropdownControl,
} from 'ia-react-core';
import React, { FC, useMemo } from 'react';
import { Roles } from '../../constants';

export interface RoleControlProps { }

const Rolecontrol: FC<RoleControlProps> = () => {
  const options = useMemo(() => (
    Object.entries(Roles).map(([key, value]) => ({
      label: key,
      value,
    }))
  ), []);

  return (
    <DropdownControl
      name="role"
      noEmptyValue
      defaultValue={Roles.Advisor}
      options={options}
    />
  );
};

export default Rolecontrol;
