import { Col, SearchControl, Tabs, TertiaryButton, media, WarningIcon } from 'ia-react-core';
import styled from 'styled-components';
import { ButtonLink } from '~/components/ButtonLink';
import { ReactComponent as DoNotDisturbIcon } from '~/assets/Icons/doNotDisturb.svg';

export const StyledTooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  width: 20px;
  height: 20px;
  color: #EF6D19;
`;

export const StyledColumn = styled(Col)`
  display:flex;
  align-items: center;
  padding-top: 5px;

  .StyledToggleButtonListItemWrapper-sc-1jhsbyz-1 {
    display:flex;
    align-items: center;
    width: max-content;
  }
`;

export const StyledSaleFileDataTableTabs = styled(Tabs)`
  & > div {
    min-height: 330px;
    box-sizing: border-box;
  }

  & > div[class*="--selected"] {
    display: flex;
    flex-direction: column;
  }

  & > div > div {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledSaleFileDataTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledSaleFileDataTableSearchControl = styled(SearchControl)`
  & + span {
    padding: 0;
  }
`;

export const StyledSaleFileDataTableSearchControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-end;
  align-items: center;

  & > span {
    ${media.xs`
    width: 100%;
  `}

    ${media.sm`
      width: 306px;
    `}
  }
`;

export const StyledSaleFileDataTableSearchButtonContainer = styled(TertiaryButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color:${({ theme }) => theme.colors.blue6};
  width: 40px;
  height: 40px;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;

  & span {
    width: 24px;
    height: 24px;
  }

  right: -10px;
  top: -11px;
`;

export const StyledSaleFileChangeRequestSearchButton = styled(StyledSaleFileDataTableSearchButtonContainer)`
  right: -10px;
  top: -11px;
`;

export const StyledSaleFileDataTableResourceLoaderContainer = styled.div`
  & > div {
    width: 100%;
  }

  & div[role="row"]:has(div[role="columnheader"]) {
    height: 50px;
  }

  & div[role="row"] > div[role="columnheader"] {
    padding: 16px 12px;
  }

  height: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const StyledSaleFileDataTableFiltersContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
`;

export const StyledSaleFileDataTableFiltersChipsControlContainer = styled.div`
  ${media.xs`
    max-width: 220px;
  `}

  ${media.sm`
    max-width: none;
  `}
`;

export const StyledSaleFileDataTableImportSharedFileButton = styled(ButtonLink)`
  height: 30px;

  ${media.xs`
    right: 0px;
    bottom: -2px;
  `}

  ${media.md`
    position: static;
    right: unset;
    bottom: unset;
  `}
`;

export const StyledDoNotDisturbIcon = styled(DoNotDisturbIcon)`
  color: red;
  width: 16px;
  height: 16px;
`;
