import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';

import { ThemeContext } from 'styled-components';
import { StyledCentered, StyledOverlay, StyledWrapper } from './LoaderOverlay.styles';

export interface LoaderOverlayProps{
  children: ReactNode;
  enabled: boolean;
  delayMs?: number;
}

const LoaderOverlay: FC<LoaderOverlayProps> = ({ children, enabled, delayMs: delay }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (enabled && !active) {
      const timeout = setTimeout(() => setActive(true), delay);
      return () => { clearTimeout(timeout); };
    }

    if (!enabled && active) {
      setActive(false);
    }

    return undefined;
  }, [enabled, setActive, delay, active]);

  const {
    assets: { mainLoader },
  } = useContext(ThemeContext);
  const ThemeMainLoader = mainLoader as FC;
  return (
    <StyledWrapper>
      {children}
      {active && (
        <StyledOverlay>
          <StyledCentered>
            <ThemeMainLoader />
          </StyledCentered>
        </StyledOverlay>)}
    </StyledWrapper>
  );
};

LoaderOverlay.defaultProps = {
  delayMs: 500,
};

export default LoaderOverlay;
