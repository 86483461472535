import { Col, Container, InternalLink, NumberControl, P, TertiaryButton } from 'ia-react-core';
import styled from 'styled-components';

export const StyledOtherTermContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-top: 8px;
`;

export const StyledOtherTermSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledOtherTermTitle = styled(P)<{$fontSize?: string; $textAlign?: string}>`
  font-size: ${({ $fontSize }) => $fontSize};
  text-align: ${({ $textAlign }) => $textAlign};
  margin-bottom: 0px;
`;

export const StyledOtherTermNumberControl = styled(NumberControl)`
  width: 53px;
`;

export const StyledContainer = styled(Container)`
  padding: 0;
  margin-bottom: 15px;
`;

export const StyledCol = styled(Col)`
  span {
    line-height: 40px;
  };
`;

export const StyledInternalLink = styled(InternalLink)`
  &.disabled {
    color: #6a6a6a;
  }
`;

export const StyledOtherTermTextButton = styled(TertiaryButton)`
  color: ${({ theme }) => theme?.colors?.blue5};
`;
StyledOtherTermTextButton.defaultProps = {
  text: true,
};

export const StyledOtherTermYearsText = styled.div`
  margin-bottom: 2px;
`;
