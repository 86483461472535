import { Action } from 'redux';
import { FormSubState } from '~/interfaces/FormSubState';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import { SetPageValidationMessagesAction } from '../ApplicationFragment.actions';

export default function setPageValidationMessages<Value>(state: FormSubState<Value>, action: Action): FormSubState<Value> {
  const { validationMessages, page, formName, stateName } = action as SetPageValidationMessagesAction;
  const selectedPage = {
    ...state?.[stateName as keyof typeof state] as Record<string, {
    validationMessages: ValidationMessage[];}>,
  };
  const forms: { [key: string]: unknown } = { ...selectedPage.validations };
  const { [formName]: _, ...restForms } = forms;

  return {
    ...state,
    [page]: {
      ...selectedPage,
      validations: {
        ...restForms,
        [formName]: {
          validationMessages,
        },
      },
    },
  };
}
