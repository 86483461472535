import { TertiaryButton, media } from 'ia-react-core';
import styled from 'styled-components';

export const StyledCoveragesTitle = styled.div`
  color: ${({ theme }) => theme.colors.blue6};
  font-feature-settings: 'clig' off, 'liga' off;
  
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`;

export const StyledVersionInfoSection = styled.span`
  height: 28px;
  line-height: 18px;
  border-radius: 30px;
  border: 1px solid rgb(127, 127, 127);
  font-size: 12px;
  font-weight: 400;
  font-style: open-sans;
  color: #6B6B6B;
  align-content: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #FFFFFF;
`;

export const StyledCoveragesTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCoveragesTitleInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const StyledCoveragesLinks = styled.section`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: right;
  flex-grow: 1;

  ${media.sm`
    align-items: center;
  `}

  ${media.md`
    align-items: center;
  `}

  ${media.lg`
    align-items: center;
    height: 40px;
  `}

  ${media.ll`
    align-items: center;
    height: 28px;
  `}
`;

export const StyledFluidContainerColumn1 = styled.div`
  ${media.lg`
    display: none;
  `}

  ${media.xl`
    width: 220px;
    display: block;
  `}
`;

export const StyledFluidContainerColumn2 = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
`;

export const StyledFluidContainerSummary = styled.div`
  min-width: 300px;
  max-width: 338px;
  width: 100%;

  ${media.md`
    display: none;
  `}

  ${media.lg`
    display: block;
  `}
`;

export const StyledFluidContainerTertiaryButton = styled(TertiaryButton)`
  display: inline-block;
  flex-grow: 0;
  padding: 8px;
`;

export const StyledFluidContainerColumns = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
`;

export const StyledFluidContainerCol1 = styled.div`
  ${media.ll`
    min-width: 220px;
  `}
`;

export const StyledFluidContainerCol2 = styled.div`
  ${media.xs`
    min-width: unset;
    max-width: unset;
    width: 100%;
  `}

  ${media.lg`
    max-width: 848px;
    min-width: 718px;
    width: auto;
  `}

  ${media.ll`
    min-width: 738px;
  `}

  ${media.xl`
    min-width: 738px;
  `}

  ${media.xxl`
    width: 100%;
  `}
`;

export const StyledFluidContainerCol3 = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 300px;
  max-width: 338px;

  ${media.xxl`
    width: 100%;
  `}
`;
