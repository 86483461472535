import { useTranslation } from 'react-i18next';

import { SaleFile } from '../interfaces/SaleFile';

export interface FileSharedCellProps {
  saleFile: SaleFile;
}
export const statutPargeNumberEnum = {
  NotShared: 0,
  Exporte: 2,
  Importe: 1,
};

const FileSharedCell = ({ saleFile }: FileSharedCellProps) => {
  const { t } = useTranslation('SaleFileDataTable');
  if (saleFile.statutPartage === statutPargeNumberEnum.Importe) return t('sharedWithMe');
  if (saleFile.statutPartage === statutPargeNumberEnum.Exporte) return t('shared');
  if (saleFile.statutPartage === statutPargeNumberEnum.NotShared) return '';
  return null;
};

export { FileSharedCell };
