import { State } from '~/interfaces/State';
import AddressModel from '~/fragments/ApplicationFragment/interfaces/AddressModel';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { ApplicantFormData } from '../interfaces';

export default function selectApplicant(state: State): ApplicantFormData<AddressModel> {
  return state?.App?.fragments?.ApplicationFragment?.regulatoryQuestion;
}

export function selectCurrentApplicant(state: State): ApplicantFormData<AddressModel> {
  return state?.App?.fragments?.ApplicationFragment?.ApplicantPage?.values?.applicantPage;
}
export function selectUpdatedNomineeList(state: State): ApplicantFormData<AddressModel> {
  return state?.App?.fragments?.ApplicationFragment?.ApplicantPage?.values?.nomineeList;
}

export function selectApplicantValidationMessages(state: State): ValidationMessage[] {
  return state?.values?.validationMessages || [];
}

export function selectApplicantValidationFields(state: State): ValidationMessage[] {
  return state?.App?.fragments?.ApplicationFragment?.ApplicantPage?.values?.validationMessages || [];
}

export function selectContingentOwnerFields(state: State): ValidationMessage[] {
  return state?.App?.fragments?.ApplicationFragment?.ApplicantPage?.values?.validationMessages || [];
}
export function selectValidationMessages(state: ApplicationFragmentState): ValidationMessage[] {
  return state?.ApplicantPage?.values?.validationMessages;
}

export function selectOrganizationTypeMessages(state: State): ValidationMessage[] {
  const allMessages = state?.App?.fragments?.ApplicationFragment?.ApplicantPage?.values?.validationMessages || [];
  if (allMessages.length) {
    return allMessages.filter((i: ValidationMessage) => ['orgType', 'orgTypeOther'].includes(i.nomPropriete));
  }
  return [];
}

export function selectSignatoriesMessages(state: State): ValidationMessage[] {
  const allMessages = state?.App?.fragments?.ApplicationFragment?.ApplicantPage?.values?.validationMessages || [];
  if (allMessages.length) {
    return allMessages.filter((i: ValidationMessage) => i.nomPropriete === 'signatories');
  }
  return [];
}

export function selectAllApplicantValidationFields(state: State): ValidationMessage[] {
  return state?.App?.fragments?.ApplicationFragment?.ApplicantPage?.values?.validationMessages || [];
}

export function defaultMessagesSelector(state: State): ValidationMessage[] | null {
  return state?.App?.fragments?.ApplicationFragment?.ApplicantPage?.values?.validationMessages;
}
