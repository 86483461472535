import { injectReducer, injectTranslations } from 'ia-react-core';
import { FC } from 'react';
import { compose } from 'redux';
import fr from './translations/fr';
import en from './translations/en';

import SaleFileDataTable, { SaleFileDataTableProps } from './SaleFileDataTable';
import { SaleFileState } from '../interfaces/SaleFileState';
import { saleFileDataTableReducer } from './SaleFileDataTable.reducer';
export const initialState: Partial<SaleFileState> = {};

export default compose<FC<SaleFileDataTableProps>>(
  injectTranslations('SaleFileDataTable', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  injectReducer({
    key: 'SaleFileDataTable',
    reducer: saleFileDataTableReducer,
    useScope: true,
    cleanState: true,
  }),
)(SaleFileDataTable);

export * from './SaleFileDataTable';
