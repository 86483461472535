export default {
  about: 'About',
  appName: 'Life Insurance',
  error: 'Sorry, something went wrong',
  settings: 'Settings',
  help: 'Help',
  home: 'Home',
  work: 'Work',
  saved: 'Save',
  delete: 'Delete',
  reset: 'Reset',
  copy: 'Copy',
  add: 'Add',
  illustration_settings: 'Illustration settings',
  contact_us: 'Contact us',
  privacy: 'Privacy',
  tos: 'Terms of use',
  accessibility: 'Accessibility',
  advisors: 'Advisors',
  illustrations: 'Illustrations',
  signature: 'Signature',
  scenario: 'Scenario',
  coverages: 'Coverages',
  coverage: 'coverage',
  concept: 'concepts',
  result: 'results',
  report: 'rapports',
  concepts: 'Concepts',
  results: 'Results',
  reports: 'Reports',
  marginal_rate: 'Marginal Rate',
  province: 'Choose...',
  yes: 'Yes',
  no: 'No',
  scenarioDrawerTitle: 'Scenarios',
  alerts: 'Alerts',
  autosaveText: 'Autosave',
  loading: 'Loading',
  OIPA_environments: 'OIPA Environments',
  deleteScenarioTitle: 'Delete this scenario',
  deleteScenarioDesc1: 'Click on Yes to delete this scenario. This change cannot be reversed.',
  deleteScenarioDesc2: 'Do you want to continue?',
  resetScenarioTitle: 'Reset this scenario',
  resetScenarioDesc1: 'This action will remove all coverages and reset the scenario. Do you want to continue?',
  noAccessErrorTitle: 'No Access',
  noAccessErrorMsg: 'You do not have access to this page.',
  print: 'Documents available for print',
  notifications: 'Notifications',
  accessibilityUrl: 'https://ia.ca/accessibility',
};
