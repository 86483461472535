import { requestResourceByName } from 'ia-react-core';
import { BeneficiaryFormState, BeneficiaryPageState } from '~/interfaces/BeneficiaryPageState';
import formatSubmitValues from '../pages/NomineePage/pages/BeneficiaryPage/utilities/formatSubmitValues';
import { ApplicationIntentType } from '../constants';
import { UPDATE_BENEFICIARY_RESOURCE_NAME } from '../pages/NomineePage/pages/BeneficiaryPage/constants';

const beneficiaryApiCall = (beneficiaryFormvalues: BeneficiaryFormState, beneficiaries: BeneficiaryPageState, dispatch: Function) => {
  const beneficiarySet = formatSubmitValues(beneficiaryFormvalues, beneficiaries);
  dispatch(requestResourceByName(`resources.${UPDATE_BENEFICIARY_RESOURCE_NAME}`, {
    shouldSave: false,
    beneficiarySet,
    intentType: ApplicationIntentType.SaveAndValidate,
    initateFromLeftMenu: true,
  }));
};

export default beneficiaryApiCall;
