import { T3 } from 'ia-react-core';
import styled from 'styled-components';

export const StyledNoOpenFilesFoundMessageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  & svg {
    width: 60px;
    height: 60px;
    color: ${({ theme }) => theme.colors.blue6};
  }
`;

export const StyledNoOpenFilesFoundMessage = styled(T3)`

`;
StyledNoOpenFilesFoundMessage.defaultProps = {
  marginBottom: 0,
};
