import { Resource, WORKFLOW_STATUSES } from 'ia-react-core';
import React, { FC } from 'react';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';
import { useHistory, useParams } from 'react-router';
import { ApiResponse } from '~/interfaces/ValidationMessage';
import { useDispatch, useSelector } from 'react-redux';
import selectTargetRoute from '~/fragments/ApplicationFragment/selectors/selectTargetRoute';
import { loadApplicationWithoutReloading, updatePacpadValidationMessagesAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';
import getStatusFromValidationMessages from '~/fragments/ApplicationFragment/utilities/getStatusFromValidationMessages';
import { updateRouteStatus } from '~/App.actions';
import selectCurrentRoute from '~/fragments/ApplicationFragment/selectors/selectCurrentRoute';
import { State } from '~/interfaces/State';
import { UPDATE_BANKING_RESOURCE } from '../constants';

export interface UpdateBankingResourceProps {}

const UpdateBankingResource: FC<UpdateBankingResourceProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { saleId } = useParams<{ saleId: string; clientId: string }>();
  const targetRoute: string = useSelector(selectTargetRoute);
  const currentRoute = useSelector(selectCurrentRoute);
  const backToHome = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);
  let initateFromLeftMenu = true;

  return (
    <Resource
      name={UPDATE_BANKING_RESOURCE}
      method="PUT"
      url={getBffUrlForRoute(
        BffRoute.save_validate_banking,
        { saleId },
      )}
      transformRequest={(payloadData: { initateFromLeftMenu: boolean}) => { initateFromLeftMenu = payloadData.initateFromLeftMenu; return payloadData; }}
      onSuccess={(res) => {
        const { validationMessages } = (res?.data as ApiResponse);
        const status = getStatusFromValidationMessages(validationMessages);
        dispatch(updatePacpadValidationMessagesAction(validationMessages));
        dispatch(updateRouteStatus(currentRoute, status));
        if (backToHome) {
          history.block(true);
          history.push('/');
          return;
        }
        if (status !== WORKFLOW_STATUSES.ERROR) dispatch(loadApplicationWithoutReloading(saleId));
        if (status === WORKFLOW_STATUSES.ERROR) {
          history.push(currentRoute);
        } else if (!initateFromLeftMenu) {
          history.push(targetRoute);
        }
      }}
    />
  );
};

export default UpdateBankingResource;
