import { Resource, ResourceProps } from 'ia-react-core';
import React, { FC, useMemo } from 'react';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';
import { P_C_TOOL_DATA_RESOURCE_NAME } from '../constants';

export interface PcToolDataResourceProps extends ResourceProps<null, null> {
  saleId: string;
  scenarioId: string;
}

const PcToolDataResource: FC<PcToolDataResourceProps> = ({ saleId, scenarioId, ...props }) => {
  const url = useMemo(() => getBffUrlForRoute(
    BffRoute.illustration_sales_comparison_tool,
    (saleId && scenarioId) ? { saleId, scenarioId } : {},
    false,
  ), [saleId, scenarioId]);

  return (
    <Resource
      name={P_C_TOOL_DATA_RESOURCE_NAME}
      method="GET"
      url={url}
      {...props}
    />
  );
};

export default PcToolDataResource;
