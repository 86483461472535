import { Action } from 'redux';
import { FormClientErrors } from '~/interfaces/FormClientErrors';
import { ValidatorConfig } from 'ia-react-core';
import { ValidationMessageServerLevel } from '~/interfaces/ValidationMessageServerLevel';
import logger from '~/utilities/logger';
import { FormSubState } from '~/interfaces/FormSubState';
import { SetValidationMessagesLinkedApplicationsAction } from '../ApplicationFragment.actions';

interface validationDetails {
  isFormValidaton: boolean;
  validationType: 'formErrors' | 'formWarnings' | 'formCautions' | 'controlErrors' | 'controlWarnings' | 'controlCautions';
}

export const validationToFormClientProp = (controlName: string, val: ValidationMessageServerLevel): validationDetails => {
  const isFormValidaton = true;
  const validationType = isFormValidaton ? 'form' : 'control';
  switch (val) {
    case ValidationMessageServerLevel.Warning:
      return {
        isFormValidaton,
        validationType: `${validationType}Warnings`,
      };
    case ValidationMessageServerLevel.Notice:
      return {
        isFormValidaton,
        validationType: `${validationType}Cautions`,
      };
    case ValidationMessageServerLevel.Error:
    default:
      return {
        isFormValidaton,
        validationType: `${validationType}Errors`,
      };
  }
};

export default function setValidationMessagesLinkedApplications<Value>(state: FormSubState<Value>, action: Action): FormSubState<Value> {
  const { validatorMap, page, formName } = action as SetValidationMessagesLinkedApplicationsAction<any>;
  const selectedPage = { ...state?.LinkedApplicationsPage };
  const selectedForm = { ...selectedPage.forms?.[formName] };

  if (!selectedForm) {
    logger.error(`Unable to access form name '${selectedForm}'`);
  }

  const forms = { ...selectedPage.forms };
  const { [formName]: _, ...restForms } = forms;

  const clientErrors: FormClientErrors<Value> = Object.entries(validatorMap).reduce((acc, [name, validator]) => {
    const previousValue = { ...acc };
    const norValidator = validator as ValidatorConfig<Value>;
    const [controlName, ruleName] = name.split('.');

    const clientFormSectionName = validationToFormClientProp(controlName, norValidator.type as unknown as ValidationMessageServerLevel);
    const previousSectionValue = previousValue?.[clientFormSectionName.validationType];
    const previousControlSectionValue = previousSectionValue?.[controlName as keyof typeof previousSectionValue];

    if (clientFormSectionName.isFormValidaton) {
      return {
        ...previousValue,
        [clientFormSectionName.validationType]: {
          ...previousSectionValue,
          [`ValidationRule-${ruleName}`]: norValidator.message,
        },
      };
    }

    return {
      ...previousValue,
      [clientFormSectionName.validationType]: {
        ...previousSectionValue,
        [controlName]: { ...previousControlSectionValue, [`ValidationRule-${ruleName}`]: norValidator.message },
      },
    };
  }, {} as FormClientErrors<Value>);

  selectedForm.clientErrors = clientErrors;
  forms[formName] = selectedForm;

  return {
    ...state,
    [page]: {
      ...selectedPage,
      forms: {
        ...restForms,
        [formName]: {
          ...selectedForm,
        },
      },
    },
  };
}
