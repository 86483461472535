import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';

export interface SummaryValidationResourceProps {
  saleId: string;
}

const ValidadateApiName = 'summary_validate_error_message';

const SummaryValidationResource: FC<SummaryValidationResourceProps> = ({ saleId }: SummaryValidationResourceProps) => (
  <Resource
    name={ValidadateApiName}
    url={getBffUrlForRouteWithoutQP(BffRoute.validate_api_error_message, { saleId, formtype: 'summary' }, false)}
    method="GET"
  />
);

export default SummaryValidationResource;
