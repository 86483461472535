import React, { FC, useEffect, useState } from 'react';
import { ErrorMessage, SafeHtml } from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import { News } from '~/interfaces/LatestNews';
import { getRelativeURL } from './helpers/getLatestNewsURL';
import ErrorMessageNews from './errorMessageNews/ErrorMessageNews';
import UpdateNewsResourceLoader from './resources/UpdateNewsResourceLoader';
import { StyledHTMLContainer, StyledLatestNewsContainer } from './LatestNews.styles';

export interface LatestNewsProps {
  latestNews: News;
  fetchNews?: boolean;
}

const LatestNews: FC<LatestNewsProps> = ({ latestNews }) => {
  const { i18n: { language } } = useTranslation();
  const { t } = useTranslation('LatestNews');

  const [latestNewsHTML, setLatestNewsHTML] = useState(null);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (latestNews && Object.keys(latestNews)?.length !== 0) {
      fetch(getRelativeURL(
        latestNews,
        language === 'en' || language === 'en-US' ? 'en' : 'fr',
      ))
        .then((response) => {
          if (!response.ok) {
            setError(true);
          }
          return response.text();
        })
        .then((data) => {
          setLatestNewsHTML(data);
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [language, latestNews]);

  if (error) {
    return (
      <ErrorMessage error={t('errorTryAgain')} />
    );
  }

  return (
    <StyledLatestNewsContainer>
      {
        latestNews && Object.keys(latestNews)?.length === 0 && <ErrorMessageNews />
      }
      {
        latestNews && Object.keys(latestNews)?.length !== 0 &&
        <UpdateNewsResourceLoader
          newsId={latestNews && latestNews?.id}
        />
      }
      {
        latestNews && Object.keys(latestNews)?.length !== 0 &&
        <StyledHTMLContainer>
          <SafeHtml
            addTags={['img']}
            addAttr={['src']}
          >
            {latestNewsHTML}
          </SafeHtml>
        </StyledHTMLContainer>
      }
    </StyledLatestNewsContainer>
  );
};

export default LatestNews;
