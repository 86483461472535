/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from 'axios';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { SaleFile } from '../components/SaleFile/interfaces/SaleFile';

export const isNoFiles = () =>
  axios.get(getBffUrlForRoute(BffRoute.user_sales_paged, undefined, false))
    .then((axiosResponse) => {
      const data = axiosResponse?.data;
      let isNoData = true;
      if (data && data?.items?.length > 0) isNoData = false;
      return isNoData;
    })
    .catch(() => Promise.reject(new Error('Invalid Data')));

export const deleteFile = (saleFile: SaleFile) =>
  axios.delete(getBffUrlForRoute(BffRoute.user_sales_saleId, { saleId: saleFile.id.valeur }, false))
    .then((axiosResponse) => axiosResponse)
    .catch(() => Promise.reject(new Error('Invalid Data')));

export const renameFile = (saleFile: SaleFile) => axios.patch(getBffUrlForRoute(BffRoute.user_sales_saleId_name, { saleId: saleFile.id.valeur }, false), { name: saleFile.nomVente })
  .then((axiosResponse) => axiosResponse)
  .catch(() => Promise.reject(new Error('Invalid Data')));
