import { InforceInsuranceFormData, InforceInsuranceType, TypeProtectionHistoriqueEnum } from '~/interfaces/InsuranceHistoryPageState';

// This prevents validation from triggering automatically on initialy loading the form
// Using null as the empty value triggers validation automatically for some reason
const EMPTY_RADIO_VALUE = '' as unknown as boolean;

const formatInforceInsuranceFormValues = (inforceInsurance: InforceInsuranceType): InforceInsuranceFormData => {
  const protectionAmounts = inforceInsurance?.protectionAmounts;

  const life = protectionAmounts?.find((x) => x.protectionType === TypeProtectionHistoriqueEnum.Vie);
  const criticalIllness = protectionAmounts?.find((x) => x.protectionType === TypeProtectionHistoriqueEnum.MaladieGrave);
  const disability = protectionAmounts?.find((x) => x.protectionType === TypeProtectionHistoriqueEnum.Invalidite);

  return ({
    id: inforceInsurance?.id ?? null,
    isContractIA: inforceInsurance?.isContractIA ?? EMPTY_RADIO_VALUE,
    isNeedPersonal: inforceInsurance?.isNeedPersonal ?? EMPTY_RADIO_VALUE,
    isIssuedLast12Months: inforceInsurance?.isIssuedLast12Months ?? EMPTY_RADIO_VALUE,
    noContract: inforceInsurance?.noContract ?? null,
    yearEmission: inforceInsurance?.yearEmission ? inforceInsurance?.yearEmission : null,
    willReplaceInsurance: inforceInsurance?.willReplaceInsurance,

    hasLifeInsuranceCoverage: Boolean(life),
    lifeInsuranceCoverageAmount: life?.amount ? String(life?.amount) : null,

    hasCriticalIllnessCoverage: Boolean(criticalIllness),
    criticalIllnessCoverageAmount: criticalIllness?.amount ? String(criticalIllness?.amount) : null,

    hasDisabilityInsuranceCoverage: Boolean(disability),
    disabilityInsuranceCoverageAmount: disability?.amount ? String(disability?.amount) : null,
  });
};

export default formatInforceInsuranceFormValues;
