import { FC } from 'react';
import { compose } from 'redux';
import { injectTranslations } from 'ia-react-core';

import FluidContainer, { FluidContainerProps } from './FluidContainer';

import fr from './translations/fr';
import en from './translations/en';

export default compose<FC<FluidContainerProps>>(
  injectTranslations('FluidContainer', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(FluidContainer);
