import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { UpdateAccountHolders } from '../ApplicationFragment.actions';

export const updateAccountHoldersReducer = (state: ApplicationFragmentState, action: Action): ApplicationFragmentState => {
  const { payload } = UpdateAccountHolders.getData(action);
  return {
    ...state,
    values: {
      ...state.values,
      pacPadAgreement: {
        ...state.values.pacPadAgreement,
        accountHolders: payload.accountHolders,
      },
    },
  };
};
