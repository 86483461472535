import styled from 'styled-components';

export const StyledTextHeading = styled.div({
  color: '#6B6B6B',
  fontWeight: '700',

});

export const StyledText = styled.div({
  color: '#6B6B6B',
  fontWeight: '600',
  fontFamily: 'Open Sans',
  fontSize: '14px',
});
