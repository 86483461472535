import React, { FC } from 'react';
import FormModel from '~/interfaces/InsurabilityDeclaration/FormModel';
import { useTranslation } from 'react-i18next';
import { P } from 'ia-react-core';
import Section from './Section';
import { StyledInsured, StyledInsuredContainer, StyledT4 } from './Form.styles';
import MessageBar from '../MessageBar';
import { StyledQuestionLabel } from './Section/Question/Question.styles';

export interface FormProps {
  form: FormModel;
}

const Form: FC<FormProps> = ({ form }) => {
  const { t } = useTranslation('formloader');

  return (
    <>
      {Boolean(form.title) && <StyledT4>{form.title}</StyledT4>}
      <StyledInsuredContainer>
        <StyledQuestionLabel style={{ marginTop: '0' }}>{t('insured')}</StyledQuestionLabel>
        <StyledInsured>
          {form.insureds.map((insured) => (<P key={`insured_${insured}`}> {insured} </P>))}
        </StyledInsured>
      </StyledInsuredContainer>
      <MessageBar />
      {form.sections.map((s) => (
        <Section key={s.id} formId={form.id} section={s} />
      ))}
    </>
  );
};
export default Form;
