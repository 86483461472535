import { configGrid, withTheming } from 'ia-react-core';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { compose } from 'redux';
import { useZoom } from '~/hooks/useZoom';
import appTheme from '~/themes/appTheme';

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { zoom } = useZoom();

  const WithTheme = useMemo(
    () =>
      compose<FC<PropsWithChildren>>(
        withTheming(appTheme, { compensationRatio: 1 / zoom }),
        configGrid,
      )((props: PropsWithChildren) => <div>{props.children}</div>),
    [zoom],
  );

  return (
    <WithTheme>
      {children}
    </WithTheme>
  );
};

export default ThemeProvider;
