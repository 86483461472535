import axios from 'axios';
import { User } from 'oidc-client-ts';

const requestInterceptor = (user: User) => {
  axios.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${user.access_token}`;
    return config;
  });
};

export default requestInterceptor;
