import React, { FC, useMemo } from 'react';
import { Resource, ResourceProps } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import SectionDto from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/dtos/SectionDto';

export interface CreateRepeatingSectionProps extends ResourceProps<null, null> {
  saleId: string;
  repeatingSectionId: string | null;
}
const CreateRepeatingSection: FC<CreateRepeatingSectionProps> = ({ saleId, repeatingSectionId }) => {
  const createRepeatingSecUrl = useMemo(() => getBffUrlForRouteWithoutQP(
    BffRoute.create_repeating_section,
    { saleId, repeatingSectionId },
    false,
  ), [saleId, repeatingSectionId]);
  const body = {};
  return (
    <Resource
      name="resources.create_repeating_section"
      url={createRepeatingSecUrl}
      data={body}
      method="POST"
      onSuccess={(result) => {
        if (result.status !== 200) {
          throw new Error(`Error encountered when creating a repeated section: ${result.status} - ${result.statusText}`);
        }

        const sectionDto = result.data as SectionDto;
        return sectionDto;
      }}
    />
  );
};

export default CreateRepeatingSection;
