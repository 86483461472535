import { RequestForType } from '~/interfaces/InsurabilityDeclaration/enums';
import { GUID_NULL } from '~/interfaces/GUID';
import { FormatToRequestForDTO, RequestForDTO } from './interfaces';

export function formatToRequestForDTO({
  previousData,
  formValues,
  save,
  intent,
}: FormatToRequestForDTO): RequestForDTO {
  const initRequest: RequestForDTO = {
    shouldSave: save,
    intentType: intent,
    requestFor: {
      requestId: previousData?.requestId ?? GUID_NULL,
      requestFor: previousData?.requestFor,
      infoEmployee: {
        id: previousData?.infoEmployee?.id ?? GUID_NULL,
        employeeName: previousData?.infoEmployee?.employeeName,
        employeeNumber: previousData?.infoEmployee?.employeeNumber === '' ? null : previousData?.infoEmployee?.employeeNumber,
        employeeTypeGroup: previousData?.infoEmployee?.employeeTypeGroup?.toString() === '' ? null : previousData?.infoEmployee?.employeeTypeGroup,
      },
    },
  };

  if (formValues?.requestFor === RequestForType.Employee) {
    const updatedRequest: RequestForDTO = {
      ...initRequest,
      requestFor: {
        ...initRequest.requestFor,
        requestFor: formValues?.requestFor,
        infoEmployee: {
          id: initRequest?.requestFor.infoEmployee?.id,
          employeeName: formValues?.infoEmployee?.employeeName,
          employeeNumber: formValues?.infoEmployee?.employeeNumber === '' ? null : formValues?.infoEmployee?.employeeNumber,
          employeeTypeGroup: formValues?.infoEmployee?.employeeTypeGroup?.toString() === '' ? null : formValues?.infoEmployee?.employeeTypeGroup,
        },
      },
    };
    return updatedRequest;
  }

  const updatedRequest: RequestForDTO = {
    ...initRequest,
    requestFor: {
      ...initRequest.requestFor,
      requestFor: formValues?.requestFor,
    },
  };

  return formValues ? updatedRequest : initRequest;
}
