import { WORKFLOW_STATUSES, WorkflowStatus } from 'ia-react-core';
import { IntentType } from '~/interfaces/SignaturePageState';
import PAGE_NAMES from '../../constants/pageNames';

const getWorkflowStatus = (intentType: IntentType, hasError: boolean, hasWarning: boolean, currentPage: string, isValidationForMode: boolean): WorkflowStatus => {
  if (hasError) return WORKFLOW_STATUSES.ERROR;
  if (hasWarning) return WORKFLOW_STATUSES.WARNING;

  const isSignatureModePage = currentPage === PAGE_NAMES.SIGNATURE_MODE;
  const isSignatureFollowupPage = currentPage === PAGE_NAMES.SIGNATURE_FOLLOWUP;

  if ((isSignatureModePage && !isValidationForMode) || (!isSignatureModePage && !isSignatureFollowupPage)) {
    return WORKFLOW_STATUSES.INACTIVE;
  }

  if (intentType === IntentType.None) {
    return WORKFLOW_STATUSES.IN_PROGRESS;
  }

  return WORKFLOW_STATUSES.COMPLETE;
};
export default getWorkflowStatus;
