import React from 'react';
import useQuery from '~/hooks/useQuery';
import useScenario from '~/fragments/IllustrationFragment/hooks/useScenario';
import { StyledIllustrationSideNavigation } from './IllustrationSideNavigation.styles';

export interface IllustrationNavigationLinkProps {
  to: string;
  title: string;
  id: string;
  active?: boolean;
}

export interface IllustrationSideNavigationProps {
  navigationLinks: IllustrationNavigationLinkProps[];
  isDrawer?: boolean;
}

const IllustrationSideNavigation = ({ navigationLinks, isDrawer }: IllustrationSideNavigationProps) => {
  const query = useQuery();
  const scenario = useScenario();
  const scenarioId = query.get('scenarioId') || scenario?.idDemande;

  return (
    <StyledIllustrationSideNavigation
      $isDrawer={isDrawer}
      items={navigationLinks.map((link: IllustrationNavigationLinkProps) => ({
        key: link.title,
        to: `${link.to}?scenarioId=${scenarioId}`,
        children: link.title,
      }))}
    />
  );
};

export default IllustrationSideNavigation;
