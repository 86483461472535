import React, { FC } from 'react';
import { openDrawerByName, requestResourceByName, Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { ImportantMessage } from '~/interfaces/ImportantMessage';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import UpdateNotificationResourceLoader from './UpdateNotificationResource';

export const GET_IMPORTANT_MESSAGES_RESOURCE_NAME = 'getImportantMessages';

export const GetNotificationResource: FC = () => {
  const getNotificationUrl = getBffUrlForRoute(BffRoute.important_messages, {}, false);
  const dispatch = useDispatch();

  const handleSuccess = (res: AxiosResponse) => {
    if (res.status === 200) {
      const notifications: ImportantMessage[] = res.data;
      const unreadIds = notifications?.filter((notification: ImportantMessage) => notification?.isRead === false)?.map((unread: ImportantMessage) => unread.id);
      if (unreadIds.length > 0) {
        dispatch(openDrawerByName('drawers.notifications'));
        dispatch(requestResourceByName('resources.updateReadNotification', unreadIds));
      }
    }
  };

  return (
    <>
      <UpdateNotificationResourceLoader messages={[]} />
      <Resource
        autoRequest
        name={GET_IMPORTANT_MESSAGES_RESOURCE_NAME}
        url={getNotificationUrl}
        method="GET"
        onSuccess={handleSuccess}
      />
    </>

  );
};
