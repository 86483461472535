export const FEATURE_TOGGLE_RESOURCE_NAME = 'featureToggles';

export const CATALOG_RESOURCE = 'catalog';

export const GET_VERSION_NUMBER_OPTIONS = 'versionNumberOptions';

export const MENU_REGEX = /\/(validation|summary|resume|changes|changements|ho-changement|ho-echange|sommaire|echange\/advisor|echange\/conseiller|validationechange)$/;
export const HOME_REGEX = /\/(folders|nouveautes|dossiers|features)$/;

export const allowedKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'];

export const SIGNATURE_MODE = {
  ELECTRONIC: 1,
  PAPER: 2,
};
