import { WORKFLOW_STATUSES, WorkflowRouteDefinition, WorkflowStatus } from 'ia-react-core';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import getTextBasedOnLocale from '~/utilities/getTextBasedOnLocale';
import isCurrentRoute from './isCurrentRoute';
import isPreviousRoute from './isPreviousRoute';
import getRouteStatus from './getRouteStatus';
import getClientIdFromRoute from './getClientIdFromRoute';
import ENGLISH_ROUTES from '../constants/englishRoutes';
import FRENCH_ROUTES from '../constants/frenchRoutes';

type LastStatuses = Record<string, WorkflowStatus>;

const updateNomineeRouteStatus = (
  nomineeRoute: WorkflowRouteDefinition,
  currentRoute: string,
  previousRoute: string,
  routePath: string,
  lastStatuses: LastStatuses,
  apiResponse: ValidationMessage[],
  setNomineeId: Function,
  setLastStatuses: Function,
  locale: string,
): WorkflowStatus => {
  let { status } = nomineeRoute;
  const clientId = getClientIdFromRoute(currentRoute);
  setNomineeId(clientId);
  const pathKey = JSON.stringify(nomineeRoute.path);
  if (isCurrentRoute(nomineeRoute, currentRoute) || nomineeRoute.path === currentRoute) {
    if (lastStatuses[pathKey]) {
      status = lastStatuses[pathKey];
    } else if (status !== WORKFLOW_STATUSES.ERROR && status !== WORKFLOW_STATUSES.COMPLETE && status !== WORKFLOW_STATUSES.CAUTION && status !== WORKFLOW_STATUSES.WARNING) {
      status = WORKFLOW_STATUSES.IN_PROGRESS;
    }
  } else if (isPreviousRoute(nomineeRoute, previousRoute, routePath)) {
    const declarationsRoutePath = getTextBasedOnLocale(locale, ENGLISH_ROUTES.DECLARATIONS, FRENCH_ROUTES.DECLARATIONS);
    const validationMessages = previousRoute.includes(declarationsRoutePath) ? apiResponse : apiResponse?.filter((message) => message.noParticipant === getClientIdFromRoute(previousRoute));
    const prevRouteStatus = ((nomineeRoute?.path && (nomineeRoute?.path as { en: string }).en === previousRoute) || (nomineeRoute?.path && (nomineeRoute?.path as { fr: string }).fr === previousRoute)) ? nomineeRoute.status : WORKFLOW_STATUSES.IN_PROGRESS;
    status = getRouteStatus(validationMessages, prevRouteStatus) as WorkflowStatus;
    setLastStatuses((prevStatuses: LastStatuses) => {
      if (prevStatuses[pathKey] === status) {
        return { ...prevStatuses, [pathKey]: status };
      }
      return prevStatuses;
    });
  }
  return status;
};

export default updateNomineeRouteStatus;
