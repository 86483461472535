import { ComponentClass } from 'react';
import { compose } from 'redux';

import { injectTranslations } from 'ia-react-core';

import fr from './translations/fr';
import en from './translations/en';
import PremiumComparisonToolModal from './PremiumComparisonToolModal';

export default compose<ComponentClass>(
  injectTranslations('PremiumComparisonToolModal', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(PremiumComparisonToolModal);
