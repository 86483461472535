import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';
import IndividualModel from '~/fragments/ApplicationFragment/interfaces/IndividualModel';
import { InsuranceHistoryFormData } from '~/interfaces/InsuranceHistoryPageState';
import { formatSubmitValues } from '.';

const createPayload = (formValues: InsuranceHistoryFormData, shouldSaveData: ApplicationIntentType, currentNominee: IndividualModel) => ({
  intentType: shouldSaveData,
  insuranceHistoryViewModel: formatSubmitValues(formValues, currentNominee),
});

export default createPayload;
