export enum StatusCode {
  inProgress = 0,
  completed = 2,
}

export enum StatusFilter {
  inProgress = 0,
  completed = 2,
  lastAccessed = 99,
}

export enum ColumnCode {
  case = '1',
  shared = '2',
  created = '3',
  status = '4',
  version = '5',
}

export enum Stage {
  proposition = 0,
  signature = 2,
  illustration = 4,
  individu = 5,
  admissibilite = 6,
  validation = 7,
  changements = 8,
  remiseEnVigueur = 9,
  transferee = 10,
  ceremonieSignatureDebutee = 11,
  signee = 12,
  client = 13,
  passageALaProposition = 14,
  advisor = 15,
  draft = 99
}

export const stagesBeforeApplication = [
  Stage.admissibilite, Stage.client, Stage.illustration, Stage.individu, Stage.draft,
];

export enum StageLabels {
  proposition ='stageLabels.proposition',
  signature ='stageLabels.signature',
  illustration ='stageLabels.illustration',
  individu ='stageLabels.individu',
  admissibilite ='stageLabels.admissibilite',
  validation ='stageLabels.validation',
  changements ='stageLabels.changements',
  remiseEnVigueur ='stageLabels.remiseEnVigueur',
  transferee = 'stageLabels.transferee',
  ceremonieSignatureDebutee = 'stageLabels.ceremonieSignatureDebutee',
  signee = 'stageLabels.signee',
  client = 'stageLabels.client',
  passageALaProposition = 'stageLabels.passageALaProposition',
  draft = 'stageLabels.draft'
}

export const filterOptions = (t: Function) => [
  { label: t('inProgress'), value: StatusFilter.inProgress },
  { label: t('completed'), value: StatusFilter.completed },
  { label: t('lastModified'), value: StatusFilter.lastAccessed },
];

export const filterOptionsEchange = (t: Function) => [
  { label: t('inProgress'), value: StatusFilter.inProgress },
  { label: t('completed'), value: StatusFilter.completed },
  { label: t('lastModified'), value: StatusFilter.lastAccessed },
];
export const SALE_MIGRATE = 'saleMigrate';
export const VALIDATE_EFFECTIVE_DATE = 'validate_effective_date';
export const VALIDATE_API_PAYLOAD = 'NouvelleVente';
export const SUCCESS_NO_CONTENT = 204;
export const SUCCESS_STATUS_CODE = 200;
