import React from 'react';
import {
  Divider, Modal, PrimaryButton, SecondaryButton, Spacing,
  T3,
} from 'ia-react-core';
import { useTranslation } from 'react-i18next';

import { PREMIUM_COMPARISON_TOOL_MODAL_NAME } from './constants';
import usePremiumComparisonToolManager from './hooks/usePremiumComparisonToolManager';
import PcToolDataResource from './resources/PcToolDataResource';
import UpdateSelectedPlanResource from './resources/UpdateSelectedPlanResource';
import PcToolForm from './components/PcToolForm/PcToolForm';
import { StyledPCToolModalActionBar } from './PremiumComparisonToolModal.styles';

const PremiumComparisonToolModal = () => {
  const { t } = useTranslation('PremiumComparisonToolModal');
  const {
    isProductSelected, saleId, scenarioId, selectedPlanCode, isApplyButtonDisabled,
    onApply, onCancel, onPcToolDataResourceSuccess, onUpdateSelectedPlanResourceSuccess, onModalClose, enableForm,
    onPcToolDataResourceFailure,
  } = usePremiumComparisonToolManager();

  return (
    <>
      <PcToolDataResource
        saleId={saleId}
        scenarioId={scenarioId}
        onSuccess={(response) => onPcToolDataResourceSuccess(response.data, response.config.method, selectedPlanCode)}
        onComplete={enableForm}
        onFailure={onPcToolDataResourceFailure}
      />
      <UpdateSelectedPlanResource saleId={saleId} scenarioId={scenarioId} onSuccess={onUpdateSelectedPlanResourceSuccess} />
      <Modal
        name={PREMIUM_COMPARISON_TOOL_MODAL_NAME}
        maxWidth={1400}
        onClose={onModalClose}
        shouldCloseOnOverlayClick={false}
      >
        <T3 marginBottom={0}>{t('pct')}</T3>
        <Spacing m-t={16} />
        <Modal.Content>
          <PcToolForm />
        </Modal.Content>
        <Spacing m-t={24} />
        <Spacing m-t={24} />
        <Divider />
        <Spacing m-b={24} />
        <StyledPCToolModalActionBar flexButtonsXs>
          {isProductSelected &&
            <PrimaryButton
              disabled={isApplyButtonDisabled}
              onClick={onApply}
            >
              {t('apply')}
            </PrimaryButton>}
          <SecondaryButton
            m-l={8}
            onClick={onCancel}
          >
            {isProductSelected ? t('cancel') : t('close')}
          </SecondaryButton>
        </StyledPCToolModalActionBar>
      </Modal>
    </>
  );
};

export default PremiumComparisonToolModal;
