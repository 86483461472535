import {
  RouteDefinition,
  RouteDefinitions,
  RouteSectionDefinition,
  useRouteLabel,
} from 'ia-react-core';
import React, { FC, ReactNode, useContext, useEffect } from 'react';
import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import APPLICATION_ROUTES from '~/constants/APPLICATION_ROUTES';
import SIGNATURE_ROUTES from '~/constants/SIGNATURE_ROUTES';
import { WorkflowLayoutTitleContext } from '../WorkflowLayoutWrapper/WorkflowLayoutWrapper';

export type CustomTitleLogic = (setTitleLabel: React.Dispatch<React.SetStateAction<React.ReactNode>>, label: ReactNode) => void;

export interface WorkflowLayoutTitleLabelResolverProps {
  customTitleLogic?: CustomTitleLogic;
}

// We must pass the page title up to the workflow page level since it lies outside the router
const WorkflowLayoutTitleLabelResolver: FC<WorkflowLayoutTitleLabelResolverProps> = ({ customTitleLogic }) => {
  const { setTitleLabel } = useContext(WorkflowLayoutTitleContext);
  const locale = useCurrentLocale();
  const routeLabel = useRouteLabel();

  useEffect(() => {
    // Translate title-label to respect locale (when Application-lang is different than Correspondence-lang)
    const route = APPLICATION_ROUTES.concat(SIGNATURE_ROUTES).find((r: RouteDefinition) => Object.values(r.label).includes(routeLabel));
    const routeLabelDef = route?.label as {
      fr: ReactNode;
      en: ReactNode;
    };
    const newTitleLabel = routeLabelDef ? routeLabelDef[locale as 'fr' | 'en'] : routeLabel;

    if (customTitleLogic) {
      customTitleLogic?.(setTitleLabel, newTitleLabel);
    } else {
      setTitleLabel(newTitleLabel);
    }
  }, [routeLabel, setTitleLabel, locale]);

  return null;
};

// Override loader of all routes with WorkflowLayoutTitleLabelResolver
export const generateWorkflowLayoutRouteOverrides = (routes: RouteDefinitions, customTitleLogic?: CustomTitleLogic) => routes.map((route: RouteSectionDefinition): RouteSectionDefinition => {
  const defaultValue = () => <WorkflowLayoutTitleLabelResolver customTitleLogic={customTitleLogic} />;
  const loader = () => Promise.resolve(({ default: defaultValue }));
  const routesOverride = route.routes ? generateWorkflowLayoutRouteOverrides(route.routes, customTitleLogic) : [];
  return {
    ...route,
    loader,
    routes: routesOverride,
  };
});

export default WorkflowLayoutTitleLabelResolver;
