import _ from 'lodash';

import AddressModel from '~/fragments/ApplicationFragment/interfaces/AddressModel';
import { Address } from 'ia-react-core';
import { toAddressDto } from '~/fragments/ApplicationFragment/utilities/address/toAddressDto';
import { ApplicantFormData, IThirdPartyInd, IThirdPartyOrg } from '../interfaces';

export const getDataForSubmission = (formData: ApplicantFormData<AddressModel | Address>) => {
  const clonedFormData = _.cloneDeep(formData);
  const thirdPartyPersons: IThirdPartyInd[] = [];
  const thirdPartyOrgs: IThirdPartyOrg[] = [];
  // Split thirdParties into thirdPartyPersons and thirdPartyOrgs (Only for BFF)
  if (clonedFormData?.regulation?.crime?.thirdParties?.length) {
    clonedFormData?.regulation?.crime?.thirdParties?.forEach?.((thirdParty) => {
      if (thirdParty.referenceNomineeId === '') {
        thirdParty.referenceNomineeId = null;
      }
      thirdParty.address = thirdParty?.address?.street ? toAddressDto(thirdParty.address as Address, thirdParty.referenceNomineeId || thirdParty.id) : null;
      if (thirdParty.indexRef === 0) { // Individual
        thirdPartyPersons.push(thirdParty as IThirdPartyInd<Address>);
      } else {
        thirdPartyOrgs.push(thirdParty as IThirdPartyOrg<Address>);
      }
    });
  }

  clonedFormData?.identity?.signatories?.forEach?.((signatory) => {
    if (signatory.referenceNomineeId === '') {
      signatory.referenceNomineeId = null;
    }
    signatory.address = signatory?.address?.street ? toAddressDto(signatory.address as Address, signatory.referenceNomineeId || signatory.id) : null;
  });

  // needed only for BFF submission
  _.set(clonedFormData, 'regulation.crime.thirdPartyPersons', thirdPartyPersons);
  _.set(clonedFormData, 'regulation.crime.thirdPartyOrgs', thirdPartyOrgs);

  return clonedFormData;
};
