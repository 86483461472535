import {
  Modal, PrimaryButton, Resource, SelectableTableColumn, T3, TableColumn,
  clearResourceDataByName,
  closeModalByName,
  requestResourceByName,
} from 'ia-react-core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '~/interfaces/AppState';

import SharedResources from '~/fragments/SharedLibrary/Resources/SharedResources';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { SaveFileState } from '~/fragments/SharedLibrary/interfaces/State';
import { AxiosResponse } from 'axios';
import { StyledActionBar, StyledDataTable, StyledInfoMessage, StyledP } from './SaveFile.styles';
import { getFileIcon } from './utilities/getFileIcon';

export interface SaveFileProps {
  selectedUser?: {
    nomVente?: string;
     venteId?: string;
  };
}

export interface MockData {
  fileName: string;
}

const SaveFile = ({ selectedUser }: SaveFileProps) => {
  const { t } = useTranslation('SaleFileDataTable');
  const dispatch = useDispatch();
  const selectedFiles = useSelector((state: AppState) => state?.App?.fragments?.HomeFragment?.pages?.FoldersPage?.SaleFileDataTable?.tables?.fileslist?.selectedRows);
  const disableButtons = selectedFiles && Object.keys(selectedFiles)?.length;
  const filesListFromApi = useSelector((state: AppState) => state?.App?.fragments?.HomeFragment?.pages?.FoldersPage?.SaleFileDataTable?.data?.getFilesList);
  const filterDataByIndex = (data: SaveFileState[], indices: number[] | string[]) => indices.map((index: any) => data[index]).filter((item) => item !== undefined);

  const handleDownload = useCallback(() => {
    const filesList = Object.keys(selectedFiles);
    const selectFileList = filterDataByIndex(filesListFromApi, filesList);
    const ApiData = selectFileList.map((item) => ({
      id: item.id,
      name: item.name,
      content: item.content ?? '',
      category: '',
      extension: item.contentType?.split('/')[1],
      type: item.contentType?.split('/')[0],
      contentType: item.contentType,
      metadata: {
      },
      documents: '',
      referenceId: item.referenceId,
      referenceName: item?.referenceName ?? item.name,
      retentionDays: 0,
    }));

    if (selectFileList?.length > 1) {
      dispatch(requestResourceByName('resources.downloadFiles', ApiData, { method: 'POST', url: getBffUrlForRouteWithoutQP(BffRoute.get_File_List, { saleId: selectedUser?.venteId }, false) }));
    }
    if (selectFileList?.length === 1) {
      dispatch(requestResourceByName('resources.downloadFiles', undefined, {
        method: 'GET',
        url: getBffUrlForRouteWithoutQP(BffRoute.download_single_file, {
          saleId: selectedUser?.venteId,
          documentId: selectFileList[0].id,
        }, false),
      }));
    }
  }, [selectedFiles]);

  const onDownload = (res: AxiosResponse) => {
    const filesList = Object.keys(selectedFiles);
    const selectFileList = filterDataByIndex(filesListFromApi, filesList);
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', selectFileList?.length > 1 ? `${selectedUser?.nomVente}.zip` : selectFileList[0]?.name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Modal name="saveEchangeFile" onClose={() => clearResourceDataByName('resources.getFilesList')}>
      <T3>{t('savemodaltitle')}</T3>
      <StyledInfoMessage messageTitle={t('savemodalinfo')} />
      <StyledDataTable name="fileslist" data={filesListFromApi} cleanState>
        <SelectableTableColumn />
        <TableColumn width="40px">{(item: SaveFileState) => getFileIcon(item.contentType)}</TableColumn>
        <TableColumn header={selectedUser?.nomVente}>{(item: SaveFileState) => item.name}</TableColumn>
      </StyledDataTable>
      <StyledActionBar>
        <PrimaryButton disabled={!(disableButtons)} onClick={handleDownload}>{t('savebutton')}</PrimaryButton>
        <PrimaryButton onClick={() => dispatch(closeModalByName('modals.saveEchangeFile'))}>
          {t('closebutton')}
        </PrimaryButton>
      </StyledActionBar>
      <StyledP>{t('message')}</StyledP>
      <SharedResources name="getFilesList" isAutoRequest method="GET" url={getBffUrlForRouteWithoutQP(BffRoute.get_File_List, { saleId: selectedUser?.venteId }, false)} />
      <Resource
        name="downloadFiles"
        method="POST"
        onSuccess={onDownload}
        config={{ responseType: 'blob' }}
      />
    </Modal>
  );
};

export default SaveFile;
