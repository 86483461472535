import {
  HelpIcon,
  Hidden,
  Menu,
  MenuIcon,
  MenuItem,
  SettingsIcon,
  openModalByName,
  useScopedSelector,
} from 'ia-react-core';
import React, { FC, ReactElement, useMemo } from 'react';
import { ReactComponent as CloudIcon } from '~/assets/Icons/cloudDone.svg';
import { ReactComponent as HomeIcon } from '~/assets/Icons/homeWhite.svg';
import { ReactComponent as MegaPhoneIcon } from '~/assets/Icons/megaPhone.svg';
import { ReactComponent as SaveDiskIcon } from '~/assets/Icons/saveDisk.svg';
import { ReactComponent as PrintIcon } from '~/assets/Icons/print.svg';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetApplicationConfigurationResource } from '~/components/GetApplicationConfigurationResource';
import AppLayoutHeaderRightContentStyles from './AppLayoutHeaderRightContent.styles';
import { AppLayoutHeaderInterface } from './interfaces/AppLayoutHeaderStateInterface';

const { StyledSaleFileName, StyledSaleFileNameContainer, StyledBadge, StyledIconButton } = AppLayoutHeaderRightContentStyles;

export interface AppLayoutHeaderRightContentProps {
  isApp: boolean;
  isDraft: boolean;
  isNewMessage: boolean;
  isChangePage: boolean;
  saleFileName: string;
  hidePrintIcon: boolean;
  onSaveClick: () => void;
  onHomeClick: () => void;
  onNotificationsClick: () => void;
  onAdvisorsClick: () => void;
  onIllustrationClick: () => void;
  onMobileMenuClick: () => void;
  onOipaOptionClick: () => void;
  onPrintClick: () => void;
}

const AppLayoutHeaderRightContent: FC<AppLayoutHeaderRightContentProps> = ({
  isApp, isDraft, isNewMessage, isChangePage, saleFileName, hidePrintIcon,
  onSaveClick, onHomeClick, onNotificationsClick, onAdvisorsClick, onIllustrationClick, onMobileMenuClick, onOipaOptionClick, onPrintClick,
}) => {
  const { t } = useTranslation('App');
  const dispatch = useDispatch();
  const applicationConfiguration = useScopedSelector((state: AppLayoutHeaderInterface) =>
    state?.data?.applicationConfiguration);
  const items = useMemo(() => {
    const menuItems: ReactElement[] = [];
    menuItems.push(
      <MenuItem url="#" onClick={onAdvisorsClick}>
        {t('advisors')}
      </MenuItem>,
    );

    menuItems.push(
      <MenuItem url="#" onClick={onIllustrationClick}>
        {t('illustrations')}
      </MenuItem>,
    );

    if (applicationConfiguration?.isOipaEnvironment) {
      menuItems.push(
        <MenuItem url="#" onClick={onOipaOptionClick} key="oipaEnvironments">
          {t('OIPA_environments')}
        </MenuItem>,
      );
    }

    return menuItems;
  }, [applicationConfiguration?.isOipaEnvironment, onAdvisorsClick, onIllustrationClick, onOipaOptionClick, t]);

  return (
    <>
      <GetApplicationConfigurationResource autoRequest />
      {/* Application section exclusive */}
      {
        isApp ?
          <>
            <Hidden xs>
              <StyledSaleFileNameContainer>
                <StyledSaleFileName>
                  {saleFileName ?? ''}
                </StyledSaleFileName>
              </StyledSaleFileNameContainer>

              {
                !isDraft &&
                  <StyledIconButton
                    Icon={CloudIcon}
                    disabled
                  />
              }

              {
                isDraft &&
                <StyledIconButton
                  onClick={onSaveClick}
                  label={t('saved')}
                  Icon={SaveDiskIcon}
                  data-testid="save-file-btn"
                />
              }

              {/* TODO */}
              <Hidden xs sm md>
                {
                  !hidePrintIcon && <StyledIconButton
                    Icon={PrintIcon}
                    onClick={onPrintClick}
                  />
                }
              </Hidden>
            </Hidden>

            <Hidden xs sm md>
              <StyledIconButton
                label={t('home')}
                Icon={HomeIcon}
                onClick={onHomeClick}
              />
            </Hidden>
          </>
          : null
      }

      <Hidden xs sm md>
        { isNewMessage === true && <StyledBadge>1</StyledBadge>}

        {
          !isChangePage && !isApp &&
          <StyledIconButton
            onClick={onNotificationsClick}
            label={t('notifications')}
            Icon={MegaPhoneIcon}
          />
        }

        <Menu
          items={items}
          verticalOffset={-10}
        >
          <StyledIconButton
            label={t('settings')}
            Icon={SettingsIcon}
          />
        </Menu>
        <Menu
          items={[
            <MenuItem url="" onClick={() => dispatch(openModalByName('modals.information'))}>
              {t('about')}
            </MenuItem>,
          ]}
          verticalOffset={-10}
        >
          <StyledIconButton label={t('about')} Icon={HelpIcon} />
        </Menu>
      </Hidden>
      <Hidden lg xl xxl>
        <StyledIconButton onClick={onMobileMenuClick} label={t('menuIcon')} Icon={MenuIcon} />
      </Hidden>
    </>
  );
};

export default AppLayoutHeaderRightContent;
