import Item from '../interfaces/Item';

function getSequenceMapping(data: Item[]) {
  const sequenceMapping = new Map();
  data?.forEach((item) => {
    if (item.resourceKey !== null && item.sequence !== null && item.sequence !== undefined) {
      const key = item.resourceKey.toLowerCase();
      sequenceMapping.set(key, item.sequence);
    }
  });
  return sequenceMapping;
}

export default getSequenceMapping;
