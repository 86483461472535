import axios from 'axios';
import { AdvisorModel } from '~/interfaces/InsurabilityDeclaration/AdvisorModel';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';

export const createSettingsAdvisor = (advisor: AdvisorModel) =>
  axios.post(getBffUrlForRoute(BffRoute.user_advisors, undefined, false), advisor)
    .then((axiosResponse) => axiosResponse)
    .catch((error) => {
      if (error.response && error.response.status === 409) {
        throw error;
      }

      return Promise.reject(new Error(`Error creating advisor "${advisor.firstName} ${advisor.lastName}"`));
    });
