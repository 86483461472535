import { MainHeaderButton, Spacing } from 'ia-react-core';
import styled from 'styled-components';

export const StyledMainHeaderButton = styled(MainHeaderButton)`
  width: 100%;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 10px;
  padding: 12px 18px;
  text-transform: initial;
  margin-right: 10px;
  
  &:hover,
  &:focus {
    border-color: white;
  }
`;

export const StyledSpacing = styled(Spacing)`
  [data-testid="RadioItem"] {
    width: fit-content;
    width: -moz-fit-content;
  }
`;
