import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionDisplayType } from '~/interfaces/InsurabilityDeclaration/enums';

// ========== Number Formatter ========== //

type NumberStringFormatter = (value: string, t?: TFunction) => string;
type NumberSymbolFormatter = (t?: TFunction) => string;

const createNumberSymbol = (fn: NumberSymbolFormatter) => fn;

const NumberStringSymbols = {
  currency:
    createNumberSymbol(() => '$'),
  percentage:
    createNumberSymbol(() => '%'),
  feet:
    createNumberSymbol((t) => t('measurementAbbreviations.feet')),
  kilograms:
    createNumberSymbol((t) => t('measurementAbbreviations.kilogram')),
  meters:
    createNumberSymbol((t) => t('measurementAbbreviations.meter')),
  pounds:
    createNumberSymbol((t) => t('measurementAbbreviations.pound')),
  centimeters:
    createNumberSymbol((t) => t('measurementAbbreviations.centimeter')),
  inches:
    createNumberSymbol((t) => t('measurementAbbreviations.inch')),
  unknown:
    createNumberSymbol(() => ''),
};

const createNumberFormatter = (fn: NumberStringFormatter) => fn;

const NumberStringFormatters = {
  currency:
    createNumberFormatter((n) => `${n} $`),
  percentage:
    createNumberFormatter((n) => `${n} %`),
  feet:
    createNumberFormatter((n, t) => `${n} ${t('measurementAbbreviations.feet')}`),
  kilograms:
    createNumberFormatter((n, t) => `${n} ${t('measurementAbbreviations.kilogram')}`),
  meters:
    createNumberFormatter((n, t) => `${n} ${t('measurementAbbreviations.meter')}`),
  pounds:
    createNumberFormatter((n, t) => `${n} ${t('measurementAbbreviations.pound')}`),
  centimeters:
    createNumberFormatter((n, t) => `${n} ${t('measurementAbbreviations.centimeter')}`),
  inches:
    createNumberFormatter((n, t) => `${n} ${t('measurementAbbreviations.inch')}`),
  unknown:
    createNumberFormatter((n) => `${n}`),
};

export type NumberDisplayType = Extract<QuestionDisplayType,
  QuestionDisplayType.Currency |
  QuestionDisplayType.Percentage |
  QuestionDisplayType.Feet |
  QuestionDisplayType.Kilograms |
  QuestionDisplayType.Meters |
  QuestionDisplayType.Pounds |
  QuestionDisplayType.Centimeters |
  QuestionDisplayType.Inches |
  QuestionDisplayType.Unknown
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const numberStringFormatterMap: Record<NumberDisplayType, NumberStringFormatter> = ({
  [QuestionDisplayType.Currency]: NumberStringFormatters.currency,
  [QuestionDisplayType.Percentage]: NumberStringFormatters.percentage,
  [QuestionDisplayType.Feet]: NumberStringFormatters.feet,
  [QuestionDisplayType.Kilograms]: NumberStringFormatters.kilograms,
  [QuestionDisplayType.Meters]: NumberStringFormatters.meters,
  [QuestionDisplayType.Pounds]: NumberStringFormatters.pounds,
  [QuestionDisplayType.Centimeters]: NumberStringFormatters.centimeters,
  [QuestionDisplayType.Inches]: NumberStringFormatters.inches,
  [QuestionDisplayType.Unknown]: NumberStringFormatters.unknown,
});

const numberSymbolMap: Record<NumberDisplayType, NumberSymbolFormatter> = ({
  [QuestionDisplayType.Currency]: NumberStringSymbols.currency,
  [QuestionDisplayType.Percentage]: NumberStringSymbols.percentage,
  [QuestionDisplayType.Feet]: NumberStringSymbols.feet,
  [QuestionDisplayType.Kilograms]: NumberStringSymbols.kilograms,
  [QuestionDisplayType.Meters]: NumberStringSymbols.meters,
  [QuestionDisplayType.Pounds]: NumberStringSymbols.pounds,
  [QuestionDisplayType.Centimeters]: NumberStringSymbols.centimeters,
  [QuestionDisplayType.Inches]: NumberStringSymbols.inches,
  [QuestionDisplayType.Unknown]: NumberStringSymbols.unknown,
});

export const useNumberFormatter = (displayType: QuestionDisplayType, isInt = false) => {
  const { t, i18n: { language } } = useTranslation('InsurabilityDeclaration');

  const values = useMemo(() => {
    const maxFractionDigits = isInt ? 0 : 2;
    const numberFormat = new Intl.NumberFormat(language, {
      minimumFractionDigits: maxFractionDigits,
      maximumFractionDigits: maxFractionDigits,
    });
    const numberFormatter = (num: number) => numberFormat.format(num);

    return {
      numberFormatter,
      symbol: numberSymbolMap[displayType as NumberDisplayType](t),
    };
  }, [displayType, isInt, t, language]);

  return values;
};

export const NumberFieldUtil = {
  useNumberFormatter,
  numberSymbolMap,
  numberStringFormatterMap,
  NumberStringSymbols,
  NumberStringFormatters,
};

// ========== String Formatter ========== //

export type StringDisplayType = Extract<QuestionDisplayType,
  QuestionDisplayType.Unknown |
  QuestionDisplayType.SocialInsuranceNumber |
  QuestionDisplayType.PhoneNumber |
  QuestionDisplayType.PostalCode |
  QuestionDisplayType.Multiline |
  QuestionDisplayType.ExtraLargeMultiline |
  QuestionDisplayType.PolicyNumber
>;

const displayTypeMasks = {
  socialInsuranceNumber: '999 999 999',
  phoneNumber: '999 999-9999',
  postalCode: 'A9A 9A9',
  policyNumber: '9999999999',
};

const displayTypeMasksByDisplayType: Record<StringDisplayType, string> = {
  [QuestionDisplayType.Unknown]: '',
  [QuestionDisplayType.SocialInsuranceNumber]: displayTypeMasks.socialInsuranceNumber,
  [QuestionDisplayType.PhoneNumber]: displayTypeMasks.phoneNumber,
  [QuestionDisplayType.PostalCode]: displayTypeMasks.postalCode,
  [QuestionDisplayType.Multiline]: '',
  [QuestionDisplayType.ExtraLargeMultiline]: '',
  [QuestionDisplayType.PolicyNumber]: displayTypeMasks.policyNumber,
};

// TODO what is particular to physician display types?
const physicianDisplayTypes: QuestionDisplayType[] = [
  QuestionDisplayType.PhysicianFirstName,
  QuestionDisplayType.PhysicianLastName,
  QuestionDisplayType.PhysicianCareLocation,
  QuestionDisplayType.PhysicianCity,
  QuestionDisplayType.PhysicianCivicNumber,
  QuestionDisplayType.PhysicianPostalCode,
  QuestionDisplayType.PhysicianProvince,
  QuestionDisplayType.PhysicianStreetName,
  QuestionDisplayType.PhysicianConditionName,
];

enum MaskToken {
  Number = '9',
  AlphaUpper = 'A',
  AlphaLower = 'a',
}

const maskTokens = Object.values(MaskToken);

const ALPHA_REGEX = /^[a-zA-Z]+$/;
const NUMBER_REGEX = /^\d+$/;

const maskFormat = (value: string, mask: string) => {
  const accumulator = [];

  const normalizedValue = value.replaceAll(/[^a-zA-Z0-9]/gm, '');

  let valueCursor = 0;
  let currentValueChar = normalizedValue.at(valueCursor);

  if (normalizedValue === '') {
    return '';
  }

  for (let i = 0; i < mask.length; ++i) {
    const currentMaskChar = mask.at(i);

    if (currentMaskChar === MaskToken.Number && NUMBER_REGEX.test(currentValueChar)) {
      valueCursor += 1;
      accumulator.push(currentValueChar);
    } else if (currentMaskChar === MaskToken.AlphaUpper && ALPHA_REGEX.test(currentValueChar)) {
      valueCursor += 1;
      accumulator.push(currentValueChar.toUpperCase());
    } else if (currentMaskChar === MaskToken.AlphaLower && ALPHA_REGEX.test(currentValueChar)) {
      valueCursor += 1;
      accumulator.push(currentValueChar.toLowerCase());
    } else if (!maskTokens.includes(currentMaskChar as MaskToken)) {
      accumulator.push(currentMaskChar);
    } else {
      break;
    }

    if (valueCursor > normalizedValue.length - 1) {
      break;
    } else {
      currentValueChar = normalizedValue.at(valueCursor);
    }
  }

  return accumulator.join('');
};

export const useStringFormatter = (displayType: QuestionDisplayType) => {
  const value = useMemo(() => {
    const mask = displayTypeMasksByDisplayType?.[displayType as StringDisplayType];
    const maskExists = Boolean(mask);
    const maskFormatter = maskExists ? (v: string) => maskFormat?.(v, mask) : (v: string) => v;
    const isMultiline = displayType === QuestionDisplayType.Multiline || displayType === QuestionDisplayType.ExtraLargeMultiline;
    const isPhysicianQuestion = physicianDisplayTypes.includes(displayType);
    const isHidden = displayType === QuestionDisplayType.Hidden;
    return {
      mask,
      maskFormatter,
      isMultiline,
      isPhysicianQuestion,
      isHidden,
    };
  }, [displayType]);

  return value;
};

export const StringFieldUtils = {
  displayTypeMasks,
  displayTypeMasksByDisplayType,
  physicianDisplayTypes,
  maskFormat,
  useStringFormatter,
};
