import { WORKFLOW_STATUSES, WorkflowStatus, useScopedSelector } from 'ia-react-core';
import { useParams } from 'react-router';
import FormModel from '~/interfaces/InsurabilityDeclaration/FormModel';
import ValidationMessageModel from '~/interfaces/InsurabilityDeclaration/ValidationMessageModel';
import { ValidationMessageTypes } from '~/interfaces/InsurabilityDeclaration/enums';
import { useMemo } from 'react';
import selectForms from '../selectors/selectForms';

export interface PrevNextFormObj {
  prevForm: FormModel;
  nextForm: FormModel;
}

function getFormWorkflowStatus(form: FormModel, showValidation: boolean): WorkflowStatus {
  if (form.validationMessages?.length > 0) {
    const warningType = form.validationMessages.some((message) => message.validationLevel === ValidationMessageTypes.Warning)
      ? WORKFLOW_STATUSES.WARNING
      : WORKFLOW_STATUSES.CAUTION;
    return showValidation ? warningType : WORKFLOW_STATUSES.INCOMPLETE;
  }
  return WORKFLOW_STATUSES.COMPLETE;
}

export function getWorkflowStatusByContext(form: FormModel, formName: string, showValidation: boolean) {
  return formName === form.urlName ? WORKFLOW_STATUSES.IN_PROGRESS : getFormWorkflowStatus(form, showValidation);
}

// Validation Messages
function getErrorsFromValidation(validationMessages: ValidationMessageModel[]) {
  return validationMessages.filter((vm) => vm.validationLevel === ValidationMessageTypes.Error);
}

export function getFirstFormWithErrors(forms: FormModel[]): FormModel {
  return forms.find((f) => getErrorsFromValidation(f.validationMessages).length > 0);
}

const nameToUrlName = (formName: string, id: string) => {
  const normalizedName = formName
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\u2019/g, '')
    .replace(/ /g, '-')
    .toLowerCase();

  return `${normalizedName}-${id}`;
};

const useCurrentForm = () => {
  const { formName: urlName } = useParams< {formName: string} >();

  const forms = useScopedSelector(selectForms);

  const currentForm = useMemo(() => forms?.find((form) => form.urlName === urlName), [urlName, forms]);

  return { forms, currentForm };
};

// Get previous and next form for a given form
export const prevNextForms = (forms: FormModel[], urlFormName: string): PrevNextFormObj => forms.reduce((acc, curr, index, tab) => {
  if (curr.urlName === urlFormName) {
    return { prevForm: tab[index - 1], nextForm: tab[index + 1] };
  }
  return acc;
}, { prevForm: undefined, nextForm: undefined });

export const FormUtils = {
  useCurrentForm,
  nameToUrlName,
  getFormWorkflowStatus,
  getWorkflowStatusByContext,
  getErrorsFromValidation,
  getFirstFormWithErrors,
  prevNextForms,
};
