import React from 'react';
import { EditIcon, DeleteIcon, MoreMenu, MenuItem } from 'ia-react-core';
import { ReactComponent as FolderIcon } from '~/assets/Icons/folder.svg';
import { useTranslation } from 'react-i18next';
import { SaleFile } from '../interfaces/SaleFile';
import { StyledFileActionsChangeCellContainer, StyledFileActionsChangeCellIconButton, StyledFileActionsChangeCellMenuContainer } from './FileStatusCell.styles';

export interface FileActionsCellProps {
  saleFile: SaleFile;
  saleCompleted: boolean;
  isCompact: boolean;
  openRenameModal: (saleFile: SaleFile) => void;
  openDeleteModal: (saleFile: SaleFile) => void;
  openSaveModal?: (saleFile: SaleFile) => void;
}

const FileActionsChange = ({ saleFile, isCompact, openRenameModal, openDeleteModal, openSaveModal, saleCompleted }: FileActionsCellProps) => {
  const { t } = useTranslation('SaleFileDataTable');
  let iconElement;
  if (isCompact) {
    const items = [
      <MenuItem onClick={() => openDeleteModal(saleFile)}>{t('delete')}</MenuItem>,
    ];
    if (!saleCompleted) {
      items.splice(0, 0, <MenuItem onClick={() => openRenameModal(saleFile)}>{t('rename')}</MenuItem>);
    }
    if (saleCompleted) {
      items.splice(1, 0, <MenuItem onClick={() => openSaveModal(saleFile)}>{t('save')}</MenuItem>);
    }

    iconElement = (
      <StyledFileActionsChangeCellMenuContainer>
        <MoreMenu
          items={items}
        />
      </StyledFileActionsChangeCellMenuContainer>
    );
  } else {
    iconElement = (
      <StyledFileActionsChangeCellContainer>
        {!saleCompleted &&
        <StyledFileActionsChangeCellIconButton
          onClick={() => openRenameModal(saleFile)}
          Icon={EditIcon}
          label={t('rename')}
        />}
        {saleCompleted &&
        <StyledFileActionsChangeCellIconButton
          onClick={() => openSaveModal(saleFile)}
          Icon={FolderIcon}
          label={t('saveFileModal.save')}
        />}
        <StyledFileActionsChangeCellIconButton
          onClick={() => openDeleteModal(saleFile)}
          Icon={DeleteIcon}
          label={t('delete')}
        />
      </StyledFileActionsChangeCellContainer>
    );
  }
  return <>{iconElement}</>;
};
export { FileActionsChange };
