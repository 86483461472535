import React, { FC, useEffect } from 'react';
import {
  Block, SecondaryButton, useScopedSelector, Divider, Form, PrimaryButton, requestResourceByName,
} from 'ia-react-core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import LoaderOverlay from '~/components/LoaderOverlay';
import selectCaseLoading from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/selectors/selectCaseLoading';
import Disclaimer from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/Disclaimer';
import { DynamicFormContentProps } from '~/interfaces/ChangeFragment';
import GetChangeRequest from '~/fragments/SharedLibrary/Resources/GetChangeRequest';
import GetChangeRequestFormTypes from '~/fragments/SharedLibrary/Resources/GetChangeRequestFormTypes';
import AddChangeRequestForm from '~/fragments/SharedLibrary/Resources/AddChangeRequestForm';
import { AppState } from '~/interfaces/AppState';
import FormModel from '~/interfaces/InsurabilityDeclaration/FormModel';
import ChangeRequestModel from '~/interfaces/InsurabilityDeclaration/ChangeRequestModel';
import { ValidationMessageTypes } from '~/interfaces/InsurabilityDeclaration/enums';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import selectedCase from '~/fragments/SharedLibrary/selectors/selectedCase';
import selectValidationAPIErrors from '~/fragments/SharedLibrary/selectors/selectValidationAPIErrors';
import ValidateErrorMessageResource from '~/fragments/SharedLibrary/Components/RenderCheckBox/Components/validateErrorMessageResource';
import DownloadChangeRequestForm from '~/fragments/SharedLibrary/Resources/DownloadChangeRequestForm';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import { StyledActionBar } from './DynamicFormContent.styles';
import FormContainer from '../FormContainer';
import getPathName from '../../utilities/getPathName';

const DynamicFormContent: FC<DynamicFormContentProps> = ({ formId, formName, saleId }: DynamicFormContentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('formloader');
  const history = useHistory();
  const loading = useScopedSelector(selectCaseLoading);
  const changeRequest: ChangeRequestModel = useSelector((state: AppState) => selectedCase(state));
  const validationApiErrorMessages: ValidationMessage[] = useSelector(selectValidationAPIErrors);

  const handleCancel = () => {
    const param = { saleId: changeRequest?.id, formId };
    const url = getBffUrlForRouteWithoutQP(BffRoute.change_request_form_by_id, param, false);
    dispatch(requestResourceByName('resources.remove_change_request_form', param, { method: 'PUT', url }));
  };
  const handleSave = () => {
    dispatch(requestResourceByName('resources.add_change_request_form', { saleId: changeRequest?.id, formId }, { method: 'POST' }));
  };

  const onSaveSuccess = (result: ChangeRequestModel) => {
    const selectedForm = result?.forms?.find((form: FormModel) => form?.id === formId);
    const hasError = selectedForm?.validationMessages?.filter((msg) => msg.validationLevel === ValidationMessageTypes.Error);
    if (selectedForm?.validationMessages?.length === 0 || hasError?.length === 0) {
      if (validationApiErrorMessages?.length > 0) {
        dispatch(requestResourceByName('resources.validate_api_error_message'));
      } else {
        onSuccessValidation();
      }
    }
  };

  useEffect(() => {
    if (saleId) {
      dispatch(requestResourceByName('resources.change_request', { saleId }));
    }
  }, [dispatch, saleId]);

  const handleDownload = () => {
    dispatch(requestResourceByName('resources.download_change_request_form', { changeRequestId: saleId }));
  };

  const onSuccessValidation = () => {
    const path = getPathName(formName, changeRequest?.language);
    history.push(`/application/${saleId}/${path}`);
  };

  return (
    <Block white>
      <Form name="dynamicform" hideErrorMessages>
        <div>
          <LoaderOverlay enabled={loading} delayMs={0}>
            <Block white p={0} m-b={24}>
              <Block white p-h={16} p-v={24}>
                <Disclaimer />
                { changeRequest && <FormContainer formId={formId} /> }
              </Block>
            </Block>
          </LoaderOverlay>
        </div>
        <Divider />
        <StyledActionBar>
          <PrimaryButton onClick={handleSave}>{t('save')}</PrimaryButton>
          <SecondaryButton onClick={handleCancel}>
            {t('cancel')}
          </SecondaryButton>
          <SecondaryButton onClick={handleDownload}>
            {t('download')}
          </SecondaryButton>
        </StyledActionBar>
      </Form>
      <GetChangeRequest saleId={saleId} />
      <GetChangeRequestFormTypes saleId={saleId} changeRequestType={formName} />
      <AddChangeRequestForm saleId={saleId} formId={formId} method="POST" onSuccess={onSaveSuccess} />
      <ValidateErrorMessageResource saleId={saleId} formtype={formName} onApiSuccess={onSuccessValidation} />
      <DownloadChangeRequestForm saleId={saleId} formId={formId} name="resources.download_change_request_form" />
    </Block>
  );
};

export default DynamicFormContent;
