import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { useDispatch } from 'react-redux';
import { loadChangeRequestFormsSuccessAction } from '../ChangeRequest.actions';
import { ChangeRequestFormState } from '../interfaces/State';

export interface GetChangeRequestFormTypesProps {
  saleId: string;
  changeRequestType: string;
}
const GetChangeRequestFormTypes: FC<GetChangeRequestFormTypesProps> = ({ saleId, changeRequestType }) => {
  const dispatch = useDispatch();

  return (
    <Resource
      name="resources.get_change_request_form_types"
      url={getBffUrlForRouteWithoutQP(BffRoute.change_page_tab_content, { saleId, changeRequestType }, false)}
      onSuccess={(result) => {
        const data = result.data as ChangeRequestFormState;
        dispatch(loadChangeRequestFormsSuccessAction(data));
      }}
    />
  );
};

export default GetChangeRequestFormTypes;
