import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledNoSaleFilesFoundMessageContainer, StyledNoSaleFilesFoundMessage } from './NoSaleFilesFoundMessage.styles';

interface NoSaleFilesFoundMessageProps {}

const NoSaleFilesFoundMessage: FC<NoSaleFilesFoundMessageProps> = () => {
  const { t } = useTranslation('SaleFileDataTable');
  return (
    <StyledNoSaleFilesFoundMessageContainer>
      <StyledNoSaleFilesFoundMessage>{t('noSaleFiles')}</StyledNoSaleFilesFoundMessage>
    </StyledNoSaleFilesFoundMessageContainer>
  );
};

export default NoSaleFilesFoundMessage;
