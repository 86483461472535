import { WORKFLOW_STATUSES, WorkflowRouteDefinition, WorkflowStatus } from 'ia-react-core';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import isCurrentRoute from './isCurrentRoute';
import isPreviousRoute from './isPreviousRoute';
import getRouteStatus from './getRouteStatus';

export type LastStatuses = Record<string, WorkflowStatus>;

const updateRouteStatus = (
  route: WorkflowRouteDefinition,
  currentRoute: string,
  previousRoute: string,
  routePath: string,
  lastStatuses: LastStatuses,
  apiResponse: ValidationMessage[],
  setLastStatuses: Function,
): WorkflowStatus => {
  let { status } = route;
  const pathKey = JSON.stringify(route.path); // Serialize the path
  if (isCurrentRoute(route, currentRoute)) {
    // Use the stored status if it exists, otherwise assign IN_PROGRESS
    if (lastStatuses[pathKey]) {
      status = lastStatuses[pathKey];
    } else if (status !== WORKFLOW_STATUSES.ERROR && status !== WORKFLOW_STATUSES.COMPLETE && status !== WORKFLOW_STATUSES.CAUTION && status !== WORKFLOW_STATUSES.WARNING) {
      status = WORKFLOW_STATUSES.IN_PROGRESS;
    }
  } else if (isPreviousRoute(route, previousRoute, routePath) && !isCurrentRoute(route, currentRoute)) { // Add this condition
    const prevRouteStatus = ((route?.path && (route?.path as { en: string }).en === previousRoute) || (route?.path && (route?.path as { fr: string }).fr === previousRoute)) ? route.status : WORKFLOW_STATUSES.IN_PROGRESS;

    status = getRouteStatus(apiResponse, prevRouteStatus) as WorkflowStatus;
    // Store the assigned status
    setLastStatuses((prevStatuses: LastStatuses) => {
      if (prevStatuses[pathKey] === status) {
        return { ...prevStatuses, [pathKey]: status };
      }
      return prevStatuses;
    });
  }
  return status;
};
export default updateRouteStatus;
