import ExplanatoryTableColumnModel from '~/interfaces/InsurabilityDeclaration/ExplanatoryTableColumnModel';
import ExplanatoryTableColumnDto from '../dtos/ExplanatoryTableColumnDto';

export default class ExplanatoryTableColumnBuilder {
  toModel(dto: ExplanatoryTableColumnDto): ExplanatoryTableColumnModel {
    return new ExplanatoryTableColumnModel(
      dto.id,
      dto.header.name,
    );
  }
}
