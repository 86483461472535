import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledOptionalLabel } from './OptionalLabel.styles';

export interface OptionalLabelProps {
  noSpace?: boolean;
}

const OptionalLabel: FC<OptionalLabelProps> = ({ noSpace = false }) => {
  const { t } = useTranslation('OptionalLabel');

  return (
    <StyledOptionalLabel>
      {!noSpace && <>&nbsp;</>}
      ({t('optional')})
    </StyledOptionalLabel>
  );
};

export default OptionalLabel;
