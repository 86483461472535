import { ComponentClass } from 'react';
import { compose } from 'redux';

import { injectTranslations } from 'ia-react-core';

import fr from './translations/fr';
import en from './translations/en';
import InsuredPersonsContent from './InsuredPersonsContent';

export default compose<ComponentClass>(
  injectTranslations('InsuredPersonsContent', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(InsuredPersonsContent);
