import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { P, PrimaryButton, Resource, SecondaryButton, Spacing, WarningModal, closeModalByName, requestResourceByName } from 'ia-react-core';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { useParams } from 'react-router';
import useScenario from '~/fragments/IllustrationFragment/hooks/useScenario';
import constructScenarioActionName from '~/utilities/constructScenarioActionName';
import { DELETE } from '~/constants/APP_CONSTANTS';
import { ScenarioNavDeleteModalProps } from './interfaces/ScenarioNavDeleteModalProps';
import { StyledScenarioNavDeleteActionBar } from './ScenarioNavDeleteModal.styles';

const ScenarioNavDeleteModal: FC<ScenarioNavDeleteModalProps> = ({ onHandleDeleteSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('App');
  const { saleId } = useParams<{ saleId: string }>();
  const scenario = useScenario();
  const url = getBffUrlForRoute(BffRoute.delete_scenario, { saleId, scenarioId: scenario?.idDemande });
  const scenarioActionStorageName = constructScenarioActionName(saleId);

  const deleteResource = (
    <Resource
      name="deleteScenario"
      url={url}
      method="DELETE"
      onSuccess={() => {
        dispatch(requestResourceByName('resources.scenarios'));
        localStorage.setItem(scenarioActionStorageName, DELETE);
        onHandleDeleteSuccess(scenario?.idDemande);
      }}
    />
  );

  return (
    <>
      {deleteResource}
      <WarningModal
        name="confirmDeleteScenario"
        modalTitle={t('deleteScenarioTitle')}
        shouldCloseOnOverlayClick={false}
      >
        <Spacing m-b={16}>
          <P>{t('deleteScenarioDesc1')}</P>
        </Spacing>
        <P>{t('deleteScenarioDesc2')}</P>

        <StyledScenarioNavDeleteActionBar>
          <PrimaryButton
            onClick={() => dispatch(requestResourceByName('resources.deleteScenario'))}
          >
            {t('yes')}
          </PrimaryButton>
          <SecondaryButton
            onClick={() => dispatch(closeModalByName('modals.confirmDeleteScenario'))}
          >
            {t('no')}
          </SecondaryButton>
        </StyledScenarioNavDeleteActionBar>
      </WarningModal>
    </>
  );
};

export default ScenarioNavDeleteModal;
