import {
  DeleteIcon, EditIcon, MainLoader, ResourceLoader, SecondaryTextIconButton, T5, TableColumn, useBreakpoint,
} from 'ia-react-core';
import React, { useCallback } from 'react';
import { AdvisorModel } from '~/interfaces/InsurabilityDeclaration/AdvisorModel';
import { useSelector } from 'react-redux';
import { ValidationMessageLevel } from '~/interfaces/ValidationMessageLevel';
import { BorderBox } from '~/components/BorderBox';
import selectAdvisorValidation from '~/fragments/ApplicationFragment/selectors/selectAdvisorValidation';
import { useTranslation } from 'react-i18next';
import { StyledButtonContainer, StyledDataTable, StyledIconButton, StyledValue, StyledMobileButtonContainer, StyledDivider, StyledAdvisorDataTableName } from './AdvisorsDataTable.styles';
import { AdvisorDataTableMapping } from '../../../../interfaces/AdvisorFormFieldMapping';
import { DEFAULT_ADVISOR_DATA_TABLE_MAPPING } from '../../constants';

type AdvisorsDataTableDisplayType = 'modal' | 'screen';

export interface AdvisorsDataTableProps {
  resourceUrl: string;
  onDeleteClickButton: (advisor: AdvisorModel) => void;
  onEditClickButton: (advisor: AdvisorModel) => void;
  dataMapping?: AdvisorDataTableMapping;
  showNoDataMessage?: boolean;
  hideIsDefaultCol?: boolean;
  type?: AdvisorsDataTableDisplayType;
}

const tableColumnWidths = {
  modal: {
    name: 169,
    code: undefined as undefined,
    role: undefined as undefined,
    commission: undefined as undefined,
    default: undefined as undefined,
    actions: 112,
  },
  screen: {
    name: 270,
    code: undefined as undefined,
    role: undefined as undefined,
    commission: 189,
    default: undefined as undefined,
    actions: 112,
  },
};

const EMPTY_COMPONENT = (): React.ReactNode => null;

export const AdvisorsDataTable = ({
  resourceUrl,
  onDeleteClickButton,
  onEditClickButton,
  dataMapping = DEFAULT_ADVISOR_DATA_TABLE_MAPPING,
  showNoDataMessage = false,
  hideIsDefaultCol = false,
  type = 'modal',
}: AdvisorsDataTableProps) => {
  const { t } = useTranslation('AdvisorsModal');

  const isXs = useBreakpoint('xs');

  const validationMessages = useSelector(selectAdvisorValidation);
  const hasValidationWarning = (name: string) => (validationMessages?.some((message) => message?.nomPropriete?.includes(name) && message?.categorie === ValidationMessageLevel.WarningCritique));

  const handleEditAdvisor = useCallback((advisor: AdvisorModel) => () => onEditClickButton(advisor), [onEditClickButton]);

  const handleDeleteAdvisor = useCallback((advisor: AdvisorModel) => () => onDeleteClickButton(advisor), [onDeleteClickButton]);

  const columnWidths = tableColumnWidths[type];

  return (
    <ResourceLoader
      name="advisorList"
      url={resourceUrl}
      getDataFromResponse={(response: AdvisorModel[]) => response}
      CustomNoDataMessage={showNoDataMessage ? null : EMPTY_COMPONENT}
      CustomLoader={MainLoader}
    >
      {({ data }: { data: AdvisorModel[] }) => (
        !isXs ?
          <StyledDataTable name="advisorTable" className={hasValidationWarning('agentViewModels') ? 'warning' : ''} data={data}>
            <TableColumn header={t('dataTable.name')} width={columnWidths.name}>
              {
                (a: AdvisorModel) => (
                  <StyledAdvisorDataTableName>
                    {dataMapping.name(a)}
                  </StyledAdvisorDataTableName>
                )
              }
            </TableColumn>
            <TableColumn header={t('common.agentCode')} width={columnWidths.code}>{dataMapping.code}</TableColumn>
            <TableColumn header={t('dataTable.role')} width={columnWidths.role}>
              {(a: AdvisorModel) => dataMapping.provideAfterSaleService(a) ? t('dataTable.afterSaleService') : ''}
            </TableColumn>
            <TableColumn header={t('common.commissionDistribution')} width={columnWidths.commission}>{dataMapping.commissionPercentage}</TableColumn>
            {!hideIsDefaultCol &&
              <TableColumn header={t('common.defaultAdvisor')} width={columnWidths.default}>
                {(a: AdvisorModel) => dataMapping.isDefault(a) ? t('dataTable.default') : ''}
              </TableColumn>}
            <TableColumn width={columnWidths.actions}>
              {(advisor: AdvisorModel) => (
                <StyledButtonContainer>
                  <StyledIconButton
                    Icon={EditIcon}
                    label={t('dataTable.edit')}
                    onClick={handleEditAdvisor(advisor)}
                  />
                  <StyledIconButton
                    Icon={DeleteIcon}
                    label={t('dataTable.delete')}
                    onClick={handleDeleteAdvisor(advisor)}
                  />
                </StyledButtonContainer>
              )}
            </TableColumn>
          </StyledDataTable>
          :
          <BorderBox>
            {data.map((advisor, index) => (
              <div key={`${advisor.code}-${index}`}>
                <T5>{t('dataTable.name')}</T5>
                <StyledValue>{advisor.name || `${advisor.firstName} ${advisor.lastName}`}</StyledValue>
                <T5>{t('common.agentCode')}</T5>
                <StyledValue>{advisor.code}</StyledValue>
                <T5>{t('dataTable.role')}</T5>
                <StyledValue>{advisor.provideAfterSaleService ? t('dataTable.afterSaleService') : ''}</StyledValue>
                <T5>{t('common.commissionDistribution')}</T5>
                <StyledValue>{`${advisor.commissionPercentage}%`}</StyledValue>
                {!hideIsDefaultCol &&
                  <>
                    <T5>{t('common.defaultAdvisor')}</T5>
                    <StyledValue>{advisor.isDefault ? t('dataTable.default') : ''}</StyledValue>
                  </>}
                <StyledMobileButtonContainer>
                  <SecondaryTextIconButton onClick={handleEditAdvisor(advisor)} LeftIcon={EditIcon}>{t('form.edit')}
                  </SecondaryTextIconButton>
                  <SecondaryTextIconButton onClick={handleDeleteAdvisor(advisor)} LeftIcon={DeleteIcon}>{t('form.delete')}
                  </SecondaryTextIconButton>
                </StyledMobileButtonContainer>
                {index !== data.length - 1 && <StyledDivider />}
              </div>
            ))}
          </BorderBox>
      )}
    </ResourceLoader>
  );
};
