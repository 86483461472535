export default {
  fatalError: 'Une erreur technique s\'est produite. Nous sommes désolés pour tout inconvénient que cela pourrait vous causer.',
  advisorTable: {
    advisors: 'Conseiller(s)',
    ok: 'Ok',
  },
  common: {
    addAnAdvisor: 'Ajouter un conseiller',
    agentCode: 'Code d\'agent',
    commissionDistribution: 'Répartition de la rémunération',
    defaultAdvisor: 'Conseiller par défaut',
    cancel: 'Annuler',
    yes: 'Oui',
    no: 'Non',
  },
  dataTable: {
    name: 'Nom',
    role: 'Rôle',
    afterSaleService: 'Service après-vente',
    default: 'Par défaut',
    edit: 'Modifier',
    delete: 'Supprimer',
  },
  delete: {
    confirm: 'Veuillez cliquer sur Oui pour supprimer ce conseiller. Vous ne pourrez plus annuler ce changement.\nD\'accord pour supprimer ?',
  },
  form: {
    modifyAnAdvisor: 'Modification d\'un conseiller',
    modifyTooltip: 'Ces modifications ne seront pas appliquées pour le dossier en cours et seront appliquées pour les prochains dossiers. Si vous désirez appliquer ces modifications au dossier en cours, vous devez faire le changement dans l’écran « Rapport » ou dans la proposition.',
    identificationAndContactInfo: 'Identification et coordonnées',
    professionalTitle: 'Titre professionnel',
    lastName: 'Nom',
    firstName: 'Prénom',
    agency: 'Agence',
    cellPhone: 'Téléphone cellulaire',
    cellPhoneTooltip: 'Veuillez inscrire votre numéro de cellulaire afin de pouvoir recevoir les notifications.',
    workPhone: 'Téléphone bureau',
    extension: 'Poste',
    fax: 'Télécopieur',
    emailAddress: 'Adresse courriel',
    compensationAndAfterSalesService: 'Rémunération et service après-vente',
    afterSalesService: 'Est-ce que ce conseiller effectuera le service après-vente ?',
    addAsDefault: 'Voulez-vous ajouter ce conseiller par défaut à vos rapports et propositions ?',
    save: 'Sauvegarder',
    add: 'Sauvegarder',
    edit: 'Éditer',
    delete: 'Supprimer',
    back: 'Retour',
    optional: 'optionnel',
    agentCodeRequired: 'Veuillez indiquer le code d\'agent.',
    agentLastNameRequired: 'Veuillez indiquer le nom du conseiller.',
    agentCodeSixDigit: 'Le code d\'agent doit avoir 6 caractères.',
    agentCodeExists: 'Il existe déjà un conseiller avec ce code d’agent.',
    workPhoneFormat: 'Le numéro de téléphone au bureau doit être dans un format valide.',
    cellPhoneFormat: 'Le numéro de cellulaire doit être dans un format valide.',
    faxNumberFormat: 'Le numéro du télécopieur doit être dans un format valide.',
    phoneExtension: 'L\'extension ne peut pas être reseigné si le numéro de téléphone bureau n\'est pas inscrit.',
    emailMessage: 'L\'adresse courriel ne correspond pas aux critères de validation.',
    error: 'Erreur',
    warning: 'Avertissement',
    commissionDistributionMax: 'La répartition de la rémunération doit être inférieure ou égale à 100 %.',
    commissionDistribution: 'Veuillez indiquer la répartition de la rémunération.',
  },
  anchorWarning: "Voir l'avertissement",
  anchorError: 'Voir erreur',
};
