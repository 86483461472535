import { Reducer } from 'react';
import { Action } from 'redux';
import {
  ADD_SETTINGS_ADVISOR_SUCCESS_TYPE, CLEAN_FATAL_ERROR_TYPE, DELETE_SETTINGS_ADVISOR_SUCCESS_TYPE, EDIT_SETTINGS_ADVISOR_SUCCESS_TYPE, FATAL_ERROR_TYPE, SET_ADVISOR_TO_EDIT_TYPE, SET_DUPLICATION_CODE_ERROR_TYPE, SWITCH_VIEW_TYPE,
} from '../AdvisorsModal.actions';
import addSettingsAdvisorSuccess from './addSettingsAdvisorSuccess';
import updateSettingsAdvisorSuccess from './updateSettingsAdvisorSuccess';
import deleteSettingsAdvisorSuccess from './deleteSettingsAdvisorSuccess';
import fatalError from './fatalError';
import switchViewMode from './switchViewMode';
import advisorToEdit from './advisorToEdit';
import cleanFatalError from './cleanFatalError';
import setDuplicationCodeError from './setDuplicationCodeError';
import { AdvisorsModalState } from '../AdvisorsSettingsModalState';

const mapping: Record<string, Reducer<any, any>> = {
  [ADD_SETTINGS_ADVISOR_SUCCESS_TYPE]: addSettingsAdvisorSuccess,
  [EDIT_SETTINGS_ADVISOR_SUCCESS_TYPE]: updateSettingsAdvisorSuccess,
  [DELETE_SETTINGS_ADVISOR_SUCCESS_TYPE]: deleteSettingsAdvisorSuccess,
  [FATAL_ERROR_TYPE]: fatalError,
  [CLEAN_FATAL_ERROR_TYPE]: cleanFatalError,
  [SWITCH_VIEW_TYPE]: switchViewMode,
  [SET_ADVISOR_TO_EDIT_TYPE]: advisorToEdit,
  [SET_DUPLICATION_CODE_ERROR_TYPE]: setDuplicationCodeError,
};

export default function reducer(state: AdvisorsModalState, action: Action) {
  const typeReducer = mapping[action.type] ?? ((x) => x);
  return typeReducer(state, action);
}
