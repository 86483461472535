import React, { FC } from 'react';
import { StyledSectionDivider } from './SectionDivider.styles';

export interface SectionDividerProps {
  className?: string;
}

const SectionDivider: FC<SectionDividerProps> = ({ className }) => <StyledSectionDivider className={className} />;

export default SectionDivider;
