import { requestResourceByName } from 'ia-react-core';
import { LinkedApplicationFormData } from '~/interfaces/LinkedApplicationsPageState';
import { ApplicationIntentType } from '../constants';
import { formatRequestToPost } from '../pages/LinkedApplicationsPage/utilities/LinkedApplication.utils';
import { UPDATE_LINKED_APPLICATIONS_RESOURCE_NAME } from '../pages/LinkedApplicationsPage/LinkedApplicationsPage.constants';

const linkedApplicationsApiCall = (formValues: LinkedApplicationFormData, dispatch: Function) => {
  if (formValues) {
    const updatedRequest = formatRequestToPost(
      formValues,
      ApplicationIntentType.SaveAndValidate,
    );
    dispatch(requestResourceByName(`resources.${UPDATE_LINKED_APPLICATIONS_RESOURCE_NAME}`, { ...updatedRequest, initateFromLeftMenu: true }));
  }
};

export default linkedApplicationsApiCall;
