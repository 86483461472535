import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Modal, RadioList, useScopedSelector, requestResourceByName, changeLanguage, SecondaryButton, PrimaryButton, ActionBar,
  openModalByName,
  closeModalByName,
  Spacing,
  ConfirmDialog,
  WarningIcon,
} from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Locale } from '~/hooks/useCurrentLocale';
import { useHistory } from 'react-router';
import RESOURCE_NAMES from '~/fragments/ChangeFragment/constants/RESOURCE_NAMES';
import { AxiosError, AxiosResponse } from 'axios';
import formatEffectiveDate from '~/utilities/formatEffectiveDate';
import { FIRST_NAME_CHAR_LIMIT, LAST_NAME_CHAR_LIMIT } from '~/fragments/ApplicationFragment/pages/ApplicantPage/constant';
import {
  StyledDateControl, StyledErrorMessage, StyledForm, StyledFormDiv, StyledFormSection, StyledInfoMessage, StyledLabel, StyledT4, StyledTextInput,
} from './UnknownDOBSexModal.styles';
import { Download, DownloadState } from '../../DownloadState';
import UpdateInsuredInformationResource from './resources/UpdateInsuredInformationResource';
import CompleteContractInsuredResource from './resources/CompleteContractInsuredResource';

interface UnknownDOBSexModalProps {

}

const UnknownDOBSexModal: React.FC<UnknownDOBSexModalProps> = () => {
  const { t } = useTranslation('makeEchange');
  const dispatch = useDispatch();
  const history = useHistory();
  const downloadFormValues = useScopedSelector((state: DownloadState) => state?.values?.downloadData);
  const [downloadData, setDownloadData] = useState<Download | null>();
  const [errorMessages, setErrorMessages] = useState<{message: string; id: string}[]>([]);

  useEffect(() => {
    if (downloadFormValues) {
      setDownloadData(downloadFormValues);
    }
  }, [downloadFormValues]);

  const handleDOBOnChange = useCallback((date: string | Date, id: string): void => {
    setErrorMessages([]);
    setDownloadData((prevState) => {
      if (prevState) {
        const newInsureds = prevState.insureds?.map((insured) => {
          if (insured.id === id) {
            return { ...insured, birthDate: date.toString() };
          }
          return insured;
        });
        return { ...prevState, insureds: newInsureds };
      }
      return prevState;
    });
  }, [downloadData]);

  const handleGenderChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, id: string): void => {
      setDownloadData((prevState) => {
        if (prevState) {
          const newInsureds = prevState.insureds?.map((insured) => {
            if (insured.id === id) {
              return { ...insured, sex: event.target.value };
            }
            return insured;
          });
          return { ...prevState, insureds: newInsureds };
        }
        return prevState;
      });
      dispatch(requestResourceByName(`resources.${RESOURCE_NAMES.UPDATE_INSURED_INFORMATION_RESOURCE}`, { ...downloadData.insureds }));
    },
    [downloadData],
  );

  const handleDOBOnBlur = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    dispatch(requestResourceByName(`resources.${RESOURCE_NAMES.UPDATE_INSURED_INFORMATION_RESOURCE}`, { ...downloadData.insureds }));
  }, [downloadData, dispatch]);

  const handleModalSubmit = (): void => {
    dispatch(requestResourceByName(`resources.${RESOURCE_NAMES.COMPLETE_CONTRACT_INSURED_RESOURCE}`, { ...downloadData }));
  };

  const onContractSuccess = (response: AxiosResponse): void => {
    const { status, data } = response;
    if (status !== 200) return;
    dispatch(changeLanguage(downloadFormValues.language ? Locale.EN : Locale.FR));
    const redirectUrl = downloadFormValues.language ? `/application/${(data as { id: string })?.id}/echange/changes` : `/application/${(data as { id: string })?.id}/echange/changements`;
    history.push(redirectUrl);
  };

  const onContractError = (error: AxiosError): void => {
    const { status, data } = error.response as AxiosResponse;
    if (status !== 400) return;
    setErrorMessages((data as { messageFR: string; messageEN: string; id: string }[]).map((item: { messageFR: string; messageEN: string; id: string }) => ({
      id: item.id,
      message: downloadFormValues.language ? item.messageEN : item.messageFR,
    })));
  };

  const handleModalClose = (): void => {
    dispatch(openModalByName('modals.confirmdobmodal'));
  };

  const confirmCloseUnknownDOBModal = (): void => {
    dispatch(closeModalByName('modals.confirmdobmodal'));
    dispatch(closeModalByName('modals.unknownDOBSex'));
  };
  return (
    <>
      <Modal name="unknownDOBSex">
        <ConfirmDialog
          name="confirmdobmodal"
          onConfirm={confirmCloseUnknownDOBModal}
          Icon={WarningIcon}
          color="#ffdc00"
          noheader
          confirmButton={t('confirmModal.yes')}
          cancelButton={t('confirmModal.no')}
        >
          {t('confirmModal.content1')}
          <Spacing p={12} />
          {t('confirmModal.content2')}
          <Spacing p={12} />
        </ConfirmDialog>
        <StyledT4>{t('unknownDOBSexHeader')}</StyledT4>
        { errorMessages?.length > 0 &&
          <StyledErrorMessage>
            {errorMessages.map(({ message, id }) => (
              <li key={id}>{message}</li>
            ))}
          </StyledErrorMessage>}
        <StyledInfoMessage>
          {t('unknownDOBSexMessage')}
        </StyledInfoMessage>
        {downloadFormValues?.insureds?.map((insured) => (
          <>
            <UpdateInsuredInformationResource
              saleId={downloadFormValues?.id}
              insuredId={insured?.id}
            />
            <StyledForm key={insured.id}>
              <StyledFormSection>
                <StyledFormDiv>
                  <StyledLabel htmlFor="lastName">{t('lastName')}</StyledLabel>
                  <StyledTextInput textOnly id="lastName" name="lastName" value={insured.lastName} maxLength={LAST_NAME_CHAR_LIMIT} />
                </StyledFormDiv>
                <StyledFormDiv>
                  <StyledLabel htmlFor="firstName">{t('firstname')}</StyledLabel>
                  <StyledTextInput textOnly id="firstName" name="firstName" value={insured.firstName} maxLength={FIRST_NAME_CHAR_LIMIT} />
                </StyledFormDiv>
              </StyledFormSection>
              <StyledFormSection>
                <StyledFormDiv>
                  <StyledLabel htmlFor="birthDate">{t('dob')}</StyledLabel>
                  {insured.birthDate === null ?
                    <StyledDateControl
                      textOnly={insured.birthDate !== null}
                      name="birthDate"
                      value=""
                      onValueChange={(date) => handleDOBOnChange(date, insured.id)}
                      onBlur={(event: ChangeEvent<HTMLInputElement>) => handleDOBOnBlur(event)}
                    />
                    :
                    <StyledTextInput
                      textOnly={insured.birthDate !== null}
                      id="birthDate"
                      name="birthDate"
                      value={formatEffectiveDate(insured.birthDate, downloadFormValues?.language ? Locale.EN : Locale.FR)}
                    />}
                </StyledFormDiv>
                <StyledFormDiv>
                  <StyledLabel htmlFor="gender">{t('gender')}</StyledLabel>
                  {insured.sex === null ?
                    <RadioList
                      options={[{ label: t('male'), value: 'Masculin' }, { label: t('female'), value: 'Feminin' }]}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleGenderChange(event, insured.id)}
                      value={downloadData?.insureds?.find((ins) => ins.id === insured.id)?.sex || ''}
                    />
                    : <StyledTextInput textOnly={insured.sex !== null} id="gender" name="gender" value={insured.sex} />}
                </StyledFormDiv>
              </StyledFormSection>
            </StyledForm>
          </>
        ))}
        <ActionBar nopadding>
          <PrimaryButton onClick={handleModalSubmit}>{t('save')}</PrimaryButton>
          <SecondaryButton onClick={handleModalClose}>{t('cancel')}</SecondaryButton>
        </ActionBar>
      </Modal>
      <CompleteContractInsuredResource saleId={downloadFormValues?.id} onContractSuccess={onContractSuccess} onContractError={onContractError} />
    </>
  );
};

export default UnknownDOBSexModal;
