import { HomeFragmentState } from '~/interfaces/HomeFragmentState';
import { HomeFragmentActions, SAVE_FOLDERS_FILTERS, SaveFolderFilters } from './HomeFragment.actions';

const homeFragmentReducer = (state: HomeFragmentState, action: HomeFragmentActions): HomeFragmentState => {
  switch (action.type) {
    case SAVE_FOLDERS_FILTERS:
      return reducerSaveFolderFilters(state, action);
    default:
      return state;
  }
};

const reducerSaveFolderFilters = (state: HomeFragmentState, { filters }: SaveFolderFilters): HomeFragmentState => ({
  ...state,
  values: {
    ...state.values,
    foldersFilters: filters,
  },
});

export default homeFragmentReducer;
