import { P } from 'ia-react-core';
import styled from 'styled-components';
import Flex from '~/components/Flex';

export const StyledInfoModalFlex = styled(Flex)`
  flex-wrap: wrap;
`;

export const StyledInfoModalFlexBr = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export const StyledInfoModalLogoDesc = styled(P)`
  max-width: 600px;
`;

export const StyledInfoModalLogoContainer = styled(Flex)`
  margin-top: 25px;
`;
