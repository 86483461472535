import React, { FC } from 'react';
import MainLinks from '~/components/MainLinks';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import SaleFileSection from '../../components/SaleFile/SaleFileSection';
import { StyledFoldersPageContainer } from './FoldersPage.styles';

export interface FoldersPageProps { }

const FoldersPage: FC<FoldersPageProps> = () => (
  <StyledFoldersPageContainer>
    <MainLinks />
    <SaleFileSection
      apiUrl={getBffUrlForRoute(BffRoute.user_sales_paged, undefined, false)}
      changeRequestUrl={getBffUrlForRoute(BffRoute.get_change_requests, undefined, false)}
    />
  </StyledFoldersPageContainer>
);

export default FoldersPage;
