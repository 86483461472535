import { SecondaryTextIconButton, media } from 'ia-react-core';
import styled from 'styled-components';

export const StyledNavLink = styled.a<{active?: boolean; fontSize?: number; fontWeight?: number; lineHeight?: number; mr?: number; color?: string; activeBorderBottomSize?: number; activeBorderBottomColor?: string}>`
  height: 100%;
  font-family: 'Montserrat';
  font-size: ${(props) => props.fontSize ?? 17}px;
  font-weight: ${(props) => props.fontWeight ?? 700};
  display: flex;
  align-items: center;
  line-height: ${(props) => props.fontSize ?? 22}px;
  color: ${(props) => props.active ? '#003DA5' : props.color ?? '#6B6B6B'}; 
  border-bottom: ${(props) => `${props.activeBorderBottomSize ?? 4}px solid ${props.active ? (props.activeBorderBottomColor ?? props.theme.colors.blue6) : 'transparent'}`};
  text-decoration: none;
  cursor: pointer;
  &:hover{
    color: '#6B6B6B';
  }
`;

export const StyledTabBar = styled.section`
  background-color: #fff;
  width: 100%;
  height: 56px;    
  text-align: left;

  z-index: 99999;

  @media (max-width: ${({ theme }) => theme?.breakpoints?.MD?.[0]}px) {
    // TODO: Find better way to make nav sticky on mobile and work with the rest of the layout 
    // (Prevent other sticky components from overlapping with this one)
    /* position: sticky;
    top: 0; */
  }

  ${media.xs`
    height: 72px;
    margin-bottom: 20px;
  `}

  ${media.sm`
    margin-bottom: 32px;
  `}

  ${media.md`
    height: 56px;
  `}

  ${media.lg`
    margin-bottom: 32px;
  `}
`;

export const StyledScenarioTabIconButton = styled(SecondaryTextIconButton)`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const StyledScenarioTabContainer = styled.nav`
  display: flex;
  gap: 32px;
  height: 100%;
  align-items: center;

  ${media.xs`
    margin: auto 16px;
  `}

  ${media.sm`
    margin: auto 32px;
  `}
`;

export const StyledScenarioTabMobileContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
