import styled from 'styled-components';

export const StyledCheckboxListWrapper = styled.div`
  label {
    span {
      font-size: 14px !important;
    }
  }
`;

export const StyledYesNoToggleButtonsWrapper = styled.div`
  label {
    span {
      font-size: 14px !important;
    }
  }
  .warning {
    div:first-child  {
    border-color: rgb(226, 195, 0);
    }
  }  
`;
