import React from 'react';
import { ResourceLoader, ResourceLoaderProps } from 'ia-react-core';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { News } from '~/interfaces/LatestNews';

const LOAD_LATEST_NEWS = 'latestNews';

export interface FetchNewsResourceLoaderProps extends ResourceLoaderProps<News> {}

const FetchNewsResourceLoader = (props: FetchNewsResourceLoaderProps) => (
  <ResourceLoader<News>
    name={LOAD_LATEST_NEWS}
    url={getBffUrlForRoute(BffRoute.latest_news, undefined, false)}
    {...props}
  />
);

export default FetchNewsResourceLoader;
