export const MIN_DATE = new Date('1901-01-01');
export const MIN_DATE_DYNAMIC_FORM = new Date('1000-01-01');

const MAX_DATE = new Date();
MAX_DATE.setFullYear(MAX_DATE.getFullYear() + 100);
export { MAX_DATE };

export const IDENTIFICATION_FORM_NAME = 'identification';
export const QUEBEC = 'QUEBEC';
export const ONTARIO = 'ONTARIO';
export const ILE_DU_PRINCE_EDOUARD = 'ILE-DU-PRINCE-EDOUARD';
export const PRINCE_EDWARD_ISLAND = 'PRINCE EDWARD ISLAND';
export const MANITOBA = 'MANITOBA';
export const NOUVEAU_BRUNSWICK = 'NOUVEAU-BRUNSWICK';
export const NEW_BRUNSWICK = 'NEW BRUNSWICK';

export const CANADA = 'CANADA';
export const CarteResidentPermanentFr = 'CARTE DE RÉSIDENT PERMANENT';
export const CarteResidentPermanentEn = 'PERMANENT RESIDENT CARD';

export const NoneOfTheAboveFr = 'Aucun des choix ci-haut';
export const NoneOfTheAboveEn = 'None of the above';
export const HealthInsuranceCardFr = "CARTE D'ASSURANCE MALADIE";
export const HealthInsuranceCardEn = 'HEALTH INSURANCE CARD';

export const DriverLicenseFr = 'Permis de conduire';
export const DriverLicensedEn = 'Driver\'s license';
export const DriverLicensedValue = '9';
export const NoneOfTheAboveValue = '1';
export const QUEBECCODE = 'QC';
export const ONTARIOCODE = 'ON';
export const PRINCE_EDWARD_ISLAND_CODE = 'PE';
export const MANITOBACODE = 'MB';
export const NEW_BRUNSWICK_CODE = 'NB';
export const CANADACODE = 'CAN';
