import React from 'react';
import { Divider } from 'ia-react-core';

import ParametersSection from './components/ParametersSection/ParametersSection';
import RidersSection from './components/RidersSection/RidersSection';
import InsuredPersonDataSection from './components/InsuredPersonDataSection/InsuredPersonDataSection';
import { StyledPCToolFlexContainer } from '../../PremiumComparisonToolModal.styles';

const InsuredPersonsContent = () => (
  <StyledPCToolFlexContainer>
    <InsuredPersonDataSection />
    <Divider />
    <ParametersSection />
    <Divider />
    <RidersSection />
  </StyledPCToolFlexContainer>
);

export default InsuredPersonsContent;
