import React, { FC, ReactNode, useContext, useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ThemeContext, ThemeProvider } from 'styled-components';
import { AppTheme } from '~/interfaces/Theme';
import MainLayout from '../MainLayout';
import { StyledMainHeaderButton, StyledSpacing } from './DynamicFormLayout.styles';

export interface DynamicFormLayoutProps {
  children?: ReactNode;
  onQuitClick: () => void;
}

const DynamicFormLayout: FC<DynamicFormLayoutProps> = ({ children, onQuitClick }) => {
  const { t } = useTranslation('formloader');
  const theme = useContext(ThemeContext);
  const history = useHistory();

  const pageTheme: AppTheme = {
    ...theme,
    layout: {
      ...theme.layout,
      maxContentWidth: 1600,
      maxHeaderContentWidth: 1600,
    },
  };

  const navigateToHome = () => {
    history.push('/');
  };

  useEffect(() => (
    MainLayout.setMainLayoutPropsTunnel({
      noFooterLanguageButton: true,
      contentVerticalPadding: 20,
      maxContentWidth: 1000,
      flexFooterLinks: true,
      headerHeight: 60,
      onMainLogoClick: (e: MouseEvent<HTMLAnchorElement>) => {
        e?.preventDefault();
        navigateToHome();
      },
    })
  ), [navigateToHome]);

  return (
    <ThemeProvider theme={pageTheme}>
      <StyledSpacing m-v={60}>
        <MainLayout.MainHeaderRightContentTunnelEntry>
          <StyledMainHeaderButton onClick={onQuitClick}>{t('quit')}</StyledMainHeaderButton>
        </MainLayout.MainHeaderRightContentTunnelEntry>
        {children}
      </StyledSpacing>
    </ThemeProvider>
  );
};

export default DynamicFormLayout;
