import { InfoTooltip, requestResourceByName, SafeHtml } from 'ia-react-core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QuestionIdentityModel from '~/interfaces/InsurabilityDeclaration/QuestionIdentityModel';
import { ValidationMessageTypes } from '~/interfaces/InsurabilityDeclaration/enums';
import { AppState } from '~/interfaces/AppState';
import isEqual from 'lodash/isEqual';
import SetAnswerRequestDtoBuilder from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/api/builders/SetAnswerRequestDtoBuilder';
import { answerQuestionsSuccessAction } from '~/fragments/SharedLibrary/ChangeRequest.actions';
import FormModel from '~/interfaces/InsurabilityDeclaration/FormModel';
import ValidationMessageModel from '~/interfaces/InsurabilityDeclaration/ValidationMessageModel';
import ExplanatoryTableModel from '~/interfaces/InsurabilityDeclaration/ExplanatoryTableModel';
import ChangeRequestModel from '~/interfaces/InsurabilityDeclaration/ChangeRequestModel';
import selectQuestion from '~/fragments/SharedLibrary/selectors/selectQuestion';
import selectedCase from '../../../../../../../selectors/selectedCase';
import { StyledFieldBox, StyledQuestionContainer, StyledQuestionLabel, StyledQuestionLabelContainer } from './Question.styles';
import ExplanatoryTable from './ExplanatoryTable';
import useQuestionFieldFactory from './QuestionFields/useQuestionFieldFactory';

export interface QuestionProps {
  question: QuestionIdentityModel;
  formId: string;
}

const Question: FC<QuestionProps> = ({ formId, question: questionIdentity }) => {
  const [answerElement, setAnswerElement] = useState<unknown[]>();
  const changeData: ChangeRequestModel = useSelector((state: AppState) => selectedCase(state), (prevData, newData) => isEqual(prevData, newData));

  const changedQuestion = useSelector((state: AppState) => selectQuestion(state, questionIdentity), (prevData, newData) => isEqual(prevData, newData));

  const selectedForm = useMemo(() => changeData?.forms?.find((form: FormModel) => form?.id === formId), [formId, changeData?.forms]);
  const answerData = useSelector((s: AppState) => s.App?.fragments?.ApplicationFragment?.data?.update_answer_question);
  const question = changeData.questions[questionIdentity.id];

  const dispatch = useDispatch();
  const { Field } = useQuestionFieldFactory(question.questionType);
  const caseId = changeData.id;

  useEffect(() => {
    if (answerData && answerElement) {
      dispatch(answerQuestionsSuccessAction(changedQuestion, answerElement, answerData));
    }
  }, [answerData, answerElement, dispatch, changedQuestion]);

  useEffect(() => {
    if (answerData?.length && answerData[0].id === questionIdentity.id && question.canTriggerActive) {
      dispatch(requestResourceByName('resources.change_request', { caseId }));
    }
  }, [answerData, dispatch, caseId, questionIdentity.id, question.canTriggerActive]);

  const handleChange = useCallback(
    (v: unknown[]) => {
      setAnswerElement(v);
      const requestBuilder = new SetAnswerRequestDtoBuilder();
      const body = [{ questions: question, answers: v }]?.map(({ questions, answers }) => requestBuilder.fromValues(questions, answers));
      dispatch(requestResourceByName('resources.update_answer_question', body));
    },
    [question, dispatch],
  );

  const validationMessages = useMemo(() => selectedForm?.validationMessages ?? [], [selectedForm?.validationMessages]);
  const validationMessage = useMemo(() => validationMessages.find((v: ValidationMessageModel) => v.targetItem === questionIdentity.id), [questionIdentity.id, validationMessages]);
  const validationLevel = validationMessage?.validationLevel ?? null;
  const hasError = Boolean((validationLevel === ValidationMessageTypes.Error));
  const hasWarning = Boolean((validationLevel === ValidationMessageTypes.Warning));
  const formattedToolTip = question.toolTip ? question.toolTip.replace(/\\n/g, '\n') : null;
  const props = { disabled: !question.isActive };

  return (
    question.isActive &&
    <StyledQuestionContainer id={`q-${question.name}`}>
      {Boolean(question.title) &&
        <StyledQuestionLabelContainer>
          <StyledQuestionLabel><SafeHtml>{question.title}</SafeHtml></StyledQuestionLabel>
          {Boolean(formattedToolTip) && <InfoTooltip tip={formattedToolTip} />}
        </StyledQuestionLabelContainer>}
      {question.explanatoryTables.map((t: ExplanatoryTableModel) => <ExplanatoryTable key={t.id} table={t} />)}
      <StyledFieldBox>
        <Field question={question} {...props} onChange={handleChange} hasError={hasError} hasWarning={hasWarning} />
      </StyledFieldBox>
    </StyledQuestionContainer>
  );
};

export default Question;
