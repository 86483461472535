import { FC } from 'react';
import { compose } from 'redux';
import { injectTranslations } from 'ia-react-core';
import OptionalLabel, { OptionalLabelProps } from './OptionalLabel';
import fr from './translations/fr';
import en from './translations/en';

export default compose<FC<OptionalLabelProps>>(
  injectTranslations('OptionalLabel', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(OptionalLabel);

export * from './OptionalLabel';
