import { Container, Row } from 'ia-react-core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import SectionIdentityModel from '~/interfaces/InsurabilityDeclaration/SectionIdentityModel';
import { AppState } from '~/interfaces/AppState';
import ChangeRequestModel from '~/interfaces/InsurabilityDeclaration/ChangeRequestModel';
import selectedCase from '~/fragments/SharedLibrary/selectors/selectedCase';
import selectSection from '~/fragments/SharedLibrary/selectors/selectSection';
import Question from './Question';
import './section.css';
import { StyledCol, StyledSafeHtml, StyledSectionT5, StyledSectionTitle } from './Section.styles';
import RepeatingSection from './RepeatingSection';

export interface SectionProps {
  section: SectionIdentityModel;
  formId: string;
}

const Section: FC<SectionProps> = ({ formId, section: sectionIdentity }) => {
  const changeData: ChangeRequestModel = useSelector((state: AppState) => selectedCase(state));
  const section = useSelector(((state: AppState) => selectSection(state, sectionIdentity)));
  const hasName = Boolean(section?.name);

  const questionsList = sectionIdentity.questions.map((q) => (
    <StyledCol key={q.id} xl={changeData.questions[q.id].size?.xl} lg={changeData.questions[q.id].size?.lg} md={changeData.questions[q.id].size?.md} sm={changeData.questions[q.id].size?.sm}>
      <Question key={q.id} formId={formId} question={q} />
    </StyledCol>
  ));

  return (section.isActive && (
    <React.Fragment key={section.id}>
      {hasName && <StyledSectionTitle>{section.name}</StyledSectionTitle>}
      {Boolean(section.title) &&
      <Container m-t={16} p={0}>
        <StyledSectionT5>{section.title}</StyledSectionT5>
      </Container>}
      { section.message && (
        <StyledSafeHtml>{section.message}</StyledSafeHtml>
      )}
      {
        sectionIdentity.questions &&
        <Container fluid={false} p-l={0}>
          <Row>
            {questionsList.map((r) => r)}
          </Row>
        </Container>
      }

      {sectionIdentity.sections.map((s) => (
        <div key={`dv_${s.id}`}>
          <Section key={`s_${s.id}`} formId={formId} section={s} />
        </div>
      ))}

      {sectionIdentity.repeatingSections?.map((rs) => (
        <RepeatingSection formId={formId} key={`rs_${rs.id}`} repeatingSection={rs} />
      ))}

    </React.Fragment>
  ));
};

export default Section;
