import { Resource, ResourceProps, WORKFLOW_STATUSES } from 'ia-react-core';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { useDispatch, useSelector } from 'react-redux';
import { loadApplicationWithoutReloading, setPageValidationMessagesAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';
import selectTargetRoute from '~/fragments/ApplicationFragment/selectors/selectTargetRoute';
import IndividualModel from '~/fragments/ApplicationFragment/interfaces/IndividualModel';
import { ApiResponse } from '~/interfaces/ValidationMessage';
import { AxiosResponse } from 'axios';
import getStatusFromValidationMessages from '~/fragments/ApplicationFragment/utilities/getStatusFromValidationMessages';
import { updateRouteStatus } from '~/App.actions';
import selectCurrentRoute from '~/fragments/ApplicationFragment/selectors/selectCurrentRoute';
import { State } from '~/interfaces/State';
import { UPDATE_INDIVIDUAL_RESOURCE_NAME } from '../constants';
export interface UpdateIndividualResourceProps extends ResourceProps<null, null> {
  saleId: string;
  currentNominee: IndividualModel;
}
const UpdateIndividualResource: FC <UpdateIndividualResourceProps> = ({ saleId, currentNominee }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const targetRoute: string = useSelector(selectTargetRoute);
  const currentRoute: string = useSelector(selectCurrentRoute);
  const backToHome = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);
  let initateFromLeftMenu = true;
  const updateIndividualUrl = currentNominee?.isPerson ?
    getBffUrlForRoute(BffRoute.identitypage_person_saveorvalidate, { saleId }) :
    getBffUrlForRoute(BffRoute.identitypage_organization_saveorvalidate, { saleId });

  const onSuccess = (res: AxiosResponse) => {
    dispatch(loadApplicationWithoutReloading(saleId));
    const { validationMessages } = (res?.data as ApiResponse);
    const messages = validationMessages || [];
    if (backToHome) {
      history.block(true);
      history.push('/');
      return;
    }
    const status = getStatusFromValidationMessages(messages);
    dispatch(updateRouteStatus(currentRoute, status));
    dispatch(setPageValidationMessagesAction('NomineePage', 'identification', messages, 'NomineePage'));
    if (status === WORKFLOW_STATUSES.ERROR) {
      history.push(currentRoute);
    } else if (!initateFromLeftMenu) {
      history.push(targetRoute);
    }
  };
  return (
    <Resource
      name={UPDATE_INDIVIDUAL_RESOURCE_NAME}
      transformRequest={(payloadData: { initateFromLeftMenu: boolean}) => { initateFromLeftMenu = payloadData.initateFromLeftMenu; return payloadData; }}
      url={updateIndividualUrl}
      method="PUT"
      onSuccess={onSuccess}
    />
  );
};

export default UpdateIndividualResource;
