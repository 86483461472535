/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from 'axios';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import logger from '~/utilities/logger';

export default async function getEffectiveDates() {
  const result = await axios.get(getBffUrlForRoute(BffRoute.application_configuration_application_versions_effective_dates));
  if (result.status !== 200) {
    logger.error(`Error while getting app version effective dates: ${result.status} - ${result.statusText}`);
  }
  return result.data;
}
