import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { ZoomContext } from '~/hooks/useZoom';

const MIN_COL_GAP = 16;
const RIGHT_MARGIN = 32;

const MIN_LEFT_COL_WIDTH = 769;
const MIN_RIGHT_COL_WIDTH = 338;
const MIN_CONTENT_WIDTH = MIN_LEFT_COL_WIDTH + MIN_RIGHT_COL_WIDTH + MIN_COL_GAP;
const NAV_WIDTH = 160;

const calculateZoom = () => (
  window.devicePixelRatio === 1.5 &&
  navigator.userAgent.includes('Windows') &&
  window.outerWidth > 1000 &&
  window.outerWidth < 1300
) ? 1.25 : 1;

const setZoomLevel = (zoom: number) => {
  const targetRatio = zoom;

  const ratio = 1 / targetRatio;
  document.body.style.overflow = 'hidden';
  document.body.style.transform = `scale3d(${ratio}, ${ratio}, 1)`;
  document.body.style.transformOrigin = 'top left';
  document.body.style.height = `${targetRatio * 100}vh`;
  document.body.style.width = `${targetRatio * 100}vw`;
};

const ZoomProvider: FC<PropsWithChildren> = ({ children }) => {
  const [zoom, setZoom] = useState(() => calculateZoom());

  useEffect(() => {
    setZoomLevel(zoom);
  }, [zoom]);

  useEffect(() => {
    const onWindowResize = () => {
      const newZoom = calculateZoom();
      setZoom(newZoom);
    };

    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  const value = useMemo(() => {
    const min = Math.floor((MIN_CONTENT_WIDTH + NAV_WIDTH + RIGHT_MARGIN) / zoom);
    const max = Math.floor(MIN_CONTENT_WIDTH + (NAV_WIDTH * 2));

    return ({
      zoom,
      navWidth: NAV_WIDTH,
      minSideNav: min,
      minWidthRequiringOffset: min,
      maxWidthRequiringOffset: max,
    });
  }, [zoom]);

  return (
    <ZoomContext.Provider value={value}>
      {children}
    </ZoomContext.Provider>
  );
};

export default ZoomProvider;
