import {
  Block,
  Col,
  Hidden,
  Row,
  Spacing,
  Visible,
  openDrawerByName,
  useScopedSelector,
} from 'ia-react-core';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditLocationIcon } from '~/assets/Icons/editLocation.svg';
import { ReactComponent as MovingArrowIcon } from '~/assets/Icons/movingArrow.svg';
import { ProductNavigationState } from '~/interfaces/ProductNavigationState';
import '../../IllustrationLayout.css';
import { useDispatch, useSelector } from 'react-redux';
import { IllustrationFragmentState } from '~/interfaces/IllustrationFragmentState';
import { createSelector } from 'reselect';
import { ResponsiveDrawer } from '~/components/ResponsiveDrawer';
import { ResponsiveMoreButton } from '~/components/ResponsiveMoreButton';
import { State } from '~/interfaces/State';
import isHeadOfficeMode from '~/fragments/ApplicationFragment/utilities/isHeadOfficeMode';
import { selectEffectiveDate, selectVersion } from '~/utilities/selectors';
import formatDate from '~/utilities/formatDate.utils';
import useMedia from '~/hooks/useMedia';
import { ButtonLink } from '~/components/ButtonLink';
import { useZoom } from '~/hooks/useZoom';
import IllustrationSideNavigation from '../IllustrationSideNavigation/IllustrationSideNavigation';
import { StyledContainerFluid } from '../../IllustrationLayout.styles';
import {
  StyledCoveragesLinks, StyledCoveragesTitle, StyledCoveragesTitleContainer, StyledCoveragesTitleInnerContainer, StyledFluidContainerCol1, StyledFluidContainerCol2, StyledFluidContainerCol3, StyledFluidContainerColumns,
  StyledVersionInfoSection,
} from './FluidContainer.styles';
import { PREFENTIAL_STATUS_PORTAL_ID } from './components/FluidContainerPreferentialStatusPortal/FluidContainerPreferentialStatusPortal';

export interface FluidContainerProps {
  data: ProductNavigationState[];
  getNavigationSelectLinks: Function;
  mainContentHeader?: string;
  onMarginalRateLinkClicked: () => void;
  onProvinceSelectionLinkClicked: () => void;
  localizedProvinceName: string;
  renderApplicationContent: Function;
  getNavigationLinks: Function;
  renderMainContent: Function;
}

const NAVIGATION_DRAWER_NAME = 'coveragesNavigation';

const selectIllustrationLayouDrawers = (state: IllustrationFragmentState) => Object.values(state?.drawers ?? {});
const selectCoveragesPageDrawers = (state: IllustrationFragmentState) => Object.values(state?.Pages?.CoveragesPage?.drawers ?? {});

const selectDrawers = createSelector([selectIllustrationLayouDrawers, selectCoveragesPageDrawers], (drawers1, drawers2) => drawers1.concat(drawers2));

const FluidContainer: FC<FluidContainerProps> = ({
  mainContentHeader, onMarginalRateLinkClicked, onProvinceSelectionLinkClicked, localizedProvinceName, renderApplicationContent, getNavigationLinks, renderMainContent, data,
}) => {
  const { i18n, t } = useTranslation('FluidContainer');
  const dispatch = useDispatch();
  const drawers = useScopedSelector(selectDrawers);
  const userRole = useSelector((state: State) => state?.App?.data?.permissions?.role);
  const isHeadOffice = useMemo(() => isHeadOfficeMode(userRole), [userRole]);

  const isAnyDrawersOpened = useMemo(() => drawers.some((drawer) => drawer?.isOpened), [drawers]);

  const links = useMemo(() => getNavigationLinks(data, i18n.language), [data, getNavigationLinks, i18n.language]);
  const effectiveDate = useSelector((state: State) => selectEffectiveDate(state)) ?? '';
  const version = useSelector((state: State) => selectVersion(state)) ?? '';
  const versionInfoMsg = effectiveDate && version ? `${formatDate(effectiveDate, i18n.language)} ${t('version')} ${version}` : '';
  const hideContentHeaders = ['Investor profile', 'Investments', 'Concepts', 'Reports', 'Results', 'Résultats', 'Investissements', 'Rapports', 'Profil d\'investisseur'];
  const shouldHideLinksContent = hideContentHeaders.includes(mainContentHeader);

  const { minSideNav, maxWidthRequiringOffset, minWidthRequiringOffset } = useZoom();

  const isMobileNav = useMedia(`(max-width: ${minSideNav - 1}px)`);

  const handleOpenNavigationDrawer = useCallback(() => {
    dispatch(openDrawerByName(`drawers.${NAVIGATION_DRAWER_NAME}`));
  }, [dispatch]);

  const linksContent = shouldHideLinksContent ? (
    <StyledCoveragesLinks>
    </StyledCoveragesLinks>
  ) : (
    <StyledCoveragesLinks>
      <ButtonLink
        RightIcon={MovingArrowIcon}
        onClick={onMarginalRateLinkClicked}
      >
        {t('marginal_rate')}
      </ButtonLink>

      <ButtonLink
        RightIcon={EditLocationIcon}
        onClick={onProvinceSelectionLinkClicked}
      >
        {localizedProvinceName ?? t('province')}
      </ButtonLink>
    </StyledCoveragesLinks>
  );

  return (
    <>
      <ResponsiveDrawer
        name={NAVIGATION_DRAWER_NAME}
        drawerTitle={t('illustration')}
      >
        <IllustrationSideNavigation navigationLinks={links} isDrawer />
      </ResponsiveDrawer>

      {/* Nav is displayed in absolute to force it to stick to the left */}
      {!isMobileNav && (
        <IllustrationSideNavigation navigationLinks={links} />
      )}

      <StyledContainerFluid $isDrawerOpen={isAnyDrawersOpened} $maxWidthRequiringOffset={maxWidthRequiringOffset} $minWidthRequiringOffset={minWidthRequiringOffset}>

        {/* Nav + Main content + Right Content */}
        <StyledFluidContainerColumns>
          <Hidden lg md sm xs>
            {/* Important: Reserve space for absolute Nav */}
            <StyledFluidContainerCol1 />
          </Hidden>

          {/* Main content */}
          <StyledFluidContainerCol2>

            <Spacing m-b={16} p-h-xs={16} p-h-sm={0}>
              <StyledCoveragesTitleContainer>

                {/* Title */}
                <StyledCoveragesTitleInnerContainer>
                  <StyledCoveragesTitle>{mainContentHeader || t('coverages')}</StyledCoveragesTitle>
                  {
                    isHeadOffice && version !== '' && effectiveDate !== '' && (mainContentHeader === t('coverages') || mainContentHeader === undefined || mainContentHeader === '') &&
                    <StyledVersionInfoSection>{versionInfoMsg}</StyledVersionInfoSection>
                  }

                  {/* Responsive Nav Button */}
                  {isMobileNav && (
                    <ResponsiveMoreButton onClick={handleOpenNavigationDrawer} />
                  )}
                </StyledCoveragesTitleInnerContainer>

                {/* Links */}
                <Hidden xs lg xl xxl>
                  {linksContent}
                </Hidden>

                <Hidden xs>
                  <div id={PREFENTIAL_STATUS_PORTAL_ID} />
                </Hidden>
              </StyledCoveragesTitleContainer>

              <Visible xs>
                <Spacing m-v={20}>
                  <div id={PREFENTIAL_STATUS_PORTAL_ID} />
                  {linksContent}
                </Spacing>
              </Visible>
            </Spacing>

            {/* Responsive summary MD */}
            <Hidden xxl xl lg xs>
              <Row m-b={16}>
                <Col>
                  {renderApplicationContent() ?? <Block white></Block>}
                </Col>
              </Row>
            </Hidden>

            {renderMainContent() ?? <Block white></Block>}
          </StyledFluidContainerCol2>

          {/* Right Summary */}
          <Hidden md sm xs>
            <StyledFluidContainerCol3>
              <Spacing m-b={16}>
                {linksContent}
              </Spacing>

              {renderApplicationContent() ?? <Block white></Block>}
            </StyledFluidContainerCol3>
          </Hidden>
        </StyledFluidContainerColumns>
      </StyledContainerFluid>
    </>
  );
};

export default FluidContainer;
