import { AppState } from './interfaces/AppState';
import {
  CLEAR_COVERAGES, ClearCoveragesAction, DISABLE_PC_TOOL_FORM, DisablePcToolFormAction, SET_ROUTES, SetRoutesAction, UPDATE_ROUTE_STATUS, UpdateRouteStatusAction, SET_APP_VERSIONING, SetAppVersioningAction,
} from './App.actions';
import { GlobalAction } from './interfaces/GlobalAction';
import getClientIdFromRoute from './fragments/ApplicationFragment/utilities/getClientIdFromRoute';

const appReducer = (state: AppState, action: GlobalAction): AppState => {
  switch (action.type) {
    case CLEAR_COVERAGES: {
      const { payload } = action as ClearCoveragesAction;
      return {
        ...state,
        fragments: {
          ...state.fragments,
          ApplicationFragment: {
            ...state.fragments?.ApplicationFragment,
            values: {
              ...state.fragments?.ApplicationFragment?.values,
              showCoverage: {
                ...state.fragments?.ApplicationFragment?.values?.showCoverage,
                coverage: payload.showCoverage,
              },
            },
          },
        },
      };
    }
    case SET_ROUTES: {
      const { payload } = action as SetRoutesAction;
      return {
        ...state,
        fragments: {
          ...state.fragments,
          ApplicationFragment: {
            ...state.fragments?.ApplicationFragment,
            routes: payload.routes,
          },
        },
      };
    }
    case UPDATE_ROUTE_STATUS: {
      const { payload } = action as UpdateRouteStatusAction;

      const updatedRoutes = state.fragments?.ApplicationFragment?.routes.map((route: any) => {
        if (route.routes) {
          return {
            ...route,
            routes: route.routes.map((nestedRoute: any) => {
              if ((nestedRoute?.path?.en?.endsWith(payload.path) && (getClientIdFromRoute(nestedRoute?.path?.en) === getClientIdFromRoute(payload.path))) || (nestedRoute?.path?.fr?.endsWith(payload.path) && (getClientIdFromRoute(nestedRoute?.path?.fr) === getClientIdFromRoute(payload.path)))) {
                return { ...nestedRoute, status: payload.status };
              }
              return nestedRoute;
            }),
          };
        }
        if (route?.path?.en?.endsWith(payload.path) || route?.path?.fr?.endsWith(payload.path)) {
          return { ...route, status: payload.status };
        }
        return route;
      });
      const newState = {
        ...state,
        fragments: {
          ...state.fragments,
          ApplicationFragment: {
            ...state.fragments?.ApplicationFragment,
            routes: updatedRoutes,
          },
        },
      };
      return newState;
    }
    case DISABLE_PC_TOOL_FORM: {
      const { payload } = action as DisablePcToolFormAction;
      return {
        ...state,
        values: {
          ...state?.values,
          isPcToolFormDisabled: payload.isPcToolFormDisabled,
        },
      };
    }
    case SET_APP_VERSIONING: {
      const { payload } = action as SetAppVersioningAction;
      return {
        ...state,
        values: {
          ...state?.values,
          appVersion: {
            ...state?.values?.appVersion,
            version: payload.version,
            effectiveDate: payload.effectiveDate,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default appReducer;
