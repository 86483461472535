export default {
  illustration_configuration: 'Illustrations configuration',
  province: 'Province',
  premiums_payment_frequency: 'Premiums payment frequency',
  monthly: 'Monthly',
  annually: 'Annually',
  compensation_bonus: 'Compensation bonus',
  save: 'Save',
  cancel: 'Cancel',
  bonus_info: 'The compensation bonus percentage indicated in this field will be used in the illustration compensation report. This percentage is added to the first-year commission for each coverage.',
  configuration_note: 'The value indicated in this field is for information purposes only. It does not necessarily represent the compensation bonus percentage that will be attributed to you.',
  ok: 'OK',
  error: 'ERROR!',
};
