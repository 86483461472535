import { injectReducer, injectTranslations } from 'ia-react-core';
import { FC } from 'react';
import { compose } from 'redux';

import { HomeFragmentState } from '~/interfaces/HomeFragmentState';

import HomeFragment, { HomeFragmentProps } from './HomeFragment';
import { HomeFragmentActions } from './HomeFragment.actions';
import homeFragmentReducer from './HomeFragment.reducer';

const initialState = {};

export default compose<FC<HomeFragmentProps>>(
  injectTranslations('HomeFragment', {}),
  injectReducer<HomeFragmentProps, HomeFragmentState, HomeFragmentActions>({
    key: 'fragments.HomeFragment',
    reducer: homeFragmentReducer,
    initialState,
    useScope: true,
    cleanState: true,
  }),
)(HomeFragment);
