import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { State } from '~/interfaces/State';

interface RouteGuardProps {
  children: ReactElement;
  requiredPermission: string[];
}

const RouteGuard: FC<RouteGuardProps> = ({ requiredPermission, children }) => {
  const userPermissions = useSelector((state: State) => state?.App?.data?.permissions?.permissions);
  const rolePermissions = useSelector((state: State) => state?.App?.Pages?.RolesPage?.data?.roles?.permissions);

  const finalPermission = rolePermissions !== undefined ? rolePermissions : userPermissions;
  const hasPermission = requiredPermission?.some((permission) => finalPermission?.includes(permission));
  if (hasPermission) {
    // Check if HomePage.Read permission is present
    const isReadOnly = finalPermission?.includes('.Read');
    // Pass the readOnly prop to children if HomePage.Read is present
    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, { readOnly: isReadOnly }));
    return <>{childrenWithProps}</>;
  }
  return <Redirect to="/" />;
};
export default RouteGuard;
