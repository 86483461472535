import { Reducer } from 'redux';
import { SaleFileState } from '../interfaces/SaleFileState';
import { ActionType } from './SaleFileDataTable.actions';

export const saleFileDataTableReducer: Reducer<SaleFileState> = (
  state,
  action,
) => {
  switch (action.type) {
    case ActionType.CLEAR_FILTER: {
      return {
        ...state,
        values: {
          ...state.values,
          filters: {
            search: '',
            statusFilter: 0,
          },
        },
      };
    }
    case ActionType.RESET_PAGINATION: {
      return {
        ...state,
        paginations: {
          mySaleFiles: {
            currentPage: 1,
          },
        },
      };
    }
    case ActionType.SET_FILTER: {
      return {
        ...state,
        values: {
          ...state.values,
          filters: {
            search: '',
            statusFilter: action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
};
