import { TFunction } from 'i18next';
import { InforceInsuranceType } from '~/interfaces/InsuranceHistoryPageState';

const getCompanyLabel = (data: InforceInsuranceType, t: TFunction) => {
  if (data.isContractIA) {
    return t('inforceInsurance.form.isContractIAOptions.withIA');
  }

  return t('inforceInsurance.form.isContractIAOptions.withAnother');
};

export default getCompanyLabel;
