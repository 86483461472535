export enum IllustrationHelpFilePath {
  TEMPORAIRETRAD_EN = 'TemporaireTrad_En.1b073a373a4187a36963a46c30177327f23f18e92f30b02f779a8e5c549c8215.html',
  TEMPORAIRETRAD_FR = 'TemporaireTrad_Fr.24bf9851b4a6122ee852a477be8c9ec0b2dea4ca78e7839efcf2b773685bf385.html',
  PARWEALTHESTATE_EN = 'PARWealthEstate_En.9851b04c57bef133b06ee123069595cf0e54f9f346aa0825c445bc84b01b0e18.html',
  PARWEALTHESTATE_FR = 'PARWealthEstate_Fr.7c9a614125de1babeedceedcfff0a94e375cb5d57b9e92bf7f01910a41fa7ce1.html',
  SPECIALISEE_EN = 'Specialisee_En.958d90ca11a210f70512dd5db00a5f5aba8a3acc4e0109051be29879a0bf71fc.html',
  SPECIALISEE_FR = 'Specialisee_Fr.6a5dfe2a105428cdcfab9f0494c0da8db2a3da247ef340d531c8b56a1c6b610b.html',
  MALADIEGRAVE_EN = 'MaladieGrave_En.cd559ff3ae9d63330acc900d5c9c35026099b2c71819aef19465185a16a83e30.html',
  MALADIEGRAVE_FR = 'MaladieGrave_Fr.b15ef86957f603a8a6453f0a8b4ebb50932a6256c7d081f3d835cb33ff487ee2.html',
  MULTITERME_EN = 'Multiterme_En.e8a63c73c3f000cfa4b6520e3aa08d479fca76b0b5647f36edc1d69fbb395ef7.html',
  MULTITERME_FR = 'Multiterme_Fr.0c6442b60cd67b2849d95b356d4b3c57ddef78fef9adbcb3dd339909e274f147.html',
  CONCEPTS_EN = 'Concepts_En.d2808c130b57cc1f9ef520ef2ec35117c01a91deb56305bfd41e64b0b7219b1a.html',
  CONCEPTS_FR = 'Concepts_Fr.22359adb10b7ed94f3dd39f5efe0a3dcbf76c54c235eec394bdb28433bbcb1e3.html',
  HYBRIDETRAD_EN = 'HybrideTrad_En.381c08ae568faf3a1e52bd5028e9605b416f5528d17419eef1e493b9197f1b28.html',
  HYBRIDETRAD_FR = 'HybrideTrad_Fr.31ad620851eae7d8c49c83043480f2a082f7e4ce43f3bf6714db08ca186edd5d.html',
  PAR_EN = 'PAR_En.2f44f8f381463df65eee838b83b9968b93330e812c948a28ece315df97bc7665.html',
  PAR_FR = 'PAR_Fr.db7e642ff52d5608b33fa624db95addd01702694feab04e77f1a8ad872671948.html',
  PARESTATE_EN = 'PAREstate_En.f10822e01828cfcc245407168dc0ad5ef888443ba803346345210e8f651ea223.html',
  PARESTATE_FR = 'PAREstate_Fr.f9ccb68fb0b161d0f6cbf822a3e238489e141039b8c9a0384ce8c63192f42fb0.html',
  GENESIS_EN = 'Genesis_En.5a0a9b00af495ef9458e145857c4f9bfaa6f74d83cbecd382ae7a2e7485c91de.html',
  GENESIS_FR = 'Genesis_Fr.dfc5edfee6bfc978445a1a80a5dd7369d5a55147a1f081b7f1caab1c49d7d038.html',
  PRODUITSSIMPLIFIES_EN = 'ProduitsSimplifies_En.c993a58d034485ce102da4a0349d7b72d48e233938261808ac66d6d2b6643000.html',
  PRODUITSSIMPLIFIES_FR = 'ProduitsSimplifies_Fr.7b9981a0ca00dae042328b5c7bb78342a1f1baef2d812bf69e7dad6eb6c1be64.html',
  PERMANENTTRAD_EN = 'PermanentTrad_En.257c4e0b119a03b6195b437ad2131cf7926ae0eafb00a6307b430c1454f1f5d0.html',
  PERMANENTTRAD_FR = 'PermanentTrad_Fr.89558737b83fd9a16cb5b97e0f9b1a85f6d947a76516ccfc4edeb91d8e1d9020.html',
  PRODUITSCONCEPTS_EN = 'ProduitsConcepts_En.2c03ec1f86111f8d5ef3b21fc6c3485f3486d76dbcade9f16a70831bf11f909e.html',
  PRODUITSCONCEPTS_FR = 'ProduitsConcepts_Fr.d6eeb16aaafd04d5cbfc69c5cdb172628234cdb9f924559db3ede2a1846ba449.html'
}
