import styled from 'styled-components';
import { WarningIcon } from 'ia-react-core';

export const StyledIcon = styled(WarningIcon)`
  width: 60px;
  height: 60px;
  color: #EF6D19;
`;

export const StyledIconContainer = styled.div`
  width: 60px;
  margin: 0 auto;
  padding-bottom: 18px;
`;

export const StyledDeleteSalesFileModalDescription = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  white-space: pre-line;
`;
