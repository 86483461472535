import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { LoadApplicationSuccess } from '../ApplicationFragment.actions';

export default function loadApplicationSuccessReducer(state: ApplicationFragmentState, action: LoadApplicationSuccess): ApplicationFragmentState {
  const { application } = action;
  return {
    ...state,
    ...application as unknown as ApplicationFragmentState,
    isLoading: false,
    initialLoad: false,
  };
}
