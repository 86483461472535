import { put, call } from 'redux-saga/effects';
import { Action } from 'redux';
import { AxiosResponse } from 'axios';
import { AddSettingsAdvisorAction, editSettingsAdvisorSuccessAction, fatalError, setAdvisorToEditAction, setDuplicationCodeErrorAction, setLoadingAction, switchViewAction } from '../AdvisorsModal.actions';
import { updateSettingsAdvisor } from '../api/updateSettingsAdvisor';
import { AdvisorModalView } from '../constants';

export function* updateAdvisorSaga(action: Action) {
  const { advisor } = action as AddSettingsAdvisorAction;
  yield put(setLoadingAction(true));
  try {
    yield call(updateSettingsAdvisor, advisor);

    yield put(editSettingsAdvisorSuccessAction(advisor));
    yield put(setAdvisorToEditAction(null));
    yield put(switchViewAction(AdvisorModalView.Table));
  } catch (error) {
    if ((error as { response: AxiosResponse })?.response?.status === 409) {
      yield put(setDuplicationCodeErrorAction(true));
    }

    yield put(fatalError('Error while creating an advisor', error));
  } finally {
    yield put(setLoadingAction(false));
  }
}
