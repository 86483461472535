import React, { ReactElement } from 'react';
import { EditIcon, DeleteIcon, Tooltip, IconButton, MoreHorizIcon } from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FolderIcon } from '~/assets/Icons/folder.svg';
import { ReactComponent as ShareIcon } from '~/assets/Icons/share.svg';
import { ReactComponent as DisabledShareIcon } from '~/assets/Icons/disabledShare.svg';
import { SectionDivider } from '~/components/SectionDivider';
import { ReactComponent as ReplyIcon } from 'src/assets/Icons/reply.svg';
import { SaleFile } from '../interfaces/SaleFile';
import {
  StyledFileActionsCellContainer, StyledFileActionsCellIconButton, StyledFileActionsCellMenuContainer, StyledFileActionsCellMenuInnerContainer, StyledFileActionsCellMenuItemContainer, StyledFileActionsCellMenuItemDescription, StyledFileActionsCellMenuItemDividerContainer, StyledFileActionsCellMenuItemValue, StyledFileActionsCellsMenuArrow,
} from './FileActionsCell.styles';

export interface FileActionsCellProps {
  saleFile: SaleFile;
  saleCompleted: boolean;
  isCompact: boolean;
  openRenameModal: (saleFile: SaleFile) => void;
  openDeleteModal: (saleFile: SaleFile) => void;
  openFileSharingModal: (saleFile: SaleFile) => void;
  openSaveModal?: (saleFile: SaleFile) => void;
}

const FileActionsCell = ({ saleFile, isCompact, saleCompleted = false, openRenameModal, openDeleteModal, openFileSharingModal, openSaveModal }: FileActionsCellProps) => {
  const { t } = useTranslation('SaleFileDataTable');
  let iconElement;
  if (isCompact) {
    const items: ReactElement[] = [];
    if (saleFile?.numeroContrat) {
      items.push(
        <StyledFileActionsCellMenuItemContainer>
          <StyledFileActionsCellMenuItemDescription>{t('case')}</StyledFileActionsCellMenuItemDescription>
          <StyledFileActionsCellMenuItemValue>{saleFile?.numeroContrat}</StyledFileActionsCellMenuItemValue>
        </StyledFileActionsCellMenuItemContainer>,
      );
    }

    items.push(
      <StyledFileActionsCellMenuItemContainer>
        <StyledFileActionsCellMenuItemDescription>{t('shared')}</StyledFileActionsCellMenuItemDescription>
        <ReplyIcon />
      </StyledFileActionsCellMenuItemContainer>,
    );
    items.push(
      <StyledFileActionsCellMenuItemDividerContainer>
        <SectionDivider />
      </StyledFileActionsCellMenuItemDividerContainer>,
    );
    items.push(
      <StyledFileActionsCellMenuItemContainer>
        <StyledFileActionsCellMenuItemDescription $link>{t('shareCase')}</StyledFileActionsCellMenuItemDescription>
        <StyledFileActionsCellsMenuArrow />
      </StyledFileActionsCellMenuItemContainer>,
    );
    items.push(
      <StyledFileActionsCellMenuItemContainer onClick={() => openDeleteModal(saleFile)}>
        <StyledFileActionsCellMenuItemDescription $link>{t('delete')}</StyledFileActionsCellMenuItemDescription>
        <StyledFileActionsCellsMenuArrow />
      </StyledFileActionsCellMenuItemContainer>,
    );

    if (!saleCompleted) {
      items.splice(
        3,
        0,
        <StyledFileActionsCellMenuItemContainer onClick={() => openRenameModal(saleFile)}>
          <StyledFileActionsCellMenuItemDescription $link>{t('rename')}</StyledFileActionsCellMenuItemDescription>
          <StyledFileActionsCellsMenuArrow />
        </StyledFileActionsCellMenuItemContainer>,
      );
    }
    if (saleCompleted) {
      items.splice(
        1,
        0,
        <StyledFileActionsCellMenuItemContainer onClick={() => openSaveModal(saleFile)}>
          <StyledFileActionsCellMenuItemDescription $link>{t('save')}</StyledFileActionsCellMenuItemDescription>
          <StyledFileActionsCellsMenuArrow />
        </StyledFileActionsCellMenuItemContainer>,
      );
    }

    const tip = (
      <StyledFileActionsCellMenuInnerContainer>
        {items}
      </StyledFileActionsCellMenuInnerContainer>
    );

    iconElement = (
      <StyledFileActionsCellMenuContainer>
        <Tooltip trigger="click" tip={tip} placement="bottom-end" tooltipPadding={0}>
          <IconButton Icon={MoreHorizIcon} />
        </Tooltip>
      </StyledFileActionsCellMenuContainer>
    );
  } else {
    if (saleFile.estPartageable) {
      iconElement = (
        <StyledFileActionsCellIconButton
          onClick={() => openFileSharingModal(saleFile)}
          Icon={ShareIcon}
          label={t('share')}
        />
      );
    } else {
      iconElement = <DisabledShareIcon />;
    }
    iconElement = (
      <StyledFileActionsCellContainer>
        {iconElement}
        {!saleCompleted &&
          <StyledFileActionsCellIconButton
            onClick={() => openRenameModal(saleFile)}
            Icon={EditIcon}
            label={t('rename')}
          />}
        {
          saleCompleted &&
          <StyledFileActionsCellIconButton
            onClick={() => openSaveModal(saleFile)}
            Icon={FolderIcon}
            label={t('saveFileModal.save')}
          />
        }
        <StyledFileActionsCellIconButton
          onClick={() => openDeleteModal(saleFile)}
          Icon={DeleteIcon}
          label={t('delete')}
        />
      </StyledFileActionsCellContainer>
    );
  }
  return <>{iconElement}</>;
};
export { FileActionsCell };
