import { RouteDefinition, RouteDefinitions, RouteSectionDefinition } from 'ia-react-core';
import NOMINEE_ROUTES from './NOMINEE_ROUTES';
import SIGNATURE_ROUTES from './SIGNATURE_ROUTES';
import CHANGES_ROUTES from './CHANGES_ROUTES';
import SIGNATURE_ROUTES_ECHANGE from './SIGNATURE_ROUTES_ECHANGE';
import APPLICATION_ECHANGE_ROUTES from './APPLICATION_ECHANGE_ROUTES';

export const APPLICATION = 'application';

export enum APPLICATION_ROUTES_KEY {
  SUMMARY = 'summary',
  ADVISOR = 'advisor',
  NOMINEE = 'nominee',
  APPLICANT = 'applicant',
  BILLING = 'billing',
  PAC_PAD_AGREEMENT = 'pac-pad-agreement',
  COVERAGES = 'coverages',
  REQUIREMENTS = 'requirements',
  LINKED_APPLICATION = 'linked-applications',
  VALIDATION = 'validation',
  SIGNATURE_PAGE = 'signaturePage',
  HEADOFFICE= 'headoffice',
  TRANSMISSION = 'transmission',
  REINSTATEMENT = 'reinstatement',
  VALIDATION_ECHANGE = 'validation-echange',
  SIGNATURE_PAGE_ECHANGE = 'signaturePage-echange',
  ECHANGES = 'echanges',
  TRANSMISSION_ECHANGE = 'transmission-echange',
  APPLICATIONS = 'applications',
}

export const applicationRoutesRecords: Record<APPLICATION_ROUTES_KEY, RouteSectionDefinition | RouteDefinition> = {
  [APPLICATION_ROUTES_KEY.SUMMARY]: {
    label: { fr: 'Résumé', en: 'Summary' },
    path: { fr: '/application/:saleId/resume', en: '/application/:saleId/summary' },
    loader: () => import('../fragments/ApplicationFragment/pages/SummaryPage'),
  },
  [APPLICATION_ROUTES_KEY.ADVISOR]: {
    label: { fr: 'Conseiller(s)', en: 'Advisor(s)' },
    path: { fr: '/application/:saleId/conseiller', en: '/application/:saleId/advisor' },
    loader: () => import('../fragments/ApplicationFragment/pages/AdvisorPage'),
  },
  [APPLICATION_ROUTES_KEY.NOMINEE]: {
    label: { fr: 'Nominee', en: 'Candidat' },
    path: { fr: '/application/:saleId/candidat', en: '/application/:saleId/nominee' },
    loader: () => import('../fragments/ApplicationFragment/pages/NomineePage'),
    routes: NOMINEE_ROUTES,
  },
  [APPLICATION_ROUTES_KEY.APPLICANT]: {
    label: { fr: 'Contractant', en: 'Applicant' },
    path: { fr: '/application/:saleId/contractant', en: '/application/:saleId/applicant' },
    loader: () => import('../fragments/ApplicationFragment/pages/ApplicantPage'),
  },
  [APPLICATION_ROUTES_KEY.BILLING]: {
    label: { fr: 'Facturation', en: 'Billing' },
    path: { fr: '/application/:saleId/facturation', en: '/application/:saleId/billing' },
    loader: () => import('../fragments/ApplicationFragment/pages/BillingPage'),
  },
  [APPLICATION_ROUTES_KEY.HEADOFFICE]: {
    label: { fr: 'Siège social', en: 'Head office' },
    path: { fr: '/application/:saleId/social', en: '/application/:saleId/headoffice' },
    loader: () => import('../fragments/ApplicationFragment/pages/HeadOfficePage'),
  },
  [APPLICATION_ROUTES_KEY.PAC_PAD_AGREEMENT]: {
    label: { fr: 'Entente PAC/DPA', en: 'PAC/PAD Agreement' },
    path: { fr: '/application/:saleId/accord-pac-pad', en: '/application/:saleId/pac-pad-agreement' },
    loader: () => import('../fragments/ApplicationFragment/pages/PacPadAgreementPage'),
  },
  [APPLICATION_ROUTES_KEY.COVERAGES]: {
    label: { fr: 'Protection(s)', en: 'Coverage(s)' },
    path: { fr: '/application/:saleId/protections', en: '/application/:saleId/coverages' },
    loader: () => import('../fragments/ApplicationFragment/pages/CoveragesPage'),
  },
  [APPLICATION_ROUTES_KEY.REQUIREMENTS]: {
    label: { fr: 'Exigences', en: 'Requirements' },
    path: { fr: '/application/:saleId/exigences', en: '/application/:saleId/requirements' },
    loader: () => import('../fragments/ApplicationFragment/pages/RequirementsPage'),
  },
  [APPLICATION_ROUTES_KEY.LINKED_APPLICATION]: {
    label: { fr: 'Polices liées', en: 'Linked Applications' },
    path: { fr: '/application/:saleId/applications-liees', en: '/application/:saleId/linked-applications' },
    loader: () => import('../fragments/ApplicationFragment/pages/LinkedApplicationsPage'),
  },
  [APPLICATION_ROUTES_KEY.VALIDATION]: {
    label: { fr: 'Validation', en: 'Validation' },
    path: { fr: '/application/:saleId/validation', en: '/application/:saleId/validation' },
    loader: () => import('../fragments/ApplicationFragment/pages/ValidationPage'),
  },
  [APPLICATION_ROUTES_KEY.SIGNATURE_PAGE]: {
    label: { fr: 'Signature', en: 'Signature' },
    path: { fr: '/application/:saleId/signature', en: '/application/:saleId/signature' },
    loader: () => import('../fragments/ApplicationFragment/pages/SignaturePage'),
    name: APPLICATION_ROUTES_KEY.SIGNATURE_PAGE,
    routes: SIGNATURE_ROUTES,
  },
  [APPLICATION_ROUTES_KEY.TRANSMISSION]: {
    label: { fr: 'Transmission', en: 'Transmission' },
    path: { fr: '/application/:saleId/transmission', en: '/application/:saleId/transmission' },
    loader: () => import('../fragments/ApplicationFragment/pages/TransmissionPage'),
  },
  [APPLICATION_ROUTES_KEY.REINSTATEMENT]: {
    label: { en: 'Reinstatement', fr: 'Remise en vigueur' },
    path: { fr: '/application/:saleId/reinstatement', en: '/application/:saleId/reinstatement' },
    loader: () => import('../fragments/ChangeFragment/pages/EChangePage/pages/Reinstatement'),
  },
  [APPLICATION_ROUTES_KEY.ECHANGES]: {
    label: { en: 'E-Change', fr: 'Changement Électronique' },
    path: { fr: '/application/:saleId/echange', en: '/application/:saleId/echange' },
    loader: () => import('../fragments/ChangeFragment/pages/EChangePage'),
    name: APPLICATION_ROUTES_KEY.ECHANGES,
    routes: CHANGES_ROUTES,
  },
  [APPLICATION_ROUTES_KEY.APPLICATIONS]: {
    label: { en: 'Application(s)', fr: 'Propositions(s)' },
    path: { fr: '/application/:saleId/candidat', en: '/application/:saleId/nominee' },
    loader: () => import('../fragments/ApplicationFragment/pages/NomineePage'),
    name: APPLICATION_ROUTES_KEY.APPLICATIONS,
    routes: APPLICATION_ECHANGE_ROUTES,
  },
  [APPLICATION_ROUTES_KEY.VALIDATION_ECHANGE]: {
    label: { en: 'Validation', fr: 'Validation' },
    path: { en: '/application/:saleId/validationechange', fr: '/application/:saleId/validationchangement' },
    loader: () => import('../fragments/ChangeFragment/pages/Validation'),
  },
  [APPLICATION_ROUTES_KEY.SIGNATURE_PAGE_ECHANGE]: {
    label: { fr: 'Signature', en: 'Signature' },
    path: { fr: '/application/:saleId/signaturechangement', en: '/application/:saleId/signatureechange' },
    loader: () => import('../fragments/ChangeFragment/pages/SignaturePage'),
    name: APPLICATION_ROUTES_KEY.SIGNATURE_PAGE_ECHANGE,
    routes: SIGNATURE_ROUTES_ECHANGE,
  },
  [APPLICATION_ROUTES_KEY.TRANSMISSION_ECHANGE]: {
    label: { en: 'Transmission', fr: 'Transmission' },
    path: { fr: '/application/:saleId/transmission', en: '/application/:saleId/transmission' },
    loader: () => import('../fragments/ApplicationFragment/pages/TransmissionPage'),
  },
};

const APPLICATION_ROUTES: RouteDefinitions = Object.values(applicationRoutesRecords);

export default APPLICATION_ROUTES;
