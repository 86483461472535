import { Block, ErrorMessage, FormCol, FormRow, IconButton, InfoMessage, T4 } from 'ia-react-core';
import styled from 'styled-components';
import { BorderBlock } from '~/components/BorderBlock';

export const StyledInfoMessage = styled(InfoMessage)`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 24px !important;
  margin-top: 10 !important;
   width: -webkit-fill-available;
`;
export const StyledBlock = styled(Block)`
margin-top: 24px;
padding: 24px 16px !important;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  svg {
    margin-right: 5px;
  }
`;
export const StyledInsuredDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left:7px;
  h6 {
    margin: 0 !important;
  }
`;

export const StyledT4 = styled(T4)`
  font-size: 14px;
  display: flex;
  align-content: center;
  line-height: 19px;
  margin: 0 !important;
`;

export const StyledInsuredBlock = styled(BorderBlock)`
  border: 1px solid #D3D8DE;
  gap: 13px;
`;

export const StyledInsuredRow = styled(FormRow)`
  padding-left: 10px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0 !important;
`;
export const StyledFormCol = styled(FormCol)`
float: left;
div { margin-bottom: 0 !important; }
margin-bottom: 0 !important;
  button {
    width: 10px;
    &:last-child {
      margin-left: 0px !important;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
padding-left: 0px;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 20px;
`;
