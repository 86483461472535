import { SecondaryTextIconButton } from 'ia-react-core';
import styled from 'styled-components';
import { ReactComponent as PrintButtonIcon } from '~/assets/Icons/print.svg';

export const StyledSaleFileNameContainer = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  max-width: 100%;
`;

export const StyledSaleFileName = styled.span`
  margin-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
`;

export const StyledPrintIcon = styled(PrintButtonIcon)`
fill: ${({ theme }) => theme.colors.light};
`;

export const StyledSecondaryTextIconButton = styled(SecondaryTextIconButton)`
white-space: wrap;
text-align: left;
`;
