export const prefix = 'ChangeRequest';
export const LoadChangeRequestType = `${prefix}.LoadChangeRequest`;
export const LoadChangeRequestSuccessType = `${prefix}.LoadChangeRequestSuccess`;
export const LoadChangeRequestFormsType = `${prefix}.LoadChangeRequestForms`;
export const LoadChangeRequestFormsSuccessType = `${prefix}.LoadChangeRequestFormsSuccess`;
export const LoadChangeRequestSummarySuccessType = `${prefix}.LoadChangeRequestSummarySuccess`;
export const LoadChangeRequestFormByIdType = `${prefix}.LoadChangeRequestFormById`;
export const DeleteChangeRequestFormByIdType = `${prefix}.DeleteChangeRequestFormById`;
export const UpdateCaseDataType = `${prefix}.UpdateCaseData`;
export const SaveChangeRequestFormType = `${prefix}.SaveChangeRequestFormType`;
export const DeleteInstanceSectionSuccessType = `${prefix}.DeleteInstanceSectionSuccessType`;
export const CreateRepeatingSectionSuccessType = `${prefix}.CreateRepeatingSectionSuccessType`;
export const AnswerQuestionsSuccessType = `${prefix}.AnswerQuestionsSuccessType`;
export const SetCurrentTab = `${prefix}.SetCurrentTab`;
export const SetInsured = `${prefix}.SetInsured`;
export const ClearInsured = `${prefix}.ClearInsured`;
export const SetInsuredAddMode = `${prefix}.SetInsuredAddMode`;
export const SetProduct = `${prefix}.SetProduct`;
export const SetFormId = `${prefix}.SetFormId`;
export const SetUseTobacco = `${prefix}.SetUseTobacco`;
export const SetPlanCode = `${prefix}.SetPlanCode`;
export const SetCoveragePlan = `${prefix}.SetCoveragePlan`;
export const ClearCoveragePlan = `${prefix}.ClearCoveragePlan`;
export const SetIsEditCover = `${prefix}.SetIsEditCover`;
export const SetIsEditProduct = `${prefix}.SetIsEditProduct`;
export const SetAdditionalCoverage = `${prefix}.SetAdditionalCoverage`;
export const SetCoverage = `${prefix}.SetCoverage`;
export const UpdateSignatureConfigureType = `${prefix}.UpdateSignatureConfigure`;
export const UpdateSignaturePaperFormType = `${prefix}.UpdateSignaturePaperForm`;
export const UpdateSignatureElectronicFormType = `${prefix}.UpdateSignatureElectronicForm`;
export const StartCeremonyFormType = `${prefix}.startCeremonyFormAction`;
export const UpdateAdditionalFormSignerFullNameType = `${prefix}.UpdateSignatureAdditionalForm`;
export const AddMainSignerRoleType = `${prefix}.AddMainSignerRoleType`;
export const DeleteMainSignerRoleType = `${prefix}.DeleteMainSignerRoleType`;
export const AddAdditionalSignerRoleType = `${prefix}.AddAdditionalSignerRoleType`;
export const DeleteAdditionalSignerRoleType = `${prefix}.DeleteAdditionalSignerRoleType`;
export const LoadApplicationIndividuals = `${prefix}.LoadApplicationIndividuals`;
export const UpdateRenderCheckBoxStatus = `${prefix}.UpdateRenderCheckBoxStatus`;
export const FormSelectionErrorType = `${prefix}.FormSelectionErrorType`;
