import React, { useMemo } from 'react';
import { MIN_DATE_DYNAMIC_FORM, MAX_DATE } from '~/fragments/ApplicationFragment/pages/NomineePage/pages/IdentificationPage/Identity/IdentityConstants';
import { Datepicker } from 'ia-react-core';
import { DateTime } from 'luxon';
import { Locale, useCurrentLocale } from '~/hooks/useCurrentLocale';
import { QuestionDisplayType } from '~/interfaces/InsurabilityDeclaration/enums';
import QuestionField from './QuestionField';

const DateField: QuestionField = ({ question, onChange, hasError, hasWarning, ...props }) => {
  const value = useMemo(() => new Date(question.answers[0]?.value as string) || null, [question.answers]);
  const locale = useCurrentLocale();

  const showYearPicker = useMemo(() => question.displayType === QuestionDisplayType.YearOnly, [question.displayType]);
  const showMonthYearPicker = useMemo(() => question.displayType === QuestionDisplayType.MonthYear, [question.displayType]);

  const dateFormat = useMemo(() => {
    let format = '';
    switch (question.displayType) {
      case QuestionDisplayType.MonthYear:
        format = locale === Locale.EN ? 'yyyy-MM' : 'yyyy-MM';
        break;
      case QuestionDisplayType.YearOnly:
        format = 'yyyy';
        break;
      default:
        format = locale === Locale.EN ? 'MM-dd-yyyy' : 'dd-MM-yyyy';
        break;
    }
    return format;
  }, [question.displayType]);

  const handleChange = (date: Date | string) => {
    if (date !== 'Invalid Date' && date !== '' && date !== 'Invalid DateTime') {
      onChange([DateTime.fromJSDate(new Date(date)).toISO()]);
    }
  };

  return (
    <Datepicker
      {...props}
      value={value || undefined}
      name={question.id}
      minDate={MIN_DATE_DYNAMIC_FORM}
      maxDate={MAX_DATE}
      onValueChange={(date: Date | string) => handleChange(date)}
      dateFormat={dateFormat}
      showMonthYearPicker={showMonthYearPicker}
      showYearPicker={showYearPicker}
      isValid={!hasError}
      isTouched
    />
  );
};

export default DateField;
