export enum ApplicationLanguage {
  ENGLISH = 'english',
  FRENCH = 'french'
}

export enum SexeEnum {
  Masculin = 1,
  Feminin = 2,
  Aucune = 0,
}

export enum TypeNoTelephoneEnum {
  Principale,
  Bureau,
  Cellulaire,
  Telecopieur
}

export enum SexeEnumInsured {
  Masculin = '1',
  Feminin = '2',
}

export enum BeneficiaryRelationEnum {
  AssureContractant = '1',
  BeneficiaireAssure = '2',
  TiersContractant = '3'
}
export enum InsuredLanguage {
  English = 1,
  French = 0
  }
