import styled from 'styled-components';

export const StyledNotificationUrgent = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: flex-start;
  gap: 10px;

  border-radius: 20px;
  border: 1px solid var(--form-error-e-71313, #E71313);
  background: #FDEDED;

  color: #DB0F0F;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

export const StyledNotificationInformation = styled.div`
  color: #2062D4;
  padding: 4px 12px ;
  border-radius: 20px;
  align-items: flex-start;
  border: 1px solid #2062D4;
  display: flex;
  max-width: 63px;
  max-height: 26px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; 
  justify-content: center;
  gap: 10px;
`;

export const StyledNotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0px 12px 24px 12px;
`;

export const StyledNotificationBulletPoints = styled.li`
  list-style-type: none;
`;

export const StyledNotificationList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  color: #000;

  font-feature-settings: 'clig' off, 'liga' off;
  /* Body/bold */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  line-height: 18px; /* 128.571% */

  & ul {
    padding-inline-start: 25px;
  }

  & a {
    color: var(--link-blue-2062-d-4, #2062D4);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body/semibold */
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-decoration: none;
  }
`;

export const StyledNotificationTextColor = styled.p`
  color: grey;
  font-size: 12px;
`;

export const StyledNotificationHeader = styled.h4`
  margin-top: 10px;
  font-size: 17px;
`;

export const StyledStrongText = styled.strong`
  font-weight: 600;
  font-size: 12px;
`;

export const StyledNotificationDivider = styled.div`
  border-bottom: 1px solid var(--Line-grey, #D3D8DE);
  margin-bottom: 24px;;
`;

export const StyledNotificationInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const StyledNotificationTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const StyledNotificationTitle = styled.div`
  color: var(--text-grey-1-b-1-b-1-b, #1B1B1B);
  font-feature-settings: 'clig' off, 'liga' off;

  /* H5 */
  font-family: Open Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 129.412% */
`;

export const StyledNotificationDate = styled.div`
  color: var(--text-grey-6-b-6-b-6-b, #6B6B6B);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Body/small */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;
