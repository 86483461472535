import ItemsModel from '~/interfaces/InsurabilityDeclaration/ItemsModel';
import CaseDto from '../dtos/CaseDto';
import SectionDto from '../dtos/SectionDto';
import QuestionBuilder from './QuestionBuilder';
import SectionBuilder from './SectionBuilder';
import RepeatingSectionBuilder from './RepeatingSectionBuilder';
import QuestionDto from '../dtos/QuestionDto';
import RepeatingSectionInCaseDto from '../dtos/RepeatingSectionInCaseDto';
import FormDto from '../dtos/FormDto';
import PartyDto from '../dtos/PartyDto';
import ChangeRequestDto from '../dtos/ChangeRequestDto';

export default class ItemsBuilder {
  private items: ItemsModel = {
    sections: {},
    questions: {},
    repeatingSections: {},
  };

  private sectionBuilder = new SectionBuilder();
  private repeatingSectionBuilder = new RepeatingSectionBuilder();
  private questionBuilder = new QuestionBuilder();

  private addSection(sectionDto: SectionDto): void {
    this.items.sections = {
      ...this.items.sections,
      [sectionDto.id]: this.sectionBuilder.toModel(sectionDto),
    };
  }

  private addQuestion(questionDto: QuestionDto): void {
    this.items.questions = {
      ...this.items.questions,
      [questionDto.id]: this.questionBuilder.toModel(questionDto),
    };
  }

  private addRepeatingSection(repSectionDto: RepeatingSectionInCaseDto, path: string): void {
    this.items.repeatingSections = {
      ...this.items.repeatingSections,
      [repSectionDto.id]: this.repeatingSectionBuilder.toModel(repSectionDto, path),
    };
  }

  /**
   * build section model object that match form tree
   * @param dto section DTO from API
   * @param parentPath parentCasePath that is linked with form tree structure
   */
  buildSection(dto: SectionDto, parentPath: string): ItemsBuilder {
    this.addSection(dto);
    dto.questions.forEach((q) => this.addQuestion(q));

    dto.sections.forEach((s, si) => this.buildSection(s, `${parentPath}.sections[${si}]`));
    dto.repeatingSections?.forEach((r, ri) => this.buildRepeatingSection(r, `${parentPath}.repeatingSections[${ri}]`));

    return this;
  }

  /**
   * build repeating section model object that match form tree
   * @param dto section DTO from API
   * @param parentPath parentCasePath that is linked with form tree structure
   */
  buildRepeatingSection(dto: RepeatingSectionInCaseDto, parentPath: string): ItemsBuilder {
    this.addRepeatingSection(dto, parentPath);

    dto.instances.forEach((s, si) => this.buildSection(s, `${parentPath}.instances[${si}]`));

    return this;
  }

  buildForm(dto: FormDto, parentPath: string): ItemsBuilder {
    dto.sections.forEach((s, si) => this.buildSection(s, `${parentPath}.sections[${si}]`));

    return this;
  }

  buildParty(dto: PartyDto, parentPath: string): ItemsBuilder {
    dto.forms.forEach((f, fi) => this.buildForm(f, `${parentPath}.forms[${fi}]`));

    return this;
  }

  buildCase(dto: CaseDto): ItemsBuilder {
    dto.parties.forEach((p, pi) => this.buildParty(p, `parties[${pi}]`));

    return this;
  }

  buildChangeRequest(dto: ChangeRequestDto): ItemsBuilder {
    dto.forms.forEach((f, fi) => this.buildForm(f, `forms[${fi}]`));

    return this;
  }

  getItems(): ItemsModel {
    return this.items;
  }
}
