import { AccountHolder, PacPadAgreementPageState } from '~/interfaces/PacPadAgreementPageState';
import { BillingPageState } from '~/interfaces/Billing/BillingPageState';
import { ValidatorMap } from 'ia-react-core';
import { ValidationMessage, ValidationResult } from '~/interfaces/ValidationMessage';
import { SignatureConfigure } from '~/interfaces/SignaturePageState';
import { SignaturePaperState } from '~/interfaces/SignaturePaperState';
import { GetSignatureElectronicDetailsState } from '~/interfaces/GetSignatureElectronicDetailsState';
import { AddDeleteAdditionalSigner, AddDeleteMainSigner } from '~/fragments/SharedLibrary/interfaces/State';
import ApplicationModel from './interfaces/ApplicationModel';
import IndividualModel from './interfaces/IndividualModel';
import { ActionCreator } from './pages/NomineePage/pages/InsurabilityDeclaration/InsurabilityDeclaration.actions';

const prefix = 'Application';
export const LOAD_APPLICATION_TYPE = `${prefix}.LoadApplication` as const;
export const loadApplication = (saleId: string) => ({
  type: LOAD_APPLICATION_TYPE,
  saleId,
});
export type LoadApplication = ReturnType<typeof loadApplication>;

export const LOAD_APPLICATION_TYPE_WITHOUT_RELOADING = 'ApplicationWithoutReloading.LoadApplication' as const;
export const loadApplicationWithoutReloading = (saleId: string) => ({
  type: LOAD_APPLICATION_TYPE_WITHOUT_RELOADING,
  saleId,
});
export type LoadApplicationWithoutReloading = ReturnType<typeof loadApplicationWithoutReloading>;

export const SET_LOADING_TYPE = `${prefix}.SetLoading` as const;
export const setLoading = (loading: boolean) => ({
  type: SET_LOADING_TYPE,
  loading,
});
export type SetLoading = ReturnType<typeof setLoading>;

export const SET_UPDATING_TYPE = `${prefix}.SetUpdating` as const;
export const setUpdating = (updating: boolean) => ({
  type: SET_UPDATING_TYPE,
  updating,
});
export type SetUpdating = ReturnType<typeof setUpdating>;

export const SET_TARGET_ROUTE_TYPE = `${prefix}.SetTargetRoute` as const;
export const setTargetRoute = (targetRoute: string) => ({
  type: SET_TARGET_ROUTE_TYPE,
  targetRoute,
});
export type SetTargetRoute = ReturnType<typeof setTargetRoute>;

export const LOAD_APPLICATION_SUCCESS_TYPE = `${prefix}.LoadApplicationSuccess` as const;
export const loadApplicationSuccess = (application: ApplicationModel) => ({
  type: LOAD_APPLICATION_SUCCESS_TYPE,
  application,
});
export type LoadApplicationSuccess = ReturnType<typeof loadApplicationSuccess>;

export const UPDATE_NOMINEE_TYPE = `${prefix}.UpdateNominee` as const;
export const updateNominee = (nominee: IndividualModel) => ({
  type: UPDATE_NOMINEE_TYPE,
  nominee,
});
export type UpdateNominee = ReturnType<typeof updateNominee>;

export const FATAL_ERROR_TYPE = `${prefix}.FatalError` as const;
export const fatalError = (logMessage: string, error?: unknown) => ({
  type: FATAL_ERROR_TYPE,
  logMessage,
  error,
});
export type FatalError = ReturnType<typeof fatalError>;

export const SET_ACCESS_LIFE_ELIGIBILITY_TYPE = `${prefix}.setAccessLifeEligibility` as const;
export const setAccessLifeEligibilityAction = (caseId: string, maximumStage: number) => ({
  type: SET_ACCESS_LIFE_ELIGIBILITY_TYPE,
  caseId,
  maximumStage,
});
export type SetAccessLifeEligibilityAction = ReturnType<typeof setAccessLifeEligibilityAction>;

export const SET_VALIDATION_MESSAGES_TYPE = `${prefix}.SetValidations` as const;
export const setValidationMessagesAction = <Value>(page: string, formName: string, validatorMap: ValidatorMap<Value>, stateName?: string) => ({
  type: SET_VALIDATION_MESSAGES_TYPE,
  page,
  formName,
  validatorMap,
  stateName,
});
export type SetValidationMessagesAction<Value> = ReturnType<typeof setValidationMessagesAction<Value>>;

export const SET_PAGE_VALIDATION_MESSAGES_TYPE = `${prefix}.SetPageValidations` as const;
export const setPageValidationMessagesAction = (page: string, formName: string, validationMessages: ValidationMessage[], stateName: string) => ({
  type: SET_PAGE_VALIDATION_MESSAGES_TYPE,
  page,
  formName,
  validationMessages,
  stateName,
});
export type SetPageValidationMessagesAction = ReturnType<typeof setPageValidationMessagesAction>;

export const SET_INS_VALIDATION_MESSAGES_TYPE = `${prefix}.SetInsPageValidations` as const;
export const setInsPageValidationMessagesAction = (page: string, formName: string, validationMessages: ValidationMessage[], stateName: string, noParticipantId: string) => ({
  type: SET_INS_VALIDATION_MESSAGES_TYPE,
  page,
  formName,
  validationMessages,
  stateName,
  noParticipantId,
});
export type SetInsPageValidationMessagesAction = ReturnType<typeof setInsPageValidationMessagesAction>;

export const SET_VALIDATION_MESSAGES_LINKED_APPLICATION_TYPE = `${prefix}.SetValidationsLinkedApplications` as const;
export const setValidationMessagesLinkedApplicationsAction = <Value>(page: string, formName: string, validatorMap: ValidatorMap<Value>) => ({
  type: SET_VALIDATION_MESSAGES_LINKED_APPLICATION_TYPE,
  page,
  formName,
  validatorMap,
});
export type SetValidationMessagesLinkedApplicationsAction<Value> = ReturnType<typeof setValidationMessagesLinkedApplicationsAction<Value>>;

export const SET_VALIDATION_MESSAGES_VALIDATION_TYPE = `${prefix}.SetValidationsValidationPage` as const;
export const setValidationMessagesInValidationPageAction = (page: string, formName: string, validatorMap: ValidationResult[]) => ({
  type: SET_VALIDATION_MESSAGES_VALIDATION_TYPE,
  page,
  formName,
  validatorMap,
});
export type SetValidationMessagesInValidationPageAction = ReturnType<typeof setValidationMessagesInValidationPageAction>;

export const SET_VALIDATION_MESSAGES_NAME_VALIDATION_TYPE = `${prefix}.SetValidationsNameValidation` as const;
export const setValidationMessagesInNameValidationAction = (page: string, formName: string, validatorMap: ValidationMessage[]) => ({
  type: SET_VALIDATION_MESSAGES_NAME_VALIDATION_TYPE,
  page,
  formName,
  validatorMap,
});
export type SetValidationMessagesInNameValidationAction = ReturnType<typeof setValidationMessagesInNameValidationAction>;

export const SET_VALIDATION_MESSAGES_NOMINEE_TYPE = `${prefix}.SetValidationsNominee` as const;
export const setValidationMessagesNomineeAction = <Value>(page: string, formName: string, validatorMap: ValidatorMap<Value>, stateName: string) => ({
  type: SET_VALIDATION_MESSAGES_NOMINEE_TYPE,
  page,
  formName,
  validatorMap,
  stateName,
});
export type SetValidationMessagesNomineeAction<Value> = ReturnType<typeof setValidationMessagesNomineeAction<Value>>;

type OnUpdate = (targetRoute: string) => void;

const actionCreatorPacPad = new ActionCreator('UpdatePacPadAgreement');
export const UpdatePacPadAgreement = actionCreatorPacPad.createAction<{ saleId: string; body: PacPadAgreementPageState; onUpdate?: OnUpdate }>('Update');
export const UpdateAccountHolders = actionCreatorPacPad.createAction<{ accountHolders: AccountHolder[] }>('UpdateAccountHolders');
export const UpdateAccountHolder = actionCreatorPacPad.createAction<{ accountHolder: AccountHolder }>('UpdateAccountHolder');

const actionCreatorBilling = new ActionCreator('UpdateBilling');
export const UpdateBilling = actionCreatorBilling.createAction<{ saleId: string; body: BillingPageState; onUpdate?: OnUpdate }>('Update');

export const UPDATE_SIGNATURE_CONFIGURE_TYPE = `${prefix}.UpdateSignatureConfigure` as const;
export const updateSignatureConfigureAction = (payload: SignatureConfigure) => ({
  type: UPDATE_SIGNATURE_CONFIGURE_TYPE,
  payload,
});
export type UpdateSignatureConfigureAction = ReturnType<typeof updateSignatureConfigureAction>;

export const UPDATE_SIGNATURE_PAPER_FORM_TYPE = `${prefix}.UpdateSignaturePaperForm` as const;
export const updateSignaturePaperFormAction = (payload: SignaturePaperState) => ({
  type: UPDATE_SIGNATURE_PAPER_FORM_TYPE,
  payload,
});
export type UpdateSignaturePaperFormAction = ReturnType<typeof updateSignaturePaperFormAction>;

export const UPDATE_SIGNATURE_ELECTRONIC_FORM_TYPE = `${prefix}.UpdateSignatureElectronicForm` as const;
export const updateSignatureElectronicFormAction = (payload: GetSignatureElectronicDetailsState) => ({
  type: UPDATE_SIGNATURE_ELECTRONIC_FORM_TYPE,
  payload,
});
export type UpdateSignatureElectronicFormAction = ReturnType<typeof updateSignatureElectronicFormAction>;

export const SIGNATURE_CEREMONY_FORM_TYPE = `${prefix}.SignatureCeremonyForm` as const;
export const SignatureCeremonyFormAction = (payload: GetSignatureElectronicDetailsState) => ({
  type: SIGNATURE_CEREMONY_FORM_TYPE,
  payload,
});
export type SignatureCeremonyFormAction = ReturnType<typeof SignatureCeremonyFormAction>;

export const UPDATE_APPLICANT_VALIDATION_MESSAGES = `${prefix}.UPDATE_VALIDATION_MESSAGES` as const;
export const updateApplicantValidationMessagesAction = (payload: ValidationMessage[]) => ({
  type: UPDATE_APPLICANT_VALIDATION_MESSAGES,
  payload,
});
export type updateApplicantValidationMessagesAction = ReturnType<typeof updateApplicantValidationMessagesAction>;

export const UPDATE_ADDITIONAL_FORM_SIGNER_FULLNAME = `${prefix}.UPDATE_ADDITIONAL_FORM_SIGNER_FULLNAME` as const;
export const UpdateAdditionalFormSignerFullNameAction = (payload: number) => ({
  type: UPDATE_ADDITIONAL_FORM_SIGNER_FULLNAME,
  payload,
});
export type UpdateAdditionalFormSignerFullNameAction = ReturnType<typeof UpdateAdditionalFormSignerFullNameAction>;

export const ADD_MAIN_SIGNER_ROLE_TYPE = `${prefix}.ADD_MAIN_SIGNER_ROLE_TYPE` as const;
export const addMainSignerRoleAction = (payload: AddDeleteMainSigner) => ({
  type: ADD_MAIN_SIGNER_ROLE_TYPE,
  payload,
});
export type AddMainSignerRoleAction = ReturnType<typeof addMainSignerRoleAction>;

export const DELETE_MAIN_SIGNER_ROLE_TYPE = `${prefix}.DELETE_MAIN_SIGNER_ROLE_TYPE` as const;
export const deleteMainSignerRoleAction = (payload: AddDeleteMainSigner) => ({
  type: DELETE_MAIN_SIGNER_ROLE_TYPE,
  payload,
});
export type DeleteMainSignerRoleAction = ReturnType<typeof deleteMainSignerRoleAction>;

export const ADD_ADDITIONAL_SIGNER_ROLE_TYPE = `${prefix}.ADD_ADDITIONAL_SIGNER_ROLE_TYPE` as const;
export const addAdditionalSignerRoleAction = (payload: AddDeleteAdditionalSigner) => ({
  type: ADD_ADDITIONAL_SIGNER_ROLE_TYPE,
  payload,
});
export type AddAdditionalSignerRoleAction = ReturnType<typeof addAdditionalSignerRoleAction>;

export const DELETE_ADDITIONAL_SIGNER_ROLE_TYPE = `${prefix}.DELETE_ADDITIONAL_SIGNER_ROLE_TYPE` as const;
export const deleteAdditionalSignerRoleAction = (payload: AddDeleteAdditionalSigner) => ({
  type: DELETE_ADDITIONAL_SIGNER_ROLE_TYPE,
  payload,
});
export type DeleteAdditionalSignerRoleAction = ReturnType<typeof deleteAdditionalSignerRoleAction>;

export const SET_VALIDATE_CLICKED_TYPE = `${prefix}.SET_VALIDATE_CLICKED` as const;
export const setValidateClicked = (clicked: boolean) => ({
  type: SET_VALIDATE_CLICKED_TYPE,
  clicked,
});
export type SetValidateClicked = ReturnType<typeof setValidateClicked>;

export const UPDATE_PACPAD_VALIDATION_MESSAGES = `${prefix}.UPDATE_PACPAD_VALIDATION_MESSAGES` as const;
export const updatePacpadValidationMessagesAction = (payload: ValidationMessage[]) => ({
  type: UPDATE_PACPAD_VALIDATION_MESSAGES,
  payload,
});
export type updatePacpadValidationMessagesAction = ReturnType<typeof updatePacpadValidationMessagesAction>;

export const IS_APPLICATION_FETCHED = `${prefix}.IsApplicationDataFetched` as const;
export const isApplicationDataFetched = (loading: boolean) => ({
  type: IS_APPLICATION_FETCHED,
  loading,
});
export type IsApplicationFetched = ReturnType<typeof isApplicationDataFetched>;

export const SET_NUMBER_OF_FORMS_SELECTED = `${prefix}.SetNumberOfFormsSelected` as const;
export const setNumberOfFormsSelected = (formCount: number) => ({
  type: SET_NUMBER_OF_FORMS_SELECTED,
  formCount,
});
export type SetNumberOfFormsSelected = ReturnType<typeof setNumberOfFormsSelected>;
