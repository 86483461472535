import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from '~/assets/Icons/copy.svg';
import { StyledNoOpenFilesFoundMessage, StyledNoOpenFilesFoundMessageContainer } from './NoOpenFilesFoundMessage.styles';

interface NoOpenFilesFoundMessageProps {}

const NoOpenFilesFoundMessage: FC<NoOpenFilesFoundMessageProps> = () => {
  const { t } = useTranslation('SaleFileDataTable');
  return (
    <StyledNoOpenFilesFoundMessageContainer>
      <CopyIcon />
      <StyledNoOpenFilesFoundMessage>{t('noOpenFiles')}</StyledNoOpenFilesFoundMessage>
    </StyledNoOpenFilesFoundMessageContainer>
  );
};

export default NoOpenFilesFoundMessage;
