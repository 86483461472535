export const LINKED_APPLICATIONS_FORM_NAME = 'linkedApplications';
export const ADDITIONAL_APPLICATION_FORM_NAME = 'additionalApplication';
export const LINKED_APPLICATION_FORM_NAME = 'LinkedApplicationForm';

export const ADDITIONAL_APPLICATION_MODAL_NAME = 'additionalApplicationModal';
export const LINKED_APPLICATION_MODAL_NAME = 'linkedApplicationModal';

export const GET_LINKED_APPLICATIONS_RESOURCE_NAME = 'linkedApplications';
export const UPDATE_LINKED_APPLICATIONS_RESOURCE_NAME = 'updateLinkedApplications';

export const LINKED_APPLICATIONS_VALIDATION_MESSAGES = 'validations.linkedApplications.validationMessages';
