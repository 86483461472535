import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { SetTargetRoute } from '../ApplicationFragment.actions';

export default function setTargetRoute(state: ApplicationFragmentState, action: Action): ApplicationFragmentState {
  const { targetRoute } = action as SetTargetRoute;

  return {
    ...state,
    targetRoute,
  };
}
