import { T2, media } from 'ia-react-core';
import styled from 'styled-components';

export const StyledWorkflowLayoutWrapperPrimaryPageContainer = styled.div`
  & > div {
    margin-bottom: 0px !important;
  }
`;

export const StyledWorkflowLayoutWrapperContentContainer = styled.div``;

export const StyledWorkflowLayoutWrapperTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  
  @media (max-width: 767.9px) {
    padding-right: 10px;
  }
`;

export const StyledWorkflowLayoutWrapperTitle = styled(T2)`
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.blue6};

  ${media.xs`
    margin-left: 16px;
  `}

  ${media.sm`
    margin-left: 0px;
  `}
`;
