import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { requestResourceByName, Form, DatepickerControl, DropdownControl } from 'ia-react-core';
import { getFormattedDateTostring, getStringToFormattedDate } from '~/utilities/getFormattedDateVals';
import logger from '~/utilities/logger';
import { State } from '~/interfaces/State';
import { CATALOG_RESOURCE, GET_VERSION_NUMBER_OPTIONS } from '~/constants';
import getEffectiveDates from '~/fragments/HomeFragment/api/getEffectiveDates';
import { setAppVersioning } from '~/App.actions';
import { getDisplayVersionNumber } from '~/utilities/getFormattedVersionNumber';
import { selectEffectiveDate, selectVersion, selectVersionNumberOptions } from '~/utilities/selectors';
import { AppVersionNumber } from '~/interfaces/AppVersionNumber';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import { StyledT5, StyledAppVersioningSection } from './AppVersioning.styles';
import { VERSIONING_FORM } from '../../constants';

export interface AppVersioningProps {
}

const AppVersioning: FC<AppVersioningProps> = () => {
  const { t } = useTranslation('CreateIllustration');
  const dispatch = useDispatch();
  const versionNumberOptionsDet: AppVersionNumber[] = useSelector((state: State) => selectVersionNumberOptions(state));
  const versionNumberOptions = useMemo(() => versionNumberOptionsDet?.map((x) => ({
    label: x.displayVersionNumber,
    value: x.actualVersionNumber,
  })), [versionNumberOptionsDet]);

  const [minEffectiveDate, setMinEffectiveDate] = useState<Date>();
  const [maxEffectiveDate, setMaxEffectiveDate] = useState<Date>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const effectiveDate = getStringToFormattedDate(useSelector((state: State) => selectEffectiveDate(state)));
  const versionNumber = useSelector((state: State) => selectVersion(state));
  const getDropDownValue = useMemo(() => {
    if (versionNumberOptions !== null && versionNumberOptions !== undefined && versionNumberOptions.length > 0) {
      const index = versionNumberOptions.findIndex((x) => x.label === versionNumber);
      return versionNumberOptions[index].value;
    }
    return null;
  }, [versionNumber]);

  const initialValues = useMemo(() => ({
    effectiveDate,
    versionNumber: getDropDownValue,
  }), [effectiveDate, versionNumber]);

  const handleVersionNumberChange = (value: any) => {
    dispatch(requestResourceByName(`resources.${CATALOG_RESOURCE}`, { effectiveDate: getFormattedDateTostring(effectiveDate), value }));
    dispatch(setAppVersioning(getFormattedDateTostring(effectiveDate), getDisplayVersionNumber(versionNumberOptionsDet, value)));
  };

  const handleEffectiveDateChange = () => {
    dispatch(requestResourceByName(`resources.${GET_VERSION_NUMBER_OPTIONS}`));
  };

  useEffect(() => {
    getEffectiveDates()
      .then((res) => {
        setIsLoading(false);
        setMaxEffectiveDate(() => getStringToFormattedDate(res?.end.toString()));
        setMinEffectiveDate(() => getStringToFormattedDate(res?.start.toString()));
      })
      .catch((error) => {
        logger.error(`Error while getting app version effective dates: ${error.status} - ${error.statusText}`);
      });
  }, []);

  return (
    <StyledAppVersioningSection>
      <Form
        name={VERSIONING_FORM}
        cleanValues
        initialValues={initialValues}
      >
        <DatepickerControl
          required
          name="effectiveDate"
          minDate={minEffectiveDate}
          maxDate={!isLoading && addDays(maxEffectiveDate, 1)}
          onValueChange={handleEffectiveDateChange}
          defaultValue={new Date()}
          includeDateIntervals={[
            {
              start: subDays(minEffectiveDate, 1),
              end: addDays(maxEffectiveDate, 1),
            },
          ]}
        />
        <StyledT5>{t('version')}</StyledT5>
        <DropdownControl
          required
          name="versionNumber"
          options={versionNumberOptions}
          onValueChange={handleVersionNumberChange}
        />
      </Form>
    </StyledAppVersioningSection>
  );
};

export default AppVersioning;
