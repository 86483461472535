import { Method } from 'axios';
import { Resource } from 'ia-react-core';
import React from 'react';

export interface ValidationResourcesProps {
  name: string;
  url?: string;
  method?: Method;
  isAutoRequest?: boolean;
  onSuccess?: () => void;
  onComplete?: () => void;
}
const ValidationResources = ({ name, url = '', method = 'GET', isAutoRequest = false, onSuccess, onComplete }: ValidationResourcesProps) => (
  <Resource
    name={name}
    method={method}
    url={url}
    autoRequest={isAutoRequest}
    onSuccess={onSuccess}
    onComplete={onComplete}
  />
);

export default ValidationResources;
