import React, { FC } from 'react';
import MainLinks from '~/components/MainLinks';
import LatestNews from '../../components/LatestNews';
import FetchNewsResourceLoader from '../CreateIllustrationPage/resources/FetchNewsResourceLoader';
import { StyledFeaturesPageContainer } from './FeaturesPage.styles';

export interface FeaturesPageProps {}

const FeaturesPage: FC<FeaturesPageProps> = () =>
  (
    <StyledFeaturesPageContainer>
      <MainLinks />
      <FetchNewsResourceLoader>
        {({ data: latestNews }) => (
          <LatestNews latestNews={latestNews} />
        )}
      </FetchNewsResourceLoader>
    </StyledFeaturesPageContainer>
  );
export default FeaturesPage;
