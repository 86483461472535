import { requestResourceByName } from 'ia-react-core';
import { ApplicationIntentType } from '../constants';
import { HeadOfficeViewModel } from '../pages/HeadOfficePage/interfaces/HeadOfficeViewModel';
import { UPDATE_HEADOFFICE_RESOURCE_NAME } from '../pages/HeadOfficePage/constants/ConstantNames';

const headOfficeApiCall = (formValues: HeadOfficeViewModel, dispatch: Function) => {
  const validateRequest = {
    shouldSave: true,
    intentType: ApplicationIntentType.SaveAndValidate,
    headOffice: {
      dateDemandeWorkFlow: formValues?.dateDemandeWorkFlow?.toString() === '' ? null : formValues?.dateDemandeWorkFlow,
      isEnAttenteCheque: formValues?.isEnAttenteCheque,
    },
  };
  dispatch(requestResourceByName(`resources.${UPDATE_HEADOFFICE_RESOURCE_NAME}`, { ...validateRequest, initateFromLeftMenu: true }));
};

export default headOfficeApiCall;
