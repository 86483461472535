import { Assure } from '~/interfaces/Scenario';

const displayDefaultDate = (assure: Assure) => {
  if (assure?.entiteNommee?.isBirthDateGeneratedWithAgeEntered) {
    return '';
  }
  if (assure?.entiteNommee?.dateOfBirth) {
    const dob = new Date(assure?.entiteNommee?.dateOfBirth);
    return new Date(dob.getTime() + Math.abs(dob.getTimezoneOffset() * 60000));
  }
  return '';
};

export default displayDefaultDate;
