import { ActionBar, P } from 'ia-react-core';
import styled from 'styled-components';
import Flex from '../Flex';

export const StyledPCToolBtnWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const StyledCoveragesContentP = styled(P)`
  color: ${({ theme }) => theme.colors.gray5}
`;

export const StyledCoveragesContentNoCoverageP = styled(P)`
  margin: 4px 0px 0px 10px; 
`;

export const StyledPCToolModalActionBar = styled(ActionBar)`
  padding-top: 0px;
`;

export const StyledPCToolFlexContainer = styled(Flex)`
  gap: 16px;
  flex-direction: column;
`;
