const COVERAGE_NAMES = {
  SUMMARY_VALIDATION_RESOURCE_NAME: 'summaryValidation',
  UPDATE_COVERAGE_JOINT_INSURED_RESOURCE_NAME: 'jointInsuredCoverage',
  UPDATE_INSURED_PROPERTY_RESOURCE_NAME: 'insuredPersonProperty',
  GET_SALEMETADATA_RESOURCE_NAME: 'saleMetadata',
  ON_CANCEL_SAVE_RESOURCE_NAME: 'onCancelSave',
  VALIDATE_CALCULATE_NAME: 'validateCalculate',
  TAX_RATE_MODAL_NAME: 'tax_rate_modal',
  PROVINCE_SELECTION_MODAL_NAME: 'province_selection_modal',
  COVERAGE_RIDERS: 'getCoverageRiders',
  PROVINCES_RESOURCE_NAME: 'provinces',
  GET_PRODUCT_RESOURCE_NAME: 'getProduct',
  UPDATE_DIVIDEND_OPTION_RESOURCE_NAME: 'updateDividendOption',
  ADDON_FACE_AMOUNT_RESOURCE_NAME: 'addOnFaceAmount',
  SAVE_RATINGS_RESOURCE_NAME: 'saveRatings',
  UNDER_COVERAGES_RESOURCE_NAME: 'underCoverages',
  INSURANCE_TYPES_RESOURCE_NAME: 'InsuranceTypes',
} as const;

export default COVERAGE_NAMES;
