import { Locale } from '~/hooks/useCurrentLocale';

const formatPCToolPrice = (value: number, locale: string) => {
  if (value) {
    const numericString = value.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (locale === Locale.EN) {
      return `$${numericString}`;
    }
    return `${numericString} $`;
  }
  return locale === Locale.EN ? 'N/A' : 'ND';
};

export default formatPCToolPrice;
