import { ActionBar, Promo2 } from 'ia-react-core';
import styled from 'styled-components';

export const StyledWrapperAddInsured = styled.div`
.changeorder {
  [role="presentation"] {
    display: none;
  }
  div {
    &:first-child {
      order: 3;
    }
    &:nth-child(2) {
      order: 2;
    }
    &:last-child {
      order: 1;
    }
  }
`;

export const StyledPromo = styled(Promo2)`
  font-size: 1.5rem;
  font-weight: 600;
`;

export const StyledActionBar = styled(ActionBar)`
  justify-content: left;
  button {
    flex: 0 0 22%;    
  }
  @media only screen and (max-width: 480px) {
    button {
      flex: 1 0 98%;      
    }
  }
`;
