import React from 'react';
import { ControlAnchor } from 'ia-react-core';

export interface ControlAnchorLinkProps {
  message: string;
  fieldName: string;
  anchorText: string;
}

export const ControlAnchorLink = ({ message, fieldName, anchorText }: ControlAnchorLinkProps) => (
  <>
    {message} <ControlAnchor name={fieldName}>{anchorText}</ControlAnchor>
  </>
);

export default ControlAnchorLink;
