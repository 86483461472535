import { LOAD_ILLUSTRATION_DATA, CreateIllustrationActions } from './CreateIllustration.actions';

export default function createIllustrationReducer(state: {}, action: CreateIllustrationActions) {
  switch (action.type) {
    case LOAD_ILLUSTRATION_DATA:
      return {
        ...state,
        categories: { ...action.data.categories },
      };
    default:
      return state;
  }
}
