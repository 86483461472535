import { Address } from 'ia-react-core';
import { v4 as uuid } from 'uuid';
import AddressModel from '../../interfaces/AddressModel';

// Convert AddressModel to Address object used by the address component
export const toAddressDto = (address: Address, nomineeId: string): AddressModel => ({
  id: uuid(),
  postalCode: address.postalCode ?? '',
  extApt: address.subBuilding ?? '',
  civicNumber: address.buildingNumber ?? '',
  province: address.province ?? '',
  street: address.street ?? '',
  city: address.city ?? '',
  poBox: address.poBoxNumber ?? '',
  branch: address.place ?? '',
  ruralRoad: address.ruralRoute ?? '',
  country: address.countryName ?? '',
  idFirstAssociatedEntity: nomineeId,
  addressLookupServiceWasUsed: false,
  subBuildingType: address.subBuildingType ?? '',
});
