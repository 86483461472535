import { SideNav, SideNavLinkProps } from 'ia-react-core';
import React, { FC } from 'react';
import { StyledSideNavigationMenuLink } from './SideNavigationMenu.styles';

interface SideNavigationMenuProps {
  items: SideNavLinkProps[];
  transparentBackground?: boolean;
  className?: string;
}

const SideNavigationMenu: FC<SideNavigationMenuProps> = ({ items, className }) => (
  <SideNav className={className}>
    {items.map((item, index) => (
      <StyledSideNavigationMenuLink key={index} {...item} />
    ))}
  </SideNav>
);

export default SideNavigationMenu;
