import { WORKFLOW_STATUSES } from 'ia-react-core';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import { ValidationMessageLevel } from '~/interfaces/ValidationMessageLevel';

const getStatusFromValidationMessages = (validationMessages: ValidationMessage[]) => {
  if (!validationMessages || validationMessages.length === 0) {
    return WORKFLOW_STATUSES.COMPLETE;
  }

  const minCategorie = validationMessages.reduce((min, v) => Math.min(min, v.categorie), Number.MAX_SAFE_INTEGER);
  let status;
  switch (minCategorie) {
    case ValidationMessageLevel.Error:
      status = WORKFLOW_STATUSES.ERROR;
      break;
    case ValidationMessageLevel.WarningCritique:
      status = WORKFLOW_STATUSES.WARNING;
      break;
    case ValidationMessageLevel.Warning:
      status = WORKFLOW_STATUSES.CAUTION;
      break;
    default:
      status = WORKFLOW_STATUSES.IN_PROGRESS;
      break;
  }
  return status;
};

export default getStatusFromValidationMessages;
