import { ToggleButtonListOption, useScopedSelector } from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import YesNoToggleButtons from '~/components/YesNoToggleButtonsList';
import selectChangeRequestLanguageCode from '~/fragments/SharedLibrary/selectors/selectChangeRequestLanguageCode';
import QuestionField from './QuestionField';
import { StyledYesNoToggleButtonsWrapper } from './QuestionField.styles';

const BoolField: QuestionField = ({ onChange, question, hasError, hasWarning, ...props }) => {
  const { t } = useTranslation('formloader');

  const caseLanguage = useScopedSelector(selectChangeRequestLanguageCode);
  const value = question?.answers[0]?.value;// as boolean;

  const setAnswer = useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    const newValue = event.target.value;
    onChange([newValue]);
  }, [onChange]);

  const optionItems = useMemo<ToggleButtonListOption<string>[]>(
    () => question.possibleAnswers.map((p) => ({ label: p, value: p })),
    [question.possibleAnswers],
  );

  const isYesNo = useMemo(() => optionItems.some((option) => typeof option.value === 'string' && (option.value.toLowerCase() === t('yes') || option.value.toLowerCase() === t('no'))), [optionItems, t]);

  return (
    <StyledYesNoToggleButtonsWrapper>
      <YesNoToggleButtons optionItems={optionItems} horizontal={isYesNo} {...props} hasWarning={hasWarning} value={value} onChange={setAnswer} lng={caseLanguage} hasError={hasError} />
    </StyledYesNoToggleButtonsWrapper>
  );
};

export default BoolField;
