import { requestResourceByName } from 'ia-react-core';
import { InsuranceHistoryFormData } from '~/interfaces/InsuranceHistoryPageState';
import { ApplicationIntentType } from '../constants';
import IndividualModel from '../interfaces/IndividualModel';
import { UPDATE_INSURANCE_HISTORY_RESOURCE_NAME } from '../pages/NomineePage/pages/InsuranceHistoryPage/InsuranceHistoryPage.constants';
import { createPayload } from '../pages/NomineePage/pages/InsuranceHistoryPage/utilities';

const insuranceHistoryApiCall = (formData: InsuranceHistoryFormData, id: string, dispatch: Function) => {
  const validateRequestPayload = createPayload(formData, ApplicationIntentType.SaveAndValidate, { id } as IndividualModel);
  dispatch(requestResourceByName(`resources.${UPDATE_INSURANCE_HISTORY_RESOURCE_NAME}`, { ...validateRequestPayload, initateFromLeftMenu: true }));
};

export default insuranceHistoryApiCall;
