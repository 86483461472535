import { FC } from 'react';
import { compose } from 'redux';
import { injectTranslations, injectReducer } from 'ia-react-core';

import { FoldersPageState } from '~/interfaces/FoldersPageState';
import { GlobalAction } from '~/interfaces/GlobalAction';

import featuresPageReducer from './FeaturesPage.reducer';
import FeaturesPage, { FeaturesPageProps } from './FeaturesPage';

import fr from './translations/fr';
import en from './translations/en';

const initialState = {};

export default compose<FC<FeaturesPageProps>>(
  injectTranslations('FoldersPage', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  injectReducer<FeaturesPageProps, FoldersPageState, GlobalAction>({
    key: 'pages.FeaturesPage',
    reducer: featuresPageReducer,
    initialState,
    useScope: true,
    cleanState: true,
  }),
)(FeaturesPage);
