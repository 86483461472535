import { Action } from 'redux';
import { ChangeRequestState } from '~/fragments/SharedLibrary/interfaces/State';
import { UpdateSignaturePaperFormAction } from '../ApplicationFragment.actions';

export function updateSignaturePaperFormReducer(state: ChangeRequestState, action: Action) {
  const { payload } = action as UpdateSignaturePaperFormAction;
  return {
    ...state,
    values: {
      ...state.values,
      signaturePaper: {
        ...state.values.signaturePaper,
        ...payload,
      },
    },
  };
}
