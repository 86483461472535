import { Resource, ResourceProps, WORKFLOW_STATUSES } from 'ia-react-core';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { updateRouteStatus } from '~/App.actions';
import { loadApplicationWithoutReloading, setPageValidationMessagesAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';
import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';
import selectTargetRoute from '~/fragments/ApplicationFragment/selectors/selectTargetRoute';
import getStatusFromValidationMessages from '~/fragments/ApplicationFragment/utilities/getStatusFromValidationMessages';
import { ApiResponse, ValidationMessage } from '~/interfaces/ValidationMessage';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import selectCurrentRoute from '~/fragments/ApplicationFragment/selectors/selectCurrentRoute';
import { State } from '~/interfaces/State';
import { BENEFICIARIES_FORM, UPDATE_BENEFICIARY_RESOURCE_NAME } from '../constants';

export interface UpdateBeneficiaryResourceProps extends ResourceProps<any, null> {
  onSuccess?: (res: any) => void;
  validationClientId?: string;
}

const getMessagesForNominee = (messages: ValidationMessage[], nomineeId: string): ValidationMessage[] => messages.filter((message) => message.noParticipant === nomineeId);
const UpdateBeneficiaryResource: FC<UpdateBeneficiaryResourceProps> = ({ onSuccess, validationClientId, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { saleId, clientId } = useParams<{ saleId: string; clientId: string }>();
  let intentType: ApplicationIntentType = ApplicationIntentType.Validate;
  let initateFromLeftMenu = true;
  const targetRoute: string = useSelector(selectTargetRoute);
  const currentRoute: string = useSelector(selectCurrentRoute);
  const backToHome = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);

  const defaultOnSuccess = (res: any) => {
    const { validationMessages } = (res?.data as ApiResponse);
    const messages = validationMessages || [];
    const nomineeValidationMessages = getMessagesForNominee(messages, validationClientId) || [];
    const status = getStatusFromValidationMessages(nomineeValidationMessages);
    if (backToHome) {
      history.block(true);
      history.push('/');
      return;
    }

    if (intentType === ApplicationIntentType.Validate || intentType === ApplicationIntentType.SaveAndValidate) {
      dispatch(updateRouteStatus(currentRoute, status));
      dispatch(setPageValidationMessagesAction('NomineePage', BENEFICIARIES_FORM, validationMessages, 'NomineePage'));
    }

    if (intentType === ApplicationIntentType.SaveAndValidate) {
      dispatch(loadApplicationWithoutReloading(saleId));
      if (status === WORKFLOW_STATUSES.ERROR) {
        history.push(currentRoute);
      } else if (!initateFromLeftMenu) {
        history.push(targetRoute);
      }
    }
  };

  return (
    <Resource
      name={UPDATE_BENEFICIARY_RESOURCE_NAME}
      url={getBffUrlForRoute(BffRoute.save_validate_beneficiary, { saleId, clientId: validationClientId || clientId })}
      method="PUT"
      transformRequest={(payloadData: {intentType: ApplicationIntentType; initateFromLeftMenu: boolean }) => {
        intentType = payloadData.intentType;
        initateFromLeftMenu = payloadData.initateFromLeftMenu;
        return payloadData;
      }}
      onSuccess={onSuccess || defaultOnSuccess}
      {...props}
    />
  );
};

export default UpdateBeneficiaryResource;
