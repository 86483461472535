import React from 'react';
import { ActionBar, closeModalByName, PrimaryButton, SecondaryButton } from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AdvisorModel } from '~/interfaces/InsurabilityDeclaration/AdvisorModel';
import { AddButton } from '~/components/AddButton';
import { AdvisorsDataTable } from '../AdvisorDataTable/AdvisorDataTable';
import { StyledAdvisorTableScreenContainer, StyledAdvisorTableScreenDivider, StyledAdvisorTableScreenTableContainer, StyledAdvisorTableScreenTitle } from './AdvisorTableScreen.styles';

export interface AdvisorTableScreenProps {
  onAddAdvisorButtonClick: () => void;
  resourceUrl: string;
  onDeleteButtonClick: (advisor: AdvisorModel) => void;
  onEditButtonClick: (advisor: AdvisorModel) => void;
}

const AdvisorTableScreen = ({
  onAddAdvisorButtonClick,
  resourceUrl,
  onDeleteButtonClick,
  onEditButtonClick,
}: AdvisorTableScreenProps) => {
  const { t } = useTranslation('AdvisorsModal');
  const dispatch = useDispatch();

  return (
    <StyledAdvisorTableScreenContainer>
      <StyledAdvisorTableScreenTitle>{t('advisorTable.advisors')}</StyledAdvisorTableScreenTitle>

      <StyledAdvisorTableScreenTableContainer>
        <AdvisorsDataTable
          resourceUrl={resourceUrl}
          onDeleteClickButton={onDeleteButtonClick}
          onEditClickButton={onEditButtonClick}
        />

        <AddButton onClick={onAddAdvisorButtonClick}>
          {t('common.addAnAdvisor')}
        </AddButton>
      </StyledAdvisorTableScreenTableContainer>

      <StyledAdvisorTableScreenDivider />

      <ActionBar nopadding flexButtonsXs>
        <PrimaryButton onClick={() => dispatch(closeModalByName('modals.advisors'))}>{t('advisorTable.ok')}</PrimaryButton>
        <SecondaryButton onClick={() => dispatch(closeModalByName('modals.advisors'))}>{t('common.cancel')}</SecondaryButton>
      </ActionBar>
    </StyledAdvisorTableScreenContainer>
  );
};

export default AdvisorTableScreen;
