import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { SetAccessLifeEligibilityAction } from '../ApplicationFragment.actions';

export default function setAccessLifeEligibilityReducer(state: ApplicationFragmentState, action: Action): ApplicationFragmentState {
  const { caseId, maximumStage } = action as SetAccessLifeEligibilityAction;
  return {
    ...state,
    accessLife:
      {
        ...state.accessLife,
        [caseId]: maximumStage,
      },

  };
}
