import {
  TableColumn, ResourceLoader, RadioListControl, Spacing,
  InfoIcon,
  Visible,
} from 'ia-react-core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Flex from '~/components/Flex';
import { useTheme } from 'styled-components';
import getTextBasedOnLocale from '~/utilities/getTextBasedOnLocale';
import usePremiumComparisonToolManager from '../../hooks/usePremiumComparisonToolManager';
import { PcToolDataResponseModel } from '../../interfaces/PcToolDataResponseModel';
import { PremiumComparatorPlansModel } from '../../interfaces/PremiumComparatorPlansModel';
import { P_C_TOOL_DATA_RESOURCE_NAME, RiderPlanCodes } from '../../constants';
import formatPCToolPrice from '../../utilities/formatPCToolPrice';
import { StyledCoveragesContentNoCoverageP, StyledCoveragesContentP } from '../../PremiumComparisonToolModal.styles';
import { StyledCoveragesContentMainLoader, StyledCoveragesContentScrollableDataTable, StyledCoveragesContentDataTable } from './CoveragesContent.styles';
const CoveragesContent = () => {
  const { t } = useTranslation('CoveragesContent');
  const {
    flexibleReturnChecked, deathReturnChecked, showFrp15Column, showFrp65Column, locale, getRiderPlanCodePrice,
  } = usePremiumComparisonToolManager();
  const theme = useTheme();

  const CustomLoader = useCallback(() => (
    <>
      <Spacing p={24}>
        <StyledCoveragesContentP>{t('calculation')}</StyledCoveragesContentP>
      </Spacing>
      <StyledCoveragesContentMainLoader />
    </>
  ), []);

  const tableContent = (
    <>
      <TableColumn name="coverages" header={t('coverages')} flex={3}>
        {(plan: PremiumComparatorPlansModel) => (
          <RadioListControl
            name="selectedPlanCode"
            options={[
              { label: getTextBasedOnLocale(locale, plan.descriptionEn, plan.descriptionFr), value: plan.pdfPlanCode.code },
            ]}
          />
        )}
      </TableColumn>
      <TableColumn name="amount" header={t('amount')}>
        {(plan: PremiumComparatorPlansModel) => formatPCToolPrice(plan?.faceAmount, locale)}
      </TableColumn>
      <TableColumn name="basePremium" header={t('basePremium')}>
        {(plan: PremiumComparatorPlansModel) => formatPCToolPrice(plan.premium, locale)}
      </TableColumn>
      {deathReturnChecked &&
      <TableColumn name="rpd" header={t('rpd')}>
        {(plan: PremiumComparatorPlansModel) => getRiderPlanCodePrice(plan?.ridersPremiums, RiderPlanCodes.deathReturn)}
      </TableColumn>}
      {flexibleReturnChecked &&
      <TableColumn name="frp20" header={t('frp20')}>
        {(plan: PremiumComparatorPlansModel) => getRiderPlanCodePrice(plan?.ridersPremiums, RiderPlanCodes.flexReturnTwenty)}
      </TableColumn>}
      {(flexibleReturnChecked && showFrp15Column) &&
      <TableColumn name="frp15" header={t('frp15')}>
        {(plan: PremiumComparatorPlansModel) => getRiderPlanCodePrice(plan?.ridersPremiums, RiderPlanCodes.flexReturnFifteen)}
      </TableColumn>}
      {(flexibleReturnChecked && showFrp65Column) &&
      <TableColumn name="frp65" header={t('frp65')}>
        {(plan: PremiumComparatorPlansModel) => getRiderPlanCodePrice(plan?.ridersPremiums, RiderPlanCodes.flexReturnSixtyFive)}
      </TableColumn>}
      <TableColumn name="totalPremium" header={t('totalPremium')}>
        {(plan: PremiumComparatorPlansModel) => formatPCToolPrice(plan.totalPremium, locale)}
      </TableColumn>
    </>
  );

  return (
    <ResourceLoader
      name="premiumComparatorPlans"
      resourceSrc={`resources.${P_C_TOOL_DATA_RESOURCE_NAME}`}
      CustomLoader={CustomLoader}
    >
      {({ data }: {data: PcToolDataResponseModel}) => (
        data.premiumComparatorPlans.length ?
          <>
            <Visible xs sm md>
              <StyledCoveragesContentScrollableDataTable
                data={data.premiumComparatorPlans}
                name="coveragesOptions"
                noCellWrap
                stickyHead
              >
                {tableContent}
              </StyledCoveragesContentScrollableDataTable>
            </Visible>
            <Visible lg xl xxl>
              <StyledCoveragesContentDataTable
                data={data.premiumComparatorPlans}
                name="coveragesOptions"
                noCellWrap
                stickyHead
              >
                {tableContent}
              </StyledCoveragesContentDataTable>
            </Visible>
          </>
          :
          <Flex>
            <InfoIcon color={theme?.colors?.blue4} />
            <StyledCoveragesContentNoCoverageP m-t={4}>{t('noCoverage')}</StyledCoveragesContentNoCoverageP>
          </Flex>
      )}
    </ResourceLoader>
  );
};

export default CoveragesContent;
