import { Col, SafeHtml, SplitDateControl, T5 } from 'ia-react-core';
import styled from 'styled-components';

export const StyledSectionTitle = styled(T5)`
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  margin-bottom: 12px;
  margin-top: 12px;
  font-size: 14px;
`;

export const StyledSectionT5 = styled(T5)`
  font-family: "Open Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

export const StyledSplitDateControl = styled(SplitDateControl)`
  min-width: 310px;
`;

export const StyledCol = styled(Col)`
  padding-left: '10px';
`;

export const StyledSafeHtml = styled(SafeHtml)`
  font-size: 14px;
`;
