import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useScreenClass } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import {
  SortableTableColumn, DataTableResourceLoader, TableColumn,
  useScopedSelector, Form, ChipsControl, Tab, requestResourceByName, openModalByName, SearchIcon,
  Visible,
  Hidden,
  FixedKeyboardEvent,
  Resource,
  WarningModal,
  PrimaryButton,
  closeModalByName,
  Spacing,
  P,
  FadingLoader,
} from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import { Locale, useCurrentLocale } from '~/hooks/useCurrentLocale';
import { State } from '~/interfaces/State';
import { FoldersFilters, FoldersPageState } from '~/interfaces/FoldersPageState';
import { saveFolderFilters } from '~/fragments/HomeFragment/HomeFragment.actions';
import { BffRoute, getBffUrlForRoute, getBffUrlForRouteWithoutQP, getRelativeUrlForRoute, relativeRoute } from '~/utilities/bffHelper';

import { AxiosResponse } from 'axios';
import { allowedKeys } from '~/constants';
import getTextBasedOnLocale from '~/utilities/getTextBasedOnLocale';
import { SaleFile, SaleFileResponse, SaleFileValidate } from '../interfaces/SaleFile';
import {
  FileNameCell, FileStatusCell, FileInformationCell, FileDateCell, FileSharedCell,
  FileActionsCell, FileMessageCell, FileActionsChange,
} from '../formatters';
import { SaleFileState } from '../interfaces/SaleFileState';
import { isColVisible } from '../helpers/isColVisible';
import {
  ColumnCode, filterOptions, filterOptionsEchange, SALE_MIGRATE, SUCCESS_NO_CONTENT, SUCCESS_STATUS_CODE, VALIDATE_API_PAYLOAD, VALIDATE_EFFECTIVE_DATE,
} from '../constants';
import { clearFilter, resetPagination, setAllFiles } from './SaleFileDataTable.actions';
import {
  StyledSaleFileDataTableResourceLoaderContainer,
  StyledSaleFileDataTableSearchButtonContainer,
  StyledSaleFileDataTableSearchControl,
  StyledSaleFileDataTableTabs,
  StyledSaleFileChangeRequestSearchButton,
  StyledSaleFileDataTableFiltersContainer,
  StyledSaleFileDataTableImportSharedFileButton, StyledSaleFileDataTableFiltersChipsControlContainer,
  StyledSaleFileDataTableSearchControlContainer,
  StyledSaleFileDataTableContainer,
} from './SaleFileDataTable.styles';
import { NoSaleFilesFoundMessage } from './components/NoSaleFilesFoundMessage';
import { PagedResultsViewModel } from '../interfaces/PagedResultsViewModel';
import { SALE_FILES_LIST_RESOURCE_NAME } from './SaleFileDataTable.constants';
import SaveFile from '../../../SaveFileModal/SaveFile';
import FileDownloadModal from '../FileDownloadModal/FileDownloadModal';
export interface SaleFileDataTableProps {
  changeRequestUrl: string;
  onDelete: (saleFile: SaleFile) => void;
  onRename: (saleFile: SaleFile) => void;
  onMoreInfos: (saleFile: SaleFile) => void;
  onFileShare?: (saleFile: SaleFile) => void;
  handleFilterType: (filterType: number) => void;
}

const SaleFileDataTable: FC<SaleFileDataTableProps> = ({ onRename, onDelete, changeRequestUrl, onFileShare, handleFilterType }) => {
  const StatusCode = {
    inProgress: 0,
    completed: 2,
  };
  const history = useHistory();
  const { i18n, t } = useTranslation('SaleFileDataTable');
  const dispatch = useDispatch();
  const locale = useCurrentLocale();
  const screenClass = useScreenClass();
  const [saleFileId, setSaleFile] = useState<SaleFile>(null);
  const [relativePath, setRelativePath] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [migrateData, setMigrateData] = useState<SaleFileResponse>(null);
  const [validateData, setValidateData] = useState<SaleFileValidate>(null);
  const filters = useScopedSelector((state: FoldersPageState) => state?.values?.filters);
  const status = useScopedSelector((state: FoldersPageState) => state?.forms?.filters?.dirty?.statusFilter);
  const formIsDirty = useScopedSelector((state: FoldersPageState) => state?.forms?.filters?.touched);
  const saleFilesFilter = useScopedSelector((state: SaleFileState) => state?.values?.filters?.search);
  const isFiltersFormInitialized = useScopedSelector((state: SaleFileState) => state?.forms?.filters?.isInitialized);
  const isSalesFileLoading = useScopedSelector((state: SaleFileState) => state?.resources?.mySaleFiles?.pending);
  const order = useScopedSelector((state: SaleFileState) => state?.tables?.mySaleFiles?.sortBy?.direction);
  const initialFilters = useSelector((state: State) => state?.App?.fragments?.HomeFragment?.values?.foldersFilters);
  const lang = useSelector((state: State) => state?.language?.locale) as 'en' | 'fr' ?? 'fr';
  const totalFilesDisplayed = useSelector((state: State) => state?.App?.fragments?.HomeFragment?.pages?.FoldersPage?.SaleFileDataTable?.data?.mySaleFiles?.totalCount);

  const location = useLocation();
  const isActionsColCompact = !isColVisible(ColumnCode.shared, screenClass);
  const actionsColHeader = isActionsColCompact && ['xs', 'sm'].includes(screenClass) ? '' : t('actions');
  const actionsColStyle = isActionsColCompact ? { width: 60, padding: '0' } : {
    padding: 12,
    minWidth: 112,
    flex: '1 1 0',
    display: 'flex',
    justifyContent: 'center',
  };

  const userPermissions = useSelector((state: State) => state?.App?.data?.permissions?.permissions);
  const rolePermissions = useSelector((state: State) => state?.App?.Pages?.RolesPage?.data?.roles?.permissions);
  const prevFilters = useRef<FoldersFilters | null>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isStatusFilterChanged, setIsStatusFilterChanged] = useState(false);
  const prevStatusFilter = useRef(filters?.statusFilter);
  const [selectedUser, setSelectedUser] = useState<{ nomVente?: string; venteId?: string }>({ nomVente: '', venteId: '' });
  const finalPermission = rolePermissions !== undefined ? rolePermissions : userPermissions;
  const hasPermission = finalPermission?.includes('EChange.All');
  const openFileDownloadModal = () => {
    dispatch(openModalByName('modals.filedownload'));
  };

  useEffect(() => {
    if (isSalesFileLoading === false && isFiltersFormInitialized) {
      dispatch(saveFolderFilters(filters));
    }
  }, [isSalesFileLoading, dispatch, filters?.search, filters, isFiltersFormInitialized]);

  useEffect(() => {
    if ((location?.pathname?.includes('folders') || location?.pathname?.includes('dossiers')) && isFiltersFormInitialized && saleFileId === null) {
      dispatch(requestResourceByName('resources.mySaleFiles', null, {
        params: filters,
      }));
    }
  }, [dispatch, location, isFiltersFormInitialized]);

  useEffect(() => {
    if (!isEqual(filters, prevFilters?.current) && (isFormSubmitted || isStatusFilterChanged)) {
      dispatch(requestResourceByName('resources.mySaleFiles', null, {
        params: filters,
      }));
      prevFilters.current = filters;
      setIsFormSubmitted(false);
      setIsStatusFilterChanged(false);
      prevStatusFilter.current = filters?.statusFilter;
      handleFilterType(filters?.statusFilter);
    }
  }, [dispatch, filters?.statusFilter, status, formIsDirty, order, isFormSubmitted, isStatusFilterChanged]);

  useEffect(() => {
    if (filters?.search?.length === 0 && filters.statusFilter === prevStatusFilter.current) {
      dispatch(requestResourceByName('resources.mySaleFiles', null, {
        params: filters,
      }));
      prevFilters.current = filters;
      prevStatusFilter.current = filters.statusFilter;
    }
  }, [dispatch, filters?.search]);

  useEffect(() => {
    if (saleFileId && saleFileId?.details?.shouldMigrate) {
      setIsLoading(true);
      dispatch(requestResourceByName(`resources.${SALE_MIGRATE}`, { saleId: saleFileId?.venteId }, { method: 'POST' }));
    } else if (saleFileId) {
      setIsLoading(true);
      dispatch(requestResourceByName(`resources.${VALIDATE_EFFECTIVE_DATE}`, VALIDATE_API_PAYLOAD, { method: 'POST' }));
    }
  }, [dispatch, saleFileId]);

  useEffect(() => {
    if (saleFileId) {
      if (migrateData?.saveStatus) {
        dispatch(openModalByName('modals.migrationAlert'));
      } else if (migrateData?.validationMessages?.length > 0) {
        dispatch(openModalByName('modals.migrationAlert'));
      } else {
        setMigrateData(null);
        setRelativePath('');
        setSaleFile(null);
      }
    }
  }, [dispatch, migrateData, migrateData?.validationMessages?.length]);

  useEffect(() => {
    if (saleFileId) {
      if (!validateData) {
        setTimeout(() => {
          history.push({ pathname: relativePath, state: { file: saleFileId, from: 'list' } });
        }, 500);
      } else {
        dispatch(openModalByName('modals.validatePassage'));
      }
    }
  }, [validateData, relativePath, saleFileId, dispatch, history]);

  const handleSubmit = useCallback(() => {
    dispatch(resetPagination());
    dispatch(requestResourceByName('resources.mySaleFiles', null, {
      params: filters,
    }));
    setIsFormSubmitted(true);
  }, [dispatch, filters]);

  const handleKeyValidation = (event: FixedKeyboardEvent): void => {
    const { key } = event;
    const allowedCharacters = /^[a-zA-Z0-9\s-]+$/;

    if (allowedKeys.includes(key)) {
      return;
    }

    if (!allowedCharacters.test(key)) {
      event.preventDefault();
    }
  };

  const handleChipsSubmit = useCallback(() => {
    dispatch(resetPagination());
  }, [dispatch]);

  const handleNewChange = useCallback(() => {
    dispatch(openModalByName('modals.makeEchange'));
  }, [dispatch]);

  const handleMigrate = () => {
    if (migrateData?.saveStatus) {
      dispatch(closeModalByName('modals.migrationAlert'));
      dispatch(requestResourceByName(`resources.${VALIDATE_EFFECTIVE_DATE}`, VALIDATE_API_PAYLOAD, { method: 'POST' }));
    } else {
      dispatch(closeModalByName('modals.migrationAlert'));
      setMigrateData(null);
      setRelativePath('');
      setSaleFile(null);
    }
  };
  const handleValidate = () => {
    const params = { saleId: saleFileId?.venteId };

    const illustrationRouteUrlEn = getRelativeUrlForRoute(relativeRoute.illustration_coverage_saleId, params);
    const illustrationouteUrlFr = getRelativeUrlForRoute(relativeRoute.illustration_couverture_saleId, params);
    dispatch(closeModalByName('modals.validatePassage'));
    history.push({ pathname: lang === Locale.EN ? illustrationRouteUrlEn : illustrationouteUrlFr, state: { file: saleFileId, from: 'list' } });
  };

  const apiUrl = getBffUrlForRoute(BffRoute.user_sales_paged, undefined, false);

  const fileMessageColumn = (
    <TableColumn id="filename-message" name="fileName" style={{ maxWidth: 22, minWidth: 22 }}>
      {(saleFile: SaleFile) => <FileMessageCell saleFile={saleFile} />}
    </TableColumn>
  );

  const onSave = useCallback(({ nomVente, venteId }: SaleFile) => {
    setSelectedUser({ nomVente, venteId });
    dispatch(openModalByName('modals.saveEchangeFile'));
  }, []);

  const lastAccessedContent = (isChangeRequest: boolean) => (
    <>
      {fileMessageColumn}
      <TableColumn id="filename-tableheader" header={t('fileName')} name="nomVente" style={{ flex: '2 1 0' }}>
        {(saleFile: SaleFile) => <FileNameCell isChangeRequest={isChangeRequest} saleFile={saleFile} handleSaleMigrate={setSaleFile} handleRelativePath={setRelativePath} />}
      </TableColumn>
      {isColVisible(ColumnCode.created, screenClass) &&
        <TableColumn id="createdDate-tableheader" header={filters?.statusFilter === StatusCode.completed ? t('transferredDate') : t('createdDate')} name="dateCreationVente" style={{ flex: '1 1 0' }}>
          {(saleFile: SaleFile) => FileDateCell(i18n.language, filters?.statusFilter === StatusCode.completed ? saleFile.dateTransfert : saleFile.dateCreationVente)}
        </TableColumn>}
      {!isChangeRequest && isColVisible(ColumnCode.case, screenClass) &&
        <TableColumn id="case-tableheader" header={t('case')} name="case">
          {(saleFile: SaleFile) => <FileInformationCell saleFile={saleFile} />}
        </TableColumn>}
      {isChangeRequest && isColVisible(ColumnCode.case, screenClass) &&
        <TableColumn id="case-tableheader" header={t('case')} name="numeroDemande">
          {(saleFile: SaleFile) => <FileInformationCell saleFile={saleFile} isChange isCase />}
        </TableColumn>}
      {isChangeRequest && isColVisible(ColumnCode.case, screenClass) &&
        <TableColumn id="case-tableheader" header={t('contract')} name="numeroContrat">
          {(saleFile: SaleFile) => <FileInformationCell saleFile={saleFile} isChange isContract />}
        </TableColumn>}
      {(isColVisible(ColumnCode.status, screenClass)) &&
        <TableColumn id="status-tableheader" header={t('status')} name="status">
          {(saleFile: SaleFile) => <FileStatusCell saleFile={saleFile} />}
        </TableColumn>}
      {!isChangeRequest && (isColVisible(ColumnCode.shared, screenClass)) &&
        <TableColumn id="shared-tableheader" header={t('shared')} name="shared">
          {(saleFile: SaleFile) => <FileSharedCell saleFile={saleFile} />}
        </TableColumn>}
      {!isChangeRequest && (
        <TableColumn id="actions-tableheader" name="actions" header={actionsColHeader} style={actionsColStyle}>
          {(saleFile: SaleFile) => <FileActionsCell saleFile={saleFile} isCompact={isActionsColCompact} saleCompleted={filters?.statusFilter === StatusCode.completed} openRenameModal={onRename} openDeleteModal={onDelete} openFileSharingModal={onFileShare} />}
        </TableColumn>
      )}
      {isChangeRequest && (
        <TableColumn id="actions-tableheader" name="actions" header={actionsColHeader} style={actionsColStyle}>
          {(saleFile: SaleFile) => <FileActionsChange saleFile={saleFile} isCompact={isActionsColCompact} saleCompleted={filters?.statusFilter === StatusCode.completed} openRenameModal={onRename} openDeleteModal={onDelete} />}
        </TableColumn>
      )}
    </>
  );

  const mainContent = (isChangeRequest: boolean) => (
    <>
      {fileMessageColumn}
      {['md', 'lg', 'xxl', 'xl', 'sm'].includes(screenClass) === true ?
        <SortableTableColumn id="filename-tableheader" header={t('fileName')} name="nomVente" style={{ flex: '2 1 0' }} displaySortIcon>
          {(saleFile: SaleFile) => <FileNameCell isChangeRequest={isChangeRequest} saleFile={saleFile} handleSaleMigrate={setSaleFile} handleRelativePath={setRelativePath} />}
        </SortableTableColumn> :
        <SortableTableColumn id="filename-tableheader" header={t('fileName')} name="nomVente" displaySortIcon style={{ flex: '2 1 0' }}>
          {(saleFile: SaleFile) => <FileNameCell isChangeRequest={isChangeRequest} saleFile={saleFile} handleSaleMigrate={setSaleFile} handleRelativePath={setRelativePath} />}
        </SortableTableColumn>}
      {isColVisible(ColumnCode.created, screenClass) &&
        <SortableTableColumn id="createdDate-tableheader" header={filters?.statusFilter === StatusCode.completed ? t('transferredDate') : t('createdDate')} name="dateCreationVente" style={{ flex: '1 1 0' }} displaySortIcon>
          {(saleFile: SaleFile) => FileDateCell(i18n.language, filters?.statusFilter === StatusCode.completed ? saleFile.dateTransfert : saleFile.dateCreationVente)}
        </SortableTableColumn>}
      {!isChangeRequest && isColVisible(ColumnCode.case, screenClass) &&
        <SortableTableColumn id="case-tableheader" header={t('case')} name="numeroDemande" style={{ flex: '1 1 0' }} displaySortIcon>
          {(saleFile: SaleFile) => <FileInformationCell saleFile={saleFile} />}
        </SortableTableColumn>}
      {isChangeRequest && isColVisible(ColumnCode.case, screenClass) &&
        <SortableTableColumn id="case-tableheader" header={t('case')} name="numeroDemande" style={{ flex: '1 1 0' }} displaySortIcon>
          {(saleFile: SaleFile) => <FileInformationCell saleFile={saleFile} isChange isCase />}
        </SortableTableColumn>}
      {isChangeRequest && isColVisible(ColumnCode.case, screenClass) &&
        <SortableTableColumn id="case-tableheader" header={t('contract')} name="numeroContrat" style={{ flex: '1 1 0' }} displaySortIcon>
          {(saleFile: SaleFile) => <FileInformationCell saleFile={saleFile} isChange isContract />}
        </SortableTableColumn>}
      {(isColVisible(ColumnCode.status, screenClass) && filters?.statusFilter === StatusCode.inProgress) &&
        <SortableTableColumn id="status-tableheader" header={t('status')} name="status" style={{ flex: '1 1 0' }} displaySortIcon>
          {(saleFile: SaleFile) => <FileStatusCell saleFile={saleFile} />}
        </SortableTableColumn>}
      {!isChangeRequest && (isColVisible(ColumnCode.shared, screenClass) && filters?.statusFilter === StatusCode.inProgress) &&
        <SortableTableColumn id="shared-tableheader" header={t('shared')} name="shared" style={{ flex: '1 1 0' }} displaySortIcon>
          {(saleFile: SaleFile) => <FileSharedCell saleFile={saleFile} />}
        </SortableTableColumn>}
      {!isChangeRequest && (
        <TableColumn id="actions-tableheader" name="actions" header={actionsColHeader} style={actionsColStyle}>
          {(saleFile: SaleFile) => <FileActionsCell saleFile={saleFile} isCompact={isActionsColCompact} saleCompleted={filters?.statusFilter === StatusCode.completed} openRenameModal={onRename} openDeleteModal={onDelete} openFileSharingModal={onFileShare} openSaveModal={onSave} />}
        </TableColumn>
      )}
      {isChangeRequest && (
        <TableColumn id="actions-tableheader" name="actions" header={actionsColHeader} style={actionsColStyle}>
          {(saleFile: SaleFile) => (
            <FileActionsChange
              saleFile={saleFile}
              isCompact={isActionsColCompact}
              saleCompleted={filters?.statusFilter === StatusCode.completed}
              openRenameModal={onRename}
              openDeleteModal={onDelete}
              openSaveModal={onSave}
            />
          )}
        </TableColumn>
      )}
    </>
  );

  const importSharedFileContent = (
    <StyledSaleFileDataTableImportSharedFileButton onClick={openFileDownloadModal}>
      {t('importSharedFile')}
    </StyledSaleFileDataTableImportSharedFileButton>
  );

  const newChangeRequestContent = (
    <StyledSaleFileDataTableImportSharedFileButton onClick={handleNewChange}>
      {t('newchangerequest')}
    </StyledSaleFileDataTableImportSharedFileButton>
  );

  const commonSearchBarProps = useMemo(() => ({
    id: 'file-searchcontrol',
    name: 'search',
    className: 'searchcontrol_sdt',
    'data-testid': 'searchFileInput',
    value: saleFilesFilter,
    autoComplete: 'off',
    clickableRightSign: true,
    clearButton: true,
    rightSignWidth: 40,
    onSearch: handleSubmit,
    onKeyDown: handleKeyValidation,
  }), [handleSubmit, saleFilesFilter]);

  const searchBarContent = (
    <StyledSaleFileDataTableSearchControlContainer>
      <Hidden sm xs>
        {importSharedFileContent}
      </Hidden>

      <StyledSaleFileDataTableSearchControl
        {...commonSearchBarProps}
        placeholder={t('searchInputPlaceholder')}
        rightSign={
          <StyledSaleFileDataTableSearchButtonContainer onClick={handleSubmit}>
            <SearchIcon />
          </StyledSaleFileDataTableSearchButtonContainer>
        }
      />
    </StyledSaleFileDataTableSearchControlContainer>
  );

  const searchBarChangeRequestContent = (
    <StyledSaleFileDataTableSearchControlContainer>
      <Hidden sm xs>
        {newChangeRequestContent}
      </Hidden>

      <StyledSaleFileDataTableSearchControl
        {...commonSearchBarProps}
        placeholder={t('searchInputPlaceholder')}
        rightSign={
          <StyledSaleFileChangeRequestSearchButton onClick={handleSubmit}>
            <SearchIcon />
          </StyledSaleFileChangeRequestSearchButton>
        }
      />
    </StyledSaleFileDataTableSearchControlContainer>
  );
  return (
    <>
      <FileDownloadModal handleSaleMigrate={setSaleFile} handleRelativePath={setRelativePath} />
      <SaveFile selectedUser={selectedUser} />
      <StyledSaleFileDataTableTabs
        small
        paddingXs="24px 16px 24px 16px"
        paddingSm="32px 16px 32px 16px"
        name="typeofBusiness"
        onSelect={() => {
          dispatch(clearFilter());
        }}
      >
        <Tab data-id-tab-new-business="true" title={t('newBusiness')}>
          <Resource
            name={SALE_MIGRATE}
            method="POST"
            url={getBffUrlForRouteWithoutQP(BffRoute.sale_migrate, { saleId: saleFileId?.venteId })}
            onSuccess={(res: AxiosResponse<SaleFileResponse>) => {
              setIsLoading(false);
              setMigrateData({ ...res?.data });
            }}
            onFailure={() => {
              setIsLoading(false);
            }}
          />
          <Resource
            name={VALIDATE_EFFECTIVE_DATE}
            method="POST"
            url={getBffUrlForRouteWithoutQP(BffRoute.validate_effective_date, { saleId: saleFileId?.venteId })}
            onSuccess={(res: AxiosResponse<SaleFileValidate>) => {
              if (res.status === SUCCESS_NO_CONTENT && Object.values(res.data).length === 0) {
                history.push({ pathname: relativePath, state: { file: saleFileId, from: 'list' } });
              } else if (res.status === SUCCESS_STATUS_CODE && Object.values(res.data).length > 0) {
                setIsLoading(false);
                setValidateData(res?.data);
              }
            }}
            onFailure={() => {
              setIsLoading(false);
            }}
            onComplete={() => {
              setIsLoading(false);
            }}
          />
          <WarningModal name="validatePassage" modalTitle={t('changeAge')} isClosable={false}>
            <P>{getTextBasedOnLocale(locale, validateData?.messageEN, validateData?.messageFR)}</P>
            <Spacing p={24}>
              <PrimaryButton data-testid="validateBtn" onClick={handleValidate}>{t('ok')}</PrimaryButton>
            </Spacing>
          </WarningModal>
          <WarningModal name="migrationAlert" isClosable={false} modalTitle={migrateData?.saveStatus && t('success')}>
            <P>{migrateData?.saveStatus && migrateData?.validationMessages.length === 0 ? t('migrateSuccessMsg') : migrateData?.validationMessages?.map((message) => getTextBasedOnLocale(locale, message.messageEN, message?.messageFR))}</P>
            <Spacing p={24}>
              <PrimaryButton data-testid="migrateBtn" onClick={handleMigrate}>{t('ok')}</PrimaryButton>
            </Spacing>
          </WarningModal>
          {isLoading ?
            <FadingLoader /> :
            <StyledSaleFileDataTableContainer>
              <Form
                initialValues={initialFilters}
                name="filters"
                onSubmit={handleSubmit}
              >
                <StyledSaleFileDataTableContainer>
                  <StyledSaleFileDataTableFiltersContainer>

                    <StyledSaleFileDataTableFiltersChipsControlContainer>
                      <ChipsControl
                        id="filestatus-filter"
                        name="statusFilter"
                        className="chipsControlSFD"
                        required
                        defaultValue={0}
                        data-testid="statusFilter"
                        options={filterOptions(t)}
                        onClick={handleChipsSubmit}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleFilterType(parseInt(e.target.value, 10));
                          setIsStatusFilterChanged(true);
                        }}
                      />
                    </StyledSaleFileDataTableFiltersChipsControlContainer>

                    <Visible sm>
                      {importSharedFileContent}
                    </Visible>

                    <Hidden xs sm>
                      {searchBarContent}
                    </Hidden>
                  </StyledSaleFileDataTableFiltersContainer>

                  <Visible xs>
                    <div>
                      {importSharedFileContent}
                    </div>
                  </Visible>

                  {(totalFilesDisplayed && !initialFilters?.search) ?
                    <Visible sm xs>
                      {searchBarContent}
                    </Visible> : null}
                </StyledSaleFileDataTableContainer>
              </Form>
              <StyledSaleFileDataTableResourceLoaderContainer>
                <DataTableResourceLoader
                  data-testid="businessDataTable"
                  name={SALE_FILES_LIST_RESOURCE_NAME}
                  url={apiUrl}
                  params={{ ...filters, lang: i18n.language }}
                  getItemCountFromResponse={(responseData: PagedResultsViewModel) => responseData?.totalCount}
                  getDataFromResponse={(responseData: PagedResultsViewModel) => responseData?.items}
                  noDataMessage={t('noSaleFiles')}
                  onSuccess={(data: AxiosResponse) => { dispatch(setAllFiles(data.data || [])); }}
                  CustomNoDataMessage={NoSaleFilesFoundMessage}
                  reverseFooter
                >
                  {filters?.statusFilter !== StatusCode.completed && filters?.statusFilter !== StatusCode.inProgress ? lastAccessedContent(false) : mainContent(false)}
                </DataTableResourceLoader>
              </StyledSaleFileDataTableResourceLoaderContainer>
            </StyledSaleFileDataTableContainer>}
        </Tab>
        <Tab disabled={!hasPermission} data-id-tab-change-request="true" title={t('changeRequest')}>
          <Resource
            name={SALE_MIGRATE}
            method="POST"
            url={getBffUrlForRouteWithoutQP(BffRoute.sale_migrate, { saleId: saleFileId?.venteId }, true)}
            onSuccess={(res: AxiosResponse<SaleFileResponse>) => {
              setIsLoading(false);
              setMigrateData({ ...res?.data });
            }}
            onFailure={() => {
              setIsLoading(false);
            }}
          />
          <Resource
            name={VALIDATE_EFFECTIVE_DATE}
            method="POST"
            url={getBffUrlForRouteWithoutQP(BffRoute.validate_effective_date, { saleId: saleFileId?.venteId })}
            onSuccess={(res: AxiosResponse<SaleFileValidate>) => {
              if (res.status === SUCCESS_NO_CONTENT && Object.values(res.data).length === 0) {
                history.push({ pathname: relativePath, state: { file: saleFileId, from: 'list' } });
              } else if (res.status === SUCCESS_STATUS_CODE && Object.values(res.data).length > 0) {
                setIsLoading(false);
                setValidateData(res?.data);
              }
            }}
            onFailure={() => {
              setIsLoading(false);
            }}
            onComplete={() => {
              setIsLoading(false);
            }}
          />
          <WarningModal name="validatePassage" modalTitle={t('changeAge')} isClosable={false}>
            <P>
              {getTextBasedOnLocale(locale, validateData?.messageEN, validateData?.messageFR)}
            </P>
            <Spacing p={24}>
              <PrimaryButton data-testid="validateBtn" onClick={handleValidate}>{t('ok')}</PrimaryButton>
            </Spacing>
          </WarningModal>
          <WarningModal name="migrationAlert" isClosable={false} modalTitle={migrateData?.saveStatus && t('success')}>
            <P>{migrateData?.saveStatus && migrateData?.validationMessages.length === 0 ? t('migrateSuccessMsg') : migrateData?.validationMessages?.map((message) => getTextBasedOnLocale(locale, message.messageEN, message?.messageFR))}</P>
            <Spacing p={24}>
              <PrimaryButton data-testid="migrateBtn" onClick={handleMigrate}>{t('ok')}</PrimaryButton>
            </Spacing>
          </WarningModal>
          {isLoading ?
            <FadingLoader /> :
            <StyledSaleFileDataTableContainer>
              <Form
                name="filters"
                onSubmit={handleSubmit}
              >
                <StyledSaleFileDataTableContainer>
                  <StyledSaleFileDataTableFiltersContainer>
                    <StyledSaleFileDataTableFiltersChipsControlContainer>
                      <ChipsControl
                        id="filestatus-filter"
                        name="statusFilter"
                        className="chipsControlSFD"
                        required
                        defaultValue={0}
                        data-testid="statusFilter"
                        options={filterOptionsEchange(t)}
                        onClick={handleChipsSubmit}
                        onChange={() => setIsStatusFilterChanged(true)}
                      />
                    </StyledSaleFileDataTableFiltersChipsControlContainer>

                    <Visible xs sm>
                      {newChangeRequestContent}
                    </Visible>

                    {(totalFilesDisplayed || initialFilters?.search) ?
                      <Hidden sm xs>
                        {searchBarChangeRequestContent}
                      </Hidden> : null}
                  </StyledSaleFileDataTableFiltersContainer>

                  {(totalFilesDisplayed || initialFilters?.search) ?
                    <Visible sm xs>
                      {searchBarChangeRequestContent}
                    </Visible> : null}
                </StyledSaleFileDataTableContainer>

              </Form>
              <StyledSaleFileDataTableResourceLoaderContainer>
                <DataTableResourceLoader
                  data-testid="changeRequestDataTable"
                  name={SALE_FILES_LIST_RESOURCE_NAME}
                  url={changeRequestUrl}
                  params={{ ...filters, lang: i18n.language }}
                  getItemCountFromResponse={(responseData: PagedResultsViewModel) => responseData?.totalCount}
                  getDataFromResponse={(responseData: PagedResultsViewModel) => responseData?.items}
                  noDataMessage={t('noSaleFiles')}
                  onSuccess={(data: AxiosResponse) => { dispatch(setAllFiles(data.data || [])); }}
                  CustomNoDataMessage={NoSaleFilesFoundMessage}
                  reverseFooter
                >
                  {filters?.statusFilter !== StatusCode.completed && filters?.statusFilter !== StatusCode.inProgress ? lastAccessedContent(true) : mainContent(true)}
                </DataTableResourceLoader>
              </StyledSaleFileDataTableResourceLoaderContainer>
            </StyledSaleFileDataTableContainer>}
        </Tab>
      </StyledSaleFileDataTableTabs>
    </>
  );
};

export default SaleFileDataTable;
