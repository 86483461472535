import { WORKFLOW_STATUSES, WorkflowRouteDefinition } from 'ia-react-core';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import updateRouteStatus from './updateRouteStatus';
import PAGE_NAMES from '../constants/pageNames';

interface RouteStatusParams {
  route: WorkflowRouteDefinition;
  routePath: string;
  apiResponse: ValidationMessage[];
  activeRoute: string;
  lastVisitedRoute: string;
  lastStatuses: any;
}

interface RouteStatusHandlers {
  isSummaryRoute: (route: WorkflowRouteDefinition) => boolean;
  isInactiveStatus: (route: WorkflowRouteDefinition) => boolean;
  setLastStatuses: (statuses: any) => void;
}

interface Route {
  label: {
    en: string;
    fr: string;
  };
}

const updateRouteStatuses = (
  { route, routePath, apiResponse, activeRoute, lastVisitedRoute, lastStatuses }: RouteStatusParams,
  { isSummaryRoute, isInactiveStatus, setLastStatuses }: RouteStatusHandlers,
) => {
  let status;
  const getStatus = (routeLabel: string, currentPage: string) => {
    const pageStatuses = {
      [PAGE_NAMES.SIGNATURE_MODE]: {
        [PAGE_NAMES.SIGNATURE_MODE]: WORKFLOW_STATUSES.IN_PROGRESS,
        [PAGE_NAMES.SIGNATURE_FOLLOWUP]: WORKFLOW_STATUSES.IN_PROGRESS,
        [PAGE_NAMES.VALIDATION]: WORKFLOW_STATUSES.COMPLETE,
        default: WORKFLOW_STATUSES.INACTIVE,
      },
      [PAGE_NAMES.SIGNATURE_FOLLOWUP]: {
        [PAGE_NAMES.SIGNATURE_FOLLOWUP]: WORKFLOW_STATUSES.IN_PROGRESS,
        [PAGE_NAMES.VALIDATION]: WORKFLOW_STATUSES.COMPLETE,
        [PAGE_NAMES.SIGNATURE_MODE]: WORKFLOW_STATUSES.COMPLETE,
        default: WORKFLOW_STATUSES.INACTIVE,
      },
    };

    return pageStatuses[currentPage][routeLabel] || pageStatuses[currentPage].default;
  };

  const currentPage = activeRoute.split('/').pop().toLowerCase();
  const isEChangeSignature = activeRoute.includes('signatureechange') || activeRoute.includes('signaturechangement');
  if (currentPage === PAGE_NAMES.EChangeValidation || currentPage === PAGE_NAMES.EChangeValidationEN) {
    if ((route.label as Route['label'])?.en?.toLowerCase() === PAGE_NAMES.TRANSMISSION.toLowerCase()) {
      status = WORKFLOW_STATUSES.INACTIVE;
    } else if (
      (route.label as Route['label'])?.en?.toLowerCase() === PAGE_NAMES.VALIDATION.toLowerCase()
    ) {
      status = WORKFLOW_STATUSES.IN_PROGRESS;
    }
    return { ...route, status: status ?? route.status };
  }
  if (currentPage === PAGE_NAMES.EChangeSUMMARY) {
    if ((route.label as Route['label'])?.en?.toLowerCase() === PAGE_NAMES.VALIDATION.toLowerCase()) {
      status = WORKFLOW_STATUSES.INACTIVE;
    }
    return { ...route, status: status ?? route.status };
  }

  if (currentPage === PAGE_NAMES.TRANSMISSION) {
    if ((route.label as Route['label'])?.en?.toLowerCase() === PAGE_NAMES.SIGNATURE.toLowerCase()) {
      status = WORKFLOW_STATUSES.COMPLETE;
    } else if ((route.label as Route['label'])?.en?.toLowerCase() === PAGE_NAMES.TRANSMISSION) {
      status = WORKFLOW_STATUSES.IN_PROGRESS;
    } else {
      status = WORKFLOW_STATUSES.INACTIVE;
    }
    return { ...route, status };
  }

  if ([PAGE_NAMES.SIGNATURE_MODE, PAGE_NAMES.SIGNATURE_FOLLOWUP].includes(currentPage) && isEChangeSignature) {
    const routeLabel = (route.label as Route['label'])?.en?.toLowerCase();
    status = getStatus(routeLabel, currentPage);
    return { ...route, status };
  }
  if (isSummaryRoute(route)) {
    status = null;
  } else if ((route.label as Route['label'])?.en?.toLowerCase() === PAGE_NAMES.TRANSMISSION.toLowerCase()) {
    status = WORKFLOW_STATUSES.INACTIVE;
  } else if (isInactiveStatus(route)) {
    status = WORKFLOW_STATUSES.INACTIVE;
  } else {
    status = updateRouteStatus(route, activeRoute, lastVisitedRoute, routePath, lastStatuses, apiResponse, setLastStatuses);
  }
  return { ...route, status };
};

export default updateRouteStatuses;
