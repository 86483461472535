import { WORKFLOW_STATUSES } from 'ia-react-core';
import { ValidationMessage } from '~/interfaces/ValidationMessage';

const getRouteStatus = (apiResponse: ValidationMessage[], defaultStatus: string) => {
  if (!apiResponse || apiResponse.length <= 0) return defaultStatus;

  let lowestCategorie = 3;

  apiResponse.forEach((value) => {
    if (value.categorie < lowestCategorie) {
      lowestCategorie = value.categorie;
    }
  });

  switch (lowestCategorie) {
    case 2:
      return WORKFLOW_STATUSES.CAUTION;
    case 1:
      return WORKFLOW_STATUSES.WARNING;
    case 0:
      return WORKFLOW_STATUSES.ERROR;
    default:
      return WORKFLOW_STATUSES.COMPLETE;
  }
};

export default getRouteStatus;
