export default {
  illustration_configuration: "Configuration d'illustrations",
  province: 'Province',
  premiums_payment_frequency: 'Fréquence de versement des primes',
  monthly: 'Mensuelle',
  annually: 'Annuelle',
  compensation_bonus: 'Pourcentage de bonification',
  save: 'Sauvegarder',
  cancel: 'Annuler',
  bonus_info: "Le pourcentage de bonification indiqué dans ce champ sera utilisé dans le rapport de commission des illustrations. Ce pourcentage s'ajoute à la commission de la première année pour chacune des protections.",
  configuration_note: 'La valeur indiquée dans ce champ apparaît à titre indicatif seulement. Elle ne représente pas nécessairement le pourcentage de bonification qui vous sera accordé.',
  ok: 'OK',
  error: 'ERREUR!',
};
