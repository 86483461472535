import React from 'react';
// import { Row } from 'ia-react-core';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { StyledContainer, StyledRow } from '../LatestNews.styles';

const ErrorMessageNews = () => {
  const { t } = useTranslation('LatestNews');

  return (
    <StyledContainer>
      <StyledRow>{t('errorNoData')}</StyledRow>
      <section dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('errorTryAgain')) }} />
    </StyledContainer>

  );
};

export default ErrorMessageNews;
