export default {
  bornOn: 'Born on',
  age: 'Age',
  gender: 'Gender',
  tobaccoUse: 'Tobacco',
  parameters: 'Parameters',
  amount: 'Amount',
  premium: 'Premium',
  monthly: 'Monthly',
  annually: 'Annually',
  riders: 'Riders',
  deathReturn: 'Return of Premiums on Death (RPD)',
  flexibleReturn: 'Flexible Return of Premiums (FRP)',
  t75AndT100: 'T75 and T100 Flexible Return of Premiums',
  years15: 'Flexible Return of Premiums 15 years (FRP15)',
  years65: 'Flexible Return Of Premiums at 65 years (FRP65)',
  yes: 'Yes',
  no: 'No',
  frp15: 'FRP15',
  frp65: 'FRP65',
};
