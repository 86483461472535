const ENGLISH_ROUTES = {
  SUMMARY: '/summary',
  ADVISOR: '/advisor',
  CONSENT: '/consent',
  IDENTIFICATION: '/identification',
  INSURANCE_HISTORY: '/insurance-history',
  BENEFICIARY: '/beneficiary',
  APPLICANT: '/applicant',
  REQUIREMENTS: '/requirements',
  COVERAGES: '/coverages',
  HEADOFFICE: '/headoffice',
  LINKED_APPLICATIONS: '/linked-applications',
  BILLING: '/billing',
  PAC_PAD_AGREEMENT: '/pac-pad-agreement',
  VALIDATION: '/validation',
  SIGNATURE: '/signature',
  DECLARATIONS: '/declarations',
  SPECIAL_INSTRUCTIONS: '/special-instructions',
  TRANSMISSION: '/transmission',
  REINSTATEMENT: '/reinstatement',
  CHANGES: '/changes',
  HEADOFFICE_ECHANGE: '/ho-echange',
  SUMMARY_ECHANGE: '/Summary',
  VALIDATION_ECHANGE: '/validationechange',
  SIGNATURE_MODE: '/signature-mode',
  SIGNATURE_FOLLOWUP: '/signature-followup',
  TRANSMISSION_ECHANGE: '/transmissionechange',
  RESULTS: '/results',
};
export default ENGLISH_ROUTES;
