import React from 'react';
import {
  FormRow, FormCol, DropdownControl, Hidden, Visible,
  ToggleSwitchControl,
} from 'ia-react-core';
import { useTranslation } from 'react-i18next';

import usePremiumComparisonToolManager from '~/components/PremiumComparisonToolModal/hooks/usePremiumComparisonToolManager';
import { AMOUNT, PREMIUM } from '~/components/PremiumComparisonToolModal/constants';
import { acceptOnlyNumeric, acceptOnlyNumericDecimal } from '~/fragments/ApplicationFragment/utilities';
import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import { StyledParametersSectionCurrencyControl } from './ParametersSection.styles';

const ParametersSection = () => {
  const { t } = useTranslation('InsuredPersonsContent');
  const {
    amountPremiumToggleValue, pcToolFormValues, provinceDropDownOptions, onFormValuesChanged, onInputFieldChanged, handleOnInputFieldValuesChanged,
  } = usePremiumComparisonToolManager();

  const locale = useCurrentLocale();

  const onAmountPremiumToggleValueChangeHandler = (val: string) => {
    onFormValuesChanged({ amountPremiumToggle: val });
  };

  const onPaymentFrequencyToggleValueChangeHandler = (val: string) => {
    onFormValuesChanged({ paymentFrequency: val });
  };

  const onProvinceValueChangeHandler = (val: string) => {
    if (val !== pcToolFormValues?.province) onFormValuesChanged({ province: val });
  };

  return (
    <>
      <Visible xs>
        <FormRow>
          <FormCol col={6} col-xs={12} width="130px" labelMarginBottom={0}>
            {amountPremiumToggleValue === AMOUNT &&
            <StyledParametersSectionCurrencyControl
              name="enteredAmount"
              maxLength={8}
              valueToStateFormatters={[
                acceptOnlyNumeric,
              ]}
              onBlur={handleOnInputFieldValuesChanged}
              onChange={onInputFieldChanged}
            />}
            {amountPremiumToggleValue === PREMIUM &&
            <StyledParametersSectionCurrencyControl
              name="enteredPremium"
              maxLength={8}
              precision={2}
              forceDecimals
              valueToStateFormatters={[(value) => acceptOnlyNumericDecimal(value, locale)]}
              onBlur={handleOnInputFieldValuesChanged}
              onChange={onInputFieldChanged}
            />}
          </FormCol>
        </FormRow>
      </Visible>

      <FormRow>
        <FormCol
          width-xs="100%"
          width-sm="190px"
          col={6}
          col-xs={12}
          labelMarginBottom={0}
        >
          <ToggleSwitchControl
            name="amountPremiumToggle"
            options={[
              { label: t('amount'), value: AMOUNT },
              { label: t('premium'), value: PREMIUM },
            ]}
            fullWidth
            onValueChange={onAmountPremiumToggleValueChangeHandler}
          />
        </FormCol>
        <Hidden xs>
          <FormCol width="120px" labelMarginBottom={0}>
            {amountPremiumToggleValue === AMOUNT &&
              <StyledParametersSectionCurrencyControl
                name="enteredAmount"
                maxLength={8}
                defaultValue={100000}
                valueToStateFormatters={[
                  acceptOnlyNumeric,
                ]}
                onBlur={handleOnInputFieldValuesChanged}
                onChange={onInputFieldChanged}
              />}
            {amountPremiumToggleValue === PREMIUM &&
              <StyledParametersSectionCurrencyControl
                name="enteredPremium"
                maxLength={8}
                precision={2}
                forceDecimals
                valueToStateFormatters={[(value) => acceptOnlyNumericDecimal(value, locale)]}
                onBlur={handleOnInputFieldValuesChanged}
                onChange={onInputFieldChanged}
              />}
          </FormCol>
        </Hidden>
      </FormRow>
      <FormRow>
        <FormCol
          width-xs="100%"
          width-sm="201px"
          col={6}
          col-xs={12}
          labelMarginBottom={0}
        >
          <ToggleSwitchControl
            name="paymentFrequency"
            options={[
              { label: t('monthly'), value: 'Mensuel' },
              { label: t('annually'), value: 'Annuel' },
            ]}
            fullWidth
            onValueChange={onPaymentFrequencyToggleValueChangeHandler}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol
          col-xs={12}
          labelMarginBottom={0}
        >
          <DropdownControl
            name="province"
            options={provinceDropDownOptions()}
            onValueChange={onProvinceValueChangeHandler}
            noEmptyValue
          />
        </FormCol>
      </FormRow>
    </>
  );
};

export default ParametersSection;
