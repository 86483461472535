import styled from 'styled-components';
import { SideNavigationMenu } from '~/components/SideNavigationMenu';

export const StyledIllustrationSideNavigation = styled(SideNavigationMenu)<{ $isDrawer?: boolean }>`
  position: absolute;
  left: 0;
  margin-top: ${({ $isDrawer }) => $isDrawer ? 24 : 44}px;
  z-index: 99999;

  & > ol {
    border-bottom: none;
  }

  & > ol > li > a {
    background: transparent !important;
  }
`;
