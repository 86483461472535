export default {
  coverages: 'Coverages',
  amount: 'Amount',
  basePremium: 'Base Premium',
  totalPremium: 'Total Premium',
  rpd: 'RPD',
  frp20: 'FRP20',
  frp15: 'FRP15',
  frp65: 'FRP65',
  noCoverage: 'No coverage found for selected criteria.',
  calculation: 'Calculation in progress...',
};
