import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { Action } from 'redux';
import { UpdateNominee } from '../ApplicationFragment.actions';

export default function updateNomineeReducer(state: ApplicationFragmentState, action: Action): ApplicationFragmentState {
  const { nominee } = action as UpdateNominee;
  const updatedNomineeIndex = state.individuals.findIndex((item) => (item.id === nominee.id));
  const newNommees = [...state.individuals];
  newNommees[updatedNomineeIndex] = nominee;
  return {
    ...state,
    individuals: newNommees,
  };
}
