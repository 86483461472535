import { FoldersFilters } from '~/interfaces/FoldersPageState';

export type SaveFolderFilters = ReturnType<typeof saveFolderFilters>;
export const SAVE_FOLDERS_FILTERS = 'UpdateBeneficiaryType' as const;
export const saveFolderFilters = (filters: FoldersFilters) => ({
  type: SAVE_FOLDERS_FILTERS,
  filters,
});

export type HomeFragmentActions = SaveFolderFilters;
