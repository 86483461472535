export default {
  about: 'À propos',
  buildVersion: 'Version du build',
  mode: 'Mode',
  evoVersion: 'Version d\'Evo',
  userCode: 'Code utilisateur',
  microServices: 'Microservices',
  featureToggles: 'Toggles Actifs',
  logoDesc: 'iA Groupe financier est une marque de commerce et un autre nom sous lequel I\'lndustrielle Alliance. Assurance et services financiers inc. exerce ses activités.',
  close: 'Fermer',
  environment: 'Environnement',
  environmentOipa: 'Environnement OIPA',
  ADVISOR: 'Conseiller',
  HO_DATAENTRY: 'Siège social - Rôle saisie',
  HO_DATAENTRY_VIP: 'Siège social - Rôle saisie VIP',
  HO_SP_QOUTE: 'Siège social - Rôle cotation spéciale',
  HO_SP_QOUTE_VIP: 'Siège social - Rôle cotation spéciale VIP',
  STUDENT: 'Étudiant',
  GUEST: 'Invité',
  copyright: 'Copyright',
  ia: 'iA Groupe financier',
  development: 'Développement',
  production: 'Production',
  test: 'Test',
  qa: 'QA',
};
