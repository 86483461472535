import React, { FC } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import { SignaturePaperResourceProps } from '../interfaces/SignaturePaperInterface';
import RESOURCE_NAMES from '../constants/RESOURCE_NAMES';

const SignaturePaperSaveResource: FC<SignaturePaperResourceProps> = ({ saleId, onApiSuccess }) => {
  const url = getBffUrlForRouteWithoutQP(BffRoute.signature_saleId_paper, { saleId }, false);

  return (
    <Resource
      name={RESOURCE_NAMES.SIGNATURE_PAPER_SAVE_RESOURCE}
      url={url}
      method="POST"
      onSuccess={onApiSuccess}
    />
  );
};

export default SignaturePaperSaveResource;
