import { RouteDefinitions } from 'ia-react-core';
import React from 'react';
import FeaturesPage from '~/fragments/HomeFragment/pages/FeaturesPage';
import RouteGuard from '~/utilities/routeGuard';
import FoldersPage from '../pages/FoldersPage';
import CreateIllustrationPage from '../pages/CreateIllustrationPage';

export const APPLICATION_PATH = '/application/:saleId';

const HOME_ROUTES: RouteDefinitions = [
  {
    label: { fr: '', en: '' },
    path: '/',
    Component: () => (
      <RouteGuard requiredPermission={['HomePage.All']}>
        <CreateIllustrationPage />
      </RouteGuard>
    ),
  },
  {
    label: {
      fr: 'Dossiers',
      en: 'Folders',
    },
    path: {
      fr: '/dossiers',
      en: '/folders',
    },
    Component: () => (
      <FoldersPage />
    ),
  },
  {
    label: {
      fr: 'Nouveautés',
      en: 'Features',
    },
    path: {
      fr: '/nouveautes',
      en: '/features',
    },
    Component: () => (
      <FeaturesPage />
    ),
  },
];

export default HOME_ROUTES;
