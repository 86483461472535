import React, { FC, ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ActionBar,
  Form,
  FormCol,
  FormRow,
  InfoMessage, Modal, PrimaryButton, Resource, SecondaryButton, Spacing, T2, T5, TextControl,
  closeModalByName,
  requestResourceByName,
  setControlValueByName,
} from 'ia-react-core';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { AxiosResponse } from 'axios';
import HoistedServerErrors from '~/fragments/ApplicationFragment/components/HoistedServerErrors';
import { ValidationMessageLevel } from '~/interfaces/ValidationMessageLevel';
import { SaleFile } from '../interfaces/SaleFile';
import { StyledText, StyledTextHeading } from './FileSharingModal.styles';
export interface FileSharingModalProps {
  saleFile?: SaleFile;
}
const FileSharingModal: FC<FileSharingModalProps> = ({ saleFile }: FileSharingModalProps) => {
  const { t } = useTranslation('SaleFileSection');

  const dispatch = useDispatch();
  const [sharedKeyValue, setSharedKeyValue] = useState('');
  const [showFailError, setShowFailError] = useState(false);

  const shareFileUrl = getBffUrlForRoute(BffRoute.share_file, { saleId: saleFile?.id?.valeur });

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(sharedKeyValue).then((res) => res, () => Promise.reject(new Error('Error in copying to clipboard')));
  };

  const handleCloseModal = () => {
    dispatch(closeModalByName('modals.filesharing'));
  };
  const getSharingKey = () => {
    setShowFailError(false);
    dispatch(requestResourceByName('resources.shareFile'));
  };
  const onShareFailure = () => {
    setShowFailError(true);
  };
  const controlAnchorMap = {
    input: 'input',
  };

  return (
    <>
      <Resource
        name="shareFile"
        method="GET"
        url={shareFileUrl}
        onSuccess={(res) => {
          setShowFailError(false);
          const response = (res as AxiosResponse)?.data as { id: string };
          const id = response?.id;
          dispatch(setControlValueByName('forms.file_sharing_form', 'exportsharedkey', id));
          setSharedKeyValue(id);
          dispatch(requestResourceByName('resources.mySaleFiles'));
        }}
        onFailure={onShareFailure}
      />
      <Modal<{ content?: ReactNode; formValue?: SaleFile }>
        name="filesharingModal"
        onOpen={getSharingKey}
      >
        <T2>{t('fileSharingModal.title')}</T2>
        <InfoMessage>{t('fileSharingModal.infoContent')}</InfoMessage>
        <Spacing p-t={20}>
        </Spacing>
        <Form name="file_sharing_form" initialValues={{ exportsharedkey: '' }}>
          <HoistedServerErrors
            selector={() => null}
            extraMessages={showFailError ? [{
              categorie: ValidationMessageLevel.Error,
              messageEN: t('fileSharingModal.fileShareFailMessage'),
              messageFR: t('fileSharingModal.fileShareFailMessage'),
              parametresFormatage: [],
              nomModel: null,
              nomPropriete: null,
              numero: '',
              isInfoManquante: false,
              noParticipant: undefined,
              idsContexte: [],
            }] : []}
            controlAnchorMap={controlAnchorMap}
          />
          <FormRow>
            <FormCol label={t('fileSharingModal.sharedKeyTextLabel')}>
              <TextControl
                name="exportsharedkey"
                disabled
              />
            </FormCol>
          </FormRow>
          <T5>
            <StyledTextHeading>{t('fileSharingModal.reminder')}</StyledTextHeading>
            <Spacing p-b={4} p-t={4} />
            <StyledText>
              {t('fileSharingModal.reminderContent1')}
              <Spacing p-b={4} />
              {t('fileSharingModal.reminderContent2')}
            </StyledText>
          </T5>
          <ActionBar>
            <PrimaryButton onClick={handleCopyToClipboard}>{t('fileSharingModal.copyToClipboardButton')}</PrimaryButton>
            <SecondaryButton onClick={handleCloseModal}>{t('fileSharingModal.close')}</SecondaryButton>

          </ActionBar>

        </Form>

      </Modal>
    </>
  );
};
export default FileSharingModal;
