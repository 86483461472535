import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { ValidationMessage } from '~/interfaces/ValidationMessage';

export default function setPacpadValidationMessagesReducer(state: ApplicationFragmentState, action: { payload: Record<string, ValidationMessage> }): ApplicationFragmentState {
  const messages: ValidationMessage[] = Object.values(action.payload);

  return {
    ...state,
    PacPadAgreementPage: {
      ...state.PacPadAgreementPage,
      validations: messages,
    },
  };
}
