import SectionIdentityModel from '~/interfaces/InsurabilityDeclaration/SectionIdentityModel';
import SectionModel from '~/interfaces/InsurabilityDeclaration/SectionModel';
import SectionDto from '../dtos/SectionDto';
import QuestionBuilder from './QuestionBuilder';
import RepeatingSectionBuilder from './RepeatingSectionBuilder';

export default class SectionBuilder {
  toModel(dto: SectionDto): SectionModel {
    return new SectionModel(
      dto.id,
      dto.name,
      dto.title,
      dto.isActive,
      dto.progression,
      dto.sectionDisplayType,
      dto.canRepeat,
      dto.message,
    );
  }

  toIdentityModel(dto: SectionDto): SectionIdentityModel {
    const questionBuilder = new QuestionBuilder();
    const repeatingBuilder = new RepeatingSectionBuilder();

    const repeatedSection = dto.repeatingSections ?? [];

    return {
      id: dto.id,
      sections: dto.sections.map((s) => this.toIdentityModel(s)),
      questions: dto.questions.map((q) => questionBuilder.toIdentityModel(q)),
      repeatingSections: repeatedSection.map((rs) => repeatingBuilder.toIdentityModel(rs)),
    };
  }
}
