import { VALID_YEAR } from '~/constants/APP_CONSTANTS';

export const numericValidatorWithKeys = (event: any) => {
  const { key, ctrlKey } = event;
  const numberPattern = /^[0-9]*$/;
  const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp', 'Delete', 'Tab'];
  if (allowedKeys.includes(key) || (ctrlKey && key === 'v')) {
    return true;
  }
  if (!numberPattern.test(key)) {
    event.preventDefault();
  }
  return true;
};

export const isValidYear = (date: Date | string) => {
  let value = date;
  if (value === null || value === '') { return false; }
  if (typeof value === 'string') {
    value = new Date(value);
  }
  if (!(value instanceof Date) || Number.isNaN(value.getTime())) {
    return false;
  }
  const year = value?.getFullYear()?.toString();
  return VALID_YEAR.test(year);
};
