export enum RiderPlanCodes {
  increasedBenefits = 'INCBENEFIT',
  deathReturn = 'RPD',
  flexReturnTwenty = 'FRP20',
  flexReturnFifteen = 'FRP15',
  flexReturnSixtyFive = 'FRP65',
}
export const PREMIUM_COMPARISON_TOOL_MODAL_NAME = 'premiumComparisonTool';
export const PCT_FORM_NAME = 'pcTool';
export const UPDATE_INSURED_PROPERTY_RESOURCE_NAME = 'insuredPersonProperty';
export const UPDATE_P_C_TOOL_INDIVIDUAL_RESOURCE_NAME = 'updatePCToolIndividual';
export const P_C_TOOL_DATA_RESOURCE_NAME = 'pcToolData';
export const GET_PROVINCES_RESOURCE_NAME = 'provinces';
export const REFRESH_COVERAGES_OPTIONS_RESOURCE_NAME = 'newCoverageOptions';
export const UPDATE_SELECTED_PLAN_RESOURCE_NAME = 'selectedCoveragesPlan';
export const P_C_TOOL_INSURANCE_TYPE = 'Individuelle';
export const P_C_TOOL_PRODUCT_CODE = 'TRANSITION';
export const FRANCAIS = 'Francais';
export const ANGLAIS = 'Anglais';
export const FLEX_RETURN_CHECKED = true;
export const FLEX_RETURN_UNCHECKED = false;
export const AMOUNT = 'amount';
export const PREMIUM = 'premium';
export const FEMININ = 'Feminin';
export const MASCULIN = 'Masculin';
export const IS_PC_TOOL_MODAL_APPLY_BTN_DISABLED = 'isPcToolModalApplyBtnDisabled';
export const VALIDATE_CALCULATE_NAME = 'validateCalculate';
