import { ValidationMessageTypes } from './enums';

export default class ValidationMessageModel {
  constructor(
    public id: string,
    public message: string,
    public validationLevel: ValidationMessageTypes,
    public targetItem: string,
  ) {}
}
