import { FormCategoryType, Progression } from './enums';
import SectionIdentityModel from './SectionIdentityModel';
import ValidationMessageModel from './ValidationMessageModel';

export default class FormModel {
  constructor(
    public id: string,
    public name: string,
    public urlName: string,
    public title: string,
    public progression: Progression,
    public insureds: string[],
    public sections: SectionIdentityModel[],
    public validationMessages: ValidationMessageModel[],
    public category: FormCategoryType,
    public code: string,
    public status: number,
  ) { }
}
