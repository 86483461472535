import { createContext, useContext } from 'react';

interface ZoomContextValue {
  zoom: number;
  navWidth: number;
  minSideNav: number;
  minWidthRequiringOffset: number;
  maxWidthRequiringOffset: number;
}

export const ZoomContext = createContext({} as ZoomContextValue);

export const useZoom = () => useContext(ZoomContext);
