import {
  AddCircleIcon,
  ContentCopyIcon,
  Hidden,
  Resource,
  RestartAltIcon,
  openDrawerByName,
  openModalByName,
  requestResourceByName,
  useScopedSelector,
} from 'ia-react-core';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as DeleteOutlineIcon } from '~/assets/Icons/deleteOutline.svg';
import { IllustrationFragmentState } from '~/interfaces/IllustrationFragmentState';
import { ResponsiveMoreButton } from '~/components/ResponsiveMoreButton';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { useHistory, useParams } from 'react-router';
import useScenario from '~/fragments/IllustrationFragment/hooks/useScenario';
import constructScenarioActionName from '~/utilities/constructScenarioActionName';
import { ADD, COPY } from '~/constants/APP_CONSTANTS';
import { dispatchResourceByName } from '~/utilities/dispatchResourceByName';
import { ButtonLink } from '~/components/ButtonLink';
import { StyledNavLink, StyledScenarioTabContainer, StyledScenarioTabMobileContainer, StyledTabBar } from './ScenariosNav.styles';
import { IllustrationNavigationSelect } from '../IllustrationNavigationSelect/IllustrationNavigationSelect';
import ScenarioNavDeleteModal from './components/ScenarioNavDeleteModal';
import ScenarioNavResetModal from './components/ScenarioNavResetModal';

export interface ScenariosNavProps {}

const ScenariosNav: FC<ScenariosNavProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('App');
  const history = useHistory();
  const scenarios = useScopedSelector((state: IllustrationFragmentState) => state.data.scenarios);
  const scenarioManagement = useScopedSelector((state: IllustrationFragmentState) => state.data.manageScenario);
  const { saleId } = useParams<{ saleId: string }>();
  const currentScenario = useScenario();
  const addUrl = getBffUrlForRoute(BffRoute.add_scenario, { saleId });
  const copyUrl = getBffUrlForRoute(BffRoute.copy_scenario, { saleId, scenarioId: currentScenario?.idDemande });
  const manageUrl = getBffUrlForRoute(BffRoute.scenario_management, { saleId, scenarioId: currentScenario?.idDemande });
  const scenarioActionStorageName = constructScenarioActionName(saleId);
  const deletedScenarioIndexStorageName = `deleted-scenario-index-${saleId}`;

  useEffect(() => {
    dispatch(requestResourceByName('resources.manageScenario'));
  }, [dispatch]);

  const addResource = (
    <Resource
      name="addScenario"
      url={addUrl}
      method="POST"
      data-testid="add-scenario"
      onSuccess={() => {
        dispatch(requestResourceByName('resources.scenarios'));
        localStorage.setItem(scenarioActionStorageName, ADD);
      }}
    />
  );

  const copyResource = (
    <Resource
      name="copyScenario"
      url={copyUrl}
      method="POST"
      data-testid="copy-scenario"
      onSuccess={() => {
        dispatch(requestResourceByName('resources.scenarios'));
        localStorage.setItem(scenarioActionStorageName, COPY);
      }}
    />
  );

  const manageResource = (
    <Resource
      name="manageScenario"
      url={manageUrl}
      method="GET"
    />
  );

  const handleNavClick = (navScenarioId: string) => {
    history.push(`/illustration/${saleId}/${t('coverage')}?scenarioId=${navScenarioId}`);
    dispatch(requestResourceByName('resources.scenarios'));
  };

  const handleDeleteSuccess = (deletedScenarioId: string) => {
    const deletedScenarioIndex = scenarios.findIndex((scenario) => scenario?.idDemande === deletedScenarioId);
    localStorage.setItem(deletedScenarioIndexStorageName, JSON.stringify(deletedScenarioIndex));
  };

  return (
    <StyledTabBar>
      {addResource}
      {copyResource}
      {manageResource}
      <ScenarioNavDeleteModal onHandleDeleteSuccess={handleDeleteSuccess} />
      <ScenarioNavResetModal />
      <Hidden xs sm>
        <StyledScenarioTabContainer>
          {
            scenarios.map((scenario, index) => (
              <StyledNavLink
                key={`Scenario${scenario.idDemande}}`}
                fontSize={17}
                active={scenario?.idDemande === currentScenario?.idDemande}
                onClick={() => handleNavClick(scenario.idDemande)}
              >
                {t('scenario')} {index + 1}
              </StyledNavLink>
            ))
          }

          {scenarioManagement?.isDeleteScenarioVisible && (
            <ButtonLink
              LeftIcon={DeleteOutlineIcon}
              onClick={() => dispatch(openModalByName('modals.confirmDeleteScenario'))}
            >
              {t('delete')}
            </ButtonLink>
          )}

          {scenarioManagement?.isCopyScenarioVisible && (
            <ButtonLink
              LeftIcon={ContentCopyIcon}
              onClick={dispatchResourceByName('resources.copyScenario', dispatch)}
            >
              {t('copy')}
            </ButtonLink>
          )}

          {scenarioManagement?.isResetScenarioVisible && (
            <ButtonLink
              LeftIcon={RestartAltIcon}
              onClick={() => dispatch(openModalByName('modals.confirmResetScenario'))}
            >
              {t('reset')}
            </ButtonLink>
          )}

          {scenarioManagement?.isAddScenarioVisible && (
            <ButtonLink
              LeftIcon={AddCircleIcon}
              onClick={() => {
                localStorage.setItem(scenarioActionStorageName, ADD);
                dispatch(openModalByName('modals.createIllustration', { updateBaseCoverage: false }));
              }}
            >
              {t('add')}
            </ButtonLink>
          )}
        </StyledScenarioTabContainer>
      </Hidden>
      <Hidden md lg xl xxl>
        <StyledScenarioTabMobileContainer>
          <IllustrationNavigationSelect
            options={scenarios.map((scenario, index) => ({ label: `${t('scenario')} ${index + 1}`, value: scenario.id }))}
          />
          <ResponsiveMoreButton onClick={() => { dispatch(openDrawerByName('drawers.scenario')); }} />
        </StyledScenarioTabMobileContainer>
      </Hidden>
    </StyledTabBar>
  );
};

export default ScenariosNav;
