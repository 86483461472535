import { FC } from 'react';
import { compose } from 'redux';
import { injectTranslations } from 'ia-react-core';
import DeleteConfirmDialog, { DownloadConfirmDialogProps } from './DownloadConfirmDialog';
import fr from '../../translations/fr';
import en from '../../translations/en';

export default compose<FC<DownloadConfirmDialogProps>>(
  injectTranslations('makeEchange', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(DeleteConfirmDialog);

export * from './DownloadConfirmDialog';
