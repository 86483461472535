import React, { FC } from 'react';
import {
  Resource, ResourceProps, WORKFLOW_STATUSES,
} from 'ia-react-core';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useValidationMessageToFormValidations } from '~/components/AdvisorsModal/hooks';
import { State } from '~/interfaces/State';
import { BffRoute, getBffUrlForRoute } from '~/utilities/bffHelper';
import { ApiResponse } from '~/interfaces/ValidationMessage';
import { updateRouteStatus } from '~/App.actions';
import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';
import { setValidationMessagesLinkedApplicationsAction } from '../../../ApplicationFragment.actions';
import getStatusFromValidationMessages from '../../../utilities/getStatusFromValidationMessages';
import selectTargetRoute from '../../../selectors/selectTargetRoute';
import { addValidationMessagesAction } from '../LinkedApplicationsPage.actions';
import { LINKED_APPLICATIONS_FORM_NAME, UPDATE_LINKED_APPLICATIONS_RESOURCE_NAME } from '../LinkedApplicationsPage.constants';

export interface PostRequestForResourceProps extends ResourceProps<null, null> {}
export const UpdateLinkedApplicationsResource: FC<PostRequestForResourceProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const saleId = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.saleId);
  const targetRoute: string = useSelector(selectTargetRoute);
  const normalizeValidation = useValidationMessageToFormValidations();
  const currentRoute = useSelector((state: State) => state?.App?.fragments?.ApplicationFragment?.values?.routePath?.currentRoute);
  const backToHome = useSelector((state: State) => state?.App?.values?.homeStatus?.homeClicked);
  let intentType: ApplicationIntentType;
  let initateFromLeftMenu = true;
  return (
    <Resource
      name={UPDATE_LINKED_APPLICATIONS_RESOURCE_NAME}
      url={getBffUrlForRoute(BffRoute.linked_applications_add, { saleId })}
      method="POST"
      autoRequest={false}
      transformRequest={(payloadData: {intentType: ApplicationIntentType; initateFromLeftMenu: boolean}) => { intentType = payloadData.intentType; initateFromLeftMenu = payloadData.initateFromLeftMenu; return payloadData; }}
      onSuccess={(res) => {
        const { validationMessages } = (res?.data as ApiResponse);
        const messages = validationMessages || [];
        const status = getStatusFromValidationMessages(messages);
        if (backToHome) {
          history.block(true);
          history.push('/');
          return;
        }
        if (intentType !== ApplicationIntentType.Save) {
          dispatch(updateRouteStatus(currentRoute, status));
          dispatch(addValidationMessagesAction(messages));
          dispatch(setValidationMessagesLinkedApplicationsAction('LinkedApplicationsPage', LINKED_APPLICATIONS_FORM_NAME, normalizeValidation(messages)));
        }
        if (intentType === ApplicationIntentType.SaveAndValidate) {
          if (status === WORKFLOW_STATUSES.ERROR) {
            history.push(currentRoute);
            // we shouldn't push to targetRoute if the action is initiated from left menu
          } else if (!initateFromLeftMenu) {
            history.push(targetRoute);
          }
        }
      }}
    />
  );
};

export default UpdateLinkedApplicationsResource;
