import { ApplicationIntentType } from '~/fragments/ApplicationFragment/constants';

export interface InsuranceHistoryFormData {
  id: string;
  individualId: string;
  hasPendingInsurance: boolean;
  hasInsuranceRefused: boolean;
  hasInforceInsurance: boolean;
  pendingInsurance: PendingInsuranceType;
  insuranceRefused: DeclinedInsuranceType[];
  inforceInsurance: InforceInsuranceType[];
  selectedDeclinedInsurance?: DeclinedInsuranceType;
  selectedInforceInsurance?: InforceInsuranceType;
}

export interface InsuranceHistoryPageState extends InsuranceHistoryFormData {}

export enum ProtectionType {
  lifeInsurance = 1,
  criticalIllness = 2,
  disabilityInsurance = 4,
  lifeAndCritical = 3,
  lifeDisaility = 5,
  criticalDisability = 6,
  all = 7
}

export enum TypeProtectionHistoriqueEnum {
  Vie = 1,
  MaladieGrave = 2,
  Invalidite = 4,
}

export interface PendingInsuranceType {
  protectionsAmounts: any;
  id: string;
  protectionAmounts: InforceProtectionAmountsType[];
  selectedProtections?: TypeProtectionHistoriqueEnum[];
}

export interface PendingInsuranceFormData {
  id: string;

  hasLifeInsuranceCoverage: boolean;
  hasCriticalIllnessCoverage: boolean;
  hasDisabilityInsuranceCoverage: boolean;

  lifeInsuranceCoverageAmount: string ;
  criticalIllnessCoverageAmount: string;
  disabilityInsuranceCoverageAmount: string;

  selectedProtections?: TypeProtectionHistoriqueEnum[];
}

export interface ProtectionAmountsType {
  protectionType: ProtectionType;
  amount: number;
}

export interface DeclinedInsuranceType {
  id: string;
  refusalReason: string;
  yearSubmission: number;
  protectionType: number;
  formProtectionType: ProtectionType[];
}

export interface InforceInsuranceType {
  id: string;
  protectionAmounts: InforceProtectionAmountsType[];
  isContractIA: boolean;
  isNeedPersonal: boolean;
  isIssuedLast12Months: boolean;
  willReplaceInsurance: boolean;
  noContract: string;
  yearEmission: string;
}

export interface InforceInsuranceFormData {
  id: string;
  isContractIA: boolean;
  isIssuedLast12Months: boolean;
  isNeedPersonal: boolean;
  noContract: string;
  yearEmission: string;

  willReplaceInsurance: boolean;

  hasLifeInsuranceCoverage: boolean;
  hasCriticalIllnessCoverage: boolean;
  hasDisabilityInsuranceCoverage: boolean;

  lifeInsuranceCoverageAmount: string ;
  criticalIllnessCoverageAmount: string;
  disabilityInsuranceCoverageAmount: string;
}

export interface InforceProtectionAmountsType {
  protectionType: TypeProtectionHistoriqueEnum;
  amount: number;
}

export interface InsuranceHistoryPayload{
  intentType: ApplicationIntentType;
    insuranceHistoryViewModel: InsuranceHistoryFormData;
    shouldSave: boolean;
}
