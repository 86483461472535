import { requestResourceByName } from 'ia-react-core';
import { IntentType } from '~/interfaces/SignaturePageState';
import { SignaturePaperState } from '~/interfaces/SignaturePaperState';
import RESOURCE_NAMES from '../pages/SignaturePage/pages/SignatureFollowup/components/SignaturePaper/constants/RESOURCE_NAMES';

const signaturePaperApiCall = (dispatch: Function, formValues: SignaturePaperState) => {
  dispatch(requestResourceByName(`resources.${RESOURCE_NAMES.SIGNATURE_PAPER_SAVE_RESOURCE}`, { ...formValues, intentType: IntentType.Validate, initateFromLeftMenu: true }, { method: 'POST' }));
};

export default signaturePaperApiCall;
