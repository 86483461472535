import { useScopedSelector, P, T3 } from 'ia-react-core';
import React, { FC } from 'react';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ExcludedMedicalCondn } from '../constants';
import selectIsOnFirstForm from '../selectors/selectIsOnFirstForm';
import { StyledDisclaimerContainer, StyledListContainer } from './Disclaimer.styles';

export interface DisclaimerProps {
}

const Disclaimer: FC<DisclaimerProps> = () => {
  const match = useRouteMatch<{ formId: string; formName: string }>();
  const { t } = useTranslation('InsurabilityDeclaration');

  const { formName } = match.params;
  const isOnFirstForm = useScopedSelector(selectIsOnFirstForm)(formName);

  return (
    <>
      {isOnFirstForm &&
      <StyledDisclaimerContainer>
        <T3>{t('disclaimerTitle')} </T3>
        <P> {t('disclaimerSubTitle')} </P>
        <StyledListContainer>
          {ExcludedMedicalCondn.map((item: string) => <P as="li" key={item}> {t(`medicalConditions.${item}`)} </P>)}
        </StyledListContainer>
      </StyledDisclaimerContainer>}
    </>
  );
};

export default Disclaimer;
