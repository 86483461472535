import { Action } from 'redux';
import { call, put } from 'redux-saga/effects';
import { fatalError, LoadApplication, loadApplicationSuccess, isApplicationDataFetched } from '../ApplicationFragment.actions';
import ApplicationModel from '../interfaces/ApplicationModel';
import getApplication from '../api/getApplication';

export default function* loadApplicationWithoutreloadingSaga(action: Action) {
  const { saleId } = action as LoadApplication;
  yield put(isApplicationDataFetched(true));
  try {
    let application: ApplicationModel = null;
    application = yield call(getApplication, { saleId });
    yield put(loadApplicationSuccess(application));
  } catch (ex) {
    yield put(fatalError('Fatal error while loading application data', ex));
  } finally {
    yield put(isApplicationDataFetched(false));
  }
}
