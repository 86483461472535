import { Container, Row } from 'ia-react-core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import SectionIdentityModel from '~/interfaces/InsurabilityDeclaration/SectionIdentityModel';
import { AppState } from '~/interfaces/AppState';
import CompactQuestion from './Question/CompactQuestion';
import { StyledCol } from './Section.styles';

export interface CompactSectionProps {
  section: SectionIdentityModel;
  formId: string;
}

const CompactSection: FC<CompactSectionProps> = ({ formId, section: sectionIdentity }) => {
  const selectedCase = useSelector((s: AppState) => s.App?.fragments?.ApplicationFragment?.changeRequest);
  const section = selectedCase.sections[sectionIdentity.id];

  const questionsList = sectionIdentity.questions.map((q) => (
    <StyledCol
      key={q.id}
      xl={selectedCase.questions[q.id].size?.xl}
      lg={selectedCase.questions[q.id].size?.lg}
      md={selectedCase.questions[q.id].size?.md}
      sm={selectedCase.questions[q.id].size?.sm}
    >
      <CompactQuestion formId={formId} question={q} />
    </StyledCol>
  ));

  return (section.isActive && (
    <React.Fragment key={section.id}>
      {
        sectionIdentity.questions &&
        <Container>
          <Row>
            { questionsList.map((r) => r)}
          </Row>
        </Container>
      }
    </React.Fragment>
  ));
};

export default CompactSection;
