import { ValidationMessage } from './ValidationMessage';

export enum SignatureMode {
  None = 0,
  Electronic = 1,
  Paper = 2,
}

export interface Agent {
  id: string;
  titreAgent: string;
  codeAgent: string;
  codeAgence: string;
  prenom: string;
  nomFamille: string;
  nom: string;
  isDefault: true;
  isSuperviseur: true;
}

export interface DeclarationProposant {
  id: string;
  estInvestissementCorrespondProfil: boolean;
  raisonDivergenceProfil: string;
}

export enum IntentType {
  None = 0,
  Validate = 1,
  Save = 2,
  ValidateSave = 3,
}
export interface SignatureConfigure {
  intentType: IntentType;
  saleId: string;
  province: number | null;
  modeSignature: SignatureMode;
  agentCode: string | null;
  messageConditionsSignatureElectronique: string;
  language: number;
  agent: Agent;
  isQuestionLetterAuthorizationVisible: boolean;
  isSupervisorBlockVisible: boolean;
  afficherPreferencesCommunicationConseiller: boolean;
  isDeclarationProposantAvailable: boolean;
  codeAgentExists: boolean;
  hasLetterAuthorization: boolean | null;
  isElectronicSignatureEligible: boolean;
  isAgentAuthenticate: boolean | null;
  besoinContractPapier: boolean | null;
  declarationProposant: DeclarationProposant | null;
  validationMessages: ValidationMessage[];
  shouldSave: boolean;
}

export interface SignatureProvince {
  province: number;
  code: string;
  description: string;
}
