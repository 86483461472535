import { Resource, ResourceProps } from 'ia-react-core';
import React, { FC } from 'react';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';
import { UPDATE_SELECTED_PLAN_RESOURCE_NAME } from '../constants';

export interface UpdateSelectedPlanResourceProps extends ResourceProps<null, null> {
  saleId: string;
  scenarioId: string;
}

const UpdateSelectedPlanResource: FC<UpdateSelectedPlanResourceProps> = ({ saleId, scenarioId, ...props }) => {
  const url = getBffUrlForRoute(BffRoute.illustration_sales_salesId_scenarios_scenarioId_comparison_tool_selected_plan, { saleId, scenarioId }, false);

  return (
    <Resource
      name={UPDATE_SELECTED_PLAN_RESOURCE_NAME}
      method="PUT"
      url={url}
      {...props}
    />
  );
};

export default UpdateSelectedPlanResource;
