import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { FormSelectionError } from '../ChangeRequest.actions';

export function updateFormSelectionError(state: ApplicationFragmentState, action: Action) {
  const { payload } = action as FormSelectionError;
  return {
    ...state,
    values: {
      formSelectionErrors: payload,
    },
  };
}
