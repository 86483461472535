import { media } from 'ia-react-core';
import styled from 'styled-components';

export const StyledWorkflowLayoutContainer = styled.div`
  display: flex;
  max-width: 1440px;

  ${media.xs`
    width: auto;
    margin: 24px 0px;
  `}
  
  ${media.sm`
    margin: 32px 32px 48px 32px;
  `}

  ${media.lg`
    margin: 60px 32px;
  `}

  ${media.xxl`
    width: 100%;
    margin: 60px auto;
  `}

  form[name="forms.pacPadAgreement"] {
    ul>li>div {
      text-align: left;

      a[data-testid="Link"] {
        white-space: pre-line;
      }
    }
  }
`;

export const StyledWorkflowLayoutSideNavContainer = styled.div`
  margin-right: 40px;

  ${media.xs`
    display: none;
  `}

  ${media.lg`
    display: block;
    min-width: 360px;
  `}

  ${media.xl`
    min-width: 400px;
  `}
`;

export const StyledWorkflowLayoutContent = styled('div')`
  width: 100%;
`;
