import { Action } from 'redux';
import { DeleteSettingsAdvisorAction } from '../AdvisorsModal.actions';
import { AdvisorsModalState } from '../AdvisorsSettingsModalState';

export default function deleteSettingsAdvisorSuccess(state: AdvisorsModalState, action: Action): AdvisorsModalState {
  const { advisorId } = action as DeleteSettingsAdvisorAction;

  const currentAdvisorList = state?.data?.advisorList.filter((a) => a.id !== advisorId);

  return {
    ...state,
    data: {
      ...state.data,
      advisorList: currentAdvisorList,
    },
  };
}
