import { injectTranslations } from 'ia-react-core';
import { FC } from 'react';
import { compose } from 'redux';
import fr from './translations/fr';
import en from './translations/en';
import InformationModal, { InformationModalProps } from './InformationModal';

export default compose<FC<InformationModalProps>>(
  injectTranslations('InformationModal', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(InformationModal);

export * from './InformationModal';
