import { ProtectionType } from '~/interfaces/InsuranceHistoryPageState';

const mapProtectionType = (protectionType: ProtectionType): ProtectionType[] => {
  let response: ProtectionType[];
  switch (protectionType) {
    case ProtectionType.lifeAndCritical:
      response = [ProtectionType.lifeInsurance, ProtectionType.criticalIllness];
      break;
    case ProtectionType.lifeDisaility:
      response = [ProtectionType.lifeInsurance, ProtectionType.disabilityInsurance];
      break;
    case ProtectionType.criticalDisability:
      response = [ProtectionType.criticalIllness, ProtectionType.disabilityInsurance];
      break;
    case ProtectionType.all:
      response = [ProtectionType.lifeInsurance, ProtectionType.criticalIllness, ProtectionType.disabilityInsurance];
      break;
    default:
      response = [protectionType];
  }
  return response;
};

export default mapProtectionType;
