import { useCallback, useEffect, useState } from 'react';

const useMedia = (mediaQuery: string) => {
  const [result, setResult] = useState(window.matchMedia(`${mediaQuery}`).matches);

  const updateResult = useCallback((event: MediaQueryListEvent) => {
    setResult(event.matches);
  }, []);

  const listenToChange = useCallback((media: MediaQueryList, callback: (event: MediaQueryListEvent) => void) => {
    if (media.addEventListener) {
      media.addEventListener('change', callback);
      return () => {
        media.removeEventListener('change', callback);
      };
    }

    media.addListener(callback);
    return () => {
      media.removeListener(callback);
    };
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`${mediaQuery}`);
    return listenToChange(media, updateResult);
  }, [listenToChange, updateResult]);

  return result;
};

export default useMedia;
