import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRelativeUrlForRoute, relativeRoute } from '~/utilities/bffHelper';
import { State } from '~/interfaces/State';
import { getLastAccessedLocalizedUrl, getLastAccessedPageParams, getLastAccessedRelativeUrl, getPageType, isPageUrlValid } from '~/utilities/lastAccesedPageNavigation';
import { LANGUAGE_EN, LANGUAGE_FR } from '~/constants/APP_CONSTANTS';
import { StyledTooltipContainer } from '../SaleFileDataTable/SaleFileDataTable.styles';
import { SaleFile } from '../interfaces/SaleFile';
import { Stage, stagesBeforeApplication } from '../constants';
import { StyledFileNameCellLink } from './FileNameCell.styles';
import translatePageId from '../helpers/translatePageId';

export interface FileNameCellProps {
  saleFile: SaleFile;
  isChangeRequest?: boolean;
  handleSaleMigrate: (saleFile: SaleFile) => void;
  handleRelativePath: (relativePath: string) => void;
}

const FileNameCell = ({ saleFile, isChangeRequest, handleSaleMigrate, handleRelativePath }: FileNameCellProps) => {
  const lang = useSelector((state: State) => state?.language?.locale) as 'en' | 'fr' ?? 'fr';

  const relativePath = useMemo(() => {
    const params = { saleId: saleFile.id.valeur };
    const changeParams = { saleId: saleFile.id.valeur };
    const { lastAccessedDetails } = saleFile;
    const saleId = saleFile.id.valeur;

    let illustrationRouteUrlEn = getRelativeUrlForRoute(relativeRoute.illustration_coverage_saleId, params);
    let illustrationouteUrlFr = getRelativeUrlForRoute(relativeRoute.illustration_couverture_saleId, params);
    let applicationRouteUrlEn = getRelativeUrlForRoute(relativeRoute.application_advisor_en, params);
    let applicationRouteUrlFr = getRelativeUrlForRoute(relativeRoute.application_advisor_fr, params);
    let changeRouteUrlEn = getRelativeUrlForRoute(relativeRoute.change_route, changeParams);
    let changeRouteUrlFr = getRelativeUrlForRoute(relativeRoute.changements_route, changeParams);
    if (lastAccessedDetails && lastAccessedDetails.lastAccessedPageId) {
      const pageId = translatePageId(isChangeRequest, lastAccessedDetails.lastAccessedPageId.valueOf());
      const pageParams = getLastAccessedPageParams(saleId, lastAccessedDetails.additionalDetails);
      const routeUrlEn = getLastAccessedRelativeUrl(getLastAccessedLocalizedUrl(pageId, LANGUAGE_EN), pageParams);
      const routeUrlFr = getLastAccessedRelativeUrl(getLastAccessedLocalizedUrl(pageId, LANGUAGE_FR), pageParams);
      switch (getPageType(pageId)) {
        case 'illustration':
          illustrationRouteUrlEn = isPageUrlValid(routeUrlEn) ? routeUrlEn : illustrationRouteUrlEn;
          illustrationouteUrlFr = isPageUrlValid(routeUrlFr) ? routeUrlFr : illustrationouteUrlFr;
          break;
        case 'application':
          applicationRouteUrlEn = isPageUrlValid(routeUrlEn) ? routeUrlEn : applicationRouteUrlEn;
          applicationRouteUrlFr = isPageUrlValid(routeUrlFr) ? routeUrlFr : applicationRouteUrlFr;
          break;
        case 'changeRequest':
          changeRouteUrlEn = isPageUrlValid(routeUrlEn) ? routeUrlEn : changeRouteUrlEn;
          changeRouteUrlFr = isPageUrlValid(routeUrlFr) ? routeUrlFr : changeRouteUrlFr;
          break;
        default:
          break;
      }
    }

    const routeMap = {
      illustration: {
        en: illustrationRouteUrlEn,
        fr: illustrationouteUrlFr,
      },
      application: {
        en: applicationRouteUrlEn,
        fr: applicationRouteUrlFr,
      },
      change: {
        en: changeRouteUrlEn,
        fr: changeRouteUrlFr,
      },
    };

    if (saleFile.progression === Stage.transferee && !saleFile.numeroContrat) {
      return routeMap.illustration[lang];
    }

    const isBeforeApplication = stagesBeforeApplication.includes(saleFile.progression as Stage);
    if (isChangeRequest) {
      return routeMap.change[lang];
    }

    return isBeforeApplication ? routeMap.illustration[lang] : routeMap.application[lang];
  }, [lang, saleFile, isChangeRequest]);

  const handleMigrator = () => {
    handleSaleMigrate(saleFile);
    handleRelativePath(relativePath);
  };

  return (
    <StyledTooltipContainer>
      <StyledFileNameCellLink
        href="#"
        onClick={handleMigrator}
      >
        {saleFile.nomVente.toLocaleUpperCase()}
      </StyledFileNameCellLink>
    </StyledTooltipContainer>
  );
};
export { FileNameCell };
