export const RELATIONS_RESOURCE = 'Relations';
export const BENEFICIARIES_FORM = 'beneficiaries';
export const UPDATE_BENEFICIARY_RESOURCE_NAME = 'updateBeneficiary';
export const BENEFICIARY_CANDIDATES_RESOURCE_NAME = 'BeneficiaryCandidates';

export enum ValidationMessageSections {
  life = 'lifeInsuranceBeneficiaries',
  criticalIllness = 'criticalIllinessBeneficiaries',
  premium = 'premiumBeneficiaries',
  premiumFlexible = 'premiumFlexibleBeneficiaries',
  accumulation = 'accumulationBeneficiaries',
  accumulationModified = 'accumulationBeneficiary',
}

export const NOT_ALLOWED_NAMES = ['madame',
  'monsieur',
  'mme',
  'mlle',
  'mr',
  'mrs',
  'sir',
  'miss',
  'mister',
  'madam',
  'heritier legaux',
  'estate',
  'heritiers legaux',
  'heritiers',
  'heritier',
  'legaux',
  'enfant',
  'child',
  'un',
  'one',
  'jr',
  'sr'];
