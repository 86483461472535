import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { ValidationMessage } from '~/interfaces/ValidationMessage';

export default function setApplicantValidationMessagesReducer(state: ApplicationFragmentState, action: { payload: Record<string, ValidationMessage> }): ApplicationFragmentState {
  const messages: ValidationMessage[] = Object.values(action.payload);

  return {
    ...state,
    ApplicantPage: {
      ...state.ApplicantPage,
      values: {
        ...state.ApplicantPage.values,
        validationMessages: messages,
      },
    },
  };
}
