import styled from 'styled-components';

export const StyledDiv = styled.div`
  display:flex;
  row-gap: 0px;
  column-gap: 88px;

  @media (max-width: ${({ theme }) => theme.breakpoints.XS[1]}px) {
    flex-direction: column;
    row-gap: 24px;
    column-gap: 0px;
  }
`;

export const StyledModalDiv = styled.div`
  width: 100%;
  padding-top: 24px;
`;

export const StyledLoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
`;
