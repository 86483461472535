import { useCallback } from 'react';
import { useRouteMatch, ExtractRouteParams, generatePath } from 'react-router';
import { CHANGES_ROUTES_KEY, ChangesRoutes } from '~/constants/CHANGES_ROUTES';
import { useCurrentLocale } from '../../ChangeFragment/hooks/useCurrentLocale';

export function useChangeRoute() {
  const locale = useCurrentLocale();
  const match = useRouteMatch();

  function localizedURI<S extends string>(routeKey: CHANGES_ROUTES_KEY, params?: ExtractRouteParams<S>) {
    const { [routeKey]: route } = ChangesRoutes;

    const paths = route?.path as {fr: string; en: string};
    const localizedPath = paths?.[locale];

    return generatePath(localizedPath, { ...match?.params, ...params });
  }

  return useCallback(localizedURI, [locale, match]);
}
