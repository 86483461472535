import { ComponentClass } from 'react';
import { compose } from 'redux';
import { injectTranslations } from 'ia-react-core';
import OipaEnvironmentsModal from './OipaEnvironmentsModal';
import fr from './translations/fr';
import en from './translations/en';

export default compose<ComponentClass>(
  injectTranslations('OipaEnvironmentsModal', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
)(OipaEnvironmentsModal);
