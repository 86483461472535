import {
  Block,
  Form, FormCol, FormRow,
  InternalLink,
  PrimaryPage,
} from 'ia-react-core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Rolecontrol from './components/RoleControl';
import RoleList from './components/RoleList';

export interface RolesPageProps { }

const RolesPage: FC<RolesPageProps> = () => {
  const { t } = useTranslation('RolesPage');

  return (
    <PrimaryPage>
      <Block>
        <>
          <Form name="roles" minColWidth={60}>
            <FormRow defaultCol={3}>
              <FormCol label={t('label')}>
                <Rolecontrol />
              </FormCol>
            </FormRow>
            <FormCol label={t('permissions')} col={3}>
              <RoleList />
            </FormCol>
          </Form>
          <InternalLink href="/">{t('home')}</InternalLink>
        </>
      </Block>
    </PrimaryPage>
  );
};

export default RolesPage;
