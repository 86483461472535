import { ValidatorMap } from 'ia-react-core';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { Locale, useCurrentLocale } from '~/fragments/ApplicationFragment/hooks/useCurrentLocale';
import { ValidationMessage } from '~/interfaces/ValidationMessage';

function useLocalizedValidationMessage(): (validationMessage: ValidationMessage) => string {
  const locale = useCurrentLocale();

  return useCallback((validationMessage: ValidationMessage) =>
    locale === Locale.FR ? validationMessage.messageFR : validationMessage.messageEN, [locale]);
}

export function lowercaseFirstLetter(str: string): string {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function useValidationMessageToFormValidations<Value>():
  (validations: ValidationMessage[]) => ValidatorMap<Value> {
  const localizeValidationMessage = useLocalizedValidationMessage();

  return useCallback(
    (validations: ValidationMessage[]) =>
      validations?.reduce((acc, curr) => {
        const fieldName = curr.nomPropriete ?? `custom_${uuid()}`;
        const propertyName = `${lowercaseFirstLetter(fieldName)}.${curr.numero}_${uuid()}`;
        return ({
          ...acc,
          [propertyName]: {
            validator: () => true,
            message: localizeValidationMessage(curr),
            type: curr.categorie,
          },
        });
      }, {}),
    [localizeValidationMessage],
  );
}
