import { ResourceProps } from 'ia-react-core';
import React, { FC, useMemo } from 'react';
import { getBffUrlForRoute, BffRoute } from '~/utilities/bffHelper';
import { ADD_ADVISOR_FORM } from '~/components/AdvisorsModal/constants';
import { useParams } from 'react-router';
import { REQUEST_FOR_RESOURCE } from '../pages/AdvisorPage/constants';
import ResourceFormValidation, { ResourceFormValidationProps } from '../pages/AdvisorPage/resources/ResourceFormValidation';

export interface SaveOrValidateAdvisorResourceProps extends ResourceProps<null, null> {
  saleId: string;
}

const SaveOrValidateAdvisorResource: FC<SaveOrValidateAdvisorResourceProps & ResourceFormValidationProps> = (
  { formName, onValidationAPISuccess, ...props },
) => {
  const { saleId } = useParams<{ saleId: string; clientId: string }>();

  const requestForUrl = useMemo(() => getBffUrlForRoute(
    BffRoute.request_for_application_saleId,
    { saleId },
  ), [saleId]);
  return (
    <ResourceFormValidation
      name={REQUEST_FOR_RESOURCE}
      onValidationAPISuccess={onValidationAPISuccess}
      formName={ADD_ADVISOR_FORM}
      page="AdvisorPage"
      url={requestForUrl}
      method="POST"
      {...props}
    />
  );
};

export default SaveOrValidateAdvisorResource;
