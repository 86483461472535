import React, { FC, useMemo } from 'react';
import { Resource } from 'ia-react-core';
import { BffRoute, getBffUrlForRouteWithoutQP } from '~/utilities/bffHelper';
import RESOURCE_NAMES from '~/fragments/ChangeFragment/constants/RESOURCE_NAMES';
import { AxiosResponse } from 'axios';

export interface UpdateInsuredInformationResourceProps {
  saleId: string;
  insuredId: string;
  onSuccess?: (response: AxiosResponse) => void;
}

const UpdateInsuredInformationResource: FC<UpdateInsuredInformationResourceProps> = ({ saleId, insuredId, onSuccess }: UpdateInsuredInformationResourceProps) => {
  const updateInsuredInformationUrl = useMemo(() => getBffUrlForRouteWithoutQP(BffRoute.update_insured_information, { saleId, insuredId }), [saleId, insuredId]);

  return (
    <Resource
      name={RESOURCE_NAMES.UPDATE_INSURED_INFORMATION_RESOURCE}
      url={updateInsuredInformationUrl}
      method="PUT"
      onSuccess={onSuccess}
    />
  );
};

export default UpdateInsuredInformationResource;
