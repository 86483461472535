import { WorkflowStatus } from 'ia-react-core';

const getHighestPriorityStatus = (
  childStatuses: WorkflowStatus[],
  STATUS_PRIORITIES: Record<WorkflowStatus, number>,
  defaultStatus: WorkflowStatus,
): WorkflowStatus => childStatuses.reduce(
  (highestStatus, currentStatus) =>
    STATUS_PRIORITIES[currentStatus] < STATUS_PRIORITIES[highestStatus] ? currentStatus : highestStatus,
  defaultStatus,
);

export default getHighestPriorityStatus;
