import { RouteDefinition, RouteSectionDefinition } from 'ia-react-core';
import { APPLICATION_ROUTES_KEY } from '~/constants/APPLICATION_ROUTES';
import { Roles } from '~/pages/Roles/constants';
import isHeadOfficeMode from './isHeadOfficeMode';

const updateApplicationRoutes = (routes: Record<APPLICATION_ROUTES_KEY, RouteSectionDefinition | RouteDefinition>, saleId: string, userRole?: Roles) => {
  if (!isHeadOfficeMode(userRole)) {
    delete routes[APPLICATION_ROUTES_KEY.HEADOFFICE];
  }

  const updatedRoutes = Object?.values(routes)?.map((route: RouteSectionDefinition) => {
    const path = route?.path as { fr: string; en: string };
    const en = path?.en.replace(/:saleId/gi, saleId);
    const fr = path?.fr.replace(/:saleId/gi, saleId);

    return { ...route, path: { en, fr } as { fr: string; en: string } };
  });
  return updatedRoutes;
};

export const updateApplicationInnerRouteForRouteName = (routes: Record<APPLICATION_ROUTES_KEY, RouteSectionDefinition | RouteDefinition>, saleId: string, routeName: string) => {
  const updatedRoutes = Object?.values(routes)?.map((route: RouteSectionDefinition) => {
    if (route.name === routeName && route.routes) {
      const innerRoutes = route.routes?.map((innerRoute: RouteSectionDefinition) => {
        const path = innerRoute?.path as { fr: string; en: string };
        const en = path?.en.replace(/:saleId/gi, saleId);
        const fr = path?.fr.replace(/:saleId/gi, saleId);
        return { ...innerRoute, path: { en, fr } as { fr: string; en: string } };
      });
      const { label } = route;
      return {
        routes: innerRoutes, label,
      };
    }
    return route;
  });

  return updatedRoutes;
};

export default updateApplicationRoutes;
