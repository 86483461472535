import styled from 'styled-components';
import { ActionBar, FormCol, T2, P } from 'ia-react-core';

export const StyledT2 = styled(T2)`
  margin-bottom: 24px;
`;

export const StyledFormCol = styled(FormCol)`
  margin-bottom: 8px;
`;

export const StyledActionBar = styled(ActionBar)`
  padding-top: 32px;
`;

export const StyledIllustrationSettingsModalActionBar = styled(ActionBar)`
  padding: 0px;
`;

export const StyledIllustrationSettingsModalDividerContainer = styled.div`
  display: flex;
  height: 18px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
`;

export const StyledIllustrationSettingsModalDivider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
  width: 100%;
`;

export const StyledIllustrationSettingsModalFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledIllustrationSettingsModalDescription = styled(P)`
  margin-bottom: 0px;
`;
