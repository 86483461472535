import { RouteDefinition, RouteSectionDefinition } from 'ia-react-core';
import React from 'react';
import ApplicationFragmentLayout from '~/fragments/ApplicationFragment/layouts/ApplicationFragmentLayout';
import HomeFragment from '~/fragments/HomeFragment';
import AppLayout from '~/layouts/AppLayout';
import RouteGuard from '~/utilities/routeGuard';
import ILLUSTRATION_ROUTES from '~/fragments/IllustrationFragment/constants/ILLUSTRATION_ROUTES';
import IllustrationFragment from '../fragments/IllustrationFragment';
import RolesPage from '../pages/Roles';
import APPLICATION_ROUTES from './APPLICATION_ROUTES';

const APPLICATION_PATH = '/application/:saleId';

const ROUTES_BY_NAME = {
  ROLES_PAGE: {
    label: {
      fr: 'Roles',
      en: 'Roles',
    },
    path: {
      fr: '/roles',
      en: '/roles',
    },
    Component: () => (<AppLayout><RolesPage /></AppLayout>),
  },
  HOME_FRAGMENT: {
    label: { fr: '', en: '' },
    path: '/*',
    exact: false,
    Component: () => (
      <RouteGuard requiredPermission={['HomePage.All']}>
        <HomeFragment />
      </RouteGuard>
    ),
  },
  ILLUSTRATION_FRAGMENT: {
    label: 'Illustration',
    path: '/illustration/:saleId',
    Component: () => (<RouteGuard requiredPermission={['Illustration.All']}><IllustrationFragment /></RouteGuard>),
    routes: ILLUSTRATION_ROUTES,
  },
  APPLICATION_FRAGMENT: {
    label: 'Application',
    path: APPLICATION_PATH,
    Component: () => (<RouteGuard requiredPermission={['Application.All']}><ApplicationFragmentLayout /></RouteGuard>),
    routes: APPLICATION_ROUTES,
  },
} as const satisfies Record<string, (RouteDefinition | RouteSectionDefinition)>;

export default ROUTES_BY_NAME;
