import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { SetValidateClicked } from '../ApplicationFragment.actions';

export default function setValidate(state: ApplicationFragmentState, action: Action): ApplicationFragmentState {
  const { clicked } = action as SetValidateClicked;
  return {
    ...state,
    isValidateClicked: clicked,
  };
}
