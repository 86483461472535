import { AddCircleIcon, T2, TertiaryButton } from 'ia-react-core';
import styled from 'styled-components';

export const StyledAdvisorTableScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;

  & > div {
    width: 100%;
  }
`;

export const StyledAdvisorTableScreenTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`;

export const StyledAdvisorTableScreenDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
`;

export const StyledAdvisorTableScreenTitle = styled(T2)`
  line-height: 28px;
`;
StyledAdvisorTableScreenTitle.defaultProps = {
  marginBottom: 0,
};

export const StyledAdvisorTableScreenAddButton = styled(TertiaryButton)`
  & > span > svg {
    margin-right: 4px;
  }
`;
StyledAdvisorTableScreenAddButton.defaultProps = {
  LeftIcon: AddCircleIcon,
  text: true,
};
