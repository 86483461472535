import { PAGE_ID_APPLICATION_TRANSMISSION, PAGE_ID_ECHANGE_TRANSMISSION, PAGE_ID_ECHANGE_SIGNATUREMODE, PAGE_ID_APPLICATION_SIGNATUREMODE, PAGE_ID_ECHANGE_SIGNATURE_FOLLOW_UP, PAGE_ID_APPLICATION_SIGNATURE_FOLLOW_UP, PAGE_ID_APPLICATION_ADVISOR, PAGE_ID_ECHANGE_ADVISOR } from '~/constants/PAGE_IDS';

const translatePageId = (isChangeRequest: boolean, pageId: number) => {
  if (!isChangeRequest && pageId === PAGE_ID_APPLICATION_TRANSMISSION) {
    return PAGE_ID_APPLICATION_TRANSMISSION;
  }
  if (isChangeRequest && pageId === PAGE_ID_APPLICATION_TRANSMISSION) {
    return PAGE_ID_ECHANGE_TRANSMISSION;
  }
  if (!isChangeRequest && pageId === PAGE_ID_ECHANGE_SIGNATUREMODE) {
    return PAGE_ID_APPLICATION_SIGNATUREMODE;
  }
  if (!isChangeRequest && pageId === PAGE_ID_ECHANGE_SIGNATURE_FOLLOW_UP) {
    return PAGE_ID_APPLICATION_SIGNATURE_FOLLOW_UP;
  }

  if (pageId === PAGE_ID_APPLICATION_ADVISOR) {
    return isChangeRequest ? PAGE_ID_ECHANGE_ADVISOR : PAGE_ID_APPLICATION_ADVISOR;
  }

  return pageId;
};

export default translatePageId;
