import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { UpdateAccountHolder } from '../ApplicationFragment.actions';

export const updateAccountHolderReducer = (state: ApplicationFragmentState, action: Action): ApplicationFragmentState => {
  const { payload } = UpdateAccountHolder.getData(action);

  const newAccountHolders = structuredClone(state.values.pacPadAgreement.accountHolders);
  const index = newAccountHolders.findIndex((x) => x.id === payload.accountHolder.id);

  if (index >= 0) {
    newAccountHolders[index] = payload.accountHolder;
  }

  return {
    ...state,
    values: {
      ...state.values,
      pacPadAgreement: {
        ...state.values.pacPadAgreement,
        accountHolders: newAccountHolders,
      },
    },
  };
};
