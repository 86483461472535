import { Action } from 'redux';
import { ApplicationFragmentState } from '~/interfaces/ApplicationFragmentState';
import { SetNumberOfFormsSelected } from '../ApplicationFragment.actions';

export default function SetNumberOfForms(state: ApplicationFragmentState, action: Action): ApplicationFragmentState {
  const { formCount } = action as SetNumberOfFormsSelected;

  return {
    ...state,
    numberOfSelectedForms: formCount,
  };
}
