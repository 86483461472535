export default {
  newSaleTitle: 'Nouvelle vente',
  myFilesTitle: 'Mes dossiers',
  accessLifeTitle: 'Accès Vie',
  otherProductsTitle: "Autres produits d'assurance",
  lookingOtherProductsTitle:
    'Vous recherchez un autre produit EVO ou Assure & Go ?',
  lookingOtherProductsDescription:
    "Tous nos produits sont toujours disponibles via les applications habituelles, tandis que cette nouvelle version d'EVO se concentre sur l'émission simplifiée.",
  launchEvoApp: "Lancer l'application de bureau EVO",
  seeAllProducts: 'Voir tous les produits',
  dontHaveEvoApp: "Vous n'avez pas l'application ?",
  downloadIt: 'Téléchargez-la',
  createIllustration: '<strong>Illustration</strong><br/> Accès Vie',
  startApplication: '<strong>Proposition</strong><br/> Accès Vie',
};
