import { ComponentClass } from 'react';
import { compose } from 'redux';

import { injectReducer, injectSaga, injectTranslations } from 'ia-react-core';

import fr from './translations/fr';
import en from './translations/en';
import globalSaga from './saga';
import reducer from './reducer';
import AdvisorsModal from './AdvisorsModal';

export default compose<ComponentClass>(
  injectTranslations('AdvisorsModal', [
    { language: 'fr', resource: fr },
    { language: 'en', resource: en },
  ]),
  injectReducer({
    key: 'components.AdvisorsModal',
    reducer,
    useScope: true,
  }),
  injectSaga({
    key: 'AdvisorModal',
    saga: globalSaga,
  }),
)(AdvisorsModal);
