import React, { FC } from 'react';
import {
  closeModalByName,
  ErrorModal,
  PrimaryButton,
  useTranslation,
} from 'ia-react-core';
import { useDispatch } from 'react-redux';
import { StyledModalDiv } from '../../PrintReportModal.styles';

export interface ErrorPrintModalProps {}

const ErrorPrintModal: FC<ErrorPrintModalProps> = () => {
  const { t } = useTranslation('PrintReportModal');
  const dispatch = useDispatch();
  const onCloseHandler = () => {
    dispatch(closeModalByName('modals.printError'));
  };

  return (
    <ErrorModal name="printError" isClosable={false} align="center">
      {t('errorMessage')}
      <StyledModalDiv>
        <PrimaryButton
          onClick={onCloseHandler}
        >
          {t('okText')}
        </PrimaryButton>
      </StyledModalDiv>
    </ErrorModal>
  );
};

export default ErrorPrintModal;
