import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { TextInput } from 'ia-react-core';
import { NumberFieldUtil } from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/utils/field.utils';
import { QuestionDisplayType } from '~/interfaces/InsurabilityDeclaration/enums';
import QuestionField from './QuestionField';

const NOT_NUMBERS_WITH_DECIMAL = /[^0-9.]/;
const MAX_CURRENCY_LENGTH = 14;
const MAX_PERCENTAGE_LENGTH = 3;
const DEFAULT_NUMBER_LENGTH = 2000;

const FloatField: QuestionField = ({ question, onChange, hasError, hasWarning, ...props }) => {
  const [value, setValue] = useState(((question.answers[0]?.value?.toString()) || ''));
  const [focused, setFocused] = useState(false);

  const debounced = useMemo(() => debounce(onChange, 500, { trailing: true }), [onChange]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    // Ensure only numbers and period in value
    const newValue = event.target.value.replace(NOT_NUMBERS_WITH_DECIMAL, '');
    if (newValue !== value) {
      setValue(newValue);
      const parsedValue = Number.parseFloat(newValue);
      debounced([parsedValue]);
    }
  }, [debounced, value]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const { symbol, numberFormatter } = NumberFieldUtil.useNumberFormatter(question.displayType, false);

  let maxLength = DEFAULT_NUMBER_LENGTH;
  switch (question.displayType) {
    case QuestionDisplayType.Currency:
      maxLength = MAX_CURRENCY_LENGTH;
      break;
    case QuestionDisplayType.Percentage:
      maxLength = MAX_PERCENTAGE_LENGTH;
      break;
    default:
      maxLength = DEFAULT_NUMBER_LENGTH;
      break;
  }

  // Display formatted value when focused and plain number when unfocused
  const displayValue = useMemo(() => {
    if (focused) {
      return value;
    }
    const parsedValue = Number.parseFloat(value);
    return Number.isNaN(parsedValue) ? '' : numberFormatter(parsedValue);
  }, [focused, numberFormatter, value]);

  return (
    <TextInput
      hasWarning={hasWarning}
      {...props}
      maxLength={maxLength}
      name={question.id}
      onChange={handleChange}
      value={displayValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      rightSign={symbol}
      isTouched
      isValid={!hasError}
    />
  );
};

export default FloatField;
