import { RouteDefinition, RouteSectionDefinition } from 'ia-react-core';
import { APPLICATION_ROUTES_KEY } from '~/constants/APPLICATION_ROUTES';
import Item from '../interfaces/Item';

function getResourceKeys(data: Item[], applicationRoutesRecords: Record<APPLICATION_ROUTES_KEY, RouteDefinition | RouteSectionDefinition>) {
  const resourceKeys = new Map();
  data?.forEach((item) => {
    if (item.resourceKey !== null) {
      const key = item?.resourceKey?.toLowerCase();
      resourceKeys.set(key, item.isVisible);
      if (!item.isVisible && key in applicationRoutesRecords) {
        delete applicationRoutesRecords[key as APPLICATION_ROUTES_KEY];
      }
    }
    if (item.items && item.items.length > 0) {
      item.items.forEach((subItem) => {
        const key = `${subItem.resourceKey.toLowerCase()}-${subItem.id}`;
        resourceKeys.set(key, subItem.isVisible);
        if (!subItem.isVisible && key in applicationRoutesRecords) {
          delete applicationRoutesRecords[key as APPLICATION_ROUTES_KEY];
        }
      });
    }
  });
  return resourceKeys;
}

export default getResourceKeys;
