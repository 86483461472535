import { TertiaryButton } from 'ia-react-core';
import styled from 'styled-components';

export const StyledResponsiveMoreButton = styled(TertiaryButton)`
  display: flex;
  align-items: center;
  padding: 8px;

  & span > span {
    width: 24px;
    height: 24px;
  }
`;
