import { Action } from 'redux';
import { FormSubState } from '~/interfaces/FormSubState';
import { ValidationMessage } from '~/interfaces/ValidationMessage';
import { cloneDeep } from 'lodash';
import updateValidationMessages from '~/fragments/ApplicationFragment/utilities/updateValidationMessages';
import { SetInsPageValidationMessagesAction } from '~/fragments/ApplicationFragment/ApplicationFragment.actions';

export default function setInsValidationMessages<Value>(state: FormSubState<Value>, action: Action): FormSubState<Value> {
  const { validationMessages, page, formName, stateName, noParticipantId } = action as SetInsPageValidationMessagesAction;
  const selectedPage = {
    ...state?.[stateName as keyof typeof state] as Record<string, {
    validationMessages: ValidationMessage[];}>,
  };
  const forms: { [key: string]: unknown } = { ...selectedPage.validations };
  const { [formName]: _, ...restForms } = forms;

  const finalMessages = cloneDeep(state?.NomineePage?.validations?.[formName]?.validationMessages) || [];
  const data = updateValidationMessages(finalMessages, validationMessages, noParticipantId);
  return {
    ...state,
    [page]: {
      ...selectedPage,
      validations: {
        ...restForms,
        [formName]: {
          validationMessages: data,
        },
      },
    },
  };
}
