import React, { FC } from 'react';
import { AddCircleIcon, LinkProps } from 'ia-react-core';
import { ButtonLink } from '../ButtonLink';
import { ButtonLinkProps } from '../ButtonLink/ButtonLink';

type AddButtonProps = LinkProps & ButtonLinkProps;

const AddButton: FC<AddButtonProps> = ({ ...props }) => (
  <ButtonLink LeftIcon={AddCircleIcon} {...props} />
);

export default AddButton;
