import { Box } from 'ia-react-core';
import styled from 'styled-components';

export const StyledBorderBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.gray2};
`;
StyledBorderBox.defaultProps = {
  white: true,
  'p-h': 16,
  'p-v': 24,
  'm-b': 0,
};
