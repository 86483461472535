export enum ChangeRequestTypes{
  Unknown = 0,
  LegalChanges = 1, // 'LegalChanges',
  CoverageModifications = 2, // 'CoverageModifications',
  SurrenderLoanWithdrawal = 3, // 'SurrenderLoanWithdrawal',
  Investments = 4, // 'Investments',
  Billing= 5, // 'Billing',
  HeadOffice = 6, // 'HeadOffice',
  Advisors = 7, // 'Advisors',
  Summary = 8, // 'Summary',
}

export enum ChangeRequestEditOptionEnum {
  Unknown = '',
  PDFForm = 'PDFForm',
  WebForm = 'WebForm'
}

export enum Language{
  Unknown = 0,
  English = 1,
  French = 2,
}

export enum APILanguage {
  English = 'Anglais',
  French = 'Francais',
}

export enum APIRequestLanguage {
  English = 'A',
  French = 'F',
}

export enum Locale {
  EN = 'en',
  FR = 'fr'
}

export enum Progression {
  new = 0,
  inProcess = 1,
  processed = 2,
  completed = 3,
}

export enum ValidationMessageTypes {
  Error = 2,
  Notice = 0,
  Warning = 1,
}

export enum SectionDisplayType {
  Unknown = 0,
  Physician = 1,
  MedicalCondition = 2,
}

export enum QuestionDisplayType {
  Unknown = 0,
  Percentage = 1,
  Currency = 2,
  Email = 3,
  SocialInsuranceNumber = 4,
  PostalCode = 5,
  PhoneNumber = 6,
  Multiline = 100,
  ExtraLargeMultiline = 101,
  Kilograms = 200,
  Pounds = 201,
  Centimeters = 300,
  Meters = 301,
  Inches = 302,
  Feet = 303,
  PolicyNumber = 304,
  MonthYear = 305,
  LettersSymbols = 306,
  LettersNumbers = 307,
  PhysicianFirstName = 1000,
  PhysicianLastName = 1001,
  PhysicianCivicNumber = 1002,
  PhysicianStreetName = 1003,
  PhysicianCity = 1004,
  PhysicianPostalCode = 1005,
  PhysicianProvince = 1006,
  PhysicianCareLocation = 1007,
  PhysicianConditionName = 1008,
  Hidden = 2000,
  NumbersSymbols = 308,
  Letters = 309,
  Numbers = 310,
  YearOnly = 311,
}

export enum FormCategoryType {
  Unkown = 0,
  Admissibility = 1,
  PreDeclaration = 2,
  InsurabilityDeclaration = 3,
  PaperForm = 4,
  MentalHealth = 5,
  FamilyHistory = 6,
  Medical = 7,
  NonMedical = 8,
  Physicians = 9,
  Disability = 10,
  SportsAndHobbies = 11,
  Driving = 12,
  Criminal = 13,
  Drugs = 14,
  Alcool = 15,
  Exam = 16,
  GeneralConditions = 17,
  Travel = 18,
  Climbing = 19,
  Racing = 20,
  Parachuting = 21,
  Aviation = 22,
  Diving = 23,
  Skiing = 24,
  Pause = 1000,
  AnswersInconsistency = 1005,
  ContextInconsistency = 1010,
  ProfessionalInformationInconsistency = 1015,
  OccupationInconsistency = 1020,
  Stop = 1100,
}

export enum QuestionType {
  Unknown = 0,
  BoolQuestion = 1,
  DateQuestion = 2,
  FloatQuestion = 3,
  IntegerQuestion = 4,
  StringQuestion = 5,
  MultipleChoiceListQuestion = 6,
  SingleChoiceListQuestion = 7,
  LookupQuestion = 8,
  StaticContent = 9
}

export enum AddPhysicianHCFEnum {
  Physician = 'Physician',
  HealthCareFacility = 'HealthCareFacility',
}

export enum RequestForType {
  Client = 0,
  Advisor = 1,
  Employee = 2
}

export enum EmployeeType {
  Employee = 601,
  VicePresident = 602
}
