import { Action, Reducer } from 'redux';
import { LOAD_CASE_SUCCESS_TYPE, LOAD_CASE_TYPE, SET_CURRENT_FORM_ID_TYPE, SET_LOADING_TYPE, validateCaseSuccessAction } from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/InsurabilityDeclaration.actions';
import loadCaseReducer from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/reducers/loadCase';
import loadCaseSuccessReducer from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/reducers/loadCaseSuccess';
import { validateCaseSuccess } from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/reducers/validateCaseSuccess';
import setCurrentFormId from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/reducers/setCurrentFormId';
import fatalError from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/reducers/fatalError';
import setLoading from '~/fragments/ApplicationFragment/pages/NomineePage/pages/InsurabilityDeclaration/reducers/setLoading';
import { FATAL_ERROR_TYPE } from '~/components/AdvisorsModal/AdvisorsModal.actions';
import setCurrentTab from './setCurrentTab';
import { clearInsured, setFormId, setUseTobacco } from './setInsured';
import loadChangeRequestFormsReducer from './loadChangeRequestFormsReducer';
import loadChangeRequestFormsSuccessReducer from './loadChangeRequestFormsSuccessReducer';
import loadChangeRequestFormByIdReducer from './loadChangeRequestFormByIdReducer';
import { createRepeatingSectionSuccess } from './createRepeatingSectionSuccess';
import { deleteInstanceSectionSuccess } from './deleteInstanceSectionSuccess';
import loadChangeRequestSuccessReducer from './loadChangeRequestSuccessReducer';
import loadChangeRequestReducer from './loadChangeRequestReducer';
import answerQuestionSuccessReducer from './answerQuestionSuccess';
import { updateSignaturePaperFormReducer } from './SignaturePaper.reducer';
import { updateSignatureConfigureReducer } from './signatureReducer';
import { updateSignatureElectronicFormReducer, updateAdditionalFormSignerFullName, addMainSignerRoleReducer, deleteMainSignerRoleReducer, addAdditionalSignerRoleReducer, deleteAdditionalSignerRoleReducer } from './SignatureElectronic.reducer';

import { ChangeRequestState } from '../interfaces/State';
import {
  DeleteChangeRequestFormByIdType, LoadChangeRequestFormByIdType,
  LoadChangeRequestFormsSuccessType, LoadChangeRequestFormsType,
  LoadChangeRequestSuccessType, LoadChangeRequestType, CreateRepeatingSectionSuccessType,
  DeleteInstanceSectionSuccessType, AnswerQuestionsSuccessType, SetCurrentTab, ClearInsured, SetFormId, SetUseTobacco, UpdateSignaturePaperFormType, UpdateSignatureConfigureType, UpdateSignatureElectronicFormType, UpdateAdditionalFormSignerFullNameType, AddMainSignerRoleType, DeleteMainSignerRoleType, AddAdditionalSignerRoleType, DeleteAdditionalSignerRoleType,
  LoadApplicationIndividuals, UpdateRenderCheckBoxStatus,
  FormSelectionErrorType,
} from '../ChangeRequest.actionTypes';

import LoadApplicationApplicantsReducer from './LoadApplicationApplicants';
import { updateRenderCheckBoxReducer } from './UpdateRenderCheckBoxReducer';
import { updateFormSelectionError } from './updateFormSelectionError';

const mapping: Record<string, Reducer> = {
  [LOAD_CASE_TYPE]: loadCaseReducer,
  [LOAD_CASE_SUCCESS_TYPE]: loadCaseSuccessReducer,
  [AnswerQuestionsSuccessType]: answerQuestionSuccessReducer,
  [SET_LOADING_TYPE]: setLoading,
  [FATAL_ERROR_TYPE]: fatalError,
  [SET_CURRENT_FORM_ID_TYPE]: setCurrentFormId,
  [validateCaseSuccessAction.type]: validateCaseSuccess,
  [CreateRepeatingSectionSuccessType]: createRepeatingSectionSuccess,
  [DeleteInstanceSectionSuccessType]: deleteInstanceSectionSuccess,
  [LoadChangeRequestType]: loadChangeRequestReducer,
  [LoadChangeRequestSuccessType]: loadChangeRequestSuccessReducer,
  [LoadChangeRequestFormsType]: loadChangeRequestFormsReducer,
  [LoadChangeRequestFormsSuccessType]: loadChangeRequestFormsSuccessReducer,
  [LoadChangeRequestFormByIdType]: loadChangeRequestFormByIdReducer,
  [DeleteChangeRequestFormByIdType]: loadChangeRequestFormByIdReducer,
  [SetCurrentTab]: setCurrentTab,
  [ClearInsured]: clearInsured,
  [SetFormId]: setFormId,
  [SetUseTobacco]: setUseTobacco,
  [UpdateSignatureConfigureType]: updateSignatureConfigureReducer,
  [UpdateSignaturePaperFormType]: updateSignaturePaperFormReducer,
  [UpdateSignatureElectronicFormType]: updateSignatureElectronicFormReducer,
  [UpdateAdditionalFormSignerFullNameType]: updateAdditionalFormSignerFullName,
  [AddMainSignerRoleType]: addMainSignerRoleReducer,
  [DeleteMainSignerRoleType]: deleteMainSignerRoleReducer,
  [AddAdditionalSignerRoleType]: addAdditionalSignerRoleReducer,
  [DeleteAdditionalSignerRoleType]: deleteAdditionalSignerRoleReducer,
  [LoadApplicationIndividuals]: LoadApplicationApplicantsReducer,
  [UpdateRenderCheckBoxStatus]: updateRenderCheckBoxReducer,
  [FormSelectionErrorType]: updateFormSelectionError,
};

export default function changeRequestReducer(state: ChangeRequestState, action: Action) {
  const typeReducer = mapping[action.type] ?? ((x) => x);
  return typeReducer(state, action);
}
