import React from 'react';
import {
  Form, FormCol, FormRow, Modal, SecondaryButton, closeModalByName, Divider, PrimaryButton, useScopedSelector, ResourceLoader, requestResourceByName,
} from 'ia-react-core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StyledActionBar, StyledSelectControl, StyledT2 } from './OipaEnvironmentsModal.styles';
import { GET_OIPA_ENVIRONMENTS_RESOURCE_NAME, SET_OIPA_ENVIRONMENTS_RESOURCE_NAME } from './constants/constants';
import { OipaEnvironmentsStateInterface } from './interfaces/OipaEnvironmentsStateInterface';
import getOipaEnvironmentsOptions from './utilities/getOipaEnvironmentsOptions';
import { GetOipaEnvironmentsResource } from './components/GetOipaEnvironmentsResource';
import { GetOipaEnvironmentsResponseInterface } from './interfaces/GetOipaEnvironmentsResponseInterface';
import { SetOipaEnvironmentsResource } from './components/SetOipaEnvironmentsResource';
import { GetApplicationConfigurationResource } from '../GetApplicationConfigurationResource';

const OipaEnvironmentsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('OipaEnvironmentsModal');
  const formData = useScopedSelector((state: OipaEnvironmentsStateInterface) => state.data?.oipaEnvironments);
  const applicationConfiguration = useScopedSelector((state: OipaEnvironmentsStateInterface) => state.data?.applicationConfiguration);

  const handleClick = () => dispatch(requestResourceByName(`resources.${SET_OIPA_ENVIRONMENTS_RESOURCE_NAME}`));

  return (
    <Modal name="oipaEnvironmentsModal">
      <GetApplicationConfigurationResource autoRequest />
      <GetOipaEnvironmentsResource />
      <SetOipaEnvironmentsResource env={formData?.environment} />
      <StyledT2>{t('oipa_environments')}</StyledT2>

      <ResourceLoader resourceSrc={`resources.${GET_OIPA_ENVIRONMENTS_RESOURCE_NAME}`}>
        {({ data }: {data: GetOipaEnvironmentsResponseInterface[]}) => (
          <div>
            <Form
              initialValues={{ environment: applicationConfiguration?.environmentOipa }}
              name="oipaEnvironmentsForm"
              valuesSrc="forms.oipaEnvironments"
              valuesDst="data.oipaEnvironments"
            >
              <FormRow>
                <FormCol label={t('environments')}>
                  <StyledSelectControl
                    name="environment"
                    options={getOipaEnvironmentsOptions(data)}
                  />
                </FormCol>
              </FormRow>
            </Form>

            <Divider />

            <StyledActionBar>
              <PrimaryButton onClick={handleClick}>
                {t('save')}
              </PrimaryButton>
              <SecondaryButton onClick={() => dispatch(closeModalByName('modals.oipaEnvironments'))}>
                {t('cancel')}
              </SecondaryButton>
            </StyledActionBar>
          </div>
        )}
      </ResourceLoader>
    </Modal>
  );
};

export default OipaEnvironmentsModal;
