import {
  Col,
  Divider,
  Drawer,
  HelpIcon,
  Menu,
  MenuItem,
  Row,
  SecondaryTextIconButton,
  SettingsIcon,
  openModalByName,
} from 'ia-react-core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as HomeIconBlue } from '~/assets/Icons/homeIcon.svg';
import { ReactComponent as WorkIconBlue } from '~/assets/Icons/workIcon.svg';
import openMedicalRequirements from '~/utilities/openMedicalRequirements';

export interface DrawerFilesProps {
}

const DrawerFiles: FC<DrawerFilesProps> & {} = () => {
  const dispatch = useDispatch();
  const localeGlobal = useSelector((state: {language: {locale: string}}) => state?.language?.locale);
  const { t } = useTranslation('DrawerFiles');
  return (

    <Drawer
      drawerTitle="My files"
      name="myFiles"
    >
      <div>
        <Divider />
        <Row p-t={24}>
          <Col>
            <SecondaryTextIconButton
              LeftIcon={WorkIconBlue}
              onClick={() => { openMedicalRequirements(localeGlobal); }}
            >
              {t('work')}
            </SecondaryTextIconButton>
          </Col>
        </Row>
        <Row p-t={16}>
          <Col>
            <SecondaryTextIconButton LeftIcon={HomeIconBlue}>
              {t('home')}
            </SecondaryTextIconButton>
          </Col>
        </Row>
        <Row p-t={16}>
          <Col>
            <Menu
              items={[
                <MenuItem
                  url="#"
                  onClick={() => dispatch(openModalByName('modals.advisors'))}
                >
                  {t('advisors')}
                </MenuItem>,
                <MenuItem url="#" onClick={() => dispatch(openModalByName('modals.illustrationSettings'))}>
                  {t('illustrations')}
                </MenuItem>,
                <MenuItem url="#">
                  {t('signature')}
                </MenuItem>,
              ]}
            >
              <SecondaryTextIconButton LeftIcon={SettingsIcon}>
                {t('settings')}
              </SecondaryTextIconButton>
            </Menu>
          </Col>
        </Row>
        <Row p-t={16}>
          <Col>
            <SecondaryTextIconButton LeftIcon={HelpIcon}>
              {t('help')}
            </SecondaryTextIconButton>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};

export default DrawerFiles;
